import { campaignQueryBase, ReadyCampaign } from '../service';
import React, { ComponentProps, useMemo, useState } from 'react';
import { Metric } from '../../../services/analyticsV2/metrics';
import { css, styled } from '../../../emotion';
import {
  createNameColumn,
  TopHeader,
  TopPaper,
  TopTable
} from '../../../components/analytics_v2/Table/TopTable/TopTable';
import { DeviceIconWithLabel } from '../../../components/DeviceIcon';
import { Device } from '../../../domainTypes/tracking';
import { COLORS } from '../../../domainTypes/colors';
import {
  AnalyticsFilter,
  ISOTimeRange
} from '../../../domainTypes/analytics_v2';
import { ProductLinkCellLazyMinimal } from '../../Links/pages/Overview/components/ProductLinkCell';
import {
  dbColumn,
  utmParameterTitle,
  UtmParameterType
} from '../../Content/pages/Traffic/Utm/service/utm';
import { Truncated } from '../../../components/Truncated';
import { SelectUtm } from '../../../components/SelectUtm';
import { FullTableFooter, fullTableQuery } from './FullTable';
import { ExportQueryButton } from '../../../components/ExportQuery';
import { FlexContainer } from '../../../layout/Flex';

type ExportButtonProps = {
  title: string;
  header?: React.ReactNode;
} & Pick<
  ComponentProps<typeof TopTable>,
  | 'range'
  | 'compareRange'
  | 'groupField'
  | 'filters'
  | 'metric'
  | 'columnTransformers'
>;

const ExportButton: React.FC<ExportButtonProps> = ({
  title,
  range,
  groupField,
  filters,
  metric,
  columnTransformers = [],
  compareRange
}) => (
  <ExportQueryButton
    title={`Export ${title}`}
    reportType="campaigns"
    query={fullTableQuery(
      range,
      groupField,
      metrics,
      filters,
      { field: metric, direction: 'DESC' },
      columnTransformers,
      compareRange
    )}
  />
);

type CampaignTableProps = {
  className?: string;
  title: string;
  includeFooter?: boolean;
  header?: React.ReactNode;
  description?: string;
  expandedMetrics?: Metric[];
} & ComponentProps<typeof TopTable>;

const CampaignTable: React.FC<CampaignTableProps> = ({
  className,
  title,
  includeFooter,
  header,
  description,
  expandedMetrics = metrics,
  ...tableProps
}) => {
  return (
    <TopPaper className={className}>
      <TopHeader title={title} description={description}>
        <FlexContainer spacing={0.5} alignItems="top">
          {header}
          <ExportButton title={title} {...tableProps} />
        </FlexContainer>
      </TopHeader>
      <TopTable {...tableProps} />
      {includeFooter ? (
        <FullTableFooter
          title={title}
          {...tableProps}
          metrics={expandedMetrics}
        />
      ) : null}
    </TopPaper>
  );
};

interface TableProps {
  filters: AnalyticsFilter[];
  range: ISOTimeRange;
  className?: string;
}

const baseMetrics: Metric[] = ['c', 'gmv_sum_net'];

const metrics: Metric[] = [
  'c',
  'p',
  'v',
  'gmv_sum_net',
  'commission_sum_net',
  'aov_net',
  'order_count_net'
];

const deviceColumn = createNameColumn(
  (d) => <DeviceIconWithLabel device={d.group.device as Device} />,
  'Device',
  COLORS.lime.lime1,
  'gmv_sum_net'
);

const DeviceTable: React.FC<TableProps> = ({ filters, range, className }) => (
  <CampaignTable
    className={className}
    title="Devices"
    description="Performance by device type"
    groupField={'device'}
    metrics={baseMetrics}
    metric={'gmv_sum_net'}
    filters={filters}
    range={range}
    nameColumn={deviceColumn}
  />
);

const linkColumn = createNameColumn(
  (d, o) => (
    <ProductLinkCellLazyMinimal
      spaceId={o.spaceId}
      productId={d.group.link_id}
    />
  ),
  'Link name or deeplink',
  COLORS.red.red1,
  'gmv_sum_net'
);

const LinkTable: React.FC<TableProps> = ({ className, filters, range }) => (
  <CampaignTable
    title="Links and products"
    description="Performance by product linked"
    className={className}
    includeFooter
    nameColumn={linkColumn}
    groupField={'link_id'}
    metrics={baseMetrics}
    metric={'gmv_sum_net'}
    filters={filters}
    range={range}
  />
);

const contentColumn = createNameColumn(
  (d) => <Truncated title={d.group.page_url} />,
  'Page URL',
  COLORS.blue.blue1,
  'gmv_sum_net'
);

const ContentTable: React.FC<TableProps> = ({ filters, range, className }) => (
  <CampaignTable
    title="Campaign content"
    description="Performance of links within your campaign content"
    className={className}
    includeFooter
    nameColumn={contentColumn}
    groupField={'page_url'}
    metrics={metrics}
    metric={'gmv_sum_net'}
    filters={filters}
    range={range}
  />
);

const UtmTable: React.FC<TableProps> = ({ className, filters, range }) => {
  const [utmParam, setUtmParam] = useState<UtmParameterType>('campaign');
  const title = useMemo(() => utmParameterTitle(utmParam), [utmParam]);
  const utmColumn = useMemo(
    () =>
      createNameColumn(
        (d) => <Truncated title={d.group[dbColumn(utmParam)]} />,
        title,
        COLORS.blue.blue1,
        'gmv_sum_net'
      ),
    [title, utmParam]
  );

  return (
    <CampaignTable
      className={className}
      title={`Top ${title}`}
      includeFooter
      header={<SelectUtm value={utmParam} onChange={setUtmParam} />}
      description={`Performance for traffic via UTM parameters`}
      nameColumn={utmColumn}
      groupField={dbColumn(utmParam)}
      metrics={baseMetrics}
      metric={'gmv_sum_net'}
      filters={filters}
      range={range}
    />
  );
};

const trackingLabelColumn = createNameColumn(
  (d) => <Truncated title={d.group.tracking_label} />,
  'Tracking label',
  COLORS.purple.purple1,
  'gmv_sum_net'
);

const TrackingLabelTable: React.FC<TableProps> = ({
  className,
  filters,
  range
}) => (
  <CampaignTable
    className={className}
    title="SubID"
    description="Performance by SubID"
    includeFooter
    nameColumn={trackingLabelColumn}
    groupField={'tracking_label'}
    metrics={['gmv_sum_net', 'commission_sum_net']}
    metric={'gmv_sum_net'}
    filters={filters}
    range={range}
    expandedMetrics={[
      'gmv_sum_net',
      'commission_sum_net',
      'aov_net',
      'order_count_net'
    ]}
  />
);

const COLUMN_COUNT = 2;

const Grid = styled('div')((p) => ({
  display: 'grid',
  gridTemplateColumns: `repeat(${COLUMN_COUNT}, minmax(0, 1fr))`,
  gap: p.theme.spacing(2)
}));

export const CampaignTables: React.FC<{
  campaign: ReadyCampaign;
}> = ({ campaign }) => {
  const { range, filters } = useMemo(() => campaignQueryBase(campaign), [
    campaign
  ]);

  return (
    <>
      <Grid>
        <ContentTable
          filters={filters}
          range={range}
          className={css(() => ({
            gridColumn: `span ${COLUMN_COUNT}`
          }))}
        />
      </Grid>
      <Grid>
        <LinkTable filters={filters} range={range} />
        <TrackingLabelTable filters={filters} range={range} />
      </Grid>
      <Grid>
        <UtmTable filters={filters} range={range} />
        <DeviceTable filters={filters} range={range} />
      </Grid>
    </>
  );
};
