import { Paper, Tooltip, Typography } from '@material-ui/core';
import { compact, keyBy, mapValues, uniq } from 'lodash';
import moment from 'moment-timezone';
import React, { useMemo } from 'react';
import { ExternalLink as IconOpenNewWindow } from 'react-feather';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { AlertBox } from '../../../../components/AlertBox';
import { PartnerBarChartCard } from '../../../../components/Charts/PartnerBarChartCard';
import { PartnerChartCard } from '../../../../components/Charts/PartnerChartCard';
import { Ctr } from '../../../../components/Ctr';
import {
  ItemSorters,
  RowsRenderer,
  ROW_HEIGHTS,
  UNKNOWN,
  useColumnsQueryParam,
  useSortQueryParam
} from '../../../../components/GroupableList';
import { HelpIcon } from '../../../../components/HelpIcon';
import { Loader } from '../../../../components/Loader';
import { NoPermissions } from '../../../../components/NoPermissions';
import { Number } from '../../../../components/Number';
import { PartnerLogoWithTextContainer } from '../../../../components/PartnerLogo';
import { PlatformWithColor } from '../../../../components/PlatformWithColor';
import { BaseCountCell, CountCell } from '../../../../components/Table';
import { IColumn } from '../../../../components/Table/Column';
import { ColumnSelector } from '../../../../components/Table/ColumnSelector';
import { Dash } from '../../../../components/Table/CountCell';
import {
  TimeframePickerDense,
  useStandardOptions,
  useTimeframeFromUrl
} from '../../../../components/TimeframePicker';
import {
  EMPTY_COUNT_WITH_TREND,
  getTrend,
  isSameTimeframe,
  Timeframe
} from '../../../../domainTypes/analytics';
import { CurrencyCode } from '../../../../domainTypes/currency';
import { EMPTY_ARR } from '../../../../domainTypes/emptyConstants';
import {
  EarningsArgsGroupedInTimeframe,
  EarningsRespGroupedInTimeframe,
  EMPTY_EARNING,
  SalesFilterArgs,
  SaleStatus,
  SaleType,
  SALE_STATUSES,
  SALE_TYPES,
  toEarningFromMinimal
} from '../../../../domainTypes/performance';
import { styled } from '../../../../emotion';
import { Centered } from '../../../../layout/Centered';
import {
  DEFAULT_OFFSET,
  DEFAULT_TOOLBAR_HEIGHT,
  PageToolbar,
  PageToolbarSection
} from '../../../../layout/PageToolbar';
import {
  queryParamToList,
  setToQueryParam,
  useNullableStringSetQueryParam,
  useQueryParam,
  useRoutes
} from '../../../../routes';
import {
  allTime,
  getClickRatio,
  getEpc,
  toComparableTimeframe
} from '../../../../services/analytics';
import { ARTICLES } from '../../../../services/beacon';
import {
  useCurrentUser,
  useHasCurrentUserRequiredScopes
} from '../../../../services/currentUser';
import {
  combineLoadingValues,
  useMappedLoadingValue
} from '../../../../services/db';
import { useExperimentalContext } from '../../../../services/experimental';
import { useTrackMixpanelView } from '../../../../services/mixpanel';
import {
  getKnownPartnerForKeyUnsafe,
  PartnerListItemWithCountsAndTrendsAndSales,
  usePartnersWithCountsAndTrendsPg
} from '../../../../services/partner';
import { useEarnings } from '../../../../services/sales/earnings';
import { timeframeToMs, toMoment } from '../../../../services/time';
import { useSpaceCurrency } from '../../../../services/useSpaceCurrency';
import * as tracking from '../../../../tracking';
import { getConversionRate } from '../../../PerformanceNew/services/sale';
import { PerformancePageBody } from '../../components/PerformancePageBody';
import { SalesFilter } from '../../components/SalesFilter';
import { useKnownAdvertisers, useKnownPartners } from '../../services/hooks';
import { PagePerformanceNetworksV2 } from './index2';
import { createPlatformFilterDefinition } from '../../../../components/analytics_v2/Filters/filters';

const EMPTY_PARTNER_KEY_SET = new Set<string>();

const PartnerLink = styled('a')`
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    display: inline-block;
  }
`;

type ColumnName =
  | 'name'
  | 'partnerCenter'
  | 'earningsPerClick'
  | 'orderCount'
  | 'aov'
  | 'conversionRate'
  | 'total'
  | 'saleValue'
  | 'products'
  | 'viewed'
  | 'avgCommissionPercent'
  | 'clicked'
  | 'viewRatio'
  | 'clickRatio';

type Column = IColumn<
  PartnerListItemWithCountsAndTrendsAndSales,
  ColumnName,
  { compare: boolean }
>;

const COLUMNS: Column[] = [
  {
    key: 'name',
    head: () => 'Network',
    cell: (p) => (
      <div style={{ display: 'flex' }}>
        <PartnerLogoWithTextContainer>
          <PlatformWithColor partner={p.partner} />
        </PartnerLogoWithTextContainer>
        {p.partner.portalUrl && (
          <Tooltip title="Open partner portal in new window" placement="top">
            <>
              <PartnerLink
                href={p.partner.portalUrl}
                target="_blank"
                rel="noopener noreferrer"
                title={p.partner.portalUrl}
                onClick={(ev) => {
                  ev.stopPropagation();
                  tracking.sendEvent({
                    category: tracking.toAppCategory(),
                    action: 'External click',
                    label: p.partner.name
                  });
                }}
              >
                <IconOpenNewWindow size={14} color="#AAA" />
              </PartnerLink>
            </>
          </Tooltip>
        )}
      </div>
    ),
    align: 'left',
    sortable: true,
    width: 200,
    flexGrow: 3
  },
  {
    key: 'clicked',
    head: () => 'Clicks',
    cell: (p, o) => (
      <CountCell
        before={p.counts.clicked.lastCount}
        after={p.counts.clicked.count}
        compare={o.compare}
      />
    ),
    headInfo: () =>
      'How many times links to this partner were clicked during the selected time period.',
    align: 'right',
    sortable: true,
    width: 100,
    flexGrow: 1
  },
  {
    key: 'earningsPerClick',
    head: () => 'EPC',
    cell: (p, o) =>
      p.sales.earnings ? (
        <CountCell
          before={p.sales.earningsPerClick.lastCount}
          after={p.sales.earningsPerClick.count}
          compare={o.compare}
          currency={p.sales.currency as CurrencyCode}
        />
      ) : (
        <Dash size={16} />
      ),
    headInfo: () =>
      'How much you earned on average for every click you drove to this affiliate program or network.',
    align: 'right',
    sortable: true,
    width: 100,
    flexGrow: 1
  },
  {
    key: 'orderCount',
    head: () => 'Orders',
    cell: (p, o) => {
      if (
        p.sales.orderCount.count === 0 &&
        p.sales.orderCount.lastCount === 0
      ) {
        return <Dash size={16} />;
      }

      return (
        <CountCell
          before={p.sales.orderCount.lastCount}
          after={p.sales.orderCount.count}
          compare={o.compare}
        />
      );
    },
    headInfo: () =>
      'How often visitors who see an affiliate link to this partner actually click on the link.',
    align: 'right',
    sortable: true,
    width: 100,
    flexGrow: 1
  },
  {
    key: 'clickRatio',
    head: () => 'CTR',
    cell: (p, o) => (
      <BaseCountCell
        before={getClickRatio({
          clicked: p.counts.clicked.lastCount,
          viewed: p.counts.viewed.lastCount
        })}
        after={getClickRatio({
          clicked: p.counts.clicked.count,
          viewed: p.counts.viewed.count
        })}
        compare={o.compare}
        format="percent"
        digits={1}
      >
        <Ctr
          rate={getClickRatio({
            clicked: p.counts.clicked.count,
            viewed: p.counts.viewed.count
          })}
        />
      </BaseCountCell>
    ),
    headInfo: () =>
      'How often visitors who see an affiliate link to this partner actually click on the link.',
    align: 'right',
    sortable: true,
    width: 100,
    flexGrow: 1
  },
  {
    key: 'conversionRate',
    head: () => 'CR',
    cell: (p) =>
      !p.sales.earnings.count ? '-' : <Ctr rate={p.sales.conversionRate} />,
    headInfo: () =>
      'Conversion rate, based on ratio of outbound clicks that lead to a sale.',
    align: 'right',
    sortable: true,
    width: 100,
    flexGrow: 1
  },
  {
    key: 'avgCommissionPercent',
    head: () => 'Comm Rate',
    cell: (p) =>
      p.sales.earnings.count ? (
        <Number n={p.sales.avgCommissionPercent} format="percent" digits={2} />
      ) : (
        <Dash size={16} />
      ),
    headInfo: () =>
      'On average, what percentage commission you are earning from your sales through this partner during the selected timeframe.',
    align: 'right',
    sortable: true,
    width: 100,
    flexGrow: 1
  },
  {
    key: 'total',
    head: () => 'Earnings',
    cell: (p, o) =>
      p.sales.earnings.count ? (
        <CountCell
          before={p.sales.earnings.lastCount}
          after={p.sales.earnings.count}
          compare={o.compare}
          currency={p.sales.currency as CurrencyCode}
        />
      ) : (
        <Dash size={16} />
      ),
    headInfo: () =>
      'Earnings you generated from this network during the selected time period, including both pending and finalized commissions.',
    align: 'right',
    sortable: true,
    width: 100,
    flexGrow: 1
  },
  {
    key: 'saleValue',
    head: () => 'Sales volume',
    cell: (p, o) =>
      p.sales.saleValue.count ? (
        <CountCell
          before={p.sales.saleValue.lastCount}
          after={p.sales.saleValue.count}
          compare={o.compare}
          currency={p.sales.currency as CurrencyCode}
        />
      ) : (
        <Dash size={16} />
      ),
    headInfo: () =>
      'Sales volume generated on this platform, for all advertisers that report it.',
    align: 'right',
    sortable: true,
    width: 100,
    flexGrow: 1
  }
];

const SORTERS: ItemSorters<PartnerListItemWithCountsAndTrendsAndSales> = {
  name: {
    key: 'name',
    items: { sort: (p) => p.partner.name, dir: 'asc' }
  },
  clicked: {
    key: 'clicked',
    items: { sort: (p) => p.counts.clicked.count, dir: 'desc' }
  },
  earningsPerClick: {
    key: 'earningsPerClick',
    items: { sort: (p) => p.sales.earningsPerClick.count, dir: 'desc' }
  },
  total: {
    key: 'total',
    items: { sort: (p) => p.sales.earnings.count, dir: 'desc' }
  },
  clickRatio: {
    key: 'clickRatio',
    items: {
      sort: (p) =>
        getClickRatio({
          clicked: p.counts.clicked.count,
          viewed: p.counts.viewed.count
        }),
      dir: 'desc'
    }
  },
  conversionRate: {
    key: 'conversionRate',
    items: { sort: (p) => p.sales.conversionRate, dir: 'desc' }
  }
};

const HEIGHT = 700;

const Grid = styled('div')`
  display: grid;
  grid-column-gap: ${(p) => p.theme.spacing(3)}px;
  grid-row-gap: ${(p) => p.theme.spacing(6)}px;
  grid-template-columns: 3fr 1.75fr;
  grid-template-rows: 450px;
  margin-bottom: ${(p) => p.theme.spacing(6)}px;

  ${(p) => p.theme.breakpoints.down('md')} {
    grid-column-gap: ${(p) => p.theme.spacing(1)}px;
    grid-row-gap: ${(p) => p.theme.spacing(2)}px;
    grid-template-columns: 1fr 1fr;
  }

  ${(p) => p.theme.breakpoints.down('sm')} {
    grid-column-gap: ${(p) => p.theme.spacing(1)}px;
    grid-row-gap: ${(p) => p.theme.spacing(2)}px;
    grid-template-columns: 1fr;
  }
`;

const useEarningsPerPlatform = (
  spaceId: string,
  timeframe: Timeframe,
  q: SalesFilterArgs,
  compare: boolean,
  currency: CurrencyCode
) => {
  const queries: EarningsArgsGroupedInTimeframe[] = useMemo(
    () =>
      compact([
        {
          type: 'groupedInTimeframe',
          d: {
            ...q,
            groupBy: ['partner_key'],
            dates: { ...timeframeToMs(timeframe), column: 'sale_date' },
            currency
          }
        },
        compare && {
          type: 'groupedInTimeframe',
          d: {
            ...q,
            groupBy: ['partner_key'],
            dateColumn: 'sale_date',
            dates: {
              ...timeframeToMs(toComparableTimeframe(timeframe)),
              column: 'sale_date'
            },
            currency
          }
        }
      ]),
    [timeframe, compare, currency, q]
  );

  return useMappedLoadingValue(
    useEarnings<EarningsRespGroupedInTimeframe[]>(spaceId, queries, currency),
    (r) => {
      // prev is empty if we are in non-compare mode
      console.log('useEarningsPerPlatform', r);
      const [curr, prev = {}] = r.res.map((x) => {
        return mapValues(
          keyBy(x.d, (t) => (t.group['partner_key'] as string) || UNKNOWN),
          (v) => toEarningFromMinimal(v.d)
        );
      });
      return { curr, prev };
    }
  );
};

// TODO once we switch over entirely we can also show trends for
// sales related data here. The necessary earnings data is already present.
const usePartnersWithCountsAndTrendsAndSales = (
  spaceId: string,
  timeframe: Timeframe,
  q: SalesFilterArgs,
  compare: boolean,
  currency: CurrencyCode
) => {
  return useMappedLoadingValue(
    combineLoadingValues(
      usePartnersWithCountsAndTrendsPg(spaceId, timeframe, compare),
      useEarningsPerPlatform(spaceId, timeframe, q, compare, currency)
    ),
    ([partnerCounts, earnings]) => {
      const earningsByPartnerCurr = earnings.curr;
      const earningsByPartnerPrev = earnings.prev;
      const partnersWithEarnings = uniq([
        ...Object.keys(earningsByPartnerCurr),
        ...Object.keys(earningsByPartnerPrev)
      ]);
      const partnersWithAnalytics = partnerCounts.map((p) => p.partner.key);
      const allPartners = uniq([
        ...partnersWithEarnings,
        ...partnersWithAnalytics
      ]);

      return allPartners.map<PartnerListItemWithCountsAndTrendsAndSales>(
        (pk) => {
          const earningsCurr =
            earningsByPartnerCurr[pk] || EMPTY_EARNING(currency);
          const earningsPrev =
            earningsByPartnerPrev[pk] || EMPTY_EARNING(currency);
          const partner = getKnownPartnerForKeyUnsafe(pk);
          const p = partnerCounts.find((p) => p.partner.key === pk) || {
            partner: partner,
            counts: {
              products: 0,
              served: EMPTY_COUNT_WITH_TREND(),
              clicked: EMPTY_COUNT_WITH_TREND(),
              viewed: EMPTY_COUNT_WITH_TREND()
            }
          };

          return {
            ...p,
            sales: {
              earnings: {
                count: earningsCurr.total,
                lastCount: earningsPrev.total,
                trend: getTrend(earningsPrev.total, earningsCurr.total)
              },
              saleValue: {
                count: earningsCurr.saleValue.total,
                lastCount: earningsPrev.saleValue.total,
                trend: getTrend(
                  earningsPrev.saleValue.total,
                  earningsCurr.saleValue.total
                )
              },
              orderCount: {
                count: earningsCurr.orderCount.total,
                lastCount: earningsPrev.orderCount.total,
                trend: getTrend(
                  earningsPrev.orderCount.total,
                  earningsCurr.orderCount.total
                )
              },
              currency: earningsCurr.currency as CurrencyCode,
              conversionRate: getConversionRate(
                earningsCurr,
                p.counts.clicked.count
              ),
              earningsPerClick: {
                count: getEpc(p.counts.clicked.count, earningsCurr.total),
                lastCount: getEpc(
                  p.counts.clicked.lastCount,
                  earningsPrev.total
                ),
                trend: getTrend(
                  getEpc(p.counts.clicked.lastCount, earningsPrev.total),
                  getEpc(p.counts.clicked.count, earningsCurr.total)
                )
              },
              avgCommissionPercent: earningsCurr.avgCommissionPercent
            }
          };
        }
      );
    }
  );
};

const ROW_TO_KEY = (p: PartnerListItemWithCountsAndTrendsAndSales) =>
  p.partner.key;

const DEFAULT_COLUMNS = COLUMNS.map((c) => c.key).filter(
  (c) => !['clickRatio', 'conversionRate'].includes(c)
);

export const PagePerformanceNetworksPg = () => {
  const { ROUTES, changeQuery } = useRoutes();
  const {
    space: { id: spaceId }
  } = useCurrentUser();
  const { options, defaultOption } = useStandardOptions();
  const [timeframe, setTimeframe] = useTimeframeFromUrl(defaultOption.value);
  const compare = !isSameTimeframe(allTime(), timeframe);
  const currency = useSpaceCurrency();

  const [[sorter, direction], setSort] = useSortQueryParam('sort', SORTERS);
  const [columnNames, setColumns] = useColumnsQueryParam(
    'columns',
    DEFAULT_COLUMNS
  );
  const columns = useMemo(
    () =>
      columnNames ? COLUMNS.filter((c) => columnNames.has(c.key)) : COLUMNS,
    [columnNames]
  );
  const [
    selectedPartners,
    setSelectedPartners,
    toPartnerQueryParam
  ] = useNullableStringSetQueryParam('partners');

  const [
    selectedAdvertisers,
    setSelectedAdvertisers,
    toAdvertiserQueryParam
  ] = useNullableStringSetQueryParam('advertisers', '---');

  const [
    selectedStatuses,
    setSelectedStatuses,
    toStatusQueryParam
  ] = useQueryParam(
    'status',
    (p) => new Set(p ? queryParamToList<SaleStatus>(p) : SALE_STATUSES),
    (ss) => (ss.size === SALE_STATUSES.length ? undefined : setToQueryParam(ss))
  );

  const [selectedTypes, setSelectedTypes, toTypeQueryParam] = useQueryParam(
    'sale_type',
    (p) =>
      new Set(
        p
          ? queryParamToList<SaleType>(p)
          : SALE_TYPES.filter((s) => s !== 'bonus')
      ),
    (ss) => (ss.size === SALE_TYPES.length ? undefined : setToQueryParam(ss))
  );

  const q: SalesFilterArgs = useMemo(() => {
    const filters: SalesFilterArgs = { dates: timeframeToMs(timeframe) };
    if (selectedStatuses.size !== SALE_STATUSES.length) {
      filters.sale_status = [...selectedStatuses];
    }
    if (selectedTypes.size !== SALE_TYPES.length) {
      filters.sale_type = [...selectedTypes];
    }
    if (selectedPartners && selectedPartners.size) {
      filters.partner_key = [...selectedPartners];
    }
    if (selectedAdvertisers && selectedAdvertisers.size) {
      filters.advertiser_name = [...selectedAdvertisers];
    }
    return filters;
  }, [
    timeframe,
    selectedPartners,
    selectedStatuses,
    selectedTypes,
    selectedAdvertisers
  ]);

  const [partnerList] = useKnownPartners(spaceId, q);
  const [advertisers] = useKnownAdvertisers(spaceId, q);
  const [partners, loading, error] = usePartnersWithCountsAndTrendsAndSales(
    spaceId,
    timeframe,
    q,
    compare,
    currency
  );

  const otherProps = useMemo(() => ({ compare }), [compare]);
  // Use this to show a message to new users about their product
  // analytics data
  const user = useCurrentUser();
  const isNewSpace =
    moment().diff(toMoment(user.space.createdAt), 'hours') < 48;

  if (error) {
    return (
      <Paper style={{ height: '100%' }}>
        <Centered height={HEIGHT}>Something went wrong.</Centered>
      </Paper>
    );
  }

  return (
    <PerformancePageBody noTopPadding>
      <PageToolbar sticky offset={DEFAULT_OFFSET}>
        <PageToolbarSection flex={1}>
          <Typography
            variant="h6"
            component="span"
            style={{
              marginRight: '3px',
              position: 'relative',
              fontWeight: 'bold',
              top: '-2px'
            }}
          >
            Platforms
          </Typography>
          <HelpIcon
            color="gray"
            articleId={ARTICLES.networks.overview}
            style={{ marginRight: '16px' }}
          ></HelpIcon>
          <ColumnSelector
            value={columnNames}
            onChange={setColumns}
            columns={COLUMNS}
          />
          <SalesFilter
            hiddenOptions={['advertisers']}
            partners={{
              partners: partnerList || EMPTY_ARR,
              value: selectedPartners,
              onChange: setSelectedPartners
            }}
            advertisers={{
              advertisers: advertisers || EMPTY_ARR,
              value: selectedAdvertisers,
              onChange: setSelectedAdvertisers
            }}
            statuses={{
              value: selectedStatuses,
              onChange: setSelectedStatuses
            }}
            types={{
              value: selectedTypes,
              onChange: setSelectedTypes
            }}
            onReset={() => {
              changeQuery({
                ...toStatusQueryParam(new Set(SALE_STATUSES)),
                ...toTypeQueryParam(new Set(SALE_TYPES)),
                ...toPartnerQueryParam(null),
                ...toAdvertiserQueryParam(null)
              });
            }}
          />
        </PageToolbarSection>
        <PageToolbarSection flex={1} justifyContent="flex-end">
          <TimeframePickerDense
            value={timeframe}
            onChange={setTimeframe}
            options={options}
          />
        </PageToolbarSection>
      </PageToolbar>

      {isNewSpace && (
        <AlertBox variant="success" style={{ marginBottom: '36px' }}>
          Your affiliate network and program-level analytics will begin
          collecting here.
          <br />
          <br />
          In the mean time, try{' '}
          <Link
            style={{ borderBottom: '1px solid black' }}
            to={ROUTES.performanceNew.transactions.url()}
          >
            setting up reporting
          </Link>{' '}
          with your affiliate networks and programs.
        </AlertBox>
      )}
      <Grid>
        <PartnerBarChartCard
          partners={selectedPartners || EMPTY_PARTNER_KEY_SET}
          spaceId={spaceId}
          timeframe={timeframe}
          height={300}
        />
        <PartnerChartCard
          partners={
            partners
              ? partners.filter((p) =>
                  selectedPartners ? selectedPartners.has(p.partner.key) : true
                )
              : undefined
          }
          loading={loading}
        />
      </Grid>
      {loading || !partners ? (
        <Paper>
          <Loader height={HEIGHT} />
        </Paper>
      ) : (
        <RowsRenderer
          columns={columns}
          sorter={sorter || SORTERS.total}
          sortDirection={direction}
          onHeadClick={(c, dir) => setSort([SORTERS[c.key] || null, dir])}
          rows={partners.filter((p) =>
            selectedPartners ? selectedPartners.has(p.partner.key) : true
          )}
          otherProps={otherProps}
          variant="contained"
          renderHead={true}
          headProps={{
            sticky: true,
            offset: DEFAULT_OFFSET + DEFAULT_TOOLBAR_HEIGHT
          }}
          rowToHref={(p) =>
            ROUTES.performanceNew.advertisers.overview.url({
              filters: [createPlatformFilterDefinition([p.partner.key])]
            })
          }
          rowToKey={ROW_TO_KEY}
          rowHeight={ROW_HEIGHTS.dense}
        />
      )}
    </PerformancePageBody>
  );
};

export const PagePerformanceNetworks = () => {
  useTrackMixpanelView('view_platforms');
  const [canView] = useHasCurrentUserRequiredScopes(['reports.platforms.view']);
  const [experimental] = useExperimentalContext();

  return (
    <>
      <Helmet>
        <title>Platforms | Affilimate</title>
      </Helmet>
      {canView ? (
        experimental ? (
          <PagePerformanceNetworksPg />
        ) : (
          <PagePerformanceNetworksV2 />
        )
      ) : (
        <PerformancePageBody>
          <NoPermissions />
        </PerformancePageBody>
      )}
    </>
  );
};
