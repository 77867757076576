import React, { useCallback, useMemo, useState } from 'react';
import {
  campaignGoal,
  campaignQueryBase,
  isCampaignRunning,
  ReadyCampaign
} from '../service';
import { TimeseriesChart } from '../../../components/analytics_v2/Chart/TimeseriesChart';
import { xAxis } from '../../../components/analytics_v2/Chart/xAxis';
import { yAxis } from '../../../components/analytics_v2/Chart/yAxis';
import { barSeries } from '../../../components/analytics_v2/Chart/barSeries';
import { metricTitle } from '../../../services/analyticsV2/metrics';
import { COLORS } from '../../../domainTypes/colors';
import { tooltip } from '../../../components/analytics_v2/Chart/tooltip';
import { Paper } from '@material-ui/core';
import {
  AnalyticsField,
  AnalyticsInterval,
  AnalyticsQuery,
  AnalyticsResponseRowWithComparison
} from '../../../domainTypes/analytics_v2';
import { useCurrentUser } from '../../../services/currentUser';
import { useMappedLoadingValue } from '../../../services/db';
import { useAnalyticsQueryV2 } from '../../../services/analyticsV2/query';
import { css } from '../../../emotion';
import Typography from '@material-ui/core/Typography';
import { Loader } from '../../../components/Loader';
import { Centered } from '../../../layout/Centered';
import { AxisDomain, ReferenceLine } from 'recharts';
import { timeseriesReferences } from './TimeseriesReferences';
import { Legend } from '../../../components/Charts/Util';
import { FlexContainer } from '../../../layout/Flex';
import {
  IntervalSelector,
  useInterval
} from '../../../components/analytics_v2/IntervalSelector';
import {
  cumulativeSum,
  cumulativeSumGrouped,
  SummedSeries
} from '../service/aggregate';
import { COLOR_UNKNOWN } from '../../../services/color';
import { getKnownPartnerForKey } from '../../../services/partner';
import { isNil } from 'lodash';
import { ExportQueryButton } from '../../../components/ExportQuery';
import { useQueryParam } from '../../../routes';
import { ChartCardFooter } from '../../../components/Charts/ChartCard';

type ChartMode = 'raw' | 'aggregated';

const chartMetric = 'gmv_sum_net';

const aggregateRows = (
  rows: AnalyticsResponseRowWithComparison[],
  mode: ChartMode,
  groupBy: AnalyticsField | undefined
): SummedSeries => {
  if (mode === 'raw') {
    return {
      series: rows,
      sum: 0
    };
  }
  if (isNil(groupBy)) {
    return cumulativeSum(rows, chartMetric);
  }
  return cumulativeSumGrouped(rows, chartMetric, groupBy);
};

const campaignQuery = (
  campaign: ReadyCampaign,
  interval: AnalyticsInterval,
  groupBy: AnalyticsField | undefined
): AnalyticsQuery => ({
  ...campaignQueryBase(campaign),
  select: [chartMetric],
  groupBy: groupBy ? [groupBy] : [],
  interval,
  orderBy: [{ field: 'interval', direction: 'DESC' }]
});

const useCampaignTimeseries = (
  campaign: ReadyCampaign,
  interval: AnalyticsInterval,
  groupBy: AnalyticsField | undefined,
  mode: ChartMode
) => {
  const { space } = useCurrentUser();
  const query = useMemo<AnalyticsQuery>(
    () => campaignQuery(campaign, interval, groupBy),
    [campaign, groupBy, interval]
  );
  const mapper = useCallback(
    (response) => aggregateRows(response.rows, mode, groupBy),
    [groupBy, mode]
  );
  return useMappedLoadingValue(
    useAnalyticsQueryV2(space.id, query),
    mapper,
    true
  );
};

const HEIGHT = 250;

export const CampaignTimeseries: React.FC<{
  campaign: ReadyCampaign;
}> = ({ campaign, children }) => {
  const [mode, setMode] = useState<ChartMode>('aggregated');
  const [split, setSplit] = useQueryParam<'pk' | 'none'>(
    'split',
    (split) => (split === 'pk' ? 'pk' : 'none'),
    (split) => split
  );
  const field = useMemo<AnalyticsField | undefined>(
    () => (split === 'none' ? undefined : split),
    [split]
  );
  const interval = useInterval();
  const [data] = useCampaignTimeseries(campaign, interval, field, mode);

  const goal = campaignGoal(campaign);
  const domain = useMemo<Readonly<[AxisDomain, AxisDomain]>>(() => {
    if (mode === 'raw') return [0, 'dataMax'];
    return [0, (max: number) => Math.ceil(Math.max(max, goal) / 10) * 10];
  }, [goal, mode]);

  const seriesName = useCallback(
    (fieldValue) =>
      split === 'none'
        ? metricTitle(chartMetric)
        : getKnownPartnerForKey(fieldValue)?.name ?? fieldValue,
    [split]
  );

  const seriesColor = useCallback(
    (fieldValue) =>
      split === 'none'
        ? COLORS.blue.blue3
        : getKnownPartnerForKey(fieldValue)?.color ?? COLOR_UNKNOWN,
    [split]
  );

  return (
    <Paper>
      <div
        className={css((t) => ({
          display: 'grid',
          position: 'relative',
          gridTemplateColumns: '1fr 300px',
          paddingTop: t.spacing(4),
          paddingLeft: t.spacing(4),
          paddingBottom: t.spacing(8),
          paddingRight: t.spacing(4),
          columnGap: t.spacing(2)
        }))}
      >
        {data ? (
          <TimeseriesChart
            data={data.series}
            metrics={[chartMetric]}
            field={field}
            ResponsiveContainerProps={{ height: HEIGHT }}
            ComposedChartProps={{
              margin: { top: 25, right: 5, left: 20, bottom: 5 }
            }}
          >
            {({ context, fieldValues }) => {
              const references =
                mode === 'aggregated'
                  ? timeseriesReferences(chartMetric, {
                      sum: data.sum,
                      goal: goal,
                      start: data.intervalAfterNow,
                      end: data.lastInterval
                    })
                  : [];
              return [
                xAxis(context, interval.unit, {
                  textAnchor: 'end',
                  tickLine: false,
                  minTickGap: 120,
                  tick: {
                    transform: 'translate(0, 12)'
                  }
                }),
                yAxis(chartMetric, 'right', context, {
                  domain
                }),
                <ReferenceLine
                  y={0}
                  yAxisId={chartMetric}
                  xAxisId="timestamp"
                  stroke="#bbb"
                />,
                barSeries(fieldValues, chartMetric, {
                  name: seriesName,
                  fill: seriesColor,
                  maxBarSize: 48
                }),
                tooltip(
                  context,
                  'day',
                  undefined,
                  split === 'none'
                    ? {
                        grouper: undefined
                      }
                    : undefined
                ),
                ...references
              ];
            }}
          </TimeseriesChart>
        ) : (
          <Centered height={HEIGHT}>
            <Loader size={32} />
          </Centered>
        )}
        <FlexContainer direction="column" alignItems="flex-start">
          <FlexContainer
            alignItems="center"
            className={css(() => ({
              width: '100%',
              marginBottom: 12
            }))}
          >
            <Typography
              variant="body1"
              style={{ fontWeight: 'bold', flexGrow: 1 }}
            >
              Campaign {isCampaignRunning(campaign) ? 'progress' : 'summary'}
            </Typography>
            <IntervalSelector />
            <ExportQueryButton
              title={`Download performance per ${interval.unit}`}
              reportType="campaigns"
              query={campaignQuery(campaign, interval, field)}
            />
          </FlexContainer>
          {children}
          <ChartCardFooter>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Legend
                items={[
                  {
                    shape: 'circle',
                    color: '#444',
                    label: 'Cumulatively',
                    active: mode === 'aggregated',
                    onClick: () => setMode('aggregated')
                  },
                  {
                    shape: 'circle',
                    color: '#444',
                    label: `Per ${interval.unit}`,
                    active: mode === 'raw',
                    onClick: () => setMode('raw')
                  }
                ]}
              />
              <Legend
                items={[
                  {
                    shape: 'circle',
                    color: '#444',
                    label: 'Total GMV',
                    active: split === 'none',
                    onClick: () => setSplit('none')
                  },
                  {
                    shape: 'circle',
                    color: '#444',
                    label: 'GMV by platform',
                    active: split === 'pk',
                    onClick: () => setSplit('pk')
                  }
                ]}
              />
            </div>
          </ChartCardFooter>
        </FlexContainer>
      </div>
    </Paper>
  );
};
