import { Paper, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { createTagsFilterDefinition } from '../../../../../../components/analytics_v2/Filters/filters';
import { useSites } from '../../../../../../components/analytics_v2/SiteSelector';
import {
  ALL_COLUMN_NAMES,
  ColumnDefinitions,
  DEFAULT_VISIBLE_COLUMN_NAMES,
  TableMetadata,
  useTable
} from '../../../../../../components/analytics_v2/Table';
import { useNonEmptyTagsFilter } from '../../../../../../components/analytics_v2/TagsSelector';
import { useTimeframe } from '../../../../../../components/analytics_v2/Timeframe';
import { CustomPagination } from '../../../../../../components/CustomPagination';
import { ExportQueryButton } from '../../../../../../components/ExportQuery';
import {
  RowsRenderer,
  RowsRendererProps,
  ROW_HEIGHTS
} from '../../../../../../components/GroupableList';
import { Loader } from '../../../../../../components/Loader';
import { ColumnSelector } from '../../../../../../components/Table/ColumnSelector';
import { WithHoverIndicator } from '../../../../../../components/WithHoverIndicator';
import {
  AnalyticsOrderBy,
  AnalyticsQuery,
  AnalyticsResponseRowWithComparison
} from '../../../../../../domainTypes/analytics_v2';
import { Color, getColorDef } from '../../../../../../domainTypes/colors';
import { styled } from '../../../../../../emotion';
import { Centered } from '../../../../../../layout/Centered';
import { FlexContainer } from '../../../../../../layout/Flex';
import { DEFAULT_OFFSET } from '../../../../../../layout/PageToolbar';
import { useRoutes } from '../../../../../../routes';
import { Metric } from '../../../../../../services/analyticsV2/metrics';
import { useAnalyticsQueryV2 } from '../../../../../../services/analyticsV2/query';
import { useCurrentUser } from '../../../../../../services/currentUser';
import { useMappedLoadingValue } from '../../../../../../services/db';
import { useMixpanel } from '../../../../../../services/mixpanel';
import { IPostgresTagsParent } from '../../../../../../services/tags';
import { useResultsWithTags } from '../service/merge-with-tags';
import { TagErrorMessage } from './TagErrorMessage';

const customColumns = ['tag'] as const;
type CustomColumns = typeof customColumns[number];
type Column = CustomColumns | Metric;

interface TagMetricsTableProps {
  tagGroup: IPostgresTagsParent;
}

const availableColumns: Column[] = [
  ...customColumns,
  'count_uniq_page_url',
  ...ALL_COLUMN_NAMES
];
const defaultVisibleColumns: Column[] = [
  ...customColumns,
  'count_uniq_page_url',
  ...DEFAULT_VISIBLE_COLUMN_NAMES
];

const Swatch = styled('div')`
  width: 16px;
  height: 16px;
  border-radius: 99px;
`;

const TagColorWrapper = styled('div')`
  display: flex;
  gap: ${(p) => p.theme.spacing(0.8)}px;
  align-items: center;
`;

const TagGroupName = styled<typeof Typography>(Typography)`
  font-size: 12px !important;
  margin-left: 22px !important;
  font-weight: bold !important;
  opacity: 0.6;
`;

const columnDefinitions: ColumnDefinitions<CustomColumns> = {
  tag: {
    column: {
      key: 'tag',
      head: () => 'Tag and group',
      cell: (p: AnalyticsResponseRowWithComparison) => (
        <>
          <WithHoverIndicator>
            <TagColorWrapper>
              <Swatch
                style={{
                  backgroundColor: getColorDef(p.group.tagColor as Color)
                    .bgColor
                }}
              />
              {p.group.tagName}
            </TagColorWrapper>
          </WithHoverIndicator>
          <TagGroupName color="textSecondary">
            {p.group.tagGroupName}
          </TagGroupName>
        </>
      ),
      align: 'left',
      sortable: false,
      width: 200,
      flexGrow: 4
    },
    sorter: {
      key: 'tag',
      items: {
        sort: (p: AnalyticsResponseRowWithComparison) => p.group.tags,
        dir: 'asc'
      }
    }
  }
};

const useTagsCount = (tagGroup: IPostgresTagsParent) => {
  const { space } = useCurrentUser();
  const { range } = useTimeframe();
  const sites = useSites();
  const tags = useNonEmptyTagsFilter(tagGroup);

  const query = useMemo<AnalyticsQuery>(() => {
    return {
      range,
      filters: [
        sites,
        tags,
        {
          field: 'parent_tags',
          condition: 'in',
          values: [tagGroup.parent.id]
        }
      ],
      select: ['count_uniq_tags']
    };
  }, [range, sites, tagGroup.parent.id, tags]);

  return useMappedLoadingValue(
    useAnalyticsQueryV2(space.id, query),
    (data) => data.rows[0]?.data.count_uniq_tags?.curr ?? 0
  );
};

const useTagsQuery = (
  tagGroup: IPostgresTagsParent,
  metrics: Metric[],
  orderBy: AnalyticsOrderBy,
  paginate?: AnalyticsQuery['paginate'],
  includePrevious = false
) => {
  const { range, compare } = useTimeframe();
  const sites = useSites();
  const tags = useNonEmptyTagsFilter(tagGroup);

  return useMemo<AnalyticsQuery>(() => {
    return {
      range,
      compare: includePrevious ? compare : undefined,
      groupBy: ['tags'],
      orderBy: [orderBy],
      filters: [
        sites,
        tags,
        {
          field: 'parent_tags',
          condition: 'in',
          values: [tagGroup.parent.id]
        }
      ],
      select: [...metrics, 'count_uniq_page_url'],
      paginate
    };
  }, [
    compare,
    includePrevious,
    metrics,
    orderBy,
    paginate,
    range,
    sites,
    tagGroup.parent.id,
    tags
  ]);
};

const useTagAnalyticsTotals = (
  tagGroup: IPostgresTagsParent,
  metrics: Metric[],
  orderBy: AnalyticsOrderBy,
  paginate: AnalyticsQuery['paginate']
) => {
  const { space } = useCurrentUser();
  const query = useTagsQuery(tagGroup, metrics, orderBy, paginate, true);

  return useResultsWithTags(
    useAnalyticsQueryV2(space.id, query, { logLabel: 'tags_analytics_totals' })
  );
};

const useExportQuery = (
  tagGroup: IPostgresTagsParent,
  metrics: Metric[],
  orderBy: AnalyticsOrderBy
): AnalyticsQuery => {
  return useTagsQuery(tagGroup, metrics, orderBy);
};

type TableProps = Pick<
  RowsRendererProps<AnalyticsResponseRowWithComparison, Column, TableMetadata>,
  'columns' | 'sorter' | 'sortDirection' | 'onHeadClick' | 'otherProps'
>;

type Totals = ReturnType<typeof useTagAnalyticsTotals>;

const InnerTable = ({
  totals,
  tableProps
}: {
  tableProps: TableProps;
  totals: Totals;
}) => {
  const { ROUTES } = useRoutes();
  const mixPanel = useMixpanel();
  const [data, loading, error] = totals;
  if (error) {
    return (
      <Paper>
        <Centered height={300}>
          <TagErrorMessage />
        </Centered>
      </Paper>
    );
  }

  if (loading || !data) {
    return (
      <Paper>
        <Centered height={300}>
          <Loader size={24} />
        </Centered>
      </Paper>
    );
  }

  return (
    <>
      <RowsRenderer
        {...tableProps}
        renderHead
        rows={data.rows}
        headProps={{
          sticky: true,
          offset: DEFAULT_OFFSET
        }}
        variant="contained"
        rowHeight={ROW_HEIGHTS.dense}
        rowToHref={(d) =>
          ROUTES.content.overview_v2.url([
            createTagsFilterDefinition([d.group.tags])
          ])
        }
        onRowClick={(d) => {
          mixPanel.track('click_tag_filter_row', { tag: d.group.tags });
        }}
        rowToKey={(d) => d.group.tags}
      />
    </>
  );
};

const PAGE_SIZE = 10;

export const TagMetricsTable: React.FC<TagMetricsTableProps> = ({
  tagGroup
}) => {
  const {
    tableProps,
    metrics,
    paginationSelectorProps,
    pagination,
    orderBy,
    columnSelectorProps
  } = useTable(availableColumns, columnDefinitions, {
    pageSize: PAGE_SIZE,
    defaultSortColumn: 'c',
    defaultVisibleColumns
  });

  const exportQuery = useExportQuery(tagGroup, metrics, orderBy);
  const totals = useTagAnalyticsTotals(tagGroup, metrics, orderBy, pagination);
  const [count = 1] = useTagsCount(tagGroup);

  return (
    <>
      <FlexContainer justifyContent="space-between">
        <div>
          <Typography
            variant="body1"
            component="p"
            style={{ fontWeight: 'bold' }}
          >
            Tag metrics
          </Typography>
          <Typography
            variant="body2"
            component="p"
            color="textSecondary"
            paragraph
          >
            Compare site performance during this period according to your key
            metrics
          </Typography>
        </div>
        <FlexContainer>
          <ColumnSelector {...columnSelectorProps} />
          <CustomPagination
            {...paginationSelectorProps}
            siblingCount={0}
            count={Math.ceil(count / PAGE_SIZE)}
          />
          <ExportQueryButton
            title="Tags"
            reportType="tags"
            query={exportQuery}
          />
        </FlexContainer>
      </FlexContainer>
      <Paper>
        <InnerTable totals={totals} tableProps={tableProps} />
      </Paper>
    </>
  );
};
