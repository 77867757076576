import {
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useMemo } from 'react';
import { Edit, Info, RefreshCw } from 'react-feather';
import { Helmet } from 'react-helmet';
import {
  PRODUCT_TABS,
  TrafficChart
} from '../../../../components/Charts/TrafficChart';
import {
  DetailCard,
  DetailCardHeadline,
  SectionSubheading
} from '../../../../components/DetailsPage';
import { IconButtonWithPromise } from '../../../../components/IconButtonWithPromise';
import { LinkExternal } from '../../../../components/LinkExternal';
import { Loader } from '../../../../components/Loader';
import {
  useStandardOptions,
  useTimeframeFromUrl
} from '../../../../components/TimeframePicker';
import { isSameTimeframe, Timeframe } from '../../../../domainTypes/analytics';
import { Doc } from '../../../../domainTypes/document';
import {
  getDestinationUrlForProduct,
  IProduct
} from '../../../../domainTypes/product';
import { styled } from '../../../../emotion';
import { useDialogState } from '../../../../hooks/useDialogState';
import { Section } from '../../../../layout/Section';
import { useRoutes } from '../../../../routes';
import {
  allTime,
  multiplyTimeframe,
  useCountsInTimeframeForProductFs,
  useCountsInTimeframeForProductPg
} from '../../../../services/analytics';
import { useCurrentUser } from '../../../../services/currentUser';
import { useTrackMixpanelView } from '../../../../services/mixpanel';
import {
  tryUpdateDestinationUrlForProduct,
  updateProduct,
  useProduct
} from '../../../../services/products';
import { usePostgres } from '../../../../services/sales/service';
import { COLORS } from '../../../../domainTypes/colors';
import {
  ClickCountCardProductFs,
  ClickCountCardProductPg
} from '../../../Analytics/ClickCountCard';
import { DetailsNotFound } from '../../components/DetailsNotFound';
import { DetailsToolbar } from '../../components/DetailsToolbar';
import { PageBodyLinkDetails } from '../../components/PageBodyLinkDetails';
import { ProductEditForm } from '../Overview/components/ProductEditForm';
import { PARTNERS } from '../../../../domainTypes/partners';

type CommonProps = {
  productId: string;
  timeframe: Timeframe;
};

type Comparable = {
  compare: boolean;
};

const TrafficFs = ({
  productId,
  timeframe,
  compare
}: CommonProps & Comparable) => {
  const [value, loading] = useCountsInTimeframeForProductFs(
    productId,
    compare ? multiplyTimeframe(timeframe, 2) : timeframe
  );
  return (
    <DetailCard style={{ gridArea: 'traffic' }}>
      <DetailCardHeadline title="Traffic overview" />
      <SectionSubheading>
        How many clicks and impressions your link has received in the selected
        timeframe.
      </SectionSubheading>
      <TrafficChart
        loading={loading}
        counters={value}
        margin="dense"
        compare={compare}
        tabs={PRODUCT_TABS}
        height={300}
      />
    </DetailCard>
  );
};

const TrafficPg = ({
  spaceId,
  productId,
  timeframe,
  compare
}: CommonProps & Comparable & { spaceId: string }) => {
  const [value, loading] = useCountsInTimeframeForProductPg(
    spaceId,
    productId,
    compare ? multiplyTimeframe(timeframe, 2) : timeframe
  );
  return (
    <DetailCard style={{ gridArea: 'traffic' }}>
      <DetailCardHeadline title="Traffic overview" />
      <SectionSubheading>
        How many clicks and impressions your link has received in the selected
        timeframe.
      </SectionSubheading>
      <TrafficChart
        loading={loading}
        counters={value}
        margin="dense"
        compare={compare}
        tabs={PRODUCT_TABS}
        height={300}
      />
    </DetailCard>
  );
};

const Issues = ({ p }: { p: Doc<IProduct> }) => {
  return null;
};

const InfoGrid = styled('div')`
  display: grid;
  grid-template-columns: 2fr 7fr 32px;
  grid-gap: ${(p) => p.theme.spacing(1)}px;
  row-gap: ${(p) => p.theme.spacing(3)}px;
  font-size: ${(p) => p.theme.custom.fontSize.m}px;
  padding: ${(p) => p.theme.spacing(2)}px ${(p) => p.theme.spacing(1)}px;
  align-items: top;
`;

const InfoGridItemTitle = styled('div')`
  color: ${(p) => p.theme.palette.grey[900]};
  font-size: ${(p) => p.theme.typography.body1.fontSize}px;
  display: flex;
  align-items: center;

  svg {
    position: relative;
    right: -3px;
    top: 2px;
  }
`;

const Ellided = styled('div')`
  min-width: 0;
  max-width: 800px;
  overflow-wrap: break-word;
`;

const ICON_SIZE = 16;

const ProductUrlRow = ({ p }: { p: Doc<IProduct> }) => {
  const { ROUTES, goTo } = useRoutes();
  const { dialogOpen, openDialog, closeDialog } = useDialogState(false);
  const { enqueueSnackbar } = useSnackbar();
  const submit = (nextProduct: Doc<IProduct>) =>
    updateProduct(nextProduct.id, nextProduct.data)
      .then(() => {
        enqueueSnackbar('Link updated!', { variant: 'success' });
        closeDialog();
      })
      .catch(() => {
        enqueueSnackbar('Link could not be updated', { variant: 'error' });
      });
  return (
    <>
      <InfoGridItemTitle>URL</InfoGridItemTitle>
      <Ellided>
        <LinkExternal
          href={p.data.url}
          title={p.data.url}
          style={{ whiteSpace: 'initial', color: COLORS.blue.blue6 }}
        >
          {p.data.url}
        </LinkExternal>
      </Ellided>
      <div>
        <Tooltip
          placement="left"
          title="Edit the URL of this link while preserving its analytics history. Does not edit the link on your website."
        >
          <IconButton onClick={openDialog}>
            <Edit size={ICON_SIZE} />
          </IconButton>
        </Tooltip>
      </div>

      <Dialog open={dialogOpen} onClose={closeDialog} maxWidth="md">
        <DialogTitle>Edit URL</DialogTitle>
        <DialogContent>
          <ProductEditForm
            product={p}
            onCancel={closeDialog}
            onDeleteSuccess={() => goTo(ROUTES.links.overview.url())}
            onSubmit={submit}
            noNameEdits
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

const ProductDestUrlRow = ({ p }: { p: Doc<IProduct> }) => {
  const { enqueueSnackbar } = useSnackbar();
  const destUrl = getDestinationUrlForProduct(p.data);
  const isDynamicSkimlink = useMemo(() => {
    if (p.data.partnerKey !== 'skimlinks') {
      return false;
    }
    const skimlinksConfig = PARTNERS.find((p) => p.key === 'skimlinks');
    if (!skimlinksConfig) {
      return false;
    }
    // If it's a skimlinks link where the destination URL
    // doesn't look like Skimlinks but the partner_key is set
    // to Skimlinks, assume it's a dynamic Skimlinks link
    // And do not allow the user to update its partnerkey/destination.
    return (
      !skimlinksConfig.matches(p.data.url) && !skimlinksConfig.matches(destUrl)
    );
  }, [destUrl, p.data.partnerKey, p.data.url]);

  return (
    <>
      <InfoGridItemTitle>
        Destination URL
        <Tooltip
          title="For cloaked or shortened affiliate links, the destination URL is what's used to determine which SubIDs to append to your affiliate links. For long links, this is the same as the link on your site."
          placement="top"
        >
          <span>
            <Info size={14} />
          </span>
        </Tooltip>
      </InfoGridItemTitle>
      <Ellided>
        <LinkExternal
          href={destUrl}
          title={destUrl}
          style={{ whiteSpace: 'initial', color: COLORS.blue.blue6 }}
        >
          {destUrl}
        </LinkExternal>
      </Ellided>

      <div>
        <Tooltip
          placement="left"
          title="Check for updates the to destination URL of this affiliate link. Keeping this up-to-date helps us append the correct Smart Label SubID for your affiliate links."
        >
          <div>
            <Tooltip
              title={
                isDynamicSkimlink
                  ? `Dynamically imported Skimlinks can't have their destination URL updated`
                  : 'Update the destination URL for this link.'
              }
            >
              <IconButtonWithPromise
                disabled={isDynamicSkimlink}
                pendingSize={ICON_SIZE}
                onClick={async () => {
                  return tryUpdateDestinationUrlForProduct(p).then(
                    (wasUpdated) => {
                      if (wasUpdated) {
                        enqueueSnackbar('Destination URL updated!', {
                          variant: 'success'
                        });
                      } else {
                        enqueueSnackbar(
                          'Destination URL is already up-to-date.',
                          {
                            variant: 'info'
                          }
                        );
                      }
                    }
                  );
                }}
              >
                <RefreshCw size={ICON_SIZE} />
              </IconButtonWithPromise>
            </Tooltip>
          </div>
        </Tooltip>
      </div>
    </>
  );
};

const PageLinkDetailsOverviewFs = ({ productId }: { productId: string }) => {
  const [product, loading] = useProduct(productId);
  const { options, defaultOption } = useStandardOptions();
  const [timeframe, setTimeframe] = useTimeframeFromUrl(defaultOption.value);
  const compare = !isSameTimeframe(allTime(), timeframe);

  return (
    <PageBodyLinkDetails productId={productId} noTopPadding>
      <DetailsToolbar
        product={product}
        timeframe={timeframe}
        options={options}
        setTimeframe={setTimeframe}
      />
      {loading && <Loader height={500} />}
      {product === null && <DetailsNotFound />}
      {product && (
        <>
          <Section>
            <Card>
              <InfoGrid>
                <ProductUrlRow p={product} />
                <ProductDestUrlRow p={product} />
              </InfoGrid>
            </Card>
          </Section>
          {!!product.data.issues.filter((i) => !i.resolved).length && (
            <Section>
              <Issues p={product} />
            </Section>
          )}
          <Section>
            <ClickCountCardProductFs productId={productId} />
          </Section>
          <Section>
            <TrafficFs
              productId={productId}
              timeframe={timeframe}
              compare={compare}
            />
          </Section>
        </>
      )}
    </PageBodyLinkDetails>
  );
};

const PageLinkDetailsOverviewPg = ({ productId }: { productId: string }) => {
  const { space } = useCurrentUser();
  const [product, loading] = useProduct(productId);
  const { options, defaultOption } = useStandardOptions();
  const [timeframe, setTimeframe] = useTimeframeFromUrl(defaultOption.value);
  const compare = !isSameTimeframe(allTime(), timeframe);

  return (
    <PageBodyLinkDetails productId={productId} noTopPadding>
      <DetailsToolbar
        product={product}
        timeframe={timeframe}
        options={options}
        setTimeframe={setTimeframe}
      />
      {loading && <Loader height={500} />}
      {product === null && <DetailsNotFound />}
      {product && (
        <>
          <Section>
            <Card>
              <InfoGrid>
                <ProductUrlRow p={product} />
                <ProductDestUrlRow p={product} />
              </InfoGrid>
            </Card>
          </Section>
          {!!product.data.issues.filter((i) => !i.resolved).length && (
            <Section>
              <Issues p={product} />
            </Section>
          )}
          <Section>
            <ClickCountCardProductPg spaceId={space.id} productId={productId} />
          </Section>
          <Section>
            <TrafficPg
              spaceId={space.id}
              productId={productId}
              timeframe={timeframe}
              compare={compare}
            />
          </Section>
        </>
      )}
    </PageBodyLinkDetails>
  );
};

export const PageLinkDetailsOverview = (props: { productId: string }) => {
  const pg = usePostgres();
  useTrackMixpanelView('view_link_details', { link_id: props.productId });

  return (
    <>
      <Helmet>
        <title>Link Details | Affilimate</title>
      </Helmet>
      {pg ? (
        <PageLinkDetailsOverviewPg {...props} />
      ) : (
        <PageLinkDetailsOverviewFs {...props} />
      )}
    </>
  );
};
