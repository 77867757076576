/* eslint-disable */

import firebase from 'firebase/app';
import { compact, groupBy, keyBy, mapValues, sortBy } from 'lodash';
import moment from 'moment-timezone';
import { useCallback, useMemo } from 'react';
import { toPercent } from '../../components/Number';
import {
  aggregateDailyCounters,
  DAY_FORMAT,
  EMPTY_COUNTER,
  EMPTY_DAILY_COUNTER,
  EMPTY_DEVICE_COUNTER,
  EMPTY_DEVICE_SHORT_COUNTER,
  EMPTY_SHORT_COUNTER,
  ICounter,
  ICounterWithTrend,
  IDailyCounter,
  IDeviceCounter,
  IPageCounter,
  mergeCounter,
  PageAnalyticsQuery,
  reduceTrendCounters,
  shortCounterToCounter,
  Timeframe,
  TIMEKEY_FORMAT,
  ICountWithTrend,
  ProductAnalyticsQuery
} from '../../domainTypes/analytics';
import {
  IDenormalizedPage,
  IDenormalizedProduct
} from '../../domainTypes/denormalization';
import { IPageWithCountsAndTrends } from '../../domainTypes/page';
import { IProductIssue } from '../../domainTypes/product';
import { ISpace } from '../../domainTypes/space';
import { usePromise } from '../../hooks/usePromise';
import { toChecksum } from '../checksum';
import {
  getCurrentSpace,
  getCurrentUser,
  useCurrentUser
} from '../currentUser';
import {
  LoadingValue,
  LoadingValueExtended,
  LoadingValueLike,
  refreshTimestamp,
  useMappedLoadingValue
} from '../db';
import { callFirebaseFunction } from '../firebaseFunctions';
import { splitInHalf } from '../list';
import { getAverage, getMedian, getPercentile } from '../math';
import { getPageAnalytics, getPageAnalyticsAsTimeseries } from '../pages';
import {
  getProductAnalyticsAsCountMap,
  getProductAnalyticsAsTimeseries
} from '../products';
import {
  getTimeKeyRangeFromTimeframe,
  INTERVALS_TO_TK,
  tkRangeFromTks,
  tksToTimeframe
} from '../time';
import {
  addToDeviceCounter,
  getTimekeyRangeFromDayFormat,
  padDailyCounters,
  toCountryCounters,
  toProductCounters,
  useDenormalizedCountsInTimeframe,
  useDenormalizedCountsInTimeframeByProtocollessDomain,
  useDenormalizedCountsInTimeframeForPage,
  useDenormalizedCountsInTimeframeForPartner,
  useDenormalizedCountsInTimeframeForProduct,
  useDenormalizedCountsInTimeframePerPageForProducts,
  useDenormalizedCountsinTimeframePerProductForPage,
  useDenormalizedPageCountsInTimeFrame,
  useDenormalizedProductCountsInTimeframe,
  usePartnerFilter
} from './denormalization';
import { AnalyticsIntervalUnit } from '../../domainTypes/analytics_v2';

const EMPTY_ARR = () => [];

export type SignificanceReferencevalue = 'count' | 'lastCount';

export const getSignificantPages = (
  pages: IPageWithCountsAndTrends[],
  field: SignificanceReferencevalue
) => {
  const getViews = (p: IPageWithCountsAndTrends) => p.counts.pageViews[field];
  const dynamicThreshold = Math.round(
    (Math.max(...pages.map(getViews)) / 100) * 5
  );
  const prefilteredPages = pages.filter((p) => getViews(p) > dynamicThreshold);
  const views = prefilteredPages.map(getViews);
  const median = getMedian(views);
  const average = getAverage(views);
  const threshold = Math.min(median, average);
  return prefilteredPages.filter((p) => getViews(p) >= threshold);
};

export const getPagesAbovePercentile = (
  pages: IPageWithCountsAndTrends[],
  percentile: number
) => {
  const getViews = (p: IPageWithCountsAndTrends) => p.counts.pageViews.count;
  const threshold = getPercentile(percentile, pages.map(getViews));
  return pages.filter((p) => getViews(p) >= threshold);
};

export const getTrend = (before: number, after: number) => {
  if (!after && !before) {
    return 0;
  }
  if (!before) {
    return 1;
  }
  return toPercent(after, before) - 1;
};

export const getViewRatio = (counter: {
  viewed: number;
  served: number;
}): number => {
  return toPercent(counter.viewed, counter.served);
};

export const getClickRatio = (counter: {
  clicked: number;
  viewed: number;
}): number => {
  return toPercent(counter.clicked, counter.viewed);
};

export const getCpm = (counter: {
  pageViews: number;
  clicked: number;
}): number => {
  if (counter.pageViews === 0) {
    return 0;
  }

  return counter.clicked / counter.pageViews;
};

export const getCommissionRate = (
  commission: number,
  salesVolume: number
): number => {
  if (salesVolume === 0) {
    return 0;
  }
  return commission / salesVolume;
};

export const getRpm = (pageViews: number, revenueInCents: number) => {
  if (!pageViews || pageViews === 0) {
    return 0;
  }
  return revenueInCents ? (revenueInCents / pageViews) * 1000 : 0;
};

export const getEpc = (clicks: number, revenueInCents: number) => {
  if (!clicks) {
    return 0;
  }
  return revenueInCents ? revenueInCents / clicks : 0;
};

export const getAvgComm = (quantity: number, revenueInCents: number) => {
  if (!quantity || revenueInCents === 0) {
    return 0;
  }
  return revenueInCents ? revenueInCents / quantity : 0;
};

export const getAov = (orders: number, revenueInCents: number) => {
  if (!orders || revenueInCents === 0) {
    return 0;
  }
  return revenueInCents ? revenueInCents / orders : 0;
};

export const formatTimeKey = (timeKey: string, format: string = 'MMM DD') => {
  const year = +timeKey.slice(0, 4);
  const dayOfYear = +timeKey.slice(4);
  return moment().year(year).dayOfYear(dayOfYear).format(format);
};

export const getTimeKeyRange = (
  start: moment.Moment,
  end: moment.Moment
): string[] => {
  const range = diffDays(start, end);
  const result: string[] = [];
  for (let i = 0; i < range; i++) {
    const x = start.clone().add(i, 'days').format(TIMEKEY_FORMAT);
    result.push(x);
  }
  return result;
};

export const getTimeKeyRangeWithInterval = (
  start: moment.Moment,
  end: moment.Moment,
  interval: AnalyticsIntervalUnit
): string[] => {
  const range = Math.abs(start.diff(end, interval));
  const result: string[] = [];
  for (let i = 0; i < range; i++) {
    const x = start.clone().add(i, interval).format(INTERVALS_TO_TK[interval]);
    result.push(x);
  }
  return result;
};

export const padCountsForTimeframe = (
  counts: IDailyCounter[],
  timeframe: Timeframe
): IDailyCounter[] => {
  const start = moment.tz(timeframe.start, DAY_FORMAT, timeframe.tz);
  const end = moment.tz(timeframe.end, DAY_FORMAT, timeframe.tz);
  const days = diffDays(start, end);

  if (counts.length === days) {
    return counts;
  }

  const range = getTimeKeyRange(start, end);
  return padCounts(counts, range);
};

const padCounts = (
  counts: IDailyCounter[],
  timeKeyRange: string[]
): IDailyCounter[] => {
  if (counts.length === timeKeyRange.length) {
    return counts;
  }

  const dict = keyBy(counts, (t) => t.timeKey);
  return timeKeyRange.map((timeKey) => {
    return dict[timeKey] || EMPTY_DAILY_COUNTER(timeKey);
  });
};

export const allTime_ = (space: ISpace, tz: string): Timeframe => {
  const start = moment(space.createdAt.toDate())
    .tz(tz)
    .startOf('day')
    .format(DAY_FORMAT);
  const end = moment().tz(tz).startOf('day').format(DAY_FORMAT);
  return { start, end, tz };
};

export const allTime = (tz?: string): Timeframe => {
  return allTime_(getCurrentSpace(), tz || getCurrentUser().tz);
};

export const today = (tz?: string): Timeframe => {
  tz = tz || getCurrentUser().tz;
  const start = moment.tz(tz).startOf('day');
  const end = moment(start).add(1, 'days');
  return {
    start: start.format(DAY_FORMAT),
    end: end.format(DAY_FORMAT),
    tz
  };
};

export const timeToDate = (unit: 'month' | 'year', tz?: string): Timeframe => {
  tz = tz || getCurrentUser().tz;
  const end = moment.tz(tz);
  const start = moment(end).startOf(unit);
  return {
    start: start.format(DAY_FORMAT),
    end: end.format(DAY_FORMAT),
    tz
  };
};

export const lastTimeframe = (
  n: number,
  unit: 'day' | 'month' | 'year',
  tz?: string
): Timeframe => {
  tz = tz || getCurrentUser().tz;
  const end = moment.tz(tz).startOf(unit);
  const start = moment(end).subtract(n, unit);
  return {
    start: start.format(DAY_FORMAT),
    end: end.format(DAY_FORMAT),
    tz
  };
};

export const lastDays = (n: number, tz?: string): Timeframe => {
  tz = tz || getCurrentUser().tz;
  const end = moment.tz(tz).startOf('day');
  const start = moment(end).subtract(n, 'days');
  return {
    start: start.format(DAY_FORMAT),
    end: end.format(DAY_FORMAT),
    tz
  };
};

export const getComparisonTimeframe = (t: Timeframe) => {
  const tz = t.tz;
  const start = moment.tz(t.start, DAY_FORMAT, tz);
  const end = moment.tz(t.end, DAY_FORMAT, tz);
  const days = diffDays(start, end);
  return {
    start: start.subtract(days, 'days').format(DAY_FORMAT),
    end: end.subtract(days, 'days').format(DAY_FORMAT),
    tz
  };
};

export const multiplyTimeframe = (
  t: Timeframe,
  multiplier: number
): Timeframe => {
  const tz = t.tz;
  const start = moment.tz(t.start, DAY_FORMAT, tz);
  const end = moment.tz(t.end, DAY_FORMAT, tz);
  const days = diffDays(start, end) * multiplier;
  return {
    start: moment(end).subtract(days, 'days').format(DAY_FORMAT),
    end: end.format(DAY_FORMAT),
    tz
  };
};

export const toComparableTimeframe = (tf: Timeframe): Timeframe => {
  const m = multiplyTimeframe(tf, 2);
  return {
    start: m.start,
    end: tf.start,
    tz: tf.tz
  };
};

export const diffDays = (start: moment.Moment, end: moment.Moment): number => {
  return Math.abs(start.diff(end, 'days'));
};

export const useCountsInTimeframe = (spaceId: string, timeframe: Timeframe) => {
  return useMappedLoadingValue(
    useDenormalizedCountsInTimeframe(spaceId, timeframe),
    (v) => padCountsForTimeframe(v, timeframe)
  );
};

export const useCountsInTimeframeByProtocollessDomain = (
  spaceId: string,
  timeframe: Timeframe
) => {
  return useMappedLoadingValue(
    useDenormalizedCountsInTimeframeByProtocollessDomain(spaceId, timeframe),
    (d) => mapValues(d, (v) => padCountsForTimeframe(v, timeframe))
  );
};

export const useCountsInTimeframeForProductFs = (
  productId: string,
  timeframe: Timeframe
) => {
  const { space } = useCurrentUser();
  return useMappedLoadingValue(
    useDenormalizedCountsInTimeframeForProduct(space.id, productId, timeframe),
    (v) => padCountsForTimeframe(v, timeframe)
  );
};

export const useCountsInTimeframeForPartner = (
  partnerKey: string,
  timeframe: Timeframe
) => {
  const { space } = useCurrentUser();
  const [filterProductIds, loadingPs, errorPs] = usePartnerFilter(
    space,
    partnerKey
  );

  const [
    counts,
    loadingCounts,
    errorCounts
  ] = useDenormalizedCountsInTimeframeForPartner(
    space.id,
    filterProductIds || EMPTY_ARR,
    timeframe
  );

  const padded = counts ? padCountsForTimeframe(counts, timeframe) : counts;
  return [
    padded,
    loadingPs || loadingCounts,
    errorPs || errorCounts
  ] as LoadingValue<typeof padded>;
};

export const useCountsInTimeframeForPageFs = (
  href: string,
  timeframe: Timeframe
) => {
  const { space } = useCurrentUser();
  const [value, loading, error] = useDenormalizedCountsInTimeframeForPage(
    space.id,
    href,
    timeframe
  );
  const padded = value ? padCountsForTimeframe(value, timeframe) : value;
  return [padded, loading, error] as [
    typeof padded,
    typeof loading,
    typeof error
  ];
};

const collectTrends = (
  currentCounts: ICounter,
  lastCounts: ICounter
): ICounterWithTrend => {
  return {
    pageViews: {
      count: currentCounts.pageViews,
      lastCount: lastCounts.pageViews,
      trend: getTrend(lastCounts.pageViews, currentCounts.pageViews)
    },
    served: {
      count: currentCounts.served,
      lastCount: lastCounts.served,
      trend: getTrend(lastCounts.served, currentCounts.served)
    },
    viewed: {
      count: currentCounts.viewed,
      lastCount: lastCounts.viewed,
      trend: getTrend(lastCounts.viewed, currentCounts.viewed)
    },
    clicked: {
      count: currentCounts.clicked,
      lastCount: lastCounts.clicked,
      trend: getTrend(lastCounts.clicked, currentCounts.clicked)
    }
  };
};

const toCounterWithTrend = (
  counters: IDailyCounter[],
  compare: boolean
): ICounterWithTrend => {
  if (!compare) {
    const counts = aggregateDailyCounters(counters);
    return collectTrends(counts, counts);
  }

  const [a, b] = splitInHalf(counters);
  const lastCounts = aggregateDailyCounters(a);
  const currentCounts = aggregateDailyCounters(b);

  return collectTrends(currentCounts, lastCounts);
};

const aggregateCounterMap = (
  dict: { [key: string]: IDailyCounter[] },
  timeframe: Timeframe,
  compare: boolean
) => {
  const start = moment.tz(timeframe.start, timeframe.tz);
  const end = moment.tz(timeframe.end, timeframe.tz);
  const range = getTimeKeyRange(start, end);
  const result: { [key: string]: ICounterWithTrend } = {};
  for (const key in dict) {
    const counters = padCounts(dict[key], range);

    // TODO: Temporary Fix
    const k =
      key === 'undefined/2020-year-in-review'
        ? 'https://practicalwanderlust.com/2020-year-in-review'
        : key;

    result[k] = toCounterWithTrend(counters, compare);
  }
  return result;
};

export const useProductCountsInTimeframeFs = (
  spaceId: string,
  timeframe: Timeframe,
  compare: boolean
): LoadingValue<{ [productId: string]: ICounterWithTrend }> => {
  const tf = compare ? multiplyTimeframe(timeframe, 2) : timeframe;
  const [value, loading, error] = useDenormalizedProductCountsInTimeframe(
    spaceId,
    tf
  );

  const aggregatedValue = useMemo(
    () => (value ? aggregateCounterMap(value, tf, compare) : value),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value]
  );

  return [aggregatedValue, loading, error];
};

export const useCountsInTimeframePerPageForProductFs = (
  productId: string,
  timeframe: Timeframe,
  compare: boolean
): LoadingValueExtended<IPageCounter[]> => {
  const { space } = useCurrentUser();
  const filterProductIds = useCallback(() => [productId], [productId]);
  return useDenormalizedCountsInTimeframePerPageForProducts(
    space.id,
    filterProductIds,
    timeframe,
    compare
  );
};

export const useCountsInTimeframePerPageForPartner = (
  partnerKey: string,
  timeframe: Timeframe,
  compare: boolean
): LoadingValue<IPageCounter[]> => {
  const { space } = useCurrentUser();
  const [filterProductIds, loadingFilterFn, errorFilterFn] = usePartnerFilter(
    space,
    partnerKey
  );

  const [
    counts,
    loadingCounts,
    errorCounts
  ] = useDenormalizedCountsInTimeframePerPageForProducts(
    space.id,
    filterProductIds || EMPTY_ARR,
    timeframe,
    compare
  );

  return [
    counts,
    loadingFilterFn || loadingCounts,
    errorFilterFn || errorCounts
  ];
};

export const usePageCountsInTimeFrame = (
  timeframe: Timeframe,
  compare: boolean
): LoadingValue<{ [url: string]: ICounterWithTrend }> => {
  const { space } = useCurrentUser();
  const tf = compare ? multiplyTimeframe(timeframe, 2) : timeframe;
  const [value, loading, error] = useDenormalizedPageCountsInTimeFrame(
    space.id,
    tf
  );

  const aggregate = useMemo(
    () => (value ? aggregateCounterMap(value, tf, compare) : value),
    [value]
  );

  return [aggregate, loading, error];
};

export const useCountsInTimeframePerProductForPageFs = (
  href: string,
  timeframe: Timeframe,
  compare: boolean
) => {
  const { space } = useCurrentUser();
  return useDenormalizedCountsinTimeframePerProductForPage(
    space.id,
    href,
    timeframe,
    compare
  );
};

const PAGE_DETAILS_CACHE: {
  [spaceeId: string]: {
    [pageUrl: string]: {
      [tk: string]: IDenormalizedPage;
    };
  };
} = {};

const EMPTY_DENORMALIZED_PAGE = (): IDenormalizedPage => {
  return {
    totalCounts: EMPTY_SHORT_COUNTER(),
    byCountry: {},
    byDevice: EMPTY_DEVICE_SHORT_COUNTER(),
    byProduct: {}
  };
};

const DETAILED_ANALYTICS_FOR_PAGE_QUERY_CACHE: {
  [spaceId: string]: {
    [checksum: string]: Promise<{
      time: number;
      d: {
        // lots of query related metadata
        tk_start: string; // used to pad with empty data
        tk_end: string; // used to pad with empty data
        ds: string; // of { [url: string]: { [tk: string]: IDenormalizedPage } }
      }[];
    }>;
  };
} = {};

const getDetailedAnalyticsForPageWithDeduplication = (
  spaceId: string,
  url: string,
  tf: Timeframe
) => {
  const checksum = toChecksum({ url, tf });
  const cache = (DETAILED_ANALYTICS_FOR_PAGE_QUERY_CACHE[spaceId] =
    DETAILED_ANALYTICS_FOR_PAGE_QUERY_CACHE[spaceId] || {});
  return (cache[checksum] =
    cache[checksum] ||
    callFirebaseFunction<{
      time: number;
      d: {
        // lots of query related metadata
        tk_start: string; // used to pad with empty data
        tk_end: string; // used to pad with empty data
        ds: string; // of { [url: string]: { [tk: string]: IDenormalizedPage } }
      }[];
    }>('analytics-getDetailedAnalyticsForPage', {
      spaceId,
      pageUrl: url,
      tf
    }));
};

const getDenormalizedPageAnalyticsData = (
  spaceId: string,
  url: string,
  tf: Timeframe
): Promise<{
  [pageUrl: string]: {
    [tk: string]: IDenormalizedPage;
  };
}> => {
  return getDetailedAnalyticsForPageWithDeduplication(spaceId, url, tf).then(
    (r) => {
      console.log('getDenormalizedPageAnalyticsData', r);
      const res: {
        [pageUrl: string]: { [tk: string]: IDenormalizedPage };
      } = {};
      r.d.forEach((d) => {
        const x: {
          [pageUrl: string]: { [tk: string]: IDenormalizedPage };
        } = JSON.parse(d.ds);

        console.log('XX', x);
        Object.entries(x).forEach(([pageUrl, byTk]) => {
          const container = (res[pageUrl] = res[pageUrl] || {});
          const fullRange = tkRangeFromTks(d.tk_start, d.tk_end);
          console.log('Full range', fullRange);
          fullRange.forEach((tk) => {
            container[tk] = byTk[tk] || EMPTY_DENORMALIZED_PAGE();
          });
        });
      });

      // now that we've padded all that was returned, acknowledged that we could have
      // gotten NO response for the url we requested. Make sure empty data is in there at least
      if (!res[url]) {
        const container: { [tk: string]: IDenormalizedPage } = {};
        const requestedRange = getTimeKeyRangeFromTimeframe(tf);
        requestedRange.forEach(
          (tk) => (container[tk] = EMPTY_DENORMALIZED_PAGE())
        );
        res[url] = container;
      }

      console.log('res', res);

      return res;
    }
  );
};

const useDenormalizedPageAnalyticsData = (
  spaceId: string,
  url: string,
  tf: Timeframe
): LoadingValueLike<
  {
    tk: string;
    d: IDenormalizedPage;
  }[]
> => {
  return usePromise(async () => {
    const tkRange = getTimeKeyRangeFromTimeframe(tf);
    const remainingTks: string[] = [];

    // while we go through the cache again later anyway, store
    // what we don't need to request right away, in case for whatever
    // reason we run into a race condition where this data disappears
    // while the request is ongoing.
    const fromCache: { [tk: string]: IDenormalizedPage } = {};
    const spaceCache = (PAGE_DETAILS_CACHE[spaceId] =
      PAGE_DETAILS_CACHE[spaceId] || {});
    const pageCache = (spaceCache[url] = spaceCache[url] || {});
    tkRange.forEach((tk) => {
      const cached = pageCache[tk];
      if (cached) {
        fromCache[tk] = cached;
      } else {
        remainingTks.push(tk);
      }
    });
    if (remainingTks.length) {
      const requestTf = tksToTimeframe(
        remainingTks[0],
        remainingTks[remainingTks.length - 1],
        tf.tz
      );
      const res = await getDenormalizedPageAnalyticsData(
        spaceId,
        url,
        requestTf
      );
      Object.entries(res).forEach(([pageUrl, byTk]) => {
        const pCache = (spaceCache[pageUrl] = spaceCache[pageUrl] || {});
        Object.entries(byTk).forEach(([tk, p]) => {
          pCache[tk] = p;
        });
      });
    }
    return tkRange.map((tk) => ({
      tk,
      d: pageCache[tk] || fromCache[tk] || EMPTY_DENORMALIZED_PAGE()
    }));
  }, [spaceId, url, tf.start, tf.end, tf.tz]);
};

export const useCountsInTimeframePerProductForPagePg = (
  spaceId: string,
  url: string,
  timeframe: Timeframe,
  compare: boolean
) => {
  const { start, end, tz } = timeframe;
  const tf = useMemo(() => {
    return compare
      ? multiplyTimeframe({ start, end, tz }, 2)
      : { start, end, tz };
  }, [compare, start, end, tz]);
  return useMappedLoadingValue(
    useDenormalizedPageAnalyticsData(spaceId, url, tf),
    (ds) => {
      const counters = ds.reduce<{
        [productId: string]: {
          [occurrenceKey: string]: IDailyCounter[];
        };
      }>((m, page) => {
        Object.keys(page.d.byProduct).forEach((productId) => {
          const perProduct = (m[productId] = m[productId] || {});
          Object.keys(page.d.byProduct[productId]).forEach((occurrenceKey) => {
            const cs: IDailyCounter[] = perProduct[occurrenceKey] || [];
            const occ = page.d.byProduct[productId][occurrenceKey];
            cs.push({
              pageViews: occ.p,
              served: occ.s,
              viewed: occ.v,
              clicked: occ.c,
              timeKey: page.tk
            });
            perProduct[occurrenceKey] = cs;
          });
        });
        return m;
      }, {});

      return toProductCounters(counters, tf, compare);
    }
  );
};

export const useCountsInTimeframeForPagePg = (
  spaceId: string,
  url: string,
  timeframe: Timeframe
) => {
  return usePromise(() => {
    return getPageAnalyticsAsTimeseries(spaceId, {
      groupBy: 'url',
      url: [url],
      tf: { start: timeframe.start, end: timeframe.end, tz: timeframe.tz }
    }).then((r) => {
      console.log('useCountsInTimeframeForPage', r);
      const rows = r.d.map<IDailyCounter>(([_, tk, p, s, v, c]) => {
        return {
          timeKey: `${tk}`,
          pageViews: p,
          served: s,
          viewed: v,
          clicked: c
        };
      });
      return padCountsForTimeframe(
        sortBy(rows, (r) => r.timeKey),
        timeframe
      );
    });
  }, [spaceId, url, timeframe.start, timeframe.end, timeframe.tz]);
};

export const useCountsInTimeframePerPagePg = (
  spaceId: string,
  timeframe: Timeframe,
  origins?: string[]
) => {
  return usePromise(() => {
    const query: Omit<PageAnalyticsQuery, 'asTimeseries'> = {
      groupBy: 'url',
      tf: { start: timeframe.start, end: timeframe.end, tz: timeframe.tz }
    };

    if (origins) {
      query.origin = origins;
    }

    return getPageAnalyticsAsTimeseries(spaceId, query).then((r) => {
      console.log('useCountsInTimeframePerPage', r);
      const byUrl = groupBy(r.d, (x) => x[0]);
      return mapValues(byUrl, (ds) => {
        const rows = ds.map<IDailyCounter>(([_, tk, p, s, v, c]) => {
          return {
            timeKey: `${tk}`,
            pageViews: p,
            served: s,
            viewed: v,
            clicked: c
          };
        });
        return padCountsForTimeframe(
          sortBy(rows, (r) => r.timeKey),
          timeframe
        );
      });
    });
  }, [spaceId, timeframe.start, timeframe.end, timeframe.tz, origins]);
};

export const useCountryClickCountsForPageInTimeframePg = (
  spaceId: string,
  url: string,
  timeframe: Timeframe,
  compare: boolean
) => {
  const { start, end, tz } = timeframe;
  const tf = useMemo(() => {
    return compare
      ? multiplyTimeframe({ start, end, tz }, 2)
      : { start, end, tz };
  }, [compare, start, end, tz]);
  return useMappedLoadingValue(
    useDenormalizedPageAnalyticsData(spaceId, url, tf),
    (ds) => {
      const byTimeKey: {
        [timeKey: string]: {
          [countryCode: string]: ICounter;
        };
      } = {};
      ds.forEach((p) => {
        const container = (byTimeKey[p.tk] = byTimeKey[p.tk] || {});
        Object.keys(p.d.byCountry).forEach((country) => {
          container[country] = mergeCounter(
            container[country] || EMPTY_COUNTER(),
            shortCounterToCounter(p.d.byCountry[country])
          );
        });
      });

      return toCountryCounters(byTimeKey, tf, compare);
    }
  );
};

export const useDeviceClickCountsForPageInTimeframePg = (
  spaceId: string,
  url: string,
  tf: Timeframe
) => {
  return useMappedLoadingValue(
    useDenormalizedPageAnalyticsData(spaceId, url, tf),
    (ds) =>
      ds.reduce<IDeviceCounter>(
        (m, p) => addToDeviceCounter(m, p.d.byDevice),
        EMPTY_DEVICE_COUNTER()
      )
  );
};

// -------------- Product stuff ------------------ //

const PRODUCT_DETAILS_CACHE: {
  [spaceeId: string]: {
    [productId: string]: {
      [tk: string]: IDenormalizedProduct;
    };
  };
} = {};

const EMPTY_DENORMALIZED_PRODUCT = (): IDenormalizedProduct => {
  return {
    totalCounts: EMPTY_SHORT_COUNTER(),
    byCountry: {},
    byDevice: EMPTY_DEVICE_SHORT_COUNTER(),
    byPage: {}
  };
};

const DETAILED_ANALYTICS_FOR_PRODUCT_QUERY_CACHE: {
  [spaceId: string]: {
    [checksum: string]: Promise<{
      time: number;
      d: {
        // lots of query related metadata
        tk_start: string; // used to pad with empty data
        tk_end: string; // used to pad with empty data
        ds: string; // of { [productId: string]: { [tk: string]: IDenormalizedProduct } }
      }[];
    }>;
  };
} = {};

const getDetailedAnalyticsForProductWithDeduplication = (
  spaceId: string,
  productId: string,
  tf: Timeframe
) => {
  const checksum = toChecksum({ productId, tf });
  const cache = (DETAILED_ANALYTICS_FOR_PRODUCT_QUERY_CACHE[spaceId] =
    DETAILED_ANALYTICS_FOR_PRODUCT_QUERY_CACHE[spaceId] || {});
  return (cache[checksum] =
    cache[checksum] ||
    callFirebaseFunction<{
      time: number;
      d: {
        // lots of query related metadata
        tk_start: string; // used to pad with empty data
        tk_end: string; // used to pad with empty data
        ds: string; // of { [productId: string]: { [tk: string]: IDenormalizedProdut } }
      }[];
    }>('analytics-getDetailedAnalyticsForProduct', {
      spaceId,
      productId,
      tf
    }));
};

const getDenormalizedProductAnalyticsData = (
  spaceId: string,
  productId: string,
  tf: Timeframe
): Promise<{
  [productId: string]: {
    [tk: string]: IDenormalizedProduct;
  };
}> => {
  return getDetailedAnalyticsForProductWithDeduplication(
    spaceId,
    productId,
    tf
  ).then((r) => {
    console.log('getDenormalizedProductAnalyticsData', r);
    const res: {
      [productId: string]: { [tk: string]: IDenormalizedProduct };
    } = {};
    r.d.forEach((d) => {
      const x: {
        [productId: string]: { [tk: string]: IDenormalizedProduct };
      } = JSON.parse(d.ds);
      Object.entries(x).forEach(([pId, byTk]) => {
        const container = (res[pId] = res[pId] || {});
        const fullRange = tkRangeFromTks(d.tk_start, d.tk_end);
        fullRange.forEach((tk) => {
          container[tk] = byTk[tk] || EMPTY_DENORMALIZED_PRODUCT();
        });
      });
    });

    // now that we've padded all that was returned, acknowledged that we could have
    // gotten NO response for the url we requested. Make sure empty data is in there at least
    if (!res[productId]) {
      const container: { [tk: string]: IDenormalizedProduct } = {};
      const requestedRange = getTimeKeyRangeFromTimeframe(tf);
      requestedRange.forEach(
        (tk) => (container[tk] = EMPTY_DENORMALIZED_PRODUCT())
      );
      res[productId] = container;
    }

    return res;
  });
};

const useDenormalizedProductAnalyticsData = (
  spaceId: string,
  productId: string,
  tf: Timeframe
): LoadingValueLike<
  {
    tk: string;
    d: IDenormalizedProduct;
  }[]
> => {
  return usePromise(async () => {
    const tkRange = getTimeKeyRangeFromTimeframe(tf);
    const remainingTks: string[] = [];

    // while we go through the cache again later anyway, store
    // what we don't need to request right away, in case for whatever
    // reason we run into a race condition where this data disappears
    // while the request is ongoing.
    const fromCache: { [tk: string]: IDenormalizedProduct } = {};
    const spaceCache = (PRODUCT_DETAILS_CACHE[spaceId] =
      PRODUCT_DETAILS_CACHE[spaceId] || {});
    const productCache = (spaceCache[productId] = spaceCache[productId] || {});
    tkRange.forEach((tk) => {
      const cached = productCache[tk];
      if (cached) {
        fromCache[tk] = cached;
      } else {
        remainingTks.push(tk);
      }
    });
    if (remainingTks.length) {
      const requestTf = tksToTimeframe(
        remainingTks[0],
        remainingTks[remainingTks.length - 1],
        tf.tz
      );
      const res = await getDenormalizedProductAnalyticsData(
        spaceId,
        productId,
        requestTf
      );
      console.log(res);
      Object.entries(res).forEach(([pId, byTk]) => {
        const pCache = (spaceCache[pId] = spaceCache[pId] || {});
        Object.entries(byTk).forEach(([tk, p]) => {
          pCache[tk] = p;
        });
      });
    }
    return tkRange.map((tk) => ({
      tk,
      d: productCache[tk] || fromCache[tk] || EMPTY_DENORMALIZED_PRODUCT()
    }));
  }, [spaceId, productId, tf.start, tf.end, tf.tz]);
};

export const useCountsInTimeframePerPageForProductPg = (
  spaceId: string,
  productId: string,
  timeframe: Timeframe,
  compare: boolean
): LoadingValueLike<IPageCounter[]> => {
  const { start, end, tz } = timeframe;
  const tf = useMemo(() => {
    return compare
      ? multiplyTimeframe({ start, end, tz }, 2)
      : { start, end, tz };
  }, [compare, start, end, tz]);
  return useMappedLoadingValue(
    useDenormalizedProductAnalyticsData(spaceId, productId, tf),
    (ds) => {
      const counters = ds.reduce<{
        [href: string]: {
          [occurrenceKey: string]: {
            [timeKey: string]: ICounter;
          };
        };
      }>((m, p) => {
        Object.keys(p.d.byPage).forEach((href) => {
          const perPage = (m[href] = m[href] || {});
          Object.keys(p.d.byPage[href]).forEach((occurrenceKey) => {
            const cs: {
              [timeKey: string]: ICounter;
            } = (perPage[occurrenceKey] = perPage[occurrenceKey] || {});
            const tkContainer = (cs[p.tk] = cs[p.tk] || EMPTY_COUNTER());
            const occ = p.d.byPage[href][occurrenceKey];
            tkContainer.pageViews += occ.p;
            tkContainer.served += occ.s;
            tkContainer.viewed += occ.v;
            tkContainer.clicked += occ.c;
          });
        });
        return m;
      }, {});

      const range = getTimekeyRangeFromDayFormat(tf.start, tf.end);

      return Object.keys(counters).map<IPageCounter>((href) => {
        const byOccurrence = Object.keys(counters[href]).reduce<{
          [occurenceKey: string]: ICounterWithTrend;
        }>((m, occurenceKey) => {
          const dailyCounters = sortBy(
            Object.entries(counters[href][occurenceKey] || {}).map<
              IDailyCounter
            >(([timeKey, c]) => {
              return {
                timeKey,
                pageViews: c.pageViews,
                served: c.served,
                viewed: c.viewed,
                clicked: c.clicked
              };
            }),
            (x) => x.timeKey
          );
          m[occurenceKey] = toCounterWithTrend(
            padDailyCounters(range, dailyCounters),
            compare
          );
          return m;
        }, {});
        return {
          total: reduceTrendCounters(Object.values(byOccurrence)),
          byOccurrence,
          href
        };
      });
    }
  );
};

export const useCountryClickCountsForProductInTimeframePg = (
  spaceId: string,
  productId: string,
  timeframe: Timeframe,
  compare: boolean
) => {
  const { start, end, tz } = timeframe;
  const tf = useMemo(() => {
    return compare
      ? multiplyTimeframe({ start, end, tz }, 2)
      : { start, end, tz };
  }, [compare, start, end, tz]);
  return useMappedLoadingValue(
    useDenormalizedProductAnalyticsData(spaceId, productId, tf),
    (ds) => {
      const byTimeKey: {
        [timeKey: string]: {
          [countryCode: string]: ICounter;
        };
      } = {};
      ds.forEach((p) => {
        const container = (byTimeKey[p.tk] = byTimeKey[p.tk] || {});
        Object.keys(p.d.byCountry).forEach((country) => {
          container[country] = mergeCounter(
            container[country] || EMPTY_COUNTER(),
            shortCounterToCounter(p.d.byCountry[country])
          );
        });
      });

      return toCountryCounters(byTimeKey, tf, compare);
    }
  );
};

export const useDeviceClickCountsForProductInTimeframePg = (
  spaceId: string,
  productId: string,
  tf: Timeframe
) => {
  return useMappedLoadingValue(
    useDenormalizedProductAnalyticsData(spaceId, productId, tf),
    (ds) =>
      ds.reduce<IDeviceCounter>(
        (m, p) => addToDeviceCounter(m, p.d.byDevice),
        EMPTY_DEVICE_COUNTER()
      )
  );
};

export const useCountsInTimeframeForProductPg = (
  spaceId: string,
  productId: string,
  timeframe: Timeframe
) => {
  return usePromise(async () => {
    return getProductAnalyticsAsTimeseries(spaceId, {
      groupBy: 'product_id',
      product_id: [productId],
      tf: { start: timeframe.start, end: timeframe.end, tz: timeframe.tz }
    }).then((r) => {
      console.log('useCountsInTimeframeForProduct', r);
      const rows = r.d.map<IDailyCounter>(([_, tk, p, s, v, c]) => {
        return {
          timeKey: `${tk}`,
          pageViews: p,
          served: s,
          viewed: v,
          clicked: c
        };
      });
      return padCountsForTimeframe(
        sortBy(rows, (r) => r.timeKey),
        timeframe
      );
    });
  }, [spaceId, productId, timeframe.start, timeframe.end, timeframe.tz]);
};

export const useProductCountsInTimeframePg = (
  q: Omit<ProductAnalyticsQuery, 'asTimeseries'> & {
    spaceId: string;
  },
  compare: boolean,
  version: number
): LoadingValueExtended<{
  [productId: string]: {
    name: string;
    url: string;
    partnerKey: string;
    issues: IProductIssue[];
    earnings: ICountWithTrend;
    ctr: ICountWithTrend;
    epc: ICountWithTrend;
    createdAt: firebase.firestore.Timestamp;
  } & ICounterWithTrend;
}> => {
  const { start, end, tz } = q.tf;
  const { column, dir } = q.orderBy || {};
  const columnAsString = column ? column.toString() : undefined;

  return usePromise(async () => {
    const curr = await getProductAnalyticsAsCountMap(q);

    // Only care about current product ids
    const currByProductId = keyBy(curr, (x) => x.productId);
    const allProductIds = [...new Set([...Object.keys(currByProductId)])];

    const prev = compare
      ? await getProductAnalyticsAsCountMap({
          ...q,
          product_id: allProductIds,
          page: 1, // Always get first page for comparison
          tf: toComparableTimeframe(q.tf)
        })
      : [];

    const prevByProductId = keyBy(prev, (x) => x.productId);

    return allProductIds.reduce<{
      [productId: string]: {
        name: string;
        url: string;
        partnerKey: string;
        issues: IProductIssue[];
        earnings: ICountWithTrend;
        ctr: ICountWithTrend;
        epc: ICountWithTrend;
        createdAt: firebase.firestore.Timestamp;
      } & ICounterWithTrend;
    }>((m, productId) => {
      const c = currByProductId[productId];
      const p = prevByProductId[productId];
      const ref = c || p;

      m[productId] = {
        name: ref.name,
        url: ref.url,
        partnerKey: ref.partnerKey,
        issues: ref.issues.map((i) => ({
          ...i,
          createdAt: refreshTimestamp(i.createdAt),
          updatedAt: refreshTimestamp(i.updatedAt)
        })),
        createdAt: refreshTimestamp(ref.createdAt),
        // EPC and CTR are already calculated in the database here
        epc: {
          count: c?.epc || 0,
          lastCount: p?.epc || 0,
          trend: getTrend(p?.epc || 0, c?.epc || 0)
        },
        ctr: {
          count: c?.ctr || 0,
          lastCount: p?.ctr || 0,
          trend: getTrend(p?.ctr || 0, c?.ctr || 0)
        },
        earnings: {
          count: c?.earnings || 0,
          lastCount: p?.earnings || 0,
          trend: getTrend(p?.earnings || 0, c?.earnings || 0)
        },
        pageViews: {
          count: c?.counts.p || 0,
          lastCount: p?.counts.p || 0,
          trend: getTrend(p?.counts.p || 0, c?.counts.p || 0)
        },
        served: {
          count: c?.counts.s || 0,
          lastCount: p?.counts.s || 0,
          trend: getTrend(p?.counts.s || 0, c?.counts.s || 0)
        },
        viewed: {
          count: c?.counts.v || 0,
          lastCount: p?.counts.v || 0,
          trend: getTrend(p?.counts.v || 0, c?.counts.v || 0)
        },
        clicked: {
          count: c?.counts.c || 0,
          lastCount: p?.counts.c || 0,
          trend: getTrend(p?.counts.c || 0, c?.counts.c || 0)
        }
      };
      return m;
    }, {});
  }, [
    q.spaceId,
    start,
    end,
    tz,
    compare,
    q.page,
    q.limit,
    q.q,
    dir,
    columnAsString,
    q.partner_key?.length, // relying on array causes infinite rerendering
    version
  ]);
};

export const usePgCountsInTimeframe = (
  spaceId: string,
  timeframe: Timeframe
) => {
  return usePromise(() => {
    return getPageAnalyticsAsTimeseries(spaceId, {
      tf: timeframe,
      groupBy: 'origin'
    }).then((r) => {
      console.log('useAnalyticsDataByOriginAsTimeseries', r, timeframe);
      const byTimekey = r.d.reduce<{
        [tk: string]: IDailyCounter;
      }>((m, [origin, tk, p, s, v, c]) => {
        const timeKey = `${tk}`;
        const container = (m[timeKey] =
          m[timeKey] || EMPTY_DAILY_COUNTER(timeKey));
        container.pageViews += p;
        container.served += s;
        container.viewed += v;
        container.clicked += c;
        return m;
      }, {});
      const counters = Object.values(byTimekey);
      return padCountsForTimeframe(
        sortBy(counters, (x) => x.timeKey),
        timeframe
      );
    });
  }, [spaceId, timeframe.start, timeframe.end, timeframe.tz]);
};

export const useAnalyticsDataByOriginAsTimeseries = (
  spaceId: string,
  timeframe: Timeframe
) => {
  return usePromise(() => {
    return getPageAnalyticsAsTimeseries(spaceId, {
      tf: timeframe,
      groupBy: 'origin'
    }).then((r) => {
      console.log('useAnalyticsDataByOriginAsTimeseries', r, timeframe);
      const byOrigin = r.d.reduce<{
        [origin: string]: IDailyCounter[];
      }>((m, [origin, tk, p, s, v, c]) => {
        const container = (m[origin] = m[origin] || []);
        container.push({
          timeKey: `${tk}`,
          pageViews: p,
          served: s,
          viewed: v,
          clicked: c
        });
        return m;
      }, {});
      return mapValues(byOrigin, (v) =>
        padCountsForTimeframe(
          sortBy(v, (x) => x.timeKey),
          timeframe
        )
      );
    });
  }, [spaceId, timeframe.start, timeframe.end, timeframe.tz]);
};

export const useAnalyticsDataByOrigin = (
  spaceId: string,
  timeframe: Timeframe
) => {
  return usePromise(() => {
    return getPageAnalytics(spaceId, {
      tf: timeframe,
      groupBy: 'origin'
    }).then((r) => {
      console.log('useAnalyticsDataByOrigin', r, timeframe);
      const byOrigin = r.d.reduce<{
        [origin: string]: ICounter;
      }>((m, [origin, p, s, v, c]) => {
        const container = (m[origin] = m[origin] || EMPTY_COUNTER());
        container.pageViews += p;
        container.served += s;
        container.viewed += v;
        container.clicked += c;
        return m;
      }, {});
      return byOrigin;
    });
  }, [spaceId, timeframe.start, timeframe.end, timeframe.tz]);
};
