import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton
} from '@material-ui/core';
import { intersection, keyBy } from 'lodash';
import React, { useEffect, useMemo, useRef } from 'react';
import { Check, ChevronDown, DownloadCloud, XCircle } from 'react-feather';
import { Helmet } from 'react-helmet';
import {
  useColumnsQueryParam,
  useSortQueryParam
} from '../../../../components/GroupableList';
import { InlineLoader } from '../../../../components/Loader';
import {
  NestedMultiSelectorButtonAsync,
  NestedMultiSelectorItem,
  toNestedMultiSelectorCheckboxGroup
} from '../../../../components/NestedMultiSelector';
import { NoPermissions } from '../../../../components/NoPermissions';
import { SearchInput } from '../../../../components/SearchInput';
import {
  SiteSelector,
  UNATTRIBUTED
} from '../../../../components/SiteSelector';
import { ColumnSelector } from '../../../../components/Table/ColumnSelector';
import { ModeSelector } from '../../../../components/Table/ModeSelector';
import {
  TimeframePickerDense,
  useStandardOptions,
  useTimeframeFromUrl
} from '../../../../components/TimeframePicker';
import { Mode, Timeframe } from '../../../../domainTypes/analytics';
import { IPageWithCountsAndTrendsAndSales } from '../../../../domainTypes/page';
import { AssignTagsParams, IPostgresTags } from '../../../../domainTypes/tags';
import { css } from '../../../../emotion';
import { useDictionary } from '../../../../hooks/useDictionary';
import { FlexContainer } from '../../../../layout/Flex';
import { SideNavHowTo } from '../../../../layout/PageBody';
import {
  DEFAULT_OFFSET,
  PageToolbar,
  PageToolbarSection
} from '../../../../layout/PageToolbar';
import {
  queryParamToList,
  setToQueryParam,
  useNullableStringSetQueryParam,
  useQueryParam,
  useRoutes,
  useStringQueryParam,
  useTypedStringQueryParam
} from '../../../../routes';
import {
  useCurrentUser,
  useHasCurrentUserRequiredScopes
} from '../../../../services/currentUser';
import { useTrackMixpanelView } from '../../../../services/mixpanel';
import { usePagesWithCountsAndSalesInTimeframePgOnly } from '../../../../services/pages';
import { pluralize } from '../../../../services/pluralize';
import { getSelectedItems } from '../../../../services/selection';
import { getActiveDomainUrls } from '../../../../services/space';
import {
  IPostgresTagsParent,
  assignTags,
  useTagHierarchy,
  useTagsForCurrentUser
} from '../../../../services/tags';
import { useSpaceCurrency } from '../../../../services/useSpaceCurrency';
import { ContentPageBody } from '../../components/ContentPageBody';
import { SearchResultsRibbon } from '../../components/SearchResultsRibbon';
import { EmptyState } from './EmptyState';
import { COLUMNS, DEFAULT_COLUMNS, PagesTable, SORTERS } from './PagesTable';
import { ContentExportButton } from '../../components/ContentExport';
import { CurrencyCode } from '../../../../domainTypes/currency';
import { useFlushAnalyticsV2Cache } from '../../../../services/analyticsV2/cache';

const HowTo: SideNavHowTo = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>How to?</DialogTitle>
      <DialogContent>Content</DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

type UpdateTagsButtonState = {
  [parentId: string]: {
    [childId: string]: boolean | 'INDETERMINATE';
  };
};

const useTagsById = (): Record<string, IPostgresTags> => {
  const [tags, loading] = useTagsForCurrentUser();
  return useMemo(() => {
    if (loading || !tags) return {};
    return keyBy(tags, (t) => t.id);
  }, [loading, tags]);
};

const applyOrRemoveTagsToPages = async (
  spaceId: string,
  pages: IPageWithCountsAndTrendsAndSales[],
  values: UpdateTagsButtonState
) => {
  // We don't need to worry about indeterminate states here - those
  // would definitely be items that haven't been touched by the user. No change
  // is required for these
  const tagIdsToAdd: string[] = [];
  const tagIdsToRemove: string[] = [];

  Object.values(values).forEach((xs) =>
    Object.entries(xs).forEach(([tagId, state]) => {
      if (state === 'INDETERMINATE') {
        return;
      }
      if (state) {
        tagIdsToAdd.push(tagId);
      } else {
        tagIdsToRemove.push(tagId);
      }
    })
  );

  const actions: AssignTagsParams['actions'] = [];
  pages.forEach((page) => {
    const prevTags = page.tagIds;
    // too naive - use URL, but in general: let the backend deal with this
    const { href } = page;
    tagIdsToAdd.forEach((tagId) => {
      if (!prevTags.includes(tagId)) {
        actions.push({
          tagId,
          href,
          action: 'apply'
        });
      }
    });
    tagIdsToRemove.forEach((tagId) => {
      if (prevTags.includes(tagId)) {
        actions.push({
          tagId,
          href,
          action: 'remove'
        });
      }
    });
  });
  console.log(JSON.stringify(actions, null, 2));
  await assignTags({ spaceId, actions });
};

const FilterTagsButton = ({
  selectedTags,
  setSelectedTags,
  tagHierarchy
}: {
  selectedTags: Set<string> | null;
  setSelectedTags: (tags: Set<string>) => void;
  tagHierarchy: IPostgresTagsParent[];
}) => {
  const initialState: UpdateTagsButtonState = useMemo(() => {
    return Object.fromEntries(
      tagHierarchy.map((parentTag) => {
        const children = Object.fromEntries(
          parentTag.children.map((tag) => {
            const isTagSelected = selectedTags
              ? selectedTags.has(tag.id)
              : false;
            return [tag.id, isTagSelected];
          })
        );
        return [parentTag.parent.id, children];
      })
    );
  }, [selectedTags, tagHierarchy]);

  const items: NestedMultiSelectorItem<
    UpdateTagsButtonState
  >[] = tagHierarchy.map((t) =>
    toNestedMultiSelectorCheckboxGroup<UpdateTagsButtonState, IPostgresTags>({
      key: t.parent.id,
      label: t.parent.name,
      options: t.children,
      isOptionSelected: (values, item) =>
        values[t.parent.id]?.[item.id] || false,
      onChangeOption: (prev, item, selected) => ({
        ...prev,
        [t.parent.id]: {
          ...prev[t.parent.id],
          [item.id]: selected
        }
      }),
      onChangeAll: (prev, items) => {
        // If they have selected everything, map all to true
        // If the array of selected items is empty, map all
        // children to false
        return {
          ...prev,
          [t.parent.id]: items.length
            ? Object.fromEntries(items.map((item) => [item.id, true]))
            : Object.fromEntries(
                Object.keys(prev[t.parent.id]).map((id) => [id, false])
              )
        };
      }
    })
  );

  return (
    <NestedMultiSelectorButtonAsync
      title="Filter by tags"
      color="default"
      variant="contained"
      cta="Apply filter"
      endIcon={<ChevronDown size={16} />}
      values={initialState}
      onChange={(values) => {
        const newTags = new Set<string>();
        Object.entries(values).forEach(([_, children]) => {
          Object.entries(children).forEach(([tagId, selected]) => {
            if (selected) {
              newTags.add(tagId);
            } else {
              newTags.delete(tagId);
            }
          });
        });

        setSelectedTags(newTags);
        return Promise.resolve();
      }}
      items={items}
      pending="Applying filters..."
      autoExpandCheckedItems
    >
      {selectedTags && selectedTags.size > 0 ? (
        <>
          <Check
            size={16}
            className={css((t) => ({
              color: t.palette.primary.main,
              marginRight: `${t.spacing(0.5)}px`,
              position: 'relative',
              top: '1px'
            }))}
          />
          Filtered by {selectedTags.size} {pluralize('tag', selectedTags.size)}
        </>
      ) : (
        <>Tags</>
      )}
    </NestedMultiSelectorButtonAsync>
  );
};

const UpdateTagsButton = ({
  spaceId,
  pages,
  tagHierarchy
}: {
  spaceId: string;
  pages: IPageWithCountsAndTrendsAndSales[];
  tagHierarchy: IPostgresTagsParent[];
}) => {
  const flushCache = useFlushAnalyticsV2Cache();
  const initialState: UpdateTagsButtonState = useMemo(() => {
    const tagCounts: { [tagId: string]: number } = {};
    pages.forEach((p) => {
      (p.tagIds || []).forEach(
        (tagId) => (tagCounts[tagId] = (tagCounts[tagId] || 0) + 1)
      );
    });
    return Object.fromEntries(
      tagHierarchy.map((parentTag) => {
        const children = Object.fromEntries(
          parentTag.children.map((tag) => {
            const countForTag = tagCounts[tag.id] || 0;
            return [
              tag.id,
              countForTag === 0
                ? false
                : countForTag === pages.length
                ? true
                : ('INDETERMINATE' as const)
            ];
          })
        );
        return [parentTag.parent.id, children];
      })
    );
  }, [pages, tagHierarchy]);

  const items: NestedMultiSelectorItem<
    UpdateTagsButtonState
  >[] = tagHierarchy.map((t) =>
    toNestedMultiSelectorCheckboxGroup<UpdateTagsButtonState, IPostgresTags>({
      key: t.parent.id,
      label: t.parent.name,
      options: t.children,
      isOptionSelected: (values, item) =>
        values[t.parent.id]?.[item.id] || false,
      onChangeOption: (prev, item, selected) => ({
        ...prev,
        [t.parent.id]: {
          ...prev[t.parent.id],
          [item.id]: selected
        }
      }),
      onChangeAll: (prev, items) => {
        // If they have selected everything, map all to true
        // If the array of selected items is empty, map all
        // children to false
        return {
          ...prev,
          [t.parent.id]: items.length
            ? Object.fromEntries(items.map((item) => [item.id, true]))
            : Object.fromEntries(
                Object.keys(prev[t.parent.id]).map((id) => [id, false])
              )
        };
      }
    })
  );

  return (
    <NestedMultiSelectorButtonAsync
      color="primary"
      variant="contained"
      endIcon={<ChevronDown size={16} />}
      values={initialState}
      onChange={async (values) => {
        await applyOrRemoveTagsToPages(spaceId, pages, values);
        flushCache(spaceId);
      }}
      items={items}
      pending="Updating tags..."
      autoExpandCheckedItems
    >
      Update tags
    </NestedMultiSelectorButtonAsync>
  );
};

const SelectionActionsPg = ({
  spaceId,
  pages,
  tagHierarchy,
  timeframe,
  currency,
  tagsById
}: {
  spaceId: string;
  pages: IPageWithCountsAndTrendsAndSales[];
  tagHierarchy: IPostgresTagsParent[] | void;
  timeframe: Timeframe;
  currency: CurrencyCode;
  tagsById: Record<string, IPostgresTags>;
}) => {
  const [canApplyTags] = useHasCurrentUserRequiredScopes([
    'tags.add_or_remove'
  ]);
  const [canExportContent] = useHasCurrentUserRequiredScopes([
    'reports.content.export'
  ]);
  return (
    <>
      {canApplyTags &&
        (tagHierarchy ? (
          <div>
            <UpdateTagsButton
              spaceId={spaceId}
              pages={pages}
              tagHierarchy={tagHierarchy}
            />
          </div>
        ) : (
          <InlineLoader />
        ))}
      {canExportContent && (
        <div>
          <ContentExportButton
            tagsById={tagsById}
            timeframe={timeframe}
            pages={pages}
            currency={currency}
            color="primary"
            variant="contained"
            startIcon={<DownloadCloud size={16} />}
            style={{
              // UpdateTagsButton has similar style
              height: '33px'
            }}
          >
            Export
          </ContentExportButton>
        </div>
      )}
    </>
  );
};

const PageContentOverviewPg = () => {
  const { ROUTES } = useRoutes();

  const n = useRef(Date.now());

  const { options, defaultOption } = useStandardOptions();
  const [timeframe, setTimeframe] = useTimeframeFromUrl(defaultOption.value);
  const [search, setSearch] = useStringQueryParam('q');
  const [columns, setColumns] = useColumnsQueryParam(
    'columns',
    DEFAULT_COLUMNS
  );
  const [mode, setMode] = useTypedStringQueryParam<Mode>(
    'table-mode',
    'absolute-numbers',
    true
  );
  const compare = true;
  const currency = useSpaceCurrency();
  const { space } = useCurrentUser();
  const [
    allPages,
    loading,
    error
  ] = usePagesWithCountsAndSalesInTimeframePgOnly(
    space.id,
    timeframe,
    currency,
    compare
  );

  const activeDomains = useMemo(() => {
    return getActiveDomainUrls(space, true);
  }, [space]);

  const sorters = SORTERS[mode];
  const [[sorter, direction], setSort] = useSortQueryParam('sort', sorters);

  const [sites, setSites] = useQueryParam(
    'site',
    (p) =>
      new Set(p && p !== UNATTRIBUTED ? queryParamToList(p) : activeDomains),
    (ss) => (ss.size === activeDomains.length ? undefined : setToQueryParam(ss))
  );

  const [selectedTags, setSelectedTags] = useNullableStringSetQueryParam(
    'tags',
    '---'
  );
  const [tagHierarchy, loadingTagHierarchy] = useTagHierarchy();
  const tagsById = useTagsById();

  const pages = useMemo(() => {
    if (!allPages || loadingTagHierarchy) {
      return undefined;
    }

    const selectedTagIds = selectedTags ? [...selectedTags] : null;

    return allPages.filter((p) => {
      const hasDomains = sites.has(p.domain);
      const hasTags = selectedTagIds
        ? intersection(p.tagIds, selectedTagIds).length > 0
        : true;
      const matchesSearch = search.length
        ? p.href.indexOf(search) !== -1
        : true;
      return hasDomains && hasTags && matchesSearch;
    });
  }, [allPages, search, sites, selectedTags, loadingTagHierarchy]);

  useEffect(() => {
    if (!loading) {
      console.log('Time elapsed: ', Date.now() - n.current);
    }
  }, [loading]);

  const {
    dictionary: selected,
    merge: setSelected,
    replace: replaceSelection
  } = useDictionary<boolean>({});

  const selectedHrefs = useMemo(
    () => getSelectedItems(pages || [], selected, (d) => d.href),
    [pages, selected]
  );

  if (error) {
    console.error(error);
    return (
      <div>
        An error occurred! Try refreshing your screen, and let us know if it
        continued.
      </div>
    );
  }

  if (!loading && (!allPages || !allPages.length)) {
    return (
      <ContentPageBody howTo={HowTo}>
        <EmptyState />
      </ContentPageBody>
    );
  }

  return (
    <ContentPageBody noTopPadding howTo={HowTo}>
      <PageToolbar sticky offset={DEFAULT_OFFSET}>
        {selectedHrefs.length ? (
          <>
            <PageToolbarSection flex={1}>
              <FlexContainer justifyContent="flex-start" spacing={0.5}>
                <div>
                  {pluralize('pages', selectedHrefs.length, true)} selected
                </div>
                <IconButton onClick={() => replaceSelection({})}>
                  <XCircle size={14} />
                </IconButton>
              </FlexContainer>
            </PageToolbarSection>

            <PageToolbarSection flex={1} justifyContent="flex-end">
              <SelectionActionsPg
                spaceId={space.id}
                pages={selectedHrefs}
                tagHierarchy={tagHierarchy}
                timeframe={timeframe}
                currency={currency}
                tagsById={tagsById}
              />
            </PageToolbarSection>
          </>
        ) : (
          <>
            <PageToolbarSection flex={2}>
              <SiteSelector
                sites={getActiveDomainUrls(space, true)}
                value={sites}
                onChange={setSites}
              />
              <ColumnSelector
                value={columns}
                onChange={setColumns}
                columns={COLUMNS}
              />
              <SearchInput
                value={search}
                onChange={setSearch}
                autoFocus
                placeholder="Search by URL or slug"
                width={300}
                size="small"
              />
            </PageToolbarSection>
            <PageToolbarSection flex={3} justifyContent="flex-end">
              {tagHierarchy && (
                <FilterTagsButton
                  selectedTags={selectedTags}
                  setSelectedTags={setSelectedTags}
                  tagHierarchy={tagHierarchy}
                />
              )}
              <ModeSelector value={mode} onChange={setMode} />
              <TimeframePickerDense
                value={timeframe}
                onChange={setTimeframe}
                options={options}
              />
            </PageToolbarSection>
          </>
        )}
      </PageToolbar>

      <SearchResultsRibbon
        pages={pages}
        hidden={!search && (!selectedTags || selectedTags.size === 0)}
      />

      <PagesTable
        pages={pages}
        loading={loading || loadingTagHierarchy || !tagHierarchy}
        sorter={sorter || sorters.clicked}
        sortDirection={direction}
        onSort={(k, dir) => setSort([sorters[k] || null, dir])}
        currency={currency}
        columns={columns}
        compare={compare}
        selected={selected}
        setSelected={setSelected}
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
        rowToHref={(d) => ROUTES.content.details.trends.url(d.href)}
        mode={mode}
        tagsById={tagsById}
      />
    </ContentPageBody>
  );
};

export const PageContentOverview = () => {
  useTrackMixpanelView('view_content_overview');
  const [canView] = useHasCurrentUserRequiredScopes([
    'reports.content.view',
    'tags.view'
  ]);

  return (
    <>
      <Helmet>
        <title>Content | Affilimate</title>
      </Helmet>
      {canView ? <PageContentOverviewPg /> : <NoPermissions />}
    </>
  );
};
