import { Paper, Typography } from '@material-ui/core';
import { TimeframePickerDense } from '../../../../components/TimeframePicker';

import {
  compact,
  /*groupBy,*/ keyBy,
  keys,
  mapValues,
  omit,
  uniq
} from 'lodash';

import moment from 'moment-timezone';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { AlertBox } from '../../../../components/AlertBox';
import { EarningsChartCard } from '../../../../components/Charts/EarningsChartCard';
import { ChartMode } from '../../../../components/Charts/EarningsChartCard/ChartModeSelector';
import { GraphMode } from '../../../../components/Charts/EarningsChartCard/GraphModeSelector';

import { SitesChartCard } from '../../../../components/Charts/SitesChartCard';
import {
  ItemSorters,
  ROW_HEIGHTS,
  RowsRenderer,
  UNKNOWN,
  useColumnsQueryParam,
  useSortQueryParam
} from '../../../../components/GroupableList';
import { Loader } from '../../../../components/Loader';
import { SiteWithColor } from '../../../../components/SiteWithColor';
import { IColumn } from '../../../../components/Table/Column';
import { ColumnSelector } from '../../../../components/Table/ColumnSelector';
import { CountCell, Dash } from '../../../../components/Table/CountCell';
import {
  useStandardOptions,
  useTimeframeFromUrl
} from '../../../../components/TimeframePicker';
import {
  EMPTY_COUNTER,
  ICounter,
  Timeframe,
  isSameTimeframe
} from '../../../../domainTypes/analytics';
import { CurrencyCode } from '../../../../domainTypes/currency';
import {
  EMPTY_EARNING,
  EarningsArgsGroupedInTimeframe,
  EarningsRespGroupedInTimeframe,
  IEarning,
  SalesFilterArgs,
  addOneEarningToAnother,
  toEarningFromMinimal
} from '../../../../domainTypes/performance';
import { styled } from '../../../../emotion';
import { Centered } from '../../../../layout/Centered';
import {
  DEFAULT_OFFSET,
  DEFAULT_TOOLBAR_HEIGHT,
  PageToolbar,
  PageToolbarSection
} from '../../../../layout/PageToolbar';
import { useRoutes, useTypedStringQueryParam } from '../../../../routes';
import {
  allTime,
  getEpc,
  getRpm,
  toComparableTimeframe,
  useAnalyticsDataByOrigin
} from '../../../../services/analytics';
import { useCurrentUser } from '../../../../services/currentUser';
import {
  useAsLoadingObject,
  useCombineLoadingValues3,
  useMappedLoadingValue
} from '../../../../services/db';

import {
  useEarnings,
  useOriginTimeseriesData
} from '../../../../services/sales/earnings';
import {
  msToTimeframe,
  timeframeToMs,
  toMoment
} from '../../../../services/time';
import { useSpaceCurrency } from '../../../../services/useSpaceCurrency';
import { PerformancePageBody } from '../../components/PerformancePageBody';
import { WithHoverIndicator } from '../../../../components/WithHoverIndicator';
import { createSiteFilterDefinition } from '../../../../components/analytics_v2/Filters/filters';

type ColumnName =
  | 'name'
  | 'pageviews'
  | 'clicks'
  | 'epc'
  | 'rpm'
  | 'earnings'
  | 'sales';

type Column = IColumn<
  EarningsAndAnalyticsByOriginRow,
  ColumnName,
  {
    compare: boolean;
  }
>;

type EarningsAndAnalyticsByOriginRow = {
  analytics: {
    prev: ICounter;
    curr: ICounter;
  };
  origin: string;
  earnings: {
    prev: IEarning;
    curr: IEarning;
  };
};

type EarningsByOriginRow = {
  origin: string;
  curr: IEarning;
  prev: IEarning;
};

const COLUMNS: Column[] = [
  {
    key: 'name',
    head: () => 'Site',
    cell: (p) => {
      return (
        <WithHoverIndicator>
          <SiteWithColor origin={p.origin} />
        </WithHoverIndicator>
      );
    },
    align: 'left',
    sortable: true,
    width: 200,
    flexGrow: 2
  },
  {
    key: 'pageviews',
    head: () => 'Pageviews',
    headInfo: () =>
      'Pageviews specifically to affiliate commerce content during the selected timeframe. Excludes pageviews to pages without affiliate links.',
    cell: (p) => {
      if (
        p.analytics.prev.pageViews === 0 &&
        p.analytics.curr.pageViews === 0
      ) {
        return <Dash />;
      }
      return (
        <CountCell
          before={p.analytics.prev.pageViews}
          after={p.analytics.curr.pageViews}
          compare={true}
        />
      );
    },
    align: 'right',
    sortable: true,
    width: 100,
    flexGrow: 1
  },
  {
    key: 'clicks',
    head: () => 'Clicks',
    cell: (p) => {
      if (p.analytics.prev.clicked === 0 && p.analytics.curr.clicked === 0) {
        return <Dash />;
      }
      return (
        <CountCell
          before={p.analytics.prev.clicked}
          after={p.analytics.curr.clicked}
          compare={true}
        />
      );
    },
    headInfo: () =>
      'How many clicks this site sent to affiliate links during the selected timeframe.',
    align: 'right',
    sortable: true,
    width: 100,
    flexGrow: 1
  },
  {
    key: 'epc',
    head: () => 'EPC',
    cell: (p, o) => {
      if (
        !p ||
        (p.analytics.prev.clicked === 0 && p.analytics.curr.clicked === 0)
      ) {
        return <Dash />;
      }
      const currEpc = getEpc(p.analytics.curr.clicked, p.earnings.curr.total);
      const prevEpc = getEpc(p.analytics.prev.clicked, p.earnings.prev.total);
      return (
        <CountCell
          before={prevEpc}
          after={currEpc}
          currency={p.earnings.curr.currency}
          compare={o.compare}
        />
      );
    },
    headInfo: () =>
      'Earnings per click (EPC). How much you earned on average for every click sent to this site during the selected timeframe.',
    align: 'right',
    sortable: true,
    width: 100,
    flexGrow: 1
  },
  {
    key: 'rpm',
    head: () => 'RPM',
    headInfo: () => `
      Revenue per one-thousand pageviews. If you drive 1,000 pageviews to this site, the amount of revenue to expect.
    `,
    cell: (p) => {
      if (!p.analytics.curr.clicked) {
        return <Dash />;
      }
      const rpmBefore = getRpm(
        p.analytics.prev.pageViews,
        p.earnings.prev.total || 0
      );

      const rpmAfter = getRpm(
        p.analytics.curr.pageViews,
        p.earnings.curr.total || 0
      );

      return (
        <CountCell
          before={rpmBefore}
          after={rpmAfter}
          compare={true}
          currency={p.earnings.curr.currency}
        />
      );
    },
    align: 'right',
    sortable: true,
    width: 100,
    flexGrow: 2
  },
  {
    key: 'earnings',
    head: () => 'Revenue',
    cell: (p, o) => (
      <CountCell
        before={p.earnings.prev.total}
        after={p.earnings.curr.total}
        compare={o.compare}
        currency={p.earnings.curr.currency}
      />
    ),
    headInfo: () =>
      'How much you earned in Final or Pending commissions, minus Refunded commissions, posted during this timeframe.',
    align: 'right',
    sortable: true,
    width: 100,
    flexGrow: 2
  },
  {
    key: 'sales',
    head: () => 'Gross sales',
    cell: (p, o) => {
      if (
        p.earnings.curr.saleValue.total === 0 &&
        p.earnings.prev.saleValue.total === 0
      ) {
        return <Dash size={16} />;
      }
      return (
        <CountCell
          before={p.earnings.prev.saleValue.total}
          after={p.earnings.curr.saleValue.total}
          compare={o.compare}
          currency={p.earnings.curr.currency}
        />
      );
    },
    headInfo: () =>
      'How much revenue you drove for advertisers from this site during the selected time period, considering Final, Pending, and Refunded transactions. Not all platforms and advertisers report this figure.',
    align: 'right',
    sortable: true,
    width: 100,
    flexGrow: 2
  }
];

const SORTERS: ItemSorters<EarningsAndAnalyticsByOriginRow> = {
  name: {
    key: 'name',
    items: { sort: (p) => p.origin, dir: 'asc' }
  },
  pageviews: {
    key: 'pageviews',
    items: { sort: (p) => p.analytics.curr.pageViews, dir: 'desc' }
  },
  clicks: {
    key: 'clicks',
    items: { sort: (p) => p.analytics.curr.clicked, dir: 'desc' }
  },
  epc: {
    key: 'epc',
    items: {
      sort: (p) => getEpc(p.analytics.curr.clicked, p.earnings.curr.total),
      dir: 'desc'
    }
  },
  rpm: {
    key: 'rpm',
    items: {
      sort: (p) => getRpm(p.analytics.curr.pageViews, p.earnings.curr.total),
      dir: 'desc'
    }
  },
  earnings: {
    key: 'earnings',
    items: { sort: (p) => p.earnings.curr.total, dir: 'desc' }
  },
  sales: {
    key: 'sales',
    items: { sort: (p) => p.earnings.curr.saleValue.total, dir: 'desc' }
  }
};

const HEIGHT = 700;

// todo: cool with that?
const TableSpace = styled('div')`
  margin-bottom: ${(p) => p.theme.spacing(6)}px;
`;

const Grid = styled('div')`
  display: grid;
  grid-column-gap: ${(p) => p.theme.spacing(3)}px;
  grid-row-gap: ${(p) => p.theme.spacing(6)}px;
  grid-template-columns: 1.75fr 3fr;
  margin-bottom: ${(p) => p.theme.spacing(6)}px;
  min-height: 500px;

  ${(p) => p.theme.breakpoints.down('md')} {
    grid-column-gap: ${(p) => p.theme.spacing(1)}px;
    grid-row-gap: ${(p) => p.theme.spacing(2)}px;
    grid-template-columns: 1fr 1fr;
  }

  ${(p) => p.theme.breakpoints.down('sm')} {
    grid-column-gap: ${(p) => p.theme.spacing(1)}px;
    grid-row-gap: ${(p) => p.theme.spacing(2)}px;
    grid-template-columns: 1fr;
  }
`;

const useEarningsPerOrigin = (
  spaceId: string,
  timeframe: Timeframe,
  compare: boolean,
  currency: CurrencyCode,
  q: SalesFilterArgs
) => {
  const queries: EarningsArgsGroupedInTimeframe[] = useMemo(() => {
    const uniqQuery = omit(q, 'dates');
    return compact([
      {
        type: 'groupedInTimeframe',
        d: {
          ...uniqQuery,
          groupBy: ['origin'],
          dates: { ...timeframeToMs(timeframe), column: 'sale_date' },
          currency
        }
      },
      compare && {
        type: 'groupedInTimeframe',
        d: {
          ...uniqQuery,
          groupBy: ['origin'],
          dateColumn: 'sale_date',
          dates: {
            ...timeframeToMs(toComparableTimeframe(timeframe)),
            column: 'sale_date'
          },
          currency
        }
      }
    ]);
  }, [timeframe, compare, currency, q]);

  return useMappedLoadingValue(
    useEarnings<EarningsRespGroupedInTimeframe[]>(spaceId, queries, currency),
    (r) => {
      // prev is empty if we are in non-compare mode
      const [curr, prev = {}] = r.res.map((x) => {
        return mapValues(
          keyBy(x.d, (t) => {
            return (t.group['origin'] as string) || UNKNOWN;
          }),
          (v) => toEarningFromMinimal(v.d)
        );
      });
      const allKeys = uniq([...keys(curr), ...keys(prev)]);
      return allKeys.map<EarningsByOriginRow>((key) => {
        //mapping to EarningsByOriginRow
        return {
          origin: key,
          curr: curr[key] || EMPTY_EARNING(currency),
          prev: prev[key] || EMPTY_EARNING(currency)
        };
      });
    }
  );
};

const DEFAULT_COLUMNS = COLUMNS.map((c) => c.key);

export const PagePerformanceChannelsContent = () => {
  const { ROUTES } = useRoutes();
  const { space } = useCurrentUser();
  const { id: spaceId } = space;
  const { options, defaultOption } = useStandardOptions();
  const [timeframe, setTimeframe] = useTimeframeFromUrl(defaultOption.value);
  const timeframeToCompare = useMemo(() => toComparableTimeframe(timeframe), [
    timeframe
  ]);
  const compare = !isSameTimeframe(allTime(), timeframe);
  const currency = useSpaceCurrency();
  const [graphMode, setGraphMode] = useTypedStringQueryParam<GraphMode>(
    'graph_mode',
    'origin'
  );

  const [chartMode, setChartMode] = useTypedStringQueryParam<ChartMode>(
    'chart_mode',
    'barChart'
  );

  const q: SalesFilterArgs = useMemo(() => {
    const filters: SalesFilterArgs = { dates: timeframeToMs(timeframe) };
    return filters;
  }, [timeframe]);

  const [d, loadingTimeseries] = useOriginTimeseriesData(spaceId, q, currency);

  const {
    d: rows,
    loading: loadingRows,
    error: loadingRowsError
  } = useAsLoadingObject(
    useMappedLoadingValue(
      useCombineLoadingValues3(
        useAnalyticsDataByOrigin(space.id, timeframe),
        useAnalyticsDataByOrigin(space.id, timeframeToCompare),
        useEarningsPerOrigin(spaceId, timeframe, compare, currency, q)
      ),
      ([curr, prev, earnings]) => {
        const output: EarningsAndAnalyticsByOriginRow[] = [];
        const youtubeDomains = [
          'www.youtube.com',
          'youtube.com',
          'm.youtube.com'
        ];

        const publisherDomains = space.domains
          .filter((d) => d.active)
          .map((d) => new URL(d.url).hostname);

        const earningsByOrigin = keyBy(earnings, (e) => e.origin);

        // Probably need to base the data on the sites the user has added,
        // instead of on the presence of analytics data.
        for (const origin of publisherDomains) {
          const row: EarningsAndAnalyticsByOriginRow = {
            analytics: {
              prev: prev[origin] || EMPTY_COUNTER(),
              curr: curr[origin] || EMPTY_COUNTER()
            },
            origin: origin,
            earnings: earningsByOrigin[origin] || {
              prev: EMPTY_EARNING(currency),
              curr: EMPTY_EARNING(currency)
            }
          };
          // Handle mismatch of www vs non-www for earnings
          // data which can provide inconsistent origin urls for earnings

          const alternateOrigin =
            origin.indexOf('www.') === 0
              ? origin.substr('www.'.length, origin.length)
              : `www.${origin}`;

          const altEarnings = earningsByOrigin[alternateOrigin];

          if (altEarnings) {
            row.earnings.prev = addOneEarningToAnother(
              row.earnings.prev,
              altEarnings.prev
            );
            row.earnings.curr = addOneEarningToAnother(
              row.earnings.curr,
              altEarnings.curr
            );
          }

          output.push(row);
        }

        // Add a special case for YouTube earnings
        const youtubeEarnings = earnings.filter(
          (e) => youtubeDomains.indexOf(e.origin) !== -1
        );

        if (
          youtubeEarnings.length &&
          !publisherDomains.includes('www.youtube.com')
        ) {
          const ytRow: EarningsAndAnalyticsByOriginRow = {
            analytics: {
              prev: EMPTY_COUNTER(),
              curr: EMPTY_COUNTER()
            },
            origin: `www.youtube.com`,
            earnings: {
              curr: youtubeEarnings.reduce<IEarning>(
                (memo, e) => addOneEarningToAnother(memo, e.curr),
                EMPTY_EARNING(currency)
              ),
              prev: youtubeEarnings.reduce<IEarning>(
                (memo, e) => addOneEarningToAnother(memo, e.prev),
                EMPTY_EARNING(currency)
              )
            }
          };
          output.push(ytRow);
        }

        // And, collect revenue from other sources into an Other category
        const otherEarnings = earnings.filter(
          (e) =>
            [...publisherDomains, ...youtubeDomains].indexOf(e.origin) === -1
        );

        if (otherEarnings.length) {
          const otherRow = {
            analytics: {
              prev: EMPTY_COUNTER(),
              curr: EMPTY_COUNTER()
            },
            origin: 'Other',
            earnings: {
              curr: otherEarnings.reduce<IEarning>(
                (memo, e) => addOneEarningToAnother(memo, e.curr),
                EMPTY_EARNING(currency)
              ),
              prev: otherEarnings.reduce<IEarning>(
                (memo, e) => addOneEarningToAnother(memo, e.prev),
                EMPTY_EARNING(currency)
              )
            }
          };
          output.push(otherRow);
        }

        return output;
      }
    )
  );

  const [[sorter, direction], setSort] = useSortQueryParam('sort', SORTERS);
  const [columnNames, setColumns] = useColumnsQueryParam(
    'columns',
    DEFAULT_COLUMNS
  );
  const columns = useMemo(
    () =>
      columnNames ? COLUMNS.filter((c) => columnNames.has(c.key)) : COLUMNS,
    [columnNames]
  );

  const otherProps = useMemo(
    () => ({ compare /*clickData, loadingClickData */ }),
    [
      compare
      /*
    clickData,
    loadingClickData
   */
    ]
  );

  // Use this to show a message to new users about their product
  // analytics data
  const user = useCurrentUser();
  const isNewSpace =
    moment().diff(toMoment(user.space.createdAt), 'hours') < 48;

  if (loadingRowsError) {
    return (
      <Paper style={{ height: '100%' }}>
        <Centered height={HEIGHT}>Something went wrong.</Centered>
      </Paper>
    );
  }

  return (
    <PerformancePageBody noTopPadding>
      <PageToolbar sticky offset={DEFAULT_OFFSET}>
        <PageToolbarSection flex={2}>
          <Typography
            variant="h6"
            component="span"
            style={{
              marginRight: '9px',
              position: 'relative',
              fontWeight: 'bold',
              top: '-2px'
            }}
          >
            Channels
          </Typography>
          <ColumnSelector
            value={columnNames}
            onChange={setColumns}
            columns={COLUMNS}
          />
        </PageToolbarSection>

        <PageToolbarSection flex={1} justifyContent="flex-end">
          <TimeframePickerDense
            value={timeframe}
            onChange={setTimeframe}
            options={options}
          />
        </PageToolbarSection>
      </PageToolbar>

      {isNewSpace && (
        <AlertBox variant="success" style={{ marginBottom: '36px' }}>
          Your affiliate network and program-level analytics will begin
          collecting here.
          <br />
          <br />
          In the mean time, try{' '}
          <Link
            style={{ borderBottom: '1px solid black' }}
            to={ROUTES.performanceNew.transactions.url()}
          >
            setting up reporting
          </Link>{' '}
          with your affiliate networks and programs.
        </AlertBox>
      )}
      <div>
        <Grid>
          <SitesChartCard
            data={
              rows
                ? rows.map((e) => ({
                    origin: e.origin,
                    earnings: e.earnings.curr
                  }))
                : []
            }
            loading={loadingRows}
          />
          <EarningsChartCard
            data={d ? d.data : undefined}
            loading={loadingTimeseries}
            currency={currency}
            graphMode={graphMode}
            setGraphMode={setGraphMode}
            chartMode={chartMode}
            setChartMode={setChartMode}
            timeframe={msToTimeframe(d ? d.dates : q.dates)}
          />
        </Grid>
        <TableSpace>
          {loadingRows || !rows ? (
            <Paper>
              <Loader height={HEIGHT} />
            </Paper>
          ) : (
            <RowsRenderer
              columns={columns}
              sorter={sorter || SORTERS.earnings}
              sortDirection={direction}
              onHeadClick={(c, dir) => setSort([SORTERS[c.key] || null, dir])}
              rows={rows}
              otherProps={otherProps}
              variant="contained"
              renderHead={true}
              headProps={{
                sticky: true,
                offset: DEFAULT_OFFSET + DEFAULT_TOOLBAR_HEIGHT
              }}
              rowToHref={(p) =>
                ROUTES.performanceNew.advertisers.overview.url({
                  filters: [createSiteFilterDefinition([p.origin])]
                })
              }
              rowToKey={(p) => p.origin}
              rowHeight={ROW_HEIGHTS.dense}
            />
          )}
        </TableSpace>
      </div>
    </PerformancePageBody>
  );
};
