import { Chip } from '@material-ui/core';
import React from 'react';
import { Check as FilterAppliedIcon, ChevronDown } from 'react-feather';
import {
  MultiSelector,
  MultiSelectorOption
} from '../../../components/MultiSelector';
import {
  AmazonAvailability,
  AMAZON_AVAILABILITIES,
  getAmazonLabel
} from '../../../domainTypes/linkCheckV2';
import { css } from '../../../emotion';
import {
  queryParamToList,
  setToQueryParam,
  useQueryParam
} from '../../../routes';
import { isSameSet } from '../../../services/set';

export const BROKEN_AMAZON_AVAILABILITIES = AMAZON_AVAILABILITIES.filter(
  (a) => a !== AmazonAvailability.OK
);

const options: MultiSelectorOption<
  AmazonAvailability
>[] = BROKEN_AMAZON_AVAILABILITIES.sort().map((issue) => {
  return {
    label: getAmazonLabel(issue),
    value: issue
  };
});

export const useAmazonIssuesQueryParam = (
  param: string,
  defaultIssues: AmazonAvailability[] = AMAZON_AVAILABILITIES
) => {
  return useQueryParam(
    param,
    (p) => new Set(p ? queryParamToList<AmazonAvailability>(p) : defaultIssues),
    (cs) =>
      isSameSet(cs, new Set(defaultIssues)) ? undefined : setToQueryParam(cs)
  );
};

export const IssueSelector = ({
  value,
  onChange
}: {
  value: Set<AmazonAvailability>;
  onChange: (nextValue: Set<AmazonAvailability>) => void;
}) => {
  const isApplied = value.size !== BROKEN_AMAZON_AVAILABILITIES.length;

  return (
    <MultiSelector
      value={value}
      onChange={onChange}
      options={options}
      legend="Issues"
      allowFocusing
      allOption={<strong>All statuses</strong>}
    >
      <Chip
        color={isApplied ? 'primary' : 'default'}
        onDelete={
          isApplied
            ? () => {
                onChange(new Set(BROKEN_AMAZON_AVAILABILITIES));
              }
            : undefined
        }
        classes={{
          colorPrimary: !isApplied
            ? undefined
            : css((t) => ({
                backgroundColor: t.palette.common.black,
                color: t.palette.common.white
              })),
          label: css(() => ({
            display: 'inline-flex',
            alignItems: 'center'
          }))
        }}
        label={
          isApplied ? (
            <>
              <FilterAppliedIcon size={18} /> &nbsp; Issues Filtered
            </>
          ) : (
            <>
              Issues
              <ChevronDown
                size={18}
                color={isApplied ? '#e0e0e0' : 'black'}
                className={css((t) => ({ marginLeft: t.spacing(1) }))}
              />
            </>
          )
        }
      />
    </MultiSelector>
  );
};
