import { CompletedCampaign } from '../service';
import { goalPercent, TotalsObject } from '../service/totals';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { formatCurrency } from '../../../components/Number';
import { useSpaceCurrency } from '../../../services/useSpaceCurrency';

export const CompletedCampaignSummary: React.FC<{
  campaign: CompletedCampaign;
  totalValues: TotalsObject;
}> = ({ campaign, totalValues }) => {
  const currency = useSpaceCurrency();

  const roas = <strong>{campaign.goals[0].amount}x ROAS</strong>;
  const earnings = <strong>{formatCurrency(totalValues.gmv, currency)}</strong>;
  if (totalValues.gmv > totalValues.gmvGoal) {
    return (
      <Typography variant="body2" paragraph color="textSecondary">
        You've reached your planned goal of {roas} and earned {earnings}.
        Congratulations!
      </Typography>
    );
  }

  return (
    <Typography variant="body2" paragraph color="textSecondary">
      You've reached <strong>{goalPercent(totalValues)}</strong> of your {roas}{' '}
      planned goal and earned {earnings}. Keep up the good work!
    </Typography>
  );
};
