import React, { useCallback } from 'react';
import { RowsRenderer } from '../../../../../../components/GroupableList';
import { Loader } from '../../../../../../components/Loader';
import { Centered } from '../../../../../../layout/Centered';
import { Card } from '@material-ui/core';
import { useRoutes } from '../../../../../../routes';
import { WithHoverIndicator } from '../../../../../../components/WithHoverIndicator';
import { ReferrerGroupName } from './ReferrerGroupName';
import {
  ALL_COLUMN_NAMES,
  ColumnDefinitions,
  DEFAULT_VISIBLE_COLUMN_NAMES,
  useTable
} from '../../../../../../components/analytics_v2/Table';
import { Metric } from '../../../../../../services/analyticsV2/metrics';
import { AnalyticsResponseRowWithComparison } from '../../../../../../domainTypes/analytics_v2';
import { FlexContainer } from '../../../../../../layout/Flex';
import { ColumnSelector } from '../../../../../../components/Table/ColumnSelector';
import { useReferrerGroupRows } from '../services/referrer-groups';
import { ReferrerGroup } from '../../../../../../domainTypes/referrers';

const customColumns = ['group'] as const;
type CustomColumns = typeof customColumns[number];
type Column = CustomColumns | Metric;

const availableColumns: Column[] = [...customColumns, ...ALL_COLUMN_NAMES];
const defaultVisibleColumns: Column[] = [
  ...customColumns,
  ...DEFAULT_VISIBLE_COLUMN_NAMES
];

const rowToKey = (p: AnalyticsResponseRowWithComparison) =>
  p.group.referrer_entry_origin;

const columnDefinitions: ColumnDefinitions<CustomColumns> = {
  group: {
    column: {
      key: 'group',
      head: () => 'Referrer group',
      cell: (p) => (
        <WithHoverIndicator>
          <ReferrerGroupName
            group={p.group.referrer_entry_origin as ReferrerGroup}
          />
        </WithHoverIndicator>
      ),
      align: 'left',
      sortable: true,
      width: 200,
      flexGrow: 4
    },
    sorter: {
      key: 'group',
      items: { sort: (p) => p.group.referrer_entry_origin, dir: 'asc' }
    }
  }
};

export const GroupsTable: React.FC = () => {
  const { ROUTES } = useRoutes();
  const { tableProps, columnSelectorProps, orderBy, metrics } = useTable(
    availableColumns,
    columnDefinitions,
    {
      pageSize: 10,
      defaultSortColumn: 'c',
      defaultVisibleColumns
    }
  );
  const rowToHref = useCallback(
    (d: AnalyticsResponseRowWithComparison) =>
      ROUTES.content.referrers.groupDetails.url(d.group.referrer_entry_origin),
    [ROUTES.content.referrers.groupDetails]
  );

  const [totals, loading] = useReferrerGroupRows(metrics, orderBy);

  return (
    <>
      <FlexContainer justifyContent="flex-end">
        <ColumnSelector {...columnSelectorProps} />
      </FlexContainer>
      {!totals || loading ? (
        <Card>
          <Centered height={300}>
            <Loader size={24} />
          </Centered>
        </Card>
      ) : (
        <RowsRenderer
          {...tableProps}
          rows={totals.rows}
          renderHead={true}
          headProps={{
            sticky: true,
            offset: 56
          }}
          rowToKey={rowToKey}
          rowToHref={rowToHref}
        />
      )}
    </>
  );
};
