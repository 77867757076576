import { sortBy } from 'lodash';
import React from 'react';
import { AdvertiserWithColor } from '../../../../components/AdvertiserWithColor';
import { Loader } from '../../../../components/Loader';
import {
  getTrend,
  Timeframe,
  UNKNOWN
} from '../../../../domainTypes/analytics';
import { CurrencyCode } from '../../../../domainTypes/currency';
import {
  EARNING_MINIMAL_FIELD_SET,
  SalesFilterArgs
} from '../../../../domainTypes/performance';
import { useErrorLoggerWithLabel } from '../../../../hooks/useErrorLogger';
import { useMappedLoadingValue } from '../../../../services/db';
import { CHART_HEIGHT, MAX_CONTENT_ROWS } from './constants';
import { EarningsTable, EarningsTableRow } from './EarningsTable';
import { useEarningsPerAdvertiser } from '../../../../services/advertisers';

const COMMISSIONS_ONLY: Pick<SalesFilterArgs, 'fields'> = {
  fields: EARNING_MINIMAL_FIELD_SET.COMMISSIONS_ONLY
};

export const TopAdvertisers = ({
  spaceId,
  currency,
  timeframe
}: {
  spaceId: string;
  currency: CurrencyCode;
  timeframe: Timeframe;
}) => {
  const [rows, loading, error] = useMappedLoadingValue(
    useEarningsPerAdvertiser(
      spaceId,
      timeframe,
      true,
      currency,
      COMMISSIONS_ONLY
    ),
    (ds) => {
      return sortBy(ds, (d) => -d.curr.total)
        .slice(0, MAX_CONTENT_ROWS)
        .map<EarningsTableRow>((d) => {
          const advertiserName =
            d.advertiserName.toLowerCase() === UNKNOWN.toLowerCase()
              ? `${d.advertiserName} (${d.partner.name})`
              : d.advertiserName;

          return {
            key: `${d.partner.key}-${d.advertiserId}`,
            title: (
              <AdvertiserWithColor
                advertiserName={advertiserName}
                partnerKey={d.partner.key}
              />
            ),
            earnings: {
              count: d.curr.total,
              lastCount: d.prev.total,
              trend: getTrend(d.prev.total, d.curr.total)
            }
          };
        });
    }
  );

  useErrorLoggerWithLabel('TopAdvertiser error', error);

  console.log('top advertisers', rows);
  if (loading) {
    return <Loader height={CHART_HEIGHT} />;
  }
  if (!rows) {
    return null;
  }
  return <EarningsTable rows={rows} currency={currency} />;
};
