import moment from 'moment-timezone';
import {
  getTrackingId,
  SaleStatus
} from '../../../../../domainTypes/performance';
import { getKnownPartnerForKeyUnsafe } from '../../../../../services/partner';
import { getActiveDomainUrls } from '../../../../../services/space';
import { fromMoment } from '../../../../../services/time';
import { withoutProtocolOrWww } from '../../../../../services/url';
import { toCents } from '../helpers';
import { IFileReportHandler } from '../types';

const toStatus = (
  bookingStatus: 'Confirmed' | 'Departed' | 'Cancelled By Customer' | 'Charged',
  finalCommission: string
): SaleStatus => {
  if (['Cancelled'].indexOf(bookingStatus) !== -1) {
    return 'Cancelled';
  }
  if (finalCommission !== 'Awaiting Finalization') {
    return 'Final';
  }
  return 'Pending';
};

const toCommission = (estimatedCommission: string, finalCommission: string) => {
  if (finalCommission !== 'Awaiting Finalization') {
    return fromDollars(finalCommission);
  }
  if (estimatedCommission === 'No Estimate Yet') {
    return 0;
  }
  if (estimatedCommission.startsWith('$')) {
    return fromDollars(estimatedCommission);
  }
  return 0;
};

const fromDollars = (amount: string) => {
  const floatNumber = amount.substr(1, amount.length);
  return toCents(floatNumber);
};

const toDevice = (device: string) => {
  return device === 'Website' ? 'desktop' : 'mobile';
};

export const AGODA: IFileReportHandler<string> = {
  type: 'CSV',
  partnerKey: 'agoda',
  parser: {
    name: 'All bookings report',
    csvHeaders:
      'Booking ID,Site ID,Site Name,Property Name,Property ID,Origin,Destination City,Destination Country,Booking Date,Check-in Date,Check-out Date,Payment Date,DMC,Device Breakdown,tag',
    matches: (text, expectedHeaders) => text.indexOf(expectedHeaders) === 0,
    processRows: async (
      rows,
      { space, partnerKey, reportId, integrationId }
    ) => {
      return rows.slice(1, rows.length - 1).map((row) => {
        const tz = 'UTC';
        const dateFormat = 'M/D/YYYY';

        const [
          bookingId,
          // eslint-disable-next-line
          siteId,
          // eslint-disable-next-line
          siteName,
          productName,
          productId,
          // eslint-disable-next-line
          customerCountry,
          // eslint-disable-next-line
          destinationCity,
          // eslint-disable-next-line
          destinationCountry,
          bookingDate,
          // eslint-disable-next-line
          checkInDate,
          // eslint-disable-next-line
          checkOutDate,
          // eslint-disable-next-line
          paymentDate,
          // eslint-disable-next-line
          dmc,
          deviceType,
          tag,
          margin,
          estimatedCommission,
          commissionRate,
          finalCommission,
          bookingStatus
        ] = row;

        const commission = toCommission(estimatedCommission, finalCommission);
        const saleDate = moment(bookingDate, dateFormat, tz);
        const completionDate = moment(checkOutDate, dateFormat, tz);
        const status = toStatus(bookingStatus, finalCommission);
        const commissionPercent =
          parseInt(commissionRate.substr(1, commissionRate.length)) / 100;
        const revenue = fromDollars(margin);
        const device = toDevice(deviceType);

        // Custom mapping for space ID sNOsY8KAM
        const siteNames = {
          TravelGeekery: 'https://www.travelgeekery.com'
        } as any;

        let origin = siteNames[siteName] || null;
        const sites = getActiveDomainUrls(space, false);

        // In case origin matches existing space but without www, use that
        sites.forEach((site) => {
          if (
            origin &&
            withoutProtocolOrWww(site) === withoutProtocolOrWww(origin)
          ) {
            origin = site;
          }
        });

        return {
          saleId: bookingId,
          trackingId: getTrackingId(tag),
          trackingLabel: tag,
          reportId,
          integrationId,
          saleDate: fromMoment(saleDate),
          origin,
          completionDate: completionDate ? fromMoment(completionDate) : null,
          status,
          partnerKey,
          partnerProductName: productName,
          partnerProductId: productId,
          amount: {
            currency: 'USD',
            price: null,
            revenue,
            commission
          },
          device,
          commissionPercent,
          payoutId: null,
          payoutDate: null,
          payoutStatus: null,
          lastModified: null,
          coupon: '',
          saleType: 'unknown',
          advertiserId: partnerKey,
          advertiserName: getKnownPartnerForKeyUnsafe(partnerKey).name
        };
      });
    }
  }
};
