import { generateToDocFn } from '../../domainTypes/document';
import { Payout } from '../../domainTypes/payout';
import { FS } from '../../versions';
import { store } from '../db';
import { isFeatureEnabled } from '../features';
import {
  CollectionListener,
  createCollectionListenerStore,
  useCollectionListener
} from '../firecache/collectionListener';

export const NETWORKS_WITH_MATCHING_PAYOUT_IDS = [
  'impact',
  'skimlinks',
  'partnerize',
  'shareasale',
  'pepperjam',
  'rakuten',
  'pap',
  'redventures'
];

export const toPayoutDoc = generateToDocFn<Payout>();

const payoutsStore = createCollectionListenerStore(
  (spaceId) =>
    new CollectionListener(
      store().collection(FS.payouts).where('spaceId', '==', spaceId),
      toPayoutDoc
    )
);

export const usePayouts = (spaceId: string) =>
  useCollectionListener(payoutsStore(spaceId));

export const useHasPayouts = () => isFeatureEnabled('PAYOUTS_V1');
