import React, { useMemo } from 'react';
import { IEarning } from '../../../domainTypes/performance';
import { CurrencyCode } from '../../../domainTypes/currency';
import { Loader } from '../../Loader';
import assertNever from 'assert-never';
import { formatChartCurrency, formatChartNumber } from '../Util';
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  TooltipProps
} from 'recharts';
import { Typography } from '@material-ui/core';
import { CustomTooltip } from '../CustomTooltip';
import { truncate } from 'lodash';
export type EarningsBarChartData = {
  key: string;
  name: string;
  earnings: IEarning;
  color: string;
};

type Props = {
  data: EarningsBarChartData[] | void;
  loading: boolean;
  currency: CurrencyCode;
  count: number;
  animate?: boolean;
  height?: number;
  aspect?: number;
  metric: 'earnings' | 'salesCount' | 'saleValue' | 'quantity' | 'orderCount';
  putUnknownIntoOther?: boolean;
  hideLegend?: boolean;
  hideCenterLabel?: boolean;
};

const HEIGHT = 390;

export const EarningsBarChart = React.memo(
  ({
    data,
    loading,
    currency,
    animate,
    height = HEIGHT,
    aspect = 1.5,
    metric
  }: Props) => {
    const barSize = useMemo(() => {
      const size = Math.round(200 / (data || []).length);
      return Math.min(size, 50);
    }, [data]);

    if (loading || !data) {
      return <Loader height={height} />;
    }

    const getVal = (x: EarningsBarChartData) => {
      const val =
        metric === 'earnings'
          ? x.earnings.total
          : metric === 'salesCount'
          ? x.earnings.orderCount.total
          : metric === 'saleValue'
          ? x.earnings.saleValue.total
          : metric === 'quantity'
          ? x.earnings.quantity.total
          : metric === 'orderCount'
          ? x.earnings.orderCount.total
          : assertNever(metric);

      return val;
    };

    const formatter = (x: number) => {
      const res =
        metric === 'earnings'
          ? formatChartCurrency(x, currency, { excludeCents: true })
          : metric === 'salesCount'
          ? formatChartNumber(x)
          : metric === 'saleValue'
          ? formatChartCurrency(x, currency, { excludeCents: true })
          : metric === 'quantity'
          ? formatChartNumber(x)
          : metric === 'orderCount'
          ? formatChartNumber(x)
          : assertNever(metric);
      return res;
    };

    return (
      <ResponsiveContainer width="99%" height="auto" aspect={aspect}>
        <BarChart
          width={150}
          height={40}
          data={data}
          layout="vertical"
          barCategoryGap={1}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 50
          }}
        >
          <Bar
            dataKey={(d) => getVal(d)}
            label={false}
            barSize={barSize}
            background={false}
          >
            {(data || []).map((v) => (
              <Cell key={`cell-${v.key}`} fill={v.color} />
            ))}
          </Bar>
          <YAxis
            type="category"
            tick={{
              fill: '#9b9b9b',
              fontSize: 14
            }}
            width={180}
            dataKey="name"
            tickFormatter={(name) => truncate(name, { length: 25 })}
          />
          <XAxis
            type="number"
            stroke="none"
            tick={{
              fill: '#9b9b9b',
              fontSize: 12
            }}
            tickFormatter={(value: number) => formatter(value)}
          />
          <Tooltip
            cursor={false}
            labelFormatter={(label: TooltipProps['label']) => (
              <Typography
                variant="body1"
                component="span"
                style={{ padding: 0, margin: 0 }}
              >
                {label}
              </Typography>
            )}
            content={<CustomTooltip size="small" />}
            formatter={(value) => formatter(value as number)}
          />
        </BarChart>
      </ResponsiveContainer>
    );
  }
);
