import {
  ColumnDefinitions,
  useTable
} from '../../../../components/analytics_v2/Table';
import { Centered } from '../../../../layout/Centered';
import { Loader } from '../../../../components/Loader';
import { RowsRenderer } from '../../../../components/GroupableList';
import React, { useMemo } from 'react';
import { useAnalyticsQueryV2 } from '../../../../services/analyticsV2/query';
import { useCurrentUser } from '../../../../services/currentUser';
import {
  AnalyticsOrderBy,
  AnalyticsQuery,
  AnalyticsResponseRowWithComparison,
  SelectableField
} from '../../../../domainTypes/analytics_v2';
import { useTimeframeWithRevision } from '../../../../components/analytics_v2/TimeframeWithRevisions';
import { FlagWithLabel } from '../../../../components/Flag';
import { Paper } from '@material-ui/core';

const customColumns = ['country'] as const;
const metrics = [
  'p',
  'c',
  'ctr',
  'epc_net',
  'quantity_net',
  'commission_sum_net',
  'gmv_sum_net'
] as const;
type CustomColumns = typeof customColumns[number];
type Metric = typeof metrics[number];
type Column = CustomColumns | Metric;

const columnDefinitions: ColumnDefinitions<CustomColumns> = {
  country: {
    column: {
      key: 'country',
      head: () => 'Country',
      cell: (p: AnalyticsResponseRowWithComparison) => {
        return <FlagWithLabel code={p.group.country} />;
      },
      align: 'left',
      sortable: true,
      width: 200,
      flexGrow: 2
    },
    sorter: {
      key: 'country',
      items: {
        sort: (p: AnalyticsResponseRowWithComparison) => p.group.country,
        dir: 'asc'
      }
    }
  }
};

const availableColumns: Column[] = [...customColumns, ...metrics];

const PAGE_SIZE = 10;

const useCountriesMetrics = (
  url: string,
  metrics: SelectableField[],
  orderBy: AnalyticsOrderBy
) => {
  const { space } = useCurrentUser();
  const { range, compare } = useTimeframeWithRevision(url);
  const query = useMemo<AnalyticsQuery>(() => {
    return {
      range,
      compare,
      select: metrics,
      groupBy: ['country'],
      orderBy: [orderBy],
      filters: [
        {
          field: 'country',
          condition: 'not in',
          values: ['']
        },
        {
          field: 'page_url',
          condition: 'in',
          values: [url]
        }
      ],
      paginate: {
        page: 1,
        limit: PAGE_SIZE
      }
    };
  }, [range, compare, metrics, orderBy, url]);

  return useAnalyticsQueryV2(space.id, query);
};

export const CountriesTable = ({ url }: { url: string }) => {
  const { tableProps, orderBy, metrics } = useTable(
    availableColumns,
    columnDefinitions,
    {
      pageSize: PAGE_SIZE,
      defaultSortColumn: 'c',
      sortQueryParamName: 'sort-countries'
    }
  );
  const [data, loading] = useCountriesMetrics(url, metrics, orderBy);
  if (!data || loading) {
    return (
      <Paper>
        <Centered height={350}>
          <Loader size={16} />
        </Centered>
      </Paper>
    );
  }

  return (
    <RowsRenderer
      {...tableProps}
      rows={data.rows}
      renderHead
      rowToKey={(d) => d.group.country}
    />
  );
};
