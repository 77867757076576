import moment from 'moment-timezone';
import {
  getTrackingId,
  SaleStatus
} from '../../../../../domainTypes/performance';
import { fromMoment, now } from '../../../../../services/time';
import { toCents, toPercent } from '../helpers';
import { IFileReportHandler } from '../types';

const DATE_FORMAT = 'YYYY-MM-DD';

const toDate = (dateStr: string) => {
  return moment.tz(dateStr, DATE_FORMAT, 'UTC');
};

const toStatus = (status: 'yes' | 'no'): SaleStatus => {
  if (status === 'no') {
    return 'Pending';
  }
  if (status === 'yes') {
    return 'Final';
  }
  return 'Unknown';
};

export const TAPFILIATE: IFileReportHandler<string> = {
  type: 'CSV',
  partnerKey: 'tapfiliate',
  parser: {
    name: 'Conversions and commissions',
    csvHeaders:
      'type,conversion_id,date,program,currency,amount,commission_amount,comission_type,commission_kind,approved,subid1,subid2,subid3,subid4,subid5',
    matches: (text, expectedHeaders) => text.indexOf(expectedHeaders) === 0,
    processRows: async (rows, { partnerKey, reportId, integrationId }) => {
      return rows
        .slice(1)
        .map((row) => {
          const [
            rowType,
            conversionId,
            date,
            program,
            currency,
            amount,
            commissionAmount,
            commissionType,
            commissionKind,
            approved,
            subid1,
            subid2,
            subid3,
            subid4,
            subid5
          ] = row;

          console.log(row);

          return {
            rowType,
            conversionId,
            date,
            program,
            currency,
            amount,
            commissionAmount,
            commissionType,
            commissionKind,
            approved,
            subid1,
            subid2,
            subid3,
            subid4,
            subid5
          };
        })
        .filter((r) => r.rowType === 'commission')
        .map((row) => {
          const currency = row.currency;

          const price = toCents(row.amount);
          const commission = toCents(row.commissionAmount);
          const saleDate = toDate(row.date);
          const status = toStatus(row.approved);
          const commissionPercent = toPercent(commission, price);
          const trackingLabel = row.subid5 || row.subid1 || '';

          return {
            saleId: row.conversionId,
            orderId: row.conversionId,
            trackingId: getTrackingId(trackingLabel),
            trackingLabel: trackingLabel,
            reportId,
            integrationId,
            saleDate: fromMoment(saleDate),
            completionDate: null,
            origin: null,
            status,
            partnerKey,
            partnerProductName: '',
            partnerProductId: '',
            payoutId: null,
            payoutDate: null,
            payoutStatus: null,
            lastModified: now(),
            coupon: '',
            saleType: 'unknown',
            amount: {
              currency,
              price,
              revenue: null,
              commission
            },
            commissionPercent,
            advertiserId: '',
            advertiserName: row.program
          };
        });
    }
  }
};
