import React from 'react';
import {
  MultiSelector,
  MultiSelectorChip,
  MultiSelectorOption
} from '../MultiSelector';

type Props = {
  sites: string[];
  value: Set<string>;
  onChange: (nextValue: Set<string>) => void;
  includeUnattributed?: boolean;
};

export const UNATTRIBUTED = 'unattributed';

export const SiteSelector = ({
  sites,
  value,
  onChange,
  includeUnattributed
}: Props) => {
  const isApplied = sites.length + (includeUnattributed ? 1 : 0) !== value.size;

  const options: MultiSelectorOption<string>[] = sites.map((s) => ({
    label: s,
    value: s
  }));

  if (includeUnattributed) {
    options.push({ label: 'Unattributed', value: UNATTRIBUTED });
  }

  return (
    <MultiSelector
      value={value}
      onChange={onChange}
      legend="Filter by sites"
      options={options}
      allOption={<strong>All sites</strong>}
      allowFocusing
    >
      <MultiSelectorChip
        isApplied={isApplied}
        onDelete={() =>
          onChange(
            new Set(includeUnattributed ? [...sites, UNATTRIBUTED] : sites)
          )
        }
        label="Sites"
        appliedLabel="Sites"
      />
    </MultiSelector>
  );
};
