import Paper from '@material-ui/core/Paper';
import { orderBy } from 'lodash';
import React, { useMemo } from 'react';
import { Ctr } from '../../../../../../components/Ctr';
import {
  ID_TO_ROW_KEY,
  ItemSorter,
  ItemSorters,
  RowsRenderer,
  ROW_HEIGHTS,
  SortValue
} from '../../../../../../components/GroupableList';
import { Loader } from '../../../../../../components/Loader';
import { PartnerLogo } from '../../../../../../components/PartnerLogo';
import { Rank } from '../../../../../../components/Rank';
import { BaseCountCell, CountCell } from '../../../../../../components/Table';
import { IColumn } from '../../../../../../components/Table/Column';
import {
  toAbsoluteGrowth,
  toSingleSort,
  ValueGetter
} from '../../../../../../components/Table/ValueGetter';
import { toSelector } from '../../../../../../services/selection';
import {
  getTrend,
  mapCounter,
  Mode
} from '../../../../../../domainTypes/analytics';
import { Doc } from '../../../../../../domainTypes/document';
import { EMPTY_OBJ } from '../../../../../../domainTypes/emptyConstants';
import { getSortValueForProductDataStatus } from '../../../../../../domainTypes/linkCheckV2';
import {
  INarrowProductWithCountsAndTrends,
  IProductLinkCheckIssue,
  ProductIssueType
} from '../../../../../../domainTypes/product';
import { SortDirection } from '../../../../../../hooks/useSort';
import {
  DEFAULT_OFFSET,
  DEFAULT_TOOLBAR_HEIGHT
} from '../../../../../../layout/PageToolbar';
import {
  getClickRatio,
  getCpm,
  getViewRatio
} from '../../../../../../services/analytics';
import {
  constructPartnerForKey,
  getKnownPartnerForKey
} from '../../../../../../services/partner';
import { formatDatePrecise } from '../../../../../../services/time';
import { ProductIssue } from '../ProductIssue';
import { ProductLinkCell } from '../ProductLinkCell';
import { Dash } from '../../../../../../components/Table/CountCell';
import { Tooltip } from '@material-ui/core';
import { useSpaceCurrency } from '../../../../../../services/useSpaceCurrency';
import { CurrencyCode } from '../../../../../../domainTypes/currency';

export type ColumnName =
  | 'name'
  | 'partner'
  | 'createdAt'
  | 'pageViews'
  | 'served'
  | 'viewed'
  | 'issues'
  | 'clicked'
  | 'rank'
  | 'earnings'
  | 'epc'
  | 'viewRatio'
  | 'clickRatio'
  | 'selector';

type Data = Doc<INarrowProductWithCountsAndTrends>;

type ProductsSorter = ItemSorter<Data>;

type Props = {
  products: void | Data[];
  loading: boolean;
  sorter: ProductsSorter;
  sortDirection: SortDirection | undefined;
  onSort: (c: ColumnName, dir: SortDirection | undefined) => void;
  columns: Set<string>;
  compare: boolean;
  rowToHref?: (d: Data) => string;
  selected: { [productId: string]: boolean };
  setSelected: (nextSelected: { [productId: string]: boolean }) => void;
  mode: Mode;
};

type OtherProps = {
  compare: boolean;
  ranks: { [productId: string]: { prev: number; cur: number } };
  selected: { [productId: string]: boolean };
  setSelected: (nextSelected: { [productId: string]: boolean }) => void;
  productIds: string[];
  mode: Mode;
  currency: CurrencyCode;
};

type Column = IColumn<Data, ColumnName, OtherProps>;

const isusesValueGetter = (p: Data): SortValue => {
  const linkCheckIssue = p.data.issues.find(
    (i) => i.type === ProductIssueType.LINK_CHECK && !i.resolved
  ) as IProductLinkCheckIssue | undefined;
  return linkCheckIssue
    ? getSortValueForProductDataStatus(linkCheckIssue.status)
    : -1;
};

export const VALUE_GETTERS: {
  [K in ColumnName]: ValueGetter<Data>;
} = {
  name: {
    current: (p) => p.data.name,
    prev: (p) => p.data.name,
    absoluteGrowth: (p) => p.data.name,
    relativeGrowth: (p) => p.data.name
  },
  partner: {
    current: (p) => p.data.url,
    prev: (p) => p.data.url,
    absoluteGrowth: (p) => p.data.url,
    relativeGrowth: (p) => p.data.url
  },
  createdAt: {
    current: (p) => p.data.created_at.toMillis(),
    prev: (p) => p.data.created_at.toMillis(),
    absoluteGrowth: (p) => p.data.created_at.toMillis(),
    relativeGrowth: (p) => p.data.created_at.toMillis()
  },
  pageViews: {
    current: (p) => p.data.counts.pageViews.count,
    prev: (p) => p.data.counts.pageViews.lastCount,
    absoluteGrowth: (p) => toAbsoluteGrowth(p.data.counts.pageViews),
    relativeGrowth: (p) => p.data.counts.pageViews.trend
  },
  served: {
    current: (p) => p.data.counts.served.count,
    prev: (p) => p.data.counts.served.lastCount,
    absoluteGrowth: (p) => toAbsoluteGrowth(p.data.counts.served),
    relativeGrowth: (p) => p.data.counts.served.trend
  },
  viewed: {
    current: (p) => p.data.counts.viewed.count,
    prev: (p) => p.data.counts.viewed.lastCount,
    absoluteGrowth: (p) => toAbsoluteGrowth(p.data.counts.viewed),
    relativeGrowth: (p) => p.data.counts.viewed.trend
  },
  earnings: {
    current: (p) => (p.data.sales ? p.data.sales.count : -Infinity),
    prev: (p) => (p.data.sales ? p.data.sales.lastCount : -Infinity),
    absoluteGrowth: (p) =>
      p.data.sales ? p.data.sales.count - p.data.sales.lastCount : -Infinity,
    relativeGrowth: (p) =>
      p.data.sales ? p.data.sales.count / p.data.sales.lastCount : -Infinity
  },
  epc: {
    current: (p) => (p.data.sales ? p.data.epc.count : -Infinity),
    prev: (p) => (p.data.sales ? p.data.epc.lastCount : -Infinity),
    absoluteGrowth: (p) =>
      p.data.sales ? p.data.epc.count - p.data.epc.lastCount : -Infinity,
    relativeGrowth: (p) =>
      p.data.sales ? p.data.epc.count / p.data.epc.lastCount : -Infinity
  },
  issues: {
    current: isusesValueGetter,
    prev: isusesValueGetter,
    absoluteGrowth: isusesValueGetter,
    relativeGrowth: isusesValueGetter
  },
  clicked: {
    current: (p) => p.data.counts.clicked.count,
    prev: (p) => p.data.counts.clicked.lastCount,
    absoluteGrowth: (p) => toAbsoluteGrowth(p.data.counts.clicked),
    relativeGrowth: (p) => p.data.counts.clicked.trend
  },
  viewRatio: {
    current: (p) => getViewRatio(mapCounter(p.data.counts, (v) => v.count)),
    prev: (p) => getViewRatio(mapCounter(p.data.counts, (v) => v.lastCount)),

    absoluteGrowth: (p) => {
      const current = getViewRatio(mapCounter(p.data.counts, (v) => v.count));
      const prev = getViewRatio(mapCounter(p.data.counts, (v) => v.lastCount));
      return current - prev;
    },
    relativeGrowth: (p) => {
      const current = getViewRatio(mapCounter(p.data.counts, (v) => v.count));
      const prev = getViewRatio(mapCounter(p.data.counts, (v) => v.lastCount));
      return getTrend(prev, current);
    }
  },
  clickRatio: {
    current: (p) => getClickRatio(mapCounter(p.data.counts, (v) => v.count)),
    prev: (p) => getClickRatio(mapCounter(p.data.counts, (v) => v.lastCount)),
    absoluteGrowth: (p) => {
      const current = getCpm(mapCounter(p.data.counts, (v) => v.count));
      const prev = getCpm(mapCounter(p.data.counts, (v) => v.lastCount));
      return current - prev;
    },
    relativeGrowth: (p) => {
      const current = getCpm(mapCounter(p.data.counts, (v) => v.count));
      const prev = getCpm(mapCounter(p.data.counts, (v) => v.lastCount));
      return getTrend(prev, current);
    }
  },
  rank: {
    current: (p) => 0,
    prev: (p) => 0,
    absoluteGrowth: (p) => 0,
    relativeGrowth: (p) => 0
  },
  selector: {
    current: (p) => 0,
    prev: (p) => 0,
    absoluteGrowth: (p) => 0,
    relativeGrowth: (p) => 0
  }
};

const createSorters = (mode: Mode): ItemSorters<Data> => ({
  partner: {
    key: 'partner',
    items: { sort: toSingleSort(VALUE_GETTERS['partner'], mode), dir: 'asc' }
  },
  name: {
    key: 'name',
    items: { sort: toSingleSort(VALUE_GETTERS['name'], mode), dir: 'asc' }
  },
  pageViews: {
    key: 'pageViews',
    items: { sort: toSingleSort(VALUE_GETTERS['pageViews'], mode), dir: 'desc' }
  },
  served: {
    key: 'served',
    items: { sort: toSingleSort(VALUE_GETTERS['served'], mode), dir: 'desc' }
  },
  viewed: {
    key: 'viewed',
    items: { sort: toSingleSort(VALUE_GETTERS['viewed'], mode), dir: 'desc' }
  },
  clicked: {
    key: 'clicked',
    items: { sort: toSingleSort(VALUE_GETTERS['clicked'], mode), dir: 'desc' }
  },
  issues: {
    key: 'issues',
    items: { sort: toSingleSort(VALUE_GETTERS['issues'], mode), dir: 'desc' }
  },
  viewRatio: {
    key: 'viewRatio',
    items: { sort: toSingleSort(VALUE_GETTERS['viewRatio'], mode), dir: 'desc' }
  },
  clickRatio: {
    key: 'clickRatio',
    items: {
      sort: toSingleSort(VALUE_GETTERS['clickRatio'], mode),
      dir: 'desc'
    }
  },
  createdAt: {
    key: 'createdAt',
    items: { sort: toSingleSort(VALUE_GETTERS['createdAt'], mode), dir: 'desc' }
  },
  earnings: {
    key: 'earnings',
    items: { sort: toSingleSort(VALUE_GETTERS['earnings'], mode), dir: 'desc' }
  },
  epc: {
    key: 'epc',
    items: { sort: toSingleSort(VALUE_GETTERS['epc'], mode), dir: 'desc' }
  }
});
export const SORTERS: {
  [K in Mode]: ItemSorters<Data>;
} = {
  'absolute-numbers': createSorters('absolute-numbers'),
  'absolute-growth': createSorters('absolute-growth'),
  'relative-growth': createSorters('relative-growth')
};

export const COLUMNS: Column[] = [
  {
    key: 'selector',
    head: (o) => (o ? toSelector(o.productIds, o) : 'Selection'),
    cell: (d, o) => toSelector([d.id], o),
    align: 'center',
    width: 50,
    flexGrow: 0
  },
  {
    key: 'rank',
    head: () => 'Rank',
    cell: (d, o) => {
      const r = o.ranks[d.id];
      if (!r) {
        return null;
      }
      return <Rank current={r.cur} previous={r.prev} />;
    },
    headInfo: () =>
      'How this link performed, according to the selected sorting column, compared to its performance in the previous timeframe.',
    align: 'center',
    width: 60,
    flexGrow: 0
  },
  {
    key: 'partner',
    head: () => 'Platform',
    cell: (d) => {
      const partner =
        getKnownPartnerForKey(d.data.partner_key) ||
        constructPartnerForKey(d.data.partner_key);
      return (
        <Tooltip
          placement="left"
          title={partner.known ? partner.name : 'Platform not recognized'}
        >
          <div>
            <PartnerLogo partner={partner} />
          </div>
        </Tooltip>
      );
    },
    headInfo: () =>
      "Which affiliate network, program, or platform we've auto-detected that this link belongs to.",
    align: 'center',
    width: 80,
    flexGrow: 0
  },
  {
    key: 'name',
    head: () => 'Name',
    cell: (d) => <ProductLinkCell p={d} />,
    headInfo: () =>
      'Name of this affiliate link. You can edit it by clicking on the row, and using the button "Rename link".',
    align: 'left',
    width: 200,
    flexGrow: 3,
    sortable: true
  },
  {
    key: 'served',
    head: () => 'Served',
    headInfo: () =>
      'How many times an affiliate link was served on a page to one of your visitors, regardless of whether they saw it.',
    cell: (d, o) => {
      return (
        <CountCell
          variant={o.mode}
          before={d.data.counts.served.lastCount}
          after={d.data.counts.served.count}
          compare={o.compare}
        />
      );
    },
    align: 'right',
    width: 80,
    flexGrow: 1,
    sortable: true
  },
  {
    key: 'viewed',
    head: () => 'Impressions',
    headInfo: () =>
      'How many times this affiliate link was seen as a result of scrolling down one of your pages.',
    cell: (d, o) => {
      return (
        <CountCell
          variant={o.mode}
          before={d.data.counts.viewed.lastCount}
          after={d.data.counts.viewed.count}
          compare={o.compare}
        />
      );
    },
    align: 'right',
    width: 80,
    flexGrow: 1,
    sortable: true
  },
  {
    key: 'viewRatio',
    head: () => '% Seen',
    headInfo: () =>
      'On pages where this affiliate link appears, how often visitors scroll far enough on the page to see the link.',
    cell: (d, o) => {
      return (
        <CountCell
          variant={o.mode}
          before={getViewRatio(mapCounter(d.data.counts, (v) => v.lastCount))}
          after={getViewRatio(mapCounter(d.data.counts, (v) => v.count))}
          compare={o.compare}
          format="percent"
        />
      );
    },
    align: 'right',
    width: 80,
    flexGrow: 1
  },
  {
    key: 'clicked',
    head: () => 'Clicks',
    headInfo: () =>
      'How many times this affiliate link was clicked on your website, on pages where the tracking snippet is installed. Excludes duplicate clicks and bot clicks.',
    cell: (d, o) => {
      return (
        <CountCell
          variant={o.mode}
          before={d.data.counts.clicked.lastCount}
          after={d.data.counts.clicked.count}
          compare={o.compare}
        />
      );
    },
    align: 'right',
    width: 80,
    flexGrow: 1,
    sortable: true
  },
  {
    key: 'clickRatio',
    head: () => 'Avg CTR',
    headInfo: () =>
      'How often visitors who see this affiliate link actually click on the link. Calculated by dividing clicks by impressions.',
    cell: (d, o) => {
      if (o.mode === 'absolute-numbers') {
        return (
          <BaseCountCell
            before={d.data.ctr.lastCount}
            after={d.data.ctr.count}
            compare={o.compare}
            format="percent"
            digits={1}
          >
            <Ctr rate={d.data.ctr.count} />
          </BaseCountCell>
        );
      }
      return (
        <CountCell
          variant={o.mode}
          before={d.data.ctr.lastCount}
          after={d.data.ctr.count}
          compare={o.compare}
          format="percent"
        />
      );
    },
    align: 'right',
    width: 80,
    flexGrow: 1,
    sortable: true
  },
  {
    key: 'issues',
    head: () => 'Issues',
    headInfo: () =>
      'Any known issues with this link, for example being broken or out of stock. Applies only to Amazon links.',
    cell: (d) => {
      return <ProductIssue issues={d.data.issues} />;
    },
    align: 'center',
    width: 30,
    flexGrow: 1,
    sortable: false
  },
  {
    key: 'earnings',
    head: () => 'Earnings',
    headInfo: () =>
      'How much revenue clicks on this exact affiliate link on your website have generated from Smart Labeled transactions.',
    cell: (d, o) => {
      return d.data.sales ? (
        <CountCell
          variant={o.mode}
          before={d.data.sales.lastCount}
          after={d.data.sales.count}
          currency={o.currency}
          compare={o.compare}
        />
      ) : (
        <Dash />
      );
    },
    align: 'right',
    width: 80,
    flexGrow: 1,
    sortable: true
  },
  {
    key: 'epc',
    head: () => 'EPC',
    headInfo: () => `
      Earnings per click. How much you earn on average for every click through on this affiliate link on your website from Smart Labeled transactions.
    `,
    cell: (d, o) => {
      if (!d.data.sales) {
        return <Dash />;
      }

      return (
        <CountCell
          variant={o.mode}
          before={d.data.epc.lastCount}
          after={d.data.epc.count}
          currency={o.currency}
          compare={o.compare}
        />
      );
    },
    align: 'right',
    width: 80,
    flexGrow: 1,
    sortable: true
  },
  {
    key: 'createdAt',
    head: () => 'Date created',
    cell: (d) => {
      return formatDatePrecise(d.data.created_at);
    },
    headInfo: () => 'The date and time this link was imported for tracking.',
    align: 'right',
    width: 80,
    flexGrow: 1,
    sortable: true
  }
];

export const DEFAULT_COLUMNS: ColumnName[] = [
  'selector',
  'rank',
  'partner',
  'earnings',
  'epc',
  'name',
  'clicked',
  'clickRatio'
];

const getRanks = (
  products: Doc<INarrowProductWithCountsAndTrends>[],
  columnName: ColumnName,
  direction: SortDirection
): { [productId: string]: { cur: number; prev: number } } => {
  const toRankDict = (
    ps: Doc<INarrowProductWithCountsAndTrends>[],
    sortBy: ColumnName,
    sortOver: 'current' | 'prev'
  ) => {
    const res: { [href: string]: number } = {};
    let lastVal: any = null;
    let lastRank: number = 1;
    for (let i = 0; i < ps.length; i++) {
      const p = ps[i];
      const val = VALUE_GETTERS[sortBy][sortOver](p);
      const tied = val === lastVal;
      const rank = tied ? lastRank : i + 1;
      if (!tied) {
        lastVal = val;
        lastRank = rank;
      }
      res[ps[i].id] = rank;
    }
    return res;
  };

  const sortPages = (
    ps: Doc<INarrowProductWithCountsAndTrends>[],
    sortBy: ColumnName,
    sortOver: 'current' | 'prev',
    dir: SortDirection
  ) => {
    return orderBy(ps, (p) => VALUE_GETTERS[sortBy][sortOver](p), dir);
  };

  const cName: ColumnName =
    columnName === 'served' ||
    columnName === 'clicked' ||
    columnName === 'clickRatio' ||
    columnName === 'viewed' ||
    columnName === 'viewRatio'
      ? columnName
      : 'clicked';

  const prev = toRankDict(
    sortPages(products, cName, 'prev', direction),
    cName,
    'prev'
  );
  const current = toRankDict(
    sortPages(products, cName, 'current', direction),
    cName,
    'current'
  );

  // This is the case when we didn't have any previous data,
  // then everyone will be listed as having rank 1
  // When true, return the special value of 0, which will indicate
  // to the UI components that this rank is supposed to be skipped
  const wereAllTiedBefore = new Set(Object.values(prev)).size === 1;
  return Object.fromEntries(
    products.map((p) => [
      p.id,
      { cur: current[p.id], prev: wereAllTiedBefore ? 0 : prev[p.id] }
    ])
  );
};

export const ProductsTablePg: React.FC<Props> = ({
  products,
  loading,
  sorter,
  sortDirection,
  onSort,
  columns: columnNames,
  compare,
  rowToHref,
  selected,
  setSelected,
  mode
}) => {
  const currency = useSpaceCurrency();
  const ranks = useMemo(
    () =>
      products
        ? getRanks(
            products,
            sorter.key as ColumnName,
            sortDirection || sorter.items.dir
          )
        : EMPTY_OBJ,
    [products, sorter, sortDirection]
  );

  const columns = useMemo(
    () =>
      columnNames ? COLUMNS.filter((c) => columnNames.has(c.key)) : COLUMNS,
    [columnNames]
  );

  const rowIds = useMemo(() => (products || []).map((r) => r.id), [products]);

  const otherProps: OtherProps = useMemo(
    () => ({
      compare,
      ranks,
      selected,
      setSelected,
      productIds: rowIds,
      mode,
      currency
    }),
    [compare, ranks, selected, setSelected, rowIds, mode, currency]
  );

  if (!products || loading) {
    return (
      <Paper>
        <Loader height={800} />
      </Paper>
    );
  }

  return (
    <RowsRenderer
      variant="contained"
      columns={columns}
      rows={products}
      rowToKey={ID_TO_ROW_KEY}
      sorter={sorter}
      sortDirection={sortDirection}
      renderHead={true}
      headProps={{
        sticky: true,
        offset: DEFAULT_OFFSET + DEFAULT_TOOLBAR_HEIGHT + 8
      }}
      onHeadClick={(c, dir) => onSort(c.key, dir)}
      chunkSize={30}
      rootMargin="400px"
      rowHeight={ROW_HEIGHTS.airy}
      rowToHref={rowToHref}
      otherProps={otherProps}
    />
  );
};
