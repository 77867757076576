import { Chip } from '@material-ui/core';
import moment from 'moment-timezone';
import React from 'react';
import { DAY_FORMAT, Timeframe } from '../../domainTypes/analytics';
import { pluralize } from '../../services/pluralize';
import { timeframeToMoments } from '../../services/time';

export const TimeframeDurationChip = ({ tf }: { tf: Timeframe }) => {
  const moments = timeframeToMoments(tf);
  const duration = pluralize('day', moments.end.diff(moments.start, 'd'), true);
  const isLastDays = tf.end === moment.tz(tf.tz).format(DAY_FORMAT);

  return (
    <Chip
      component="span"
      style={{ backgroundColor: '#ededed' }}
      label={`${isLastDays ? 'Last ' : ''}${duration}`}
    />
  );
};
