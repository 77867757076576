export const FS = {
  affiliations: 'affiliationsV1',
  affiliationLeads: 'affiliationLeadsV1',
  affilimateConfig: 'affilimateConfigV1',
  advertisers: 'advertisersV1',
  amazonApiKeysPublic: 'amazonApiKeysPublicV1',
  amazonApiKeysPrivate: 'amazonApiKeysPrivateV1',
  apiStatus: 'apiStatusV1',
  attributionRules: 'attributionRules',
  attributionRuleMaps: 'attributionRuleMaps',
  autoLabelConfigs: 'autoLabelConfigsV1',
  campaigns: 'campaignsV1',
  channels: 'channelsV1',
  chromeExtensionMetadata: 'chromeExtensionMetadataV1',
  clicksWithTrackingLabel: 'clicksWithTrackingLabelV1',
  customDimensionDetails: 'customDimensionDetailsV1',
  customDimensions: 'customDimensionsV1',
  denormalizationByClick: 'denormalizationByClick',
  denormalizationByPage: 'denormalizationByPage',
  denormalizationByProduct: 'denormalizationByProduct',
  events: 'eventsV1',
  exchangeRates: 'exchangeRatesV1',
  notifications: 'notificationsV1',
  notificationCounters: 'notificationCountersV1',
  pages: 'pagesV1',
  pageScreenshots: 'pageScreenshots',
  pageSummaries: 'pageSummariesV1',
  payouts: 'payoutsV1',
  potentialUsers: 'potentialUsersV1',
  productMaps: 'productMaps',
  products: 'products',
  publicApiToken: 'publicApiTokenV1',
  reportingExtensionStatus: 'reportingExtensionStatusV1',
  reportingStats: 'reportingStatsV1',
  reports: 'reportsV1',
  sales: 'salesV1',
  schedules: 'schedules',
  secretsPublic: 'secretsPublicV1',
  secretsPrivate: 'secretsPrivateV1',
  shortenedLinks: 'shortenedLinksV1',
  spaces: 'spaces',
  settings: 'settingsV1',
  spaceNotes: 'spaceNotesV1',
  tagPrototypes: 'tagPrototypesV1',
  trackingConfigs: 'trackingConfigsV1',
  trackingConfigsV4: 'trackingConfigsV4',
  jobs: 'jobsV1',
  users: 'users',
  userInvitations: 'userInvitationsV1',
  userEngagement: 'userEngagementV1',
  usersPerSpace: 'usersPerSpaceV1',
  widgets: 'widgets',

  linkCheckStandalone: 'linkCheckStandaloneV1',
  linkCheckApp: 'linkCheckAppV1',
  linkCheckPackages: 'linkCheckPackagesV2',
  linkCheckWorkers: 'linkCheckWorkersV2',
  linkCheckerStandaloneReportNotificationRequests:
    'linkCheckerStandaloneReportNotificationRequestsV1',

  _deploymentBuilds: 'deploymentBuilds',
  _deploymentJobs: 'deploymentJobs',
  _trackingStatsHourly: 'trackingStatsHourlyV1'
};

export const RDB = {
  permissions: 'permissions',
  productImportUpdateDate: 'productImportUpdateDate',
  productScans: 'productScans',
  productScanTrackers: 'productScanTrackers',
  productScanSpeedRestrictions: 'productScanSpeedRestrictions',
  productScanAborts: 'productScansAborts',
  productSuggestions: 'productSuggestions',
  productTrash: 'productTrash',
  pageQueue: 'pageQueue',

  amazonLinkCheckIps: 'amazonLinkCheckIps',
  _deploymentNumbers: 'deploymentNumbers'
};

export const CF = {
  affiliate: {
    getAffiliates: 'affiliate-getAffiliates'
  },
  amazonApiKeys: {
    store: 'amazonApiKeys-store',
    remove: 'amazonApiKeys-remove'
  },
  analysis: {
    prompt: 'analysis-prompt'
  },
  analytics: {
    getCountsInTimeframe: 'analytics-getCountsInTimeframe',
    getCountsInTimeframeForProduct: 'analytics-getCountsInTimeframeForProduct',
    getCountsInTimeframeForPage: 'analytics-getCountsInTimeframeForPage',
    getCountsInTimeframePerProduct: 'analytics-getCountsInTimeframePerProduct',

    getCountsPerPageInTimeframe: 'analytics-getCountsPerPageInTimeframe',
    getCountsInTimeframePerPageForProduct:
      'analytics-getCountsInTimeframePerPageForProduct',
    getCountsInTimeframePerProductForPage:
      'analytics-getCountsInTimeframePerProductForPage',
    getDeviceCountsForClicksPerProductInTimeframe:
      'analytics-getDeviceCountsForClicksPerProductInTimeframe',
    getDeviceCountsForClicksPerPageInTimeframe:
      'analytics-getDeviceCountsForClicksPerPageInTimeframe',
    getDeviceCountsForClicksInTimeframe:
      'analytics-getDeviceCountsForClicksInTimeframe',
    getReferrerCountsForClicksPerProductInTimeframe:
      'analytics-getReferrerCountsForClicksPerProductInTimeframe',
    getReferrerCountsForClicksPerPageInTimeframe:
      'analytics-getReferrerCountsForClicksPerPageInTimeframe',
    getReferrerCountsForClicksInTimeframe:
      'analytics-getReferrerCountsForClicksInTimeframe',
    scheduledReport: 'analytics-scheduledReport'
  },
  api: {
    index: 'api-index'
  },
  apiSpec: {
    get: 'apispec-get'
  },
  billing: {
    createCheckoutSession: 'billing-createCheckoutSession',
    createSubscription: 'billing-createSubscription',
    getSubscription: 'billing-getSubscription',
    getInvoices: 'billing-getInvoices',
    getPlans: 'billing-getPlans',
    getCoupon: 'billing-getCoupon',
    scheduledTrialReminder: 'billing-scheduledTrialReminer',
    connectCustomSubscription: 'billing-connectCustomSubscription',
    changePlan: 'billing-changePlan'
  },
  geo: {
    isEu: 'geo-isEu'
  },
  helpCenter: {
    search: 'helpCenter-search'
  },
  denormalization: {
    denormalizeSpace: 'denormalization-denormalizeSpace',
    denormalizePointInTime: 'denormalization-denormalizePointInTime'
  },
  files: {
    exportQuery: 'files-exportQuery',
    exportTransactions: 'files-exportTransactions'
  },
  linkCheckApp: {
    scheduledCheck: 'linkCheck_app-scheduledCheck'
  },
  mail: {
    sendToSpace: 'mail-sendToSpace',
    sendToUsers: 'mail-sendToUsers',
    sendToAddresses: 'mail-sendToAddresses',
    moveUserToActiveList: 'mail-moveUserToActiveList',
    getEmailLists: 'mail-getEmailLists',
    unsubscribeByDeliveryId: 'mail-unsubscribeByDeliveryId',
    createLeadV2: 'mail-createLeadV2',
    createLead: 'mail-createLead'
  },
  monitoring: {
    getTrackingStatsHourlyBySpace: 'monitoring-getTrackingStatsHourlyBySpace'
  },
  pages: {
    checkPageForModifications: 'pages-checkPageForModifications',
    tryRecreateExistingScreenshots: 'pages-tryRecreateExistingScreenshots',
    createRevisionManually: 'pages-createRevisionManually'
  },
  pageSummaries: {
    seedSpace: 'pageSummaries-seedSpace'
  },
  products: {
    createNewProductMap: 'products-createNewProductMap',
    addToProductMap: 'products-addToProductMap',
    removeFromProductMap: 'products-removeFromProductMap',
    updateProductImportDate: 'products-updateProductImportDate',
    getDestinationUrl: 'products-getDestinationUrl',
    pushProductsToPg: 'products-pushProductsToPg',
    deleteProductsFromPg: 'products-deleteProductsFromPg'
  },
  tools: {
    createLink: 'tools-createLink'
  },
  reporting: {
    createLink: 'reporting-createLink',
    testSecretAndStore: 'reporting-testSecretAndStore',
    preview: 'reporting-preview',
    autoRun: 'reporting-autoRun',
    requestThroughExtension: 'reporting-requestThroughExtension',
    relinkSales: 'reporting-relinkSales',
    getAllPresentNonAffilimateTrackingLabels:
      'reporting-getAllPresentNonAffilimateTrackingLabels',
    dailyEarningsReport: 'reporting-dailyEarningsReport',
    dailyEarningsReport2: 'reporting-dailyEarningsReport2',
    getDataFromAdvertisers: 'reporting-getDataFromAdvertisers',
    saveSalesFromReports: 'reporting-saveSalesFromReports',
    scheduleReportingJobs: 'reporting-scheduleReportingJobs',
    triggerApiHandler: 'reporting-triggerApiHandler',
    updateSecretsStatus: 'reporting-updateSecretsStatus',
    importSales: 'reporting-importSales',
    deleteSales: 'reporting-deleteSales'
  },
  scraping: {
    scanPages: 'scraping-scanPages',
    reschedulePages: 'scraping-reschedulePages',
    abortScan: 'scraping-abortScan',
    scheduledScan: 'scraping-scheduledScan',
    scheduledScanRemoval: 'scraping-scheduledScanRemoval',
    getSuggestionGroupsForScan: 'scraping-getSuggestionGroupsForScan'
  },
  sales: {
    exportSales: 'sales-exportSales'
  },
  secrets: {
    store: 'secret-store'
  },
  tracking: {
    getConfig: 'tracking_v5-getConfig',
    trackImpressionsServed: 'tracking_v5-trackImpressionsServed',
    trackImpressionsViewed: 'tracking_v5-trackImpressionsViewed',
    trackLinkClick: 'tracking_v5-trackLinkClick'
  },
  trackingConfig_v2: {
    addProductsToTrackingConfig:
      'trackingConfig_v2-addProductsToTrackingConfig',
    removeProductsFromTrackingConfig:
      'trackingConfig_v2-removeProductsFromTrackingConfig',
    refreshTrackingConfig: 'trackingConfig_v2-refreshTrackingConfig'
  },
  space: {
    create: 'space-createSpace',
    update: 'space-update',
    updateDomainActivity: 'space-updateDomainActivity',
    addDomain: 'space-addDomain',
    addDomainUnverified: 'space-addDomainUnverified',
    toggleAutoImportDomain: 'space-toggleAutoImportDomain',
    updateSiteSettings: 'space-updateSiteSettings',
    removeDomain: 'space-removeDomain',
    addMemberToSpace: 'space-addMemberToSpace',
    removeMemberFromSpace: 'space-removeMemberFromSpace',
    setupAdditionalSpaceDefaults: 'space-setupAdditionalSpaceDefaults '
  },
  tags: {
    createTags: 'tags-createTags',
    getTagsForPages: 'tags-getTagsForPages',
    getTags: 'tags-getTags',
    assignTags: 'tags-assignTags',
    deleteTag: 'tags-deleteTag',
    updateTag: 'tags-updateTag',
    synchroniseAuthorTags: 'tags-syncAuthorsManually',
    synchroniseAutomaticTags: 'tags-syncAutomaticTagsManually'
  },
  user: {
    updateUser: 'user-updateUser',
    createUser: 'user-createUser',
    createInvitation: 'user-createInvitation',
    revokeInvitation: 'user-revokeInvitation',
    getMinimalInvitationData: 'user-getMinimalInvitationData',
    acceptInvitation: 'user-acceptInvitation',
    editPermissions: 'user-editPermissions',
    removeUserFromSpace: 'user-removeUserFromSpace',
    resendInvitationMailAndUpdateExpirationDate:
      'user-resendInvitationMailAndUpdateExpirationDate'
  }
};

export const DENORMALIZATION = {
  byPage: {
    version: 'v4'
  },
  byProduct: { version: 'v4' },
  byClick: { version: 'v1' }
};
