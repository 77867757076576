import { groupBy } from 'lodash';
import moment from 'moment-timezone';
import { CurrencyCode } from '../../../../../domainTypes/currency';
import { ISale } from '../../../../../domainTypes/performance';
import { detectCurrency } from '../../../../../services/currency';
import { getKnownPartnerForKeyUnsafe } from '../../../../../services/partner';
import { fromMoment } from '../../../../../services/time';
import { addDevice, toCents, toPercent } from '../helpers';
import { IFileReportHandler } from '../types';

const detectCurrencyFromRow = (
  priceHeader: string,
  trackingId: string
): CurrencyCode | null => {
  if (priceHeader === '価格') {
    return 'JPY';
  }

  if (priceHeader === 'cena') {
    return 'PLN';
  }

  if (priceHeader === 'Pris' && trackingId.endsWith('-21')) {
    return 'SEK';
  }

  const match = priceHeader.match(/.+\((.+)\)/);
  // GBP has no currency info in the header!

  if (!match || !match[1]) {
    return 'GBP';
  }

  const currency = detectCurrency(match[1]);

  if (currency === 'USD' && trackingId.endsWith('-22')) {
    return 'CAD';
  }

  return currency;
};

export const AMAZON: IFileReportHandler<string> = {
  type: 'CSV',
  partnerKey: 'amazon',
  parser: {
    name: 'Fee earnings report',
    csvHeaders: '',
    parseOptions: { quoteChar: '' },
    matches: (text) => text.indexOf('Fee-Earnings reports from') === 0,
    processRows: async (rows, { partnerKey, reportId, integrationId }) => {
      const headers = rows[1];
      const priceHeader = headers[6];
      const trackingId = rows[2][4];
      const currency = detectCurrencyFromRow(priceHeader, trackingId);

      if (!currency) {
        throw new Error(`UNKNOWN CURRENCY - ${priceHeader}`);
      }

      const groupedRows = groupBy(rows.slice(2), (row) => {
        const [
          // eslint-disable-next-line
          category,
          // eslint-disable-next-line
          partnerProductName,
          partnerProductId,
          // eslint-disable-next-line
          __seller,
          // eslint-disable-next-line
          trackingLabel,
          _saleDate,
          // eslint-disable-next-line
          _price,
          // eslint-disable-next-line
          _quantity,
          // eslint-disable-next-line
          _isReturn,
          // eslint-disable-next-line
          _revenue,
          // eslint-disable-next-line
          _commission,
          // eslint-disable-next-line
          _device
        ] = row;
        return `${_saleDate}-${partnerProductId}`;
      });
      const keys = Object.keys(groupedRows);

      return keys.reduce((memo, key) => {
        const asinGroup = groupedRows[key];
        const sales = asinGroup.map((row, i) => {
          const [
            category,
            partnerProductName,
            partnerProductId,
            seller,
            trackingLabel,
            _saleDate,
            _price,
            _quantity,
            _isReturn,
            _revenue,
            _commission,
            _device
          ] = row;

          const saleDate = moment.tz(_saleDate, 'YYYY-MM-DD HH:mm:ss', 'UTC');
          const rowAddon = i === 0 ? `` : `-${i + 1}`;
          const saleId = saleDate.valueOf() + partnerProductId + rowAddon;
          const completionDate = saleDate;
          const status = parseInt(_isReturn, 10) === 1 ? 'Refunded' : 'Final';
          const price = toCents(_price);
          const revenueInCents = toCents(_revenue);
          const multiplier = status === 'Refunded' ? -1 : 1;
          const revenue = revenueInCents * multiplier;
          const commission = toCents(_commission) * multiplier;
          const commissionPercent = toPercent(commission, revenue);
          const quantity = parseInt(_quantity, 10);

          const sale: ISale = {
            saleId,
            saleDate: fromMoment(saleDate),
            reportId,
            integrationId,
            trackingId: '',
            trackingLabel: trackingLabel || '',
            origin: null,
            completionDate: fromMoment(completionDate),
            status,
            partnerKey,
            partnerProductName,
            partnerProductId,
            payoutId: null,
            payoutDate: null,
            payoutStatus: null,
            lastModified: null,
            coupon: '',
            saleType: 'unknown',
            amount: {
              currency,
              revenue,
              price,
              commission
            },
            commissionPercent,
            quantity,
            advertiserId: partnerKey,
            advertiserName: getKnownPartnerForKeyUnsafe(partnerKey).name,
            metadata: {
              category,
              seller
            }
          };

          addDevice(sale, _device, {
            DESKTOP: 'desktop',
            PHONE: 'mobile',
            TABLET: 'tablet'
          });
          return sale;
        });
        return [...memo, ...sales];
      }, [] as ISale[]);
    }
  },
  splitFile: (f) => {
    return [f];
  }
};
