import { LoadingValue, useMappedLoadingValue } from '../../../services/db';
import { ReadyCampaign, campaignGoal, campaignQueryBase } from './index';
import { useCurrentUser } from '../../../services/currentUser';
import { useMemo } from 'react';
import { AnalyticsQuery } from '../../../domainTypes/analytics_v2';
import { useAnalyticsQueryV2 } from '../../../services/analyticsV2/query';
import { formatNumber } from '../../../components/Number';

export const goalPercent = (totals: TotalsObject): string =>
  formatNumber({
    n: totals.gmv / totals.gmvGoal,
    format: 'percent',
    digits: 1
  });

export interface TotalsObject {
  c: number;
  p: number;
  v: number;
  commission: number;
  gmv: number;
  flatSpend: number;
  gmvGoal: number;
  aov: number;
  orderCount: number;
}

export type Totals = LoadingValue<TotalsObject>;

export type TotalsField = keyof TotalsObject;

export const useCampaignTotals = (campaign: ReadyCampaign): Totals => {
  const { space } = useCurrentUser();
  const query = useMemo<AnalyticsQuery>(() => {
    return {
      ...campaignQueryBase(campaign),
      select: [
        'c',
        'p',
        'v',
        'commission_sum_net',
        'gmv_sum_net',
        'aov_net',
        'order_count_net'
      ]
    };
  }, [campaign]);
  return useMappedLoadingValue(
    useAnalyticsQueryV2(space.id, query),
    (response) => {
      const data = response.rows[0].data;
      return {
        commission: data.commission_sum_net?.curr ?? 0,
        gmv: data.gmv_sum_net?.curr ?? 0,
        flatSpend: campaign.flatSpend,
        gmvGoal: campaignGoal(campaign),
        aov: data.aov_net?.curr ?? 0,
        orderCount: data.order_count_net?.curr ?? 0,
        c: data.c?.curr ?? 0,
        p: data.p?.curr ?? 0,
        v: data.v?.curr ?? 0
      };
    }
  );
};
