import React, { useMemo } from 'react';
import { Payout } from '../../../../../domainTypes/payout';
import { Doc } from '../../../../../domainTypes/document';
import { Drawer, IconButton, Typography } from '@material-ui/core';
import {
  CommissionWrapper,
  DrawerHeader,
  DrawerSection,
  SectionHeading,
  List
} from '../../../../../components/DrawerHeader';
import { ArrowRightCircle, ChevronLeft, ChevronRight, X } from 'react-feather';
import { styled } from '../../../../../emotion';
import { Currency } from '../../../../../components/Number';
import { PayoutStatusBadge } from '../../../components/PayoutStatus';
import { toMoment } from '../../../../../services/time';
import { getKnownPartnerForKeyUnsafe } from '../../../../../services/partner';
import { PlatformWithColor } from '../../../../../components/PlatformWithColor';
import { Link } from 'react-router-dom';
import { useRoutes } from '../../../../../routes';
import {
  useStandardOptions,
  useTimeframeFromUrl
} from '../../../../../components/TimeframePicker';
import { toTransactionsLink } from '../service';
import { PayoutTransactions } from './PayoutTransactions';
import { PayoutId } from './PayoutId';

const InnerWrapper = styled('div')`
  max-width: 400px;
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: 700;

  svg {
    margin-left: ${({ theme }) => theme.spacing(0.5)}px;
  }
`;

const TransactionsSummaryWrapper = styled('div')`
  background: ${({ theme }) => theme.palette.grey[100]};
`;

export const PayoutsDrawer = ({
  open,
  onClose,
  payout,
  setSelectedPayout,
  allPayouts
}: {
  open: boolean;
  onClose: () => void;
  payout: Doc<Payout> | null;
  setSelectedPayout: (payout: Doc<Payout> | null) => void;
  allPayouts: Doc<Payout>[];
}) => {
  const { ROUTES } = useRoutes();
  const { defaultOption } = useStandardOptions();
  const [timeframe] = useTimeframeFromUrl(defaultOption.value);
  const partnerKey = payout
    ? getKnownPartnerForKeyUnsafe(payout.data.partnerKey)
    : null;

  const sortedPayouts = React.useMemo(() => {
    return allPayouts.sort((a, b) => {
      // Handle if payoutDate is null
      if (!a.data.payoutDate) {
        return -1;
      }
      if (!b.data.payoutDate) {
        return 1;
      }
      return (
        toMoment(b.data.payoutDate).unix() - toMoment(a.data.payoutDate).unix()
      );
    });
  }, [allPayouts]);

  const currentIndex = useMemo(() => {
    return sortedPayouts.findIndex((p) => p.id === payout?.id || null);
  }, [sortedPayouts, payout]);

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <DrawerHeader>
        <Typography variant="body1" component="span">
          Payout summary
          <Typography
            className="pagination"
            variant="body2"
            component="span"
            style={{ margin: '0 24px' }}
          >
            {currentIndex + 1} of {sortedPayouts.length.toLocaleString('en-US')}
            <IconButton
              size="small"
              disabled={currentIndex === 0}
              onClick={() => {
                setSelectedPayout(sortedPayouts[currentIndex - 1] || null);
              }}
            >
              <ChevronLeft size={16} />
            </IconButton>
            <IconButton
              size="small"
              disabled={currentIndex === sortedPayouts.length - 1}
              onClick={() => {
                setSelectedPayout(sortedPayouts[currentIndex + 1]);
              }}
            >
              <ChevronRight size={16} />
            </IconButton>
          </Typography>
        </Typography>
        <IconButton onClick={onClose}>
          <X size={16} />
        </IconButton>
      </DrawerHeader>
      {payout && (
        <InnerWrapper>
          <DrawerSection>
            <CommissionWrapper>
              <Typography variant="h4" component="p">
                <Currency
                  cents={payout.data.netAmount.originalValue}
                  currency={payout.data.netAmount.originalCurrency}
                />
              </Typography>
              <PayoutStatusBadge status={payout.data.payoutStatus} />
            </CommissionWrapper>
            {payout.data.netAmount.originalCurrency !==
              payout.data.netAmount.convertedCurrency && (
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                style={{ marginTop: '12px' }}
              >
                Converts into{' '}
                <Currency
                  cents={payout.data.netAmount.convertedValue}
                  currency={payout.data.netAmount.convertedCurrency}
                />
              </Typography>
            )}
            {partnerKey && (
              <div style={{ marginTop: '8px', marginBottom: '8px' }}>
                <PlatformWithColor partner={partnerKey} />
              </div>
            )}
            <StyledLink to={toTransactionsLink(ROUTES, payout, timeframe)}>
              View transactions{' '}
              <ArrowRightCircle
                size={14}
                style={{ position: 'relative', top: '1px' }}
              />
            </StyledLink>
          </DrawerSection>
          <DrawerSection>
            <SectionHeading>Payout details</SectionHeading>
            <Typography
              variant="h6"
              component="p"
              paragraph
              style={{ wordBreak: 'break-all' }}
            >
              {payout.data.description}
            </Typography>
            <List>
              <dt>Payout ID</dt>
              <dd>
                <PayoutId>{payout.data.payoutId}</PayoutId>
              </dd>
              <dt>Net amount</dt>
              <dd>
                {payout.data.netAmount ? (
                  <Currency
                    cents={payout.data.netAmount.originalValue}
                    currency={payout.data.netAmount.originalCurrency}
                  />
                ) : (
                  '-'
                )}
              </dd>

              {payout.data.tax && payout.data.tax.originalValue !== 0 && (
                <>
                  <dt>Tax amount</dt>
                  <dd>
                    <Currency
                      cents={payout.data.tax.originalValue}
                      currency={payout.data.tax.originalCurrency}
                    />
                  </dd>
                </>
              )}
              {payout.data.amount.originalValue !==
                payout.data.netAmount.originalValue && (
                <>
                  <dt>Gross amount</dt>
                  <dd>
                    <Currency
                      cents={payout.data.amount.originalValue}
                      currency={payout.data.amount.originalCurrency}
                    />
                  </dd>
                </>
              )}
              <dt>Payout date</dt>
              <dd>
                {payout.data.payoutDate
                  ? toMoment(payout.data.payoutDate).format('L')
                  : '-'}
              </dd>
              {payout.data.reportingPeriod && (
                <>
                  <dt>Reporting period</dt>
                  <dd>
                    {toMoment(payout.data.reportingPeriod.start).format('L')} –{' '}
                    {toMoment(payout.data.reportingPeriod.end).format('L')}
                  </dd>
                </>
              )}
              {payout.data.payoutReference &&
                payout.data.payoutReference !== payout.data.payoutId && (
                  <>
                    <dt>Payout reference</dt>
                    <dd>{payout.data.payoutReference}</dd>
                  </>
                )}
              {payout.data.advertiserId && (
                <>
                  <dt>Advertiser ID</dt>
                  <dd>{payout.data.advertiserId}</dd>
                </>
              )}
              {payout.data.advertiserName && (
                <>
                  <dt>Advertiser</dt>
                  <dd>{payout.data.advertiserName}</dd>
                </>
              )}
            </List>
          </DrawerSection>
          <TransactionsSummaryWrapper>
            <PayoutTransactions payout={payout} />
          </TransactionsSummaryWrapper>
          <DrawerSection>
            <SectionHeading>Other dates</SectionHeading>
            <List>
              <dt>Due date</dt>
              <dd>
                {payout.data.dueDate
                  ? toMoment(payout.data.dueDate).format('L')
                  : '-'}
              </dd>
              <dt>Created date</dt>
              <dd>
                {payout.data.createdDate
                  ? toMoment(payout.data.createdDate).format('L')
                  : '-'}
              </dd>
            </List>
          </DrawerSection>
        </InnerWrapper>
      )}
    </Drawer>
  );
};
