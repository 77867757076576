import isPropValid from '@emotion/is-prop-valid';
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography
} from '@material-ui/core';
import { isUndefined } from 'lodash';
import React, { useMemo } from 'react';
import { ArrowRight, Globe, Info, Settings } from 'react-feather';
import { Link } from 'react-router-dom';
import { AddAdditionalDomainModal } from '../../../../../../components/AddDomainModal';
import { OffsiteChannelForm } from '../../../../../../components/AddDomainModal/components/OffsiteChannelForm';
import {
  AdditionActionsMenuOption,
  AdditionalActionsMenu
} from '../../../../../../components/AdditionalActionsMenu';
import { AlertBox } from '../../../../../../components/AlertBox';
import { ButtonWithPromise } from '../../../../../../components/ButtonWithPromise';
import {
  Data,
  EarningsBarChartV2
} from '../../../../../../components/Charts/EarningsChartV2';
import { Loader } from '../../../../../../components/Loader';
import {
  formatCurrency,
  formatNumber
} from '../../../../../../components/Number';
import { PulsingCircle } from '../../../../../../components/PulsingCircle';
import { SiteSettings } from '../../../../../../components/SiteSettings';
import { StartHere } from '../../../../../../components/StartHere';
import {
  Heading,
  Hint,
  SubHeading
} from '../../../../../../components/Typography';
import { Timeframe } from '../../../../../../domainTypes/analytics';
import { AnalyticsQuery } from '../../../../../../domainTypes/analytics_v2';
import { IChannel } from '../../../../../../domainTypes/channels';
import { CurrencyCode } from '../../../../../../domainTypes/currency';
import { Doc } from '../../../../../../domainTypes/document';
import { EMPTY_ARR } from '../../../../../../domainTypes/emptyConstants';
import { IDomain } from '../../../../../../domainTypes/space';
import { styled } from '../../../../../../emotion';
import { useDialogState } from '../../../../../../hooks/useDialogState';
import { useSnackbar } from '../../../../../../hooks/useSnackbar';
import { FlexContainer } from '../../../../../../layout/Flex';
import { useRoutes } from '../../../../../../routes';
import { useAdminOrImpersonatorClaim } from '../../../../../../services/auth';
import { ARTICLES } from '../../../../../../services/beacon';
import {
  saveChannel,
  toChannelIcon
} from '../../../../../../services/channels';
import {
  useCurrentUser,
  useHasCurrentUserRequiredScopes
} from '../../../../../../services/currentUser';
import {
  isInitiallyLoading,
  LoadingValue
} from '../../../../../../services/db';
import { useFeatureEnabled } from '../../../../../../services/features';
import { setDomainActive } from '../../../../../../services/space';
import {
  isoRangeToTimeframe,
  ISOTimeRange,
  now
} from '../../../../../../services/time';
import { withoutProtocol } from '../../../../../../services/url';
import { ClicksTable } from '../../../../components/DomainSummary/ClicksTable';
import { CHART_HEIGHT } from '../../../../components/DomainSummary/constants';
import { EarningsTable } from '../../../../components/DomainSummary/EarningsTable';
import { RealTimeReportingMiniChart } from '../../../../components/DomainSummary/RealtimeReporting';
import { StatChip } from '../../../../components/DomainSummary/StatChip';
import { EarningsSummary } from '../EarningsSummary';
import {
  useAdvertiserEarningsDataTotal,
  useAverageCommissionForChannel,
  useAverageCommissionForDomain,
  useContentTrendsByClicksForDomain,
  useContentTrendsByEarningsForDomain,
  useEarningsBarChartData,
  useEarningsBarChartDataForChannel,
  useEarningsByCampaignIdForChannel,
  useEarningsBySubIdForChannel,
  useOrderRateForChannel,
  useOrderRateForDomain,
  useRealtimeReportingMiniChartData,
  useSummaryEarningsDataForChannel,
  useSummaryEarningsDataForDomain,
  useSummaryEarningsDataTotal
} from './data';
import {
  createChannelFilterDefinition,
  createSiteFilterDefinition
} from '../../../../../../components/analytics_v2/Filters/filters';

const OverflowingCard = styled(Card, {
  shouldForwardProp: (props) => isPropValid(props)
})<{ highlight?: boolean }>`
  && {
    overflow: visible;
    background-color: ${(p) => (p.highlight ? `#f5fbff` : '#FFF')};
    border: ${(p) => (p.highlight ? `1px solid #d3e6f8` : 'none')};
  }
`;

const Header = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${(p) => p.theme.spacing(3)}px;
`;

const HeaderLeft = styled('div')`
  display: flex;
  align-items: center;
  flex-flow: row wrap;

  > div:first-of-type {
    margin-right: ${(p) => p.theme.spacing(2)}px;
  }

  > * {
    margin-right: ${(p) => p.theme.spacing()}px;
  }
`;

const SubHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${(p) => p.theme.spacing(2)}px;
`;

const GridItemBody = styled('div')`
  height: calc(100% - 34px);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const Grid = styled('div')`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: ${(p) => p.theme.spacing(6)}px;

  ${(p) => p.theme.breakpoints.down('md')} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${(p) => p.theme.spacing(6)}px;
  }

  ${(p) => p.theme.breakpoints.down('sm')} {
    grid-template-columns: 1fr;
    grid-gap: ${(p) => p.theme.spacing(6)}px;
  }
`;

const GridItem: React.FC<{
  heading: React.ReactNode;
  subheading?: React.ReactNode;
  info?: string;
  style?: React.CSSProperties;
}> = ({ heading, subheading, children, style, info }) => {
  return (
    <div style={style}>
      <SubHeader>
        <SubHeading>
          {heading}

          {info && (
            <Tooltip title={info} placement="top">
              <span
                style={{ marginLeft: '5px', position: 'relative', top: '2px' }}
              >
                <Info size={13} />
              </span>
            </Tooltip>
          )}
        </SubHeading>
        {subheading ? <Hint>{subheading}</Hint> : null}
      </SubHeader>
      <GridItemBody>{children}</GridItemBody>
    </div>
  );
};

const OrderRateChip = ({
  d
}: {
  d: LoadingValue<{ curr: number; prev: number | undefined }>;
}) => {
  const [v] = d;
  const values = useMemo(() => {
    if (!v) {
      return { value: '', diff: '' };
    }

    // d.prev might be null, if no data for the comparison timeframe is available,
    // e.g. new accounts will frequently run into this
    return {
      value: formatNumber({
        n: v.curr,
        format: 'percent',
        digits: 2
      }),
      diffFormatted: v.prev
        ? formatNumber({
            n: v.curr - v.prev,
            format: 'percent',
            digits: 2,
            plusMinus: true
          })
        : '',
      diff: v.prev ? v.curr - v.prev : 0
    };
  }, [v]);

  return (
    <StatChip
      label="OR"
      value={values.value}
      diff={values.diffFormatted}
      tooltip="Order Rate: The ratio of your clicks to orders"
      color={values.diff === 0 ? 'gray' : values.diff > 0 ? 'green' : 'yellow'}
      loading={isInitiallyLoading(d)}
    />
  );
};

const AverageCommissionChip = ({
  d,
  currency
}: {
  d: LoadingValue<{ curr: number; prev: number | undefined }>;
  currency: CurrencyCode;
}) => {
  const [v] = d;
  const values = useMemo(() => {
    if (!v) {
      return { value: '', diff: '' };
    }

    // d.prev might be null, if no data for the comparison timeframe is available,
    // e.g. new accounts will frequently run into this
    return {
      value: formatCurrency(v.curr, currency),
      diffFormatted: isUndefined(v.prev)
        ? ''
        : formatCurrency(v.curr - v.prev, currency, true),
      diff: v.prev ? v.curr - v.prev : 0
    };
  }, [v, currency]);
  return (
    <StatChip
      label="AVC"
      value={values.value}
      diff={values.diffFormatted}
      tooltip="Average Commission: How much each product you're selling earns you on average in commissions"
      color={values.diff === 0 ? 'gray' : values.diff > 0 ? 'green' : 'yellow'}
      loading={isInitiallyLoading(d)}
    />
  );
};

export const DomainSummary = ({
  spaceId,
  domain,
  range,
  compareRange,
  tz,
  currency
}: {
  spaceId: string;
  domain: IDomain;
  range: ISOTimeRange;
  compareRange: ISOTimeRange | null;
  tz: string;
  currency: CurrencyCode;
}) => {
  const { ROUTES } = useRoutes();
  const { dialogOpen, openDialog, closeDialog } = useDialogState(false);
  const [canEditDomains] = useHasCurrentUserRequiredScopes(['domains.edit']);

  const options = useMemo(() => {
    const os: AdditionActionsMenuOption[] = [
      {
        key: 'settings',
        label: 'Site Settings',
        dialogMaxWidth: 'sm',
        dialog: ({ onClose }) => {
          return (
            <>
              <DialogTitle>Site settings</DialogTitle>
              <DialogContent>
                <SiteSettings domain={domain.url} />
              </DialogContent>
            </>
          );
        }
      },
      {
        key: 'deactive',
        label: 'Deactivate',
        dialogMaxWidth: 'sm',
        dialog: ({ onClose }) => {
          return (
            <>
              <DialogTitle>
                Deactivate {withoutProtocol(domain.url)}?
              </DialogTitle>
              <DialogContent>
                <AlertBox variant="pending">
                  <Typography variant="body1" component="p">
                    Deactivating a website will hide this website from your
                    dashboard, but <strong>does not</strong> stop it from
                    sending data to Affilimate.
                  </Typography>
                </AlertBox>
                <br />
                <Typography variant="body1" component="p" paragraph>
                  Please remove the Affilimate tracking snippet from this
                  website before pressing Deactivate. Once you've both removed
                  the snippet and deactivated, this site will no longer be
                  tracked in your dashboard or send data to Affilimate.
                </Typography>
                <Typography variant="body1" component="p" paragraph>
                  Don't remember how you installed the snippet?{' '}
                  <Link
                    to={ROUTES.docs.knowledgeBase.url(ARTICLES.setup.install)}
                    style={{ borderBottom: '1px solid' }}
                  >
                    Review original install guide
                  </Link>
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <ButtonWithPromise
                  variant="contained"
                  color="secondary"
                  onClick={() => setDomainActive(spaceId, domain.url, false)}
                  pending="Deactivating..."
                >
                  Deactivate site, I've removed the snippet
                </ButtonWithPromise>
              </DialogActions>
            </>
          );
        }
      }
    ];
    return os;
  }, [spaceId, domain.url, ROUTES.docs.knowledgeBase]);

  const domainWithoutProtocol = withoutProtocol(domain.url);
  const transactionsPage = ROUTES.performanceNew.transactions.url({
    filters: [createSiteFilterDefinition([domainWithoutProtocol])]
  });

  const summaryData = useSummaryEarningsDataForDomain(
    spaceId,
    domainWithoutProtocol,
    range,
    compareRange,
    tz
  );
  const avgCommData = useAverageCommissionForDomain(
    spaceId,
    domainWithoutProtocol,
    range,
    compareRange
  );

  const orderRateData = useOrderRateForDomain(
    spaceId,
    domainWithoutProtocol,
    range,
    compareRange
  );

  const contentTrendsByEarningsData = useContentTrendsByEarningsForDomain(
    spaceId,
    domainWithoutProtocol,
    range,
    compareRange
  );

  const contentTrendsByClicksData = useContentTrendsByClicksForDomain(
    spaceId,
    domainWithoutProtocol,
    range,
    compareRange
  );

  const domainFilter = useMemo<AnalyticsQuery['filters']>(
    () => [
      {
        field: 'page_url_origin',
        condition: 'in',
        values: [domainWithoutProtocol]
      }
    ],
    [domainWithoutProtocol]
  );
  const earningsChartData = useEarningsBarChartData(
    spaceId,
    range,
    tz,
    domainFilter
  );

  return (
    <OverflowingCard>
      <Header>
        <HeaderLeft>
          <Heading>
            <FlexContainer alignItems="center">
              <Globe size={17} />
              <Link to={transactionsPage}>{domainWithoutProtocol}</Link>
            </FlexContainer>
          </Heading>
          {!domain.verified && (
            <StartHere>
              <Button
                onClick={openDialog}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <PulsingCircle color="green" /> Waiting for data
              </Button>
            </StartHere>
          )}
          {domain.verified && (
            <>
              <OrderRateChip d={orderRateData} />
              <AverageCommissionChip d={avgCommData} currency={currency} />
            </>
          )}
        </HeaderLeft>
        {canEditDomains && (
          <AdditionalActionsMenu variant="cog" options={options} />
        )}
      </Header>

      <Grid>
        <Link to={transactionsPage}>
          <GridItem
            heading="Earnings summary"
            style={{
              height: '100%'
            }}
          >
            <EarningsSummary data={summaryData} currency={currency} />
          </GridItem>
        </Link>
        <Link to={transactionsPage}>
          <GridItem heading="Daily earnings and clicks">
            <EarningsChart
              data={earningsChartData}
              currency={currency}
              timeframe={isoRangeToTimeframe(range, tz)}
            />
          </GridItem>
        </Link>
        <GridItem
          heading={
            <Link
              to={ROUTES.content.overview.url({ site: domainWithoutProtocol })}
            >
              Content by earnings
            </Link>
          }
        >
          {isInitiallyLoading(contentTrendsByEarningsData) ? (
            <Loader height={CHART_HEIGHT} />
          ) : (
            <EarningsTable
              rows={contentTrendsByEarningsData[0] || EMPTY_ARR}
              currency={currency}
            />
          )}
        </GridItem>
        <GridItem heading="Content by clicks">
          {isInitiallyLoading(contentTrendsByClicksData) ? (
            <Loader height={CHART_HEIGHT} />
          ) : (
            <ClicksTable rows={contentTrendsByClicksData[0] || EMPTY_ARR} />
          )}
        </GridItem>
      </Grid>
      <AddAdditionalDomainModal
        open={dialogOpen}
        onClose={closeDialog}
        domain={domain.url}
        onVerifyDomain={() => Promise.resolve()}
        onScan={() => Promise.resolve()}
      />
    </OverflowingCard>
  );
};

export const ChannelSummary = ({
  spaceId,
  channel,
  range,
  compareRange,
  tz,
  currency
}: {
  spaceId: string;
  channel: Doc<IChannel>;
  range: ISOTimeRange;
  compareRange: ISOTimeRange | null;
  tz: string;
  currency: CurrencyCode;
}) => {
  const { ROUTES } = useRoutes();
  const currentUser = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();
  const [newChannel, setNewChannel] = React.useState<Doc<IChannel>>(channel);
  const [canEditChannels] = useHasCurrentUserRequiredScopes(['channels.edit']);
  const { dialogOpen, setDialogOpen } = useDialogState(false);

  const { channelId } = channel.data;
  const transactionsPage = ROUTES.performanceNew.transactions.url({
    filters: [createChannelFilterDefinition([channelId])]
  });

  const summaryData = useSummaryEarningsDataForChannel(
    spaceId,
    channelId,
    range,
    compareRange,
    tz
  );
  const avgCommData = useAverageCommissionForChannel(
    spaceId,
    channelId,
    range,
    compareRange
  );

  const orderRateData = useOrderRateForChannel(
    spaceId,
    channelId,
    range,
    compareRange
  );

  const earningsBySubId = useEarningsBySubIdForChannel(
    spaceId,
    channelId,
    range,
    compareRange
  );

  const earningsByCampaign = useEarningsByCampaignIdForChannel(
    spaceId,
    channelId,
    range,
    compareRange
  );

  // const channelFilter = useMemo<AnalyticsQuery['filters']>(
  //   () => [
  //     {
  //       field: 'channel_id',
  //       condition: 'in',
  //       values: [channelId]
  //     }
  //   ],
  //   [channelId]
  // );
  // const earningsChartData = useEarningsBarChartData(
  //   spaceId,
  //   range,
  //   tz,
  //   channelFilter
  // );
  const earningsChartData = useEarningsBarChartDataForChannel(
    spaceId,
    range,
    tz,
    channelId
  );

  return (
    <>
      <OverflowingCard>
        <Header>
          <HeaderLeft>
            <Heading>
              <Link to={transactionsPage}>
                <FlexContainer alignItems="center">
                  {toChannelIcon(channel.data.type)} {channel.data.name}
                </FlexContainer>
              </Link>
            </Heading>
            <OrderRateChip d={orderRateData} />
            <AverageCommissionChip d={avgCommData} currency={currency} />
          </HeaderLeft>
          {canEditChannels && (
            <IconButton onClick={() => setDialogOpen(true)}>
              <Settings size={20} style={{ color: '#BBB' }} />
            </IconButton>
          )}
        </Header>

        <Grid>
          <Link to={transactionsPage}>
            <GridItem
              heading="Earnings summary"
              style={{
                height: '100%'
              }}
            >
              <EarningsSummary data={summaryData} currency={currency} />
            </GridItem>
          </Link>
          <Link to={transactionsPage}>
            <GridItem heading="Daily earnings and clicks">
              <EarningsChart
                data={earningsChartData}
                currency={currency}
                timeframe={isoRangeToTimeframe(range, tz)}
              />
            </GridItem>
          </Link>
          <GridItem heading="Earnings by SubID">
            {isInitiallyLoading(earningsBySubId) ? (
              <Loader height={CHART_HEIGHT} />
            ) : (
              <EarningsTable
                rows={earningsBySubId[0] || EMPTY_ARR}
                currency={currency}
              />
            )}
          </GridItem>
          <GridItem heading="Earnings by Campaign">
            {isInitiallyLoading(earningsByCampaign) ? (
              <Loader height={CHART_HEIGHT} />
            ) : (
              <EarningsTable
                rows={earningsByCampaign[0] || EMPTY_ARR}
                currency={currency}
              />
            )}
          </GridItem>
        </Grid>
      </OverflowingCard>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        scroll="body"
      >
        <DialogContent>
          <OffsiteChannelForm
            channel={newChannel}
            allChannels={[]}
            setChannel={setNewChannel}
            onCancel={() => setDialogOpen(false)}
            onSaveChannel={async (channel) => {
              const newChannel: Doc<IChannel> = {
                ...channel,
                data: {
                  ...channel.data,
                  spaceId: spaceId,
                  updatedAt: now(),
                  createdBy: currentUser.id,
                  channelId: channel.data.channelId
                }
              };
              return saveChannel(newChannel).then(() => {
                enqueueSnackbar('Channel saved!', { variant: 'success' });
              });
            }}
            mode="edit"
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

const EarningsChartWrapper = styled('div')`
  .recharts-tooltip-wrapper {
    z-index: 2;
  }
`;

const EarningsChart = ({
  data,
  currency,
  timeframe
}: {
  data: LoadingValue<{
    sales: Data[];
    clicks: { tk: string; clicks: number }[];
  }>;
  currency: CurrencyCode;
  timeframe: Timeframe;
}) => {
  if (isInitiallyLoading(data)) {
    return <Loader height={CHART_HEIGHT} />;
  }
  return (
    <EarningsChartWrapper>
      <EarningsBarChartV2
        data={data[0] ? data[0].sales : EMPTY_ARR}
        clickData={data[0] ? data[0].clicks : EMPTY_ARR}
        currency={currency}
        timeframe={timeframe}
        height={CHART_HEIGHT}
        size="small"
        intervalUnit={'day'}
      />
    </EarningsChartWrapper>
  );
};

export const TotalSummary = ({
  spaceId,
  range,
  compareRange,
  tz,
  currency
}: {
  spaceId: string;
  range: ISOTimeRange;
  compareRange: ISOTimeRange | null;
  tz: string;
  currency: CurrencyCode;
}) => {
  const { ROUTES } = useRoutes();

  const [isAdminOrImpersonator] = useAdminOrImpersonatorClaim();
  const transactionsPage = ROUTES.performanceNew.transactions.url();
  const advertisersPage = ROUTES.performanceNew.advertisers.overview.url();
  // To start, we will only surface realtime to publishers
  // with access to traffic sources. This will
  // probably get removed soon.
  const hasTrafficSourcesEnabled = useFeatureEnabled('REFERRER_REPORTS_V1');

  const summaryData = useSummaryEarningsDataTotal(
    spaceId,
    range,
    compareRange,
    tz
  );
  const advertiserEarningsData = useAdvertiserEarningsDataTotal(
    spaceId,
    range,
    compareRange
  );

  const rtMiniChartData = useRealtimeReportingMiniChartData(spaceId);

  const earningsChartData = useEarningsBarChartData(spaceId, range, tz);

  return (
    <OverflowingCard highlight elevation={1}>
      <Grid>
        <Link to={transactionsPage}>
          <GridItem
            heading="Portfolio summary"
            info="All revenue from all traffic sources, including commissions without site attribution. Typically higher than site totals for this reason."
            style={{
              height: '100%'
            }}
          >
            <EarningsSummary data={summaryData} currency={currency} />
          </GridItem>
        </Link>

        <Link to={transactionsPage}>
          <GridItem heading="Combined earnings and clicks">
            <EarningsChart
              data={earningsChartData}
              currency={currency}
              timeframe={isoRangeToTimeframe(range, tz)}
            />
          </GridItem>
        </Link>

        <Link to={advertisersPage}>
          <GridItem heading="Advertisers by earnings">
            {isInitiallyLoading(advertiserEarningsData) ? (
              <Loader height={CHART_HEIGHT} />
            ) : (
              <EarningsTable
                rows={advertiserEarningsData[0] || EMPTY_ARR}
                currency={currency}
              />
            )}
          </GridItem>
        </Link>
        {isAdminOrImpersonator || hasTrafficSourcesEnabled ? (
          <Link to={ROUTES.dashboard.realtime.url()}>
            <GridItem
              heading={
                <>
                  Realtime{' '}
                  <ArrowRight
                    size={14}
                    style={{ position: 'relative', top: '2px' }}
                  />
                </>
              }
              subheading="Last 48h&nbsp;&nbsp;&nbsp;"
            >
              <RealTimeReportingMiniChart data={rtMiniChartData} />
            </GridItem>
          </Link>
        ) : (
          <GridItem
            heading="Monitoring"
            info="Clicks and pageviews within affiliate content. Updated hourly, displayed in your own browser timezone rather than account timezone."
            subheading="Last 48h&nbsp;&nbsp;&nbsp;"
          >
            <RealTimeReportingMiniChart data={rtMiniChartData} />
          </GridItem>
        )}
      </Grid>
    </OverflowingCard>
  );
};
