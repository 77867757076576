import React from 'react';
import { CampaignAdvertiser } from '../../../domainTypes/campaigns';
import { getKnownPartnerForKey } from '../../../services/partner';
import { WithShape } from '../../../components/Charts/Util';
import { getAdvertiserColor } from '../../../components/AdvertiserWithColor';
import { ConnectionId } from '../../../components/ConnectionId';
import { Typography } from '@material-ui/core';
import { FlexContainer } from '../../../layout/Flex';

export const AdvertiserLabel: React.FC<{ advertiser: CampaignAdvertiser }> = ({
  advertiser
}) => {
  const platform = getKnownPartnerForKey(advertiser.partnerKey);
  return (
    <FlexContainer alignItems="center">
      <WithShape
        large
        color={getAdvertiserColor(advertiser.name, advertiser.partnerKey)}
      >
        {advertiser.name}
      </WithShape>
      {platform && (
        <Typography variant="caption" color="textSecondary">
          {platform.name}
        </Typography>
      )}
      <ConnectionId>{advertiser.integrationId}</ConnectionId>
    </FlexContainer>
  );
};
