import { Paper, Tab, Tabs, Typography } from '@material-ui/core';
import React from 'react';
import {
  CommissionWrapper,
  DrawerSection,
  ListWide,
  SectionHeading
} from '../../../../../../components/DrawerHeader';
import { Currency } from '../../../../../../components/Number';
import { PlatformWithColor } from '../../../../../../components/PlatformWithColor';
import { Centered } from '../../../../../../layout/Centered';
import { LimitedWidth } from '../../../../../../layout/PageBody';
import { useCurrentUser } from '../../../../../../services/currentUser';
import { usePayouts } from '../../../../../../services/payouts';
import { PayoutStatusBadge } from '../../../../components/PayoutStatus';
import { getKnownPartnerForKeyUnsafe } from '../../../../../../services/partner';
import {
  useStandardOptions,
  useTimeframeFromUrl
} from '../../../../../../components/TimeframePicker';
import { toMoment } from '../../../../../../services/time';
import { useRoutes, useNumberQueryParam } from '../../../../../../routes';
import { PaperLoader } from '../../../../../../components/Loader';
import { Doc } from '../../../../../../domainTypes/document';
import { Payout } from '../../../../../../domainTypes/payout';
import { styled } from '../../../../../../emotion';
import { Link } from 'react-router-dom';
import { ArrowRight } from 'react-feather';
import { toTransactionsLink } from '../../service';
import { PayoutTransactions } from '../../components/PayoutTransactions';
import { PayoutSpotCheck } from '../../components/PayoutSpotCheck';

const PayoutSummaryGrid = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: ${({ theme }) => theme.spacing(2)}px;
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;

  & > div {
    padding: ${({ theme }) => theme.spacing(2)}px;
  }
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: 700;

  svg {
    margin-left: ${({ theme }) => theme.spacing(0.5)}px;
  }
`;

const PayoutGrid = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${({ theme }) => theme.spacing(2)}px;
`;

const PayoutDetailsInner = ({ payout }: { payout: Doc<Payout> }) => {
  const { ROUTES } = useRoutes();
  const partnerKey = payout
    ? getKnownPartnerForKeyUnsafe(payout.data.partnerKey)
    : null;
  const { defaultOption } = useStandardOptions();
  const [timeframe] = useTimeframeFromUrl(defaultOption.value);
  const [selectedTab, setSelectedTab] = useNumberQueryParam('tab', 0);
  const handleChangeTab = (_: React.ChangeEvent<{}>, newValue: number) =>
    setSelectedTab(newValue);

  return (
    <div>
      <div>
        <PayoutGrid>
          <Paper>
            <DrawerSection>
              <CommissionWrapper>
                <Typography
                  variant="h3"
                  component="p"
                  style={{ fontWeight: 'bold' }}
                >
                  <Currency
                    cents={payout.data.netAmount.originalValue}
                    currency={payout.data.netAmount.originalCurrency}
                  />
                </Typography>
                <PayoutStatusBadge status={payout.data.payoutStatus} />
              </CommissionWrapper>
              {partnerKey && (
                <div style={{ display: 'flex', margin: '12px 0' }}>
                  <PlatformWithColor partner={partnerKey} />
                  &nbsp;
                  {payout.data.advertiserName &&
                    `(${payout.data.advertiserName})`}
                </div>
              )}
              <StyledLink to={toTransactionsLink(ROUTES, payout, timeframe)}>
                View transactions <ArrowRight size={14} />
              </StyledLink>
            </DrawerSection>
            <DrawerSection>
              <SectionHeading>Payout Details</SectionHeading>
              <ListWide>
                <dt>Payout date</dt>
                <dd>
                  {payout.data.payoutDate
                    ? toMoment(payout.data.payoutDate).format('L')
                    : 'Not paid yet'}
                </dd>
                <dt>Description</dt>
                <dd>{payout.data.description}</dd>
                <dt>Net amount</dt>
                <dd>
                  {payout.data.netAmount ? (
                    <Currency
                      cents={payout.data.netAmount.originalValue}
                      currency={payout.data.netAmount.originalCurrency}
                    />
                  ) : (
                    '-'
                  )}
                </dd>
                <dt>Tax amount</dt>
                <dd>
                  {payout.data.tax ? (
                    <Currency
                      cents={payout.data.tax.originalValue}
                      currency={payout.data.tax.originalCurrency}
                    />
                  ) : (
                    'N/A'
                  )}
                </dd>
                <dt>Gross amount</dt>
                <dd>
                  <Currency
                    cents={payout.data.amount.originalValue}
                    currency={payout.data.amount.originalCurrency}
                  />
                </dd>
                <dt>Reporting period</dt>
                <dd>
                  {payout.data.reportingPeriod
                    ? `${toMoment(payout.data.reportingPeriod.start).format(
                        'L'
                      )} – ${toMoment(payout.data.reportingPeriod.end).format(
                        'L'
                      )}`
                    : 'Not provided'}
                </dd>
              </ListWide>
            </DrawerSection>
            <DrawerSection>
              <SectionHeading>Invoice details</SectionHeading>
              <ListWide>
                {payout.data.advertiserName && (
                  <>
                    <dt>Advertiser</dt>
                    <dd>{payout.data.advertiserName}</dd>
                  </>
                )}
                {payout.data.advertiserId && (
                  <>
                    <dt>Advertiser ID</dt>
                    <dd>{payout.data.advertiserId}</dd>
                  </>
                )}
                <dt>Payout ID</dt>
                <dd>{payout.data.payoutId}</dd>
                <dt>Payout reference</dt>
                <dd>{payout.data.payoutReference || 'N/A'}</dd>
              </ListWide>
            </DrawerSection>
            <DrawerSection>
              <SectionHeading>Dates</SectionHeading>
              <ListWide>
                <dt>Due date</dt>
                <dd>
                  {payout.data.dueDate
                    ? toMoment(payout.data.dueDate).format('L')
                    : '-'}
                </dd>
                <dt>Created date</dt>
                <dd>
                  {payout.data.createdDate
                    ? toMoment(payout.data.createdDate).format('L')
                    : '-'}
                </dd>
              </ListWide>
            </DrawerSection>
          </Paper>
          <div>
            <Tabs value={selectedTab} onChange={handleChangeTab}>
              <Tab label="Transactions" />
              <Tab label="Spot check" />
            </Tabs>
            {selectedTab === 0 && (
              <Paper>
                <PayoutTransactions payout={payout} />
              </Paper>
            )}
            {selectedTab === 1 && (
              <Paper>
                <PayoutSpotCheck payout={payout} />
              </Paper>
            )}
          </div>
        </PayoutGrid>
      </div>
    </div>
  );
};

export const PayoutDetails = ({ payoutId }: { payoutId: string }) => {
  const { space } = useCurrentUser();
  const [allPayouts, loadingPayouts] = usePayouts(space.id);
  const payout = allPayouts
    ? allPayouts.find((payout) => payout.id === payoutId)
    : null;

  if (loadingPayouts) {
    return (
      <LimitedWidth width={1000}>
        <PaperLoader height={300} />
      </LimitedWidth>
    );
  }

  if (!payout) {
    return (
      <LimitedWidth width={1000}>
        <Paper>
          <Centered height={300}>No matching payout found</Centered>
        </Paper>
      </LimitedWidth>
    );
  }

  return (
    <div>
      <PayoutSummaryGrid></PayoutSummaryGrid>
      {payout && <PayoutDetailsInner payout={payout} />}
    </div>
  );
};
