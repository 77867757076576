import { Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import {
  Check,
  ChevronsDown,
  ChevronsUp,
  Info,
  RefreshCcw
} from 'react-feather';
import {
  DrawerSection,
  List,
  SectionHeading
} from '../../../../../components/DrawerHeader';
import { Loader } from '../../../../../components/Loader';
import { Currency, formatCurrency } from '../../../../../components/Number';
import {
  useStandardOptions,
  useTimeframeFromUrl
} from '../../../../../components/TimeframePicker';
import { COLORS } from '../../../../../domainTypes/colors';
import { Doc } from '../../../../../domainTypes/document';
import { Payout } from '../../../../../domainTypes/payout';
import { styled } from '../../../../../emotion';
import { useCurrentUser } from '../../../../../services/currentUser';
import { useExperimentalContext } from '../../../../../services/experimental';
import { usePayoutEarnings } from '../service';
import { PayoutTransactionsV2 } from './PayoutTransactionsV2';

const InfoIcon = styled(Info)`
  margin-left: ${({ theme }) => theme.spacing(1)}px;
  position: relative;
  top: 1px;
`;

const PayoutConverted = styled('div')`
  display: inline-block;
  align-items: center;
  background-color: ${COLORS.green.green7};
  color: white;
  margin-left: ${(p) => p.theme.spacing(0.5)}px;
  width: 16px;
  height: 16px;
  line-height: 14px;
  padding: 1px;
  border-radius: 100%;
  text-align: center;

  svg {
    display: inline-block;
  }
`;

const PayoutMatchCheck = styled('div')`
  display: inline-block;
  align-items: center;
  background-color: ${(p) => p.theme.palette.primary.main};
  color: white;
  margin-left: ${(p) => p.theme.spacing(0.5)}px;
  width: 16px;
  height: 16px;
  position: relative;
  text-align: center;
  top: -2px;
  line-height: 14px;
  padding: 0px;
  border-radius: 100%;

  svg {
    display: inline-block;
    position: relative;
    top: 2px;
  }
`;

const PayoutMatchWarning = styled('div')`
  display: inline-block;
  align-items: center;
  background-color: ${(p) => p.theme.palette.grey[800]};
  color: ${(p) => p.theme.palette.grey[100]};
  margin-left: ${(p) => p.theme.spacing(0.5)}px;
  width: 16px;
  height: 16px;
  position: relative;
  text-align: center;
  top: -2px;
  line-height: 14px;
  padding: 0px;
  border-radius: 100%;

  svg {
    display: inline-block;
    position: relative;
    top: 2px;
  }
`;

const PayoutTransactionsV1 = ({ payout }: { payout: Doc<Payout> }) => {
  const { space } = useCurrentUser();
  const accountCurrency = space.config.currency;
  const { defaultOption } = useStandardOptions();
  const [timeframe] = useTimeframeFromUrl(defaultOption.value);

  const [earnings, loadingEarnings] = usePayoutEarnings(
    space.id,
    timeframe,
    accountCurrency,
    payout
  );

  if (loadingEarnings || !earnings) {
    return <Loader height={340} size={24} />;
  }

  const isSamePayoutAndReportingCurrency =
    earnings.currency === payout.data.netAmount.originalCurrency;

  const isExactMatch =
    isSamePayoutAndReportingCurrency &&
    payout.data.netAmount.originalValue === earnings.total;

  const payoutDiff = earnings.total - payout.data.netAmount.originalValue;
  const payoutPercentDiff = (payoutDiff / earnings.total) * 100;

  const isCloseMatch =
    !isExactMatch &&
    isSamePayoutAndReportingCurrency &&
    Math.abs(payoutPercentDiff) < 2;

  return (
    <div>
      <DrawerSection>
        <SectionHeading>
          Transaction summary
          <Tooltip
            title="Imported transactions that match this payout, reported in your account currency."
            placement="top"
          >
            <span style={{ display: 'inline-block' }}>
              <InfoIcon size={13} />
            </span>
          </Tooltip>
        </SectionHeading>
        <List>
          <dt>Net amount</dt>
          <dd>
            <Currency cents={earnings.total} currency={earnings.currency} />{' '}
            {isExactMatch && (
              <Tooltip
                title="This payout is fully reconciled against your imported transactions."
                placement="top"
              >
                <PayoutMatchCheck>
                  <Check size={12} />
                </PayoutMatchCheck>
              </Tooltip>
            )}
            {isCloseMatch && (
              <Tooltip
                title={`This payout is within ${formatCurrency(
                  Math.abs(payoutDiff),
                  accountCurrency
                )} (${Math.abs(payoutPercentDiff).toFixed(
                  2
                )}%) of your imported transaction data.`}
                placement="top"
              >
                <PayoutMatchCheck>
                  <span style={{ display: 'inline-block' }}>≈</span>
                </PayoutMatchCheck>
              </Tooltip>
            )}
            {!isCloseMatch &&
              !isExactMatch &&
              isSamePayoutAndReportingCurrency && (
                <Tooltip
                  title={`This payout is ${formatCurrency(
                    Math.abs(payoutDiff),
                    accountCurrency
                  )} ${payoutDiff > 0 ? 'less (-' : 'more (+'}${Math.abs(
                    payoutPercentDiff
                  ).toFixed(
                    2
                  )}%) than your imported transaction data for this Payout ID.`}
                  placement="top"
                >
                  <PayoutMatchWarning>
                    {payoutDiff > 0 ? (
                      <ChevronsUp size={12} />
                    ) : (
                      <ChevronsDown size={12} />
                    )}
                  </PayoutMatchWarning>
                </Tooltip>
              )}
            {!isSamePayoutAndReportingCurrency && (
              <Tooltip
                title="Account currency is different than payout currency. This is our estimated value based on your converted transaction data. May differ from the network or your bank's conversion rate."
                placement="top"
              >
                <PayoutConverted>
                  <RefreshCcw size={10} />
                </PayoutConverted>
              </Tooltip>
            )}
          </dd>
          <dt>Sales volume</dt>
          <dd>
            <Currency
              cents={earnings.saleValue.total}
              currency={earnings.currency}
            />{' '}
          </dd>
          <dt>Orders</dt>
          <dd>{earnings.orderCount.total.toLocaleString('en-US')}</dd>
          <dt>Items sold</dt>
          <dd>{earnings.transactionCount.toLocaleString('en-US')}</dd>
        </List>
      </DrawerSection>
      <DrawerSection>
        <SectionHeading>
          Statuses
          <Tooltip
            title="Statuses for commissions that match this payout."
            placement="top"
          >
            <span style={{ display: 'inline-block' }}>
              <InfoIcon size={13} />
            </span>
          </Tooltip>
        </SectionHeading>
        <List>
          <dt>Final or Pending</dt>
          <dd>
            <Currency cents={earnings.final} currency={earnings.currency} />{' '}
            &nbsp;&nbsp;&nbsp;{' '}
            <Typography
              variant="caption"
              color="textSecondary"
              component="span"
            >
              {earnings.totalCount.toLocaleString('en-US')}x
            </Typography>
          </dd>
          <dt>Refunded</dt>
          <dd>
            -<Currency cents={earnings.refunded} currency={earnings.currency} />
            &nbsp;&nbsp;&nbsp;{' '}
            <Typography
              variant="caption"
              color="textSecondary"
              component="span"
            >
              {earnings.refundCount}x
            </Typography>
          </dd>
          <dt>Canceled</dt>
          <dd>
            <Currency cents={earnings.cancelled} currency={earnings.currency} />
            &nbsp;&nbsp;&nbsp;{' '}
            <Typography
              variant="caption"
              color="textSecondary"
              component="span"
            >
              {earnings.cancelledCount}x
            </Typography>
          </dd>
        </List>
      </DrawerSection>
    </div>
  );
};

export const PayoutTransactions = ({ payout }: { payout: Doc<Payout> }) => {
  const [experimental] = useExperimentalContext();
  return experimental ? (
    <PayoutTransactionsV1 payout={payout} />
  ) : (
    <PayoutTransactionsV2 payout={payout} />
  );
};
