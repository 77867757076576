import React, { useMemo } from 'react';
import { styled } from '../../../emotion';
import { TimeframePickerDense } from '../../TimeframePicker';
import {
  RevisionSelectorDense,
  useRevisionFromUrl
} from '../../../features/Content/components/RevisionSelector';
import { useStandardSelectorStateFromUrl } from '../../../features/Content/components/Selectors';
import { useCurrentUser } from '../../../services/currentUser';
import { usePage } from '../../../services/page';
import { useTimeframe } from '../Timeframe';
import { getTimeBoundariesWithComparison } from '../../../services/page/revision';
import { isBefore } from '../../../services/time';
import { AnalyticsQuery } from '../../../domainTypes/analytics_v2';
import moment from 'moment-timezone';
import { IPageRevision } from '../../../domainTypes/page';
import firebase from 'firebase/app';

const Container = styled('div')`
  display: flex;
  align-items: flex-start;
  > * {
    margin-left: ${(p) => p.theme.spacing(1)}px;
  }
`;

interface TimeframeWithRevisionProps {
  url: string;
}

type Timestamp = firebase.firestore.Timestamp;
type Boundary = {
  start: Timestamp;
  end: Timestamp | null;
};

const fieldNames = {
  timeframe: 'timeframe',
  revision: 'revision',
  syncTimeframe: 'syncTimeframe'
};

const boundaryToRange = ({ start, end }: Boundary, t0: Timestamp) => {
  const clampedStart = isBefore(start, t0) ? t0 : start;
  return {
    start: moment(clampedStart.toMillis()).toISOString(),
    end: moment(end ? end.toMillis() : new Date()).toISOString()
  };
};

const revisionsToRanges = (
  selectedRevision: IPageRevision,
  revisions: IPageRevision[],
  spaceCreatedAt: Timestamp
) => {
  const { curr, prev } = getTimeBoundariesWithComparison(
    selectedRevision,
    revisions
  );
  const compare = prev
    ? { range: boundaryToRange(prev, spaceCreatedAt) }
    : undefined;
  const range = boundaryToRange(curr, spaceCreatedAt);
  return {
    compare,
    range
  };
};

export const useTimeframeWithRevision = (url: string) => {
  const {
    space: { id, createdAt }
  } = useCurrentUser();
  const [pageMetadata] = usePage(id, url);
  const revisions = useMemo(
    () => (pageMetadata ? pageMetadata.data.revisions : []),
    [pageMetadata]
  );
  const {
    value: { revision, syncTimeframe }
  } = useRevisionFromUrl(revisions, fieldNames);
  const timeframe = useTimeframe();
  return useMemo<Pick<AnalyticsQuery, 'range' | 'compare'>>(() => {
    if (revision && syncTimeframe) {
      return revisionsToRanges(revision, revisions, createdAt);
    }
    return timeframe;
  }, [createdAt, revision, revisions, syncTimeframe, timeframe]);
};

export const TimeframeWithRevision: React.FC<TimeframeWithRevisionProps> = ({
  url
}) => {
  const { space } = useCurrentUser();
  const [pageMetadata] = usePage(space.id, url);
  const { value, onChange, options } = useStandardSelectorStateFromUrl(
    space,
    pageMetadata ? pageMetadata.data.revisions : [],
    fieldNames
  );

  return (
    <Container>
      {options.revisions.length > 0 ? (
        <RevisionSelectorDense
          value={value}
          onChange={(newValue) => onChange({ ...value, ...newValue })}
          options={options.revisions}
          tz={value.timeframe.tz}
        />
      ) : null}
      <TimeframePickerDense
        value={value.timeframe}
        onChange={(timeframe) => onChange({ ...value, timeframe })}
        options={options.timeframes}
      />
    </Container>
  );
};
