import { capitalize } from 'lodash';
import { useMemo } from 'react';
import { getAdvertiserColor } from '../../../../components/AdvertiserWithColor';
import { EarningsBarChartCardMetricV2 } from '../../../../components/Charts/EarningsChartCardV2';
import {
  Data,
  transformAnalyticsResponseToEarningsChartData
} from '../../../../components/Charts/EarningsChartV2';
import {
  AnalyticsColumnTransformer,
  AnalyticsFilter,
  AnalyticsInterval,
  AnalyticsQuery,
  AnalyticsResponse,
  AnalyticsSearch
} from '../../../../domainTypes/analytics_v2';
import { COUNTRY_ABBREVIATIONS } from '../../../../domainTypes/country';
import { EMPTY_ARR } from '../../../../domainTypes/emptyConstants';
import {
  PAYOUT_UI_CONFIG,
  SALE_UI_CONFIG
} from '../../../../domainTypes/performance';
import { ISpace } from '../../../../domainTypes/space';
import { getCountryColor } from '../../../../services/analytics/country';
import {
  ANALYTICS_GROUPS,
  useChannelIdGrouper
} from '../../../../services/analyticsV2/groups';
import {
  AnalyticsOpts,
  useAnalyticsQueryV2
} from '../../../../services/analyticsV2/query';
import {
  Channel,
  channelContainer,
  useSpaceChannels
} from '../../../../services/channels/channels';
import { getStableRandomColor } from '../../../../services/color';
import { useMappedLoadingValue } from '../../../../services/db';
import {
  constructPartnerForKey,
  getKnownPartnerForKey
} from '../../../../services/partner';
import { ISOTimeRange } from '../../../../services/time';

const _useEarningsBarChartData = (
  spaceId: string,
  range: ISOTimeRange,
  filters: AnalyticsFilter[],
  search: AnalyticsSearch[],
  interval: AnalyticsInterval,
  groupBy: AnalyticsQuery['groupBy'],
  columnTransformers: AnalyticsColumnTransformer[],
  metric: EarningsBarChartCardMetricV2,
  mapFn: (res: AnalyticsResponse) => Data[],
  opts?: AnalyticsOpts
) => {
  const q = useMemo<AnalyticsQuery>(() => {
    return {
      range,
      filters,
      search,
      groupBy,
      columnTransformers,
      interval,
      select: [metric]
    };
  }, [range, filters, search, interval, groupBy, metric, columnTransformers]);

  return useMappedLoadingValue(useAnalyticsQueryV2(spaceId, q, opts), (res) =>
    mapFn(res)
  );
};

export const toEarningsBarChartDataForCountry = (
  res: AnalyticsResponse,
  metric: EarningsBarChartCardMetricV2,
  tz: string
) => {
  return transformAnalyticsResponseToEarningsChartData(
    res,
    (r) => r.group['country'],
    (countryCode) => {
      const countryName = COUNTRY_ABBREVIATIONS[countryCode] || countryCode;
      const label =
        countryName !== 'Unknown'
          ? `${countryCode} - ${countryName}`
          : 'Not available';

      return {
        key: countryCode,
        color: getCountryColor(countryCode),
        label
      };
    },
    tz,
    metric
  );
};

export const useEarningsBarChartDataForCountry = (
  space: ISpace,
  range: ISOTimeRange,
  filters: AnalyticsFilter[],
  search: AnalyticsSearch[],
  interval: AnalyticsInterval,
  metric: EarningsBarChartCardMetricV2,
  opts?: AnalyticsOpts
) => {
  return _useEarningsBarChartData(
    space.id,
    range,
    filters,
    search,
    interval,
    ANALYTICS_GROUPS.country.groupBy,
    EMPTY_ARR,
    metric,
    (res) => toEarningsBarChartDataForCountry(res, metric, interval.tz),
    opts
  );
};

export const toEarningsBarChartDataForDevice = (
  res: AnalyticsResponse,
  metric: EarningsBarChartCardMetricV2,
  tz: string
) => {
  return transformAnalyticsResponseToEarningsChartData(
    res,
    (r) => r.group['device'],
    (device) => {
      const deviceType = capitalize(device);
      return {
        key: deviceType,
        color: getStableRandomColor(deviceType),
        label: deviceType
      };
    },
    tz,
    metric
  );
};

export const useEarningsBarChartDataForDevice = (
  space: ISpace,
  range: ISOTimeRange,
  filters: AnalyticsFilter[],
  search: AnalyticsSearch[],
  interval: AnalyticsInterval,
  metric: EarningsBarChartCardMetricV2,
  opts?: AnalyticsOpts
) => {
  return _useEarningsBarChartData(
    space.id,
    range,
    filters,
    search,
    interval,
    ANALYTICS_GROUPS.device.groupBy,
    EMPTY_ARR,
    metric,
    (res) => toEarningsBarChartDataForDevice(res, metric, interval.tz),
    opts
  );
};

export const toEarningsBarChartDataForSaleStatus = (
  res: AnalyticsResponse,
  metric: EarningsBarChartCardMetricV2,
  tz: string
) => {
  return transformAnalyticsResponseToEarningsChartData(
    res,
    (r) => r.group['sale_status'],
    (status) => {
      const saleStatus = capitalize(status);
      const config = SALE_UI_CONFIG[saleStatus] || SALE_UI_CONFIG.Unknown;
      return {
        key: saleStatus,
        color: config.color,
        label: saleStatus
      };
    },
    tz,
    metric
  );
};

export const useEarningsBarChartDataForSaleStatus = (
  space: ISpace,
  range: ISOTimeRange,
  filters: AnalyticsFilter[],
  search: AnalyticsSearch[],
  interval: AnalyticsInterval,
  metric: EarningsBarChartCardMetricV2,
  opts?: AnalyticsOpts
) => {
  const groupBy = useMemo<AnalyticsQuery['groupBy']>(() => ['sale_status'], []);
  return _useEarningsBarChartData(
    space.id,
    range,
    filters,
    search,
    interval,
    groupBy,
    EMPTY_ARR,
    metric,
    (res) => toEarningsBarChartDataForSaleStatus(res, metric, interval.tz),
    opts
  );
};

export const toEarningsBarChartDataForPayoutStatus = (
  res: AnalyticsResponse,
  metric: EarningsBarChartCardMetricV2,
  tz: string
) => {
  return transformAnalyticsResponseToEarningsChartData(
    res,
    (r) => r.group['payout_status'],
    (status) => {
      const config = status
        ? PAYOUT_UI_CONFIG[status] || PAYOUT_UI_CONFIG.Unknown
        : PAYOUT_UI_CONFIG.Unknown;
      return {
        key: status,
        color: config.color,
        label: capitalize(status)
      };
    },
    tz,
    metric
  );
};

export const useEarningsBarChartDataForPayoutStatus = (
  space: ISpace,
  range: ISOTimeRange,
  filters: AnalyticsFilter[],
  search: AnalyticsSearch[],
  interval: AnalyticsInterval,
  metric: EarningsBarChartCardMetricV2,
  opts?: AnalyticsOpts
) => {
  const groupBy = useMemo<AnalyticsQuery['groupBy']>(
    () => ['payout_status'],
    []
  );
  return _useEarningsBarChartData(
    space.id,
    range,
    filters,
    search,
    interval,
    groupBy,
    EMPTY_ARR,
    metric,
    (res) => toEarningsBarChartDataForPayoutStatus(res, metric, interval.tz),
    opts
  );
};

export const toEarningsBarChartDataForNetwork = (
  res: AnalyticsResponse,
  metric: EarningsBarChartCardMetricV2,
  tz: string
) => {
  return transformAnalyticsResponseToEarningsChartData(
    res,
    (r) => r.group['pk'],
    (pk) => {
      const partner = getKnownPartnerForKey(pk) || constructPartnerForKey(pk);
      return {
        key: partner.key,
        color: partner.color,
        label: partner.name
      };
    },
    tz,
    metric
  );
};

export const useEarningsBarChartDataForNetwork = (
  space: ISpace,
  range: ISOTimeRange,
  filters: AnalyticsFilter[],
  search: AnalyticsSearch[],
  columnTransformers: AnalyticsColumnTransformer[],
  interval: AnalyticsInterval,
  metric: EarningsBarChartCardMetricV2,
  opts?: AnalyticsOpts
) => {
  return _useEarningsBarChartData(
    space.id,
    range,
    filters,
    search,
    interval,
    ANALYTICS_GROUPS.pk.groupBy,
    columnTransformers,
    metric,
    (res) => toEarningsBarChartDataForNetwork(res, metric, interval.tz),
    opts
  );
};

export const toEarningsBarChartDataForAdvertiser = (
  res: AnalyticsResponse,
  metric: EarningsBarChartCardMetricV2,
  tz: string
) => {
  return transformAnalyticsResponseToEarningsChartData(
    res,
    (r) =>
      JSON.stringify({ adv: r.group['advertiser_name'], pk: r.group['pk'] }),
    (json) => {
      const { adv, pk } = JSON.parse(json) as { adv: string; pk: string };
      return {
        key: `${adv}--${pk}`,
        color: getAdvertiserColor(adv, pk),
        label: adv
      };
    },
    tz,
    metric
  );
};

export const useEarningsBarChartDataForAdvertiser = (
  space: ISpace,
  range: ISOTimeRange,
  filters: AnalyticsFilter[],
  search: AnalyticsSearch[],
  columnTransformers: AnalyticsColumnTransformer[],
  interval: AnalyticsInterval,
  metric: EarningsBarChartCardMetricV2,
  opts?: AnalyticsOpts
) => {
  return _useEarningsBarChartData(
    space.id,
    range,
    filters,
    search,
    interval,
    ANALYTICS_GROUPS.advertiser.groupBy,
    columnTransformers,
    metric,
    (res) => toEarningsBarChartDataForAdvertiser(res, metric, interval.tz),
    opts
  );
};

// TODO youtube handling - older version of this code had some special handling
// do deal with onsite and offsite activity, which specifically counted youtube as onsite or something like that
export const toEarningsBarChartDataForPageUrlOrigin = (
  res: AnalyticsResponse,
  metric: EarningsBarChartCardMetricV2,
  tz: string
) => {
  return transformAnalyticsResponseToEarningsChartData(
    res,
    (r) => r.group['page_url_origin'],
    (pageUrlOrigin) => {
      const x = pageUrlOrigin || 'Other';
      return {
        key: x,
        color: getStableRandomColor(x),
        label: x
      };
    },
    tz,
    metric
  );
};

export const useEarningsBarChartDataForPageUrlOrigin = (
  space: ISpace,
  range: ISOTimeRange,
  filters: AnalyticsFilter[],
  search: AnalyticsSearch[],
  columnTransformers: AnalyticsColumnTransformer[],
  interval: AnalyticsInterval,
  metric: EarningsBarChartCardMetricV2,
  opts?: AnalyticsOpts
) => {
  const grouper = ANALYTICS_GROUPS.page_url_origin;
  const _columnTransformers = useMemo(
    () => [...grouper.columnTransformers(space), ...columnTransformers],
    [space, grouper, columnTransformers]
  );
  return _useEarningsBarChartData(
    space.id,
    range,
    filters,
    search,
    interval,
    grouper.groupBy,
    _columnTransformers,
    metric,
    (res) => toEarningsBarChartDataForPageUrlOrigin(res, metric, interval.tz),
    opts
  );
};

export const toEarningsBarChartDataForChannel = (
  res: AnalyticsResponse,
  metric: EarningsBarChartCardMetricV2,
  tz: string,
  channels: Channel[]
) => {
  return transformAnalyticsResponseToEarningsChartData(
    res,
    (r) => r.group['channel_id'],
    (channelId) => channelContainer(channelId, channels),
    tz,
    metric
  );
};

export const useEarningsBarChartDataForChannel = (
  space: ISpace,
  range: ISOTimeRange,
  filters: AnalyticsFilter[],
  search: AnalyticsSearch[],
  columnTransformers: AnalyticsColumnTransformer[],
  interval: AnalyticsInterval,
  metric: EarningsBarChartCardMetricV2,
  opts?: AnalyticsOpts
) => {
  const grouper = useChannelIdGrouper();
  const [channels = []] = useSpaceChannels();
  const _columnTransformers = useMemo(
    () => [...grouper.columnTransformers(space), ...columnTransformers],
    [space, grouper, columnTransformers]
  );
  return _useEarningsBarChartData(
    space.id,
    range,
    filters,
    search,
    interval,
    grouper.groupBy,
    _columnTransformers,
    metric,
    (res) =>
      toEarningsBarChartDataForChannel(res, metric, interval.tz, channels),
    opts
  );
};
