import React from 'react';
import { ReadyCampaign } from '../service';
import { goalPercent, TotalsObject } from '../service/totals';
import { formatNumber } from '../../../components/Number';
import moment from 'moment-timezone';
import Typography from '@material-ui/core/Typography';
import { useSpaceCurrency } from '../../../services/useSpaceCurrency';
import { formatChartCurrency } from '../../../components/Charts/Util';

export const RunningCampaignProgress: React.FC<{
  campaign: ReadyCampaign;
  totalValues: TotalsObject;
}> = ({ campaign, totalValues }) => {
  const durationPercentage = formatNumber({
    n:
      moment().diff(campaign.timeframe.start, 's') /
      moment(campaign.timeframe.end).diff(campaign.timeframe.start, 's'),
    format: 'percent',
    digits: 0
  });

  return (
    <>
      <Typography variant="body2" paragraph color="textSecondary">
        You’ve reached <strong>{goalPercent(totalValues)}</strong> of your{' '}
        <strong>{campaign.goals[0].amount}x ROAS</strong> goal{' '}
        <strong>{durationPercentage}</strong> of the way through the campaign
        timeframe.
      </Typography>
      <GoalForecastNotice campaign={campaign} totalValues={totalValues} />
    </>
  );
};

const GoalForecastNotice: React.FC<{
  campaign: ReadyCampaign;
  totalValues: TotalsObject;
}> = ({ totalValues, campaign }) => {
  const currency = useSpaceCurrency();
  const goalRatio = totalValues.gmv / totalValues.gmvGoal;

  const durationRatio =
    moment().diff(campaign.timeframe.start, 's') /
    moment(campaign.timeframe.end).diff(campaign.timeframe.start, 's');

  const isBehind = goalRatio < durationRatio;
  if (!isBehind) {
    return (
      <Typography variant="body2" paragraph color="textSecondary">
        You're on track to meet your goal. Keep up the good work!
      </Typography>
    );
  }

  const targetDailyAvg =
    (totalValues.gmvGoal - totalValues.gmv) /
    moment(campaign.timeframe.end).diff(moment(), 'd');

  const currentDailyAvg =
    totalValues.gmv / moment().diff(campaign.timeframe.start, 'd');

  const formattedTarget = formatChartCurrency(targetDailyAvg, currency, {
    excludeCents: true
  });

  const percentage = formatNumber({
    n: (targetDailyAvg - currentDailyAvg) / currentDailyAvg,
    format: 'percent',
    digits: 0
  });

  return (
    <Typography variant="body2" paragraph color="textSecondary">
      This campaign is behind. You'll need to average{' '}
      <strong>{formattedTarget}</strong> in GMV per day to reach your goal. This
      is <strong>{percentage}</strong> more per day than what you've done so
      far.
    </Typography>
  );
};
