import { IconButton, Typography } from '@material-ui/core';
import { compact, intersection } from 'lodash';
import React, { useMemo } from 'react';
import { ChevronLeft, ChevronRight, Search } from 'react-feather';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { createPlatformFilterDefinition } from '../../../../../../components/analytics_v2/Filters/filters';
import { ChartMode } from '../../../../../../components/Charts/EarningsChartCard/ChartModeSelector';
import { GraphMode } from '../../../../../../components/Charts/EarningsChartCard/GraphModeSelector';
import { PlatformWithColor } from '../../../../../../components/PlatformWithColor';
import { SearchInput } from '../../../../../../components/SearchInput';
import {
  SingleSelector,
  SingleSelectorChip,
  SingleSelectorOption
} from '../../../../../../components/SingleSelector';
import {
  TimeframePickerDense,
  useStandardOptions,
  useTimeframeFromUrl
} from '../../../../../../components/TimeframePicker';
import {
  isSameTimeframe,
  Timeframe
} from '../../../../../../domainTypes/analytics';
import { CurrencyCode } from '../../../../../../domainTypes/currency';
import {
  IEarningMinimal,
  IEarningMinimalField,
  SalesFilterArgs,
  SALE_TYPES_WITHOUT_BONUS
} from '../../../../../../domainTypes/performance';
import { styled } from '../../../../../../emotion';
import { CanvasBar } from '../../../../../../layout/Canvas';
import {
  DEFAULT_OFFSET,
  PageToolbar,
  PageToolbarSection
} from '../../../../../../layout/PageToolbar';
import { Section } from '../../../../../../layout/Section';
import {
  useNullableStringSetQueryParam,
  useNumberQueryParam,
  useRoutes,
  useStringQueryParam,
  useTypedStringQueryParam
} from '../../../../../../routes';
import { useEarningsPerAdvertiser } from '../../../../../../services/advertisers';
import { allTime } from '../../../../../../services/analytics';
import { useCurrentUser } from '../../../../../../services/currentUser';
import { getKnownPartnerForKeyUnsafe } from '../../../../../../services/partner';
import { useProductTimeseriesData } from '../../../../../../services/sales/earnings';
import { msToTimeframe, timeframeToMs } from '../../../../../../services/time';
import {
  AdvertiserProductGrouper,
  productGrouperToEnglish,
  useEarningsPerPageForAdvertisers,
  useEarningsPerProductSoldForAdvertisers
} from '../../../../services/advertisers';
import { AdvertiserDetailsTopProductsSoldTable } from './components/AdvertiserDetailsTopProductsSoldTable';
import { AdvertiserSummaryCard } from './components/AdvertiserSummaryCard';
import { EarningsPerProductChartCard } from './components/EarningsPerProductChartCard';
import { TopPagesChartCard } from './components/TopPagesChartCard';
import { AdvertiserDetailsTopPagesTable } from './components/TopPagesTable';
import { TopProductsChartCard } from './components/TopProductsChartCard';

const ADVERTISER_DETAILS_COLUMNS: IEarningMinimalField[] = [
  'ct',
  'pt',
  'oct',
  'qt',
  'ac'
];

const SearchInfo = styled('div')`
  background-color: #e5e5e5;
  color: ${({ theme }) => theme.palette.grey[600]};
  padding: ${({ theme }) => theme.spacing(0.5)}px;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  font-size: 12px;
  text-align: center;
`;

const TopGrid = styled('div')`
  display: grid;
  grid-template-columns: 1.3fr 2fr;
  min-height: 490px;
  grid-column-gap: ${({ theme }) => theme.spacing(2)}px;
  grid-row-gap: ${({ theme }) => theme.spacing(1)}px;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    grid-template-columns: 1fr;
  }
`;

const SearchAndPaginationWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)}px;
`;

const PAGE_SIZE = 11;
const VISIBLE_PAGE_SIZE = 10;

export const Body = ({
  spaceId,
  currency,
  partnerKeys,
  orderBy,
  setOrderBy,
  groupBy,
  setGroupBy,
  advertiserNames,
  search,
  timeframe
}: {
  spaceId: string;
  currency: CurrencyCode;
  orderBy: keyof IEarningMinimal;
  setOrderBy: (orderBy: keyof IEarningMinimal) => void;
  groupBy: AdvertiserProductGrouper;
  setGroupBy: (groupBy: AdvertiserProductGrouper) => void;
  partnerKeys: Set<string>;
  advertiserNames: Set<string>;
  search: string;
  timeframe: Timeframe;
}) => {
  const stablePartnerKeys = useMemo(() => [...partnerKeys].sort(), [
    partnerKeys
  ]);
  const stableAdvertiserNames = useMemo(() => [...advertiserNames].sort(), [
    advertiserNames
  ]);

  const compare = !isSameTimeframe(allTime(), timeframe);
  const [productPage, setProductPage] = useNumberQueryParam('productPage', 1);

  const q: SalesFilterArgs = useMemo(() => {
    const filters: SalesFilterArgs = { dates: timeframeToMs(timeframe) };
    if (stablePartnerKeys && stablePartnerKeys.length) {
      filters.partner_key = [...stablePartnerKeys];
    }
    if (stableAdvertiserNames && stableAdvertiserNames.length) {
      filters.advertiser_name = [...stableAdvertiserNames];
    }
    filters.sale_type = SALE_TYPES_WITHOUT_BONUS;
    filters.fields = ADVERTISER_DETAILS_COLUMNS;
    filters.search = { q: search };
    filters.page = productPage;
    filters.limit = PAGE_SIZE;
    filters.orderBy = {
      col: orderBy,
      dir: 'DESC'
    };

    return filters;
  }, [
    timeframe,
    productPage,
    search,
    orderBy,
    stableAdvertiserNames,
    stablePartnerKeys
  ]);

  const [earnings, loadingEarnings] = useEarningsPerAdvertiser(
    spaceId,
    timeframe,
    compare,
    currency,
    q
  );

  const [graphMode, setGraphMode] = useTypedStringQueryParam<GraphMode>(
    'graph_mode',
    'advertiser'
  );

  const [chartMode, setChartMode] = useTypedStringQueryParam<ChartMode>(
    'chart_mode',
    'barChart'
  );

  const [
    perPage,
    perPageLoading,
    perPageError
  ] = useEarningsPerPageForAdvertisers(
    spaceId,
    stablePartnerKeys,
    stableAdvertiserNames,
    timeframe,
    true,
    currency,
    q
  );

  const [
    perProductSold,
    perProductSoldLoading,
    perProductSoldError
  ] = useEarningsPerProductSoldForAdvertisers(
    spaceId,
    q,
    true,
    currency,
    groupBy
  );

  const [d, loadingTimeseries] = useProductTimeseriesData(
    spaceId,
    q,
    currency,
    groupBy,
    perProductSold
  );

  return (
    <>
      {search.length > 0 && (
        <SearchInfo>
          <Search size={12} /> These results apply your search term{' '}
          <strong>"{search}"</strong> to transactions with matching product
          names, product IDs, order IDs, and the selected metadata field.
        </SearchInfo>
      )}
      <Section>
        <AdvertiserSummaryCard
          currency={currency}
          advertiserNames={stableAdvertiserNames}
          perProductSold={perProductSold}
          perPage={perPage}
          groupBy={groupBy}
          orderBy={orderBy}
          totalEarnings={earnings}
          loadingEarnings={loadingEarnings}
          loading={perProductSoldLoading || perPageLoading}
        />
        <PageToolbar>
          <SearchAndPaginationWrapper>
            <Typography variant="body1" component="h2">
              <strong>Product performance</strong>
            </Typography>
          </SearchAndPaginationWrapper>
        </PageToolbar>
        <TopGrid>
          <TopProductsChartCard
            ds={perProductSold}
            groupBy={groupBy}
            orderBy={orderBy}
            loading={perProductSoldLoading}
            error={perProductSoldError}
            currency={currency}
          />
          <EarningsPerProductChartCard
            data={d ? d.data : undefined}
            loading={loadingTimeseries || perProductSoldLoading}
            currency={currency}
            graphMode={graphMode}
            orderBy={orderBy}
            setGraphMode={setGraphMode}
            chartMode={chartMode}
            setChartMode={setChartMode}
            timeframe={msToTimeframe(d ? d.dates : q.dates)}
          />
        </TopGrid>
        <PageToolbar>
          <div>
            <Typography variant="body1" component="h2">
              <strong>
                Performance by {productGrouperToEnglish(groupBy).toLowerCase()}
              </strong>
            </Typography>
            <Typography variant="caption" component="p" color="textSecondary">
              Update this list and the charts above using the{' '}
              <strong>Group by</strong> and <strong>Sort by</strong> options in
              the report header.
            </Typography>
          </div>
          <SearchAndPaginationWrapper>
            {productPage > 1 && (
              <IconButton
                onClick={() => {
                  setProductPage(productPage - 1);
                }}
              >
                <ChevronLeft size={16} />
              </IconButton>
            )}
            <span style={{ whiteSpace: 'nowrap' }}>Page {productPage}</span>
            {perProductSold &&
              perProductSold.length !== 0 &&
              perProductSold.length === PAGE_SIZE && (
                <IconButton
                  onClick={() => {
                    setProductPage(productPage + 1);
                  }}
                >
                  <ChevronRight size={16} />
                </IconButton>
              )}
          </SearchAndPaginationWrapper>
        </PageToolbar>
        <AdvertiserDetailsTopProductsSoldTable
          ds={
            perProductSold
              ? perProductSold.slice(0, VISIBLE_PAGE_SIZE)
              : undefined
          }
          loading={perProductSoldLoading}
          error={perProductSoldError}
          partnerKeys={stablePartnerKeys}
          groupBy={groupBy}
          orderBy={orderBy}
          currency={currency}
        />
      </Section>
      {false && (
        <Section>
          <TopPagesChartCard
            ds={perPage}
            loading={perPageLoading}
            error={perPageError}
            currency={currency}
          />
        </Section>
      )}
      <PageToolbar>
        <div>
          <Typography variant="body1" component="h2">
            <strong>Content performance</strong>
          </Typography>
        </div>
      </PageToolbar>
      <Section>
        <CanvasBar>
          <span>Top pages</span>
        </CanvasBar>
        <AdvertiserDetailsTopPagesTable
          ds={perPage}
          search={search}
          loading={perPageLoading}
          error={perPageError}
        />
      </Section>
    </>
  );
};

export const PagePerformanceAdvertiserDetailsInner = () => {
  const [
    selectedPartners
    //setSelectedPartners,
    //toPartnerQueryParam
  ] = useNullableStringSetQueryParam('partners');
  const { ROUTES } = useRoutes();

  const [
    selectedAdvertisers
    //setSelectedAdvertisers,
    //toAdvertiserQueryParam
  ] = useNullableStringSetQueryParam('advertisers', '---');
  const { options, defaultOption } = useStandardOptions();
  const [timeframe, setTimeframe] = useTimeframeFromUrl(defaultOption.value);
  const [search, setSearch] = useStringQueryParam('search');
  const [orderBy, setOrderBy] = useTypedStringQueryParam<keyof IEarningMinimal>(
    'orderBy',
    'ct'
  );
  const [groupBy, setGroupBy] = useTypedStringQueryParam<
    AdvertiserProductGrouper
  >('productGroupBy', 'partner_product_name');
  const { space } = useCurrentUser();

  const orderByOptions = useMemo(() => {
    // Filter the options based the network
    const options: SingleSelectorOption<IEarningMinimalField>[] = compact([
      {
        value: 'ct',
        label: 'Earnings',
        searchValue: 'Earnings'
      },
      {
        value: 'pt',
        label: 'Sales volume',
        searchValue: 'Sales volume'
      },
      !selectedPartners?.has('amazon') && {
        value: 'oct',
        label: 'Order count',
        searchValue: 'Order count'
      },
      {
        value: 'qt',
        label: 'Quantity sold',
        searchValue: 'Quantity sold'
      }
    ]);
    return options;
  }, [selectedPartners]);

  const groupByOptions = useMemo(() => {
    // Add metadata options based on whatever is
    // provided by the backend
    let options: SingleSelectorOption<AdvertiserProductGrouper>[] = compact([
      {
        value: 'partner_product_name',
        label: 'Product name',
        searchValue: 'Product name'
      },
      {
        value: 'partner_product_id',
        label: 'Product ID',
        searchValue: 'Product ID'
      }
    ]);

    if (selectedPartners) {
      if (
        intersection(Array.from(selectedPartners), [
          'impact',
          'amazon',
          'redventures'
        ]).length > 0
      ) {
        options = options.concat([
          {
            value: 'metadata.category',
            label: 'Category',
            searchValue: 'Category'
          }
        ]);
      }

      if (selectedPartners.has('amazon')) {
        options = options.concat([
          {
            value: 'metadata.seller',
            label: 'Seller',
            searchValue: 'Seller'
          }
        ]);
      }
    }
    return options;
  }, [selectedPartners]);

  if (!selectedPartners || !selectedAdvertisers) {
    return <div>no partner selected</div>;
  }

  return (
    <>
      <Helmet>
        <title>{[...selectedAdvertisers].join(',')} | Affilimate</title>
      </Helmet>
      <PageToolbar sticky offset={DEFAULT_OFFSET}>
        <PageToolbarSection flex={6} justifyContent="flex-start">
          <Typography
            variant="h6"
            component="h2"
            style={{ marginRight: '6px' }}
          >
            <strong>{[...selectedAdvertisers].join(',')}</strong>
          </Typography>
          {[...selectedPartners].map((partnerKey) => {
            const partner = getKnownPartnerForKeyUnsafe(partnerKey);
            return (
              <Link
                to={ROUTES.performanceNew.advertisers.overview.url({
                  filters: [createPlatformFilterDefinition([partnerKey])]
                })}
              >
                <PlatformWithColor partner={partner} key={partnerKey} />
              </Link>
            );
          })}
          &nbsp;&nbsp;
          <SingleSelector
            value={groupBy}
            onChange={setGroupBy}
            legend="Group by"
            options={groupByOptions}
          >
            <SingleSelectorChip
              isApplied={!!groupBy}
              label="Group by"
              appliedLabel={`Group by ${productGrouperToEnglish(groupBy)}`}
            />
          </SingleSelector>
          <SingleSelector
            value={orderBy}
            onChange={setOrderBy}
            legend="Sort by"
            options={orderByOptions}
          >
            <SingleSelectorChip
              isApplied={!!orderBy}
              label="Sort by"
              appliedLabel={`Sort by ${
                orderByOptions.find((o) => o.value === orderBy)?.label
              }`}
            />
          </SingleSelector>
        </PageToolbarSection>
        <PageToolbarSection flex={3} justifyContent="flex-end">
          <SearchInput
            value={search}
            onChange={setSearch}
            width={250}
            placeholder="Search transactions"
          />
          <TimeframePickerDense
            value={timeframe}
            onChange={setTimeframe}
            options={options}
          />
        </PageToolbarSection>
      </PageToolbar>
      <Body
        spaceId={space.id}
        timeframe={timeframe}
        search={search}
        orderBy={orderBy}
        groupBy={groupBy}
        setOrderBy={setOrderBy}
        setGroupBy={setGroupBy}
        partnerKeys={selectedPartners}
        advertiserNames={selectedAdvertisers}
        currency={space.config.currency}
      />
    </>
  );
};
