import { Button, Typography } from '@material-ui/core';
import * as moment from 'moment-timezone';
import React, { useMemo } from 'react';
import { IInvoice } from '../../../domainTypes/billing';
import { styled } from '../../../emotion';
import { COLORS } from '../../../domainTypes/colors';
import { Lock } from 'react-feather';

const IconWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  text-align: center;
  margin: ${(p) => p.theme.spacing(3)}px auto ${(p) => p.theme.spacing(3)}px;
  border-radius: 100%;
`;

const Grid = styled('div')`
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-gap: ${(p) => p.theme.spacing(1)}px;
  max-width: 500px;
  margin: ${(p) => p.theme.spacing(3)}px auto;
  background-color: ${COLORS.volcano.volcano1};
  border: 2px dashed ${COLORS.volcano.volcano3};
  padding: ${(p) => p.theme.spacing(2)}px;
  border-radius: ${(p) => p.theme.shape.borderRadius}px;
`;

const OverdueInvoice = ({ invoice }: { invoice: IInvoice }) => {
  if (!invoice.due_date) {
    return null;
  }

  const isOverdue =
    moment.default(new Date(invoice.due_date * 1000)).isBefore(moment.now()) &&
    !invoice.paid;

  if (!isOverdue) {
    return null;
  }

  const dayDiff =
    moment
      .default(new Date(invoice.due_date * 1000))
      .diff(moment.now(), 'days') * -1;

  const createdDiff =
    moment
      .default(new Date(invoice.created * 1000))
      .diff(moment.now(), 'days') * -1;

  const createdDate = moment
    .default(new Date(invoice.created * 1000))
    .format('MMM Do YYYY');

  return (
    <>
      <div>{invoice.number}</div>
      <div>
        {createdDate}
        <br />
        <Typography
          variant="caption"
          component="p"
          style={{ color: COLORS.volcano.volcano5 }}
        >
          {createdDiff} days ago
        </Typography>
      </div>
      <div
        style={{
          fontWeight: 700,
          color: COLORS.volcano.volcano6
        }}
      >
        {dayDiff} days overdue
      </div>
    </>
  );
};

export const PageInvoicesOverdueOverlay = ({
  onIgnore,
  invoices
}: {
  onIgnore: () => void;
  invoices: IInvoice[] | void;
}) => {
  const OVERDUE_DAYS_LIMIT_BEFORE_LOCKOUT = 60;
  const mostOverdueInvoiceInDays = useMemo(() => {
    if (!invoices || !invoices.length) {
      return 0;
    }

    return invoices.reduce<number>((acc, invoice) => {
      if (!invoice.due_date) {
        return acc;
      }
      const dayDiff =
        moment
          .default(new Date(invoice.due_date * 1000))
          .diff(moment.now(), 'days') * -1;

      return Math.max(acc, dayDiff);
    }, 0);
  }, [invoices]);

  const daysRemaining =
    OVERDUE_DAYS_LIMIT_BEFORE_LOCKOUT - mostOverdueInvoiceInDays;

  if (!invoices || !invoices.length) {
    return null;
  }

  return (
    <div>
      <IconWrapper
        style={{
          color: COLORS.volcano.volcano6,
          backgroundColor: COLORS.volcano.volcano2
        }}
      >
        <Lock size={60} />
      </IconWrapper>

      <Typography
        variant="h5"
        component="p"
        paragraph
        style={{
          textAlign: 'center',
          textTransform: 'uppercase',
          color: COLORS.volcano.volcano6
        }}
      >
        <strong>Action required</strong>
      </Typography>
      <Typography variant="body1" component="p" paragraph>
        Your organization has invoices which are{' '}
        <strong>more than 30 days overdue</strong>.<br />
        Please pay the overdue invoices immediately to avoid automatic lockout.
      </Typography>
      <Grid>
        <Typography
          variant="body2"
          component="p"
          color="textSecondary"
          gutterBottom
        >
          Invoice number
        </Typography>
        <Typography
          variant="body2"
          component="p"
          color="textSecondary"
          gutterBottom
        >
          Date issued
        </Typography>
        <Typography
          variant="body2"
          component="p"
          color="textSecondary"
          gutterBottom
        >
          Days overdue
        </Typography>
        {invoices.reverse().map((invoice) => (
          <OverdueInvoice invoice={invoice} key={invoice.id} />
        ))}
      </Grid>
      {daysRemaining > 0 && (
        <div style={{ textAlign: 'center' }}>
          <Button
            variant="text"
            color="default"
            onClick={onIgnore}
            style={{ opacity: 0.5 }}
          >
            Temporarily bypass
          </Button>
          <Typography
            variant="caption"
            component="p"
            paragraph
            color="textSecondary"
            style={{ opacity: 0.5 }}
          >
            <em>&mdash; {daysRemaining} days until lockout &mdash;</em>
          </Typography>
        </div>
      )}
    </div>
  );
};
