import { compact, min, trim } from 'lodash';
import moment from 'moment-timezone';
import { CurrencyCode } from '../../../../../domainTypes/currency';
import { getTrackingId, ISale } from '../../../../../domainTypes/performance';
import { getExchangeRates } from '../../../../../services/currency';
import { getKnownPartnerForKeyUnsafe } from '../../../../../services/partner';
import { getActiveDomainUrls } from '../../../../../services/space';
import { fromMoment } from '../../../../../services/time';
import { toDomain, withoutProtocolOrWww } from '../../../../../services/url';
import { addDevice, toCents } from '../helpers';
import { IFileReportHandler } from '../types';

const toStatus = (action: string) => {
  if (action === 'Payment') {
    return 'Final';
  }
  if (action === 'Refund') {
    return 'Refunded';
  }
  return 'Unknown';
};

const exchangeDateFormat = 'YYYY-MM-DD';
const dateFormat = 'YYYYMMDD';
const dateTimeFormat = `${dateFormat} HH:mm:ss`;
const participationDateFormat = 'YYYY-MM-DD HH:mm:ss';

export const KLOOK: IFileReportHandler<string> = {
  type: 'CSV',
  partnerKey: 'klook',
  parser: {
    name: 'Klook report',
    csvHeaders:
      'Order ID,Ticket ID,Booking Number,Action,Action Date,Action Time,Participation Date,Participation Time,Participants,Sales Amount,Commission Rate,Commission Amount,AID,ADID,Website Name(English),Partner Params,Activity ID,Activity Name,Destination,Package Name,Activity Category,Platform,Promo Code Type,Supply Category 01,Supply Category 02,Supply Category 03',
    matches: (text, expectedHeaders) => text.indexOf(expectedHeaders) === 0,
    processRows: async (
      rows,
      { space, partnerKey, reportId, integrationId }
    ) => {
      const tz = 'UTC';

      // Because file contains a \n at the end
      const dataRows = rows.slice(1, rows.length);
      const [, , , , endReportDate] = dataRows[0];
      const finalIndex = dataRows.length - 1 > 0 ? dataRows.length - 1 : 0;
      const [, , , , startReportDate] = dataRows[finalIndex];

      const TODAY = moment.tz(tz).format(exchangeDateFormat);
      const YESTERDAY = moment
        .tz(tz)
        .subtract(1, 'day')
        .format(exchangeDateFormat);

      const startDateMoment = moment.tz(startReportDate, dateFormat, tz);
      const endDateMoment = moment.tz(endReportDate, dateFormat, tz);
      const dateDiff = startDateMoment.diff(endDateMoment, 'days');

      // Assure we get at least 5 days of data in case there are gaps
      if (dateDiff < 5) {
        startDateMoment.subtract(5, 'days');
      }

      const startDate = startDateMoment.format(exchangeDateFormat);
      const endDate = endDateMoment.format(exchangeDateFormat);
      const [, , , , , , , , , , , firstCommission] = dataRows[0];
      const publisherCurrency: CurrencyCode = firstCommission.substr(0, 3);

      const exchangeRates = await getExchangeRates(
        publisherCurrency,
        startDate,
        endDate
      );

      return compact(
        dataRows.map((row) => {
          if (!row.length) {
            return undefined;
          }

          let [
            orderId,
            saleId,
            // eslint-disable-next-line
            bookingNumber,
            action, // Payment | Refund
            orderDate,
            orderTime,
            participationDate,
            participationTime,
            // eslint-disable-next-line
            __quantity,
            // eslint-disable-next-line
            amountOriginalCurrency, // 3-digit currency code, a space, the value
            _commissionPercent,
            // eslint-disable-next-line
            shareAmountUsd,
            // eslint-disable-next-line
            __aid,
            // eslint-disable-next-line
            __adid,
            // eslint-disable-next-line
            websiteName,
            // eslint-disable-next-line
            partnerParams,
            partnerProductId,
            partnerProductName,
            // eslint-disable-next-line
            __destination,
            // eslint-disable-next-line
            __packageName,
            // eslint-disable-next-line
            __platform, // web | mobile | ios | android | ???
            // eslint-disable-next-line
            __promoCode
          ] = row;

          const trackingLabel = partnerParams;
          const trackingId = getTrackingId(trackingLabel);
          const status = toStatus(action);
          const saleDate = moment
            .tz(orderDate, dateFormat, tz)
            .format(exchangeDateFormat);

          const exchangeDate = saleDate === TODAY ? YESTERDAY : saleDate;

          const saleDateTime = moment.tz(
            `${orderDate} ${orderTime}`,
            dateTimeFormat,
            tz
          );
          const completionDate = moment.tz(
            `${participationDate} ${participationTime}`,
            participationDateFormat,
            tz
          );

          const multiplier = status === 'Refunded' ? -1 : 1;
          const originalCurrency: CurrencyCode = amountOriginalCurrency.substr(
            0,
            3
          );

          // Find the closest available date to gather exchange rates from
          const allDates = Object.keys(exchangeRates.rates).sort((a, b) => {
            return (
              moment.tz(a, exchangeDateFormat, tz).unix() -
              moment.tz(b, exchangeDateFormat, tz).unix()
            );
          });

          const distances = allDates.map((d) =>
            Math.abs(
              moment.tz(d, exchangeDateFormat, tz).diff(exchangeDate, 'days')
            )
          );
          const shortestDistance = min(distances);
          const smallestDistanceIndex = distances.find(
            (d) => d === shortestDistance
          )!;
          const dateToUse = allDates[smallestDistanceIndex];
          const dayRates = exchangeRates.rates[dateToUse];
          const rate = dayRates[originalCurrency];

          const originalAmountForeignCurrency = toCents(
            trim(
              amountOriginalCurrency.substr(3, amountOriginalCurrency.length)
            )
          );

          const originalPriceInUsd = originalAmountForeignCurrency / rate;
          const price = originalPriceInUsd * multiplier;

          const website = websiteName.match(/\((.+)\)/);
          const sites = getActiveDomainUrls(space, false);

          let origin = website
            ? toDomain(
                website[1].indexOf('http') === 0
                  ? website[1].replace('%28', '').replace('%29', '')
                  : `https://${website[1]
                      .replace('%28', '')
                      .replace('%29', '')}`
              )
            : null;

          // In case origin matches existing space but without www, use that
          sites.forEach((site) => {
            if (
              origin &&
              withoutProtocolOrWww(site) === withoutProtocolOrWww(origin)
            ) {
              origin = site;
            }
          });

          const commission =
            toCents(shareAmountUsd.replace(`${publisherCurrency} `, '')) *
            multiplier;

          const commissionPercent =
            parseFloat(_commissionPercent.replace('%', '')) / 100;

          if (status === 'Refunded') {
            saleId = `${saleId}-Refund`;
          }

          const sale: ISale = {
            saleId,
            orderId,
            trackingId,
            trackingLabel,
            reportId,
            integrationId,
            origin,
            saleDate: fromMoment(saleDateTime),
            completionDate: fromMoment(completionDate),
            status,
            partnerKey,
            partnerProductName,
            partnerProductId,
            amount: {
              currency: publisherCurrency,
              price,
              revenue: null,
              commission
            },
            payoutId: null,
            payoutDate: null,
            payoutStatus: null,
            lastModified: null,
            coupon: '',
            saleType: 'unknown',
            commissionPercent,
            advertiserId: partnerKey,
            advertiserName: getKnownPartnerForKeyUnsafe(partnerKey).name
          };

          addDevice(sale, __platform, {
            web: 'desktop',
            mobile: 'mobile',
            ios: 'mobile',
            android: 'mobile'
          });
          return sale;
        })
      );
    }
  }
};
