import { DocReference } from '../../../domainTypes/document';
import * as ENV from '../../../env.json';

const getConsoleUrl = () => {
  return `https://console.firebase.google.com/project/${ENV.firebase.projectId}`;
};

export const toFirestoreConsole = (collection: string, id?: string) => {
  const path = ['data', collection];
  if (id) {
    path.push(id);
  }

  return `${getConsoleUrl()}/database/firestore/${path.join('~2F')}`;
};

export const toFirestoreConsoleFromDoc = (doc: DocReference) => {
  const path = ['data', doc.collection, doc.id];
  return `${getConsoleUrl()}/database/firestore/${path.join('~2F')}`;
};
