import { truncate } from 'lodash';
import { Tooltip } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from '../../emotion';
import { useTheme } from '../../themes';
import * as tracking from '../../tracking';
import { IPartner } from '../../domainTypes/partners';
import { HelpCircle } from 'react-feather';

type ILogoChipProps = {
  backgroundColor?: string;
};

const LogoChip = styled('div')<ILogoChipProps>`
  width: 24px;
  height: 24px;
  text-align: center;
  display: flex;
  background-color: ${(p) => p.backgroundColor || 'white'};
  border-radius: 100%;
  padding: 4px;
  opacity: 0.5;
  transition: all 0.3s linear;

  &:hover {
    opacity: 1;
    transition: all 0.3s linear;
  }
`;

const PartnerLogoLinkWrapper: React.FC<{
  partner: IPartner;
  tooltipLabel: string;

  internal: boolean;
  internalLink: string;

  trackingAction: tracking.IEvent['action'];
  trackingCategory: string;
}> = ({
  partner,
  tooltipLabel,

  internal,
  internalLink,

  trackingAction,
  trackingCategory,

  children
}) => {
  const { name, portalUrl } = partner;
  const title = tooltipLabel || `Open ${name} affiliate portal`;

  if (internal) {
    return (
      <Tooltip title={title} aria-label={title} placement="top">
        <Link
          to={internalLink}
          onClick={(e) => {
            e.stopPropagation();
            tracking.sendEvent({
              category: trackingCategory,
              action: trackingAction,
              label: name
            });
          }}
        >
          {children}
        </Link>
      </Tooltip>
    );
  }

  return (
    <Tooltip title={title} aria-label={title} placement="top">
      <a
        href={portalUrl || ''}
        target="_blank"
        rel="noopener noreferrer"
        onClick={(e) => {
          e.stopPropagation();
          tracking.sendEvent({
            category: trackingCategory,
            action: trackingAction,
            label: name
          });
        }}
      >
        {children}
      </a>
    </Tooltip>
  );
};

const partnerChipStyle: React.CSSProperties = {
  opacity: 0.9,
  marginRight: 4,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 26,
  height: 26
};

export const UnknownLogoChip = ({
  style,
  chipStyle
}: {
  style?: any;
  label?: string;
  chipStyle?: boolean;
}) => {
  const theme = useTheme();
  const finalStyle = chipStyle ? { ...partnerChipStyle, ...style } : style;
  return (
    <LogoChip
      backgroundColor={theme.palette.grey.A200}
      style={{ lineHeight: '16px', ...finalStyle }}
    >
      <span
        style={{ display: 'inline-block', textAlign: 'center', width: '100%' }}
      >
        <HelpCircle size={16} />
      </span>
    </LogoChip>
  );
};

export const PartnerLogo = ({
  partner,
  style,
  chipStyle
}: {
  style?: any;
  partner: IPartner;
  chipStyle?: boolean;
}) => {
  const { name, logo } = partner;
  const finalStyle = chipStyle ? { ...partnerChipStyle, ...style } : style;
  return logo ? (
    <LogoChip style={finalStyle}>
      <img src={`/images/logos/${logo.imgSrc}`} width={logo.width} alt={name} />
    </LogoChip>
  ) : (
    <UnknownLogoChip label={name} style={finalStyle} />
  );
};

export const PartnerLogoLink = ({
  partner,
  tooltipLabel = '',

  internal = false,
  internalLink = '',

  trackingAction = 'Click',
  trackingCategory = tracking.toAppCategory()
}: {
  partner: IPartner;
  tooltipLabel?: string;

  internal?: boolean;
  internalLink?: string;

  trackingAction?: tracking.IEvent['action'];
  trackingCategory?: string;
}) => {
  return (
    <PartnerLogoLinkWrapper
      partner={partner}
      tooltipLabel={tooltipLabel}
      internal={internal}
      internalLink={internalLink}
      trackingAction={trackingAction}
      trackingCategory={trackingCategory}
    >
      <PartnerLogo partner={partner} />
    </PartnerLogoLinkWrapper>
  );
};

export const PartnerLogoWithTextContainer = styled('div')`
  display: flex;
  align-items: center;

  > div:first-of-type {
    margin-right: ${(p) => p.theme.spacing() / 2}px;
  }
`;

export const PartnerNameContainer = styled<'div', { noBold?: boolean }>('div')`
  font-weight: ${(p) => (p.noBold ? 'normal' : 700)};
`;

const PartnerAdditionalNameContainer = styled('span')`
  color: ${(p) => p.theme.palette.grey[600]};
  font-weight: normal;
  display: inline-block;
  margin-left: 6px;
`;

export const PartnerLogoWithName = ({
  partner,
  maxLength,
  noBold,
  additionalName
}: {
  partner: IPartner;
  maxLength?: number;
  noBold?: boolean;
  additionalName?: string | null;
}) => {
  const name = maxLength
    ? truncate(partner.name, { length: maxLength })
    : partner.name;

  return (
    <PartnerLogoWithTextContainer>
      <PartnerLogo partner={partner} />
      <PartnerNameContainer noBold={noBold}>
        {name}{' '}
        {additionalName ? (
          <PartnerAdditionalNameContainer>
            ({additionalName})
          </PartnerAdditionalNameContainer>
        ) : null}
      </PartnerNameContainer>
    </PartnerLogoWithTextContainer>
  );
};
