import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  Switch,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { ChevronDown, Info } from 'react-feather';
import { Doc } from '../../domainTypes/document';
import { Schedule } from '../../domainTypes/schedule';
import {
  IDomain,
  IDomainUpdateSettingsParams,
  ISpace
} from '../../domainTypes/space';
import { css, styled } from '../../emotion';
import {
  SelectableButton,
  SelectableChip
} from '../../features/Settings/components/SelectableButton';
import { useSnackbar } from '../../hooks/useSnackbar';
import { ARTICLES } from '../../services/beacon';
import { useCurrentUser } from '../../services/currentUser';
import { setFeature, useFeatureEnabled } from '../../services/features';
import { useScans } from '../../services/scan';
import { updateOrCreateSchedule } from '../../services/schedules/helper';
import { useProductScanSchedule } from '../../services/schedules/productScan';
import {
  updateAutoImportSpace,
  updateSiteSettings
} from '../../services/space';
import { AlertBox } from '../AlertBox';
import { Form } from '../Form';
import { FormField } from '../Form/FormField';
import { HelpIcon } from '../HelpIcon';
import { Loader } from '../Loader';
import { ScheduleFormSimple } from '../Schedule/SimpleForm';

const InfoWrapper = styled('span')`
  display: inline-block;
  position: relative;
  top: 2px;
  right: -6px;
`;

const Row = styled('div')`
  display: flex;
  align-items: center;
`;

type UpdateSettingsFn = (
  domain: IDomain,
  settings: Partial<IDomainUpdateSettingsParams>
) => Promise<void>;

const SelectableButtonWrapper = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
`;

const TrackingSettings = ({
  domain,
  onUpdateSettings
}: {
  domain: IDomain;
  onUpdateSettings: UpdateSettingsFn;
}) => {
  const [scans] = useScans(1);
  const currentUser = useCurrentUser();
  const hasScanlessTracking = useFeatureEnabled('SCANLESS_TRACKING');
  const allowChangeImportSettings = useMemo(() => {
    // Only allow people to freely switch between this
    // when setting up their first website
    return (
      (currentUser.space.domains.length <= 1 && !scans) ||
      (scans && scans.length === 0)
    );
  }, [scans, currentUser.space.domains]);

  return (
    <FormControl>
      <AlertBox variant="primary">
        Pop open our help docs for more information on your tracking options.
      </AlertBox>
      <br />
      <div
        style={{
          opacity: allowChangeImportSettings ? 1 : 0.7,
          pointerEvents: allowChangeImportSettings ? 'initial' : 'none'
        }}
      >
        <Typography
          variant="body1"
          component="p"
          style={{ fontWeight: 'bold' }}
        >
          Link tracking method
          <Tooltip
            placement="top"
            title="Instant tracking will eventually be available for sites with cloaked links. Today, sites with cloaked links that do not use Universal SubIDs (see advanced settings) will need to use the Daily Link Import instead."
          >
            <InfoWrapper>
              <Info size={16} />
            </InfoWrapper>
          </Tooltip>
        </Typography>
        <Typography
          variant="body1"
          component="p"
          color="textSecondary"
          paragraph
        >
          How should we discover links to track? This setting applies to all
          your sites.
        </Typography>
        <SelectableButtonWrapper>
          <SelectableButton
            selected={!hasScanlessTracking}
            disabled={!allowChangeImportSettings}
            onClick={() => {
              setFeature(
                currentUser.space,
                'SCANLESS_TRACKING',
                false,
                currentUser.id
              );
            }}
            heading={
              <>
                Daily link import <SelectableChip>Default</SelectableChip>
              </>
            }
            subheading={
              <ul>
                <li>Required for sites with cloaked links on WordPress.</li>
                <li>
                  Crawls your sitemaps daily to import links for tracking.
                </li>
              </ul>
            }
          />
          <SelectableButton
            selected={hasScanlessTracking}
            disabled={!allowChangeImportSettings}
            heading={
              <>
                Instant tracking <SelectableChip>New</SelectableChip>
              </>
            }
            onClick={() => {
              setFeature(
                currentUser.space,
                'SCANLESS_TRACKING',
                true,
                currentUser.id
              );
            }}
            subheading={
              <ul>
                <li>Simplified setup for sites without cloaked links.</li>
                <li>
                  Also, custom cloakers using our universal SubIDs (see below).
                </li>
              </ul>
            }
          />
        </SelectableButtonWrapper>
      </div>
      <br />
      <br />
      <Accordion style={{ border: '1px solid #EEE', borderRadius: '8px' }}>
        <AccordionSummary expandIcon={<ChevronDown />} id="advanced-settings">
          <Typography variant="body1" component="p" color="textSecondary">
            Advanced tracking settings
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <Typography
              variant="body1"
              component="p"
              style={{ fontWeight: 'bold' }}
            >
              SubID update method
              <Tooltip
                placement="top"
                title="Replaced SubIDs will include a human-friendly page slug at the beginning. Appended SubIDs will respect any existing SubID value, and only add our dynamic SubID to the end, separated by an underscore."
              >
                <InfoWrapper>
                  <Info size={16} />
                </InfoWrapper>
              </Tooltip>
            </Typography>
            <Typography
              variant="body1"
              component="p"
              color="textSecondary"
              paragraph
            >
              Should Affilimate replace or append to any existing SubIDs?
            </Typography>
            <SelectableButtonWrapper>
              <SelectableButton
                selected={
                  !domain.updateMethod || domain.updateMethod === 'replace'
                }
                heading="Replace existing SubIDs"
                onClick={() => {
                  onUpdateSettings(domain, { updateMethod: 'replace' });
                }}
                subheading="Default setting. SubIDs placed in the format of post-slug_amcid-XXX."
              />
              <SelectableButton
                selected={domain.updateMethod === 'append'}
                heading="Append to existing SubIDs"
                onClick={() => {
                  onUpdateSettings(domain, { updateMethod: 'append' });
                }}
                subheading="Recommended if you have existing, meaningful SubIDs on your links."
              />
            </SelectableButtonWrapper>
            <br />
            <Typography
              variant="body1"
              component="p"
              style={{ fontWeight: 'bold' }}
            >
              Smart labels for cloaked links
              <Tooltip
                placement="top"
                title="Non-cloaked links and WordPress plugin users should use Network-specific SubIDs. Custom link cloaker users with their own backend integration can use Universal SubIDs to control how Affilimate's SubIDs are merged with your internal tracking setup."
              >
                <InfoWrapper>
                  <Info size={16} />
                </InfoWrapper>
              </Tooltip>
            </Typography>
            <Typography
              variant="body1"
              component="p"
              color="textSecondary"
              paragraph
            >
              How should Affilimate add SubIDs to your cloaked links?
            </Typography>
            <SelectableButtonWrapper>
              <SelectableButton
                selected={
                  !domain.subidStrategy || domain.subidStrategy === 'network'
                }
                onClick={() => {
                  onUpdateSettings(domain, { subidStrategy: 'network' });
                }}
                heading={
                  <>
                    Network-specific SubIDs{' '}
                    <SelectableChip>Default</SelectableChip>
                  </>
                }
                subheading="Automatically add SubIDs for each network based on our default parameters."
              />
              <SelectableButton
                selected={domain.subidStrategy === 'universal'}
                onClick={() => {
                  onUpdateSettings(domain, { subidStrategy: 'universal' });
                }}
                heading={
                  <>
                    Universal SubIDs{' '}
                    <SelectableChip>Custom setup</SelectableChip>
                  </>
                }
                subheading={`Always use the "amcid" query parameter for SubIDs on cloaked links regardless of network.`}
              />
            </SelectableButtonWrapper>
            <br />
            {false && (
              <div>
                <Typography
                  variant="body1"
                  component="p"
                  style={{ fontWeight: 'bold' }}
                >
                  Link cleaning
                  <Tooltip
                    placement="top"
                    title="Not ignoring dynamic parameters causes a new link to be generated in your account for each unique value. Provide dynamic parameters to ignore, separated by commas."
                  >
                    <InfoWrapper>
                      <Info size={16} />
                    </InfoWrapper>
                  </Tooltip>
                </Typography>
                <Typography
                  variant="body1"
                  component="p"
                  color="textSecondary"
                  paragraph
                >
                  Any dynamically generated parameters, including any of your
                  own dynamic SubIDs, <strong>must</strong> be ignored.
                </Typography>
                <TextField
                  name="dynamicParams"
                  variant="outlined"
                  fullWidth
                  placeholder="subid, campaign, xcust, afftrack, etc."
                />
                <br />
                <br />
              </div>
            )}
            <Typography
              variant="body1"
              component="p"
              style={{ fontWeight: 'bold' }}
            >
              Canonical URLs
              <Tooltip
                placement="top"
                title="Use this only if your canonical URLs have query parameters in them. Otherwise, this prevents you from using unique URLs and the same canonical for A/B testing."
              >
                <InfoWrapper>
                  <Info size={16} />
                </InfoWrapper>
              </Tooltip>
            </Typography>
            <Typography
              variant="body1"
              component="p"
              color="textSecondary"
              paragraph
            >
              Should Affilimate track canonical URLs instead of the browser URL?
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={domain.urlFormat === 'canonical'}
                  onChange={(e) => {
                    onUpdateSettings(domain, {
                      urlFormat: e.target.checked ? 'canonical' : 'url'
                    });
                  }}
                />
              }
              label="Yes, use canonical URLs instead"
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </FormControl>
  );
};

const SiteScheduleSettings = ({
  space,
  domain
}: {
  space: ISpace;
  domain: IDomain;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [productScanScheduleDoc] = useProductScanSchedule(space.id);
  const onChange = (schedule: Doc<Schedule>) =>
    updateOrCreateSchedule(schedule.id, schedule.data);

  const onUpdateAutoImport = (d: IDomain) => {
    const { autoImport } = d;
    return updateAutoImportSpace(space, d, autoImport).catch((err) => {
      if (err.message === 'Cannot autoImport an unverified domain') {
        enqueueSnackbar('Verify the domain before enabling auto-import', {
          variant: 'error'
        });
      } else {
        enqueueSnackbar(
          'Could not enable auto-import. Please try again or contact Support.',
          { variant: 'error' }
        );
      }
    });
  };

  return (
    <div>
      <Typography variant="body1" component="p" style={{ fontWeight: 'bold' }}>
        Scan schedule
        <Tooltip
          placement="top"
          title="This setting determines when and how often we'll scan your site for affiliate links and check for version of your pages."
        >
          <span
            style={{
              display: 'inline-block',
              position: 'relative',
              top: '2px',
              right: '-6px'
            }}
          >
            <Info size={16} />
          </span>
        </Tooltip>
      </Typography>
      <Typography variant="body1" component="p" color="textSecondary" paragraph>
        This setting applies to <strong>all domains</strong> in this workspace.
      </Typography>
      {!productScanScheduleDoc ? (
        <Loader size={24} height={98} />
      ) : (
        <ScheduleFormSimple
          label={<>Automatically scan your sites for affiliate links</>}
          doc={productScanScheduleDoc}
          onChange={onChange}
        />
      )}
      <Typography
        variant="body1"
        component="p"
        style={{ fontWeight: 'bold', marginTop: '48px' }}
      >
        Auto-import affiliate links
        <Tooltip
          placement="top"
          title={
            <>
              With this setting, you can allow us to automatically import the
              affiliate links our algorithm finds on your site. Otherwise,
              you'll need to review and improve each set of links before import.
            </>
          }
        >
          <span
            style={{
              display: 'inline-block',
              position: 'relative',
              top: '2px',
              right: '-3px'
            }}
          >
            <Info size={16} />
          </span>
        </Tooltip>
      </Typography>
      <Typography variant="body1" component="p" color="textSecondary" paragraph>
        Automatically import new links found during site scans.
      </Typography>
      <Form
        autoSave={true}
        onSubmit={onUpdateAutoImport}
        initialValues={domain}
      >
        {({ submitting }) => (
          <Row>
            <FormField name="autoImport" type="checkbox">
              {({ input }) => (
                <FormControlLabel
                  label={
                    <>
                      Enable auto-import for this website{' '}
                      <strong>(Recommended)</strong>
                    </>
                  }
                  classes={{ root: css(() => ({ marginLeft: 0 })) }}
                  control={
                    <Checkbox
                      color="primary"
                      checked={input.checked}
                      onChange={input.onChange}
                    />
                  }
                />
              )}
            </FormField>
            {submitting && <CircularProgress size={20} />}
          </Row>
        )}
      </Form>
    </div>
  );
};

const UrlSourceSettings = ({
  domain,
  onUpdateSettings
}: {
  domain: IDomain;
  onUpdateSettings: UpdateSettingsFn;
}) => {
  const [loading, setLoading] = useState(false);
  const [sitemaps, setSitemaps] = useState<string>(
    domain.sitemaps !== null ? domain.sitemaps.join('\r\n') : ''
  );
  const [isDefault, setIsDefault] = useState(domain.sitemaps === null);

  const onSaveSitemaps = async (defaultSettings?: boolean) => {
    setLoading(true);
    const sitemapArr = sitemaps.split(/\r?\n/);
    const sitemapVal = isDefault || defaultSettings ? null : sitemapArr;

    try {
      await onUpdateSettings(domain, { sitemaps: sitemapVal });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      return Promise.reject(err);
    }
  };

  return (
    <div>
      <Typography variant="body1" component="p" style={{ fontWeight: 'bold' }}>
        Sitemap URLs
      </Typography>
      <Typography variant="body1" component="p" color="textSecondary" paragraph>
        We'll use these sitemaps to efficiently crawl your site for affiliate
        links and track changes to your content.
      </Typography>
      <FormGroup row>
        <FormControlLabel
          control={
            <Switch
              color="primary"
              checked={isDefault}
              onChange={(_) => {
                if (isDefault) {
                  setIsDefault(false);
                } else {
                  setIsDefault(true);
                  onSaveSitemaps(true);
                }
              }}
            />
          }
          label={
            <>
              Use auto-detected sitemaps
              <Tooltip
                placement="top"
                title={
                  <>
                    Leave this switched on to let us auto-detect your sitemap
                    based on your robots.txt file and check sitemaps located at
                    /sitemap.xml.
                    <br />
                    <br />
                    Switch it off to provide one or more custom Sitemap URLs.
                  </>
                }
              >
                <span
                  style={{
                    display: 'inline-block',
                    position: 'relative',
                    top: '2px',
                    right: '-6px'
                  }}
                >
                  <Info size={16} />
                </span>
              </Tooltip>
            </>
          }
        />
      </FormGroup>
      {!isDefault && (
        <div>
          <Typography
            variant="body1"
            component="p"
            style={{ fontWeight: 'bold', marginTop: '24px' }}
          >
            Custom Sitemaps
          </Typography>
          <Typography
            variant="body1"
            component="p"
            color="textSecondary"
            paragraph
          >
            Provide the full URL (including protocol) to your sitemap URLs.
          </Typography>
          <FormGroup row>
            <TextField
              multiline
              rows={5}
              name="sitemap"
              variant="outlined"
              value={sitemaps}
              onChange={(e) => {
                setSitemaps(e.target.value);
              }}
              fullWidth
              placeholder="One sitemap URL per line, including protocol"
            />
          </FormGroup>
          <Button
            variant="contained"
            color="default"
            style={{ marginTop: '12px' }}
            onClick={() => onSaveSitemaps()}
            disabled={sitemaps.length === 0 || loading}
          >
            {loading ? 'Saving...' : 'Save custom sitemaps'}
          </Button>
          <AlertBox variant="background" style={{ marginTop: '24px' }}>
            <HelpIcon articleId="sitemap-compatibility">
              View docs on sitemap formatting
            </HelpIcon>{' '}
            for our compatibility guidelines.
          </AlertBox>
        </div>
      )}
    </div>
  );
};

const LinkSettings = ({
  domain,
  onUpdateSettings
}: {
  domain: IDomain;
  onUpdateSettings: UpdateSettingsFn;
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [redirectUrls, setCustomRedirectUrls] = useState(
    domain.redirectUrls !== null ? domain.redirectUrls.join('\r\n') : ''
  );

  const onSaveRedirectUrls = async () => {
    const arr = redirectUrls.split(/\r?\n/);
    const isUsingMainDomain = arr.some((url) => url === domain.url);

    if (isUsingMainDomain) {
      setError(
        'You cannot redirect to the main domain. Set a redirect format that includes either a custom subdomain or path prefix.'
      );
      return;
    } else {
      setError('');
    }

    setLoading(true);

    try {
      await onUpdateSettings(domain, { redirectUrls: arr });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(
        'An error occurred updating your link settings. Try again our contact support.'
      );
      return Promise.reject(err);
    }
  };

  return (
    <div>
      <AlertBox variant="primary">
        Skip Cloaking Settings if you do not cloak or redirect your affiliate
        links.
      </AlertBox>
      <br />
      <Typography variant="body1" component="p" style={{ fontWeight: 'bold' }}>
        Cloaked link format&nbsp;&nbsp;
        <HelpIcon articleId={ARTICLES.linkCloaker.compatibility} color="blue">
          See required settings
        </HelpIcon>
      </Typography>
      <Typography variant="body1" component="p" color="textSecondary" paragraph>
        We'll use this to detect cloaked links on your site. For example,{' '}
        <u>https://go.yoursite.com</u> or{' '}
        <u>https://yoursite.com/recommends/</u>.<br />
        <br />
        Provide one per line, starting with <strong>https://</strong>.
      </Typography>
      <FormGroup row>
        <TextField
          multiline
          rows={3}
          name="customer-cloaker"
          variant="outlined"
          value={redirectUrls}
          onChange={(e) => {
            setCustomRedirectUrls(e.target.value);
          }}
          fullWidth
          placeholder="One URL per line, including protocol (e.g. https://)"
        />
      </FormGroup>
      <Button
        variant="contained"
        color="default"
        style={{ marginTop: '12px' }}
        disabled={loading}
        onClick={onSaveRedirectUrls}
      >
        {loading ? 'Saving...' : 'Save changes'}
      </Button>
      {error && (
        <AlertBox variant="pending" style={{ marginTop: '24px' }}>
          {error}
        </AlertBox>
      )}
    </div>
  );
};

export const SiteSettings = ({ domain }: { domain: string }) => {
  const currentUser = useCurrentUser();
  const space = currentUser.space;
  const hasScanlessTracking = useFeatureEnabled('SCANLESS_TRACKING');
  const [selectedTab, setSelectedTab] = useState(hasScanlessTracking ? 0 : 1);
  const { enqueueSnackbar } = useSnackbar();

  const spaceDomain = useMemo(() => {
    return currentUser.space.domains.find((d) => d.url === domain);
  }, [currentUser, domain]);

  const onUpdateSiteSettings = async (
    d: IDomain,
    settings: Partial<IDomainUpdateSettingsParams>
  ) => {
    const updatedSettings = {
      autoImport: d.autoImport,
      sitemaps: d.sitemaps,
      redirectUrls: d.redirectUrls,
      urlFormat: d.urlFormat,
      updateMethod: d.updateMethod,
      subidStrategy: d.subidStrategy,
      subIdDefaultPrefix: d.subIdDefaultPrefix,
      subIdMatches: d.subIdMatches,
      ignoreParameters: d.ignoreParameters,
      ...settings
    };

    return updateSiteSettings(space, d, { ...updatedSettings })
      .then(() => {
        enqueueSnackbar('Updated your settings successfully', {
          variant: 'success'
        });
      })
      .catch(() => {
        enqueueSnackbar(
          'There was an error updating your settings. Please try again or contact Support.',
          { variant: 'error' }
        );
      });
  };

  const onTabChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  if (!spaceDomain) {
    return <div>Could not find space domain</div>;
  }

  return (
    <div>
      <div>
        <Tabs
          value={selectedTab}
          onChange={onTabChange}
          style={{ marginBottom: '24px' }}
          variant="fullWidth"
        >
          <Tab label="Cloaking" />
          <Tab label="Tracking" />
          <Tab
            style={{ display: hasScanlessTracking ? 'none' : 'block' }}
            label="Schedule"
          />
          <Tab
            style={{ display: hasScanlessTracking ? 'none' : 'block' }}
            label="URL sources"
          />
        </Tabs>
        {selectedTab === 0 && (
          <LinkSettings
            domain={spaceDomain}
            onUpdateSettings={onUpdateSiteSettings}
          />
        )}
        {selectedTab === 1 && (
          <TrackingSettings
            domain={spaceDomain}
            onUpdateSettings={onUpdateSiteSettings}
          />
        )}
        {selectedTab === 2 && (
          <SiteScheduleSettings domain={spaceDomain} space={space} />
        )}
        {selectedTab === 3 && (
          <UrlSourceSettings
            domain={spaceDomain}
            onUpdateSettings={onUpdateSiteSettings}
          />
        )}
      </div>
    </div>
  );
};
