import moment from 'moment-timezone';
import {
  getTrackingId,
  SaleStatus
} from '../../../../../domainTypes/performance';
import { getKnownPartnerForKeyUnsafe } from '../../../../../services/partner';
import { fromMoment } from '../../../../../services/time';
import { toCents } from '../helpers';
import { IFileReportHandler } from '../types';

const toStatus = (
  wasCancelled: boolean,
  completionDate: moment.Moment | null,
  now: moment.Moment
): SaleStatus => {
  if (wasCancelled || !completionDate) {
    return 'Cancelled';
  }
  return completionDate.isSameOrBefore(now) ? 'Final' : 'Pending';
};

function parseDate(dateString: string, timezone: string) {
  // Define an array of formats you expect the dateString to be in
  const formats = [
    'M/D/YYYY', // Format 1: month, day, year
    'D/M/YYYY', // Format 2: day, month, year
    'YYYY-MM-DD' // Format 3: year, month, day
  ];

  // Attempt to parse the dateString using the formats array
  // The 'true' argument enforces strict parsing, meaning the format must match exactly
  const parsedDate = moment.tz(dateString, formats, true, timezone);

  // Check if the date was successfully parsed
  if (parsedDate.isValid()) {
    return parsedDate;
  } else {
    throw new Error('Invalid date format');
  }
}

export const GET_YOUR_GUIDE: IFileReportHandler<string> = {
  type: 'CSV',
  partnerKey: 'gyg',
  parser: {
    name: 'Booking Overview',
    csvHeaders:
      ',Status,Activity,City,Booking date,Travel date,Campaign,Traveler origin,View activity,Booking Reference,Participants,Potential income',
    matches: (text, expectedHeaders) => text.indexOf(expectedHeaders) === 0,
    processRows: async (
      rows,
      { space, now, partnerKey, reportId, integrationId }
    ) => {
      // Slice the last row because this is an empty totals row
      return rows.slice(1, rows.length - 1).map((row) => {
        const tz = space.config.tz || 'UTC';

        const [
          // eslint-disable-next-line
          _rowCount,
          _status,
          partnerProductName,
          // eslint-disable-next-line
          _city,
          _saleDate,
          _completionDate,
          trackingLabel,
          // eslint-disable-next-line
          __location,
          // eslint-disable-next-line
          __viewActivity,
          saleId,
          quantity,
          _commission,
          _currency // This is an extra, optional row we let people add
          // that doesn't actually appear in the original CSV
        ] = row;

        const wasCancelled = _status === 'Canceled';
        const saleDate = parseDate(_saleDate, tz);
        const potentialCompletionDate = parseDate(_completionDate, tz);
        const completionDate = !wasCancelled ? potentialCompletionDate : null;
        const commission = toCents(_commission);
        const currency = _currency || 'EUR';

        return {
          saleId,
          trackingId: getTrackingId(trackingLabel),
          trackingLabel,
          reportId,
          integrationId,
          saleDate: fromMoment(saleDate),
          orderId: saleId,
          origin: null,
          completionDate: completionDate ? fromMoment(completionDate) : null,
          status: toStatus(wasCancelled, completionDate, now),
          partnerKey,
          partnerProductName,
          partnerProductId: null,
          payoutId: null,
          payoutDate: null,
          payoutStatus: null,
          lastModified: null,
          coupon: '',
          saleType: 'cpa',
          quantity: parseInt(quantity),
          amount: {
            currency,
            price: null,
            revenue: null,
            commission
          },
          commissionPercent: null,
          advertiserId: partnerKey,
          advertiserName: getKnownPartnerForKeyUnsafe(partnerKey).name
        };
      });
    }
  }
};
