import { Button, Typography } from '@material-ui/core';
import moment from 'moment-timezone';
import React, { useMemo } from 'react';
import { DownloadCloud } from 'react-feather';
import { ButtonWithPromise } from '../../../../../components/ButtonWithPromise';
import { Doc } from '../../../../../domainTypes/document';
import { DownloadExportNotification } from '../../../../../domainTypes/notifications';
import { downloadFromCloudStorageWithPath } from '../../../../../services/storage';
import { formatDate, toMoment } from '../../../../../services/time';
import { AlertStatus, IAlertStatus } from '../../AlertStatus';
import { Container } from '../../Item/Container';
import { Body, Frame } from '../../Item/Frame';
import { Heading } from '../../Item/Heading';
import * as Icon from '../../Item/Icon';
import { ExportReportType } from '../../../../../domainTypes/export';

const NOW = moment();

export const Download_Export = ({
  n
}: {
  n: Doc<DownloadExportNotification>;
}) => {
  const isDownloadExpired = useMemo(() => {
    return NOW.diff(toMoment(n.data.createdAt), 'days') > 7;
  }, [n.data.createdAt]);

  return isDownloadExpired ? (
    <ExpiredNotification n={n} />
  ) : (
    <ReadyNotification n={n} />
  );
};

const reportName = (reportType: ExportReportType): string => {
  switch (reportType) {
    case 'campaigns':
      return 'Campaign';
    case 'custom_dimension_details':
      return 'Custom dimension details';
    case 'custom_dimensions':
      return 'Custom dimensions';
    case 'content':
      return 'Content';
    case 'transactions':
      return 'Transaction';
    case 'tags':
      return 'Tags';
    case 'utm':
      return 'UTM parameter';
    case 'referrers':
      return 'Referrers';
    case 'links':
      return 'Links';
    case 'advertisers':
      return 'Advertiser';
    case 'platforms':
      return 'Platforms';
    case 'channels':
      return 'Channels';
  }
};

const ExpiredNotification: React.FC<{
  n: Doc<DownloadExportNotification>;
}> = ({ n }) => {
  return (
    <NotificationBody n={n}>
      <Heading>{reportName(n.data.data.reportType)} export expired</Heading>
      <Typography color="textSecondary" variant="body2" component="p" paragraph>
        This export file has expired. Please request a new export if you need to
        download it again.
      </Typography>
      <Typography color="textSecondary" variant="body2" component="p" paragraph>
        <strong>Export dates:</strong> {getExportDates(n)} (exclusive)
      </Typography>
      <Button variant="contained" disabled={true} color="primary">
        File expired
      </Button>
    </NotificationBody>
  );
};

const ReadyNotification: React.FC<{
  n: Doc<DownloadExportNotification>;
}> = ({ n }) => {
  return (
    <NotificationBody n={n}>
      <Heading>{reportName(n.data.data.reportType)} export is ready</Heading>
      <AlertStatus status={IAlertStatus.SUCCESS} />
      <Typography color="textSecondary" variant="body2" component="p" paragraph>
        Click the button below to download your export. The file will be
        available for 7 days.
      </Typography>
      <Typography color="textSecondary" variant="body2" component="p" paragraph>
        <strong>Export dates:</strong> {getExportDates(n)} (exclusive)
      </Typography>
      <ButtonWithPromise
        onClick={() => {
          return downloadFromCloudStorageWithPath(n.data.data.downloadPath);
        }}
        variant="contained"
        pending="Fetching export..."
        color="primary"
      >
        Download export
      </ButtonWithPromise>
    </NotificationBody>
  );
};

const NotificationBody: React.FC<{ n: Doc<DownloadExportNotification> }> = ({
  n,
  children
}) => {
  return (
    <Frame n={n}>
      <Container>
        <Icon.Container>
          <DownloadCloud size={Icon.SIZE} />
        </Icon.Container>
        <Body>{children}</Body>
      </Container>
    </Frame>
  );
};

const getExportDates = (n: Doc<DownloadExportNotification>): string => {
  const start = formatDate(moment(n.data.data.start), 'L');
  const end = formatDate(moment(n.data.data.end), 'L');
  return `${start} - ${end}`;
};
