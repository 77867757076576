import { Paper, Tooltip } from '@material-ui/core';
import { truncate } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import {
  ItemSorters,
  RowsRenderer,
  ROW_HEIGHTS,
  useColumnsQueryParam,
  useSortQueryParam
} from '../../../../../../../components/GroupableList';
import { Loader } from '../../../../../../../components/Loader';
import { IColumn } from '../../../../../../../components/Table/Column';
import { CountCell } from '../../../../../../../components/Table/CountCell';
import { CurrencyCode } from '../../../../../../../domainTypes/currency';
import { IEarningMinimal } from '../../../../../../../domainTypes/performance';
import { styled } from '../../../../../../../emotion';
import {
  DEFAULT_OFFSET,
  DEFAULT_TOOLBAR_HEIGHT
} from '../../../../../../../layout/PageToolbar';
import { useRoutes } from '../../../../../../../routes';
import { getAvgComm } from '../../../../../../../services/analytics';
import { getStableRandomColor } from '../../../../../../../services/color';
import {
  AdvertiserProductGrouper,
  EarningsPerProductSoldForAdvertisersRow,
  productGrouperToEnglish,
  productNameFieldToEnglish
} from '../../../../../services/advertisers';

type ColumnName =
  | 'name'
  | 'earnings'
  | 'saleValue'
  | 'orderCount'
  | 'quantity'
  | 'avgCommRate'
  | 'avgComm'
  | 'avgPrice';

type Column = IColumn<
  EarningsPerProductSoldForAdvertisersRow,
  ColumnName,
  { groupBy: AdvertiserProductGrouper }
>;

const DEFAULT_COLUMNS = [
  'name',
  'earnings',
  'saleValue',
  'orderCount',
  'quantity',
  'avgCommRate',
  'avgComm',
  'avgPrice'
];

const SORTERS: ItemSorters<EarningsPerProductSoldForAdvertisersRow> = {
  earnings: {
    key: 'earnings',
    items: { sort: (p) => p.curr.total, dir: 'desc' }
  },
  saleValue: {
    key: 'saleValue',
    items: { sort: (p) => p.curr.saleValue.total, dir: 'desc' }
  },
  orderCount: {
    key: 'orderCount',
    items: { sort: (p) => p.curr.orderCount.total, dir: 'desc' }
  },

  quantity: {
    key: 'quantity',
    items: { sort: (p) => p.curr.quantity.total, dir: 'desc' }
  }
};

const Color = styled('div')`
  width: 16px;
  height: 16px;
  border-radius: 99px;
`;

const ProductWithColorWrapper = styled('div')`
  display: grid;
  grid-template-columns: 16px 1fr;
  gap: ${(p) => p.theme.spacing(0.8)}px;
  align-items: center;
`;

const COLUMNS: Column[] = [
  {
    key: 'name',
    head: (o) => (o?.groupBy ? productGrouperToEnglish(o.groupBy) : 'Name'),
    cell: (p, o) => {
      const nameLabel = productNameFieldToEnglish(p.name, o.groupBy);

      return (
        <Tooltip
          title={p.name.length > nameLabel.length ? p.name : nameLabel}
          placement="top"
        >
          <ProductWithColorWrapper>
            <Color
              style={{
                backgroundColor: getStableRandomColor(p.name)
              }}
            />
            <span>{truncate(nameLabel, { length: 100 })}</span>
          </ProductWithColorWrapper>
        </Tooltip>
      );
    },
    align: 'left',
    sortable: false,
    width: 300,
    flexGrow: 2
  },
  {
    key: 'orderCount',
    head: () => 'Orders',
    headInfo: (o) =>
      `Unique orders of/by/including this ${
        o?.groupBy
          ? productGrouperToEnglish(o.groupBy).toLowerCase()
          : 'product'
      }, if the advertiser reports it. Not all platforms and advertisers report Order IDs.`,
    cell: (p) => {
      return (
        <CountCell
          before={p.prev.orderCount.total}
          after={p.curr.orderCount.total}
          compare={true}
          dashWhenAllZero
        />
      );
    },
    align: 'right',
    sortable: true,
    width: 100,
    flexGrow: 2
  },
  {
    key: 'quantity',
    head: () => 'Quantity',
    headInfo: (o) =>
      `Quantity sold of/by this ${
        o?.groupBy
          ? productGrouperToEnglish(o.groupBy).toLowerCase()
          : 'product'
      }, if the advertiser reports it. Not all platforms and advertisers distinguish between order and quantity.`,
    cell: (p) => {
      return (
        <CountCell
          before={p.prev.quantity.total}
          after={p.curr.quantity.total}
          compare={true}
          dashWhenAllZero
        />
      );
    },
    align: 'right',
    sortable: true,
    width: 100,
    flexGrow: 2
  },
  {
    key: 'avgPrice',
    head: () => 'Avg price',
    headInfo: (o) =>
      `Average price paid for/by/in this ${
        o?.groupBy
          ? productGrouperToEnglish(o.groupBy).toLowerCase()
          : 'product'
      }, if the advertiser reports it. Not all platforms and advertisers report sales volume needed to calculate this metric.`,
    cell: (p) => {
      const before =
        p.prev.saleValue.total > 0
          ? p.prev.saleValue.total / p.prev.quantity.total
          : 0;
      const after =
        p.curr.saleValue.total > 0
          ? p.curr.saleValue.total / p.curr.quantity.total
          : 0;

      return (
        <CountCell
          before={before}
          after={after}
          compare={true}
          currency={p.curr.currency}
          dashWhenAllZero
        />
      );
    },
    align: 'right',
    sortable: false,
    width: 100,
    flexGrow: 2
  },
  {
    key: 'avgComm',
    head: () => 'Avg comm',
    headInfo: (o) =>
      `Average commission earned for/by/in this ${
        o?.groupBy
          ? productGrouperToEnglish(o.groupBy).toLowerCase()
          : 'product'
      }, based on quantity sold.`,
    cell: (p) => {
      const prevAvgComm = getAvgComm(p.prev.quantity.total, p.prev.total);
      const currAvgComm = getAvgComm(p.curr.quantity.total, p.curr.total);

      return (
        <CountCell
          before={prevAvgComm}
          after={currAvgComm}
          compare={true}
          currency={p.curr.currency}
          dashWhenAllZero
        />
      );
    },
    align: 'right',
    sortable: false,
    width: 100,
    flexGrow: 2
  },
  {
    key: 'avgCommRate',
    head: () => 'Avg rate',
    headInfo: (o) =>
      `Average, effective commission rate for/by/in this ${
        o?.groupBy
          ? productGrouperToEnglish(o.groupBy).toLowerCase()
          : 'product'
      }, based on transactions that report their sale volume. Not all platforms and adverters report sales volumes.`,
    cell: (p) => {
      return (
        <CountCell
          before={p.prev.avgCommissionPercent}
          after={p.curr.avgCommissionPercent}
          compare={true}
          format="percent"
          dashWhenAllZero
        />
      );
    },
    align: 'right',
    sortable: false,
    width: 100,
    flexGrow: 2
  },
  {
    key: 'earnings',
    head: () => 'Earnings',
    headInfo: (o) =>
      `Your earnings for/by/in this ${
        o?.groupBy
          ? productGrouperToEnglish(o.groupBy).toLowerCase()
          : 'product'
      }. May include $0 commissions which will increase in value upon locking, depending on the advertiser settings.`,
    cell: (p) => {
      return (
        <CountCell
          before={p.prev.total}
          after={p.curr.total}
          currency={p.curr.currency}
          compare={true}
          dashWhenAllZero
        />
      );
    },
    align: 'right',
    sortable: true,
    width: 120,
    flexGrow: 2
  },
  {
    key: 'saleValue',
    head: () => 'Sales volume',
    headInfo: (o) =>
      `Also called GMV. Your revenue driven for the advertiser for/by/in this ${
        o?.groupBy
          ? productGrouperToEnglish(o.groupBy).toLowerCase()
          : 'product'
      }, if the advertiser reports it. Not all advertisers and platforms report sales volumes.`,
    cell: (p) => {
      return (
        <CountCell
          before={p.prev.saleValue.total}
          after={p.curr.saleValue.total}
          currency={p.curr.currency}
          compare={true}
          dashWhenAllZero
        />
      );
    },
    align: 'right',
    sortable: true,
    width: 100,
    flexGrow: 2
  }
];

const ROW_TO_KEY = (p: EarningsPerProductSoldForAdvertisersRow) => `${p.name}`;

export const AdvertiserDetailsTopProductsSoldTable = ({
  ds,
  loading,
  error,
  groupBy,
  orderBy,
  partnerKeys,
  currency
}: {
  ds: void | EarningsPerProductSoldForAdvertisersRow[];
  loading: boolean;
  error: any;
  groupBy: AdvertiserProductGrouper;
  orderBy: keyof IEarningMinimal;
  partnerKeys: string[];
  currency: CurrencyCode;
}) => {
  const [columnNames] = useColumnsQueryParam('columns', DEFAULT_COLUMNS);
  const columns = useMemo(() => {
    if (partnerKeys.includes('amazon')) {
      return COLUMNS.filter((c) => {
        return !c.key.includes('orderCount') && !c.key.includes('aov');
      });
    }
    return columnNames
      ? COLUMNS.filter((c) => columnNames.has(c.key))
      : COLUMNS;
  }, [columnNames, partnerKeys]);
  const [[sorter, direction], setSort] = useSortQueryParam('sort', SORTERS);
  const { ROUTES } = useRoutes();

  useEffect(() => {
    if (orderBy === 'pt') {
      setSort([SORTERS['saleValue'], 'desc']);
    }
    if (orderBy === 'ct') {
      setSort([SORTERS['earnings'], 'desc']);
    }
    if (orderBy === 'oct') {
      setSort([SORTERS['orderCount'], 'desc']);
    }
    if (orderBy === 'qt') {
      setSort([SORTERS['quantity'], 'desc']);
    }
  }, [orderBy]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return (
      <Paper>
        <Loader height={300} />
      </Paper>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!ds) {
    return null;
  }

  return (
    <Paper>
      <RowsRenderer
        columns={columns}
        sorter={sorter || SORTERS.earnings}
        sortDirection={direction}
        rows={ds}
        variant="contained"
        renderHead={true}
        otherProps={{ groupBy }}
        headProps={{
          sticky: true,
          offset: DEFAULT_OFFSET + DEFAULT_TOOLBAR_HEIGHT
        }}
        rowToHref={(d) => ROUTES.performanceNew.transactions.url({ q: d.name })}
        rowToKey={ROW_TO_KEY}
        rowHeight={ROW_HEIGHTS.dense}
      />
    </Paper>
  );
};
