import { styled } from '../../../../emotion';
import { Copy as IconCopy } from 'react-feather';
import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import { sortBy } from 'lodash';
import React, { useState } from 'react';
import { AdditionalActionsMenu } from '../../../../components/AdditionalActionsMenu';
import { AlertBox } from '../../../../components/AlertBox';
import { ButtonWithPromise } from '../../../../components/ButtonWithPromise';
import { CopyButton } from '../../../../components/CopyButton';
import { Date } from '../../../../components/Dates';
import { Form } from '../../../../components/Form';
import { FormField } from '../../../../components/Form/FormField';
import { FormSubmitButton } from '../../../../components/Form/FormSubmitButton';
import { Loader } from '../../../../components/Loader';
import { PublicApiToken } from '../../../../domainTypes/api';
import { generateToDocFn } from '../../../../domainTypes/document';
import { useDialogState } from '../../../../hooks/useDialogState';
import { useErrorLogger } from '../../../../hooks/useErrorLogger';
import { Centered } from '../../../../layout/Centered';
import {
  removeDoc,
  store,
  useMappedLoadingValue
} from '../../../../services/db';
import { callFirebaseFunction } from '../../../../services/firebaseFunctions';
import {
  CollectionListener,
  createCollectionListenerStore,
  useCollectionListener
} from '../../../../services/firecache/collectionListener';
import { FS } from '../../../../versions';
import { useHasCurrentUserRequiredScopes } from '../../../../services/currentUser';

const toPublicApiTokenDoc = generateToDocFn<PublicApiToken>();
const publicApiTokenStore = createCollectionListenerStore(
  (spaceId) =>
    new CollectionListener(
      store().collection(FS.publicApiToken).where('spaceId', '==', spaceId),
      toPublicApiTokenDoc
    )
);

const usePublicApiTokensForSpace = (spaceId: string) => {
  return useMappedLoadingValue(
    useCollectionListener(publicApiTokenStore(spaceId)),
    (ds) => sortBy(ds, (d) => d.data.createdAt.toMillis())
  );
};

const MIN_HEIGHT = 150;
export const PublicApiTokensTable = ({ spaceId }: { spaceId: string }) => {
  const [docs, loading, error] = usePublicApiTokensForSpace(spaceId);
  useErrorLogger(error);
  const [canDeleteApiTokens] = useHasCurrentUserRequiredScopes([
    'api_tokens.delete'
  ]);

  if (loading) {
    return <Loader height={MIN_HEIGHT} />;
  }
  if (error) {
    // Should never end up here
    return <Centered height={MIN_HEIGHT}>Something went wrong.</Centered>;
  }
  if (!docs) {
    return null;
  }
  if (!docs.length) {
    return <Centered height={MIN_HEIGHT}>No tokens created yet.</Centered>;
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>API Token Name</TableCell>
          <TableCell>Created At</TableCell>
          {canDeleteApiTokens && <TableCell align="right" />}
        </TableRow>
      </TableHead>
      <TableBody>
        {docs.map((d) => (
          <TableRow key={d.id}>
            <TableCell>{d.data.name || d.data.publicKey}</TableCell>
            <TableCell>
              <Date d={d.data.createdAt.toMillis()} />
            </TableCell>

            {canDeleteApiTokens && (
              <TableCell align="right">
                <AdditionalActionsMenu
                  label="Token actions"
                  options={[
                    {
                      key: 'revoke',
                      label: 'Revoke token',
                      dialogMaxWidth: 'sm',
                      dialog: ({ onClose }) => (
                        <>
                          <DialogTitle>Revoke token?</DialogTitle>
                          <DialogContent>
                            <Typography variant="body1" component="p">
                              Once you revoke this token, any future API
                              requests that attempt to use this token will fail.
                            </Typography>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={onClose}>Cancel</Button>

                            <ButtonWithPromise
                              variant="contained"
                              color="secondary"
                              onClick={() => {
                                console.log('to delete', d);
                                return removeDoc(d).then(
                                  () => onClose(),
                                  (err) => console.log('err', err)
                                );
                              }}
                              pending="Revoking token..."
                            >
                              Revoke token
                            </ButtonWithPromise>
                          </DialogActions>
                        </>
                      )
                    }
                  ]}
                />
              </TableCell>
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const JwtContainer = styled('div')`
  word-break: break-all;
  font-family: monospace;
  margin-bottom: ${(p) => p.theme.spacing(1)}px;
  background-color: #eee;
  padding: ${(p) => p.theme.spacing(1)}px;
  border-radius: ${(p) => p.theme.shape.borderRadius}px;
  margin-bottom: ${(p) => p.theme.spacing(2)}px;
`;

type CreatePublicApiTokenState =
  | {
      step: 'CREATE';
    }
  | {
      step: 'READ';
      jwt: string;
    };
const INITIAL_STATE: CreatePublicApiTokenState = {
  step: 'CREATE'
};
export const CreatePublicApiTokenButton = ({
  spaceId,
  ...buttonProps
}: { spaceId: string } & Omit<ButtonProps, 'onClick'>) => {
  const { dialogOpen, openDialog, closeDialog } = useDialogState();
  const [state, setState] = useState<CreatePublicApiTokenState>(INITIAL_STATE);
  const close = () => {
    setState(INITIAL_STATE);
    closeDialog();
  };
  return (
    <>
      <Button {...buttonProps} onClick={openDialog} />

      <Dialog
        open={dialogOpen}
        scroll="body"
        onClose={close}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Create Secret API Token</DialogTitle>
        {state.step === 'CREATE' && (
          <Form
            initialValues={{ name: '' }}
            onSubmit={async (vs) => {
              const { jwt } = await callFirebaseFunction<{ jwt: string }>(
                'space-createPublicApiToken',
                {
                  spaceId,
                  name: vs.name
                }
              );
              setState({
                step: 'READ',
                jwt
              });
            }}
          >
            {({ submitting }) => (
              <>
                <DialogContent>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    component="p"
                    paragraph
                  >
                    Give the API token a name that will help you remember how
                    and where you are using it.
                  </Typography>
                  <FormField name="name" type="input">
                    {({ input }) => (
                      <TextField
                        label="API Token Name"
                        variant="outlined"
                        fullWidth
                        autoFocus
                        {...input}
                      />
                    )}
                  </FormField>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => close()}>Cancel</Button>
                  <FormSubmitButton
                    variant="contained"
                    color="primary"
                    size="large"
                    submitComponent="Creating..."
                    submitting={submitting}
                    disabled={false}
                  >
                    Create token
                  </FormSubmitButton>
                </DialogActions>
              </>
            )}
          </Form>
        )}
        {state.step === 'READ' && (
          <>
            <DialogContent>
              <AlertBox variant="success">
                <Typography variant="body1" component="p">
                  Your API token has been successfully generated!
                </Typography>
              </AlertBox>
              <Typography
                variant="body1"
                color="textSecondary"
                component="p"
                paragraph
                style={{ marginTop: '24px' }}
              >
                This token will only be shown once. Store it somewhere secure,
                and do not include it in any publicly available code.
              </Typography>
              <Typography
                variant="body1"
                component="p"
                paragraph
                style={{ marginTop: '24px' }}
              >
                <strong>Your secret token:</strong>
              </Typography>
              <JwtContainer>{state.jwt}</JwtContainer>
              <CopyButton
                text={state.jwt}
                variant="contained"
                size="large"
                color="primary"
                label={
                  <>
                    <IconCopy size={16} /> &nbsp; Copy token
                  </>
                }
                notificationMessage="Token copied!"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => close()}>Done</Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};
