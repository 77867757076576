import {
  DEFAULT_OFFSET,
  PageToolbar,
  PageToolbarSection
} from '../../../../layout/PageToolbar';
import { FlexContainer } from '../../../../layout/Flex';
import Typography from '@material-ui/core/Typography';
import { css } from '../../../../emotion';
import { Link } from 'react-router-dom';
import { Calendar, Edit } from 'react-feather';
import { CampaignStatusIcon } from '../CampaignStatusIcon';
import { campaignStatusTitle, ReadyCampaign } from '../../service';
import React from 'react';
import { useRoutes } from '../../../../routes';
import { Moment } from 'moment-timezone';
import { Helmet } from 'react-helmet';

const Timeframe: React.FC<{ start: Moment; end: Moment }> = ({
  start,
  end
}) => {
  return (
    <div
      className={css((t) => ({
        color: t.palette.grey[800],
        fontSize: 14,
        fontWeight: 500,
        backgroundColor: 'white',
        padding: '6px 10px',
        borderRadius: 4
      }))}
    >
      <Calendar
        size={16}
        className={css((t) => ({
          color: t.palette.grey[600],
          position: 'relative',
          top: 2,
          marginRight: t.spacing(1)
        }))}
      />
      {start.format('MMM D')} - {end.format('MMM D, YYYY')}
    </div>
  );
};

export const CampaignReportToolbar: React.FC<{ campaign: ReadyCampaign }> = ({
  campaign
}) => {
  const { ROUTES } = useRoutes();
  return (
    <>
      <Helmet>
        <title>{campaign.name} | Affilimate</title>
      </Helmet>
      <PageToolbar sticky offset={DEFAULT_OFFSET}>
        <PageToolbarSection flex={2}>
          <div>
            <Typography
              variant="h5"
              style={{
                fontWeight: 'bold',
                position: 'relative',
                top: -3,
                whiteSpace: 'nowrap',
                display: 'flex',
                alignItems: 'center',
                gap: 16
              }}
              component="span"
            >
              {campaign.name}
            </Typography>
            <FlexContainer alignItems="center" spacing={1}>
              {campaign.advertisers.map((advertiser) => advertiser.name)}
              <Link to={ROUTES.campaigns.details.url(campaign.id, 'report')}>
                <FlexContainer
                  spacing={0.5}
                  alignItems="center"
                  className={css((t) => ({
                    color: t.palette.grey[600]
                  }))}
                >
                  <Edit
                    size={16}
                    className={css(() => ({
                      position: 'relative',
                      top: -1
                    }))}
                  />
                  <Typography
                    variant="caption"
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    Edit campaign
                  </Typography>
                </FlexContainer>
              </Link>
            </FlexContainer>
          </div>
        </PageToolbarSection>
        <PageToolbarSection flex={1} justifyContent="flex-end">
          <FlexContainer spacing={1.5}>
            <FlexContainer alignItems="center">
              <CampaignStatusIcon status={campaign.status} />
              <Typography variant="body1">
                {campaignStatusTitle(campaign.status)}
              </Typography>
            </FlexContainer>
            <Timeframe
              start={campaign.timeframe.start}
              end={campaign.timeframe.end}
            />
          </FlexContainer>
        </PageToolbarSection>
      </PageToolbar>
    </>
  );
};
