import Cookies from 'js-cookie';
import { mapValues } from 'lodash';
import { AffiliateCookie } from '../../domainTypes/affiliate/cookie';
import { CurrencyCode } from '../../domainTypes/currency';
import { Doc, generateToDocFn } from '../../domainTypes/document';
import { IPermissions } from '../../domainTypes/permissions';
import {
  IDomain,
  IDomainCreateParams,
  IDomainUpdateAutoImportParams,
  IDomainUpdateParams,
  IDomainUpdateSettingsParams,
  ISpace
} from '../../domainTypes/space';
import { CF, FS } from '../../versions';
import { store } from '../db';
import { callFirebaseFunction } from '../firebaseFunctions';

export const createSpace = (
  spaceId: string,
  tz: string,
  affiliation?: AffiliateCookie | null
) => {
  const fpCookie = Cookies.get('_fprom_tid');
  return callFirebaseFunction(CF.space.create, {
    spaceId,
    tz,
    affiliation,
    fpCookie
  });
};

export const addDomain = (spaceId: string, domain: string) => {
  const params: IDomainCreateParams = {
    spaceId,
    url: domain
  };
  return callFirebaseFunction(CF.space.addDomain, params);
};

export const addDomainUnverified = (spaceId: string, domain: string) => {
  const params: IDomainCreateParams = {
    spaceId,
    url: domain
  };
  return callFirebaseFunction(CF.space.addDomainUnverified, params);
};

export const setDomainActive = (
  spaceId: string,
  domain: string,
  active: boolean
) => {
  const params: IDomainUpdateParams = {
    spaceId,
    url: domain,
    active
  };
  return callFirebaseFunction(CF.space.updateDomainActivity, params);
};

export const removeDomain = (spaceId: string, domain: string) => {
  const params: IDomainCreateParams = {
    spaceId,
    url: domain
  };
  return callFirebaseFunction(CF.space.removeDomain, params);
};

export const getActiveDomainUrls = (space: ISpace, hostnameOnly = false) => {
  return space.domains
    .filter((d) => d.active)
    .map((d) => {
      if (hostnameOnly) {
        try {
          return new URL(d.url).hostname;
        } catch (err) {
          // invalid url
          return d.url;
        }
      } else {
        return d.url;
      }
    });
};

export const getActiveDomainUrlsFromSpaceId = (
  spaceId: string,
  hostnameOnly = false
) => {
  return store()
    .collection(FS.spaces)
    .doc(spaceId)
    .get()
    .then((s) =>
      s.exists ? getActiveDomainUrls(toSpaceDoc(s).data, hostnameOnly) : []
    );
};

export const isSingleSiteSpace = (space: ISpace) => {
  return space.domains.filter((d) => d.active).length === 1;
};

export const getUserIds = (space: Doc<ISpace>): string[] => {
  return Object.keys(space.data.permissions);
};

export const generateChannelId = (url: string) => {
  try {
    const urlObj = new URL(url);
    return urlObj.hostname.replace(/\./g, '-');
  } catch (err) {
    return url;
  }
};

export const toSpaceDoc = generateToDocFn<ISpace>((d) => {
  d.features = d.features || {};
  d.onboarding = d.onboarding || {};
  d.domains.forEach((domain) => {
    if (domain.verified === undefined) {
      domain.verified = true;
    }
    if (typeof domain.autoImport !== 'boolean') {
      domain.autoImport = false;
    }
    domain.channelId = domain.channelId || generateChannelId(domain.url);
    domain.sitemaps = domain.sitemaps || null;
    domain.redirectUrls = domain.redirectUrls || null;
  });
  // As long as this is not fully active and activated, everyone's an owner
  d.permissionsV2 = {
    users:
      d.permissionsV2?.users ||
      mapValues<typeof d.permissions, IPermissions | null>(
        d.permissions,
        (x) => {
          return x ? { roles: ['OWNER'], additionalScopes: [] } : null;
        }
      ),
    customRoles: d.permissionsV2?.customRoles || {}
  };
  return d;
});

export const updateCurrency = (spaceId: string, currency: CurrencyCode) => {
  // this line is not really necessary, but we use it to get some type safety
  // as we're updating through a property path.
  // In case the type changes, this line would flare up with a compiler error.
  const nextValue: ISpace['config']['currency'] = currency;
  return store().collection(FS.spaces).doc(spaceId).update({
    'config.currency': nextValue
  });
};

export const updateTimezone = (spaceId: string, tz: string) => {
  // this line is not really necessary, but we use it to get some type safety
  // as we're updating through a property path.
  // In case the type changes, this line would flare up with a compiler error.
  const nextValue: ISpace['config']['tz'] = tz;
  return store().collection(FS.spaces).doc(spaceId).update({
    'config.tz': nextValue
  });
};

export const updateSiteSettings = (
  space: ISpace,
  domain: IDomain,
  settings: {
    autoImport: boolean | null;
    sitemaps: string[] | null;
    redirectUrls: string[] | null;
    urlFormat: 'url' | 'canonical';
    updateMethod: 'replace' | 'append';
    subidStrategy: 'network' | 'universal';
    subIdMatches: string[];
    subIdDefaultPrefix: string;
    ignoreParameters: string[] | null;
  }
) => {
  const params: IDomainUpdateSettingsParams = {
    spaceId: space.id,
    url: domain.url,
    autoImport: settings.autoImport,
    sitemaps: settings.sitemaps,
    redirectUrls: settings.redirectUrls,
    urlFormat: settings.urlFormat,
    updateMethod: settings.updateMethod,
    subidStrategy: settings.subidStrategy,
    subIdDefaultPrefix: settings.subIdDefaultPrefix,
    subIdMatches: settings.subIdMatches,
    ignoreParameters: settings.ignoreParameters
  };

  return callFirebaseFunction(CF.space.updateSiteSettings, params);
};

export const updateAutoImportSpace = (
  space: ISpace,
  domain: IDomain,
  autoImport: boolean | null
) => {
  const params: IDomainUpdateAutoImportParams = {
    spaceId: space.id,
    url: domain.url,
    autoImport
  };

  return callFirebaseFunction(CF.space.toggleAutoImportDomain, params);
};
