import {
  CollectionListener,
  createCollectionListenerStore,
  useCollectionListener
} from '../../../services/firecache/collectionListener';
import {
  LoadingValue,
  setDoc,
  store,
  useMappedLoadingValue
} from '../../../services/db';
import { FS } from '../../../versions';
import { Doc, generateToDocFn } from '../../../domainTypes/document';
import { ICampaign } from '../../../domainTypes/campaigns';
import { useCurrentUser } from '../../../services/currentUser';
import { ISpace } from '../../../domainTypes/space';
import { compact, Omit } from 'lodash';
import {
  createDocumentListenerGetter,
  useDocumentListener
} from '../../../services/firecache/documentListener';
import {
  AnalyticsFilter,
  ISOTimeRange
} from '../../../domainTypes/analytics_v2';
import { toMoment } from '../../../services/time';
import { Moment } from 'moment-timezone';
import shortid from 'shortid';
import { removeTrailingSlash } from '../../../services/url';

const toCampaignDoc = generateToDocFn<ICampaign>();

const campaignsStore = (spaceId: string) =>
  store().collection(FS.campaigns).where('spaceId', '==', spaceId);

const campaignsCollectionListener = createCollectionListenerStore(
  (spaceId) => new CollectionListener(campaignsStore(spaceId), toCampaignDoc)
);

export const useCampaignsCollection = (spaceId: string) =>
  useCollectionListener(campaignsCollectionListener(spaceId));

export const campaignStatuses = [
  'planning',
  'scheduled',
  'running',
  'completed'
] as const;

export const campaignStatusTitle = (status: CampaignStatus) => {
  switch (status) {
    case 'planning':
      return 'Planning';
    case 'scheduled':
      return 'Scheduled';
    case 'running':
      return 'Running';
    case 'completed':
      return 'Completed';
  }
};

export type CampaignStatus = typeof campaignStatuses[number];

interface BaseCampaign extends Omit<ICampaign, 'timeframe'> {}

type PlannedCampaign = BaseCampaign & {
  status: 'planning';
};

type ScheduledCampaign = BaseCampaign & {
  status: 'scheduled';
  timeframe: {
    start: Moment;
    end: Moment;
  };
};

export type RunningCampaign = BaseCampaign & {
  status: 'running';
  timeframe: {
    start: Moment;
    end: Moment;
  };
};

export type CompletedCampaign = BaseCampaign & {
  status: 'completed';
  timeframe: {
    start: Moment;
    end: Moment;
  };
};

export type Campaign =
  | PlannedCampaign
  | ScheduledCampaign
  | RunningCampaign
  | CompletedCampaign;

export type ReadyCampaign = RunningCampaign | CompletedCampaign;

export const isCampaignRunning = (
  campaign: Campaign
): campaign is RunningCampaign => campaign.status === 'running';

export const isCampaignComplete = (
  campaign: Campaign
): campaign is CompletedCampaign => campaign.status === 'completed';

// Bad name
export const isCampaignReady = (
  campaign: Campaign
): campaign is ReadyCampaign =>
  campaign.status === 'running' || campaign.status === 'completed';

export const campaignQueryBase = ({
  timeframe,
  links,
  advertisers,
  pageUrls
}: ReadyCampaign): {
  filters: AnalyticsFilter[];
  range: ISOTimeRange;
} => ({
  range: {
    start: timeframe.start.toISOString(),
    end: timeframe.end.toISOString()
  },
  filters: compact([
    advertisers.length > 0 &&
      links.length === 0 && {
        field: 'pk',
        condition: 'in',
        values: advertisers.map((a) => a.partnerKey)
      },
    advertisers.length > 0 &&
      links.length === 0 && {
        field: 'advertiser_id',
        condition: 'in',
        values: advertisers.map((a) => a.id)
      },
    links.length > 0 && {
      field: 'link_id',
      condition: 'in',
      values: links
    },
    pageUrls.length > 0 && {
      field: 'page_url',
      condition: 'in',
      values: pageUrls.map(removeTrailingSlash)
    }
  ])
});

export const campaignGoal = (campaign: Campaign): number => {
  const roas = campaign.goals.find((g) => g.type === 'roas')?.amount ?? 1;
  return roas * campaign.flatSpend;
};

// Type is used to model changing schema of ICampaign
type PartialICampaign = Pick<ICampaign, 'spaceId' | 'id'> &
  Partial<Omit<ICampaign, 'spaceId' | 'id'>>;

const readCampaign = (partialCampaign: PartialICampaign): Campaign => {
  const campaign: ICampaign = {
    ...DEFAULT_CAMPAIGN,
    ...partialCampaign
  };

  const { timeframe } = campaign;
  if (!timeframe) {
    return {
      ...campaign,
      status: 'planning'
    };
  }
  const now = Date.now();
  if (now < timeframe.start.toMillis()) {
    return {
      ...campaign,
      status: 'scheduled',
      timeframe: {
        start: toMoment(timeframe.start),
        end: toMoment(timeframe.end)
      }
    };
  }
  if (now < timeframe.end.toMillis()) {
    return {
      ...campaign,
      status: 'running',
      timeframe: {
        start: toMoment(timeframe.start),
        end: toMoment(timeframe.end)
      }
    };
  }
  return {
    ...campaign,
    status: 'completed',
    timeframe: {
      start: toMoment(timeframe.start),
      end: toMoment(timeframe.end)
    }
  };
};

export const useCampaigns = (): LoadingValue<Campaign[]> => {
  const { space } = useCurrentUser();
  return useMappedLoadingValue(useCampaignsCollection(space.id), (campaigns) =>
    campaigns.map(({ data }) => readCampaign(data))
  );
};

const DEFAULT_CAMPAIGN: Omit<ICampaign, 'spaceId' | 'id'> = {
  name: '',
  advertisers: [],
  managers: [],
  flatSpend: 10000,
  goals: [],
  timeframe: null,
  pageUrls: [],
  links: []
};

export const EMPTY_CAMPAIGN = (space: ISpace): ICampaign => ({
  spaceId: space.id,
  id: shortid(),
  ...DEFAULT_CAMPAIGN
});

const toCampaignDocId = (spaceId: string, slug: string) => `${spaceId}_${slug}`;

export const removeCampaign = async (spaceId: string, id: string) =>
  store().collection(FS.campaigns).doc(toCampaignDocId(spaceId, id)).delete();

export const saveCampaign = async (
  spaceId: string,
  id: string,
  campaign: Omit<ICampaign, 'id' | 'spaceId'>
) => {
  const campaignToSave: Doc<ICampaign> = {
    collection: FS.campaigns,
    id: toCampaignDocId(spaceId, id),
    data: {
      ...campaign,
      id,
      spaceId
    }
  };
  return setDoc(campaignToSave);
};

const campaignStore = createDocumentListenerGetter(
  (docId: string) => store().collection(FS.campaigns).doc(docId),
  toCampaignDoc
);

export const useCampaign = (slug: string): LoadingValue<Campaign | null> => {
  const { space } = useCurrentUser();
  return useMappedLoadingValue(
    useDocumentListener(campaignStore(toCampaignDocId(space.id, slug))),
    (campaign) => {
      if (!campaign) {
        return null;
      }
      return readCampaign(campaign.data);
    }
  );
};
