import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { compact } from 'lodash';
import { useSnackbar, WithSnackbarProps } from 'notistack';
import React from 'react';
import { Check, Info } from 'react-feather';
import { Helmet } from 'react-helmet';
import { Cross } from 'recharts';
import { AddAdditionalDomainModal } from '../../../../components/AddDomainModal';
import {
  AdditionActionsMenuOption,
  AdditionalActionsMenu
} from '../../../../components/AdditionalActionsMenu';
import { ButtonWithPromise } from '../../../../components/ButtonWithPromise';
import { HelpIcon } from '../../../../components/HelpIcon';
import { SelectCurrency } from '../../../../components/SelectCurrency';
import { SiteSettings } from '../../../../components/SiteSettings';
import {
  TrackingMetaTag,
  TrackingScriptTag
} from '../../../../components/Snippet';
import { DEFAULT_CURRENCY, ISpace } from '../../../../domainTypes/space';
import { IUserDomain } from '../../../../domainTypes/user';
import { styled } from '../../../../emotion';
import { useDialogState } from '../../../../hooks/useDialogState';
import { CanvasBar } from '../../../../layout/Canvas';
import { LimitedWidth } from '../../../../layout/PageBody';
import { Section } from '../../../../layout/Section';
import { useRoutes } from '../../../../routes';
import { ARTICLES } from '../../../../services/beacon';
import {
  useCurrentUser,
  useCurrentUserScopes
} from '../../../../services/currentUser';
import { createScan } from '../../../../services/scan';
import {
  addDomain,
  removeDomain,
  setDomainActive,
  updateCurrency
} from '../../../../services/space';
import { SettingsLayout } from '../../SettingsLayout';
import {
  CreatePublicApiTokenButton,
  PublicApiTokensTable
} from './PublicApiTokens';

const TrackingScriptTagS = styled(TrackingScriptTag)`
  font-size: 11px;
`;

const TrackingMetaTagS = styled(TrackingMetaTag)`
  font-size: 11px;
`;

const ConfirmRemoveDomainDialog = ({
  open,
  onClose,
  onSuccess,
  onError,
  spaceId,
  domain
}: {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  onError: () => void;
  spaceId: string;
  domain: string;
}) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>Are you sure you want to remove this site?</DialogTitle>
    <DialogContent>
      <Typography variant="body1" paragraph>
        When you remove <strong>{domain}</strong> from your account, we'll no
        longer track clicks, apply tracking labels to your links, or monitor
        this site for new affiliate links.
      </Typography>
      <Typography variant="body1">
        In most cases, you can simply <em>deactivate</em> the site. Only remove
        the site if you've added it by mistake.
      </Typography>
    </DialogContent>
    <DialogActions>
      <ButtonWithPromise
        pending={<span>Removing site...</span>}
        onClick={() =>
          removeDomain(spaceId, domain).then(onSuccess).catch(onError)
        }
      >
        Remove site
      </ButtonWithPromise>
      <Button color="primary" variant="contained" onClick={onClose}>
        Cancel
      </Button>
    </DialogActions>
  </Dialog>
);

const createDomainOptions = (
  spaceId: string,
  domain: IUserDomain,
  enqueueSnackbar: WithSnackbarProps['enqueueSnackbar'],
  canRemoveDomains: boolean
): AdditionActionsMenuOption[] => {
  return compact([
    {
      key: 'settings',
      label: 'Site Settings',
      dialogMaxWidth: 'sm',
      dialog: ({ onClose }) => {
        return (
          <>
            <DialogTitle>Site settings</DialogTitle>
            <DialogContent>
              <SiteSettings domain={domain.url} />
            </DialogContent>
          </>
        );
      }
    },
    {
      key: 'activate',
      onClick: () => {
        return setDomainActive(spaceId, domain.url, !domain.active)
          .then(() => {
            return enqueueSnackbar(
              `Your site was ${
                domain.active ? 'deactivated' : 'activated'
              } successfully`,
              {
                variant: 'success'
              }
            );
          })
          .catch(() => {
            return enqueueSnackbar('Your site could not be deactivated', {
              variant: 'error'
            });
          });
      },
      label: <span>{domain.active ? 'Deactivate' : 'Reactivate'}</span>
    },
    canRemoveDomains && {
      key: 'remove',
      dialog: ({ onClose }) => (
        <ConfirmRemoveDomainDialog
          spaceId={spaceId}
          domain={domain.url}
          open
          onClose={onClose}
          onSuccess={() => {
            return enqueueSnackbar('Your domain was removed successfully', {
              variant: 'success'
            });
          }}
          onError={() => {
            return enqueueSnackbar("Uh oh, we couldn't remove that domain", {
              variant: 'error'
            });
          }}
        />
      ),
      label: <span>Remove...</span>
    }
  ]);
};

const ActiveWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ActiveLabelWrapper = styled('div')`
  margin-right: ${(p) => p.theme.spacing(1)}px;
`;

const PreferenceGrid = styled('div')`
  display: grid;
  grid-template: '1fr 1fr';
  grid-gap: ${(p) => p.theme.spacing(2)}px;
`;

const Preferences = ({ space }: { space: ISpace }) => {
  return (
    <>
      <PreferenceGrid>
        <SelectCurrency
          value={space.config.currency || DEFAULT_CURRENCY}
          onChange={(nextCurrency) => updateCurrency(space.id, nextCurrency)}
          label="Currency"
          disabled
        />
        <TextField
          value={space.config.tz || 'UTC'}
          disabled
          label="Timezone"
          variant="outlined"
        />
      </PreferenceGrid>
    </>
  );
};

export const PageSettingsNewGeneral = () => {
  const { ROUTES, goTo } = useRoutes();
  const scopes = useCurrentUserScopes();
  const canViewDomains = scopes.has('domains.view');
  const canAddDomains = scopes.has('domains.create');
  const canEditDomains = scopes.has('domains.edit');
  const canRemoveDomains = scopes.has('domains.delete');
  const canSeeSettings = scopes.has('settings.view');
  const canCreateApiTokens = scopes.has('api_tokens.create');
  const canViewApiTokens = scopes.has('api_tokens.view');

  const currentUser = useCurrentUser();
  const spaceId = currentUser.space.id;
  const { dialogOpen, openDialog, closeDialog } = useDialogState();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <SettingsLayout>
      <Helmet>
        <title>General Settings | Affilimate</title>
      </Helmet>
      {canSeeSettings && (
        <LimitedWidth width={800}>
          {canViewDomains && (
            <Section>
              <CanvasBar>
                <div>
                  Your Sites &nbsp;{' '}
                  <HelpIcon articleId={ARTICLES.websites.manageSites} />
                </div>
                {canAddDomains && (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={openDialog}
                  >
                    Add a site
                  </Button>
                )}
              </CanvasBar>
              <Paper>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Domain</TableCell>
                      <TableCell align="center">
                        <Tooltip
                          title="Active sites send tracking events, get their links labeled, and are automatically scanned for new links. You can deactivate any site you no longer wish to track."
                          placement="top"
                          aria-label="Active sites send tracking events, get their links labeled, and are automatically scanned for new links. You can deactivate any site you no longer wish to track."
                        >
                          <ActiveWrapper>
                            <ActiveLabelWrapper>Active</ActiveLabelWrapper>{' '}
                            <Info size={16} />
                          </ActiveWrapper>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip
                          title="Verified sites are those where we've confirmed your ownership through your meta tag and received the first tracking data."
                          placement="top"
                          aria-label="Verified sites are those where we've confirmed your ownership through your meta tag and received the first tracking data."
                        >
                          <ActiveWrapper>
                            <ActiveLabelWrapper>Verified</ActiveLabelWrapper>{' '}
                            <Info size={16} />
                          </ActiveWrapper>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentUser.space.domains.map((domain, i) => (
                      <TableRow key={i}>
                        <TableCell>{domain.url}</TableCell>
                        <TableCell align="center">
                          {domain.active && <Check />}
                          {!domain.active && <Cross />}
                        </TableCell>
                        <TableCell align="center">
                          {domain.verified && <Check />}
                          {!domain.verified && <Cross />}
                        </TableCell>
                        <TableCell align="right">
                          {canEditDomains && (
                            <AdditionalActionsMenu
                              label="Edit domain"
                              options={createDomainOptions(
                                spaceId,
                                domain,
                                enqueueSnackbar,
                                canRemoveDomains
                              )}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            </Section>
          )}

          <Section>
            <CanvasBar>
              <div>
                Your Snippets &nbsp;{' '}
                <HelpIcon articleId={ARTICLES.setup.install} />
              </div>
            </CanvasBar>
            <Card>
              <CardContent>
                <TrackingMetaTagS />
                <TrackingScriptTagS />
              </CardContent>
            </Card>
          </Section>

          <Section>
            <CanvasBar>Preferences</CanvasBar>
            <Card>
              <CardContent>
                <Preferences space={currentUser.space} />
                <Typography
                  variant="caption"
                  color="textSecondary"
                  component="p"
                  style={{ marginTop: '18px' }}
                >
                  Please contact Support to change these settings.
                </Typography>
              </CardContent>
            </Card>
          </Section>

          {canViewApiTokens && (
            <Section>
              <CanvasBar>
                <div>
                  Secret API Tokens &nbsp;{' '}
                  <HelpIcon articleId={ARTICLES.api.overview} />
                </div>

                {canCreateApiTokens && (
                  <CreatePublicApiTokenButton
                    variant="contained"
                    color="primary"
                    size="small"
                    spaceId={currentUser.space.id}
                  >
                    Create new token
                  </CreatePublicApiTokenButton>
                )}
              </CanvasBar>
              <Paper>
                <PublicApiTokensTable spaceId={currentUser.space.id} />
              </Paper>
            </Section>
          )}
        </LimitedWidth>
      )}

      <AddAdditionalDomainModal
        open={dialogOpen}
        onClose={closeDialog}
        onVerifyDomain={(domain) => addDomain(spaceId, domain)}
        onScan={(ds) =>
          createScan(ds, 'DOMAIN').then((doc) =>
            goTo(ROUTES.links.scans.details.url(doc.id))
          )
        }
      />
    </SettingsLayout>
  );
};
