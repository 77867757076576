import { Card } from '@material-ui/core';
import { compact, isNil } from 'lodash';
import React, { useMemo, useState } from 'react';
import { Search } from 'react-feather';
import { Helmet } from 'react-helmet';
import { AlertBox } from '../../../../components/AlertBox';
import {
  FiltersDrawer,
  FiltersIcon,
  useFilterDrawerState
} from '../../../../components/analytics_v2/Filters/Drawer';
import { Dimension } from '../../../../components/analytics_v2/Filters/filters';
import { useFilterClauses } from '../../../../components/analytics_v2/Filters/hooks';
import { createSearchBox } from '../../../../components/analytics_v2/SearchBox';
import {
  ColumnDefinitions,
  TableMetadata,
  useTable
} from '../../../../components/analytics_v2/Table';
import {
  Timeframe,
  useTimeframe
} from '../../../../components/analytics_v2/Timeframe';
import { CustomPagination } from '../../../../components/CustomPagination';
import { ExportQueryButton } from '../../../../components/ExportQuery';
import { RowsRenderer } from '../../../../components/GroupableList';
import { InlineLoader, Loader } from '../../../../components/Loader';
import { NoPermissions } from '../../../../components/NoPermissions';
import { SearchInput } from '../../../../components/SearchInput';
import { ColumnSelector } from '../../../../components/Table/ColumnSelector';
import {
  AnalyticsFilter,
  AnalyticsOrderBy,
  AnalyticsQuery,
  AnalyticsResponseRowWithComparison
} from '../../../../domainTypes/analytics_v2';
import { ClickDimensionName } from '../../../../domainTypes/customDimensions';
import { css, styled } from '../../../../emotion';
import { Centered } from '../../../../layout/Centered';
import { FlexContainer } from '../../../../layout/Flex';
import {
  DEFAULT_OFFSET,
  PageToolbar,
  PageToolbarSection
} from '../../../../layout/PageToolbar';
import { useStringQueryParam } from '../../../../routes';
import { useChannelIdGrouper } from '../../../../services/analyticsV2/groups';
import { Metric } from '../../../../services/analyticsV2/metrics';
import {
  toAnalyticsV2Search,
  useAnalyticsQueryV2
} from '../../../../services/analyticsV2/query';
import {
  useCurrentUser,
  useHasCurrentUserRequiredScopes
} from '../../../../services/currentUser';
import { useCustomDimensions } from '../../../../services/customDimensions';
import { useMappedLoadingValue } from '../../../../services/db';
import { useFeatureEnabled } from '../../../../services/features';
import { PageBodyLinks } from '../../components/PageBodyLinks';
import { ProductLinkCellWithPartnerLazy } from '../Overview/components/ProductLinkCell';
import { LinkDetailsModal } from './LinkDetailsModal';

const customColumns = ['link_id'] as const;
type CustomColumns = typeof customColumns[number];
type Column = CustomColumns | Metric;

const relevantColumns: Metric[] = [
  'count_uniq_link_occ',
  'p',
  'v',
  'c',
  's',
  'ctr',
  'commission_sum_net',
  'avg_rate_net',
  'view_ratio',
  'order_count_net',
  'order_count_gross',
  'quantity_net',
  'rpm_net',
  'epc_net',
  'gmv_sum_net'
];

const availableColumns: Column[] = ['link_id', ...relevantColumns];

const DEFAULT_VISIBLE_COLUMN_NAMES: Metric[] = [
  'c',
  'ctr',
  'commission_sum_net',
  'quantity_net',
  'epc_net'
];

const defaultVisibleColumns: Column[] = [
  ...customColumns,
  ...DEFAULT_VISIBLE_COLUMN_NAMES
];

const columnDefinitions: ColumnDefinitions<CustomColumns> = {
  link_id: {
    column: {
      key: 'link_id',
      head: () => 'Link name or deeplink',
      cell: (p: AnalyticsResponseRowWithComparison, o: TableMetadata) => (
        <ProductLinkCellWithPartnerLazy
          spaceId={o.spaceId}
          productId={p.group.link_id}
        />
      ),
      align: 'left',
      sortable: false,
      defaultDirection: 'asc',
      width: 400,
      flexGrow: 8
    },
    sorter: {
      key: 'link_id',
      items: {
        sort: (p: AnalyticsResponseRowWithComparison) => p.group.link_id,
        dir: 'asc'
      }
    }
  }
};

const EXPORT_MAX_ROW_COUNT = 1000;
const PAGE_SIZE = 20;

const SearchInfo = styled('div')`
  background-color: #e5e5e5;
  color: ${({ theme }) => theme.palette.grey[600]};
  padding: ${({ theme }) => theme.spacing(0.5)}px;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  font-size: 12px;
  text-align: center;

  svg {
    margin-right: ${({ theme }) => theme.spacing(0.5)}px;
    vertical-align: middle;
  }
`;

const [SearchBox, useSearch] = createSearchBox('page_query');

const usePageUrlSearch = () => useSearch('page_url');
const useNormalizedSearch = () => {
  const search = usePageUrlSearch();
  return useMemo(() => {
    if (isNil(search)) return '';
    // TODO: yup, hacky!
    return search.pattern.replaceAll('%', '');
  }, [search]);
};

const useLinksFilters = (): AnalyticsFilter[] => {
  const filters = useFilterClauses();
  const search = usePageUrlSearch();
  const searchFilter = search ?? null;

  return useMemo(() => {
    const finalFilters: AnalyticsFilter[] = compact([
      ...filters,
      searchFilter,
      {
        field: 'link_id',
        condition: 'not in',
        values: ['']
      }
    ]);
    return finalFilters;
  }, [searchFilter, filters]);
};

const toLinkSearch = (term: string) =>
  toAnalyticsV2Search(term, ['link_name', 'link_url', 'link_dest_url']);

const useLinksCount = (linkSearch = '') => {
  const { space } = useCurrentUser();
  const filters = useLinksFilters();
  const { columnTransformers } = useChannelIdGrouper();
  const { range } = useTimeframe();
  const query = useMemo<AnalyticsQuery>(() => {
    return {
      select: ['count_uniq_link_id'],
      range,
      filters,
      search: toLinkSearch(linkSearch),
      columnTransformers: columnTransformers(space)
    };
  }, [range, filters, columnTransformers, space, linkSearch]);

  return useMappedLoadingValue(
    useAnalyticsQueryV2(space.id, query),
    (data) => data.rows[0]?.data.count_uniq_link_id?.curr ?? 0
  );
};

const useLinksQuery = (
  metrics: Metric[],
  paginate: AnalyticsQuery['paginate'],
  orderBy: AnalyticsOrderBy,
  linkSearch = ''
) => {
  const { space } = useCurrentUser();
  const filters = useLinksFilters();
  const tf = useTimeframe();
  const { columnTransformers } = useChannelIdGrouper();
  const query = useMemo<AnalyticsQuery>(() => {
    return {
      groupBy: ['link_id'],
      select: metrics,
      // select: metrics,
      ...tf,
      filters,
      search: toLinkSearch(linkSearch),
      paginate,
      orderBy: [orderBy],
      columnTransformers: columnTransformers(space)
    };
  }, [
    metrics,
    tf,
    filters,
    paginate,
    orderBy,
    columnTransformers,
    space,
    linkSearch
  ]);

  return useAnalyticsQueryV2(space.id, query, {
    logMode: 'compact',
    logLabel: 'useLinksQuery'
  });
};

const useLinksExportQuery = (
  metrics: Metric[],
  orderBy: AnalyticsOrderBy,
  linkSearch = ''
) => {
  const { space } = useCurrentUser();
  const filters = useLinksFilters();
  const { columnTransformers } = useChannelIdGrouper();
  const { range } = useTimeframe();
  return useMemo<AnalyticsQuery>(() => {
    return {
      groupBy: ['link_id'],
      select: [...metrics, 'agg_uniq_page_url'],
      range,
      filters,
      search: toLinkSearch(linkSearch),
      paginate: {
        page: 1,
        limit: EXPORT_MAX_ROW_COUNT
      },
      orderBy: [orderBy],
      columnTransformers: columnTransformers(space)
    };
  }, [metrics, range, filters, orderBy, columnTransformers, space, linkSearch]);
};

const useAvailableDimensions = (): Dimension[] => {
  const [customDimensions = []] = useMappedLoadingValue(
    useCustomDimensions(),
    (dimensions) => Object.keys(dimensions) as ClickDimensionName[]
  );
  const hasTrafficSources = useFeatureEnabled('REFERRER_REPORTS_V1');
  return useMemo(() => {
    const baseDimensions: Dimension[] = compact([
      'channel',
      'platform',
      'device',
      'tag',
      hasTrafficSources && 'utm_campaign',
      hasTrafficSources && 'utm_medium',
      hasTrafficSources && 'utm_source',
      hasTrafficSources && 'utm_term',
      hasTrafficSources && 'utm_content',
      hasTrafficSources && 'referrer'
    ]);
    return [...baseDimensions, ...customDimensions];
  }, [customDimensions, hasTrafficSources]);
};

const SHOW_LINK_SEARCH = false;

const LinksOverview = () => {
  const [linkDetails, setLinkDetails] = useState<string | null>(null);
  const filterDimensions = useAvailableDimensions();
  const {
    tableProps,
    columnSelectorProps,
    paginationSelectorProps,
    pagination,
    orderBy,
    metrics
  } = useTable(availableColumns, columnDefinitions, {
    pageSize: PAGE_SIZE,
    defaultSortColumn: 'c',
    defaultVisibleColumns
  });

  const search = useNormalizedSearch();
  const { isOpen, toggle } = useFilterDrawerState(false, {
    localStorageKey: 'links-v2-overview-filter-drawer-state'
  });

  const [linkSearch, setLinkSearch] = useStringQueryParam('link_query');

  const [data, loading] = useLinksQuery(
    metrics,
    pagination,
    orderBy,
    linkSearch
  );
  const [count] = useLinksCount(linkSearch);
  const exportQuery = useLinksExportQuery(metrics, orderBy, linkSearch);
  const exportRowLimitReached = count && count >= EXPORT_MAX_ROW_COUNT;

  return (
    <>
      <PageToolbar>
        <PageToolbarSection flex={4}>
          <FlexContainer>
            <SearchBox
              size="small"
              width={360}
              placeholder="Filter by page URL (e.g. /home/, best-deals)"
            />
            {SHOW_LINK_SEARCH && (
              <SearchInput
                size="small"
                width={360}
                placeholder="Filter by link name or URL"
                value={linkSearch}
                onChange={setLinkSearch}
              />
            )}
            <FiltersIcon isOpen={isOpen} toggle={toggle} />
          </FlexContainer>
        </PageToolbarSection>
        <PageToolbarSection flex={4} justifyContent="flex-end">
          {data && loading && <InlineLoader />}
          <ColumnSelector {...columnSelectorProps} />
          <CustomPagination
            {...paginationSelectorProps}
            siblingCount={0}
            count={Math.ceil((count || 1) / PAGE_SIZE)}
          />
          <Timeframe />
          <ExportQueryButton
            title="Export links to CSV"
            reportType="links"
            query={exportQuery}
          >
            {exportRowLimitReached ? (
              <AlertBox variant="error">
                Only the first <strong>1000 rows</strong> matching your search
                criteria will be exported. Need more? Please contact the
                Customer Success team for support.
              </AlertBox>
            ) : null}
          </ExportQueryButton>
        </PageToolbarSection>
      </PageToolbar>
      <div
        className={css((t) => ({
          marginBottom: t.spacing(2),
          width: '100%'
        }))}
      >
        <FiltersDrawer
          isOpen={isOpen}
          title="Filter links by"
          orderBy={orderBy.field as Metric}
          availableDimensions={filterDimensions}
        />
      </div>
      {search && (
        <SearchInfo>
          <Search size={12} /> Showing links that appear on pages where the page
          URL or path contains <strong>"{search}"</strong>.
        </SearchInfo>
      )}
      {!data ? (
        <Card>
          <Centered height={350}>
            <Loader size={32} />
          </Centered>
        </Card>
      ) : (
        <RowsRenderer
          {...tableProps}
          renderHead={true}
          headProps={{
            sticky: true,
            offset: DEFAULT_OFFSET
          }}
          rows={data.rows}
          onRowClick={(d) => {
            setLinkDetails(d.group.link_id);
          }}
          rowToKey={(d) => d.group.link_id}
        />
      )}
      <LinkDetailsModal
        initialPageSearch={search}
        open={linkDetails !== null}
        onClose={() => setLinkDetails(null)}
        linkId={linkDetails as string}
      />
    </>
  );
};

export const PageLinksOverviewV2 = () => {
  const [canView] = useHasCurrentUserRequiredScopes(['reports.links.view']);

  return (
    <>
      <Helmet>
        <title>Links | Affilimate</title>
      </Helmet>
      <PageBodyLinks noTopPadding>
        {canView ? <LinksOverview /> : <NoPermissions />}
      </PageBodyLinks>
    </>
  );
};
