import { sortBy, sum, truncate } from 'lodash';
import moment from 'moment-timezone';
import React, { useMemo } from 'react';
import { Trash as IconTrash } from 'react-feather';
import { Helmet } from 'react-helmet';
import {
  useColumnsQueryParam,
  useSortQueryParam
} from '../../../../components/GroupableList';
import { IconContainer } from '../../../../components/IconContainer';
import {
  getAppliedLabel,
  MultiSelector,
  MultiSelectorChip
} from '../../../../components/MultiSelector';
import { SearchInput } from '../../../../components/SearchInput';
import { ColumnSelector } from '../../../../components/Table/ColumnSelector';
import { ModeSelector } from '../../../../components/Table/ModeSelector';
import {
  TimeframePickerDense,
  useStandardOptions,
  useTimeframeFromUrl
} from '../../../../components/TimeframePicker';
import { isSameTimeframe, Mode } from '../../../../domainTypes/analytics';
import { Doc } from '../../../../domainTypes/document';
import { EMPTY_ARR } from '../../../../domainTypes/emptyConstants';
import {
  INarrowProductWithCountsAndTrends,
  ProductIssueType
} from '../../../../domainTypes/product';
import { useDictionary } from '../../../../hooks/useDictionary';
import { useErrorLogger } from '../../../../hooks/useErrorLogger';
import { usePromise } from '../../../../hooks/usePromise';
import {
  DEFAULT_OFFSET,
  PageToolbar,
  PageToolbarPagination,
  PageToolbarSection
} from '../../../../layout/PageToolbar';
import {
  queryParamToList,
  setToQueryParam,
  useNumberQueryParam,
  useQueryParam,
  useRoutes,
  useStringQueryParam,
  useTypedStringQueryParam
} from '../../../../routes';
import {
  allTime,
  useProductCountsInTimeframePg
} from '../../../../services/analytics';
import {
  useCurrentUser,
  useHasCurrentUserRequiredScopes
} from '../../../../services/currentUser';
import {
  constructPartnerForKey,
  getKnownPartnerForKey,
  PartnerListItem
} from '../../../../services/partner';
import { pluralize } from '../../../../services/pluralize';
import { getPartnersWithProductCounts } from '../../../../services/products';
import { toMoment } from '../../../../services/time';
import { FS } from '../../../../versions';
import { PageBodyLinks } from '../../components/PageBodyLinks';
import { EmptyState } from './components/EmptyState';
import { ProductDeleteButton } from './components/ProductDeleteButton';
import {
  COLUMNS as COLUMNS_PG,
  DEFAULT_COLUMNS as DEFAULT_COLUMNS_PG,
  ProductsTablePg,
  SORTERS as SORTERS_PG
} from './components/ProductsTablePg';
import { useTrackMixpanelView } from '../../../../services/mixpanel';
import { NoPermissions } from '../../../../components/NoPermissions';
import { LinksEmptyState } from './components/LinksEmptyState';
import { EmptyLinkSearchState } from './components/EmptyLinkSearchState';
import { PaperLoader } from '../../../../components/Loader';
import { getSelectedItems } from '../../../../services/selection';
import { usePgProductsCacheContext } from '../../../../services/products/cache';

const SelectionActionsPg = ({
  products,
  deselect
}: {
  products: Doc<INarrowProductWithCountsAndTrends>[];
  deselect?: () => void;
}) => {
  const productIds = products.map((p) => p.id);
  return (
    <ProductDeleteButton
      variant="contained"
      color="secondary"
      productIds={productIds}
      onSuccess={deselect}
    >
      <IconContainer>
        <IconTrash size={14} />
        <div>
          Delete {products.length} {pluralize('link', products.length)}
        </div>
      </IconContainer>
    </ProductDeleteButton>
  );
};

const PartnerSelector = ({
  partners,
  value,
  onChange
}: {
  partners: PartnerListItem[];
  value: Set<string> | null;
  onChange: (nextValue: Set<string>) => void;
}) => {
  const fullSet = useMemo(() => new Set(partners.map((p) => p.partner.key)), [
    partners
  ]);
  const knownPartnerKeys = useMemo(
    () =>
      new Set(
        partners.filter((p) => p.partner.known).map((p) => p.partner.key)
      ),
    [partners]
  );
  const v = value || fullSet;
  const isApplied = partners.length !== v.size;

  return (
    <MultiSelector
      value={v}
      onChange={onChange}
      legend="Networks"
      options={sortBy(partners, (p) => p.partner.name).map((p) => ({
        label: `${truncate(p.partner.name, { length: 30 })} (${
          p.counts.links
        })`,
        value: p.partner.key
      }))}
      groupers={[
        {
          key: 'known',
          label: 'Known networks',
          predicate: (o) => knownPartnerKeys.has(o.value)
        },
        {
          key: 'unknown',
          label: 'Other networks',
          predicate: (o) => !knownPartnerKeys.has(o.value)
        }
      ]}
      allOption={<strong>All partners</strong>}
      allowFocusing
    >
      <MultiSelectorChip
        isApplied={isApplied}
        onDelete={() => onChange(fullSet)}
        label="Networks"
        appliedLabel={getAppliedLabel(
          'network',
          isApplied
            ? partners
                .filter((p) => v.has(p.partner.key))
                .map((p) => p.partner.name)
            : []
        )}
      />
    </MultiSelector>
  );
};

export const usePartnersQueryParam = (
  param: string,
  allPartners: PartnerListItem[]
) => {
  const hasAllPartners = (set: Set<string>) => {
    for (const p of allPartners) {
      if (!set.has(p.partner.key)) {
        return false;
      }
    }
    return true;
  };
  return useQueryParam(
    param,
    (p) => (p ? new Set(queryParamToList<string>(p)) : null),
    (cs) => (!cs || hasAllPartners(cs) ? undefined : setToQueryParam(cs))
  );
};

export const usePartnersQueryParam2 = (param: string) => {
  return useQueryParam(
    param,
    (p) => (p ? new Set(queryParamToList<string>(p)) : null),
    (cs) => (!cs || !cs.size ? undefined : setToQueryParam(cs))
  );
};

export const useIssuesQueryParam = (param: string) => {
  return useQueryParam(
    param,
    (p) => (p ? new Set(queryParamToList<ProductIssueType>(p)) : null),
    (cs) => (cs?.size ? setToQueryParam(cs) : undefined)
  );
};

const PAGE_SIZE = 200;

const PageLinksOverviewPg = () => {
  const { space } = useCurrentUser();
  const spaceId = space.id;
  const { ROUTES } = useRoutes();
  const { options, defaultOption } = useStandardOptions();
  const [timeframe, setTimeframe] = useTimeframeFromUrl(defaultOption.value);
  const compare = !isSameTimeframe(allTime(), timeframe);
  const [canDeleteLinks] = useHasCurrentUserRequiredScopes(['links.delete']);
  const [search, setSearch] = useStringQueryParam('q');
  const [mode, setMode] = useTypedStringQueryParam<Mode>(
    'table-mode',
    'absolute-numbers',
    true
  );

  const sorters = SORTERS_PG[mode];
  const [[sorter, direction], setSort] = useSortQueryParam('sort', sorters);

  const [columns, setColumns] = useColumnsQueryParam(
    'columns',
    DEFAULT_COLUMNS_PG
  );

  const {
    dictionary: selected,
    merge: mergeSelected,
    replace: replaceSelection
  } = useDictionary<boolean>({});

  // This is for the filter
  const [partners, setPartners] = usePartnersQueryParam2('partners');

  const [allPartners] = usePromise(async () => {
    const cs = await getPartnersWithProductCounts(spaceId);
    return cs.map((c) => {
      const knownPartner = getKnownPartnerForKey(c.partner_key);
      return {
        partner: knownPartner || constructPartnerForKey(c.partner_key),
        counts: { links: c.count }
      };
    });
  }, [spaceId]);

  const { totalPages } = useMemo(() => {
    if (!allPartners) {
      return { totalPages: null };
    }
    const count = sum(
      allPartners
        .filter((p) =>
          partners && partners.size > 0 ? partners.has(p.partner.key) : true
        )
        .map((p) => p.counts.links)
    );
    const pages = Math.ceil(count / PAGE_SIZE);
    return { totalPages: pages };
  }, [allPartners, partners]);

  const [page, setPage] = useNumberQueryParam('p', 1);
  const { version: pgProductsCacheVersion } = usePgProductsCacheContext();
  const [
    analytics,
    loadingAnalytics,
    errorAnalytics
  ] = useProductCountsInTimeframePg(
    {
      spaceId: space.id,
      groupBy: 'product_id',
      tf: timeframe,
      page,
      q: search,
      limit: PAGE_SIZE,
      partner_key: partners ? Array.from(partners) : undefined,
      orderBy: {
        column: sorter?.key ? [sorter.key] : [sorters.clicked.key],
        dir: direction === 'asc' ? 'ASC' : 'DESC'
      }
    },
    compare,
    pgProductsCacheVersion
  );

  const data = useMemo(() => {
    if (!analytics) {
      return null;
    }

    const result: Doc<INarrowProductWithCountsAndTrends>[] = Object.keys(
      analytics
    ).map((pid) => {
      const row = analytics[pid];
      const p: Doc<INarrowProductWithCountsAndTrends> = {
        id: pid,
        collection: FS.products,
        data: {
          space_id: spaceId,
          id: pid,
          name: row.name,
          url: row.url,
          partner_key: row.partnerKey,
          created_at: row.createdAt,
          issues: row.issues,
          sales: row.earnings,
          epc: row.epc,
          ctr: row.ctr,
          counts: {
            ...analytics[pid]
          }
        }
      };
      return p;
    });

    return result;
  }, [analytics, spaceId]);

  const loading = loadingAnalytics;
  useErrorLogger(errorAnalytics);

  const selectedProducts: Doc<
    INarrowProductWithCountsAndTrends
  >[] = useMemo(() => getSelectedItems(data || [], selected, (d) => d.id), [
    data,
    selected
  ]);

  // Use this to show a message to new users about their product
  // analytics data
  const user = useCurrentUser();
  const isNewSpace =
    moment().diff(toMoment(user.space.createdAt), 'hours') < 48;
  const hasLinks = useMemo(
    () => allPartners && allPartners.some((p) => p.counts.links > 0),
    [allPartners]
  );
  const hasVerifiedSites = user.space.domains.find(
    (d) => d.active && d.verified
  );

  return (
    <PageBodyLinks noTopPadding>
      <PageToolbar sticky offset={DEFAULT_OFFSET}>
        {selectedProducts.length && canDeleteLinks ? (
          <>
            <PageToolbarSection flex={1}>
              {pluralize('links', selectedProducts.length, true)} selected
            </PageToolbarSection>
            <PageToolbarSection flex={1} justifyContent="flex-end">
              <SelectionActionsPg
                products={selectedProducts}
                deselect={() => {
                  replaceSelection({});
                }}
              />
            </PageToolbarSection>
          </>
        ) : (
          <>
            <PageToolbarSection flex={4}>
              <SearchInput
                placeholder="Search by link name or URL"
                value={search}
                onChange={setSearch}
                width={400}
                size="small"
              />
              <PartnerSelector
                partners={allPartners || EMPTY_ARR}
                value={partners}
                onChange={(ps) =>
                  setPartners(
                    ps.size === (allPartners || []).length ? null : ps
                  )
                }
              />
            </PageToolbarSection>
            <PageToolbarSection
              flex={4}
              justifyContent="flex-end"
              spacing="wide"
            >
              <ColumnSelector
                value={columns}
                onChange={setColumns}
                columns={COLUMNS_PG}
              />
              <PageToolbarPagination
                page={page}
                setPage={setPage}
                pageCount={totalPages || 0}
              />
              {false && <ModeSelector value={mode} onChange={setMode} />}
              <TimeframePickerDense
                value={timeframe}
                onChange={setTimeframe}
                options={options}
              />
            </PageToolbarSection>
          </>
        )}
      </PageToolbar>

      {isNewSpace && hasLinks && (
        <LinksEmptyState hasVerifiedSites={!!hasVerifiedSites} />
      )}

      {loading && <PaperLoader height={600} />}

      {!loading && !hasLinks && data?.length === 0 && !search && <EmptyState />}

      {!loading && hasLinks && data?.length === 0 && search && (
        <EmptyLinkSearchState />
      )}

      {data && data.length > 0 && (
        <>
          <ProductsTablePg
            products={data || []}
            loading={loading}
            sorter={sorter || sorters.clicked}
            sortDirection={direction}
            onSort={(k, dir) => setSort([sorters[k] || null, dir])}
            columns={columns}
            compare={compare}
            rowToHref={(d) => ROUTES.links.details.overview.url(d.id)}
            selected={selected}
            setSelected={mergeSelected}
            mode={mode}
          />
        </>
      )}
    </PageBodyLinks>
  );
};

export const PageLinksOverview = () => {
  const [canView] = useHasCurrentUserRequiredScopes(['reports.links.view']);
  useTrackMixpanelView('view_links_overview');

  return (
    <>
      <Helmet>
        <title>Links | Affilimate</title>
      </Helmet>
      {canView ? <PageLinksOverviewPg /> : <NoPermissions />}
    </>
  );
};
