import { Typography } from '@material-ui/core';
import { capitalize, compact } from 'lodash';
import React, { useMemo } from 'react';
import { Timeframe } from '../../../domainTypes/analytics';
import {
  AnalyticsColumnTransformer,
  AnalyticsFilter,
  AnalyticsInterval,
  AnalyticsIntervalUnit,
  AnalyticsSearch
} from '../../../domainTypes/analytics_v2';
import { CurrencyCode } from '../../../domainTypes/currency';
import { EMPTY_ARR } from '../../../domainTypes/emptyConstants';
import { ISpace } from '../../../domainTypes/space';
import {
  useEarningsBarChartDataForAdvertiser,
  useEarningsBarChartDataForChannel,
  useEarningsBarChartDataForCountry,
  useEarningsBarChartDataForDevice,
  useEarningsBarChartDataForNetwork,
  useEarningsBarChartDataForPageUrlOrigin,
  useEarningsBarChartDataForPayoutStatus,
  useEarningsBarChartDataForSaleStatus
} from '../../../features/PerformanceNew/pages/Transactions/data';
import { Centered } from '../../../layout/Centered';
import { FlexContainer } from '../../../layout/Flex';
import { isoRangeToTimeframe, ISOTimeRange } from '../../../services/time';
import { InlineLoader, Loader } from '../../Loader';
import { ChartCard, ChartCardFooter, ChartCardFooterBar } from '../ChartCard';
import { Data, EarningsBarChartV2 } from '../EarningsChartV2';
import { ILegendItem, Legend } from '../Util';
import { ChartMode, ChartModeSelector } from './ChartModeSelector';
import { GraphMode, GraphModeSelector } from './GraphModeSelector';
import { IntervalSelector } from './IntervalSelector';

export type EarningsBarChartCardMetricV2 =
  | 'commission_sum_net'
  | 'gmv_sum_net'
  | 'c';

const DEFAULT_SELECTABLE_METRICS: EarningsBarChartCardMetricV2[] = [
  'commission_sum_net',
  'gmv_sum_net'
];
export const EarningsChartCardV2: React.FC<{
  data: void | Data[];
  loading: boolean;
  currency: CurrencyCode;
  style?: React.CSSProperties;
  graphMode: GraphMode;
  setGraphMode?: (nextGraphMode: GraphMode) => void;
  chartMode: ChartMode;
  setChartMode?: (nextGraphMode: ChartMode) => void;
  metric: EarningsBarChartCardMetricV2;
  setMetric?: (nextMetric: EarningsBarChartCardMetricV2) => void;
  selectableMetrics?: EarningsBarChartCardMetricV2[];
  intervalUnit: AnalyticsIntervalUnit;
  setIntervalUnit?: (nextIntervalUnit: AnalyticsIntervalUnit) => void;
  timeframe?: Timeframe;
}> = ({
  data,
  loading,
  currency,
  style,
  graphMode,
  setGraphMode,
  chartMode,
  setChartMode,
  metric,
  setMetric,
  intervalUnit,
  setIntervalUnit,
  selectableMetrics = DEFAULT_SELECTABLE_METRICS,
  timeframe
}) => {
  const chartSelectors = (
    <>
      {setIntervalUnit && (
        <IntervalSelector value={intervalUnit} onChange={setIntervalUnit} />
      )}
      {setGraphMode && (
        <GraphModeSelector value={graphMode} onChange={setGraphMode} />
      )}
      {setChartMode && (
        <ChartModeSelector value={chartMode} onChange={setChartMode} />
      )}
    </>
  );

  // store reference to last timeframe
  // if loading serve last timeframe
  // if loading turns false -> expose new timeframe

  const heading = useMemo(() => {
    return metric === 'commission_sum_net'
      ? 'Earnings'
      : metric === 'gmv_sum_net'
      ? 'Sales volume'
      : 'Outbound clicks';
  }, [metric]);

  const legendItems: ILegendItem[] = setMetric
    ? compact([
        selectableMetrics.includes('commission_sum_net') && {
          color: '#444',
          shape: 'circle',
          active: metric === 'commission_sum_net',
          onClick: () => setMetric('commission_sum_net'),
          label: 'Earnings'
        },
        selectableMetrics.includes('gmv_sum_net') && {
          color: '#444',
          shape: 'circle',
          active: metric === 'gmv_sum_net',
          onClick: () => setMetric('gmv_sum_net'),
          label: 'Sales volume'
        },
        selectableMetrics.includes('c') && {
          color: '#444',
          shape: 'circle',
          active: metric === 'c',
          onClick: () => setMetric('c'),
          label: 'Clicks'
        }
      ])
    : EMPTY_ARR;

  if (loading) {
    return (
      <ChartCard
        heading={heading}
        topRight={chartSelectors}
        style={style}
        size="small"
        padding="dense"
      >
        <Loader height={330} />
      </ChartCard>
    );
  }
  if (!data) {
    // TODO this should be an error state
    return (
      <ChartCard
        heading={heading}
        subheading={`By ${capitalize(graphMode)}`}
        topRight={chartSelectors}
        style={style}
        size="small"
        padding="dense"
      >
        <Centered>
          <Typography
            variant="body1"
            component="p"
            paragraph
            style={{ textAlign: 'center', marginTop: '100px', maxWidth: '80%' }}
          >
            <strong>No data was loaded</strong>
            <br />
            <br />
            Try refreshing the page. If you expect to see data here and the
            error persists, please contact Support with the URL to this page.
          </Typography>
        </Centered>
        <ChartCardFooter>
          <ChartCardFooterBar>
            <Legend items={legendItems} />
          </ChartCardFooterBar>
        </ChartCardFooter>
      </ChartCard>
    );
  }

  return (
    <ChartCard
      heading={heading}
      topRight={
        <FlexContainer>
          {loading && <InlineLoader color="inherit" />}
          {chartSelectors}
        </FlexContainer>
      }
      style={style}
      size="small"
      padding="dense"
      maximizedContent={() => (
        <EarningsBarChartV2
          height={'auto'}
          data={data}
          currency={currency}
          metric={metric}
          yAxisOrientation="right"
          timeframe={timeframe}
          chartMode={chartMode}
          intervalUnit={intervalUnit}
        />
      )}
    >
      <EarningsBarChartV2
        height={372}
        data={data}
        currency={currency}
        metric={metric}
        yAxisOrientation="right"
        timeframe={timeframe}
        chartMode={chartMode}
        intervalUnit={intervalUnit}
      />
      <ChartCardFooter>
        <ChartCardFooterBar>
          <Legend items={legendItems} />
        </ChartCardFooterBar>
      </ChartCardFooter>
    </ChartCard>
  );
};

interface EarningsChartCardWithoutDataV2Props {
  space: ISpace;
  range: ISOTimeRange;
  filters: AnalyticsFilter[];
  search: AnalyticsSearch[];
  columnTransformers?: AnalyticsColumnTransformer[];
  interval: AnalyticsInterval;
  currency: CurrencyCode;
  metric: EarningsBarChartCardMetricV2;
  setMetric: (nextMetric: EarningsBarChartCardMetricV2) => void;
  selectableMetrics?: EarningsBarChartCardMetricV2[];
  style?: React.CSSProperties;
  graphMode: GraphMode;
  setGraphMode?: (nextGraphMode: GraphMode) => void;
  intervalUnit: AnalyticsIntervalUnit;
  setIntervalUnit?: (nextIntervalUnit: AnalyticsIntervalUnit) => void;
  chartMode: ChartMode;
  setChartMode?: (nextGraphMode: ChartMode) => void;
}

const EarningsBarChartCardNetworks = ({
  space,
  range,
  filters,
  search,
  columnTransformers = EMPTY_ARR,
  interval,
  ...props
}: EarningsChartCardWithoutDataV2Props) => {
  const [d, loading] = useEarningsBarChartDataForNetwork(
    space,
    range,
    filters,
    search,
    columnTransformers,
    interval,
    props.metric,
    {
      logLabel: 'earnings_bar_chart_platform'
    }
  );
  return (
    <EarningsChartCardV2
      data={d}
      loading={loading}
      timeframe={isoRangeToTimeframe(range, interval.tz)}
      {...props}
    />
  );
};

const EarningsBarChartCardCountry = ({
  space,
  range,
  filters,
  search,
  interval,
  ...props
}: EarningsChartCardWithoutDataV2Props) => {
  const [d, loading] = useEarningsBarChartDataForCountry(
    space,
    range,
    filters,
    search,
    interval,
    props.metric,
    {
      logLabel: 'earnings_bar_chart_country'
    }
  );
  return (
    <EarningsChartCardV2
      data={d}
      loading={loading}
      timeframe={isoRangeToTimeframe(range, interval.tz)}
      {...props}
    />
  );
};

const EarningsBarChartCardStatus = ({
  space,
  range,
  filters,
  search,
  interval,
  ...props
}: EarningsChartCardWithoutDataV2Props) => {
  const [d, loading] = useEarningsBarChartDataForSaleStatus(
    space,
    range,
    filters,
    search,
    interval,
    props.metric,
    {
      logLabel: 'earnings_bar_chart_sale_status'
    }
  );
  return (
    <EarningsChartCardV2
      data={d}
      loading={loading}
      timeframe={isoRangeToTimeframe(range, interval.tz)}
      {...props}
    />
  );
};

const EarningsBarChartCardPayoutStatus = ({
  space,
  range,
  filters,
  search,
  interval,
  ...props
}: EarningsChartCardWithoutDataV2Props) => {
  const [d, loading] = useEarningsBarChartDataForPayoutStatus(
    space,
    range,
    filters,
    search,
    interval,
    props.metric,
    {
      logLabel: 'earnings_bar_chart_payout_status'
    }
  );
  return (
    <EarningsChartCardV2
      data={d}
      loading={loading}
      timeframe={isoRangeToTimeframe(range, interval.tz)}
      {...props}
    />
  );
};

const EarningsBarChartCardDevice = ({
  space,
  range,
  filters,
  search,
  interval,
  ...props
}: EarningsChartCardWithoutDataV2Props) => {
  const [d, loading] = useEarningsBarChartDataForDevice(
    space,
    range,
    filters,
    search,
    interval,
    props.metric,
    {
      logLabel: 'earnings_bar_chart_device'
    }
  );
  return (
    <EarningsChartCardV2
      data={d}
      loading={loading}
      timeframe={isoRangeToTimeframe(range, interval.tz)}
      {...props}
    />
  );
};

const EarningsBarChartCardChannel = ({
  space,
  range,
  filters,
  search,
  columnTransformers = EMPTY_ARR,
  interval,
  ...props
}: EarningsChartCardWithoutDataV2Props) => {
  const [d, loading] = useEarningsBarChartDataForChannel(
    space,
    range,
    filters,
    search,
    columnTransformers,
    interval,
    props.metric,
    {
      logLabel: 'earnings_bar_chart_channel'
    }
  );
  return (
    <EarningsChartCardV2
      data={d}
      loading={loading}
      timeframe={isoRangeToTimeframe(range, interval.tz)}
      {...props}
    />
  );
};

const EarningsBarChartCardOrigin = ({
  space,
  range,
  search,
  filters,
  columnTransformers = EMPTY_ARR,
  interval,
  ...props
}: EarningsChartCardWithoutDataV2Props) => {
  const [d, loading] = useEarningsBarChartDataForPageUrlOrigin(
    space,
    range,
    filters,
    search,
    columnTransformers,
    interval,
    props.metric,
    {
      logLabel: 'earnings_bar_chart_origin'
    }
  );
  return (
    <EarningsChartCardV2
      data={d}
      loading={loading}
      timeframe={isoRangeToTimeframe(range, interval.tz)}
      {...props}
    />
  );
};

const EarningsBarChartCardAdvertisers = ({
  space,
  range,
  filters,
  search,
  columnTransformers = EMPTY_ARR,
  interval,
  ...props
}: EarningsChartCardWithoutDataV2Props) => {
  const [d, loading] = useEarningsBarChartDataForAdvertiser(
    space,
    range,
    filters,
    search,
    columnTransformers,
    interval,
    props.metric,
    {
      logLabel: 'earnings_bar_chart_advertisers'
    }
  );
  return (
    <EarningsChartCardV2
      data={d}
      loading={loading}
      timeframe={isoRangeToTimeframe(range, interval.tz)}
      {...props}
    />
  );
};

export const EarningsChartCardWithoutDataV2 = ({
  ...props
}: EarningsChartCardWithoutDataV2Props) => {
  const { graphMode } = props;
  if (graphMode === 'platform') {
    return <EarningsBarChartCardNetworks {...props} />;
  }
  if (graphMode === 'advertiser') {
    return <EarningsBarChartCardAdvertisers {...props} />;
  }
  if (graphMode === 'channel') {
    return <EarningsBarChartCardChannel {...props} />;
  }
  if (graphMode === 'origin') {
    return <EarningsBarChartCardOrigin {...props} />;
  }
  if (graphMode === 'country') {
    return <EarningsBarChartCardCountry {...props} />;
  }
  if (graphMode === 'device') {
    return <EarningsBarChartCardDevice {...props} />;
  }
  if (graphMode === 'transactionStatus') {
    return <EarningsBarChartCardStatus {...props} />;
  }
  if (graphMode === 'payoutStatus') {
    return <EarningsBarChartCardPayoutStatus {...props} />;
  }
  return null;
};
