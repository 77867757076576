import { InputBase, MenuItem, Select } from '@material-ui/core';
import { useMixpanel } from '../../../services/mixpanel';
import { useHasPayouts } from '../../../services/payouts';

export type GraphMode =
  | 'platform'
  | 'advertiser'
  | 'channel'
  | 'origin'
  | 'device'
  | 'country'
  | 'transactionStatus'
  | 'payoutStatus';

export const GraphModeSelector = ({
  value,
  onChange
}: {
  value: GraphMode;
  onChange: (nextValue: GraphMode) => void;
}) => {
  const mixpanel = useMixpanel();
  const hasPayouts = useHasPayouts();

  let options = [
    { value: 'platform', label: 'Platform' },
    { value: 'advertiser', label: 'Advertiser' },
    { value: 'channel', label: 'Channel' },
    { value: 'origin', label: 'Origin' },
    { value: 'device', label: 'Device' },
    { value: 'country', label: 'Country' },
    { value: 'transactionStatus', label: 'Transaction Status' }
  ];

  if (hasPayouts) {
    options = [...options, { value: 'payoutStatus', label: 'Payout Status' }];
  }

  return (
    <Select
      value={value}
      onChange={(ev) => {
        const gm = ev.target.value as GraphMode;
        mixpanel.track('set_graph_mode', {
          graphMode: gm
        });
        return onChange(gm);
      }}
      disableUnderline
      input={<InputBase style={{ fontSize: '0.85rem' }} />}
    >
      {options.map(({ value, label }) => (
        <MenuItem key={value} value={value}>
          By {label}
        </MenuItem>
      ))}
    </Select>
  );
};
