import { compact } from 'lodash';
import React, { useMemo } from 'react';
import { PageBody, SideNavProps } from '../../layout/PageBody';
import { useRoutes } from '../../routes';
import { useCurrentUserScopes } from '../../services/currentUser';
import { useFeatureEnabled } from '../../services/features';

type Props = {
  noTopPadding?: boolean;
  children: React.ReactNode;
  width?: number | 'M' | 'L';
};

export const SettingsLayout = ({ children, noTopPadding }: Props) => {
  const { ROUTES } = useRoutes();
  const scopes = useCurrentUserScopes();
  const canViewBilling = scopes.has('billing.view');
  const canViewTeams = scopes.has('users.view');
  const canViewDomains = scopes.has('domains.view');
  const canViewApiTokens = scopes.has('api_tokens.view');
  const hasChromeExtensionEnabled = useFeatureEnabled('CHROME_EXTENSION');

  const canViewGeneralSettings = useMemo(() => {
    return canViewApiTokens || canViewDomains;
  }, [canViewDomains, canViewApiTokens]);

  const SHOW_REVISION_AND_TRACKING_SETTINGS = false;

  const sideNav: SideNavProps = {
    groups: compact([
      {
        label: 'Account',
        items: compact([
          {
            label: 'User Profile',
            path: ROUTES.settings.profile.url()
          },
          canViewBilling && {
            label: 'Affiliate Program',
            path: ROUTES.settings.affiliateProgram.url()
          }
        ])
      },
      {
        label: 'Workspace',
        items: compact([
          canViewGeneralSettings && {
            label: 'General',
            path: ROUTES.settings.general.url()
          },
          canViewTeams && {
            label: 'Team',
            path: ROUTES.settings.users.url()
          },
          hasChromeExtensionEnabled && {
            label: 'Chrome Extension',
            path: ROUTES.settings.browserExtension.url({})
          },
          SHOW_REVISION_AND_TRACKING_SETTINGS && {
            label: 'Links & Tracking',
            path: ROUTES.settings.tracking.url()
          },
          SHOW_REVISION_AND_TRACKING_SETTINGS && {
            label: 'Heatmaps',
            path: ROUTES.settings.content.url()
          },
          {
            label: 'Notifications',
            path: ROUTES.settings.notifications.url()
          },
          canViewBilling && {
            label: 'Billing & Usage',
            path: ROUTES.settings.billing.url()
          }
        ])
      }
    ])
  };
  return (
    <>
      <PageBody sideNav={sideNav} noTopPadding={noTopPadding}>
        {children}
      </PageBody>
    </>
  );
};
