import { Card, Tooltip } from '@material-ui/core';
import { isNaN, truncate } from 'lodash';
import React, { useMemo } from 'react';
import {
  Award,
  DollarSign,
  ExternalLink,
  Percent,
  ShoppingBag
} from 'react-feather';
import { Link } from 'react-router-dom';
import {
  formatChartCurrency,
  formatChartNumber
} from '../../../../../../../components/Charts/Util';
import { UNKNOWN } from '../../../../../../../components/GroupableList';
import { Loader } from '../../../../../../../components/Loader';
import {
  Currency,
  formatCurrency,
  Number
} from '../../../../../../../components/Number';
import { CurrencyCode } from '../../../../../../../domainTypes/currency';
import {
  EMPTY_EARNING,
  IEarningMinimal
} from '../../../../../../../domainTypes/performance';
import { styled } from '../../../../../../../emotion';
import { Centered } from '../../../../../../../layout/Centered';
import { getPathname } from '../../../../../../../services/url';
import {
  EarningsPerProductSoldForAdvertisersRow,
  EarningsPerPageForAdvertisersRow,
  productGrouperToEnglish,
  AdvertiserProductGrouper,
  earningsMinimalFieldtoEnglish
} from '../../../../../services/advertisers';
import { AdvertiserEarnings } from '../../../../../../../services/advertisers';
import { useRoutes } from '../../../../../../../routes';

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.palette.text.primary};
`;

const StyledA = styled('a')`
  color: ${({ theme }) => theme.palette.primary.main};
`;

const Grid = styled('div')`
  display: grid;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: ${({ theme }) => theme.spacing(2)}px;
`;

const Paragraph = styled('p')`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.grey[600]};

  strong {
    color: ${({ theme }) => theme.palette.text.primary};
  }
`;

const IconWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  text-align: center;
  margin: 0 auto;
  border-radius: 100%;
`;

const GridCard = styled(Card)`
  display: grid;
  grid-template-columns: 36px 1fr;
  grid-column-gap: ${({ theme }) => theme.spacing(2)}px;
  align-items: center;
`;

export const AdvertiserSummaryCard = ({
  currency,
  advertiserNames,
  perProductSold,
  perPage,
  totalEarnings,
  loadingEarnings,
  groupBy,
  orderBy,
  loading
}: {
  currency: CurrencyCode;
  totalEarnings: void | AdvertiserEarnings[];
  loadingEarnings: boolean;
  advertiserNames: string[];
  groupBy: AdvertiserProductGrouper;
  orderBy: keyof IEarningMinimal;
  perProductSold: void | EarningsPerProductSoldForAdvertisersRow[];
  perPage: void | EarningsPerPageForAdvertisersRow[];
  loading: boolean;
}) => {
  const advertiserName = truncate(advertiserNames.join(', '), { length: 100 });
  const { ROUTES } = useRoutes();

  const topProduct = useMemo(() => {
    if (!perProductSold) {
      return null;
    }
    return perProductSold[0];
  }, [perProductSold]);

  const topPage = useMemo(() => {
    if (!perPage) {
      return null;
    }
    return perPage.filter((p) => {
      return p.pageUrl !== UNKNOWN;
    })[0];
  }, [perPage]);

  const [minCommissionRate, maxCommissionRate] = useMemo(() => {
    if (!perProductSold) {
      return [null, null];
    }
    const min = perProductSold.reduce((a, b) => {
      const newMin = Math.min(
        a,
        b.curr.total > 0 ? b.curr.total / b.curr.saleValue.total : 0
      );
      if (isNaN(newMin)) {
        return a;
      }
      return newMin;
    }, Infinity);
    const max = perProductSold.reduce((a, b) => {
      const newMax = Math.max(
        a,
        b.curr.total > 0 ? b.curr.total / b.curr.saleValue.total : 0
      );
      if (isNaN(newMax)) {
        return a;
      }
      return newMax;
    }, -Infinity);

    const roundedMin = Math.round(min * 100) / 100;
    const roundedMax = Math.round(max * 100) / 100;

    return [roundedMin, roundedMax];
  }, [perProductSold]);

  const earnings = useMemo(() => {
    if (!totalEarnings || loadingEarnings) {
      return EMPTY_EARNING(currency);
    }
    return totalEarnings.map((e) => e.curr)[0] || EMPTY_EARNING(currency);
  }, [totalEarnings, loadingEarnings, currency]);

  if (loading || !earnings) {
    return (
      <Grid>
        <Card>
          <Centered>
            <Loader height={104} size={24} />
          </Centered>
        </Card>
        <Card>
          <Centered>
            <Loader height={104} size={24} />
          </Centered>
        </Card>
        <Card>
          <Centered>
            <Loader height={104} size={24} />
          </Centered>
        </Card>
      </Grid>
    );
  }

  return (
    <Grid>
      <GridCard>
        <IconWrapper style={{ color: '#888', backgroundColor: '#EEE' }}>
          <DollarSign size={16} />
        </IconWrapper>
        <Paragraph>
          You earned{' '}
          <Tooltip
            title={formatCurrency(earnings.total, currency)}
            placement="top"
          >
            <strong>
              {earnings ? formatChartCurrency(earnings.total, currency) : '—'}
            </strong>
          </Tooltip>{' '}
          {earnings.saleValue.total > 0 && (
            <>
              and drove{' '}
              <Tooltip
                title={formatCurrency(earnings.saleValue.total, currency)}
                placement="top"
              >
                <strong>
                  {earnings
                    ? formatChartCurrency(earnings.saleValue.total, currency)
                    : '—'}
                </strong>
              </Tooltip>{' '}
              in sales volume{' '}
            </>
          )}
          from{' '}
          {earnings && earnings.orderCount.total > 0 && (
            <>
              <Tooltip
                title={`${earnings.orderCount.total} orders`}
                placement="top"
              >
                <strong>
                  {formatChartNumber(earnings.orderCount.total)} orders
                </strong>
              </Tooltip>{' '}
              and{' '}
            </>
          )}
          <Tooltip
            title={`${earnings.quantity.total} quantity sold`}
            placement="top"
          >
            <strong>
              {formatChartNumber(earnings.quantity.total)} quantity sold
            </strong>
          </Tooltip>{' '}
          for {advertiserName} during this timeframe.
        </Paragraph>
      </GridCard>
      <GridCard>
        <IconWrapper style={{ color: '#888', backgroundColor: '#EEE' }}>
          <ShoppingBag size={16} />
        </IconWrapper>
        <Paragraph>
          {topProduct && (
            <>
              <Tooltip title={topProduct.name} placement="top">
                <strong>{truncate(topProduct.name, { length: 50 })}</strong>
              </Tooltip>{' '}
              was your top{' '}
              <strong>{productGrouperToEnglish(groupBy).toLowerCase()}</strong>{' '}
              {orderBy !== 'ct' && (
                <>
                  by{' '}
                  <strong>
                    {earningsMinimalFieldtoEnglish(orderBy).toLowerCase()}
                  </strong>{' '}
                </>
              )}
              resulting in earnings of{' '}
              <Tooltip
                title={formatCurrency(topProduct.curr.total, currency)}
                placement="top"
              >
                <strong>
                  {formatChartCurrency(topProduct.curr.total, currency)}
                </strong>
              </Tooltip>
              .
            </>
          )}{' '}
          {topPage && (
            <>
              Your best page by earnings is{' '}
              <Tooltip title={topPage.pageUrl} placement="top">
                <StyledLink
                  to={ROUTES.content.details.trends.url(topPage.pageUrl)}
                >
                  {truncate(getPathname(topPage.pageUrl), { length: 50 })}
                </StyledLink>
              </Tooltip>{' '}
              <Tooltip title="Open in a new tab" placement="bottom">
                <StyledA
                  href={topPage.pageUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ExternalLink size={14} />
                </StyledA>
              </Tooltip>
            </>
          )}
        </Paragraph>
      </GridCard>
      <GridCard>
        {earnings.saleValue.total > 0 ? (
          <>
            <IconWrapper style={{ color: '#888', backgroundColor: '#EEE' }}>
              <Percent size={16} />
            </IconWrapper>
            <Paragraph>
              Your <strong>effective commission rate</strong> with{' '}
              {advertiserName} in this timeframe is{' '}
              {earnings && (
                <strong>
                  <Number
                    n={earnings.total / earnings.saleValue.total}
                    format="percent"
                    digits={1}
                  />
                </strong>
              )}
              {minCommissionRate !== null &&
              maxCommissionRate !== null &&
              minCommissionRate !== maxCommissionRate ? (
                <>
                  , and ranges from{' '}
                  <strong>
                    <Number n={minCommissionRate * 100} digits={1} />-
                    <Number n={maxCommissionRate} digits={1} format="percent" />
                  </strong>
                  .
                </>
              ) : (
                <>.</>
              )}
            </Paragraph>
          </>
        ) : (
          <>
            <IconWrapper style={{ color: '#888', backgroundColor: '#EEE' }}>
              <Award size={16} />
            </IconWrapper>
            <Paragraph>
              Your <strong>average commission</strong> from {advertiserName} in
              this timeframe is{' '}
              {earnings && (
                <strong>
                  <Currency
                    cents={earnings.total / earnings.orderCount.total}
                    currency={currency}
                  />
                </strong>
              )}
              .
            </Paragraph>
          </>
        )}
      </GridCard>
    </Grid>
  );
};
