import React, { useMemo, useState } from 'react';
import { ChartCard } from '../../../../../../../components/Charts/ChartCard';
import {
  EarningsBarChart,
  EarningsBarChartData
} from '../../../../../../../components/Charts/EarningsBarChart';
import { CurrencyCode } from '../../../../../../../domainTypes/currency';
import { IEarningMinimal } from '../../../../../../../domainTypes/performance';
import { getStableRandomColor } from '../../../../../../../services/color';
import {
  AdvertiserProductGrouper,
  EarningsPerProductSoldForAdvertisersRow,
  productNameFieldToEnglish
} from '../../../../../services/advertisers';

export const TopProductsChartCard = ({
  ds,
  loading,
  error,
  orderBy,
  groupBy,
  currency
}: {
  ds: void | EarningsPerProductSoldForAdvertisersRow[];
  orderBy: keyof IEarningMinimal;
  groupBy: AdvertiserProductGrouper;
  loading: boolean;
  error: any;
  currency: CurrencyCode;
}) => {
  const [count] = useState(20);
  const metric = useMemo(() => {
    switch (orderBy) {
      case 'ct':
        return 'earnings';
      case 'qt':
        return 'quantity';
      case 'oct':
        return 'orderCount';
      case 'pt':
        return 'saleValue';
      default:
        return 'earnings';
    }
  }, [orderBy]);

  const data = useMemo(() => {
    if (!ds) {
      return undefined;
    }
    return ds.map<EarningsBarChartData>((d) => {
      const nameLabel = productNameFieldToEnglish(d.name, groupBy);
      return {
        key: d.name,
        name: nameLabel,
        earnings: d.curr,
        color: getStableRandomColor(d.name)
      };
    });
  }, [ds, groupBy]);

  const subheading = useMemo(() => {
    if (metric === 'earnings') {
      return 'earnings';
    }
    if (metric === 'saleValue') {
      return 'sales volume';
    }
    if (metric === 'quantity') {
      return 'quantity';
    }
    if (metric === 'orderCount') {
      return 'order count';
    }
    return undefined;
  }, [metric]);

  return (
    <ChartCard
      heading="Top products sold"
      subheading={`By ${subheading}`}
      size="small"
      padding="dense"
      centered
    >
      <EarningsBarChart
        data={data}
        loading={loading}
        currency={currency}
        count={count}
        metric={metric}
        animate={false}
        aspect={1.05}
      />
    </ChartCard>
  );
};
