import { Tooltip, Typography } from '@material-ui/core';
import { capitalize, keyBy, truncate } from 'lodash';
import React, { useMemo, useState } from 'react';
import { Info } from 'react-feather';
import { DeviceIcon } from '../../../../components/DeviceIcon';
import {
  Grouper,
  RowsRenderer,
  Sorter
} from '../../../../components/GroupableList';
import { Currency, Number } from '../../../../components/Number';
import { PartnerLogo } from '../../../../components/PartnerLogo';
import { IColumn } from '../../../../components/Table/Column';
import { Dash } from '../../../../components/Table/CountCell';
import {
  ClickhouseTransaction,
  TransactionsField
} from '../../../../domainTypes/analytics_v2';
import { CurrencyCode } from '../../../../domainTypes/currency';
import { PARTNERS } from '../../../../domainTypes/partners';
import { IEarning, SaleStatus } from '../../../../domainTypes/performance';
import { styled } from '../../../../emotion';
import { debuggedShallowEqual } from '../../../../services/debug';
import { useHasPayouts } from '../../../../services/payouts';
import { pluralize } from '../../../../services/pluralize';
import {
  SIMPLE_DATE,
  sqlTimestampToMoment,
  sqlTimestampToMomentOrNull
} from '../../../../services/time';
import { SalesStatusCompact } from '../SalesStatus';
import { ReferringLink } from './ReferringLink';
import { SalesDrawerV2 } from './SalesDrawer';
import { TrackingLabelV2 } from './TrackingLabel';
import { ITrackedConvertedSaleWithProduct } from './types';

const HEIGHT = 500;

const PARTNERS_BY_KEY = keyBy(PARTNERS, (p) => p.key);

const PartnerProductWrapper = styled('div')`
  display: flex;
  align-items: center;
  width: 100%;
  word-break: break-all;

  div:first-of-type {
    margin-right: 12px;
  }
`;

const PartnerProductLink: React.FC<{
  d: ClickhouseTransaction;
}> = ({ d }) => {
  const partnerProductName = arrayToString(d.partner_product_name);
  const text = document.createElement('textarea');
  const isCpcWithNoProductName = d.sale_type === 'cpc' && !partnerProductName;

  text.innerHTML =
    partnerProductName ||
    (d.sale_type === 'cpc' ? 'CPC commission' : 'Not provided');

  const value = text.value;
  const partner = PARTNERS_BY_KEY[d.pk || ''];

  if (isCpcWithNoProductName) {
    return (
      <PartnerProductWrapper>
        {partner && <PartnerLogo partner={partner} />}{' '}
        {value ? truncate(value, { length: 80 }) : ''}
        {isCpcWithNoProductName && (
          <Tooltip
            placement="top"
            title="CPC commissions do not include product names"
          >
            <span
              style={{
                display: 'flex',
                marginLeft: 6,
                color: '#999',
                position: 'relative',
                top: '1px'
              }}
            >
              <Info size={14} />
            </span>
          </Tooltip>
        )}
      </PartnerProductWrapper>
    );
  }

  return (
    <Tooltip title={value} placement="top-start">
      <PartnerProductWrapper>
        {partner && <PartnerLogo partner={partner} />}{' '}
        {value ? truncate(value, { length: 80 }) : ''}
      </PartnerProductWrapper>
    </Tooltip>
  );
};

const SaleDateColumn = ({
  d,
  tz
}: {
  d: ClickhouseTransaction;
  tz: string;
}) => {
  const { completion_date, sale_date } = d;
  const completionM = sqlTimestampToMomentOrNull(completion_date, tz);
  const saleM = sqlTimestampToMoment(sale_date, tz);
  if (!completionM) {
    return <div>{saleM.format('MMM D, YYYY HH:mm:ss')}</div>;
  }

  const isSameTimestamp = completionM && saleM.isSame(completionM);
  const daysBetween = completionM.diff(saleM, 'days');

  if (isSameTimestamp) {
    return <div>{saleM.format(SIMPLE_DATE)}</div>;
  }

  return (
    <div>
      <div>{saleM.format(SIMPLE_DATE)}</div>
      <Typography variant="caption" component="p" color="textSecondary">
        {!isSameTimestamp &&
          `${daysBetween} ${pluralize('day', daysBetween)} ahead`}
      </Typography>
    </div>
  );
};

export type ColumnName =
  | 'status'
  | 'partnerKey'
  | 'trackingLabel'
  | 'clickedLink'
  | 'pageUrl'
  | 'commission'
  | 'origin'
  | 'trackingId'
  | 'coupon'
  | 'subid1'
  | 'subid2'
  | 'subid3'
  | 'subid4'
  | 'subid5'
  | 'subid6'
  | 'quantity'
  | 'completionDate'
  | 'clickDate'
  | 'commissionPercent'
  | 'advertiser'
  | 'advertiserId'
  | 'orderId'
  | 'partnerProductId'
  | 'partnerProductName'
  | 'price'
  | 'device'
  | 'saleDate';

export type TransactionColumn = IColumn<
  ClickhouseTransaction,
  ColumnName,
  { hasPayouts: boolean; tz: string; currency: string }
> & {
  requiredFields: TransactionsField[];
};

const arrayToString = (arr?: string[]) => (arr || []).join(', ');

export const TRANSACTION_COLUMNS: TransactionColumn[] = [];

export const COLUMNS: TransactionColumn[] = [
  {
    key: 'status',
    head: () => 'Status',
    cell: (d, o) => {
      const { sale_status, payout_status } = d;
      if (!sale_status) {
        return null;
      }
      return (
        <SalesStatusCompact
          status={capitalize(sale_status) as SaleStatus}
          payoutStatus={
            o.hasPayouts && payout_status ? payout_status : undefined
          }
        />
      );
    },
    align: 'left',
    width: 24,
    flexGrow: 1,

    requiredFields: ['sale_status', 'payout_status']
  },
  {
    key: 'orderId',
    head: () => 'Order ID',
    cell: (d) => d.order_id || '',
    align: 'left',
    width: 100,
    flexGrow: 1,
    requiredFields: ['order_id']
  },
  {
    key: 'partnerProductId',
    head: () => 'Product ID or SKU',
    cell: (d) => arrayToString(d.partner_product_id),
    align: 'left',
    width: 100,
    flexGrow: 1,
    requiredFields: ['partner_product_id']
  },
  {
    key: 'partnerProductName',
    head: () => 'Product name or description',
    cell: (d) => <PartnerProductLink d={d} />,
    align: 'left',
    width: 300,
    flexGrow: 5,
    requiredFields: ['partner_product_name', 'pk', 'sale_type']
  },
  {
    key: 'advertiser',
    head: () => 'Advertiser',
    cell: (d) => d.advertiser_name || '',
    align: 'left',
    width: 120,
    flexGrow: 2,
    requiredFields: ['advertiser_name']
  },
  {
    key: 'advertiserId',
    head: () => 'Advertiser ID',
    cell: (d) => d.advertiser_id || '',
    align: 'left',
    width: 120,
    flexGrow: 2,
    requiredFields: ['advertiser_id']
  },
  {
    key: 'saleDate',
    head: () => 'Date',
    cell: (d, o) => <SaleDateColumn d={d} tz={o.tz} />,
    align: 'left',
    width: 100,
    flexGrow: 2,
    requiredFields: ['sale_date', 'completion_date']
  },
  {
    key: 'completionDate',
    head: () => 'Completion Date',
    cell: (d, o) =>
      sqlTimestampToMomentOrNull(d.completion_date, o.tz)?.format(
        'MMM D, YYYY HH:mm:ss'
      ),
    align: 'left',
    width: 100,
    flexGrow: 2,
    requiredFields: ['completion_date']
  },
  {
    key: 'clickDate',
    head: () => 'Click Date',
    cell: (d, o) =>
      sqlTimestampToMomentOrNull(d.click_date, o.tz)?.format(
        'MMM D, YYYY HH:mm:ss'
      ),
    align: 'left',
    width: 100,
    flexGrow: 2,
    requiredFields: ['click_date']
  },
  {
    key: 'trackingLabel',
    head: () => 'Referrer',
    cell: (d) => <TrackingLabelV2 d={d} />,
    align: 'left',
    width: 200,
    flexGrow: 5,
    requiredFields: [
      'space_id',
      'tracking_id',
      'tracking_label',
      'coupon',
      'page_url_origin',
      'channel_id',
      'link_id',
      'label_rule_id',
      'page_url',
      'page_url_origin'
    ]
  },
  {
    key: 'origin',
    head: () => 'Origin domain',
    cell: (d) => d.page_url_origin,
    align: 'left',
    width: 200,
    flexGrow: 5,
    requiredFields: ['page_url_origin']
  },
  {
    key: 'pageUrl',
    head: () => 'Referring page',
    cell: (d) => d.page_url,
    align: 'left',
    width: 200,
    flexGrow: 5,
    requiredFields: ['page_url']
  },
  {
    key: 'clickedLink',
    head: () => 'Clicked link',
    cell: (d) =>
      d.link_id && <ReferringLink spaceId={d.space_id} productId={d.link_id} />,
    align: 'left',
    width: 200,
    flexGrow: 3,
    requiredFields: ['space_id', 'link_id']
  },
  {
    key: 'coupon',
    head: () => 'Coupon',
    cell: (d) => arrayToString(d.coupon),
    align: 'left',
    width: 200,
    flexGrow: 3,
    requiredFields: ['coupon']
  },
  {
    key: 'device',
    head: () => 'Device',
    cell: (d) => {
      const { device = 'unknown' } = d;
      if (device === 'unknown') {
        return null;
      }
      return (
        <Tooltip title={`Sale occurred on ${device}`} placement="top">
          <div>
            <DeviceIcon size={18} device={device} ignoreUnknown />
          </div>
        </Tooltip>
      );
    },
    align: 'center',
    width: 48,
    requiredFields: ['device']
  },
  {
    key: 'commissionPercent',
    head: () => 'Commission Rate',
    cell: (d) => {
      // This is currently a workaround, as we have a rounding error for commission percent
      // in Clickhouse. So we recalculate it here
      if (!d.comm_percent || !d.commission) {
        return <Dash />;
      }

      const trueCommPercent = d.commission / (d.revenue || d.price || 0);

      return <Number n={trueCommPercent} format="percent" digits={2} />;
    },
    align: 'right',
    width: 100,
    flexGrow: 1,
    requiredFields: ['comm_percent', 'commission', 'revenue', 'price']
  },
  {
    key: 'trackingId',
    head: () => 'Tracking',
    cell: (d) =>
      d.tracking_label ? (
        <Tooltip title={d.tracking_label} placement="top">
          <div>{d.tracking_label}</div>
        </Tooltip>
      ) : null,
    align: 'left',
    width: 100,
    flexGrow: 1,
    requiredFields: ['tracking_label']
  },
  {
    key: 'subid1',
    head: () => 'SubID 1',
    cell: (d) => d.sub_id_01 || null,
    align: 'left',
    width: 100,
    flexGrow: 1,
    requiredFields: ['sub_id_01']
  },
  {
    key: 'subid2',
    head: () => 'SubID 2',
    cell: (d) => d.sub_id_02 || null,
    align: 'left',
    width: 100,
    flexGrow: 1,
    requiredFields: ['sub_id_02']
  },
  {
    key: 'subid3',
    head: () => 'SubID 3',
    cell: (d) => d.sub_id_03 || null,
    align: 'left',
    width: 100,
    flexGrow: 1,
    requiredFields: ['sub_id_03']
  },
  {
    key: 'subid4',
    head: () => 'SubID 4',
    cell: (d) => d.sub_id_04 || null,
    align: 'left',
    width: 100,
    flexGrow: 1,
    requiredFields: ['sub_id_04']
  },
  {
    key: 'subid5',
    head: () => 'SubID 5',
    cell: (d) => d.sub_id_05 || null,
    align: 'left',
    width: 100,
    flexGrow: 1,
    requiredFields: ['sub_id_05']
  },
  {
    key: 'quantity',
    head: () => 'Quantity',
    cell: (d) => d.quantity || null,
    align: 'right',
    width: 100,
    flexGrow: 1,
    requiredFields: ['quantity']
  },
  {
    key: 'price',
    head: () => 'Sales volume',
    cell: (d, o) => {
      const value = d.revenue || d.price;
      return value && <Currency cents={value} currency={o.currency} />;
    },
    align: 'right',
    width: 100,
    flexGrow: 1,
    requiredFields: ['price', 'revenue']
  },
  {
    key: 'commission',
    head: () => 'Earnings',
    cell: (d, o) => (
      <>
        <Currency cents={d.commission} currency={o.currency} />
      </>
    ),
    align: 'right',
    width: 100,
    flexGrow: 1,
    requiredFields: ['commission']
  }
];

export const DEFAULT_VISIBLE_COLUMNS = COLUMNS.filter(
  (h) =>
    ![
      'completionDate',
      'clickDate',
      'orderId',
      'advertiserId',
      'partnerProductId',
      'clickedLink',
      'pageUrl',
      'saleDate',
      //'device',
      'trackingId',
      'subid1',
      'subid2',
      'subid3',
      'subid4',
      'subid5',
      'quantity',
      'price',
      'origin',
      'coupon',
      'commissionPercent'
    ].includes(h.key)
).map((h) => h.key);

const ROW_HEIGHT = 40;

export type GrouperType =
  | 'date'
  | 'page'
  | 'origin'
  | 'productClicked'
  | 'productSold'
  | 'device'
  | 'partner'
  | 'subId'
  | 'advertiser'
  | string;

export type SalesGrouper = Grouper<
  GrouperType,
  IEarning,
  ITrackedConvertedSaleWithProduct
>;

const ROW_TO_KEY = (s: ClickhouseTransaction) => s.id;

export const DEFAULT_SALESLIST_PAGE_SIZE = 100;

// Use this sorter for
const IDENTITY_SORTER: Sorter<any, any> = {
  key: 'identity',
  label: '',
  groups: { sort: () => '', dir: 'asc' },
  items: { sort: () => '', dir: 'asc' }
};

export const SalesListV2: React.FC<{
  ds: ClickhouseTransaction[];
  visibleColumns?: Set<ColumnName>;
  height?: number;
  currency: CurrencyCode;
  tz: string;
}> = React.memo(
  ({
    ds,
    height = HEIGHT,
    visibleColumns = new Set(DEFAULT_VISIBLE_COLUMNS),
    currency,
    tz
  }) => {
    const hasPayouts = useHasPayouts();
    const columns = useMemo(
      () =>
        visibleColumns
          ? COLUMNS.filter((c) => visibleColumns.has(c.key))
          : COLUMNS,
      [visibleColumns]
    );

    const [
      selectedSale,
      setSelectedSale
    ] = useState<ClickhouseTransaction | null>(null);

    const otherProps = useMemo(() => {
      return { hasPayouts, currency, tz };
    }, [hasPayouts, currency, tz]);

    return (
      <>
        <RowsRenderer
          variant="contained"
          rows={ds}
          columns={columns}
          sorter={IDENTITY_SORTER}
          renderHead={true}
          chunkSize={30}
          rootMargin="400px"
          otherProps={otherProps}
          rowHeight={ROW_HEIGHT}
          rowToKey={ROW_TO_KEY}
          onRowClick={(d) => {
            setSelectedSale(d);
          }}
        />

        <SalesDrawerV2
          sale={selectedSale}
          open={selectedSale !== null}
          onClose={() => {
            setSelectedSale(null);
          }}
          currency={currency}
          tz={tz}
        />
      </>
    );
  },
  debuggedShallowEqual('SalesList')
);
