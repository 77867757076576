import { PageBody } from '../../../../layout/PageBody';
import { Helmet } from 'react-helmet';
import { useRoutes } from '../../../../routes';
import { newSideNavProps } from '../../services/detailsSideNav';
import { getPathname } from '../../../../services/url';
import React from 'react';
import {
  PageToolbar,
  PageToolbarSection,
  PageToolbarTitle
} from '../../../../layout/PageToolbar';
import { DetailsPageTitle } from '../../components/DetailsPageTitle';
import { TimeframeWithRevision } from '../../../../components/analytics_v2/TimeframeWithRevisions';
import { CountriesTable } from './CountriesTable';
import Typography from '@material-ui/core/Typography';
import { css } from '../../../../emotion';
import { DevicesTable } from './DevicesTable';

const TableSection: React.FC<{ header: string }> = ({ header, children }) => (
  <div
    className={css((t) => ({
      marginBottom: t.spacing(8)
    }))}
  >
    <Typography
      variant="body2"
      component="p"
      className={css((t) => ({
        fontWeight: 'bold',
        marginBottom: t.spacing(2)
      }))}
    >
      {header}
    </Typography>
    {children}
  </div>
);

export const DetailsAudienceBody = ({ url }: { url: string }) => {
  const { ROUTES } = useRoutes();
  const sideNav = newSideNavProps(ROUTES, url);
  return (
    <PageBody sideNav={sideNav} noTopPadding>
      <Helmet>
        <title>{getPathname(url)} | Affilimate</title>
      </Helmet>
      <PageToolbar>
        <PageToolbarTitle flex={2}>
          <DetailsPageTitle url={url} />
        </PageToolbarTitle>

        <PageToolbarSection flex={2} justifyContent="flex-end">
          <TimeframeWithRevision url={url} />
        </PageToolbarSection>
      </PageToolbar>
      <TableSection header="Top geographies">
        <CountriesTable url={url} />
      </TableSection>
      <TableSection header="Top devices">
        <DevicesTable url={url} />
      </TableSection>
    </PageBody>
  );
};
