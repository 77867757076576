import React, { useMemo } from 'react';
import {
  ITimeframeOption,
  TimeframePicker,
  TimeframePickerDense,
  useStandardOptions,
  useTimeframeFromUrl
} from '../../../../components/TimeframePicker';
import { isSameTimeframe, Timeframe } from '../../../../domainTypes/analytics';
import { IPageRevision } from '../../../../domainTypes/page';
import { ISpace } from '../../../../domainTypes/space';
import { styled } from '../../../../emotion';
import { useRoutes } from '../../../../routes';
import {
  getRevisionTimeframe,
  isSameRevision
} from '../../../../services/page/revision';
import {
  RevisionSelector,
  RevisionSelectorDense,
  useRevisionFromUrl
} from '../RevisionSelector';

type Value = {
  revision: IPageRevision | null;
  syncTimeframe: boolean;
  timeframe: Timeframe;
};

type Props = {
  value: Value;
  options: {
    revisions: IPageRevision[];
    timeframes: ITimeframeOption[];
  };
  onChange: (nextValue: Value) => void;
};

const Container = styled('div')`
  display: flex;
  align-items: center;
  > * {
    margin-left: ${(p) => p.theme.spacing(2)}px;
  }
`;

const ContainerDense = styled('div')`
  display: flex;
  align-items: flex-start;
  > * {
    margin-left: ${(p) => p.theme.spacing(1)}px;
  }
`;

export type FieldNames = {
  revision: string;
  timeframe: string;
  syncTimeframe: string;
};

export const useStandardSelectorStateFromUrl = (
  space: ISpace,
  revisions: IPageRevision[],
  fieldNames: FieldNames = {
    timeframe: 'timeframe',
    revision: 'revision',
    syncTimeframe: 'syncTimeframe'
  }
): Props => {
  const { changeQuery } = useRoutes();
  const {
    value: revValue,
    options: revOptions,
    toQueryParams: toRevQuery
  } = useRevisionFromUrl(revisions, fieldNames);
  const { options: tfOptions, defaultOption } = useStandardOptions();
  // eslint-disable-next-line
  const [tf, setTimeframe, toTimeframeQuery] = useTimeframeFromUrl(
    defaultOption.value,
    fieldNames.timeframe
  );

  const { revision, syncTimeframe } = revValue;
  const timeframe = useMemo(
    () =>
      revision && syncTimeframe
        ? getRevisionTimeframe(space, revision, revisions)
        : tf,
    [tf, revision, revisions, space, syncTimeframe]
  );

  return {
    value: {
      revision,
      syncTimeframe,
      timeframe
    },
    options: {
      timeframes: tfOptions,
      revisions: revOptions
    },
    onChange: (v) => {
      let query = {};
      if (
        !isSameRevision(revision, v.revision) ||
        syncTimeframe !== v.syncTimeframe
      ) {
        query = {
          ...query,
          ...toRevQuery({
            revision: v.revision,
            syncTimeframe: v.syncTimeframe,
            timeframe: v.timeframe
          })
        };
      }
      if (!isSameTimeframe(timeframe, v.timeframe)) {
        query = {
          ...query,
          ...toTimeframeQuery(v.timeframe),
          ...toRevQuery({
            revision: v.revision,
            syncTimeframe: false,
            timeframe: v.timeframe
          })
        };
      }
      changeQuery(query);
    }
  };
};

export const Selectors: React.FC<Props> = ({ value, onChange, options }) => {
  return (
    <Container>
      {value.revision && (
        <RevisionSelector
          value={{
            revision: value.revision,
            syncTimeframe: value.syncTimeframe,
            timeframe: value.timeframe
          }}
          options={options.revisions}
          onChange={(s) => onChange({ ...value, ...s })}
          tz={value.timeframe.tz}
        />
      )}
      <TimeframePicker
        value={value.timeframe}
        options={options.timeframes}
        onChange={(tf) => onChange({ ...value, timeframe: tf })}
      />
    </Container>
  );
};

export const SelectorsDense: React.FC<Props> = ({
  value,
  onChange,
  options
}) => {
  return (
    <ContainerDense>
      {value.revision && (
        <RevisionSelectorDense
          value={{
            revision: value.revision,
            timeframe: value.timeframe,
            syncTimeframe: value.syncTimeframe
          }}
          options={options.revisions}
          onChange={(s) => onChange({ ...value, ...s })}
          tz={value.timeframe.tz}
        />
      )}
      <TimeframePickerDense
        value={value.timeframe}
        options={options.timeframes}
        onChange={(tf) => onChange({ ...value, timeframe: tf })}
      />
    </ContainerDense>
  );
};
