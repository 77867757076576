import React, { useMemo } from 'react';
import { css } from '../../../../../../../emotion';
import { Paper, Tooltip, Typography } from '@material-ui/core';
import {
  RowsRenderer,
  useColumnsQueryParam,
  useSortQueryParam,
  ItemSorters,
  ROW_HEIGHTS
} from '../../../../../../../components/GroupableList';
import { EarningsPerPageForAdvertisersRow } from '../../../../../services/advertisers';
import { IColumn } from '../../../../../../../components/Table/Column';
import {
  DEFAULT_OFFSET,
  DEFAULT_TOOLBAR_HEIGHT
} from '../../../../../../../layout/PageToolbar';
import { CountCell } from '../../../../../../../components/Table';
import { Dash } from '../../../../../../../components/Table/CountCell';
import { addOneEarningToAnother } from '../../../../../../../domainTypes/performance';
import { getPathname } from '../../../../../../../services/url';
import { getDomainName } from '../../../../../../../services/pages';
import { getAov, getAvgComm } from '../../../../../../../services/analytics';
import { useRoutes } from '../../../../../../../routes';

type ColumnName =
  | 'pageUrl'
  | 'earnings'
  | 'saleValue'
  | 'orderCount'
  | 'avgPrice'
  | 'avgComm'
  | 'aov'
  | 'quantity';

type Column = IColumn<EarningsPerPageForAdvertisersRow, ColumnName, undefined>;

const COLUMNS: Column[] = [
  {
    key: 'pageUrl',
    head: () => 'Page URL',
    cell: (p) => {
      const pathName = getPathname(p.pageUrl);
      return (
        <>
          <Tooltip placement="top-start" title={pathName}>
            <Typography
              className={css((t) => ({
                fontSize: '14px',
                color: t.palette.primary.main,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap'
              }))}
            >
              {pathName}
            </Typography>
          </Tooltip>
          <Typography
            className={css((_) => ({
              fontSize: '11px',
              fontWeight: 700,
              color: '#acacb9'
            }))}
          >
            {getDomainName(p.pageUrl)}
          </Typography>
        </>
      );
    },
    align: 'left',
    sortable: true,
    width: 300,
    flexGrow: 2
  },
  {
    key: 'orderCount',
    head: () => 'Orders',
    cell: (p) => {
      if (p.prev.orderCount.total === 0 && p.curr.orderCount.total === 0) {
        return <Dash />;
      }
      return (
        <CountCell
          before={p.prev.orderCount.total}
          after={p.curr.orderCount.total}
          compare={true}
        />
      );
    },
    align: 'right',
    sortable: true,
    width: 100,
    flexGrow: 2
  },
  {
    key: 'quantity',
    head: () => 'Quantity',
    cell: (p) => {
      return (
        <CountCell
          before={p.prev.quantity.total}
          after={p.curr.quantity.total}
          dashWhenAllZero
          compare={true}
        />
      );
    },
    align: 'right',
    sortable: true,
    width: 100,
    flexGrow: 2
  },
  {
    key: 'avgPrice',
    head: () => 'Avg price',
    cell: (p) => {
      const before =
        p.prev.quantity.total > 0
          ? p.prev.saleValue.total / p.prev.quantity.total
          : 0;
      const after =
        p.curr.quantity.total > 0
          ? p.curr.saleValue.total / p.curr.quantity.total
          : 0;

      return (
        <CountCell
          before={before}
          after={after}
          compare={true}
          currency={p.curr.currency}
          dashWhenAllZero
        />
      );
    },
    align: 'right',
    sortable: false,
    width: 100,
    flexGrow: 2
  },
  {
    key: 'avgComm',
    head: () => 'Avg comm',
    cell: (p) => {
      const prevAvgComm = getAvgComm(p.prev.quantity.total, p.prev.total);
      const currAvgComm = getAvgComm(p.curr.quantity.total, p.curr.total);

      return (
        <CountCell
          before={prevAvgComm}
          after={currAvgComm}
          compare={true}
          currency={p.curr.currency}
          dashWhenAllZero
        />
      );
    },
    align: 'right',
    sortable: false,
    width: 100,
    flexGrow: 2
  },
  {
    key: 'aov',
    head: () => 'AOV',
    cell: (p) => {
      const currAov = getAov(p.curr.orderCount.total, p.curr.saleValue.total);
      const prevAov = getAov(p.prev.orderCount.total, p.prev.saleValue.total);

      if (currAov === 0 && prevAov === 0) {
        return <Dash />;
      }
      return (
        <CountCell
          before={prevAov}
          after={currAov}
          currency={p.curr.currency}
          compare={true}
        />
      );
    },
    align: 'right',
    sortable: true,
    width: 100,
    flexGrow: 2
  },
  {
    key: 'earnings',
    head: () => 'Earnings',
    cell: (p) => {
      if (p.prev.total === 0 && p.curr.total === 0) {
        return <Dash />;
      }
      return (
        <CountCell
          before={p.prev.total}
          after={p.curr.total}
          currency={p.curr.currency}
          compare={true}
        />
      );
    },
    align: 'right',
    sortable: true,
    width: 100,
    flexGrow: 2
  },
  {
    key: 'saleValue',
    head: () => 'Sales volume',
    cell: (p) => {
      if (p.prev.saleValue.total === 0 && p.curr.saleValue.total === 0) {
        return <Dash />;
      }
      return (
        <CountCell
          before={p.prev.saleValue.total}
          after={p.curr.saleValue.total}
          currency={p.curr.currency}
          compare={true}
        />
      );
    },
    align: 'right',
    sortable: true,
    width: 100,
    flexGrow: 2
  }
];

const DEFAULT_COLUMNS = [
  'pageUrl',
  'earnings',
  'saleValue',
  'orderCount',
  'avgPrice',
  'quantity',
  'aov',
  'avgComm'
];

const SORTERS: ItemSorters<EarningsPerPageForAdvertisersRow> = {
  pageUrl: {
    key: 'pageUrl',
    items: { sort: (p) => p.pageUrl, dir: 'asc' }
  },
  earnings: {
    key: 'earnings',
    items: { sort: (p) => p.curr.total, dir: 'desc' }
  },
  saleValue: {
    key: 'saleValue',
    items: { sort: (p) => p.curr.saleValue.total, dir: 'desc' }
  },
  quantity: {
    key: 'quantity',
    items: { sort: (p) => p.curr.quantity.total, dir: 'desc' }
  }
};

const ROW_TO_KEY = (p: EarningsPerPageForAdvertisersRow) => `${p.pageUrl}`;

export const AdvertiserDetailsTopPagesTable = ({
  ds,
  loading,
  search,
  error
}: {
  ds: void | EarningsPerPageForAdvertisersRow[];
  loading: boolean;
  search: string | null;
  error: any;
}) => {
  const { ROUTES } = useRoutes();
  const [columnNames] = useColumnsQueryParam('columns', DEFAULT_COLUMNS);
  const columns = useMemo(
    () =>
      columnNames ? COLUMNS.filter((c) => columnNames.has(c.key)) : COLUMNS,
    [columnNames]
  );
  const [[sorter, direction], setSort] = useSortQueryParam('sort', SORTERS);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!ds) {
    return <div>No data</div>;
  }

  console.log('pages table', ds);

  // Re-group the data using only the pageUrl's origin and path name
  const grouped = ds.reduce((acc, curr) => {
    try {
      const url = new URL(curr.pageUrl);
      const key = `${url.origin}${url.pathname}`;
      const prev = acc.get(key);
      if (prev) {
        prev.prev = addOneEarningToAnother(prev.prev, curr.prev);
        prev.curr = addOneEarningToAnother(prev.curr, curr.curr);
      } else {
        acc.set(key, {
          pageUrl: key,
          prev: curr.prev,
          curr: curr.curr
        });
      }
      return acc;
    } catch (e) {
      return acc;
    }
  }, new Map<string, EarningsPerPageForAdvertisersRow>());

  const rows = Array.from(grouped.values()).filter((r) => {
    if (!search) {
      return true;
    }
    return r.pageUrl.toLowerCase().includes(search.toLowerCase());
  });

  return (
    <Paper>
      <RowsRenderer
        columns={columns}
        sorter={sorter || SORTERS.earnings}
        sortDirection={direction}
        onHeadClick={(c, dir) => setSort([SORTERS[c.key] || null, dir])}
        rows={rows}
        variant="contained"
        renderHead={true}
        otherProps={undefined}
        headProps={{
          sticky: true,
          offset: DEFAULT_OFFSET + DEFAULT_TOOLBAR_HEIGHT
        }}
        rowToHref={(d) => ROUTES.content.details.trends.url(d.pageUrl)}
        rowToKey={ROW_TO_KEY}
        rowHeight={ROW_HEIGHTS.dense}
      />
    </Paper>
  );
};
