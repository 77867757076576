export type UserLevelScope =
  | 'api_tokens.view'
  | 'api_tokens.create'
  | 'api_tokens.delete'
  | 'channels.view'
  | 'channels.edit'
  | 'channels.delete'
  | 'channels.create'
  | 'custom_dimensions.view'
  | 'custom_dimensions.edit'
  | 'custom_dimensions.delete'
  | 'custom_dimensions.create'
  | 'campaigns.view'
  | 'campaigns.create'
  | 'campaigns.edit'
  | 'campaigns.delete'
  | 'settings.view'
  | 'settings.edit'
  | 'settings.tracking.view'
  | 'settings.tracking.edit'
  | 'emails.daily_earnings_report.edit'
  | 'billing.view'
  | 'billing.edit'
  | 'dashboard.view'
  | 'realtime.view'
  | 'docs.view'
  | 'domains.create'
  | 'domains.delete'
  | 'domains.edit'
  | 'domains.view'
  | 'integrations.create'
  | 'integrations.delete'
  | 'integrations.delete_report'
  | 'integrations.edit'
  | 'integrations.toggle_smart_labels'
  | 'integrations.trigger_pull'
  | 'integrations.upload_report'
  | 'integrations.view'
  | 'link_checker.amazon.api_keys.edit'
  | 'link_checker.amazon.view'
  | 'link_checker.schedule.edit'
  | 'link_checker.settings.view'
  | 'link_generator.use'
  | 'links.view'
  | 'links.create'
  | 'links.delete'
  | 'links.edit'
  | 'links.export'
  | 'pages.view'
  | 'pages.edit'
  | 'pages.metrics.clicks'
  | 'pages.metrics.ctr'
  | 'pages.metrics.epc'
  | 'pages.metrics.impressions'
  | 'pages.metrics.items_sold'
  | 'pages.metrics.pageviews'
  | 'pages.metrics.revenue'
  | 'pages.metrics.rpm'
  | 'pages.metrics.served'
  | 'rates.view'
  | 'reports.advertisers.view'
  | 'reports.advertisers.export'
  | 'reports.rates.view'
  | 'reports.payouts.view'
  | 'reports.content.view'
  | 'reports.content.export'
  | 'reports.traffic_sources.view'
  | 'reports.utms.view'
  | 'reports.custom_dimensions.view'
  | 'reports.links.view'
  | 'reports.platforms.view'
  | 'reports.transactions.export'
  | 'reports.transactions.view'
  | 'reports.tags.view'
  | 'reports.channels.view'
  | 'revisions.view'
  | 'revisions.edit'
  | 'revisions.toggle_ignore'
  | 'revisions.notes.edit'
  | 'rules.create'
  | 'rules.delete'
  | 'rules.view'
  | 'scans.run'
  | 'scans.view'
  | 'scans.settings.view'
  | 'scans.settings.edit'
  | 'tags.create'
  | 'tags.edit'
  | 'tags.delete'
  | 'tags.add_or_remove'
  | 'tags.view'
  | 'transactions.view'
  | 'transactions.create'
  | 'transactions.delete'
  | 'payouts.view'
  | 'users.create'
  | 'users.delete'
  | 'users.edit'
  | 'users.view';

export const ALL_USER_LEVEL_SCOPES: UserLevelScope[] = [
  'api_tokens.view',
  'api_tokens.create',
  'api_tokens.delete',
  'channels.view',
  'channels.edit',
  'channels.delete',
  'channels.create',
  'custom_dimensions.view',
  'custom_dimensions.edit',
  'custom_dimensions.delete',
  'custom_dimensions.create',
  'campaigns.view',
  'campaigns.create',
  'campaigns.edit',
  'campaigns.delete',
  'emails.daily_earnings_report.edit',
  'billing.view',
  'billing.edit',
  'dashboard.view',
  'realtime.view',
  'docs.view',
  'domains.create',
  'domains.delete',
  'domains.edit',
  'domains.view',
  'integrations.create',
  'integrations.delete',
  'integrations.delete_report',
  'integrations.edit',
  'integrations.toggle_smart_labels',
  'integrations.trigger_pull',
  'integrations.upload_report',
  'integrations.view',
  'link_checker.amazon.api_keys.edit',
  'link_checker.amazon.view',
  'link_checker.schedule.edit',
  'link_checker.settings.view',
  'link_generator.use',
  'links.create',
  'links.delete',
  'links.edit',
  'links.export',
  'links.view',
  'pages.view',
  'pages.edit',
  'pages.metrics.clicks',
  'pages.metrics.ctr',
  'pages.metrics.epc',
  'pages.metrics.impressions',
  'pages.metrics.items_sold',
  'pages.metrics.pageviews',
  'pages.metrics.revenue',
  'pages.metrics.rpm',
  'pages.metrics.served',
  'rates.view',
  'reports.advertisers.view',
  'reports.advertisers.export',
  'reports.rates.view',
  'reports.payouts.view',
  'reports.content.view',
  'reports.content.export',
  'reports.traffic_sources.view',
  'reports.utms.view',
  'reports.custom_dimensions.view',
  'reports.links.view',
  'reports.platforms.view',
  'reports.transactions.export',
  'reports.transactions.view',
  'reports.tags.view',
  'reports.channels.view',
  'revisions.view',
  'revisions.edit',
  'revisions.toggle_ignore',
  'revisions.notes.edit',
  'rules.create',
  'rules.delete',
  'rules.view',
  'scans.run',
  'scans.view',
  'scans.settings.view',
  'scans.settings.edit',
  'settings.view',
  'settings.edit',
  'settings.tracking.view',
  'settings.tracking.edit',
  'tags.create',
  'tags.edit',
  'tags.delete',
  'tags.add_or_remove',
  'tags.view',
  'transactions.view',
  'transactions.create',
  'transactions.delete',
  'payouts.view',
  'users.create',
  'users.delete',
  'users.edit',
  'users.view'
];

// We have several fixed roles that are NOT saved on a db level but always
// default to whatever we have in code. This grants us flexibility in updating
// them while still allowing for custom roles.
export type RoleId =
  | 'OWNER'
  | 'SUPER_ADMIN'
  | 'ADMIN'
  | 'VIEWER'
  | 'TOOLS_USER'
  | string;

export const ALL_ROLES: RoleId[] = [
  'OWNER',
  'SUPER_ADMIN',
  'ADMIN',
  'VIEWER',
  'TOOLS_USER'
];

export const PLANS_WITH_TEAMS = [
  'CUSTOM_1M_USD',
  'CUSTOM_1Y_USD',
  'STANDARD_FOREVER_FREE',
  'SOLO_CREATOR_1M_USD',
  'SOLO_CREATOR_1Y_USD',
  'PORTFOLIO_1M_USD',
  'PORTFOLIO_1Y_USD',
  'PORTFOLIO_1Y_USD2',
  'MEDIA_COMPANY_1M_USD',
  'MEDIA_COMPANY_1Y_USD',
  'BUSINESS_250K_1M_USD',
  'BUSINESS_250K_1Y_USD',
  'BUSINESS_500K_1M_USD',
  'BUSINESS_500K_1Y_USD',
  'BUSINESS_750K_1M_USD',
  'BUSINESS_750K_1Y_USD',
  'BUSINESS_1MIL_1M_USD',
  'BUSINESS_1MIL_1Y_USD',
  'BUSINESS_2MIL_1M_USD',
  'BUSINESS_2MIL_1Y_USD'
];

export interface IPermissions {
  roles: RoleId[];
  additionalScopes: UserLevelScope[];
}

export interface SpacePermissions {
  users: {
    [userId: string]: IPermissions | null; // users can be blanked out
  };
  customRoles: {
    [roleId: string]: Role;
  };
}

type Role = {
  id: RoleId;
  name: string;
  scopes: UserLevelScope[];
  custom: boolean;
};

const DEFAULT_ROLES: {
  [K in RoleId]: Role;
} = {
  OWNER: {
    id: 'OWNER',
    name: 'Owner',
    scopes: ALL_USER_LEVEL_SCOPES,
    custom: false
  },
  SUPER_ADMIN: {
    id: 'SUPER_ADMIN',
    name: 'Super Admin',
    scopes: ALL_USER_LEVEL_SCOPES, // Difference with owner is they cannot remove owners
    custom: false
  },
  ADMIN: {
    id: 'ADMIN',
    name: 'Admin',
    scopes: ALL_USER_LEVEL_SCOPES.filter(
      (s) =>
        ![
          'users.create',
          'users.edit',
          'users.delete',
          'users.view',
          'billing.view',
          'billing.edit'
        ].includes(s)
    ),
    custom: false
  },
  VIEWER: {
    id: 'VIEWER',
    name: 'Viewer',
    scopes: [
      'dashboard.view',
      'realtime.view',
      'channels.view',
      'custom_dimensions.view',
      'campaigns.view',
      'docs.view',
      'domains.view',
      'integrations.view',
      'link_checker.amazon.view',
      'link_checker.settings.view',
      'link_generator.use',
      'links.export',
      'links.view',
      'pages.view',
      'payouts.view',
      'rates.view',
      'reports.advertisers.view',
      'reports.advertisers.export',
      'reports.channels.view',
      'reports.content.view',
      'reports.content.export',
      'reports.traffic_sources.view',
      'reports.utms.view',
      'reports.custom_dimensions.view',
      'reports.links.view',
      'reports.payouts.view',
      'reports.platforms.view',
      'reports.rates.view',
      'reports.tags.view',
      'reports.transactions.export',
      'reports.transactions.view',
      'revisions.view',
      'rules.view',
      'scans.settings.view',
      'scans.view',
      'settings.tracking.view',
      'settings.view',
      'tags.view',
      'transactions.view'
    ],
    custom: false
  },
  TOOLS_USER: {
    id: 'TOOLS_USER',
    name: 'Tools User',
    scopes: [
      'link_generator.use',
      'channels.view',
      'custom_dimensions.view',
      'docs.view'
    ],
    custom: false
  }
};

export const collectUserLevelScopesForUser = (
  spacePermissions: SpacePermissions,
  userId: string
): UserLevelScope[] | null => {
  const userPermissions = spacePermissions.users[userId];
  if (!userPermissions) {
    return null;
  }
  const scopes = userPermissions.roles.reduce<UserLevelScope[]>(
    (m, roleId) => {
      const roleScopes =
        DEFAULT_ROLES[roleId]?.scopes ||
        spacePermissions.customRoles[roleId]?.scopes ||
        [];
      return [...m, ...roleScopes];
    },
    [...userPermissions.additionalScopes]
  );
  return [...new Set(scopes)].sort();
};

export enum PermissionStatus {
  ALLOWED = 'ALLOWED',
  MISSING_SCOPES = 'MISSING_SCOPES',
  NOT_IN_SPACE = 'NOT_IN_SPACE'
}

export const analyzeUserLevelScopes = (
  spacePermissions: SpacePermissions,
  userId: string,
  requiredScopes: UserLevelScope[]
): {
  status: PermissionStatus;
  present: UserLevelScope[];
  missing: UserLevelScope[];
} => {
  const givenScopes = collectUserLevelScopesForUser(spacePermissions, userId);
  if (!givenScopes) {
    return {
      status: PermissionStatus.NOT_IN_SPACE,
      present: [],
      missing: requiredScopes
    };
  }
  const missing: UserLevelScope[] = [];
  requiredScopes.forEach((scope) => {
    if (!givenScopes.includes(scope)) {
      missing.push(scope);
    }
  });
  return {
    present: givenScopes,
    missing,
    status:
      missing.length === 0
        ? PermissionStatus.ALLOWED
        : PermissionStatus.MISSING_SCOPES
  };
};

export const hasUserLevelScopes = (
  spacePermissions: SpacePermissions,
  userId: string,
  requiredScopes: UserLevelScope[]
) => {
  const res = analyzeUserLevelScopes(spacePermissions, userId, requiredScopes);
  return res.status === PermissionStatus.ALLOWED;
};
