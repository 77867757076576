import React from 'react';
import { CampaignsPageBody } from '../components/CampaignsPageBody';
import { useCampaigns } from '../service';
import { Loader } from '../../../components/Loader';
import Typography from '@material-ui/core/Typography';
import { Helmet } from 'react-helmet';
import { Paper } from '@material-ui/core';
import { styled } from '../../../emotion';

const Wrapper = styled('div')`
  margin: 0 auto;
  max-width: 1200px;
`;

export const PageCampaignsPerformance: React.FC = () => {
  const [campaigns, loading] = useCampaigns();
  return (
    <>
      <Helmet>
        <title>Campaigns Overview | Affilimate</title>
      </Helmet>
      <CampaignsPageBody>
        <Wrapper>
          <Typography component="h1" variant="h6">
            <strong>Campaign Performance</strong>
          </Typography>
          {loading || !campaigns ? (
            <Loader size={36} height={500} />
          ) : (
            <Paper>hi</Paper>
          )}
        </Wrapper>
      </CampaignsPageBody>
    </>
  );
};
