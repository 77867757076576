import React, { useMemo } from 'react';
import { ChartCard } from '../../../../../../../components/Charts/ChartCard';
import {
  Data,
  EarningsBarChart
} from '../../../../../../../components/Charts/EarningsChart';
import {
  ChartMode,
  ChartModeSelector
} from '../../../../../../../components/Charts/EarningsChartCard/ChartModeSelector';
import {
  GraphMode,
  GraphModeSelector
} from '../../../../../../../components/Charts/EarningsChartCard/GraphModeSelector';
import { InlineLoader, Loader } from '../../../../../../../components/Loader';
import { Timeframe } from '../../../../../../../domainTypes/analytics';
import { CurrencyCode } from '../../../../../../../domainTypes/currency';
import { Centered } from '../../../../../../../layout/Centered';
import { Typography } from '@material-ui/core';
import { FlexContainer } from '../../../../../../../layout/Flex';
import { capitalize } from 'lodash';
import { IEarningMinimal } from '../../../../../../../domainTypes/performance';

export const EarningsPerProductChartCard: React.FC<{
  data: void | Data[];
  loading: boolean;
  currency: CurrencyCode;
  style?: React.CSSProperties;
  orderBy: keyof IEarningMinimal;
  graphMode: GraphMode;
  setGraphMode: (nextGraphMode: GraphMode) => void;
  chartMode: ChartMode;
  setChartMode: (nextGraphMode: ChartMode) => void;
  timeframe?: Timeframe;
}> = ({
  data,
  loading,
  currency,
  style,
  orderBy,
  graphMode,
  setGraphMode,
  chartMode,
  setChartMode,
  timeframe
}) => {
  const metric = useMemo(() => {
    switch (orderBy) {
      case 'ct':
        return 'earnings';
      case 'qt':
        return 'quantity';
      case 'oct':
        return 'orderCount';
      case 'pt':
        return 'saleValue';
      default:
        return 'earnings';
    }
  }, [orderBy]);

  const modeSelector = (
    <>
      <GraphModeSelector value={graphMode} onChange={setGraphMode} />
      <ChartModeSelector value={chartMode} onChange={setChartMode} />
    </>
  );

  // store reference to last timeframe
  // if loading serve last timeframe
  // if loading turns false -> expose new timeframe

  const heading = useMemo(() => {
    switch (metric) {
      case 'earnings':
        return 'Earnings per product sold';
      case 'quantity':
        return 'Quantity per product sold';
      case 'orderCount':
        return 'Orders that included product sold';
      case 'saleValue':
        return 'Sale volume per product sold';
      default:
        return 'Earnings';
    }
  }, [metric]);

  if (loading) {
    return (
      <ChartCard
        heading="Top products sold"
        subheading={heading}
        topRight={modeSelector}
        style={style}
        size="small"
        padding="dense"
      >
        <Loader height={330} />
      </ChartCard>
    );
  }
  if (!data) {
    // TODO this should be an error state
    return (
      <ChartCard
        heading={heading}
        subheading={`By ${capitalize(graphMode)}`}
        topRight={modeSelector}
        style={style}
        size="small"
        padding="dense"
      >
        <Centered>
          <Typography
            variant="body1"
            component="p"
            paragraph
            style={{ textAlign: 'center', marginTop: '100px', maxWidth: '80%' }}
          >
            <strong>No data was loaded</strong>
            <br />
            <br />
            Try refreshing the page. If you expect to see data here and the
            error persists, please contact Support with the URL to this page.
          </Typography>
        </Centered>
      </ChartCard>
    );
  }

  return (
    <ChartCard
      heading="Top products sold"
      subheading={heading}
      topRight={
        loading ? (
          <FlexContainer>
            <InlineLoader color="inherit" />
            {modeSelector}
          </FlexContainer>
        ) : (
          modeSelector
        )
      }
      style={style}
      size="small"
      padding="dense"
      maximizedContent={() => (
        <EarningsBarChart
          height={'auto'}
          data={data}
          currency={currency}
          metric={metric}
          yAxisOrientation="right"
          timeframe={timeframe}
          chartMode={chartMode}
        />
      )}
    >
      <EarningsBarChart
        height={400}
        data={data}
        currency={currency}
        metric={metric}
        yAxisOrientation="right"
        timeframe={timeframe}
        chartMode={chartMode}
      />
    </ChartCard>
  );
};
