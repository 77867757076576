import { Card } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import {
  FiltersDrawer,
  FiltersIcon,
  useFilterDrawerState
} from '../../../../components/analytics_v2/Filters/Drawer';
import { Dimension } from '../../../../components/analytics_v2/Filters/filters';
import { useTable } from '../../../../components/analytics_v2/Table';
import { Timeframe } from '../../../../components/analytics_v2/Timeframe';
import { CustomPagination } from '../../../../components/CustomPagination';
import { ExportQueryButton } from '../../../../components/ExportQuery';
import { RowsRenderer } from '../../../../components/GroupableList';
import { InlineLoader, Loader } from '../../../../components/Loader';
import { ColumnSelector } from '../../../../components/Table/ColumnSelector';
import { SelectableField } from '../../../../domainTypes/analytics_v2';
import { css } from '../../../../emotion';
import { Centered } from '../../../../layout/Centered';
import { FlexContainer } from '../../../../layout/Flex';
import { DEFAULT_OFFSET, PageToolbar } from '../../../../layout/PageToolbar';
import { Metric } from '../../../../services/analyticsV2/metrics';
import { useFeatureEnabled } from '../../../../services/features';
import { EmptySearchResults, GeneralError } from './Messages';
import { SearchSummary } from './SearchSummary';
import { SelectionToolbar } from './SelectionToolbar';
import {
  PAGE_SIZE,
  SearchBox,
  useExportQuery,
  usePagesCount,
  usePagesMetrics
} from './service';
import { columnDefinitions, useColumns } from './Table/columns';

export const useFilterDimensions = (): Dimension[] => {
  const hasReferrerReports = useFeatureEnabled('REFERRER_REPORTS_V1');
  return useMemo(() => {
    const baseDimensions: Dimension[] = ['tag', 'site', 'device'];
    const availableDimensions: Dimension[] = hasReferrerReports
      ? [
          ...baseDimensions,
          'referrer',
          'utm_content',
          'utm_campaign',
          'utm_medium',
          'utm_source',
          'utm_term'
        ]
      : baseDimensions;
    return availableDimensions;
  }, [hasReferrerReports]);
};

export interface PageSelectionState {
  pages: Set<string>;
  togglePage: (page: string) => void;
  toggleAllPages: () => void;
  isAllPagesSelected: boolean;
  allPagesCount: number;
  resetPages: () => void;
}

const useSelectionState = () => {
  const [pagesCount = 1] = usePagesCount();

  const [pages, setPages] = useState<Set<string>>(new Set());
  const [allPages, setAllPages] = useState(false);

  return useMemo<PageSelectionState>(() => {
    const toggleAllPages = () => {
      setAllPages((v) => !v);
    };
    const togglePage = (page: string) => {
      const nextValue = new Set(pages);
      if (nextValue.has(page)) {
        nextValue.delete(page);
      } else {
        nextValue.add(page);
      }
      setPages(nextValue);
    };
    const resetPages = () => {
      setPages(new Set());
      setAllPages(false);
    };
    return {
      pages,
      togglePage,
      toggleAllPages,
      isAllPagesSelected: allPages,
      resetPages,
      allPagesCount: pagesCount
    };
  }, [allPages, pages, pagesCount]);
};

export const ContentOverviewBody = () => {
  const dimensions = useFilterDimensions();
  const { isOpen, toggle } = useFilterDrawerState(true, {
    localStorageKey: 'content-report-filter-drawer-state'
  });
  const { available, defaultVisible } = useColumns();
  const {
    allPagesCount,
    pages,
    isAllPagesSelected,
    resetPages,
    togglePage,
    toggleAllPages
  } = useSelectionState();

  const columnDefs = useMemo(
    () =>
      columnDefinitions({
        allPagesCount,
        toggleAllPages,
        togglePage,
        pages,
        isAllPagesSelected
      }),
    [allPagesCount, isAllPagesSelected, pages, toggleAllPages, togglePage]
  );

  const {
    tableProps,
    columnSelectorProps,
    paginationSelectorProps,
    pagination,
    orderBy,
    metrics
  } = useTable(available, columnDefs, {
    pageSize: PAGE_SIZE,
    defaultSortColumn: 'c',
    defaultVisibleColumns: defaultVisible
  });

  const normalizedColumnSelectorProps = useMemo(() => {
    return {
      ...columnSelectorProps,
      columns: columnSelectorProps.columns.filter((c) => c.key !== 'selector')
    };
  }, [columnSelectorProps]);

  const [data, loading, error] = usePagesMetrics(metrics, pagination, orderBy);

  const hasActiveSelection = pages.size > 0 || isAllPagesSelected;
  const tableHeadOffset = DEFAULT_OFFSET - 1;
  const exportQuery = useExportQuery(metrics, orderBy);

  if (error) {
    return <GeneralError />;
  }

  return (
    <>
      <PageToolbar
        className={css(() => ({ alignItems: 'baseline', paddingTop: 0 }))}
      >
        {hasActiveSelection ? (
          <SelectionToolbar
            pages={pages}
            isAllPagesSelected={isAllPagesSelected}
            resetPages={resetPages}
            allPagesCount={allPagesCount}
          />
        ) : (
          <div style={{ width: '100%' }}>
            <FlexContainer
              direction="row"
              justifyContent="space-between"
              marginBottom={2}
            >
              <FlexContainer spacing={0.5}>
                <SearchBox
                  autoFocus
                  placeholder="Search by URL or slug"
                  width={450}
                  size="small"
                />
                <FiltersIcon isOpen={isOpen} toggle={toggle} />
              </FlexContainer>

              <FlexContainer justifyContent="flex-end">
                {data && loading && <InlineLoader />}
                <ColumnSelector {...normalizedColumnSelectorProps} />
                <CustomPagination
                  {...paginationSelectorProps}
                  count={Math.ceil(allPagesCount / PAGE_SIZE)}
                  siblingCount={0}
                />
                <Timeframe />
                <ExportQueryButton
                  title="Export pages to CSV"
                  reportType="content"
                  query={exportQuery}
                />
              </FlexContainer>
            </FlexContainer>

            <FiltersDrawer
              availableDimensions={dimensions}
              title="Filter content by"
              isOpen={isOpen}
              orderBy={orderBy.field as Metric} // NOTE: ensure that!
            />
          </div>
        )}
      </PageToolbar>

      {!data ? (
        <Card>
          <Centered height={350}>
            <Loader size={32}></Loader>
          </Centered>
        </Card>
      ) : data.length === 0 ? (
        <EmptySearchResults />
      ) : (
        <>
          <SearchSummary
            metrics={metrics}
            orderByField={orderBy.field as SelectableField}
          />
          <RowsRenderer
            {...tableProps}
            renderHead={true}
            headProps={{
              sticky: true,
              offset: tableHeadOffset
            }}
            rows={data}
            rowToKey={(d) => d.group.page_url}
          />
        </>
      )}
    </>
  );
};
