import React from 'react';
import { CampaignsPageBody } from '../components/CampaignsPageBody';
import { isCampaignComplete, isCampaignRunning, useCampaign } from '../service';
import { Loader } from '../../../components/Loader';
import { Message } from '../../../components/Message';
import { Helmet } from 'react-helmet';
import { useTrackMixpanelView } from '../../../services/mixpanel';
import { CompletedCampaignReport } from '../components/report/CompletedCampaignReport';
import { RunningCampaignReport } from '../components/report/RunningCampaignReport';

export const CampaignReport: React.FC<{ campaignId: string }> = ({
  campaignId
}) => {
  const [campaign, loading] = useCampaign(campaignId);
  useTrackMixpanelView('view_campaigns_report', { id: campaignId });
  return (
    <>
      <Helmet>
        <title>Campaign report | Affilimate</title>
      </Helmet>
      <CampaignsPageBody>
        {loading || !campaign ? (
          <Loader size={36} height={500} />
        ) : isCampaignComplete(campaign) ? (
          <CompletedCampaignReport campaign={campaign} />
        ) : isCampaignRunning(campaign) ? (
          <RunningCampaignReport campaign={campaign} />
        ) : (
          <Message message="Campaign is not ready yet!" />
        )}
      </CampaignsPageBody>
    </>
  );
};
