import { Chip } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { capitalize, compact, isEmpty, take } from 'lodash';
import React, { ReactNode, useCallback } from 'react';
import {
  OTHERS_CHANNEL_ID,
  UNKNOWN_CHANNEL_ID
} from '../../../../domainTypes/channels';
import { COUNTRY_ABBREVIATIONS } from '../../../../domainTypes/country';
import {
  ChannelFilterDef,
  FilterDefinition,
  PlatformFilterDef,
  ReferrerFilterDef,
  TagFilterDef
} from '../../../../domainTypes/filters';
import {
  saleTypeLabel,
  SALE_UI_CONFIG
} from '../../../../domainTypes/performance';
import { IPostgresTags } from '../../../../domainTypes/tags';
import { FlexContainer } from '../../../../layout/Flex';
import {
  Channel,
  channelLabel,
  useSpaceChannels
} from '../../../../services/channels/channels';
import { useMappedLoadingValue } from '../../../../services/db';
import { getKnownPartnerForKey } from '../../../../services/partner';
import { useTagsForCurrentUser } from '../../../../services/tags';
import { Dimension, useDimensionTitle } from '../filters';
import { toReferrerGroupTitle } from '../../../../features/Content/pages/Traffic/Referrers/services/referrer-groups';
import { ReferrerGroup } from '../../../../domainTypes/referrers';
import { InternalId } from '../../../ConnectionId';

interface FilterChipProps {
  definition: FilterDefinition;
  onClick: () => void;
  onDelete: (dimension: Dimension) => void;
}

const Segment: React.FC = ({ children }) => (
  <Typography variant="body2" component="span" style={{ fontWeight: 'bold' }}>
    {children}
  </Typography>
);

const Or = () => (
  <Typography variant="body2" color="textSecondary" component="span">
    or
  </Typography>
);

const formatIntegrationIdList = (ids: string[]): ReactNode[] => {
  const labels =
    ids.length <= 4 ? ids : [...take(ids, 3), `${ids.length - 3} more`];
  return labels.flatMap((segment, i) => {
    const isFirst = i === 0;
    return isFirst
      ? [
          <Segment key={i}>
            <InternalId>{segment}</InternalId>
          </Segment>
        ]
      : [
          <Or />,
          <Segment key={i}>
            <InternalId>{segment}</InternalId>
          </Segment>
        ];
  });
};

const formatList = (names: string[]): ReactNode[] => {
  const labels =
    names.length <= 4 ? names : [...take(names, 3), `${names.length - 3} more`];
  return labels.flatMap((segment, i) => {
    const isFirst = i === 0;
    return isFirst
      ? [<Segment key={i}>{segment}</Segment>]
      : [<Or />, <Segment key={i}>{segment}</Segment>];
  });
};

export const FilterChip: React.FC<FilterChipProps> = ({
  definition,
  onClick,
  onDelete
}) => {
  const title = useDimensionTitle(definition.k);
  switch (definition.k) {
    case 'integration_id':
      return (
        <BaseChip
          title={`${title} is`}
          onClick={onClick}
          onDelete={() => onDelete('integration_id')}
        >
          {formatIntegrationIdList(definition.v)}
        </BaseChip>
      );
    case 'advertiser':
      return (
        <BaseChip
          title={`${title} is`}
          onClick={onClick}
          onDelete={() => onDelete('advertiser')}
        >
          {formatList(definition.v)}
        </BaseChip>
      );
    case 'payout_id':
      return (
        <BaseChip
          title={`${title} is`}
          onClick={onClick}
          onDelete={() => onDelete('payout_id')}
        >
          {formatList(definition.v)}
        </BaseChip>
      );
    case 'payout_status':
      return (
        <BaseChip
          title={`${title} is`}
          onClick={onClick}
          onDelete={() => onDelete('payout_status')}
        >
          {formatList(definition.v)}
        </BaseChip>
      );
    case 'transaction_status':
      return (
        <BaseChip
          title={`${title} is`}
          onClick={onClick}
          onDelete={() => onDelete('transaction_status')}
        >
          {formatList(
            definition.v.map(
              (status) => SALE_UI_CONFIG[capitalize(status)].label
            )
          )}
        </BaseChip>
      );
    case 'transaction_type':
      return (
        <BaseChip
          title={`${title} is`}
          onClick={onClick}
          onDelete={() => onDelete('transaction_type')}
        >
          {formatList(definition.v.map(saleTypeLabel))}
        </BaseChip>
      );
    case 'channel':
      return (
        <ChannelChip
          definition={definition}
          onClick={onClick}
          onDelete={() => onDelete('channel')}
        >
          {formatList(definition.v)}
        </ChannelChip>
      );
    case 'click_data_01':
      return (
        <BaseChip
          title={`${title} is`}
          onClick={onClick}
          onDelete={() => onDelete('click_data_01')}
        >
          {formatList(definition.v)}
        </BaseChip>
      );
    case 'click_data_02':
      return (
        <BaseChip
          title={`${title} is`}
          onClick={onClick}
          onDelete={() => onDelete('click_data_02')}
        >
          {formatList(definition.v)}
        </BaseChip>
      );
    case 'click_data_03':
      return (
        <BaseChip
          title={`${title} is`}
          onClick={onClick}
          onDelete={() => onDelete('click_data_03')}
        >
          {formatList(definition.v)}
        </BaseChip>
      );
    case 'click_data_04':
      return (
        <BaseChip
          title={`${title} is`}
          onClick={onClick}
          onDelete={() => onDelete('click_data_04')}
        >
          {formatList(definition.v)}
        </BaseChip>
      );
    case 'click_data_05':
      return (
        <BaseChip
          title={`${title} is`}
          onClick={onClick}
          onDelete={() => onDelete('click_data_05')}
        >
          {formatList(definition.v)}
        </BaseChip>
      );
    case 'click_data_06':
      return (
        <BaseChip
          title={`${title} is`}
          onClick={onClick}
          onDelete={() => onDelete('click_data_06')}
        >
          {formatList(definition.v)}
        </BaseChip>
      );
    case 'click_data_07':
      return (
        <BaseChip
          title={`${title} is`}
          onClick={onClick}
          onDelete={() => onDelete('click_data_07')}
        >
          {formatList(definition.v)}
        </BaseChip>
      );
    case 'click_data_08':
      return (
        <BaseChip
          title={`${title} is`}
          onClick={onClick}
          onDelete={() => onDelete('click_data_08')}
        >
          {formatList(definition.v)}
        </BaseChip>
      );
    case 'click_data_09':
      return (
        <BaseChip
          title={`${title} is`}
          onClick={onClick}
          onDelete={() => onDelete('click_data_09')}
        >
          {formatList(definition.v)}
        </BaseChip>
      );
    case 'click_data_10':
      return (
        <BaseChip
          title={`${title} is`}
          onClick={onClick}
          onDelete={() => onDelete('click_data_10')}
        >
          {formatList(definition.v)}
        </BaseChip>
      );
    case 'country':
      return (
        <BaseChip
          title={`${title} is`}
          onClick={onClick}
          onDelete={() => onDelete('country')}
        >
          {formatList(definition.v.map((c) => COUNTRY_ABBREVIATIONS[c] || c))}
        </BaseChip>
      );
    case 'device':
      return (
        <BaseChip
          title={`${title} is`}
          onClick={onClick}
          onDelete={() => onDelete('device')}
        >
          {formatList(definition.v)}
        </BaseChip>
      );
    case 'utm_medium': {
      const values = definition.v;
      return (
        <BaseChip
          title={`${title} is`}
          onClick={onClick}
          onDelete={() => onDelete('utm_medium')}
        >
          {formatList(values)}
        </BaseChip>
      );
    }
    case 'utm_source': {
      const values = definition.v;
      return (
        <BaseChip
          title={`${title} is`}
          onClick={onClick}
          onDelete={() => onDelete('utm_source')}
        >
          {formatList(values)}
        </BaseChip>
      );
    }
    case 'utm_term': {
      const values = definition.v;
      return (
        <BaseChip
          title={`${title} is`}
          onClick={onClick}
          onDelete={() => onDelete('utm_term')}
        >
          {formatList(values)}
        </BaseChip>
      );
    }
    case 'utm_content': {
      const values = definition.v;
      return (
        <BaseChip
          title={`${title} is`}
          onClick={onClick}
          onDelete={() => onDelete('utm_content')}
        >
          {formatList(values)}
        </BaseChip>
      );
    }
    case 'utm_campaign': {
      const values = definition.v;
      return (
        <BaseChip
          title={`${title} is`}
          onClick={onClick}
          onDelete={() => onDelete('utm_campaign')}
        >
          {formatList(values)}
        </BaseChip>
      );
    }
    case 'referrer':
      return (
        <ReferrerChip
          definition={definition}
          onClick={onClick}
          onDelete={() => onDelete('referrer')}
        />
      );
    case 'tag': {
      return (
        <TagsChip
          definition={definition}
          onClick={onClick}
          onDelete={() => onDelete('tag')}
        />
      );
    }
    case 'site': {
      const values = definition.v;
      return (
        <BaseChip
          title={`${title} is`}
          onClick={onClick}
          onDelete={() => onDelete('site')}
        >
          {formatList(values)}
        </BaseChip>
      );
    }
    case 'platform': {
      return (
        <PlatformChip
          onClick={onClick}
          onDelete={() => onDelete('platform')}
          definition={definition}
        />
      );
    }
  }
};

interface PlatformChipProps {
  definition: PlatformFilterDef;
  onClick: () => void;
  onDelete: (dimension: Dimension) => void;
}

const formatPk = (pk: string) =>
  isEmpty(pk) ? 'Unknown' : getKnownPartnerForKey(pk)?.name;

const PlatformChip: React.FC<PlatformChipProps> = ({
  definition,
  onClick,
  onDelete
}) => {
  const platforms = compact(definition.v.map(formatPk));
  return (
    <BaseChip
      title="Platform is"
      onClick={onClick}
      onDelete={() => onDelete('platform')}
    >
      {formatList(platforms)}
    </BaseChip>
  );
};

interface ChannelChipProps {
  definition: ChannelFilterDef;
  onClick: () => void;
  onDelete: (dimension: Dimension) => void;
}

const ChannelChip: React.FC<ChannelChipProps> = ({
  definition,
  onClick,
  onDelete
}) => {
  const mapFn = useCallback(
    (channels: Channel[]) =>
      compact([
        ...channels
          .filter((c) => definition.v.includes(c.id))
          .map(channelLabel),
        definition.v.includes(UNKNOWN_CHANNEL_ID) && 'Unknown',
        definition.v.includes(OTHERS_CHANNEL_ID) && 'Other'
      ]),
    [definition.v]
  );
  const [channels = []] = useMappedLoadingValue(
    useSpaceChannels(),
    mapFn,
    true
  );

  return (
    <BaseChip
      title="Channel is"
      onClick={onClick}
      onDelete={() => onDelete('channel')}
    >
      {formatList(channels)}
    </BaseChip>
  );
};

interface ReferrerChipProps {
  definition: ReferrerFilterDef;
  onClick: () => void;
  onDelete: (dimension: Dimension) => void;
}

const ReferrerChip: React.FC<ReferrerChipProps> = ({
  definition,
  onClick,
  onDelete
}) => {
  switch (definition.v.mode) {
    case 'group':
      return (
        <BaseChip
          title="Referrer group is"
          onClick={onClick}
          onDelete={() => onDelete('site')}
        >
          <Segment>
            {toReferrerGroupTitle(definition.v.v as ReferrerGroup)}
          </Segment>
        </BaseChip>
      );
    case 'domains':
      const domains = definition.v.v.map((domain) =>
        isEmpty(domain) ? 'direct' : domain
      );
      return (
        <BaseChip
          title="Referrer is"
          onClick={onClick}
          onDelete={() => onDelete('site')}
        >
          {formatList(domains)}
        </BaseChip>
      );
  }
};

interface TagsChipProps {
  definition: TagFilterDef;
  onClick: () => void;
  onDelete: (dimension: Dimension) => void;
}

const TagsChip: React.FC<TagsChipProps> = ({
  definition,
  onClick,
  onDelete
}) => {
  const mapFn = useCallback(
    (tags: IPostgresTags[]) =>
      tags.filter((t) => definition.v.includes(t.id)).map((t) => t.name),
    [definition.v]
  );
  const [tags = []] = useMappedLoadingValue(
    useTagsForCurrentUser(),
    mapFn,
    true
  );
  return (
    <BaseChip title="Tag is" onClick={onClick} onDelete={() => onDelete('tag')}>
      {formatList(tags)}
    </BaseChip>
  );
};

export const PlaceholderChip = ({ dimension }: { dimension: Dimension }) => {
  const title = useDimensionTitle(dimension);
  return (
    <Chip
      disabled
      variant="outlined"
      label={
        <Typography variant="body2" style={{ fontStyle: 'italic' }}>
          {title} ...
        </Typography>
      }
    />
  );
};

const BaseChip: React.FC<{
  title: string;
  onClick: () => void;
  onDelete: () => void;
}> = ({ title, onClick, onDelete, children }) => {
  return (
    <Chip
      onClick={onClick}
      onDelete={onDelete}
      variant="outlined"
      label={
        <FlexContainer spacing={0.5}>
          <Typography variant="body2" component="span">
            {title}
          </Typography>
          {children}
        </FlexContainer>
      }
    />
  );
};
