import { CampaignsPageBody } from '../components/CampaignsPageBody';
import React, { useCallback, useMemo } from 'react';
import { Campaign, saveCampaign, useCampaign } from '../service';
import { Loader } from '../../../components/Loader';
import { Paper } from '@material-ui/core';
import { css } from '../../../emotion';
import Typography from '@material-ui/core/Typography';
import {
  CampaignForm,
  CampaignFormFields,
  fieldsToFirestore
} from '../components/CampaignForm';
import { ICampaign } from '../../../domainTypes/campaigns';
import { useSnackbar } from '../../../hooks/useSnackbar';
import { fromMoment } from '../../../services/time';
import {
  useCurrentUser,
  useCurrentUserScopes
} from '../../../services/currentUser';
import { Helmet } from 'react-helmet';
import { useRoutes, useStringQueryParam } from '../../../routes';
import { LimitedWidth } from '../../../layout/PageBody';
import { useMixpanel, useTrackMixpanelView } from '../../../services/mixpanel';

const Body = ({ campaign }: { campaign: Campaign }) => {
  const scopes = useCurrentUserScopes();
  const canDelete = scopes.has('campaigns.delete');
  const { space } = useCurrentUser();
  const { track } = useMixpanel();
  const { enqueueSnackbar } = useSnackbar();
  const { goTo, ROUTES } = useRoutes();
  const [back] = useStringQueryParam('back', 'overview');
  const c = useMemo<ICampaign>(() => {
    const timeframe: ICampaign['timeframe'] =
      campaign.status !== 'planning'
        ? {
            start: fromMoment(campaign.timeframe.start),
            end: fromMoment(campaign.timeframe.end)
          }
        : null;
    return {
      ...campaign,
      timeframe
    };
  }, [campaign]);

  const onSaveCampaign = useCallback(
    async (fields: CampaignFormFields) => {
      try {
        track('edit_campaign', { id: campaign.id });
        await saveCampaign(space.id, campaign.id, fieldsToFirestore(fields));
        enqueueSnackbar(`Saved ${campaign.name} campaign`, {
          variant: 'success'
        });
        const url =
          back === 'overview'
            ? ROUTES.campaigns.overview.url()
            : ROUTES.campaigns.report.url(campaign.id);
        goTo(url);
      } catch (e) {
        enqueueSnackbar(`Failed to save ${campaign.name} campaign`, {
          variant: 'error'
        });
      }
    },
    [
      ROUTES.campaigns.overview,
      ROUTES.campaigns.report,
      back,
      campaign.id,
      campaign.name,
      enqueueSnackbar,
      goTo,
      space.id,
      track
    ]
  );

  return (
    <>
      <Helmet>
        <title>Editing {campaign.name} | Affilimate</title>
      </Helmet>
      <LimitedWidth width={600}>
        <Paper
          className={css((t) => ({
            padding: t.spacing(3),
            marginTop: t.spacing(4)
          }))}
        >
          <Typography variant="h6">
            Edit <strong>{campaign.name}</strong> campaign
          </Typography>
          <CampaignForm
            campaign={c}
            saveCampaign={onSaveCampaign}
            saveLabel="Save changes"
            allowDelete={canDelete}
          />
        </Paper>
      </LimitedWidth>
    </>
  );
};

export const CampaignDetails: React.FC<{
  campaignId: string;
}> = ({ campaignId }) => {
  const [campaign, loading] = useCampaign(campaignId);
  useTrackMixpanelView('view_campaigns_detail', { id: campaignId });
  return (
    <>
      <Helmet>
        <title>Campaign Details | Affilimate</title>
      </Helmet>
      <CampaignsPageBody>
        {loading ? (
          <Loader size={36} height={500} />
        ) : !campaign ? null : (
          <Body campaign={campaign} />
        )}
      </CampaignsPageBody>
    </>
  );
};
