import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@material-ui/core';
import { compact, isEmpty } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { X } from 'react-feather';
import {
  ColumnDefinitions,
  useTable
} from '../../../../components/analytics_v2/Table';
import { useTimeframe } from '../../../../components/analytics_v2/Timeframe';
import { CustomPagination } from '../../../../components/CustomPagination';
import { RowsRenderer } from '../../../../components/GroupableList';
import { Loader } from '../../../../components/Loader';
import { SearchInput } from '../../../../components/SearchInput';
import {
  AnalyticsFilterLike,
  AnalyticsOrderBy,
  AnalyticsQuery,
  AnalyticsResponseRowWithComparison
} from '../../../../domainTypes/analytics_v2';
import { styled } from '../../../../emotion';
import { Centered } from '../../../../layout/Centered';
import { FlexContainer } from '../../../../layout/Flex';
import { useRoutes } from '../../../../routes';
import { Metric } from '../../../../services/analyticsV2/metrics';
import { useAnalyticsQueryV2 } from '../../../../services/analyticsV2/query';
import { useCurrentUser } from '../../../../services/currentUser';
import { useMappedLoadingValue } from '../../../../services/db';
import { Href } from '../../../Content/pages/Overview_v2/Table/Href';
import { ProductLinkTitleWithPartnerLazy } from '../Overview/components/ProductLinkCell';

interface LinkDetailsModalProps {
  open: boolean;
  initialPageSearch: string;
  onClose: () => void;
  linkId: string;
}

const useLinksPagesCountQuery = (
  linkId: string,
  filter: AnalyticsFilterLike | null
) => {
  const { space } = useCurrentUser();
  const { range } = useTimeframe();
  const query = useMemo<AnalyticsQuery>(() => {
    return {
      select: ['count_uniq_page_url'],
      range,
      filters: compact([
        filter,
        {
          field: 'link_id',
          condition: 'in',
          values: [linkId]
        }
      ])
    };
  }, [range, filter, linkId]);

  return useMappedLoadingValue(
    useAnalyticsQueryV2(space.id, query),
    (data) => data.rows[0]?.data.count_uniq_page_url?.curr ?? 0
  );
};

const Hr = styled('hr')`
  border: 1px solid ${({ theme }) => theme.palette.grey[300]};
`;

const useLinksPagesQuery = (
  linkId: string,
  metrics: Metric[],
  filter: AnalyticsFilterLike | null,
  paginate: AnalyticsQuery['paginate'],
  orderBy: AnalyticsOrderBy
) => {
  const { space } = useCurrentUser();
  const tf = useTimeframe();
  const query = useMemo<AnalyticsQuery>(() => {
    return {
      groupBy: ['page_url'],
      select: metrics,
      ...tf,
      filters: compact([
        filter,
        {
          field: 'link_id',
          condition: 'in',
          values: [linkId]
        }
      ]),
      paginate,
      orderBy: [orderBy]
    };
  }, [metrics, tf, filter, linkId, paginate, orderBy]);

  return useAnalyticsQueryV2(space.id, query);
};

const customColumns = ['page_url'] as const;
type CustomColumns = typeof customColumns[number];
type Column = CustomColumns | Metric;

const availableColumns: Column[] = [
  ...customColumns,
  'p',
  'c',
  'ctr',
  'commission_sum_net',
  'gmv_sum_net',
  'epc_net',
  'rpm_net'
];

const columnDefinitions: ColumnDefinitions<CustomColumns> = {
  page_url: {
    column: {
      key: 'page_url',
      head: () => 'Page URL',
      cell: (p: AnalyticsResponseRowWithComparison) => (
        <Href href={p.group.page_url} />
      ),
      align: 'left',
      sortable: true,
      defaultDirection: 'asc',
      width: 400,
      flexGrow: 8
    },
    sorter: {
      key: 'page_url',
      items: {
        sort: (p: AnalyticsResponseRowWithComparison) => p.group.page_url,
        dir: 'asc'
      }
    }
  }
};

const PAGE_SIZE = 20;
// NOTE: ld stands for Link Details
const SORT_PARAM_NAME = 'ldsort';
const PAGE_PARAM_NAME = 'ldpage';
const COLUMNS_PARAM_NAME = 'ldcols';

const Content = ({
  linkId,
  initialPageSearch
}: {
  linkId: string;
  initialPageSearch: string;
}) => {
  const [pageSearch, setPageSearch] = useState(initialPageSearch);
  const {
    tableProps,
    paginationSelectorProps,
    metrics,
    orderBy,
    pagination
  } = useTable(availableColumns, columnDefinitions, {
    pageSize: PAGE_SIZE,
    defaultSortColumn: 'c',
    sortQueryParamName: SORT_PARAM_NAME,
    paginationParamName: PAGE_PARAM_NAME,
    columnSelectorParamName: COLUMNS_PARAM_NAME
  });
  const filter = useMemo<AnalyticsFilterLike | null>(() => {
    if (isEmpty(pageSearch)) return null;
    return {
      field: 'page_url',
      condition: 'ilike',
      pattern: `%${pageSearch}%`
    };
  }, [pageSearch]);

  const [data, loading] = useLinksPagesQuery(
    linkId,
    metrics,
    filter,
    pagination,
    orderBy
  );
  const [count = 1] = useLinksPagesCountQuery(linkId, filter);

  return (
    <>
      <FlexContainer style={{ marginLeft: '18px' }}>
        <Typography variant="body1" style={{ fontWeight: 700 }} paragraph>
          Page performance
        </Typography>
        <Typography variant="body2" color="textSecondary" paragraph>
          This individual link's performance within different pages.
        </Typography>
      </FlexContainer>
      <FlexContainer
        style={{ marginLeft: '18px' }}
        justifyContent="space-between"
      >
        <FlexContainer>
          <SearchInput
            value={pageSearch}
            onChange={setPageSearch}
            size="small"
            width={300}
            placeholder="Filter by page URL"
          />
        </FlexContainer>
        <CustomPagination
          {...paginationSelectorProps}
          siblingCount={0}
          count={Math.ceil(count / PAGE_SIZE)}
        />
      </FlexContainer>
      <br />
      {!data || loading ? (
        <Centered height={350}>
          <Loader size={32} />
        </Centered>
      ) : (
        <RowsRenderer
          {...tableProps}
          renderHead={true}
          rows={data.rows}
          rowToKey={(d) => d.group.page_url}
          variant="plain"
        />
      )}
    </>
  );
};

export const LinkDetailsModal: React.FC<LinkDetailsModalProps> = ({
  initialPageSearch,
  open,
  onClose,
  linkId
}) => {
  const { space } = useCurrentUser();
  const { changeQuery } = useRoutes();
  const close = useCallback(() => {
    onClose();
    // NOTE: reset Link Details query params
    changeQuery({
      [SORT_PARAM_NAME]: undefined,
      [PAGE_PARAM_NAME]: undefined,
      [COLUMNS_PARAM_NAME]: undefined
    });
  }, [changeQuery, onClose]);
  return (
    <Dialog open={open} onClose={close} fullWidth maxWidth="xl" scroll="body">
      <DialogTitle>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 100px'
          }}
        >
          <ProductLinkTitleWithPartnerLazy
            spaceId={space.id}
            productId={linkId}
          />
          <div style={{ textAlign: 'right' }}>
            <IconButton onClick={onClose}>
              <X size={24} />
            </IconButton>
          </div>
        </div>
      </DialogTitle>
      <Hr />
      <DialogContent>
        <Content linkId={linkId} initialPageSearch={initialPageSearch} />
      </DialogContent>
    </Dialog>
  );
};
