import moment from 'moment-timezone';
import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { ChartCard } from '../../../../components/Charts/ChartCard';
import { Loader, PaperLoader } from '../../../../components/Loader';
import {
  TimeframePickerDense,
  useStandardOptions,
  useTimeframeFromUrl
} from '../../../../components/TimeframePicker';
import { SubHeading } from '../../../../components/Typography';
import { DAY_FORMAT, TIMEKEY_FORMAT } from '../../../../domainTypes/analytics';
import { Doc } from '../../../../domainTypes/document';
import { IPageMetadata } from '../../../../domainTypes/page';
import { styled } from '../../../../emotion';
import { useErrorLogger } from '../../../../hooks/useErrorLogger';
import { CanvasBar } from '../../../../layout/Canvas';
import { PageBody } from '../../../../layout/PageBody';
import {
  DEFAULT_OFFSET,
  PageToolbar,
  PageToolbarSection,
  PageToolbarTitle
} from '../../../../layout/PageToolbar';
import { Section } from '../../../../layout/Section';
import { useComboQueryParam, useRoutes } from '../../../../routes';
import {
  multiplyTimeframe,
  useCountsInTimeframeForPagePg
} from '../../../../services/analytics';
import {
  useCurrentUser,
  useCurrentUserScopes
} from '../../../../services/currentUser';
import {
  combineLoadingValues,
  useMappedLoadingValue
} from '../../../../services/db';
import { useTrackMixpanelView } from '../../../../services/mixpanel';
import { usePageOrCreate } from '../../../../services/page';
import { getPathname } from '../../../../services/url';
import { useSpaceCurrency } from '../../../../services/useSpaceCurrency';
import { DetailsPageTitle } from '../../components/DetailsPageTitle';
import { sideNavProps } from '../../services/detailsSideNav';
import { Chart, LeftMode, RightMode } from './components/Chart';
import { CHART_HEIGHT } from './components/constants';
import { Summary, toSummaryDataPg } from './components/Summary';
import { useEarningsByAdvertiserTimeseries, useTotalEarnings } from './service';
import {
  AdvertisersTable,
  COLUMNS,
  SORTERS
} from '../../../../components/AdvertisersTable';
import {
  useColumnsQueryParam,
  useSortQueryParam
} from '../../../../components/GroupableList';
import { ColumnSelector } from '../../../../components/Table/ColumnSelector';
import { useEarningsPerAdvertiser } from '../../../../services/advertisers';
import { useExperimentalContext } from '../../../../services/experimental';
import { DetailsTrendsBody } from './components/DetailsTrendsBody';
import { NoPermissions } from '../../../../components/NoPermissions';

const Grid = styled('div')`
  display: grid;
  grid-column-gap: ${(p) => p.theme.spacing(3)}px;
  grid-row-gap: ${(p) => p.theme.spacing(4)}px;
  grid-template-columns: 1fr 2fr;

  ${(p) => p.theme.breakpoints.down('md')} {
    grid-template-columns: 1fr;
    grid-column-gap: ${(p) => p.theme.spacing(1)}px;
    grid-row-gap: ${(p) => p.theme.spacing(1)}px;
  }
`;

const getLastRevision = (m: void | Doc<IPageMetadata> | null) => {
  if (!m) {
    return null;
  }
  return m.data.revisions[m.data.revisions.length - 1] || null;
};

const useModeQueryParam = (
  param: string,
  defaultValue: [LeftMode, RightMode]
) => {
  return useComboQueryParam<[LeftMode, RightMode]>(
    param,
    ([left, right]) => [
      (left as LeftMode) || defaultValue[0],
      (right as RightMode) || defaultValue[1]
    ],
    ([left, right]) =>
      left === defaultValue[0] && right === defaultValue[1]
        ? [undefined, undefined]
        : [left, right],
    '---'
  );
};

const DEFAULT_COLUMNS = COLUMNS.map((c) => c.key).filter(
  (c) =>
    ['orderCountGross', 'lostCount', 'avgCommissionNetPercent'].indexOf(c) ===
    -1
);

const PageContentDetailsTrendsPg = ({ url }: { url: string }) => {
  const { space } = useCurrentUser();
  const currency = useSpaceCurrency();

  const { ROUTES } = useRoutes();
  const sideNav = sideNavProps(ROUTES, url);

  const [mode, setMode] = useModeQueryParam('chart-mode', [
    'clicks',
    'earnings'
  ]);
  const { options, defaultOption } = useStandardOptions();
  const [timeframe, setTimeframe] = useTimeframeFromUrl(defaultOption.value);
  const comparableTimeframe = multiplyTimeframe(timeframe, 2);

  const [d, loading, error] = useMappedLoadingValue(
    combineLoadingValues(
      useCountsInTimeframeForPagePg(space.id, url, comparableTimeframe),
      usePageOrCreate(space.id, url)
    ),
    ([traffic, metadata]) => {
      return {
        traffic,
        metadata
      };
    }
  );

  const [
    advertisersTimeseries,
    loadingAdvertisersTimeseries
  ] = useEarningsByAdvertiserTimeseries(space.id, url, timeframe, currency);

  const [totalEarnings, loadingTotalEarnings] = useTotalEarnings(
    space.id,
    url,
    timeframe,
    currency
  );

  const lastRevision = getLastRevision(d ? d.metadata : null);

  useErrorLogger(error);

  const { start, tz } = timeframe;

  const summaryData = useMemo(() => {
    if (!d || !totalEarnings) {
      return undefined;
    }
    return toSummaryDataPg(
      d.traffic,
      totalEarnings,
      moment.tz(start, DAY_FORMAT, tz).format(TIMEKEY_FORMAT),
      currency
    );
  }, [d, totalEarnings, start, tz, currency]);

  const q = useMemo(
    () => ({
      page_url: [url]
    }),
    [url]
  );
  const [byAdvertiser, loadingByAdvertiser] = useEarningsPerAdvertiser(
    space.id,
    timeframe,
    true,
    currency,
    q
  );

  const [[sorter, direction], setSort] = useSortQueryParam('sort', SORTERS);
  const [columnNames, setColumns] = useColumnsQueryParam(
    'columns',
    DEFAULT_COLUMNS
  );

  return (
    <PageBody sideNav={sideNav} noTopPadding>
      <PageToolbar>
        <PageToolbarTitle flex={2}>
          <DetailsPageTitle url={url} />
        </PageToolbarTitle>
        <PageToolbarSection flex={2} justifyContent="flex-end">
          <TimeframePickerDense
            value={timeframe}
            onChange={setTimeframe}
            options={options}
          />
        </PageToolbarSection>
      </PageToolbar>

      <Section>
        <Grid>
          <Summary
            summary={summaryData}
            advertisers={byAdvertiser}
            rev={lastRevision}
            timeframe={timeframe}
            loading={loading || loadingTotalEarnings || loadingByAdvertiser}
          />
          {(loading || loadingAdvertisersTimeseries) && (
            <ChartCard
              heading="Performance over time"
              size="small"
              padding="dense"
            >
              <Loader height={CHART_HEIGHT + 42} />
            </ChartCard>
          )}
          {d && advertisersTimeseries && (
            <ChartCard
              heading="Performance over time"
              size="small"
              padding="dense"
              maximizedContent={() => (
                <Chart
                  traffic={d.traffic}
                  earnings={advertisersTimeseries}
                  timeframe={timeframe}
                  currency={currency}
                  metadata={d.metadata?.data || null}
                  mode={mode}
                  onModeChange={setMode}
                  height="auto"
                  compareLeft
                />
              )}
            >
              <Chart
                traffic={d.traffic}
                earnings={advertisersTimeseries}
                timeframe={timeframe}
                currency={currency}
                metadata={d.metadata?.data || null}
                mode={mode}
                onModeChange={setMode}
                height={CHART_HEIGHT}
                compareLeft
              />
            </ChartCard>
          )}
        </Grid>
      </Section>
      <Section>
        <CanvasBar>
          <SubHeading>Advertiser performance</SubHeading>
          <ColumnSelector
            value={columnNames}
            onChange={setColumns}
            columns={COLUMNS}
          />
        </CanvasBar>
        {!byAdvertiser && loadingByAdvertiser && <PaperLoader height={300} />}
        {byAdvertiser && (
          <AdvertisersTable
            data={byAdvertiser}
            columns={columnNames}
            sortDirection={direction}
            sorter={sorter}
            setSort={setSort}
            compare={true}
            stickyHeadOffset={DEFAULT_OFFSET}
          />
        )}
      </Section>
    </PageBody>
  );
};

export const PageContentDetailsTrends = ({ url }: { url: string }) => {
  useTrackMixpanelView('view_content_details');
  const [isExperiment] = useExperimentalContext();
  const scopes = useCurrentUserScopes();
  const canViewContentReports = scopes.has('reports.content.view');

  if (!canViewContentReports) {
    return <NoPermissions />;
  }

  return (
    <div>
      <Helmet>
        <title>{getPathname(url)} | Affilimate</title>
      </Helmet>
      {isExperiment ? (
        <PageContentDetailsTrendsPg url={url} />
      ) : (
        <DetailsTrendsBody url={url} />
      )}
    </div>
  );
};
