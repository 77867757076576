import React from 'react';
import { Helmet } from 'react-helmet';
import { CountryChart } from '../../../../components/Charts/CountryChart';
import { DeviceChart } from '../../../../components/Charts/DeviceChart';
import {
  DetailCard,
  DetailCardHeadline,
  SectionSubheading
} from '../../../../components/DetailsPage';
import { Loader } from '../../../../components/Loader';
import {
  useStandardOptions,
  useTimeframeFromUrl
} from '../../../../components/TimeframePicker';
import { Timeframe } from '../../../../domainTypes/analytics';
import { styled } from '../../../../emotion';
import {
  useCountryClickCountsForProductInTimeframePg,
  useDeviceClickCountsForProductInTimeframePg
} from '../../../../services/analytics';
import { useCountryClickCountsForProductInTimeframeFs } from '../../../../services/analytics/country';
import { useDeviceClickCountsForProductInTimeframeFs } from '../../../../services/analytics/device';
import { useCurrentUser } from '../../../../services/currentUser';
import { useProduct } from '../../../../services/products';
import { usePostgres } from '../../../../services/sales/service';
import { DetailsNotFound } from '../../components/DetailsNotFound';
import { DetailsToolbar } from '../../components/DetailsToolbar';
import { PageBodyLinkDetails } from '../../components/PageBodyLinkDetails';

type CommonProps = {
  spaceId: string;
  productId: string;
  timeframe: Timeframe;
};

const DevicesFs = ({ productId, timeframe }: CommonProps) => {
  const [counts, loading] = useDeviceClickCountsForProductInTimeframeFs(
    productId,
    timeframe
  );
  return (
    <DetailCard>
      <DetailCardHeadline title="Top devices" />
      <SectionSubheading>
        Devices your visitors use when clicking this affiliate link
      </SectionSubheading>
      <DeviceChart counts={counts} loading={loading} />
    </DetailCard>
  );
};

const CountriesFs = ({ productId, timeframe }: CommonProps) => {
  const [counts, loading] = useCountryClickCountsForProductInTimeframeFs(
    productId,
    timeframe,
    false
  );
  return (
    <DetailCard>
      <DetailCardHeadline title="Top countries" />
      <SectionSubheading>
        Countries your visitors are in when clicking this affiliate link.
      </SectionSubheading>
      <CountryChart counts={counts} loading={loading} />
    </DetailCard>
  );
};

const DevicesPg = ({ spaceId, productId, timeframe }: CommonProps) => {
  const [counts, loading] = useDeviceClickCountsForProductInTimeframePg(
    spaceId,
    productId,
    timeframe
  );
  return (
    <DetailCard>
      <DetailCardHeadline title="Top devices" />
      <SectionSubheading>
        Devices your visitors use when clicking this affiliate link
      </SectionSubheading>
      <DeviceChart counts={counts} loading={loading} />
    </DetailCard>
  );
};

const CountriesPg = ({ spaceId, productId, timeframe }: CommonProps) => {
  const [counts, loading] = useCountryClickCountsForProductInTimeframePg(
    spaceId,
    productId,
    timeframe,
    false
  );
  return (
    <DetailCard>
      <DetailCardHeadline title="Top countries" />
      <SectionSubheading>
        Countries your visitors are in when clicking this affiliate link
      </SectionSubheading>
      <CountryChart counts={counts} loading={loading} />
    </DetailCard>
  );
};

const Grid = styled('div')`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: ${(p) => p.theme.spacing(3)}px;
  grid-row-gap: ${(p) => p.theme.spacing(6)}px;

  ${(p) => p.theme.breakpoints.down('md')} {
    grid-template-columns: 1fr;
  }
`;

export const PageLinkDetailsAudience = ({
  productId
}: {
  productId: string;
}) => {
  const pg = usePostgres();
  const { space } = useCurrentUser();
  const [product, loading] = useProduct(productId);
  const { options, defaultOption } = useStandardOptions();
  const [timeframe, setTimeframe] = useTimeframeFromUrl(defaultOption.value);

  return (
    <PageBodyLinkDetails productId={productId} noTopPadding>
      <Helmet>
        <title>Link Audience | Affilimate</title>
      </Helmet>
      <DetailsToolbar
        product={product}
        timeframe={timeframe}
        options={options}
        setTimeframe={setTimeframe}
      />
      {loading && <Loader height={500} />}
      {product === null && <DetailsNotFound />}
      {product && (
        <Grid>
          {pg ? (
            <>
              <DevicesPg
                spaceId={space.id}
                productId={productId}
                timeframe={timeframe}
              />
              <CountriesPg
                spaceId={space.id}
                productId={productId}
                timeframe={timeframe}
              />
            </>
          ) : (
            <>
              <DevicesFs
                spaceId={space.id}
                productId={productId}
                timeframe={timeframe}
              />
              <CountriesFs
                spaceId={space.id}
                productId={productId}
                timeframe={timeframe}
              />
            </>
          )}
        </Grid>
      )}
    </PageBodyLinkDetails>
  );
};
