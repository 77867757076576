import React, { useCallback } from 'react';
import { AutocompleteMultiAsync } from '../../../components/Autocomplete';
import { useCurrentUser } from '../../../services/currentUser';
import { queryAnalyticsV2 } from '../../../services/analyticsV2/query';
import { AnalyticsQuery } from '../../../domainTypes/analytics_v2';
import { useTimeframe } from '../../../components/analytics_v2/Timeframe';
import { Chip } from '@material-ui/core';
import { usePromise } from '../../../hooks/usePromise';
import { getProductByIdPg, renderLinkName } from '../../../services/products';
import { Skeleton } from '@material-ui/lab';
import { Truncated } from '../../../components/Truncated';

const LinkLabel = ({ linkId }: { linkId: string }) => {
  const { space } = useCurrentUser();
  const [p, loading] = usePromise(() => {
    return getProductByIdPg(space.id, linkId);
  }, [space.id, linkId]);

  if (loading) {
    return <Skeleton style={{ width: '30%' }} />;
  }
  if (!p) {
    return <div>{linkId}</div>;
  }

  const linkName = renderLinkName({
    name: p.name,
    url: p.url,
    partnerKey: p.partner_key
  });
  return <Truncated title={p.url}>{linkName}</Truncated>;
};

interface LinksSelectorProps {
  value: string[];
  onChange: (value: string[]) => void;
  pageUrls: string[];
}

export const LinksSelector: React.FC<LinksSelectorProps> = ({
  value,
  onChange,
  pageUrls
}) => {
  const { space } = useCurrentUser();
  const { range } = useTimeframe();
  const getOptions = useCallback(
    async (filter: string) => {
      const q: AnalyticsQuery = {
        range,
        select: ['c'],
        groupBy: ['link_id'],
        filters: [
          {
            field: 'link_name',
            condition: 'ilike',
            pattern: `%${filter}%`
          },
          {
            field: 'page_url',
            condition: 'in',
            values: pageUrls
          }
        ],
        orderBy: [{ field: 'c', direction: 'DESC' }],
        paginate: {
          limit: 50,
          page: 1
        }
      };

      return queryAnalyticsV2(space.id, q).then((response) => {
        return response.rows.map((row) => row.group.link_id);
      });
    },
    [pageUrls, range, space.id]
  );

  return (
    <AutocompleteMultiAsync
      fullWidth
      value={value}
      onChange={onChange}
      options={getOptions}
      renderOption={(linkId) => <LinkLabel linkId={linkId} />}
      renderTags={(linkIds, getTagProps) =>
        linkIds.map((linkId, index) => (
          <Chip
            label={<LinkLabel linkId={linkId} />}
            {...getTagProps({ index })}
          />
        ))
      }
    />
  );
};
