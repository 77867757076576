import {
  Button,
  ButtonBase,
  Card,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography
} from '@material-ui/core';
import { mixpanel as mp } from '../../services/mixpanel';
import firebase from 'firebase/app';
import firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';
import React, { useEffect, useState } from 'react';
import { ArrowRightCircle, Mail } from 'react-feather';
import { AlertBox } from '../../components/AlertBox';
import { SelectCurrency } from '../../components/SelectCurrency';
import { SelectTimezone } from '../../components/SelectTimezone';
import { DEFAULT_CURRENCY } from '../../domainTypes/space';
import { ICurrentUser } from '../../domainTypes/user';
import { styled } from '../../emotion';
import { useAfterFirstRender } from '../../hooks/useAfterFirstRender';
import { useDialogState } from '../../hooks/useDialogState';
import { loginExtension } from '../../services/chromeExtension';
import { useCurrentUser } from '../../services/currentUser';
import { callFirebaseFunction } from '../../services/firebaseFunctions';
import {
  addDomainUnverified,
  updateCurrency,
  updateTimezone
} from '../../services/space';
import * as CY from '../../testIds';
import * as tracking from '../../tracking';
import { CF } from '../../versions';
import { ResetPassword } from '../ResetPassword';
import { testEmail, testWebsite } from '../../services/validation';

const ui = new firebaseui.auth.AuthUI(firebase.auth());

const SIGNUP_CONFIG: firebaseui.auth.Config = {
  signInFlow: 'popup',
  signInOptions: [
    {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      customParameters: {
        // Forces account selection even when one account
        // is available.
        prompt: 'select_account'
      }
    }
  ],
  callbacks: {
    signInSuccessWithAuthResult: () => {
      try {
        loginExtension();
      } catch {}
      return false;
    }
  }
};

const ID = 'firebase-ui-login-container';

const LoginWrapper = styled('div')`
  height: 100vh;
  display: grid;
  grid-template-columns: 33% 66%;
  overflow-x: hidden;

  @media (max-width: 800px) {
    display: block;
  }
`;

const RightSide = styled('div')`
  padding: ${(p) => p.theme.spacing() * 5}px;
  background-color: #151616;
  display: flex;
  align-items: center;
  text-align: center;
  position: relative;
  transition: width 0.5s;

  @media (max-width: 800px) {
    display: none;
    padding: ${(p) => p.theme.spacing(1)}px;
    width: 90%;
    margin: 0 auto;
  }
`;

const RightSideInner = styled('div')`
  margin: 0 auto;
  max-width: 322px;
  color: ${(p) => p.theme.palette.grey[200]};
`;

const MiniDashboard = styled('div')`
  margin: 0 auto;

  img {
    width: 80%;
  }
`;

const LeftSide = styled('div')`
  padding: ${(p) => p.theme.spacing() * 5}px;
  display: flex;
  align-items: center;
  text-align: center;
  position: relative;
  transition: width 0.5s;

  @media (max-width: 800px) {
    padding: ${(p) => p.theme.spacing(1)}px;
    width: 90%;
    margin: 0 auto;
  }
`;

const LeftSideInner = styled('div')`
  margin: 0 auto;
`;

const LeftSideContent = styled('div')`
  margin-bottom: ${(p) => p.theme.spacing() * 3}px;
`;

const ResetPasswordDialog = ({
  open,
  onClose
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const onSubmit = (email: string) => {
    return firebase.auth().sendPasswordResetEmail(email);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle>Reset Your Password</DialogTitle>
      <DialogContent>
        <ResetPassword onSubmit={onSubmit} onCancel={onClose} />
      </DialogContent>
    </Dialog>
  );
};

const FormRowDouble = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-column-gap: 12px;
`;

const TermsAndConditions = styled('p')`
  color: ${(p) => p.theme.palette.grey[500]};
  font-size: 12px;
  text-align: left;
  line-height: 18px;

  a {
    border-bottom: 1px solid;
  }
`;

const AlreadyHaveAccount = styled('div')`
  margin-top: ${(p) => p.theme.spacing(4)}px;
  color: ${(p) => p.theme.palette.grey[500]};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;

  span {
    display: inline-block;
  }

  button {
    position: relative;
    top: 1px;
    min-width: auto;
    padding: 0;
    margin: 0 6px;
  }

  a {
    color: ${(p) => p.theme.palette.primary.main};
  }
`;

const SignupOptionsContainer = styled('div')`
  display: grid;
  grid-template-columns: 100%;
  grid-column-gap: ${(p) => p.theme.spacing(2)}px;
  grid-row-gap: ${(p) => p.theme.spacing(2)}px;
  align-items: center;
  margin-bottom: ${(p) => p.theme.spacing(1)}px;
`;

const SignupOptionButton = styled<typeof ButtonBase, { selected: boolean }>(
  ButtonBase
)`
  width: 100%;
  color: ${(p) =>
    p.selected
      ? p.theme.palette.grey[800]
      : p.theme.palette.grey[500]} !important;
  border-radius: ${(p) => p.theme.shape.borderRadius}px !important;
  padding: 0 !important;

  svg {
    margin-right: ${(p) => p.theme.spacing(1)}px;
  }

  img {
    margin-right: ${(p) => p.theme.spacing(1)}px;
    width: 16px;
  }
`;

const GoogleButtonWrapper = styled<'div', { isSignup: boolean }>('div')`
  margin-bottom: ${(p) => p.theme.spacing(2)}px;

  @media (max-width: 800px) {
    display: none;
  }

  .firebaseui-idp-list > .firebaseui-list-item {
    margin: 0 !important;
  }
  .firebaseui-idp-list {
    margin: 0 !important;
  }
  .firebaseui-card-content {
    padding: 0 !important;
  }
  .firebaseui-container {
    max-width: none !important;
    margin: 0 !important;
  }
  .firebaseui-idp-google > .firebaseui-idp-text {
    font-weight: 300;
    color: ${(p) => p.theme.palette.grey[700]} !important;
  }
  .mdl-button--raised {
    box-shadow: none;
    background: none !important;
    border: 0;
    border-radius: ${(p) => p.theme.shape.borderRadius}px !important;
    width: 100% !important;
    max-width: none;
    display: flex;
    justify-content: center;
    padding: ${(p) => p.theme.spacing(1)}px !important;
  }

  &:after {
    content: 'OR';
    position: relative;
    color: ${(p) => p.theme.palette.grey[500]};
    left: calc(50% - 12px);
    top: ${(p) => p.theme.spacing(1)}px;
  }
`;

const PreferencesGrid = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${(p) => p.theme.spacing(1)}px;
  grid-row-gap: ${(p) => p.theme.spacing(1)}px;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const AccountDetailsForm = ({
  currentUser,
  website
}: {
  currentUser: ICurrentUser;
  website: string;
}) => {
  const [loading, setLoading] = useState(false);
  const space = currentUser.space;

  useEffect(() => {
    tracking.sendOnboardingEvent({
      event: 'Onboarding: Create account',
      value: 0
    });

    try {
      const mixpanel = mp();
      mixpanel.set_group('spaces', currentUser.space.id);
      mixpanel.track('create_account', {
        spaces: [currentUser.space.id]
      });
    } catch (e) {
      console.error(e);
    }
    // eslint-disable-next-line
  }, []);

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    setLoading(true);

    const fbUser = firebase.auth().currentUser;

    if (!fbUser) {
      return;
    }

    await callFirebaseFunction(CF.space.setupAdditionalSpaceDefaults, {
      spaceId: currentUser.space.id
    }).catch(() => console.log('Setup of additional space resources failed'));

    try {
      await addDomainUnverified(currentUser.space.id, website);
      const mixpanel = mp();
      mixpanel.track('prefill_website', {
        url: website,
        spaces: [currentUser.space.id]
      });

      tracking.sendOnboardingEvent({
        event: 'Onboarding: Fill profile',
        value: 0
      });

      window.location.assign('/');
    } catch (error) {
      console.log(error.message);
      console.log(error.code);

      // This is not a problem, simply passs them through
      if (error.code === 'already-exists') {
        // TODO: Send analytics event
        window.location.assign('/');
      } else {
        throw error;
      }
    }
  };

  return (
    <div>
      <form onSubmit={onSubmit} style={{ textAlign: 'left' }}>
        <Typography
          variant="body1"
          component="p"
          style={{ marginBottom: '8px' }}
        >
          <strong>Choose your settings</strong>
        </Typography>
        <Typography
          variant="body1"
          component="p"
          color="textSecondary"
          style={{ marginBottom: '24px' }}
        >
          How should we show your affiliate commissions and analytics?
        </Typography>
        <PreferencesGrid>
          <SelectCurrency
            value={space.config.currency || DEFAULT_CURRENCY}
            onChange={(nextCurrency) => updateCurrency(space.id, nextCurrency)}
            label="Currency"
            fullWidth
          />
          <SelectTimezone
            value={space.config.tz || 'UTC'}
            onChange={(nextTz) => {
              updateTimezone(space.id, nextTz);
            }}
            label="Timezone"
            fullWidth
          />
        </PreferencesGrid>
        <div style={{ textAlign: 'right', marginTop: '28px' }}>
          <Button
            type="submit"
            size="large"
            variant="contained"
            disabled={loading}
            color="primary"
          >
            <ArrowRightCircle size={16} /> &nbsp; Continue
          </Button>
        </div>
      </form>
    </div>
  );
};

const NoWebsiteDialog = ({
  open,
  closeDialog
}: {
  open: boolean;
  closeDialog: () => void;
}) => {
  return (
    <Dialog open={open} onClose={closeDialog}>
      <DialogTitle>Can I use Affilimate without a website?</DialogTitle>
      <DialogContent>
        <Typography variant="body1" component="p" paragraph>
          No, Affilimate is not designed to be used without a website.
        </Typography>
        <Typography variant="body1" component="p" paragraph>
          While Affilimate tracks affiliate commissions from all traffic
          sources, our automatic revenue attribution features only work on
          websites because they require custom JavaScript to run.
        </Typography>
        <Typography variant="body1" component="p" paragraph>
          This means that to use Affilimate's core feature set, you must have a
          website, hosted on your own domain, where you can install custom
          JavaScript.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="default" variant="contained">
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Loader = styled(CircularProgress)`
  width: 20px !important;
  height: 20px !important;
  margin-right: ${(p) => p.theme.spacing(1)}px;

  svg {
    color: inherit;
  }
`;

const AuthenticateWithEmailForm = ({
  action,
  website,
  setWebsite
}: {
  action: 'signup' | 'login';
  website: string;
  setWebsite: (site: string) => void;
}) => {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [email, setEmail] = useState('');
  const { dialogOpen: open, closeDialog, openDialog } = useDialogState();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [warning, setWarning] = useState('');

  const handleChangeTermsAccepted = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTermsAccepted(event.target.checked);
  };

  useEffect(() => {
    const el = document.querySelector('input[id="email"]') as HTMLElement;
    if (el !== null) {
      el.focus();
    }
  }, []);

  const createUser = async () => {
    setWarning('');

    if (!email.length) {
      setWarning('Please provide an email address.');
      return;
    }

    const emailWarning = testEmail(email);
    if (emailWarning.length) {
      setWarning(emailWarning);
      return;
    }

    if (password.length < 8) {
      setWarning(
        "Please provide a password that's at least 8 characters long."
      );
      return;
    }

    if (!website.length) {
      setWarning('Please provide the URL of the site you want to set up.');
      return;
    }

    const websiteWarning = testWebsite(website);

    if (websiteWarning.length) {
      setWarning(websiteWarning);
      return;
    }

    if (!termsAccepted) {
      setWarning(
        'You must accept our terms and privacy policy to use our service. Please check the box to continue.'
      );
      return;
    }

    setLoading(true);

    return firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .catch((error) => {
        const errorMessage = error.message;
        setWarning(errorMessage);
        setLoading(false);
      });
  };

  const loginUser = () => {
    setWarning('');
    setLoading(true);

    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        const currentUrl = new URL(window.location.href);
        if (currentUrl.searchParams.has('invitationId')) {
          const id = currentUrl.searchParams.get('invitationId');
          window.location.href = `/accept-invitation/${id}`;
        } else {
          window.location.assign('/');
        }
      })
      .catch((error) => {
        const errorMessage = error.message;
        setWarning(errorMessage);
        setLoading(false);
      });
  };

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (action === 'signup') {
      createUser();
    } else if (action === 'login') {
      loginUser();
    }
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        {action === 'signup' && (
          <>
            <Typography
              variant="body1"
              component="p"
              style={{ marginBottom: '8px' }}
            >
              <strong>Create your account</strong>
            </Typography>
            <Typography
              variant="caption"
              color="textSecondary"
              component="p"
              gutterBottom
            >
              Start your 15-day free trial. No credit card required.
            </Typography>
          </>
        )}
        <TextField
          variant="outlined"
          name="email"
          type="email"
          id="email"
          label="Email address"
          placeholder="name@yourwebsite.com"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          autoComplete="email"
          margin="normal"
          fullWidth
        />
        <TextField
          variant="outlined"
          name="password"
          autoComplete={
            action === 'signup' ? 'new-password' : 'current-password'
          }
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          type="password"
          placeholder="8 characters or more"
          label="Password"
          margin="normal"
          fullWidth
        />
        {action === 'signup' && (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '12px',
                marginTop: '36px'
              }}
            >
              <Typography variant="body1" component="p">
                <strong>Add your first website</strong>
              </Typography>
              <div style={{ textAlign: 'right' }}>
                <Button
                  variant="text"
                  color="default"
                  onClick={openDialog}
                  style={{ color: '#BBB', padding: 0 }}
                >
                  <u>I don't have a website</u>
                </Button>
              </div>
            </div>
            <Typography
              variant="caption"
              color="textSecondary"
              component="p"
              gutterBottom
            >
              What's a website or blog where you're promoting affiliate links
              today?
            </Typography>
            <TextField
              variant="outlined"
              name="website"
              value={website}
              onChange={(e) => {
                setWebsite(e.target.value);
              }}
              placeholder="https://yourwebsite.com"
              label="Your Website URL"
              margin="normal"
              fullWidth
            />
          </>
        )}
        <FormRowDouble style={{ marginTop: '36px' }}>
          {action === 'signup' ? (
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  checked={termsAccepted}
                  onChange={handleChangeTermsAccepted}
                />
              }
              label={
                <TermsAndConditions>
                  I agree to Affilimate's{' '}
                  <a
                    href="https://affilimate.com/qs/terms-of-service/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    terms of service
                  </a>{' '}
                  and{' '}
                  <a
                    href="https://affilimate.com/qs/privacy-policy/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    privacy policy
                  </a>
                  .
                </TermsAndConditions>
              }
            />
          ) : (
            <div />
          )}
          <div style={{ textAlign: 'right' }}>
            <Button
              type="submit"
              size="large"
              variant="contained"
              disabled={loading}
              color="primary"
            >
              {loading ? (
                <>
                  <Loader />{' '}
                  {action === 'signup' ? 'Creating...' : 'Logging in...'}
                </>
              ) : action === 'signup' ? (
                <>
                  <ArrowRightCircle size={18} /> &nbsp; Get started now
                </>
              ) : (
                'Log in'
              )}
            </Button>
          </div>
        </FormRowDouble>
        {warning && <AlertBox variant="pending">{warning}</AlertBox>}
      </form>
      <NoWebsiteDialog open={open} closeDialog={closeDialog} />
    </>
  );
};

type ISignupOptions = 'email' | 'google';

const SignupOptions = ({
  selectedOption,
  onChangeOption,
  action
}: {
  selectedOption: ISignupOptions;
  onChangeOption: (opt: ISignupOptions) => void;
  action: 'signup' | 'login';
}) => {
  useAfterFirstRender(() => {
    firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(() => {
        // we might not be on this screen anymore, but we have also
        // no way to cancel to promise!
        if (document.getElementById(ID)) {
          ui.start(`#${ID}`, SIGNUP_CONFIG);
        }

        setTimeout(updateGoogleButtonText);
      });
  });

  const updateGoogleButtonText = () => {
    setTimeout(() => {
      const button = document.querySelector(
        'button[data-provider-id="google.com"] .firebaseui-idp-text-long'
      );
      if (button) {
        button.innerHTML =
          action === 'signup' ? 'Sign up with Google' : 'Log in with Google';
      }
    }, 100);
  };

  // We want to hide google login in most scenarios to
  // avoid spammy signups
  const allowGoogleLogin =
    window.location.href.indexOf('allowGoogleLogin') !== -1;

  useEffect(updateGoogleButtonText, [action]);

  return (
    <SignupOptionsContainer>
      <GoogleButtonWrapper
        isSignup={action === 'signup'}
        style={{ display: allowGoogleLogin ? 'block' : 'none' }}
      >
        <div id={ID} data-cy={CY.LOGIN.firebaseUi} />
      </GoogleButtonWrapper>
      <div>
        <SignupOptionButton
          selected={selectedOption === 'email'}
          onClick={() => {
            onChangeOption('email');
          }}
        >
          <Mail size={16} />{' '}
          {action === 'signup' ? 'Sign up with Email' : 'Log in with Email'}
        </SignupOptionButton>
      </div>
    </SignupOptionsContainer>
  );
};

const StepperContainer = styled('div')``;
const localStorage = (window as any).localStorage;

export const Login = ({
  creatingNewAccount
}: {
  creatingNewAccount: boolean | undefined;
}) => {
  const user = useCurrentUser();
  const hasSignupUrl = window.location.pathname.indexOf('/signup') !== -1;
  const action = hasSignupUrl ? 'signup' : 'login';
  const [open, setDialogOpen] = useState(false);
  const [website, updateWebsite] = useState(localStorage.website || '');
  const [selectedAuthOption, setSelectedAuthOption] = useState<
    'email' | 'google'
  >('email');
  const setWebsite = (website: string) => {
    updateWebsite(website);
    localStorage.website = website;
  };

  useEffect(() => {
    if (hasSignupUrl) {
      window.location.assign('/');
    }
  }, [hasSignupUrl]);

  const signedUpOnSecondStep = action === 'signup' && user;

  if (hasSignupUrl) {
    return null;
  }

  return (
    <LoginWrapper>
      <RightSide>
        <RightSideInner>
          <MiniDashboard>
            <img src="/images/mini-dashboard.png" alt="Affilimate" />
          </MiniDashboard>
          {action === 'signup' && (
            <>
              <Typography variant="h6" component="h2" paragraph>
                Start tracking like the most successful content publishers today
              </Typography>
            </>
          )}
          {action === 'login' && (
            <Typography variant="h6" component="h2">
              Welcome to your Affilimate dashboard. Please log in to continue.
            </Typography>
          )}
        </RightSideInner>
      </RightSide>
      <LeftSide>
        <LeftSideInner>
          <LeftSideContent>
            <div style={{ position: 'relative', textAlign: 'center' }}>
              <div style={{ textAlign: 'center' }}>
                <img
                  src="https://storage.googleapis.com/affilimate-assets/logos/logo-black-full.png"
                  width="120px"
                  alt="Affilimate"
                  style={{ display: 'block', margin: '12px auto' }}
                />
              </div>
              {action === 'signup' && (
                <StepperContainer>
                  <Stepper
                    activeStep={!!user ? 1 : 0}
                    style={{ backgroundColor: 'transparent' }}
                  >
                    <Step key={0} completed={!!user}>
                      <StepLabel>Create account</StepLabel>
                    </Step>
                    <Step key={1}>
                      <StepLabel>Choose settings</StepLabel>
                    </Step>
                    <Step key={2}>
                      <StepLabel>Start tracking</StepLabel>
                    </Step>
                  </Stepper>
                </StepperContainer>
              )}
            </div>
          </LeftSideContent>
          <Card
            elevation={8}
            style={{ padding: '24px', maxWidth: '450px', textAlign: 'left' }}
          >
            {!user && (
              <div>
                {action === 'login' && (
                  <SignupOptions
                    selectedOption={selectedAuthOption}
                    onChangeOption={setSelectedAuthOption}
                    action={action}
                  />
                )}
                {selectedAuthOption === 'email' && (
                  <AuthenticateWithEmailForm
                    action={action}
                    website={website}
                    setWebsite={setWebsite}
                  />
                )}
              </div>
            )}
            {signedUpOnSecondStep && (
              <AccountDetailsForm currentUser={user} website={website} />
            )}
          </Card>
          {action === 'signup' && !user && (
            <AlreadyHaveAccount>
              <span>Already have an account?</span>{' '}
              <Button
                variant="text"
                color="primary"
                onClick={() => {
                  window.location.assign('/');
                }}
              >
                Log in
              </Button>{' '}
              <span>or</span>{' '}
              <Button
                variant="text"
                color="primary"
                onClick={() => {
                  setDialogOpen(true);
                }}
              >
                reset your password
              </Button>
            </AlreadyHaveAccount>
          )}
          {action === 'signup' && user && (
            <AlreadyHaveAccount>
              <span>Made a new account by mistake?</span>{' '}
              <Button
                variant="text"
                color="primary"
                onClick={() => {
                  return firebase.auth().signOut();
                }}
              >
                Log out
              </Button>
            </AlreadyHaveAccount>
          )}
          {action === 'login' && (
            <AlreadyHaveAccount>
              <Button
                variant="text"
                color="primary"
                onClick={() => {
                  setDialogOpen(true);
                }}
              >
                Reset your password
              </Button>
            </AlreadyHaveAccount>
          )}
          <ResetPasswordDialog
            open={open}
            onClose={() => {
              setDialogOpen(false);
            }}
          />
        </LeftSideInner>
      </LeftSide>
    </LoginWrapper>
  );
};
