import React from 'react';
import { Card } from '@material-ui/core';
import { css } from '../../../emotion';
import Typography from '@material-ui/core/Typography';
import { Skeleton } from '@material-ui/lab';
import { FlexContainer } from '../../../layout/Flex';
import { metricTitle } from '../../../services/analyticsV2/metrics';
import { useSpaceCurrency } from '../../../services/useSpaceCurrency';
import {
  goalPercent,
  Totals,
  TotalsField,
  TotalsObject
} from '../service/totals';
import {
  formatChartCurrency,
  formatChartNumber
} from '../../../components/Charts/Util';
import {
  CompletedCampaign,
  isCampaignRunning,
  RunningCampaign
} from '../service';
import { TargetStatus } from './TargetStatus';

type CardName =
  | 'earnings'
  | 'flatSpend'
  | 'gmv'
  | 'goal'
  | 'orders'
  | 'aov'
  | 'c'
  | 'p'
  | 'v';

const BaseCard: React.FC<{
  totals: Totals;
  title: string;
  children: (values: TotalsObject) => React.ReactNode;
}> = ({ totals, title, children }) => {
  const [values, loading] = totals;
  return (
    <Card
      className={css(() => ({
        flex: 1
      }))}
    >
      <Typography variant="body2" gutterBottom>
        {title}
      </Typography>
      {loading || !values ? (
        <Skeleton
          className={css(() => ({
            width: '60%',
            fontSize: '1.5rem'
          }))}
        />
      ) : (
        children(values)
      )}
    </Card>
  );
};

const CurrencyCard: React.FC<{
  title: string;
  totals: Totals;
  field: TotalsField;
}> = ({ title, totals, field }) => {
  const currency = useSpaceCurrency();
  return (
    <BaseCard totals={totals} title={title}>
      {(values) => (
        <Typography variant="h5">
          {formatChartCurrency(values[field], currency, { excludeCents: true })}
        </Typography>
      )}
    </BaseCard>
  );
};

const NumberCard: React.FC<{
  title: string;
  totals: Totals;
  field: TotalsField;
}> = ({ title, totals, field }) => {
  return (
    <BaseCard totals={totals} title={title}>
      {(values) => (
        <Typography variant="h5">{formatChartNumber(values[field])}</Typography>
      )}
    </BaseCard>
  );
};

const GoalCard: React.FC<{
  campaign: RunningCampaign | CompletedCampaign;
  totals: Totals;
}> = ({ totals, campaign }) => {
  const currency = useSpaceCurrency();
  return (
    <BaseCard totals={totals} title="Goal">
      {(values) => {
        const goal = formatChartCurrency(values.gmvGoal, currency, {
          excludeCents: true
        });
        const percentage = goalPercent(values);
        return (
          <FlexContainer alignItems="baseline" spacing={0.5}>
            <Typography variant="h5">{goal}</Typography>
            <Typography variant="body1" color="textSecondary">
              ({percentage})
            </Typography>
            {isCampaignRunning(campaign) ? (
              <TargetStatus gmv={values.gmv} campaign={campaign} />
            ) : null}
          </FlexContainer>
        );
      }}
    </BaseCard>
  );
};

const MetricCard = ({
  name,
  totals,
  campaign
}: {
  campaign: CompletedCampaign | RunningCampaign;
  totals: Totals;
  name: CardName;
}) => {
  switch (name) {
    case 'c':
      return <NumberCard title={metricTitle('c')} totals={totals} field="c" />;
    case 'p':
      return <NumberCard title={metricTitle('p')} totals={totals} field="p" />;
    case 'v':
      return <NumberCard title={metricTitle('v')} totals={totals} field="v" />;
    case 'earnings':
      return (
        <CurrencyCard
          title={metricTitle('commission_sum_net')}
          totals={totals}
          field="commission"
        />
      );
    case 'flatSpend':
      return (
        <CurrencyCard title="Flat spend" totals={totals} field="flatSpend" />
      );
    case 'gmv':
      return (
        <CurrencyCard
          title={metricTitle('gmv_sum_net')}
          totals={totals}
          field="gmv"
        />
      );
    case 'goal':
      return <GoalCard totals={totals} campaign={campaign} />;
    case 'orders':
      return (
        <NumberCard
          title={metricTitle('order_count_net')}
          totals={totals}
          field="orderCount"
        />
      );
    case 'aov':
      return (
        <CurrencyCard
          title={metricTitle('aov_net')}
          totals={totals}
          field="aov"
        />
      );
  }
};

export const CampaignTotals = ({
  totals,
  cards,
  campaign
}: {
  totals: Totals;
  cards: CardName[];
  campaign: CompletedCampaign | RunningCampaign;
}) => {
  return (
    <FlexContainer spacing={2}>
      {cards.map((name) => (
        <MetricCard name={name} totals={totals} campaign={campaign} />
      ))}
    </FlexContainer>
  );
};
