import { Button, Hidden } from '@material-ui/core';
import { partition } from 'lodash';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { AddAdditionalDomainModal } from '../../../../components/AddDomainModal';
import { NoPermissions } from '../../../../components/NoPermissions';
import {
  SearchInput,
  useSearchRegexp
} from '../../../../components/SearchInput';
import {
  TimeframePickerDense,
  useRangeDataForTimeframe,
  useStandardOptions,
  useTimeframeFromUrl
} from '../../../../components/TimeframePicker';
import { Hint } from '../../../../components/Typography';
import { DEFAULT_CURRENCY } from '../../../../domainTypes/space';
import { styled } from '../../../../emotion';
import { useDialogState } from '../../../../hooks/useDialogState';
import { PageBody } from '../../../../layout/PageBody';
import {
  DEFAULT_OFFSET,
  PageToolbar,
  PageToolbarSection
} from '../../../../layout/PageToolbar';
import { useRoutes, useStringQueryParam } from '../../../../routes';
import { useChannels } from '../../../../services/channels';
import {
  useCurrentUser,
  useCurrentUserScopes,
  useHasCurrentUserRequiredScopes
} from '../../../../services/currentUser';
import { useFeatureEnabled } from '../../../../services/features';
import { useTrackMixpanelView } from '../../../../services/mixpanel';
import { pluralize } from '../../../../services/pluralize';
import { createScan } from '../../../../services/scan';
import { addDomain } from '../../../../services/space';
import { SummaryContainer } from '../../components/SummaryContainer';
import {
  ChannelSummary,
  DomainSummary,
  TotalSummary
} from './components/DomainSummary';

const CenteredContainer = styled('div')`
  margin-top: ${(p) => p.theme.spacing(3)}px;
  display: flex;
  justify-content: center;
`;

export const DashboardOverviewV2 = () => {
  const { ROUTES, goTo } = useRoutes();
  const { space } = useCurrentUser();
  const scopes = useCurrentUserScopes();
  const canViewDomains = scopes.has('domains.view');
  const canAddDomains = scopes.has('domains.create');
  const canAddChannels = scopes.has('channels.create');

  const { options, defaultOption } = useStandardOptions();
  const [timeframe, setTimeframe] = useTimeframeFromUrl(defaultOption.value);
  const { range, compareRange, tz } = useRangeDataForTimeframe(timeframe);
  const [search, setSearch] = useStringQueryParam('q');
  const { dialogOpen, openDialog, closeDialog } = useDialogState(false);
  const [channels = []] = useChannels(space.id);
  const hasChannels = useFeatureEnabled('CHANNELS');

  const currency = space.config.currency || DEFAULT_CURRENCY;
  const searchRegexp = useSearchRegexp(search);

  const filteredDomains = searchRegexp
    ? space.domains.filter((d) => searchRegexp.exec(d.url))
    : space.domains;
  const filteredChannels = searchRegexp
    ? channels.filter((c) => searchRegexp.exec(c.data.name))
    : channels;
  const [activeDomains, inactiveDomains] = partition(
    filteredDomains,
    (d) => d.active
  );

  return (
    <PageBody noTopPadding>
      <PageToolbar sticky offset={DEFAULT_OFFSET}>
        <PageToolbarSection spacing="wide" flex={2}>
          {(canAddDomains || canAddChannels) && (
            <Hidden xsDown>
              <Button variant="contained" color="primary" onClick={openDialog}>
                Add property
              </Button>
            </Hidden>
          )}
          <Hidden smDown>
            <SearchInput
              value={search}
              onChange={setSearch}
              placeholder="Find property"
              width={300}
              size="small"
            />
          </Hidden>
          <Hidden mdUp>
            <SearchInput
              value={search}
              onChange={setSearch}
              placeholder="Find property"
              width={150}
              size="small"
            />
          </Hidden>
        </PageToolbarSection>
        <PageToolbarSection flex={1} justifyContent="flex-end">
          <TimeframePickerDense
            value={timeframe}
            onChange={setTimeframe}
            options={options}
          />
        </PageToolbarSection>
      </PageToolbar>
      {canViewDomains && !search && (
        <SummaryContainer>
          <TotalSummary
            spaceId={space.id}
            tz={tz}
            currency={currency}
            range={range}
            compareRange={compareRange}
          />
        </SummaryContainer>
      )}

      {canViewDomains &&
        activeDomains.map((d) => {
          return (
            <SummaryContainer key={d.url}>
              <DomainSummary
                spaceId={space.id}
                domain={d}
                tz={tz}
                currency={currency}
                range={range}
                compareRange={compareRange}
              />
            </SummaryContainer>
          );
        })}

      {hasChannels &&
        filteredChannels.length > 0 &&
        filteredChannels.map((channel) => (
          <SummaryContainer key={channel.id}>
            <ChannelSummary
              spaceId={space.id}
              channel={channel}
              tz={tz}
              currency={currency}
              range={range}
              compareRange={compareRange}
            />
          </SummaryContainer>
        ))}

      {!!inactiveDomains.length && canViewDomains && (
        <CenteredContainer>
          <Hint>
            {pluralize(
              'additional inactive domain',
              inactiveDomains.length,
              true
            )}
            . <Link to={ROUTES.settings.general.url()}>Open Settings</Link> if
            you wish to re-activate them.
          </Hint>
        </CenteredContainer>
      )}

      <AddAdditionalDomainModal
        open={dialogOpen}
        onClose={closeDialog}
        onVerifyDomain={(domain) => addDomain(space.id, domain)}
        onScan={(ds) =>
          createScan(ds, 'DOMAIN').then((doc) =>
            goTo(ROUTES.links.scans.details.url(doc.id))
          )
        }
      />
    </PageBody>
  );
};

export const PageDashboardOverviewV2 = () => {
  useTrackMixpanelView('view_dashboard');
  const [canView] = useHasCurrentUserRequiredScopes(['dashboard.view']);

  return (
    <>
      <Helmet>
        <title>Affilimate | Dashboard</title>
      </Helmet>
      {canView ? <DashboardOverviewV2 /> : <NoPermissions />}
    </>
  );
};
