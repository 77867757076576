import { Truncated } from '../../../../../../components/Truncated';
import React, { useMemo } from 'react';
import { RowsRenderer } from '../../../../../../components/GroupableList';
import { FlexContainer } from '../../../../../../layout/Flex';
import { AnalyticsResponseRowWithComparison } from '../../../../../../domainTypes/analytics_v2';
import { Centered } from '../../../../../../layout/Centered';
import { Loader } from '../../../../../../components/Loader';
import { Card } from '@material-ui/core';
import { dbColumn, utmParameterTitle, UtmParameterType } from '../service/utm';
import { createSearchBox } from '../../../../../../components/analytics_v2/SearchBox';
import {
  PAGE_SIZE,
  useUtmCountQuery,
  useUtmExportQuery,
  useUtmMetricsQuery
} from '../service/data';
import { ColumnSelector } from '../../../../../../components/Table/ColumnSelector';
import {
  ALL_COLUMN_NAMES,
  ColumnDefinition,
  DEFAULT_VISIBLE_COLUMN_NAMES,
  useTable
} from '../../../../../../components/analytics_v2/Table';
import { CustomPagination } from '../../../../../../components/CustomPagination';
import { useRoutes } from '../../../../../../routes';
import { WithHoverIndicator } from '../../../../../../components/WithHoverIndicator';
import { Metric } from '../../../../../../services/analyticsV2/metrics';
import { ExportQueryButton } from '../../../../../../components/ExportQuery';
import { capitalize } from 'lodash';
import { createUTMFilterDefinition } from '../../../../../../components/analytics_v2/Filters/filters';

interface TableProps {
  selectedParameter: UtmParameterType;
}

export const UTM_PARAM_COLUMN_NAME = 'utm';
type UtmParamColumn = 'utm';
type Column = UtmParamColumn | Metric;
const availableColumns: Column[] = [UTM_PARAM_COLUMN_NAME, ...ALL_COLUMN_NAMES];
const defaultVisibleColumns: Column[] = [
  'utm',
  ...DEFAULT_VISIBLE_COLUMN_NAMES
];

const utmParamColumn = (
  utmParam: UtmParameterType
): ColumnDefinition<UtmParamColumn> => {
  const key = dbColumn(utmParam);
  return {
    column: {
      key: UTM_PARAM_COLUMN_NAME,
      head: () => utmParameterTitle(utmParam),
      cell: (p: AnalyticsResponseRowWithComparison) => (
        <WithHoverIndicator>
          <Truncated title={p.group[key]} />
        </WithHoverIndicator>
      ),
      align: 'left',
      sortable: true,
      width: 200,
      flexGrow: 4
    },
    sorter: {
      key: UTM_PARAM_COLUMN_NAME,
      items: {
        sort: (p: AnalyticsResponseRowWithComparison) => p.group[key],
        dir: 'asc'
      }
    }
  };
};

const [SearchBox, useSearch] = createSearchBox('q');

export const UtmTable: React.FC<TableProps> = ({ selectedParameter }) => {
  const { ROUTES } = useRoutes();
  const columnDefinitions = useMemo<
    Record<UtmParamColumn, ColumnDefinition<UtmParamColumn>>
  >(
    () => ({
      utm: utmParamColumn(selectedParameter)
    }),
    [selectedParameter]
  );

  const {
    tableProps,
    columnSelectorProps,
    paginationSelectorProps,
    pagination,
    orderBy,
    metrics
  } = useTable(availableColumns, columnDefinitions, {
    pageSize: PAGE_SIZE,
    defaultSortColumn: 'c',
    columnSelectorParamName: 'utmcols',
    defaultVisibleColumns
  });

  const search = useSearch(dbColumn(selectedParameter));

  const [campaigns, loadingCampaigns] = useUtmMetricsQuery(
    selectedParameter,
    metrics,
    search,
    orderBy,
    pagination
  );
  const [campaignsCount = 1] = useUtmCountQuery(selectedParameter, search);
  const exportQuery = useUtmExportQuery(
    selectedParameter,
    metrics,
    search,
    orderBy
  );

  return (
    <>
      <FlexContainer justifyContent="space-between" marginBottom={1}>
        <FlexContainer>
          <SearchBox
            placeholder={`Search by ${selectedParameter}`}
            width={300}
          />
        </FlexContainer>
        <FlexContainer>
          <ColumnSelector {...columnSelectorProps} />
          <CustomPagination
            {...paginationSelectorProps}
            count={Math.ceil(campaignsCount / PAGE_SIZE)}
            siblingCount={0}
          />
          <ExportQueryButton
            title={capitalize(selectedParameter)}
            reportType="utm"
            query={exportQuery}
          />
        </FlexContainer>
      </FlexContainer>
      {loadingCampaigns || !campaigns ? (
        <Card>
          <Centered height={350}>
            <Loader size={32}></Loader>{' '}
          </Centered>
        </Card>
      ) : (
        <RowsRenderer
          {...tableProps}
          renderHead={true}
          rowToHref={(d) =>
            ROUTES.content.overview_v2.url([
              createUTMFilterDefinition(selectedParameter, [
                d.group[dbColumn(selectedParameter)]
              ])
            ])
          }
          headProps={{
            sticky: true,
            offset: 56
          }}
          // TODO: common selector
          rows={campaigns.rows}
          rowToKey={(d) => d.group[dbColumn(selectedParameter)]}
        />
      )}
    </>
  );
};
