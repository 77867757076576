const DEFAULT_WIDTH = 16;

type LogoProps = {
  imgSrc: string;
  width: number;
};

export interface IPartner {
  key: string;
  name: string;
  matches: (url: string) => boolean;
  logo?: LogoProps;
  portalUrl: string | null;
  known: boolean;
  color: string;
  isNetwork?: boolean;
  forcePartnerKey?: boolean; // to propagate the pK to the product map. Useful for partners where smart labelling cannot be determined easily by looking at a domain, such as Post Affiliate Pro
}

const matchRegexp = (regexp: RegExp): ((url: string) => boolean) => {
  return (url) => regexp.test(url);
};

const matchUrlObj = (fn: (u: URL) => boolean): ((url: string) => boolean) => {
  return (url) => {
    try {
      return fn(new URL(url));
    } catch {
      return false;
    }
  };
};

const matchesAny = (urls: string[], url: string) => {
  return urls.reduce((result, u) => {
    return result || url.indexOf(u) !== -1;
  }, false);
};

export const PARTNERS: IPartner[] = [
  {
    key: 'direct',
    name: 'Direct',
    matches: () => false,
    known: true,
    portalUrl: null,
    color: '#000'
  },
  {
    key: 'affilimate',
    name: 'Affilimate',
    matches: matchRegexp(/^https?:\/\/(affilimate.com|affilimate.io)(.+)?aid=/),
    known: true,
    portalUrl: 'https://affilimate.com/settings/affiliate',
    logo: {
      imgSrc: 'affilimate.png',
      width: DEFAULT_WIDTH
    },
    color: '#18202c'
  },
  {
    key: 'affilae',
    name: 'Affilae',
    matches: matchRegexp(
      /((\?|&)ae=\d+)|(^https?:\/\/c3po\.link)|(^https?:\/\/lb\.affilae\.com\/r\/)|(#ae\d{1,3}($|&))/
    ),
    known: true,
    portalUrl: 'https://app.affilae.com',
    logo: {
      imgSrc: 'affilae.svg',
      width: DEFAULT_WIDTH
    },
    color: '#fa541c',
    isNetwork: true,
    forcePartnerKey: true
  },
  {
    key: 'admitad',
    name: 'AdmitAd',
    matches: matchRegexp(/^https?:\/\/(ad.admitad.com)|(fas.st)/),
    known: true,
    portalUrl: 'https://store.admitad.com/en/sso/login/',
    logo: {
      imgSrc: 'admitad.png',
      width: DEFAULT_WIDTH
    },
    isNetwork: true,
    color: '#8c75e7'
  },
  {
    key: 'adsbymoney',
    name: 'Ads by Money',
    matches: matchRegexp(/^https?:\/\/secure.money.com\/pr\//),
    known: true,
    portalUrl: 'https://www.adsbymoney.com',
    logo: {
      imgSrc: 'adsbymoney.ico',
      width: DEFAULT_WIDTH
    },
    isNetwork: true,
    color: '#22205d'
  },
  {
    key: 'affiliateWp',
    name: 'Affiliate WP',
    matches: (url) => {
      const paramsRegex = /^https?:\/\/(.+)(ref=(.+)|(\/(ref|aff|sap)\/(.+)\/)|(promo=(.+)))|((.+)ref=(.+)&campaign=(.+))|(\/refer\/(.+))/;
      const domains = [
        'africanpure.co',
        'aminoasylum.shop',
        'biotechpeptides.com',
        'brandsgateway.com',
        'buymodafinilonline.com',
        'chemyo.com',
        'happyjuicy.nl',
        'hcgains.com',
        'juicebro.com',
        'marijuanasa.co.za',
        'modafinilxl.com',
        'purerawz.co',
        'sportstechnologylabs.com',
        'genx.bio',
        'paradigmpeptides.com',
        'behemothlabz.com',
        'swisschems.is',
        'testa-omega3.com',
        'vitaily.nl',
        'sap.je',
        'watertogousa.com',
        'travelingmailbox.com',
        'linkwhisper.com',
        'uksarms.com'
      ];
      return matchesAny(domains, url) && matchRegexp(paramsRegex)(url);
    },
    known: true,
    portalUrl: null,
    logo: {
      imgSrc: 'affiliatewp.png',
      width: DEFAULT_WIDTH
    },
    color: '#820014'
  },
  {
    key: 'travelpayouts',
    name: 'Travelpayouts',
    matches: matchRegexp(
      /^https?:\/\/(tp\.media)|(https:\/\/(.+)\.tp\.st)|(https:\/\/(.+)\.travelpayouts\.com\/click)/
    ),
    logo: {
      imgSrc: 'travelpayouts.png',
      width: DEFAULT_WIDTH
    },
    known: true,
    isNetwork: true,
    forcePartnerKey: false,
    portalUrl: 'https://app.travelpayouts.com',
    color: '#1890ff'
  },
  {
    key: 'affiliatly',
    name: 'Affiliatly',
    matches: (url) => {
      return (
        matchRegexp(/(\?|&)aff=\d{1,3}/)(url) &&
        url.indexOf('aff.php') === -1 &&
        url.indexOf('purevpn.com') === -1
      );
    },
    logo: {
      imgSrc: 'affiliatly.ico',
      width: DEFAULT_WIDTH
    },
    known: true,
    forcePartnerKey: true,
    portalUrl: null,
    color: '#69c0ff'
  },
  {
    key: 'amazon',
    name: 'Amazon',
    matches: matchRegexp(
      /^https?:\/\/(amzn\.to|aax-us-east.amazon-adsystem.com|www\.amazon.(com|de|co.uk|ca|com.br|co.au|cn|fr|in|it|co.jp|com.mx|nl|sg|es|com.tr|ae)(.+)?(((\?|&)tag=)|\/exec\/))|(buy\.geni\.us\/Proxy\.ashx\?(.+)GR_URL=https%3A%2F%2Fwww\.amazon\.)/
    ),
    logo: {
      imgSrc: 'amazon.svg',
      width: DEFAULT_WIDTH
    },
    known: true,
    portalUrl: 'https://affiliate-program.amazon.com',
    color: '#fa8c16'
  },
  {
    key: 'airbnb',
    name: 'Airbnb Associates',
    matches: matchRegexp(/airbnb.com\/associates\/[0-9]+/),
    logo: {
      imgSrc: 'airbnb.png',
      width: DEFAULT_WIDTH
    },
    known: true,
    portalUrl: 'https://airbnb.com',
    color: '#f759ab'
  },
  {
    key: 'bol',
    name: 'Bol.com',
    matches: matchRegexp(
      /^https?:\/\/(partner|partnerprogramma)\.bol\.com\/click\/click/
    ),
    logo: {
      imgSrc: 'bol.png',
      width: DEFAULT_WIDTH
    },
    known: true,
    portalUrl: 'https://login.bol.com/login?client_id=apm',
    color: '#2f54eb'
  },
  {
    key: 'odyssey',
    name: 'Odyssey',
    matches: matchRegexp(/(mc=\d+&c=\w+)|(utm_source=Odyssey_Partnerships)/),
    logo: {
      imgSrc: 'odyssey.ico',
      width: DEFAULT_WIDTH
    },
    known: true,
    forcePartnerKey: true,
    isNetwork: true,
    portalUrl: 'https://partnerships.odysseyattribution.co/login',
    color: '#ffc53d'
  },
  {
    key: 'maxbounty',
    name: 'MaxBounty',
    matches: matchRegexp(
      /^https?:\/\/(www\.)?mb[0-9]{2,3}|afflat3[a-z][1-2].com\/lnk.asp/
    ),
    logo: {
      imgSrc: 'maxbounty.jpeg',
      width: DEFAULT_WIDTH
    },
    known: true,
    isNetwork: true,
    forcePartnerKey: true,
    portalUrl: 'https://affiliates.maxbounty.com',
    color: '#cf1322'
  },
  {
    key: 'financeads',
    name: 'financeAds',
    matches: matchRegexp(/^https?:\/\/www\.financeads.net\/tc.php/),
    logo: {
      imgSrc: 'financeads.ico',
      width: DEFAULT_WIDTH
    },
    known: true,
    isNetwork: true,
    portalUrl: 'https://login.financeads.net',
    color: '#820014'
  },
  {
    key: 'daisycon',
    name: 'Daisycon',
    matches: matchRegexp(
      /^https?:\/\/[a-z0-9]{3,5}\.(net|nl)\/c\/\?si=[0-9]{1,}/
    ),
    logo: {
      imgSrc: 'daisycon.jpeg',
      width: DEFAULT_WIDTH
    },
    known: true,
    forcePartnerKey: true,
    isNetwork: true,
    portalUrl: 'https://my.daisycon.com',
    color: '#40CC7C'
  },
  {
    key: 'effiliation',
    name: 'Effiliation',
    matches: matchRegexp(/^https?:\/\/track\.effiliation\.com/),
    logo: {
      imgSrc: 'effiliation.png',
      width: DEFAULT_WIDTH
    },
    known: true,
    isNetwork: true,
    portalUrl: 'https://www.effiliation.com/en/publishers-area/',
    color: '#931680'
  },
  {
    key: 'kutoku',
    name: 'Kutoku',
    matches: matchRegexp(/(\?|&)ktk=[A-Za-z0-9]+/),
    logo: {
      imgSrc: 'kutoku.png',
      width: DEFAULT_WIDTH
    },
    known: true,
    isNetwork: true,
    forcePartnerKey: true,
    portalUrl: 'https://dashboard.kutoku.com/sign-in',
    color: '#5d6fd3'
  },
  {
    key: 'digidip',
    name: 'Digidip',
    matches: matchRegexp(/https?:\/\/(.+)\.digidip\.net\//),
    logo: {
      imgSrc: 'digidip.ico',
      width: DEFAULT_WIDTH
    },
    known: true,
    isNetwork: true,
    forcePartnerKey: true,
    portalUrl: 'https://digidip.net/publisher/login',
    color: '#3b3b3b'
  },
  {
    key: 'flexoffers',
    name: 'FlexOffers',
    matches: matchRegexp(/^https?:\/\/(track.flexlinks(pro)?.com)|(fxo.co)/),
    logo: {
      imgSrc: 'flexoffers.png',
      width: DEFAULT_WIDTH
    },
    known: true,
    isNetwork: true,
    portalUrl: 'https://publisherpro.flexoffers.com/Login',
    color: '#002766'
  },
  {
    key: 'howl',
    name: 'Howl (Narrativ)',
    matches: matchRegexp(
      /^https?:\/\/((howl\.me)|(shop-links.co))\/[a-zA-Z0-9]+/
    ),
    logo: {
      imgSrc: 'howl.png',
      width: DEFAULT_WIDTH
    },
    known: true,
    isNetwork: true,
    portalUrl: 'https://app.planethowl.com/signin',
    color: '#5241ef'
  },
  {
    key: 'redventures',
    name: 'Red Ventures',
    matches: matchRegexp(
      /^https?:\/\/(oc\.brcclx\.com\/)|(www\.bankrate\.com\/landing\/)/
    ),
    logo: {
      imgSrc: 'redventures.ico',
      width: DEFAULT_WIDTH
    },
    known: true,
    isNetwork: true,
    portalUrl: 'https://affiliates.rvmedianetwork.com/login',
    color: '#b2292e'
  },
  {
    key: 'ebay',
    name: 'eBay Partner Network',
    matches: matchRegexp(
      /^(https:\/\/www\.ebay\.((com)|(co\.uk))(.+)campid)|(https:\/\/ebay\.us)/
    ),
    logo: {
      imgSrc: 'ebay.ico',
      width: DEFAULT_WIDTH
    },
    known: true,
    portalUrl: 'https://partner.ebay.com/',
    color: '#597ef7'
  },
  {
    key: 'bluehost',
    name: 'Bluehost',
    matches: matchRegexp(/^https?:\/\/www\.bluehost.com\/track\/(.)+/),
    logo: {
      imgSrc: 'bluehost.ico',
      width: DEFAULT_WIDTH
    },
    known: true,
    portalUrl: 'https://www.bluehost.com/cgi/partner',
    color: '#2f54eb'
  },
  {
    key: 'civitatis',
    name: 'Civitatis',
    matches: matchRegexp(/civitatis\.com(.+)?aid=\d+/),
    logo: {
      imgSrc: 'civitatis.png',
      width: DEFAULT_WIDTH
    },
    known: true,
    portalUrl: null,
    color: '#f759ab'
  },
  {
    key: 'goldenfrog',
    name: 'Goldenfrog',
    matches: matchRegexp(
      /^https:\/\/(get\.vyprvpn\.com\/refer\/)|(www\.goldenfrog\.com(.+)aff_id=\d+)/
    ),
    logo: {
      imgSrc: 'goldenfrog.png',
      width: DEFAULT_WIDTH
    },
    known: true,
    portalUrl: null,
    color: '#722ed1'
  },
  {
    key: 'clickfunnels',
    name: 'ClickFunnels',
    matches: matchRegexp(
      /^https:\/\/(www\.clickfunnels\.com)|(partnerpromotions)(.+)affiliate_id=\d+/
    ),
    logo: {
      imgSrc: 'click-funnels.svg',
      width: DEFAULT_WIDTH
    },
    known: true,
    portalUrl: null,
    color: '#40a9ff'
  },
  {
    key: 'keap',
    name: 'Keap',
    matches: matchRegexp(/^https:\/\/[a-z0-9]+\.isrefer\.com\/go\//),
    logo: {
      imgSrc: 'keap.svg',
      width: DEFAULT_WIDTH
    },
    known: true,
    portalUrl: null,
    color: '#52c41a'
  },
  {
    key: 'leaddyno',
    name: 'LeadDyno',
    matches: matchRegexp(/(^https?:\/\/lddy.no)|((\?|&)afmc=(.+))/),
    logo: {
      imgSrc: 'leaddyno.jpeg',
      width: DEFAULT_WIDTH
    },
    known: true,
    portalUrl: null,
    color: '#0050b3'
  },
  {
    key: 'everflow',
    name: 'Everflow',
    matches: matchRegexp(
      /((.)+oid=(.)+affid=\d+)|((.)+affid=\d+(.)+oid=(.))|((([a-z0-9]+(trk|clk)\.com)|(www\.pubtrack\.co|www\.fasttrack05\.com|track\.proofreadanywhere\.com|www\.rm2djk4ss\.com))\/(cmp\/)?[A-Z0-9]{5,8}\/[A-Z0-9]{5,8})/
    ),
    logo: {
      imgSrc: 'everflow.png',
      width: DEFAULT_WIDTH
    },
    known: true,
    portalUrl: null,
    forcePartnerKey: true,
    color: '#722ed1'
  },
  {
    key: 'skimlinks',
    name: 'Skimlinks',
    matches: matchRegexp(
      /^https?:\/\/(fave.co)|(go.skimresources.com)|(go.redirectingat.com)|(id=[0-9A-Z]+&xs=[0-9]&url=(.+))/
    ),
    logo: {
      imgSrc: 'skimlinks.jpg',
      width: DEFAULT_WIDTH
    },
    known: true,
    portalUrl: 'https://hub.skimlinks.com',
    color: '#40a9ff',
    forcePartnerKey: true,
    isNetwork: true
  },
  {
    key: 'belboon',
    name: 'Belboon',
    matches: matchRegexp(/\/ts\/i\d+\/tsc\?amc=con\.blbn/),
    logo: {
      imgSrc: 'belboon.png',
      width: DEFAULT_WIDTH
    },
    known: true,
    portalUrl: 'https://partner.service.belboon.com/app/index.html#/login',
    color: '#01718d',
    forcePartnerKey: true,
    isNetwork: true
  },
  // Matches any leftover links that don't belong to a
  // more specific Ingenious Technologies integration
  {
    key: 'ingenioustech',
    name: 'Ingenious Technologies',
    matches: matchRegexp(/(\?|&)(amc=)/),
    logo: {
      imgSrc: 'ingenious.png',
      width: DEFAULT_WIDTH
    },
    known: true,
    portalUrl: null,
    color: '#cd5c5c',
    forcePartnerKey: true,
    isNetwork: true
  },
  {
    key: 'firstpromoter',
    name: 'FirstPromoter',
    matches: matchRegexp(/(\?|&)(fp_ref=|fpr=)/),
    logo: {
      imgSrc: 'firstpromoter.png',
      width: DEFAULT_WIDTH
    },
    known: true,
    portalUrl: null,
    forcePartnerKey: true,
    color: '#0facf3'
  },
  {
    key: 'mvf',
    name: 'MVF Global',
    matches: matchRegexp(/^https:\/\/appwiki.nl\/link\/brand\/\w+/),
    logo: {
      imgSrc: 'mvfglobal.ico',
      width: DEFAULT_WIDTH
    },
    known: true,
    portalUrl: 'https://appwiki.nl/partner',
    color: '#061178'
  },
  {
    key: 'pepperjam',
    name: 'Ascend (Pepperjam)',
    matches: matchRegexp(
      /^https:\/\/(www\.)?(gopjn|pjtra|pntrac|pntra|pjatr|pntrs).com/
    ),
    logo: {
      imgSrc: 'pepperjam.png',
      width: DEFAULT_WIDTH
    },
    known: true,
    portalUrl: 'https://ascend.pepperjam.com/login',
    color: '#ff9c6e',
    isNetwork: true
  },
  {
    key: 'tradedoubler',
    name: 'Tradedoubler',
    matches: matchRegexp(/clk(uk|de)?.tradedoubler.com/),
    logo: {
      imgSrc: 'tradedoubler.png',
      width: DEFAULT_WIDTH
    },
    known: true,
    isNetwork: true,
    portalUrl: 'https://login.tradedoubler.com/public/aLogin.action',
    color: '#40a9ff'
  },
  {
    key: 'impact',
    name: 'Impact',
    matches: (url) => {
      const IMPACT_TRACKING_DOMAINS = [
        '1.envato.market',
        '22o6.net',
        '258o.net',
        '27exom.net',
        '2cw9.net',
        '2det.net',
        '2gfm.net',
        '2gib.net',
        '2gl34e.net',
        '2j9x.net',
        '2lka.net',
        '2lsp.net',
        '2m8f.net',
        '2nm686.net',
        '2npn3e.net',
        '2pxhba.net',
        '2rcf97.net',
        '2rch.net',
        '2su64p.net',
        '2t23.net',
        '2uf4ta.net',
        '2xc8.net',
        '2xje.net',
        '2xkn.net',
        '2ygtwc.net',
        '33qw.net',
        '35p2.net',
        '36c4.net',
        '3anx.net',
        '3f64ir.net',
        '3lki.net',
        '3obyns.net',
        '3qag.net',
        '3tpfen.net',
        '3tvl.net',
        '3uu8.net',
        '3xvk.net',
        '43a8.net',
        '43k8.net',
        '43wo.net',
        '43zz.net',
        '4byfvk.net',
        '4drrzr.net',
        '4emhls.net',
        '4fq8.net',
        '4g9yrz.net',
        '4hyab9.net',
        '4ok7ht.net',
        '4paxeq.net',
        '4qxjn9.net',
        '4tqiav.net',
        '4xc4ep.net',
        '57ib.net',
        '58dp.net',
        '58mq.net',
        '5ad6.net',
        '5d3x.net',
        '5f77.net',
        '5kd8.net',
        '5l5h.net',
        '5nfc.net',
        '5oih.net',
        '5rmr.net',
        '5sfo.net',
        '5vju.net',
        '5zd6.net',
        '642pbb.net',
        '648q.net',
        '64ud.net',
        '68w6.net',
        '6cqhdo.net',
        '6dny.net',
        '6eld.net',
        '6jxl.net',
        '6noy.net',
        '6q33.net',
        '6rfywi.net',
        '6uu72h.net',
        '6wfgdb.net',
        '6x7g.net',
        '6ywx.net',
        '72mu89.net',
        '74az.net',
        '74rjtv.net',
        '74tzgq.net',
        '74wq.net',
        '74xz8u.net',
        '75r4.net',
        '775j.net',
        '77jaha.net',
        '78cfvm.net',
        '79ic8e.net',
        '7cnq.net',
        '7ck3j6.net',
        '7eer.net',
        '7ema.net',
        '7fdy.net',
        '7isk.net',
        '7mh5.net',
        '7no9.net',
        '7orgeq.net',
        '7oyhzp.net',
        '7q8j.net',
        '7qto.net',
        '7sb2uw.net',
        '7st3op.net',
        '7tiv.net',
        '7ufqwl.net',
        '7voo.net',
        '7w7o67.net',
        '7xde.net',
        '7ymy.net',
        '7z5k.net',
        '7zd4df.net',
        '83ckgt.net',
        '8aog.net',
        '8bga.net',
        '8bvm.net',
        '8f6i.net',
        '8hslpj.net',
        '8hwt.net',
        '8ibi.net',
        '8kpa2n.net',
        '8kt6.net',
        '8lvxaf.net',
        '8mz3uu.net',
        '8n4773.net',
        '8ne3.net',
        '8ocm68.net',
        '8odi.net',
        '8rh8hm.net',
        '8s4u9r.net',
        '8u86.net',
        '8ujrgu.net',
        '8utb.net',
        '8v4lqg.net',
        '8x7ctf.net',
        '8zaeu3.net',
        '8zgmnp.net',
        '8zwg.net',
        '973t.net',
        '9j4c.net',
        '9nz77o.net',
        '9pctbx.net',
        '9q66.net',
        '9quv.net',
        '9rwv.net',
        '9u2rlz.net',
        '9vn7kv.net',
        '9yde.net',
        '9yoxzr.net',
        '9zpg.net',
        'a49pb4.net',
        'a49tr6.net',
        'a4v3ci.net',
        'a5fp.net',
        'a6gkwq.net',
        'a9yw.net',
        'affiliates.abebooks.com',
        'aht6xo.net',
        'aiy7.net',
        'akum7z.net',
        'am3t9s.net',
        'aqp4qa.net',
        'aqpq.net',
        'atkw.net',
        'attfm2.net',
        'audw.net',
        'auhm.net',
        'av4d.net',
        'avo2.net',
        'awb5.net',
        'awv4zz.net',
        'aydjxz.net',
        'ayph.net',
        'ayxtyv.net',
        'b54k.net',
        'b9i7.net',
        'bbgqo9.net',
        'blihtq.net',
        'bn5x.net',
        'bodybuilding.com',
        'bop8.net',
        'bpu9.net',
        'briy.net',
        'brvi.net',
        'bs6l.net',
        'bts6.net',
        'btyhsg.net',
        'bueme2.net',
        'bvrd.net',
        'bwa8.net',
        'bxvfun.net',
        'bzi2vw.net',
        'c2ukkg.net',
        'c38gzp.net',
        'c3me6x.net',
        'c3nu2i.net',
        'c5l5.net',
        'c9ftyd.net',
        'cezg3w.net',
        'cfzu.net',
        'chx3kt.net',
        'cmuw.net',
        'cw3o.net',
        'cwv7.net',
        'd2lsjo.net',
        'd67ag4.net',
        'dbapeb.net',
        'dcm9zy.net',
        'deg5.net',
        'dfjeo3.net',
        'dg6u.net',
        'dgdrgu.net',
        'dgrk2e.net',
        'dne9je.net',
        'dodxnr.net',
        'drn3.net',
        'dttq.net',
        'dkkdet.net',
        'dubn.net',
        'e2rq.net',
        'e4wb.net',
        'e54b.net',
        'e8i7.net',
        'e9jo.net',
        'e9ppfh.net',
        'eat8mo.net',
        'ebml.net',
        'eccsr4.net',
        'ei7w.net',
        'elfm.net',
        'epvitm.net',
        'eqcm.net',
        'eqjw.net',
        'eqwh.net',
        'erik73.net',
        'eszb.net',
        'et7l.net',
        'evyy.net',
        'ewrvdi.net',
        'exgl.net',
        'eyip.net',
        'eyjo.net',
        'f6rcao.net',
        'f9tmep.net',
        'fb8d.net',
        'fdcm73.net',
        'fdf2.net',
        'ffxwxg.net',
        'fgjaok.net',
        'fhsxpf.net',
        'fi2z.net',
        'fjbu.net',
        'fmtgqt.net',
        'fqik.net',
        'frhi32.net',
        'ftcv.net',
        'fu4n.net',
        'fubo.tv',
        'fum2sp.net',
        'fx3vf7.net',
        'fziv.net',
        'fzsu.net',
        'g39l.net',
        'g6gdil.net',
        'g7ix2j.net',
        'ga3c.net',
        'gcc3.net',
        'gfko.net',
        'gfpv.net',
        'givvml.net',
        'glg9ob.net',
        'gnv2.net',
        'go.corsair.com',
        'go.gemvara.com',
        'go.jewelry.com',
        'go.silpada.com',
        'go.web.plus.espn.com',
        'goto.americanexpress.com',
        'goto.bluenile.com',
        'goto.bodybuilding.com',
        'goto.carters.com',
        'goto.grocery.walmart.com',
        'goto.kayosports.com.au',
        'goto.target.com',
        'goto.walmart.com',
        'geolog.ie',
        'gqce.net',
        'gqco.net',
        'gtlppj.net',
        'gtlptb.net',
        'gvhb.net',
        'gyuyee.net',
        'h382za.net',
        'h4km.net',
        'haujjd.net',
        'hbbswr.net',
        'hblm3c.net',
        'hg7mxc.net',
        'hgphc2.net',
        'hj2i.net',
        'hjef.net',
        'hmqldu.net',
        'hmxg.net',
        'hnyj8s.net',
        'hpn.houzz.co.uk',
        'hpn.houzz.com',
        'hq6ajo.net',
        'hrlo.net',
        'hs9x.net',
        'htuy.net',
        'hu6f.net',
        'hv8ceq.net',
        'hw4zyk.net',
        'hyyc7q.net',
        'i104546.net',
        'i108736.net',
        'i113075.net',
        'i114090.net',
        'i116503.net',
        'i116782.net',
        'i117711.net',
        'i121497.net',
        'i122908.net',
        'i123888.net',
        'i125364.net',
        'i127288.net',
        'i128439.net',
        'i130297.net',
        'i130883.net',
        'i134524.net',
        'i134784.net',
        'i139049.net',
        'i139476.net',
        'i140070.net',
        'i140643.net',
        'i141006.net',
        'i141602.net',
        'i142493.net',
        'i144304.net',
        'i146980.net',
        'i148866.net',
        'i156748.net',
        'i157428.net',
        'i160067.net',
        'i163678.net',
        'i168918.net',
        'i169060.net',
        'i179050.net',
        'i181536.net',
        'i181990.net',
        'i183635.net',
        'i189637.net',
        'i190936.net',
        'i191769.net',
        'i200065.net',
        'i203032.net',
        'i203761.net',
        'i205484.net',
        'i206080.net',
        'i206938.net',
        'i206969.net',
        'i209368.net',
        'i210675.net',
        'i213011.net',
        'i231566.net',
        'i240138.net',
        'i240196.net',
        'i242740.net',
        'i246054.net',
        'i246982.net',
        'i250869.net',
        'i257289.net',
        'i258711.net',
        'i260660.net',
        'i261477.net',
        'i263265.net',
        'i263671.net',
        'i270719.net',
        'i271380.net',
        'i274396.net',
        'i274555.net',
        'i275503.net',
        'i276271.net',
        'i276400.net',
        'i276600.net',
        'i277339.net',
        'i279709.net',
        'i284638.net',
        'i285710.net',
        'i286093.net',
        'i294432.net',
        'i294747.net',
        'i295461.net',
        'i295768.net',
        'i297327.net',
        'i298770.net',
        'i299105.net',
        'i300907.net',
        'i301580.net',
        'i302106.net',
        'i303683.net',
        'i305175.net',
        'i308314.net',
        'i310051.net',
        'i312864.net',
        'i317572.net',
        'i317579.net',
        'i326295.net',
        'i326837.net',
        'i328067.net',
        'i331371.net',
        'i334637.net',
        'i339540.net',
        'i340849.net',
        'i344083.net',
        'i351729.net',
        'i353150.net',
        'i357552.net',
        'i358707.net',
        'i358993.net',
        'i359245.net',
        'i363662.net',
        'i119894.net',
        'i3f2.net',
        'i3zp.net',
        'i5em.net',
        'i5md.net',
        'i679.net',
        'i6xjt2.net',
        'i7cdw9.net',
        'i8epma.net',
        'i8h2.net',
        'i9pljp.net',
        'ibfwsl.net',
        'icjj.net',
        'idgz2n.net',
        'ie8t3j.net',
        'ifgza3.net',
        'ifmu.net',
        'ig9i.net',
        'igs4ds.net',
        'ihfo.net',
        'ijrn.net',
        'ikkr9x.net',
        'iln8.net',
        'imlz.net',
        'ioym.net',
        'iqoc.net',
        'ir2by2.net',
        'ixmz.net',
        'iy7a.net',
        'iybsj3.net',
        'iyhh.net',
        'iypa.net',
        'j4df.net',
        'j4ib.net',
        'j8ujgp.net',
        'jedg.net',
        'jewn.net',
        'jgpt48.net',
        'jlud63.net',
        'jmx223.net',
        'jpzeir.net',
        'jtlo.net',
        'juo2.net',
        'jv6k.net',
        'jvam.net',
        'jwpdsd.net',
        'jyae.net',
        'k77v.net',
        'k7qtpo.net',
        'kbasqv.net',
        'kbp968.net',
        'kd4a.net',
        'keof.net',
        'kh4ffx.net',
        'kjorbe.net',
        'kk2kau.net',
        'kmlj9i.net',
        'krg4.net',
        'krym8q.net',
        'kwpkyy.net',
        'kxyi.net',
        'l3km.net',
        'l49yho.net',
        'l9vx.net',
        'l9yg.net',
        'ldw66v.net',
        'lf49oc.net',
        'lgkftb.net',
        'li9jiy.net',
        'liln.net',
        'linkto.hrblock.com',
        'lkze4s.net',
        'llbyf9.net',
        'lmwjx3.net',
        'ln72.net',
        'lusg.net',
        'lvuv.net',
        'm43q4j.net',
        'm4ibck.net',
        'm768hc.net',
        'magpmk.net',
        'meqk.net',
        'mivh.net',
        'mjs4.net',
        'mkr3.net',
        'ml6pmo.net',
        'mlvy.net',
        'mno8.net',
        'mp4l.net',
        'mp5l.net',
        'mpye.net',
        'mrlph3.net',
        'msafflnk.net',
        'mvqw.net',
        'mvvx.net',
        'mw46.net',
        'mwztt8.net',
        'mxu9.net',
        'myi4.net',
        'mzte.net',
        'n5ka.net',
        'n72aat.net',
        'n72y7a.net',
        'n76h.net',
        'nbq93e.net',
        'ncw6.net',
        'nd8t.net',
        'ng4cgr.net',
        'ngi2ba.net',
        'ngih.net',
        'nhuie7.net',
        'njih.net',
        'njv3dp.net',
        'nkwcmr.net',
        'nkygzf.net',
        'nm3tqp.net',
        'nnh2.net',
        'nob9.net',
        'npfrrw.net',
        'nqn6.net',
        'nrku7u.net',
        'nrlq2m.net',
        'nsji.net',
        'ntaf.net',
        'nvaz.net',
        'nwh3qn.net',
        'o3ae.net',
        'o5kg.net',
        'o64jx9.net',
        'o67m.net',
        'o7n83a.net',
        'o93x.net',
        'obak77.net',
        'obbk.net',
        'oedf.net',
        'oet7.net',
        'oie8.net',
        'ojrq.net',
        'oloiyb.net',
        'opfm.net',
        'ork2.net',
        'otegtm.net',
        'oteh.net',
        'otg8.net',
        'otpb.net',
        'ouod.net',
        'ovon4f.net',
        'ow29pp.net',
        'oyuv.net',
        'ozkewk.net',
        'p3oc.net',
        'p5ld.net',
        'p73z.net',
        'p7qb.net',
        'p8mc.net',
        'pa4kxy.net',
        'partner.canva.com',
        'partners.alamo.com',
        'partners.enterprise.com',
        'partners.hostgator.com',
        'partners.hotwire.com',
        'partners.inmotionhosting.com',
        'partners.nationalcar.com',
        'partners.wantable.co',
        'pb6g.net',
        'pbj2.net',
        'pdy5.net',
        'pfm4.net',
        'pkpakz.net',
        'pq2o.net',
        'puasq9.net',
        'pvxt.net',
        'pvzi.net',
        'pxf.io',
        'pxi6.net',
        'q4ew.net',
        'q77h.net',
        'qbt4.net',
        'qflm.net',
        'qk4r.net',
        'qn6r.net',
        'qodh.net',
        'qq3wj3.net',
        'qumg.net',
        'quvl.net',
        'qy67zi.net',
        'qyiv3c.net',
        'qyov.net',
        'r2oa.net',
        'r37x9j.net',
        'r69o.net',
        'r7kg.net',
        'rao4.net',
        'refer.turo.com',
        'rfvk.net',
        'rg35.net',
        'rhq9ml.net',
        'risj.net',
        'rjnwm7.net',
        'rqu9.net',
        'rrmo.net',
        'rt8x.net',
        'ruqo.net',
        'rv5k.net',
        'rv8crv.net',
        'rvgu.net',
        'rw9xb6.net',
        'ryvx.net',
        's4lle7.net',
        's7so.net',
        'saq2.net',
        'sfe5.net',
        'sgur.net',
        'shrw9t.net',
        'shzxkd.net',
        'sjv.io',
        'sk2bvq.net',
        'snlv.net',
        'soyxmd.net',
        'ssxmnr.net',
        'syuh.net',
        'szey.net',
        't2bw9u.net',
        't2nfq3.net',
        't7c9v8.net',
        't8puue.net',
        'tbthfv.net',
        'tdndqm.net',
        'te8rfv.net',
        'tf77py.net',
        'thj6q2.net',
        'tk2x2c.net',
        'tkjf.net',
        'tkl68z.net',
        'tlir.net',
        'tm7516.net',
        'tm7559.net',
        'tm7560.net',
        'tm7562.net',
        'tm7566.net',
        'tm7569.net',
        'tm8534.net',
        'tmfhgn.net',
        'tnu8.net',
        'tpeipe.net',
        'tql5.net',
        'tracking.maxcdn.com',
        'tracking.stackpath.com',
        'trw6mw.net',
        'tv2h87.net',
        'txuqk6.net',
        'tzva.net',
        'u44t.net',
        'u4prjd.net',
        'u7lr6p.net',
        'u97e.net',
        'ubertrk.com',
        'ue7a.net',
        'ue8cqz.net',
        'uewp.net',
        'uidg.net',
        'uikc.net',
        'uisv.net',
        'ujde.net',
        'ule4b9.net',
        'ulnv.net',
        'ulvh.net',
        'upvt.net',
        'uqhv.net',
        'uqog.net',
        'uvwgb9.net',
        'uqrxu7.net',
        'uqzq.net',
        'uskn.net',
        'ustnul.net',
        'uxsi.net',
        'uydo.net',
        'uym8.net',
        'uztnuq.net',
        'uzvs.net',
        'vayb.net',
        'vaz6fn.net',
        'vdcy.net',
        'vdvm.net',
        'vegb.net',
        'ver28r.net',
        'vfjm.net',
        'vjggsg.net',
        'vneoga.net',
        'vnx2z7.net',
        'vocq.net',
        'voq9.net',
        'vp6l.net',
        'vqi8.net',
        'vs9au6.net',
        'vtdix3.net',
        'vthnbx.net',
        'vvtnn9.net',
        'vwli.net',
        'vwz6.net',
        'vx83.net',
        'vxca.net',
        'vxoy.net',
        'vz7pkt.net',
        'vzck.net',
        'vzew.net',
        'vzffua.net',
        'w27s.net',
        'w2t6.net',
        'w2wxmz.net',
        'w6fg.net',
        'w7ma.net',
        'w9iork.net',
        'w9v5.net',
        'wcmu8e.net',
        'wd2f.net',
        'wf373c.net',
        'wfraqy.net',
        'wgn3fr.net',
        'whij.net',
        'wjx7.net',
        'wk5q.net',
        'wkq9.net',
        'wmempi.net',
        'wmsyr7.net',
        'wnbi.net',
        'wo8g.net',
        'woc3yh.net',
        'wqi6.net',
        'wrjfga.net',
        'wrrv.net',
        'wsktbf.net',
        'wsslc4.net',
        'wvr2.net',
        'wxc9hm.net',
        'wyfx2f.net',
        'x57o.net',
        'xayxet.net',
        'xb398u.net',
        'xg6r.net',
        'xhtgk3.net',
        'xhuc.net',
        'xibx.net',
        'xikq.net',
        'xk3g.net',
        'xkpq.net',
        'xkri.net',
        'xlwzq3.net',
        'xovt.net',
        'xpfo.net',
        'xq2wid.net',
        'xr2bd6.net',
        'xr64.net',
        'xrx2ci.net',
        'xuok.net',
        'xuvt.net',
        'xvtl.net',
        'xwrk.net',
        'xyibsh.net',
        'y6mxrg.net',
        'y8uw.net',
        'yardaz.net',
        'yaub.net',
        'ydow.net',
        'yfb7.net',
        'yfh6ag.net',
        'ygwk.net',
        'yoxl.net',
        'ytuz.net',
        'yuxg.net',
        'yvzx.net',
        'yx69dc.net',
        'yxku6p.net',
        'z27l.net',
        'z5dw.net',
        'z6rjha.net',
        'z6vo.net',
        'z724.net',
        'zafxzt.net',
        'zc5a.net',
        'zfrcsk.net',
        'zgkv.net',
        'zihf.net',
        'zlyuo6.net',
        'znhhcm.net',
        'znqymu.net',
        'znvt.net',
        'zpn8dk.net',
        'zrjdwn.net',
        'ztk5.net',
        'zvbf.net',
        'zvcr.net',
        'zvq6.net',
        'zvvq7p.net',
        'zytd7d.net',
        'zz6n.net',
        'a5oq.net',
        'mxuy67.net',
        '34rwxn.net',
        'id2d3x.net',
        'qbh8ac.net',
        'cleob4th.net',
        'x89scb.net',
        'tod8mp.net',
        '3k3q.net',
        'yc65.net',
        'ywhcc7.net',
        'j48ltb.net',
        'qylf.net'
      ];

      if (matchesAny(IMPACT_TRACKING_DOMAINS, url)) {
        return true;
      }

      if (
        matchRegexp(
          /^https:\/\/(www\.)?skyscanner\.net\/g\/referrals\/(.+)(campaignId=\d+)|(associateid=)/
        )(url)
      ) {
        return true;
      }

      return matchRegexp(
        /((qflm|dts2xn|e4wb|uzvs|yuy8ab|o6eiov|ayxtyv|alzt|9zpg|df7rps|t7c9v8|7eqqol|4drrzr|a4xxmk|i679|risj|i6xjt2|536u|8bxp97|4cl7|u7lr6p|obbk|vxoy|xq2wid|9yoxzr|zvvq7p|h382za|7ufqwl|3anx|2pxhba|r7kg|xovt|jvam|lvuv|7w7o67|ntaf|jtlo|nhuie7|2cw9|3tpfen|5ad6|xayxet|i309903|vxca|w6fg|9rwv|voq9|q77h|64ud|wo8g|kh4ffx|oloiyb|5d3x|vjggsg|e8i7|eszb|aiy7|vneoga|eqwh|mivh|soyxmd|fgjaok|cwv7|snlv|bn5x|7zd4df|ujde|vdvm|eyjo|pfm4|ln72|rg35|njih|ojrq|l9yg|ygwk|93n6tx|igs4ds|jlud63|u97e|xayxet|3uu8|n72aat|vnx2z7|shzxkd|evyy|bvrd|i231566|7eer|7tiv|c2nwa3|pvxt|rfvk|8ocm68|2t23|atkw|e9ppfh|fdcm73|syuh|mno8|yaub|ryvx|qvig|n5ka|xhuc|vzck|fdf2|hyyc7q|ibfwsl|ilbqy6|rv5k|zvbf|i[0-9]{6}|zlyuo6)\.net)|((sjv|pxf)\.io)|(goto.target.com)|(goto.walmart.com)|(1.envato.market)|(goto.bluenile.com)|(impact.swarovski.com)|(\/c\/[0-9]{6,7}\/[0-9]{5,7}\/[0-9]{3,5})|(irad=\d+|irmp=\d+)/
      )(url);
    },
    logo: {
      imgSrc: 'impact.png',
      width: DEFAULT_WIDTH
    },
    known: true,
    portalUrl: 'https://app.impact.com/',
    color: '#f759ab',
    isNetwork: true,
    forcePartnerKey: true
  },
  {
    key: 'refersion',
    name: 'Refersion',
    matches: matchRegexp(
      /^https:\/\/(.+)((\?|&)rfsn=)|(\.refersion\.com\/l\/.+)/
    ),
    logo: {
      imgSrc: 'refersion.png',
      width: DEFAULT_WIDTH
    },
    forcePartnerKey: true,
    known: true,
    isNetwork: true,
    portalUrl: 'https://www.refersion.com/affiliate/login',
    color: '#66d994'
  },
  {
    key: 'partnerstack',
    name: 'PartnerStack',
    matches: matchRegexp(
      /^https:\/\/((.+)grsm.io|try.thinkific.com|try.quillbot.com|aff.trypipedrive.com|try.monday.com|get.brevo.com)/
    ),
    logo: {
      imgSrc: 'partnerstack.png',
      width: DEFAULT_WIDTH
    },
    known: true,
    isNetwork: true,
    portalUrl: 'https://dash.partnerstack.com',
    color: '#b37feb'
  },
  {
    key: 'webgains',
    name: 'Webgains',
    matches: matchRegexp(/^https:\/\/track\.webgains\.com/),
    logo: {
      imgSrc: 'webgains.png',
      width: DEFAULT_WIDTH
    },
    known: true,
    isNetwork: true,
    portalUrl: 'https://www.webgains.com/front/user/login',
    color: '#ffa39e'
  },
  {
    key: 'optimise',
    name: 'Optimise',
    matches: matchRegexp(/^https:\/\/clk\.omgt1\.com/),
    logo: {
      imgSrc: 'optimise.png',
      width: DEFAULT_WIDTH
    },
    known: true,
    portalUrl: null,
    color: '#029be5'
  },
  {
    key: 'ontraport',
    name: 'Ontraport',
    matches: matchRegexp(/orid=\d+&opid=\d+/),
    logo: {
      imgSrc: 'ontraport.png',
      width: DEFAULT_WIDTH
    },
    known: true,
    portalUrl: null,
    color: '#029be5'
  },
  {
    key: 'sovrn',
    name: 'Sovrn (VigLink)',
    matches: matchRegexp(
      /https?:\/\/(redirect.viglink.com)|(sovrn.co\/[0-9a-z]+)/
    ),
    logo: {
      imgSrc: 'sovrn.png',
      width: DEFAULT_WIDTH
    },
    known: true,
    isNetwork: true,
    portalUrl: 'https://sessions.viglink.com/sign-in',
    color: '#faad14'
  },
  {
    key: 'linkconnector',
    name: 'LinkConnector',
    matches: matchRegexp(/^https?:\/\/www\.linkconnector.com\/ta\.php/),
    logo: {
      imgSrc: 'linkconnector.jpg',
      width: DEFAULT_WIDTH
    },
    known: true,
    isNetwork: true,
    portalUrl: 'https://www.linkconnector.com/account-login/',
    color: '#b37feb'
  },
  {
    key: 'cbdAffs',
    name: 'CBD Affs',
    matches: matchRegexp(/^https?:\/\/clicks\.trackcb\.com/),
    logo: {
      imgSrc: 'cbdaffs.png',
      width: DEFAULT_WIDTH
    },
    known: true,
    portalUrl: 'https://system.cbdaffs.com',
    forcePartnerKey: true,
    color: '#448044'
  },
  {
    key: 'cannaffiliate',
    name: 'CannAffiliate',
    matches: matchRegexp(/^https?:\/\/tracking\.cannaffiliate\.com/),
    logo: {
      imgSrc: 'cannaffiliate.png',
      width: DEFAULT_WIDTH
    },
    known: true,
    portalUrl: 'https://login.cannaffiliate.com/',
    forcePartnerKey: true,
    color: '#00cc66'
  },
  {
    key: 'revoffers',
    name: 'Katalys',
    matches: matchRegexp(/^https?:\/\/track\.revoffers\.com/),
    logo: {
      imgSrc: 'katalys.png',
      width: DEFAULT_WIDTH
    },
    known: true,
    portalUrl: 'https://manage.revoffers.com',
    forcePartnerKey: true,
    color: '#1962ff'
  },
  {
    key: 'affise',
    name: 'Affise',
    matches: matchRegexp(/\/click\?pid=\d+&offer_id=\d+/),
    logo: {
      imgSrc: 'affise.ico',
      width: DEFAULT_WIDTH
    },
    known: true,
    portalUrl: null,
    forcePartnerKey: true,
    color: '#a11cf2'
  },
  {
    key: 'tune',
    name: 'Tune (HasOffers)',
    matches: matchRegexp(
      /((\?|&|\/)(aff_c|offer_id)=)|^https?:\/\/(redirect\.outdoorsy\.com)|(offer\.zenmate\.com)|(privateinternetaccess\.com)|(cybertool\.co)/
    ),
    logo: {
      imgSrc: 'tune.png',
      width: DEFAULT_WIDTH
    },
    known: true,
    portalUrl: null,
    forcePartnerKey: true,
    color: '#2f54eb'
  },
  {
    key: 'viabam',
    name: 'Viabam',
    matches: matchRegexp(/viabam.com/),
    logo: {
      imgSrc: 'viabam.png',
      width: DEFAULT_WIDTH
    },
    known: true,
    portalUrl: null,
    color: '#f759ab'
  },
  {
    key: 'rakuten',
    name: 'Rakuten',
    matches: matchRegexp(/^https:\/\/(click\.)?linksynergy(\.walmart)?\.com/),
    logo: {
      imgSrc: 'rakuten.png',
      width: DEFAULT_WIDTH
    },
    known: true,
    portalUrl: 'https://cli.linksynergy.com/cli/publisher/home.php',
    color: '#780650',
    isNetwork: true
  },
  {
    key: 'bhphotovideo',
    name: 'B&H Photo Video',
    matches: matchRegexp(
      /^https:\/\/(www\.bhphotovideo\.com(.+)\/BI\/\d+)|(bhpho\.to\/[A-Za-z0-9]+)/
    ),
    logo: {
      imgSrc: 'bhphotovideo.jpg',
      width: DEFAULT_WIDTH
    },
    known: true,
    portalUrl: 'https://affiliates.bhphotovideo.com/',
    color: '#f5222d'
  },
  {
    key: 'clickbank',
    name: 'ClickBank',
    matches: matchRegexp(
      /^https?:\/\/((.+)?hop\.clickbank\.net)|(www\.rocketlanguages\.com(.+)(\?|&)aff=(.+))/
    ),
    logo: {
      imgSrc: 'clickbank.jpg',
      width: DEFAULT_WIDTH
    },
    known: true,
    isNetwork: true,
    portalUrl: 'https://accounts.clickbank.com/login.htm',
    color: '#ff4d4f'
  },
  {
    key: 'agoda',
    name: 'Agoda',
    matches: matchRegexp(/https:\/\/www.agoda.com\/partners\/(.+)cid=\d+/),
    logo: {
      imgSrc: 'agoda.ico',
      width: DEFAULT_WIDTH
    },
    known: true,
    portalUrl: 'https://partners.agoda.com',
    color: '#ff4d4f'
  },
  {
    key: 'ltk',
    name: 'LTK (rewardStyle)',
    matches: matchRegexp(/https?:\/\/rstyle.me/),
    logo: {
      imgSrc: 'ltk.png',
      width: DEFAULT_WIDTH
    },
    known: true,
    isNetwork: true,
    portalUrl: 'https://auth-creator.shopltk.com/login/',
    color: '#4e4e4e'
  },
  {
    key: 'idevaffiliate',
    name: 'iDevAffiliate',
    matches: matchRegexp(
      /(idevaffiliate.php)|(\.idevaffiliate\.com\/[\d]+\.html)|((\?|&)(idev_id|idev_username))/
    ),
    logo: {
      imgSrc: 'idevaffiliate.png',
      width: DEFAULT_WIDTH
    },
    known: true,
    forcePartnerKey: true,
    isNetwork: true,
    portalUrl: null,
    color: '#096dd9'
  },
  {
    key: 'booking',
    name: 'Booking.com',
    matches: matchRegexp(/^https?:\/\/(www.)?booking.com(.+)aid=/),
    logo: {
      imgSrc: 'booking.png',
      width: DEFAULT_WIDTH
    },
    known: true,
    portalUrl: 'https://www.booking.com/affiliate-program/v2/index.html',
    color: '#0050b3'
  },
  {
    key: 'gyg',
    name: 'GetYourGuide',
    matches: matchRegexp(
      /^https?:\/\/(www.)?getyourguide.(com|de|ch|fr|nl|no|pl|com.mx|fi|pt|com.br|se|ru|com.tw|co.kr|com.tr|co.jp|at|dk|co.uk|es|it)(.+)(\?|&partner_id=)/
    ),
    logo: {
      imgSrc: 'getyourguide.png',
      width: DEFAULT_WIDTH
    },
    known: true,
    portalUrl: 'https://partner.getyourguide.com',
    color: '#fa541c'
  },
  {
    key: 'hotels_combined',
    name: 'Hotels Combined',
    matches: matchRegexp(
      /^https?:\/\/(www\.)?hotelscombined.com(.+)(\?|&)a_aid=/
    ),
    logo: {
      imgSrc: 'hotelscombined.ico',
      width: DEFAULT_WIDTH
    },
    known: true,
    portalUrl: 'https://affiliates.hotelscombined.com/',
    color: '#69c0ff'
  },
  {
    key: 'cake',
    name: 'CAKE',
    matches: matchRegexp(/a=\d+(.)+c=\d+/),
    logo: {
      imgSrc: 'cake.ico',
      width: DEFAULT_WIDTH
    },
    known: true,
    portalUrl: null,
    forcePartnerKey: true,
    color: '#69c0ff'
  },
  {
    key: 'avantlink',
    name: 'AvantLink',
    matches: matchRegexp(
      /^https?:\/\/((www|classic)\.avantlink\.com\/click\.php)|(snp\.link)|(alnk\.to)/
    ),
    logo: {
      imgSrc: 'avantlink.ico',
      width: DEFAULT_WIDTH
    },
    known: true,
    portalUrl: 'https://avantlink.com/',
    color: '#597ef7',
    isNetwork: true
  },
  {
    key: 'viator',
    name: 'Viator',
    matches: matchRegexp(/^https?:\/\/(partner|www|www.partner)?.viator.com/),
    logo: {
      imgSrc: 'viator.ico',
      width: DEFAULT_WIDTH
    },
    known: true,
    portalUrl: 'https://partners.viator.com/',
    color: '#08979c'
  },
  {
    key: 'secomapp',
    name: 'UpPromote',
    matches: matchRegexp(/(\?|&)sca_ref=/),
    logo: {
      imgSrc: 'uppromote.png',
      width: DEFAULT_WIDTH
    },
    known: true,
    portalUrl: null,
    color: '#ff4e41'
  },
  {
    key: 'goaffpro',
    name: 'GoAffPro',
    matches: (url) => {
      const DOMAINS = [
        'camelcitymill.com',
        'element26.co',
        '2pood.com',
        'store.kabukistrength.net',
        'eleiko.com',
        'ekrinathletics.com',
        'getpuretorque.com',
        'lebertfitness.com',
        'www.ativafit.com',
        'myorganiccompany.store',
        'podzies.com',
        'quebecsup.com',
        'soundsgood.ca',
        'theamazingblanket.myshopify.com',
        'www.hypepods.ca',
        'yogavisage.fr',
        'www.orangefit.nl',
        'www.seamossmountain.com',
        'www.breezypacks.com',
        'sugarmedical.com',
        'comfort-fresh.com',
        'www.sunflake.nl',
        'www.reismonkey.nl',
        'stormrock.fr',
        'lesfrerescbd.fr',
        'www.tealerlab.com',
        'mamakana.com',
        'weencbd.fr',
        'greendogs.fr',
        'kuch.fr',
        'www.herbs-cbd.fr',
        'cbdpaschere.com',
        'stormrock-high.fr',
        'alpinols.com',
        'hemproutine.fr',
        'okiweed.com',
        'chamancbd.com',
        'www.zamaly.fr',
        'www.cdbpurple.fr'
      ];

      return matchesAny(DOMAINS, url) && matchRegexp(/(\?|&)ref=/)(url);
    },
    logo: {
      imgSrc: 'goaffpro.svg',
      width: DEFAULT_WIDTH
    },
    known: true,
    forcePartnerKey: true,
    isNetwork: true,
    portalUrl: 'https://affiliates.goaffpro.app',
    color: '#343a40'
  },
  {
    key: 'tapfiliate',
    name: 'Tapfiliate',
    matches: matchRegexp(/(\?|&)tap_a=/),
    logo: {
      imgSrc: 'tapfiliate.png',
      width: DEFAULT_WIDTH
    },
    known: true,
    forcePartnerKey: true,
    portalUrl: null,
    color: '#4bd0d7'
  },
  {
    key: 'commission-factory',
    name: 'Commission Factory',
    matches: matchRegexp(/^https:\/\/t\.cfjump\.com\/\d+\/(.+)/),
    logo: {
      imgSrc: 'commisionfactory.ico',
      width: DEFAULT_WIDTH
    },
    known: true,
    isNetwork: true,
    portalUrl: 'https://dashboard.commissionfactory.com/LogIn/',
    color: '#28C2C4'
  },
  {
    key: 'voyagin',
    name: 'Voyagin',
    matches: matchRegexp(/https?:\/\/www\.govoyagin\.com(.+)acode=/),
    logo: {
      imgSrc: 'voyagin.ico',
      width: DEFAULT_WIDTH
    },
    known: true,
    portalUrl: 'https://www.govoyagin.com/affiliate-partner-program',
    color: '#ff7a45'
  },
  {
    key: 'jr_pass',
    name: 'Japan Rail Pass',
    matches: matchRegexp(/https?:\/\/www.jrailpass.com(.+)affiliate=/),
    logo: {
      imgSrc: 'jrailpass.ico',
      width: DEFAULT_WIDTH
    },
    known: true,
    portalUrl: 'https://www.jrailpass.com/modules/trackingfront/stats.php',
    color: '#eb2f96'
  },
  {
    key: 'awin',
    name: 'Awin',
    matches: matchRegexp(
      /^https?:\/\/((www\.awin1.com\/(awclick|cread|pclick)\.php)|(tidd.ly))/
    ),
    logo: {
      imgSrc: 'awin.png',
      width: DEFAULT_WIDTH
    },
    known: true,
    portalUrl: 'https://ui.awin.com/login',
    color: '#f5222d',
    isNetwork: true
  },
  {
    key: 'cj',
    name: 'CJ Affiliate',
    matches: matchRegexp(
      /^https?:\/\/(www.)?((dpbolvw.net)|(anrdoezrs.net)|(lduhtrp.com)|(ftjcfx.com)|(tkqlhce.com)|(jdoqocy.com)|(kqzyfj.com)|(kqzfj.com)|(cj.dotomi.com)|(emjcd.com)|(qksrv.net)|(tqlkg.com)|(66trp\.com))/
    ),
    logo: {
      imgSrc: 'cj.ico',
      width: DEFAULT_WIDTH
    },
    known: true,
    portalUrl: 'https://members.cj.com/member/login/',
    color: '#025450',
    isNetwork: true
  },
  {
    key: 'jvzoo',
    name: 'JVZoo',
    matches: matchRegexp(
      /^https?:\/\/(jvzoo|jvz1|jvz2|jvz3|jvz4|jvz5|jvz6)\.com/
    ),
    logo: {
      imgSrc: 'jvzoo.ico',
      width: DEFAULT_WIDTH
    },
    known: true,
    isNetwork: true,
    portalUrl: 'https://www.jvzoo.com/auth/login/',
    color: '#a8071a'
  },
  {
    key: 'partnerize',
    name: 'Partnerize',
    matches: matchRegexp(
      /^(https?:\/\/([[a-z0-9\-_]+\.)?prf\.hn)|(http?s:\/\/(expedia|vrbo)\.com\/affiliate(.+))/
    ),
    logo: {
      imgSrc: 'partnerize.png',
      width: DEFAULT_WIDTH
    },
    known: true,
    isNetwork: true,
    portalUrl: 'https://console.partnerize.com/account/signin',
    color: '#13c2c2'
  },
  {
    key: 'shareasale',
    name: 'ShareASale',
    matches: matchRegexp(
      /^https?:\/\/(www\.)?(shareasale.com(.+)(u|(userID))=|shrsl.com)|(.+)(\?|&)sscid=/
    ),
    logo: {
      imgSrc: 'shareasale.png',
      width: DEFAULT_WIDTH
    },
    known: true,
    portalUrl: 'https://www.shareasale.com',
    color: '#ffd666',
    isNetwork: true
  },
  {
    key: 'rentalcarscom',
    name: 'RentalCars.com',
    matches: matchRegexp(
      /^https?:\/\/www\.rentalcars.com(.+)(\?|&)affiliateCode=/
    ),
    logo: {
      imgSrc: 'rentalcars.png',
      width: DEFAULT_WIDTH
    },
    known: true,
    portalUrl: 'https://partner-extranet.rentalcarsconnect.com/en/login',
    color: '#096dd9'
  },
  {
    key: 'klook',
    name: 'Klook',
    matches: matchRegexp(
      /https?:\/\/(affiliate\.klook\.com)|(www.klook\.com(.+)(aid|af_wid)=)/
    ),
    logo: {
      imgSrc: 'klook.png',
      width: DEFAULT_WIDTH
    },
    known: true,
    portalUrl: 'https://affiliate.klook.com/home',
    color: '#ff7a45'
  },
  {
    key: 'stacked_brands',
    name: 'Stacked Brands',
    matches: matchRegexp(
      /^https?:\/\/(www.)?((instantknockout.com)|(testofuel.com)|(primemale.com)|(4gauge.com))(.+)?(&|\?)a_aid=/
    ),
    logo: {
      imgSrc: 'stackedbrands.png',
      width: DEFAULT_WIDTH
    },
    known: true,
    portalUrl: 'https://admin.stackedbrands.com/affiliates/login.php',
    color: '#26BBDF',
    isNetwork: true
  },
  {
    key: 'expressvpn',
    name: 'ExpressVPN',
    matches: matchRegexp(
      /((kzrzpzf)|(xvinlink)|(xvbelink)|(xvmelink)|(xvuslink))\.com/
    ),
    logo: {
      imgSrc: 'expressvpn.png',
      width: DEFAULT_WIDTH
    },
    known: true,
    portalUrl: 'https://vpnaffiliates.com',
    color: '#a8071a'
  },
  {
    key: 'pap',
    name: 'Post Affiliate Pro',
    matches: matchUrlObj((u) => {
      if (u.hash.match(/(a_aid=.+)|(tr_aid=.+)/)) {
        return true;
      }
      if (u.origin.match(/repfitness\.com/)) {
        return true;
      }
      if (u.origin.match(/\.ivacy.com/)) {
        return true;
      }
      if (
        u.href.match(
          /((\.postaffiliatepro)|(affiliates\.xeroshoes)).com\/scripts\//
        )
      ) {
        return true;
      }
      return !!u.search.match(/a_aid|tr_aid|a_bid/);
    }),
    logo: {
      imgSrc: 'postaffiliatepro.svg',
      width: DEFAULT_WIDTH
    },
    known: true,
    portalUrl: 'https://www.postaffiliatepro.com/',
    color: '#b3d4fc',
    isNetwork: true,
    forcePartnerKey: true
  },
  {
    key: 'tradetracker',
    name: 'TradeTracker',
    matches: matchRegexp(
      /(https?:\/\/tc\.tradetracker\.net\/?\?c=[0-9]{1,})|((&|\?)tt=[0-9_]+)/
    ),
    logo: {
      imgSrc: 'tradetracker.png',
      width: DEFAULT_WIDTH
    },
    known: true,
    isNetwork: true,
    portalUrl: 'https://affiliate.tradetracker.com',
    color: '#096dd9'
  },
  {
    key: 'engine',
    name: 'Engine by MoneyLion',
    matches: matchRegexp(/^https?:\/\/(fiona|offers\.evenfinancial)\.com/),
    logo: {
      imgSrc: 'engine.png',
      width: DEFAULT_WIDTH
    },
    known: true,
    isNetwork: true,
    portalUrl: 'https://engine.tech',
    color: '#15315a'
  },
  /*
  {
    key: 'quinstreet',
    name: 'QuinStreet',
    matches: matchRegexp(/^https?:\/\/www\.cardratings\.com(.+)(\?|&)src=\d+/),
    logo: {
      imgSrc: 'quinstreet.png',
      width: DEFAULT_WIDTH
    },
    known: true,
    isNetwork: true,
    portalUrl: 'https://www.login.quinstreet.com/Marketplace/Login.aspx',
    color: '#ff4500'
  },
  {
    key: 'iab',
    name: 'Interactive Brokers',
    matches: matchRegexp(
      /^https?:\/\/www\.interactivebrokers\.com(.+)(\?|&)src=/
    ),
    logo: {
      imgSrc: 'interactive-brokers.png',
      width: DEFAULT_WIDTH
    },
    known: true,
    isNetwork: true,
    portalUrl: 'https://www.interactivebrokers.com/en/home.php',
    color: '#d91222'
  },
  */
  {
    key: 'levanta',
    name: 'Levanta',
    matches: matchRegexp(
      /(https?:\/\/(www\.)?amazon\.com(.+)&((aa_campaignid)|(aa_adgroupid))=lv_)|(https:\/\/lvnta\.com\/(.+))/
    ),
    logo: {
      imgSrc: 'levanta.svg',
      width: DEFAULT_WIDTH
    },
    known: true,
    isNetwork: true,
    portalUrl: 'https://app.levanta.io/auth/sign-in',
    color: '#4a9459'
  },
  {
    key: 'stay22',
    name: 'Stay22',
    matches: matchRegexp(/^https?:\/\/www\.stay22\.com\/allez\/booking(.+)/),
    logo: {
      imgSrc: 'stay22.svg',
      width: DEFAULT_WIDTH
    },
    known: true,
    isNetwork: true,
    portalUrl: 'https://hub.stay22.com',
    color: '#243047'
  },
  {
    key: 'freshreach',
    name: 'FreshReach',
    matches: matchRegexp(/^https?:\/\/freshreach\.co\/click\.php(.+)/),
    logo: {
      imgSrc: 'freshreach.png',
      width: DEFAULT_WIDTH
    },
    known: true,
    isNetwork: true,
    portalUrl: 'https://account.fmtc.co/cp/login',
    color: '#8dc447'
  },
  {
    key: 'gbr',
    name: 'GOBankingRates',
    matches: matchRegexp(/products\.gobankingrates\.com/),
    logo: {
      imgSrc: 'gobankingrates.png',
      width: DEFAULT_WIDTH
    },
    known: true,
    isNetwork: true,
    portalUrl: '',
    color: '#20964f'
  }
];
