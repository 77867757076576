import {
  Button,
  FormControl,
  IconButton,
  ListSubheader,
  MenuItem,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { compact, omit, trim } from 'lodash';
import { noop } from 'lodash/fp';
import React, { useEffect, useMemo, useState } from 'react';
import { Plus, X } from 'react-feather';
import { ButtonWithPromise } from '../../../../components/ButtonWithPromise';
import { InternalId } from '../../../../components/ConnectionId';
import { Form } from '../../../../components/Form';
import { FormField } from '../../../../components/Form/FormField';
import { FormSubmitButton } from '../../../../components/Form/FormSubmitButton';
import { CURRENCIES } from '../../../../domainTypes/currency';
import { Doc } from '../../../../domainTypes/document';
import {
  AMAZON_MARKETPLACES,
  IReportingPreviewParams,
  IReportingTestParams,
  RAKUTEN_NETWORKS,
  ReportingApiResult,
  TRADE_TRACKER_LOCALES,
  WEBGAINS_COUNTRIES
} from '../../../../domainTypes/reporting';
import { ISecretWithTs, SecretFieldType } from '../../../../domainTypes/secret';
import { styled } from '../../../../emotion';
import { useDialogState } from '../../../../hooks/useDialogState';
import { useChannels } from '../../../../services/channels';
import {
  getCurrentUser,
  useHasCurrentUserRequiredScopes
} from '../../../../services/currentUser';
import { useFeatureEnabled } from '../../../../services/features';
import { callFirebaseFunction } from '../../../../services/firebaseFunctions';
import { useMixpanel } from '../../../../services/mixpanel';
import { getKnownPartnerForKey } from '../../../../services/partner';
import { getSecretValue, setSecretValue } from '../../../../services/secret';
import { CF } from '../../../../versions';
import { IApiReportHandler } from '../../services/handlers/types';
import { ApiRunDialog } from '../ApiRunDialog';

type Props = {
  handler: IApiReportHandler;
  secret: Doc<ISecretWithTs>;
  otherButtons?: (disabled: boolean) => React.ReactNode;
  onSuccess: () => void;
  onError?: (err: any) => void;
  allowRun?: boolean;
  allowCancel?: boolean;
};

type PropertyType = 'domain' | 'channel';

const handleResult = (
  result: ReportingApiResult,
  onSuccess: () => void,
  onError: (err: any) => void
) => {
  if (result.errorType || result.errorMsg || !result.report) {
    onError(result.errorType);
  } else {
    onSuccess();
  }
};

const FormButtons = styled<'div', { gridSize: number; fullWidth: boolean }>(
  'div'
)`
  display: grid;
  grid-template-columns: ${(p) =>
    p.gridSize === 3 ? `1fr 1fr 1fr` : `1fr 1fr`};
  margin-top: ${(p) => p.theme.spacing(2)}px;

  > * {
    margin-right: ${(p) => p.theme.spacing()}px !important;
    ${(p) => (p.fullWidth ? 'width: 100%;' : '')}
  }
`;

type Pairs = { [key: string]: string };
type PairsArray = { key: string; value: string };

const PairColumns = styled('div')`
  display: grid;
  grid-template-columns: 1fr 2fr 12px;
  padding-right: 6px;
  grid-column-gap: 6px;
  align-items: center;
`;

const PropertyAndIdPairs = ({
  label,
  value,
  onChange,
  disabled,
  propertyTypes
}: {
  label: string;
  value: Pairs;
  onChange: (nextValue: Pairs) => void;
  disabled?: boolean;
  propertyTypes: PropertyType[];
}) => {
  const initialValue = useMemo(() => {
    const values = Object.keys(value).map((k) => {
      return { key: k, value: value[k] };
    });
    return values.length === 0 ? [{ key: '', value: '' }] : values;
  }, [value]);

  const [rows, setRows] = useState<PairsArray[]>(initialValue);
  useEffect(() => setRows(initialValue), [initialValue]);

  const onChangeRows = (rows: PairsArray[]) => {
    const rowsAsValues = rows.reduce<Pairs>((res, r) => {
      const key = trim(r.key);
      res[key] = trim(r.value);
      return res;
    }, {});
    onChange(rowsAsValues);
  };

  const inputLabel = (() => {
    if (label === 'Tracking subdomains') {
      return 'Subdomain';
    }
    if (label === 'Slugs per domain') {
      return 'Slug';
    }
    if (label === 'Publication names per domain') {
      return 'Name';
    }
    if (label === 'Tracking IDs per Property') {
      return 'Tracking ID';
    }
    return 'Site ID';
  })();

  const onAddRow = () => {
    const newRows = [...rows, { key: '', value: '' }];
    setRows(newRows);
    onChangeRows(newRows);
  };

  const onRemoveRow = (index: number) => {
    const newRows = compact(rows.map((r, i) => (i === index ? null : r)));
    setRows(newRows);
    onChangeRows(newRows);
  };

  const propertyTypeLabel = propertyTypes.includes('channel')
    ? 'Site or Channel'
    : 'Site';

  return (
    <div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 72px',
          alignItems: 'center',
          paddingLeft: '3px',
          paddingTop: '6px',
          justifyContent: 'space-between'
        }}
      >
        <Typography variant="body1" color="textSecondary">
          {label}
        </Typography>
        <Button
          color="primary"
          variant="outlined"
          size="small"
          onClick={onAddRow}
          disabled={disabled}
        >
          <Plus size={16} /> &nbsp; Add
        </Button>
      </div>
      <PairColumns>
        {rows.map((k, i) => {
          return (
            <React.Fragment key={i}>
              <FormControl style={{ width: '100%' }} margin="normal">
                <TextField
                  value={k.key}
                  variant="outlined"
                  label={inputLabel}
                  required
                  type="text"
                  disabled={disabled}
                  onChange={(e) => {
                    const newKey = e.target.value as string;
                    const newRows = rows.map((r, j) => {
                      if (i === j) {
                        return { key: newKey, value: r.value };
                      }
                      return r;
                    });
                    setRows(newRows);
                    onChangeRows(newRows);
                  }}
                />
              </FormControl>
              <PropertyInput
                value={k.value}
                disabled={disabled}
                propertyTypes={propertyTypes}
                onChange={(newValue) => {
                  const newRows = rows.map((r, j) => {
                    if (i === j) {
                      return { key: r.key, value: newValue };
                    }
                    return r;
                  });
                  setRows(newRows);
                  onChangeRows(newRows);
                }}
                label={propertyTypeLabel}
              />
              <div>
                <Tooltip placement="top" title="Remove row">
                  <IconButton
                    size="small"
                    style={{ position: 'relative', top: '3px' }}
                    disabled={disabled}
                    onClick={() => {
                      onRemoveRow(i);
                    }}
                  >
                    <X size={16} />
                  </IconButton>
                </Tooltip>
              </div>
            </React.Fragment>
          );
        })}
      </PairColumns>
    </div>
  );
};

const PropertyInput = ({
  value,
  onChange,
  label,
  disabled,
  propertyTypes
}: {
  value: string;
  onChange: (nextValue: string) => void;
  label: React.ReactNode;
  disabled?: boolean;
  propertyTypes: PropertyType[];
}) => {
  const currentUser = getCurrentUser();
  const [channels, loading] = useChannels(currentUser.space.id);
  const hasChannels = useFeatureEnabled('CHANNELS');

  // Required because the Menu component requires array of React nodes as children
  const menuOptions = useMemo(() => {
    const domainOptions = currentUser.space.domains
      .filter((d) => d.active)
      .map((domain) => (
        <MenuItem value={domain.url} key={domain.url}>
          {domain.url}
        </MenuItem>
      ));

    if (!channels || !hasChannels || !propertyTypes.includes('channel')) {
      return domainOptions;
    }

    const options: React.ReactNode[] = [];
    const headerStyles: React.CSSProperties = {
      backgroundColor: 'white',
      pointerEvents: 'none',
      fontWeight: 'bold'
    };
    const domainHeader = (
      <ListSubheader style={headerStyles}>Sites</ListSubheader>
    );
    const channelsHeader = (
      <ListSubheader style={headerStyles}>Channels</ListSubheader>
    );
    options.push(domainHeader);
    options.push(...domainOptions);
    options.push(channelsHeader);
    const channelOptions = channels.map((channel) => (
      <MenuItem value={channel.data.channelId} key={channel.data.channelId}>
        {channel.data.name} &nbsp;
        <InternalId>{channel.data.channelId}</InternalId>
      </MenuItem>
    ));
    options.push(...channelOptions);
    return options;
  }, [currentUser.space.domains, channels, hasChannels, propertyTypes]);

  return (
    <FormControl style={{ width: '100%' }} margin="normal">
      <TextField
        select
        value={value}
        variant="outlined"
        label={label}
        required
        disabled={disabled || loading}
        onChange={(ev) => onChange(ev.target.value as string)}
      >
        {menuOptions}
      </TextField>
    </FormControl>
  );
};

const SecretFieldInput = ({
  value,
  onChange,
  label,
  disabled,
  type
}: {
  value: string;
  onChange: (nextValue: string) => void;
  label: React.ReactNode;
  disabled?: boolean;
  type: SecretFieldType;
}) => {
  const margin = 'normal';
  if (['text', 'password', 'url'].indexOf(type) !== -1) {
    return (
      <TextField
        value={value}
        type={type}
        onChange={(ev) => onChange(ev.target.value)}
        required
        variant="outlined"
        label={label}
        margin={margin}
        disabled={disabled}
        fullWidth
      />
    );
  }

  if (type === 'webgainsCountry') {
    return (
      <FormControl style={{ width: '100%' }} margin={margin}>
        <TextField
          select
          label={label}
          value={value}
          variant="outlined"
          disabled={disabled}
          onChange={(ev) => onChange(ev.target.value as string)}
        >
          {WEBGAINS_COUNTRIES.map((country) => (
            <MenuItem value={country.id} key={country.id}>
              {country.label}
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
    );
  }

  if (type === 'tradeTrackerLocale') {
    return (
      <FormControl style={{ width: '100%' }} margin={margin}>
        <TextField
          select
          value={value}
          variant="outlined"
          label={label}
          disabled={disabled}
          onChange={(ev) => onChange(ev.target.value as string)}
        >
          {TRADE_TRACKER_LOCALES.map((locale) => (
            <MenuItem value={locale.value} key={locale.value}>
              {locale.label}
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
    );
  }

  if (type === 'rakutenNetwork') {
    return (
      <FormControl style={{ width: '100%' }} margin={margin}>
        <TextField
          select
          value={value}
          variant="outlined"
          label={label}
          disabled={disabled}
          onChange={(ev) => onChange(ev.target.value as string)}
        >
          {RAKUTEN_NETWORKS.map((network) => (
            <MenuItem value={network.id} key={network.id}>
              {network.label}
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
    );
  }

  if (type === 'domain') {
    return (
      <PropertyInput
        value={value}
        onChange={onChange}
        propertyTypes={['domain']}
        label={label}
        disabled={disabled}
      />
    );
  }

  if (type === 'currency') {
    return (
      <FormControl style={{ width: '100%' }} margin={margin}>
        <TextField
          value={value}
          select
          label={label}
          variant="outlined"
          disabled={disabled}
          onChange={(ev) => onChange(ev.target.value as string)}
        >
          {CURRENCIES.map((c) => (
            <MenuItem value={c} key={c}>
              {c}
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
    );
  }

  if (type === 'amazonMarketplace') {
    return (
      <FormControl style={{ width: '100%' }} margin={margin}>
        <TextField
          value={value}
          select
          label={label}
          variant="outlined"
          required
          disabled={disabled}
          onChange={(ev) => onChange(ev.target.value as string)}
        >
          {AMAZON_MARKETPLACES.map((marketplace) => (
            <MenuItem value={marketplace.id} key={marketplace.id}>
              {marketplace.label}
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
    );
  }

  if (type === 'travelPayoutsCurrency') {
    return (
      <FormControl style={{ width: '100%' }} margin={margin}>
        <TextField
          value={value}
          select
          label={label}
          variant="outlined"
          required
          disabled={disabled}
          onChange={(ev) => onChange(ev.target.value as string)}
        >
          {CURRENCIES.filter((c) => ['USD', 'EUR', 'RUB'].includes(c)).map(
            (c) => (
              <MenuItem value={c} key={c}>
                {c}
              </MenuItem>
            )
          )}
        </TextField>
      </FormControl>
    );
  }

  if (type === 'amazonFeedUrl') {
    const FEED_URLS: { key: string; value: string }[] = [
      {
        key: 'https://assoc-datafeeds-na.amazon.com',
        value: 'North America - https://assoc-datafeeds-na.amazon.com'
      },
      {
        key: 'https://assoc-datafeeds-eu.amazon.com',
        value: 'Europe - https://assoc-datafeeds-eu.amazon.com'
      },
      {
        key: 'https://assoc-datafeeds-fe.amazon.com',
        value: 'Japan - https://assoc-datafeeds-fe.amazon.com'
      }
    ];

    return (
      <FormControl style={{ width: '100%' }} margin={margin}>
        <TextField
          select
          value={value}
          label={label}
          disabled={disabled}
          variant="outlined"
          onChange={(ev) => onChange(ev.target.value as string)}
        >
          {FEED_URLS.map((url) => (
            <MenuItem value={url.key} key={url.key}>
              {url.value}
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
    );
  }

  console.log('SecretField: Type not supported', type);
  return null;
};

const EditMode = ({
  handler,
  secret,
  onSuccess = () => undefined,
  onError = () => undefined,
  allowCancel,
  goToView
}: Props & { goToView: () => void }) => {
  const [canAddIntegrations] = useHasCurrentUserRequiredScopes([
    'integrations.create'
  ]);

  const mixpanel = useMixpanel();
  const initialValues = useMemo(() => {
    const ivs = handler.configFields.reduce<{ [key: string]: any }>((m, s) => {
      m[s.key] = s.secret ? '' : getSecretValue(secret.data, s.key);
      return m;
    }, {});
    return { ...ivs, nickname: secret.data.nickname };
  }, [secret, handler]);

  if (!canAddIntegrations) {
    return (
      <div>
        <Typography variant="body2" component="p" color="textSecondary">
          You do not have permissions to add new integrations.
        </Typography>
      </div>
    );
  }

  return (
    <Form
      initialValues={initialValues}
      onSubmit={(values) => {
        const withoutNickname = omit(values, 'nickname');
        const nextSecretData = Object.entries(withoutNickname).reduce(
          (m, [k, v]) =>
            setSecretValue(m, k, typeof v === 'string' ? trim(v) : v),
          secret.data
        );

        nextSecretData.nickname = values.nickname;
        const success = () => {
          goToView();
          onSuccess();
        };
        return callFirebaseFunction<ReportingApiResult, IReportingTestParams>(
          CF.reporting.testSecretAndStore,
          {
            handler: handler.configName,
            secret: nextSecretData
          }
        ).then((result) => {
          mixpanel.track('add_integration', {
            name: handler.partnerKey,
            spaces: [secret.data.spaceId]
          });
          return handleResult(result, success, onError);
        }, onError);
      }}
    >
      {({ submitting, valid }) => (
        <div>
          <div>
            {handler.configFields.map((f) => {
              if (['domainAndIdPairs', 'propertyAndIdPairs'].includes(f.type)) {
                const propertyTypes: PropertyType[] =
                  f.type === 'domainAndIdPairs'
                    ? ['domain']
                    : ['domain', 'channel'];
                return (
                  <FormField key={f.key} name={f.key}>
                    {({ input }) => (
                      <PropertyAndIdPairs
                        label={f.label}
                        {...input}
                        propertyTypes={propertyTypes}
                      />
                    )}
                  </FormField>
                );
              }

              return (
                <FormField
                  key={f.key}
                  name={f.key}
                  type={
                    ['domains', 'currency'].includes(f.type)
                      ? 'select'
                      : 'input'
                  }
                >
                  {({ input }) => (
                    <SecretFieldInput
                      {...input}
                      type={f.type}
                      label={f.label}
                    />
                  )}
                </FormField>
              );
            })}
          </div>
          <FormField key="nickname" name="nickname">
            {({ input }) => (
              <TextField
                {...input}
                variant="outlined"
                label="Short nickname (Optional)"
                margin="normal"
                helperText="Distinguishes multiple connections to the same network."
                fullWidth
              />
            )}
          </FormField>
          <FormButtons fullWidth gridSize={2}>
            <FormSubmitButton
              variant="contained"
              color="primary"
              disabled={!valid}
              submitting={submitting}
              submitComponent="Testing..."
            >
              Test connection
            </FormSubmitButton>
            {allowCancel && <Button onClick={goToView}>Cancel</Button>}
          </FormButtons>
        </div>
      )}
    </Form>
  );
};

const ViewMode = ({
  handler,
  secret,
  onSuccess = () => undefined,
  onError = () => undefined,
  allowRun,
  goToEdit
}: Props & { goToEdit: () => void }) => {
  const { dialogOpen, openDialog, closeDialog } = useDialogState();
  const [canEditIntegrations] = useHasCurrentUserRequiredScopes([
    'integrations.edit'
  ]);
  const [canPullTransactions] = useHasCurrentUserRequiredScopes([
    'integrations.trigger_pull'
  ]);

  const test = () => {
    const end = Date.now();
    return callFirebaseFunction<ReportingApiResult, IReportingPreviewParams>(
      CF.reporting.preview,
      {
        spaceId: secret.data.spaceId,
        integrationId: secret.data.instanceId,
        handler: handler.configName,
        start: end - 1,
        end
      }
    ).then((result) => handleResult(result, onSuccess, onError), onError);
  };

  const partner = getKnownPartnerForKey(handler.partnerKey);

  return (
    <div>
      <div>
        {handler.configFields.map((f) => {
          if (['domainAndIdPairs', 'propertyAndIdPairs'].includes(f.type)) {
            const propertyTypes: PropertyType[] =
              f.type === 'domainAndIdPairs'
                ? ['domain']
                : ['domain', 'channel'];
            return (
              <PropertyAndIdPairs
                key={f.label}
                label={f.label}
                value={getSecretValue(secret.data, f.key)}
                propertyTypes={propertyTypes}
                disabled={true}
                onChange={noop}
              />
            );
          }

          return (
            <SecretFieldInput
              key={f.label}
              value={getSecretValue(secret.data, f.key)}
              onChange={noop}
              disabled={true}
              type={f.type}
              label={f.label}
            />
          );
        })}
        <TextField
          label="Nickname"
          value={secret.data.nickname}
          variant="outlined"
          margin="normal"
          disabled={true}
          fullWidth
        />
        <FormButtons fullWidth gridSize={partner && allowRun ? 3 : 2}>
          {canEditIntegrations && (
            <ButtonWithPromise
              variant="contained"
              color="default"
              onClick={() => test()}
              pending="Testing..."
            >
              Test connection
            </ButtonWithPromise>
          )}
          {canEditIntegrations && <Button onClick={goToEdit}>Edit</Button>}
          {partner && allowRun && canPullTransactions && (
            <Button onClick={openDialog}>Import...</Button>
          )}
        </FormButtons>
      </div>
      {partner && allowRun && (
        <ApiRunDialog
          open={dialogOpen}
          onClose={closeDialog}
          partner={partner}
          handler={handler}
          secret={secret}
        />
      )}
    </div>
  );
};

export const ApiSettings: React.FC<Props> = (props) => {
  const { createdAt } = props.secret.data;
  const [editMode, setEditMode] = useState(!createdAt);

  if (editMode) {
    return <EditMode {...props} goToView={() => setEditMode(false)} />;
  }

  return <ViewMode {...props} goToEdit={() => setEditMode(true)} />;
};
