import { Typography } from '@material-ui/core';
import moment from 'moment-timezone';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { AlertBox } from '../../../../components/AlertBox';
import {
  AnalyticsTable,
  ColumnDefinitions,
  DEFAULT_AVAILABLE_COLUMN_NAMES,
  useTable
} from '../../../../components/analytics_v2/Table';
import { ChartMode } from '../../../../components/Charts/EarningsChartCard/ChartModeSelector';
import {
  EarningsBarChartCardMetricV2,
  EarningsChartCardWithoutDataV2
} from '../../../../components/Charts/EarningsChartCardV2';
import { EarningsPieChartCardV2 } from '../../../../components/Charts/EarningsPieChartV2';
import { CustomPagination } from '../../../../components/CustomPagination';
import {
  ExportQueryButton,
  useExportQuery
} from '../../../../components/ExportQuery';
import { ColumnSelector } from '../../../../components/Table/ColumnSelector';
import {
  TimeframePickerDense,
  useStandardOptions,
  useTimeframeFromUrl
} from '../../../../components/TimeframePicker';
import { isSameTimeframe } from '../../../../domainTypes/analytics';
import {
  AnalyticsFilter,
  AnalyticsInterval,
  AnalyticsIntervalUnit,
  AnalyticsQuery
} from '../../../../domainTypes/analytics_v2';
import { EMPTY_ARR } from '../../../../domainTypes/emptyConstants';
import { css, styled } from '../../../../emotion';
import { FlexContainer } from '../../../../layout/Flex';
import {
  DEFAULT_OFFSET,
  PageToolbar,
  PageToolbarSection
} from '../../../../layout/PageToolbar';
import { useRoutes, useTypedStringQueryParam } from '../../../../routes';
import { allTime, toComparableTimeframe } from '../../../../services/analytics';
import { useChannelIdGrouper } from '../../../../services/analyticsV2/groups';
import { Metric } from '../../../../services/analyticsV2/metrics';
import { useAnalyticsQueryV2 } from '../../../../services/analyticsV2/query';
import {
  useCurrentUser,
  useHasCurrentUserRequiredScopes
} from '../../../../services/currentUser';
import { toMoment, useTimeframeToIsoRange } from '../../../../services/time';
import { useSpaceCurrency } from '../../../../services/useSpaceCurrency';
import { PerformancePageBody } from '../../components/PerformancePageBody';
import { WithHoverIndicator } from '../../../../components/WithHoverIndicator';
import { ChannelIdLabel } from '../../../../services/channels/ChannelLabel';
import {
  FiltersDrawer,
  FiltersIcon,
  useFilterDrawerState
} from '../../../../components/analytics_v2/Filters/Drawer';
import { Dimension } from '../../../../components/analytics_v2/Filters/filters';
import { useFilterClauses } from '../../../../components/analytics_v2/Filters/hooks';
import { useFeatureEnabled } from '../../../../services/features';
import { compact } from 'lodash';

const Grid = styled('div')`
  display: grid;
  grid-column-gap: ${(p) => p.theme.spacing(3)}px;
  grid-row-gap: ${(p) => p.theme.spacing(6)}px;
  grid-template-columns: 1.75fr 3fr;
  margin-bottom: ${(p) => p.theme.spacing(6)}px;
  min-height: 500px;

  ${(p) => p.theme.breakpoints.down('md')} {
    grid-column-gap: ${(p) => p.theme.spacing(1)}px;
    grid-row-gap: ${(p) => p.theme.spacing(2)}px;
    grid-template-columns: 1fr 1fr;
  }

  ${(p) => p.theme.breakpoints.down('sm')} {
    grid-column-gap: ${(p) => p.theme.spacing(1)}px;
    grid-row-gap: ${(p) => p.theme.spacing(2)}px;
    grid-template-columns: 1fr;
  }
`;

const customColumns = ['channel_id'] as const;
type CustomColumns = typeof customColumns[number];
type Column = CustomColumns | Metric;
const availableColumns: Column[] = [
  ...customColumns,
  ...DEFAULT_AVAILABLE_COLUMN_NAMES
];
const defaultVisibleColumns: Column[] = [
  ...customColumns,
  'p',
  'c',
  'epc_net',
  'rpm_net',
  'commission_sum_net',
  'gmv_sum_net'
];
const columnDefinitions: ColumnDefinitions<CustomColumns> = {
  channel_id: {
    column: {
      key: 'channel_id',
      head: () => 'Channel',
      cell: (p) => (
        <WithHoverIndicator>
          <ChannelIdLabel channelId={p.group.channel_id} />
        </WithHoverIndicator>
      ),
      align: 'left',
      sortable: true,
      width: 220,
      flexGrow: 2
    },
    sorter: {
      key: 'channel_id',
      items: {
        sort: (p) => p.group['channel_id'] || '',
        dir: 'desc'
      }
    }
  }
};

const PAGE_SIZE = 10;

export const PagePerformanceChannelsContentV2 = () => {
  // TODO: what dimensions we want there?
  const showTrafficSources = useFeatureEnabled('REFERRER_REPORTS_V1');
  const showCustomDimensions = useFeatureEnabled('CUSTOM_DIMENSIONS');
  const showPayoutDimensions = useFeatureEnabled('PAYOUTS_V1');

  const availableDimensions = useMemo<Dimension[]>(
    () =>
      compact([
        showCustomDimensions && 'click_data_01',
        showCustomDimensions && 'click_data_02',
        showTrafficSources && 'utm_medium',
        showTrafficSources && 'utm_source',
        showTrafficSources && 'utm_campaign',
        showTrafficSources && 'utm_content',
        showTrafficSources && 'utm_term',
        showTrafficSources && 'referrer',
        showPayoutDimensions && 'payout_status',
        showPayoutDimensions && 'payout_id',
        'channel',
        'platform',
        'transaction_status',
        'transaction_type',
        'device',
        'tag'
      ]),
    [showCustomDimensions, showTrafficSources, showPayoutDimensions]
  );
  const { isOpen, toggle } = useFilterDrawerState(false, {
    localStorageKey: 'channels-report-filter-drawer-state'
  });
  const { ROUTES } = useRoutes();
  const { space, tz } = useCurrentUser();
  const { id: spaceId } = space;
  const { options, defaultOption } = useStandardOptions();
  const [timeframe, setTimeframe] = useTimeframeFromUrl(defaultOption.value);
  const compare = !isSameTimeframe(allTime(), timeframe);
  const currency = useSpaceCurrency();

  const [chartMode, setChartMode] = useTypedStringQueryParam<ChartMode>(
    'chart_mode',
    'barChart'
  );

  const compareTimeframe = toComparableTimeframe(timeframe);
  const range = useTimeframeToIsoRange(timeframe);
  const compareRange = useTimeframeToIsoRange(compareTimeframe);

  const filters: AnalyticsFilter[] = useFilterClauses();
  const [intervalUnit, setIntervalUnit] = useTypedStringQueryParam<
    AnalyticsIntervalUnit
  >('interval_unit', 'day');
  const interval = useMemo<AnalyticsInterval>(
    () => ({
      unit: intervalUnit,
      value: 1,
      tz
    }),
    [tz, intervalUnit]
  );

  const [metric, setMetric] = useTypedStringQueryParam<
    EarningsBarChartCardMetricV2
  >('metric', 'commission_sum_net');

  const {
    tableProps,
    metrics,
    paginationSelectorProps,
    pagination,
    orderBy,
    columnSelectorProps
  } = useTable(availableColumns, columnDefinitions, {
    pageSize: PAGE_SIZE,
    defaultSortColumn: 'commission_sum_net',
    defaultVisibleColumns
  });

  const grouper = useChannelIdGrouper();

  const q = useMemo<AnalyticsQuery>(() => {
    return {
      range,
      compare: compare ? { range: compareRange } : undefined,
      groupBy: grouper.groupBy,
      filters,
      paginate: pagination,
      orderBy: [orderBy],
      select: metrics,
      columnTransformers: grouper.columnTransformers(space)
    };
  }, [
    range,
    compare,
    compareRange,
    grouper,
    filters,
    pagination,
    orderBy,
    metrics,
    space
  ]);
  const exportQ = useExportQuery(q);
  const tableData = useAnalyticsQueryV2(spaceId, q);

  const [canExportChannels] = useHasCurrentUserRequiredScopes([
    'reports.channels.view' // should be export
  ]);

  // Use this to show a message to new users about their product
  // analytics data
  const user = useCurrentUser();
  const isNewSpace =
    moment().diff(toMoment(user.space.createdAt), 'hours') < 48;

  return (
    <PerformancePageBody noTopPadding>
      <PageToolbar
        sticky
        offset={DEFAULT_OFFSET}
        className={css(() => ({ flexWrap: 'wrap' }))}
      >
        <PageToolbarSection flex={2}>
          <Typography
            variant="h6"
            component="span"
            style={{
              marginRight: '9px',
              position: 'relative',
              fontWeight: 'bold',
              top: '-2px'
            }}
          >
            Channels
          </Typography>
          <FiltersIcon isOpen={isOpen} toggle={toggle} />
        </PageToolbarSection>

        <PageToolbarSection flex={1} justifyContent="flex-end">
          <TimeframePickerDense
            value={timeframe}
            onChange={setTimeframe}
            options={options}
          />
        </PageToolbarSection>

        <div
          className={css((t) => ({
            marginTop: t.spacing(2),
            width: '100%'
          }))}
        >
          <FiltersDrawer
            isOpen={isOpen}
            title="Filter channels by"
            orderBy={orderBy.field as Metric}
            availableDimensions={availableDimensions}
          />
        </div>
      </PageToolbar>

      {isNewSpace && (
        <AlertBox variant="success" style={{ marginBottom: '36px' }}>
          Your affiliate network and program-level analytics will begin
          collecting here.
          <br />
          <br />
          In the mean time, try{' '}
          <Link
            style={{ borderBottom: '1px solid black' }}
            to={ROUTES.performanceNew.transactions.url()}
          >
            setting up reporting
          </Link>{' '}
          with your affiliate networks and programs.
        </AlertBox>
      )}
      <div>
        <Grid>
          <EarningsPieChartCardV2
            space={space}
            range={range}
            filters={filters}
            currency={currency}
            metric="commission_sum_net"
            grouper={grouper}
            heading="All Sites"
            subheading="How each site contributes to your total revenue"
            aspect={1.5}
            hideOther={true}
          />
          <EarningsChartCardWithoutDataV2
            space={space}
            range={range}
            search={EMPTY_ARR}
            interval={interval}
            intervalUnit={intervalUnit}
            setIntervalUnit={setIntervalUnit}
            filters={filters}
            currency={currency}
            metric={metric}
            setMetric={setMetric}
            selectableMetrics={['commission_sum_net', 'gmv_sum_net', 'c']}
            graphMode="channel"
            chartMode={chartMode}
            setChartMode={setChartMode}
          />
        </Grid>

        <FlexContainer justifyContent="space-between">
          <div>
            <Typography
              variant="body1"
              component="p"
              style={{ fontWeight: 'bold' }}
            >
              Channel metrics
            </Typography>
            <Typography
              variant="body2"
              component="p"
              color="textSecondary"
              paragraph
            >
              Compare channel performance during this period according to your
              key metrics
            </Typography>
          </div>

          <FlexContainer>
            <ColumnSelector {...columnSelectorProps} />
            <CustomPagination {...paginationSelectorProps} siblingCount={0} />

            {canExportChannels && (
              <ExportQueryButton
                query={exportQ}
                reportType="channels"
                title="Export channels to CSV"
              />
            )}
          </FlexContainer>
        </FlexContainer>

        <AnalyticsTable
          d={tableData}
          tableProps={tableProps}
          rowToKey={grouper.toKey}
          headProps={{ sticky: false }}
        />
      </div>
    </PerformancePageBody>
  );
};
