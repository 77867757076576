import React from 'react';
import { FlexContainer } from '../../../../../../layout/Flex';
import { Breadcrumbs } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { isNil } from 'lodash';
import Typography from '@material-ui/core/Typography';
import { css } from '../../../../../../emotion';
import { MetricSelector } from './MetricSelector';
import { Timeframe } from '../../../../../../components/analytics_v2/Timeframe';
import { SiteSelector } from '../../../../../../components/analytics_v2/SiteSelector';

interface LinkDefinition {
  to?: string;
  title: string;
}

interface TopBarProps {
  breadcrumbs: LinkDefinition[];
}

const ReferrerBreadcrumbs: React.FC<Pick<TopBarProps, 'breadcrumbs'>> = ({
  breadcrumbs
}) => (
  <Breadcrumbs className={css((t) => ({ marginRight: t.spacing(2) }))}>
    {breadcrumbs.map(({ title, to }) => {
      if (isNil(to)) {
        return (
          <Typography
            key={title}
            variant="h6"
            color="textPrimary"
            style={{ fontWeight: 'bold' }}
          >
            {title}
          </Typography>
        );
      }
      return (
        <Link to={to} key={title}>
          <Typography variant="h6" color="textPrimary">
            {title}
          </Typography>
        </Link>
      );
    })}
  </Breadcrumbs>
);

export const TopBar: React.FC<TopBarProps> = ({ breadcrumbs }) => {
  return (
    <FlexContainer marginBottom={1} justifyContent="space-between">
      <FlexContainer>
        <ReferrerBreadcrumbs breadcrumbs={breadcrumbs} />
        <MetricSelector />
        <SiteSelector />
      </FlexContainer>
      <Timeframe />
    </FlexContainer>
  );
};
