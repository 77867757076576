import { Paper, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { BookOpen } from 'react-feather';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { styled } from '../../../../../emotion';
import { IPostgresTags } from '../../../../../domainTypes/tags';
import { useRoutes } from '../../../../../routes';
import { Section } from '../../../../../layout/Section';
import {
  IPostgresTagsParent,
  useTagHierarchy
} from '../../../../../services/tags';
import { useCurrentUserScopes } from '../../../../../services/currentUser';
import { NoPermissions } from '../../../../../components/NoPermissions';
import { Centered } from '../../../../../layout/Centered';
import { Loader } from '../../../../../components/Loader';
import { Message } from '../../../../../components/Message';
import { TagManagerPrompt } from './components/TagManagerPrompt';
import { useTrackMixpanelView } from '../../../../../services/mixpanel';
import { ContentPageBody } from '../../../components/ContentPageBody';
import { SiteSelector } from '../../../../../components/analytics_v2/SiteSelector';
import { Timeframe } from '../../../../../components/analytics_v2/Timeframe';
import { TagMetricsTable } from './components/TagMetricsTable';
import { TagBarChart } from './components/TagBarChart';
import { TagTrendChart } from './components/TagTrendChart';
import { TagsSelector } from '../../../../../components/analytics_v2/TagsSelector';

const TopChartsGrid = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${(p) => p.theme.spacing(2)}px;
`;

const StyledDocsIcon = styled(Link)`
  position: relative;
  top: 4px;
  right: -2px;
  color: ${(p) => p.theme.palette.primary.main};
  display: inline-block;
  margin: ${(p) => p.theme.spacing(1)}px;
`;

export type Group = { id: string; name: string };

const TagTopBar = ({ tagGroup }: { tagGroup: IPostgresTags }) => {
  const { ROUTES } = useRoutes();

  return (
    <Section
      style={{
        marginBottom: '12px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <div
        style={{
          display: 'flex',
          gap: '6px'
        }}
      >
        <Typography
          variant="h6"
          component="span"
          style={{
            marginRight: '9px',
            position: 'relative',
            fontWeight: 'bold'
          }}
        >
          <span>{tagGroup.name} overview</span>
          <StyledDocsIcon to={ROUTES.docs.knowledgeBase.url('tags')}>
            <BookOpen size={22} />
          </StyledDocsIcon>
        </Typography>

        <SiteSelector />
        <TagsSelector tagGroup={tagGroup} />
      </div>
      <div
        style={{
          width: '50%',
          display: 'flex',
          justifyContent: 'right'
        }}
      >
        <Timeframe />
      </div>
    </Section>
  );
};

interface OverviewBodyProps {
  tagGroup: IPostgresTagsParent;
}

const OverviewBody = ({ tagGroup }: OverviewBodyProps) => {
  const mixpanelProperties = useMemo(
    () => ({
      tag_group_id: tagGroup.parent.id,
      tag_group_name: tagGroup.parent.name
    }),
    [tagGroup.parent.id, tagGroup.parent.name]
  );
  useTrackMixpanelView('view_tags_report', mixpanelProperties);

  const { parent } = tagGroup;

  return (
    <>
      <TagTopBar tagGroup={parent} />
      <Section>
        <TopChartsGrid>
          <TagBarChart tagGroup={tagGroup} />
          <TagTrendChart tagGroup={tagGroup} />
        </TopChartsGrid>
      </Section>
      <Section>
        <TagMetricsTable tagGroup={tagGroup} />
      </Section>
    </>
  );
};

interface PageContentBodyProps {
  groupId: string;
}

const PageContentBody = ({ groupId }: PageContentBodyProps) => {
  const scopes = useCurrentUserScopes();
  const canView = useMemo(() => {
    return scopes.has('reports.tags.view');
  }, [scopes]);
  const [tags, loadingTags] = useTagHierarchy();

  if (!canView) {
    return <NoPermissions />;
  }
  if (loadingTags) {
    return (
      <Centered height={300}>
        <Loader size={32} />
      </Centered>
    );
  }
  const tagGroup =
    !loadingTags && tags && tags.find(({ parent: { id } }) => id === groupId);

  if (!tagGroup) {
    return <Message message="Couldn't find requested tag group." />;
  }

  if (isEmpty(tagGroup.children)) {
    return (
      <Paper style={{ width: '80%', margin: '0 auto' }}>
        <TagManagerPrompt height={350} />
      </Paper>
    );
  }
  return <OverviewBody tagGroup={tagGroup} />;
};

interface PageContentTagGroupOverviewProps {
  groupId: string;
}

export const PageContentTagGroupOverview = ({
  groupId
}: PageContentTagGroupOverviewProps) => {
  return (
    <>
      <Helmet>
        <title>Tags | Affilimate</title>
      </Helmet>
      <ContentPageBody>
        <PageContentBody groupId={groupId} />
      </ContentPageBody>
    </>
  );
};
