import { InputBase, MenuItem, Select } from '@material-ui/core';
import moment from 'moment-timezone';
import React, { useMemo } from 'react';
import {
  AnalyticsQuery,
  ISOTimeRange
} from '../../../../../domainTypes/analytics_v2';
import { useNumberQueryParam } from '../../../../../routes';
import { ONE_MINUTE } from '../../../../../services/time';
import { DAY_FORMAT, Timeframe } from '../../../../../domainTypes/analytics';

const usePeriodSelectorState = () => {
  return useNumberQueryParam('period', 48);
};

const periodInHours = [
  1,
  6,
  12,
  24,
  48,
  /* TODO: remove this option when we get realtime sales data */ 24 * 7
];
const options = periodInHours.map((p) => (
  <MenuItem key={p} value={p}>
    {p === 168 ? 'Last week' : p === 1 ? 'Last hour' : `Last ${p} hours`}
  </MenuItem>
));

export const PeriodSelector: React.FC = () => {
  const [period, setPeriod] = usePeriodSelectorState();
  return (
    <Select
      value={period}
      onChange={(e) => {
        setPeriod(e.target.value as number);
      }}
      disableUnderline
      input={<InputBase style={{ fontSize: '0.85rem' }} />}
    >
      {options}
    </Select>
  );
};

export const usePeriodRange = (endDate: number): AnalyticsQuery['range'] => {
  const [period] = usePeriodSelectorState();
  return useMemo<ISOTimeRange>(() => {
    const end = new Date(endDate).toISOString();
    const start = new Date(endDate - period * 60 * ONE_MINUTE).toISOString();
    return {
      start,
      end
    };
  }, [period, endDate]);
};

export const comparePeriodRange = (range: ISOTimeRange): ISOTimeRange => {
  const start = moment(range.start);
  const end = moment(range.end);
  const diff = end.diff(start, 'h');
  return {
    start: start.subtract(diff, 'h').toISOString(),
    end: end.subtract(diff, 'h').toISOString()
  };
};

export const usePeriodRangeToTimeframe = (): Timeframe => {
  const [period] = usePeriodSelectorState();
  const userTimezone = useMemo(() => moment.tz.guess(), []);
  const now = Date.now();
  const normalizedPeriod = Math.max(period, 24);
  const end = moment(now).add(24, 'h');
  return {
    start: end.clone().subtract(normalizedPeriod, 'h').format(DAY_FORMAT),
    end: end.format(DAY_FORMAT),
    tz: userTimezone
  };
};
