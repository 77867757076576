import React, { useState, useMemo } from 'react';
import moment from 'moment-timezone';
import { PlatformWithColor } from '../../../../../components/PlatformWithColor';
import { IColumn } from '../../../../../components/Table/Column';
import { Payout } from '../../../../../domainTypes/payout';
import { getKnownPartnerForKeyUnsafe } from '../../../../../services/partner';
import {
  Groupers,
  RowsRenderer,
  Sorters,
  useSortQueryParam
} from '../../../../../components/GroupableList';
import { Doc } from '../../../../../domainTypes/document';
import { toMoment } from '../../../../../services/time';
import { Currency } from '../../../../../components/Number';
import { compact, groupBy } from 'lodash';
import { Dash } from '../../../../../components/Table/CountCell';
import {
  DEFAULT_OFFSET,
  DEFAULT_TOOLBAR_HEIGHT
} from '../../../../../layout/PageToolbar';
import { PayoutsDrawer } from './PayoutsDrawer';
import {
  AdditionActionsMenuOption,
  AdditionalActionsMenu
} from '../../../../../components/AdditionalActionsMenu';
import { Routes } from '../../../../../domainTypes/routes';
import { useRoutes } from '../../../../../routes';
import { toTransactionsLink } from '../service';
import {
  useStandardOptions,
  useTimeframeFromUrl
} from '../../../../../components/TimeframePicker';
import { Timeframe } from '../../../../../domainTypes/analytics';
import { PayoutId } from './PayoutId';
import { Typography } from '@material-ui/core';
import { PayoutStatusBadge } from '../../../components/PayoutStatus';
import { Section } from '../../../../../layout/Section';
import { styled } from '../../../../../emotion';
import { Arrow } from '../../../../../components/Arrow';

const ROW_HEIGHT = 40;
const YEAR_MONTH_FORMAT = 'YYYY-MM';

type PayoutGroup = {
  monthYear: string;
};

const GroupHeaderWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: ${(p) => p.theme.spacing(2)}px;
  padding-left: 0;
  cursor: pointer;
`;

const GroupDate = styled('div')`
  font-size: 14px;
  font-weight: 700;
  display: flex;
  align-items: center;
`;

const GroupStats = styled('div')`
  display: flex;
  justify-content: 'space-between';
  text-align: right;
  > :last-child {
    min-width: ${(p) => p.theme.spacing(16)}px;
  }
`;

type GrouperType = 'platform';

export type ColumnName =
  | 'platform'
  | 'payoutId'
  | 'payoutStatus'
  | 'payoutDate'
  | 'reportingPeriod'
  | 'netAmount'
  | 'actions';

export type PayoutsListColumn = IColumn<
  Doc<Payout>,
  ColumnName,
  {
    setSelectedPayout: React.Dispatch<React.SetStateAction<Doc<Payout> | null>>;
    routes: Routes;
    timeframe: Timeframe;
  }
>;

export const COLUMNS: PayoutsListColumn[] = [
  {
    key: 'platform',
    head: () => 'Platform',
    cell: (payout) => {
      const partner = getKnownPartnerForKeyUnsafe(payout.data.partnerKey);
      return (
        <>
          <PlatformWithColor partner={partner} />
          {payout.data.advertiserName && (
            <Typography
              variant="caption"
              color="textSecondary"
              component="span"
              style={{ marginLeft: '22px', fontSize: '13px' }}
            >
              {payout.data.advertiserName}
            </Typography>
          )}
        </>
      );
    },
    align: 'left',
    width: 250,
    flexGrow: 1
  },
  {
    key: 'payoutId',
    head: () => 'Payout ID',
    headInfo: () =>
      'Internal payout ID, often differs from the invoice ID. Can be searched for in the transaction report.',
    cell: (payout) => <PayoutId>{payout.data.payoutId}</PayoutId>,
    align: 'left',
    width: 100,
    flexGrow: 1
  },
  {
    key: 'payoutStatus',
    head: () => 'Status',
    cell: (payout) => {
      return <PayoutStatusBadge status={payout.data.payoutStatus} />;
    },
    align: 'left',
    width: 100,
    flexGrow: 1
  },
  {
    key: 'payoutDate',
    head: () => 'Payout Date',
    headInfo: () =>
      'Date the network marked this commission as paid. Can differ from bank deposits.',
    cell: (payout) => {
      return payout.data.payoutDate ? (
        toMoment(payout.data.payoutDate).format('L')
      ) : (
        <Dash />
      );
    },
    align: 'left',
    width: 100,
    sortable: true,
    flexGrow: 1
  },
  {
    key: 'reportingPeriod',
    head: () => 'Reporting period',
    headInfo: () =>
      'The period of time where commissions included in this payout originally occurred. Not provided by all platforms.',
    cell: (payout) => {
      return payout.data.reportingPeriod ? (
        `${toMoment(payout.data.reportingPeriod.start).format(
          'L'
        )} – ${toMoment(payout.data.reportingPeriod.end).format('L')}`
      ) : (
        <Dash />
      );
    },
    align: 'left',
    width: 150,
    flexGrow: 1
  },
  {
    key: 'netAmount',
    head: () => 'Net amount',
    headInfo: () =>
      'Net payout amount, after any taxes or deductions, as reported by the platform in the original payout currency.',
    cell: (payout) => {
      return payout.data.netAmount ? (
        <Currency
          cents={payout.data.netAmount.originalValue}
          currency={payout.data.netAmount.originalCurrency}
        />
      ) : (
        <Dash />
      );
    },
    align: 'right',
    width: 80,
    flexGrow: 1
  },
  {
    key: 'actions',
    head: () => 'Actions',
    cell: (payout, props) => {
      const additionalActions: AdditionActionsMenuOption<{
        payout: Doc<Payout>;
      }>[] = compact([
        {
          key: 'details',
          label: 'View details',
          href: props.routes.performanceNew.payouts.details.overview.url(
            payout.id
          )
        },
        {
          key: 'transactions',
          label: 'View transactions',
          href: toTransactionsLink(props.routes, payout, props.timeframe)
        },
        false && {
          key: 'download',
          label: 'Download invoice',
          onClick: async (e) => {
            e.stopPropagation();
            alert('downloading invoice!');
            return Promise.resolve(props);
          }
        }
      ]);

      return (
        <AdditionalActionsMenu options={additionalActions} data={{ payout }} />
      );
    },
    align: 'right',
    width: 0,
    flexGrow: 1
  }
];

export const SORTERS: Sorters<PayoutGroup, Doc<Payout>> = {
  platform: {
    key: 'platform',
    label: 'Platform',
    groups: { sort: (g) => g.key, dir: 'desc' },
    items: { sort: (p) => p.data.partnerKey, dir: 'asc' }
  },
  payoutId: {
    key: 'payoutId',
    label: 'Payout ID',
    groups: { sort: (g) => g.key, dir: 'desc' },
    items: { sort: (p) => p.data.payoutId, dir: 'asc' }
  },
  payoutDate: {
    key: 'payoutDate',
    label: 'Payout Date',
    groups: { sort: (g) => g.key, dir: 'desc' },
    items: {
      sort: (p) =>
        p.data.payoutDate ? p.data.payoutDate.toMillis() : Date.now(),
      dir: 'desc'
    }
  }
};

export const GROUPERS: Groupers<GrouperType, PayoutGroup, Doc<Payout>> = {
  platform: {
    key: 'platform',
    label: 'Platform',
    toKey: (p) => p.data.partnerKey,
    toLabel: (p) => p.key,
    defaultSorter: SORTERS.platform
  }
};

const isCurrentMonth = (monthStr: string) => {
  if (monthStr === 'null') {
    return false;
  }
  const month = moment(monthStr, YEAR_MONTH_FORMAT);
  const now = moment();
  return month.isSame(now, 'month');
};

const ArrowContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  font-size: 12px;
`;

const currentMonth = moment().format(YEAR_MONTH_FORMAT);

export const PayoutsList = ({ payouts }: { payouts: Doc<Payout>[] }) => {
  const { ROUTES } = useRoutes();
  const [selectedPayout, setSelectedPayout] = React.useState<Doc<
    Payout
  > | null>(null);
  const [[sorter, direction], setSort] = useSortQueryParam('sort', SORTERS);
  const { defaultOption } = useStandardOptions();
  const [timeframe] = useTimeframeFromUrl(defaultOption.value);
  const [groupsCollapsed, setGroupCollapsed] = useState<{
    [groupKey: string]: boolean;
  }>({
    null: true,
    [currentMonth]: true
  });

  const collapseGroup = (groupKey: string) => {
    setGroupCollapsed((prev) => ({
      ...prev,
      [groupKey]: !prev[groupKey]
    }));
  };

  const groupedByMonthYear = React.useMemo(() => {
    const groups = groupBy(payouts, (p) =>
      p.data.payoutDate
        ? toMoment(p.data.payoutDate).format(YEAR_MONTH_FORMAT)
        : null
    );
    // Return array of groups including
    // totals
    return Object.entries(groups)
      .map(([key, payouts]) => {
        const total = payouts.reduce(
          (acc, p) => acc + (p.data.netAmount?.convertedValue ?? 0),
          0
        );
        return {
          key,
          total,
          payouts
        };
      })
      .sort((a, b) => {
        return a.key < b.key ? 1 : -1;
      });
  }, [payouts]);

  const allPayouts = useMemo(() => {
    return Object.values(groupedByMonthYear).flatMap((g) => g.payouts);
  }, [groupedByMonthYear]);

  return (
    <div>
      {groupedByMonthYear.map((group) => {
        const isExpanded = !groupsCollapsed[group.key];
        return (
          <Section collapse={!isExpanded}>
            <GroupHeaderWrapper
              role="button"
              onClick={() => {
                collapseGroup(group.key);
              }}
            >
              <GroupDate>
                <div>
                  {group.key === 'null'
                    ? 'No payout date set'
                    : moment(group.key, YEAR_MONTH_FORMAT).format(
                        'MMMM YYYY'
                      )}{' '}
                  {isCurrentMonth(group.key) && (
                    <Typography
                      variant="body2"
                      component="span"
                      color="textSecondary"
                      style={{ marginLeft: 8 }}
                    >
                      (Month-to-date)
                    </Typography>
                  )}
                </div>
                <ArrowContainer>
                  <Arrow
                    width={16}
                    height={16}
                    dir={isExpanded ? 'DOWN' : 'RIGHT'}
                  />
                </ArrowContainer>
              </GroupDate>
              <GroupStats>
                <div>
                  <Typography
                    variant="body2"
                    component="span"
                    color="textSecondary"
                  >
                    {group.payouts.length} payouts
                  </Typography>
                </div>
                <div>
                  <Currency cents={group.total} currency="USD" />
                </div>
              </GroupStats>
            </GroupHeaderWrapper>
            {isExpanded && (
              <RowsRenderer
                variant="contained"
                rows={group.payouts}
                columns={COLUMNS}
                sorter={sorter || SORTERS.payoutDate}
                sortDirection={direction}
                onHeadClick={(c, dir) => setSort([SORTERS[c.key] || null, dir])}
                headProps={{
                  sticky: true,
                  offset: DEFAULT_OFFSET + DEFAULT_TOOLBAR_HEIGHT + 10
                }}
                otherProps={{ setSelectedPayout, routes: ROUTES, timeframe }}
                onRowClick={(p) => setSelectedPayout(p)}
                renderHead={true}
                chunkSize={30}
                rootMargin="400px"
                rowHeight={ROW_HEIGHT}
                rowToKey={(p) => p.id}
              />
            )}
          </Section>
        );
      })}
      <PayoutsDrawer
        open={selectedPayout !== null}
        setSelectedPayout={setSelectedPayout}
        payout={selectedPayout}
        allPayouts={allPayouts}
        onClose={() => setSelectedPayout(null)}
      />
    </div>
  );
};
