import React from 'react';
import ReactJson, { ReactJsonViewProps } from 'react-json-view';

export const Json: React.FC<{
  data: any;
  shouldCollapse?: ReactJsonViewProps['shouldCollapse'];
  onEdit?: ReactJsonViewProps['onEdit'];
}> = ({ data, shouldCollapse, onEdit }) => {
  if (data === undefined) {
    return <em>undefined</em>;
  }
  if (data === null) {
    return <em>null</em>;
  }
  return (
    <ReactJson
      src={data}
      name={null}
      theme="tomorrow"
      shouldCollapse={shouldCollapse}
      collapseStringsAfterLength={80}
      onEdit={onEdit}
      enableClipboard={false}
      displayDataTypes={false}
      displayObjectSize={false}
    />
  );
};
