import firebase from 'firebase/app';
import {
  compact,
  fromPairs,
  groupBy,
  keyBy,
  mapValues,
  partition,
  startCase
} from 'lodash';
import moment from 'moment-timezone';
import { useCallback, useEffect, useMemo } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import shortid from 'shortid';
import { UNKNOWN } from '../../components/GroupableList';
import {
  EMPTY_TREND_COUNTER,
  IProductCounter,
  IShortCounter,
  ProductAnalyticsQuery,
  ProductAnalyticsResponseSum,
  ProductAnalyticsResponseTimeseries,
  Timeframe,
  TIMEKEY_FORMAT
} from '../../domainTypes/analytics';
import { CurrencyCode } from '../../domainTypes/currency';
import { Doc, generateToDocFn } from '../../domainTypes/document';
import {
  addOneEarningToAnother,
  aggregateSales,
  EarningsArgsGroupedInTimeframe,
  EarningsRespGroupedInTimeframe,
  EMPTY_EARNING,
  IEarning,
  ITrackedConvertedSale,
  toEarningFromMinimal
} from '../../domainTypes/performance';
import {
  CreateProductArgs,
  INarrowProductWithCountsAndTrendsAndOccurrencesAndSales,
  IPostgresProduct,
  IProduct,
  IProductAliasMerged,
  IProductDestination,
  IProductIssue,
  IProductWithCountsAndTrends,
  IProductWithCountsAndTrendsAndOccurrences,
  IProductWithCountsAndTrendsAndOccurrencesAndSales,
  PostgresProductQuery
} from '../../domainTypes/product';
import { ISpace } from '../../domainTypes/space';
import { useSalesInTimeFrameForPageBySpaceId } from '../../features/PerformanceNew/services/sale';
import { fireAndForget } from '../../helpers';
import { usePromise } from '../../hooks/usePromise';
import {
  batchDelete,
  batchSet,
  combineLoadingValues,
  combineLoadingValues3,
  LoadingValue,
  refreshTimestamp,
  store,
  updateDoc,
  useMappedLoadingValue
} from '../../services/db';
import { CF, FS } from '../../versions';
import {
  multiplyTimeframe,
  toComparableTimeframe,
  useCountsInTimeframePerProductForPageFs,
  useCountsInTimeframePerProductForPagePg,
  useProductCountsInTimeframeFs
} from '../analytics';
import {
  useDenormalizedCountsInTimeframePerProductForProducts,
  usePageOccurrencesByProduct
} from '../analytics/denormalization';
import { toChecksum } from '../checksum';
import {
  getCurrentSpace,
  getCurrentUser,
  useCurrentUser
} from '../currentUser';
import { callFirebaseFunction } from '../firebaseFunctions';
import {
  CollectionListener,
  createCollectionListenerStore,
  useCollectionListener
} from '../firecache/collectionListener';
import {
  createDocumentListenerGetter,
  useDocumentListener
} from '../firecache/documentListener';
import {
  getPartnerForUrl,
  getPartnerKeyForProductAtPointInTime
} from '../partner';
import { useEarnings } from '../sales/earnings';
import { usePostgres } from '../sales/service';
import { now, timeframeToMoments, timeframeToMs } from '../time';
import { removeTrailingSlash } from '../url';
import {
  flushPgProductsCacheForSpace,
  getPgProductsCacheForSpace
} from './cache';

const productsCollection = () => store().collection(FS.products);

export const toProductDoc = generateToDocFn<IProduct>((p) => {
  p.aliases = p.aliases || []; // introduced later - making sure it's always defined
  p.destinations = p.destinations || []; // introduced later - making sure it's always defined
  p.issues = p.issues || [];
  return p;
});

const getProductsQuery = (spaceId: string) => {
  return productsCollection().where('spaceId', '==', spaceId);
};

const getCollectionListener = createCollectionListenerStore(
  (spaceId) => new CollectionListener(getProductsQuery(spaceId), toProductDoc)
);

const getDocListener = createDocumentListenerGetter(
  (productId) => productsCollection().doc(productId),
  toProductDoc
);

export const getProductsBySpaceId = (spaceId: string) => {
  return getCollectionListener(spaceId).get();
};

export const getProductDocumentListener = createDocumentListenerGetter(
  (id) => productsCollection().doc(id),
  toProductDoc
);

export const useProduct = (productId: string) => {
  return useDocumentListener(getDocListener(productId));
};

/**
 * @deprecated
 */
export const useProducts = () => {
  const { space } = useCurrentUser();
  const pg = usePostgres();
  useEffect(() => {
    pg && console.log('Using deprecated hook useProducts');
  }, [pg]);
  return useProductsBySpaceId(space.id);
};

export const useProductsBySpaceId = (spaceId: string) => {
  const pg = usePostgres();
  useEffect(() => {
    pg && console.log('Using deprecated hook useProducts');
  }, [pg]);
  return useCollectionListener(getCollectionListener(spaceId));
};

/**
 * @deprecated
 */
export const useHasAnyProducts = () => {
  return useMappedLoadingValue(
    useCollection(getProductsQuery(getCurrentSpace().id).limit(1)),
    (v) => v && v.docs && !!v.docs.length
  );
};

export const useProductsWithCountsAndTrendsInTimeframeFs = (
  timeframe: Timeframe,
  compare: boolean
): LoadingValue<Doc<IProductWithCountsAndTrends>[]> => {
  const { space } = useCurrentUser();
  return useMappedLoadingValue(
    combineLoadingValues(
      useProductsBySpaceId(space.id),
      useProductCountsInTimeframeFs(space.id, timeframe, compare)
    ),
    ([docs, counts]) => {
      return docs.map((d) => {
        return {
          id: d.id,
          collection: FS.products,
          data: {
            ...d.data,
            counts: counts[d.id] || EMPTY_TREND_COUNTER()
          }
        };
      });
    }
  );
};

export const useEarningsByProductInTimeframe = (
  spaceId: string,
  timeframe: Timeframe,
  currency: CurrencyCode,
  compare: boolean
) => {
  const { start, end, tz } = timeframe;
  const queries = useMemo<EarningsArgsGroupedInTimeframe[]>(() => {
    const tf: Timeframe = { start, end, tz };
    return compact([
      {
        type: 'groupedInTimeframe',
        d: { groupBy: ['product_id'], dates: timeframeToMs(tf), currency }
      },
      compare && {
        type: 'groupedInTimeframe',
        d: {
          groupBy: ['product_id'],
          dates: timeframeToMs(toComparableTimeframe(tf)),
          currency
        }
      }
    ]);
  }, [start, end, tz, compare, currency]);

  return useMappedLoadingValue(
    useEarnings<EarningsRespGroupedInTimeframe[]>(spaceId, queries, currency),
    (r) => {
      console.log('useEarningsByProductInTimeframe', r);
      const curr = r.res[0];
      const prev: EarningsRespGroupedInTimeframe | undefined = r.res[1];
      const prevByProductId = keyBy(
        prev?.d || [],
        (k) => (k.group['product_id'] as string) || UNKNOWN
      );
      const currByProductId = keyBy(
        curr.d,
        (k) => (k.group['product_id'] as string) || UNKNOWN
      );
      return mapValues(currByProductId, (x, k) => {
        const p = prevByProductId[k]?.d;
        return {
          prev: p ? toEarningFromMinimal(p) : EMPTY_EARNING(currency),
          curr: toEarningFromMinimal(x.d)
        };
      });
    }
  );
};

export const usePageProductIndex = (
  timeframe: Timeframe
): LoadingValue<
  {
    href: string;
    products: { productId: string; occurrences: number }[];
  }[]
> => {
  const { space } = useCurrentUser();
  return useMappedLoadingValue(
    usePageOccurrencesByProduct(space.id, timeframe),
    (t) => {
      const res: {
        [href: string]: {
          [productId: string]: number;
        };
      } = {};
      Object.entries(t).forEach(([productId, occsByPage]) => {
        Object.entries(occsByPage).forEach(([href, occs]) => {
          const container = (res[href] = res[href] || {});
          container[productId] = occs;
        });
      });

      return Object.entries(res).map(([href, ps]) => {
        return {
          href,
          products: Object.entries(ps).map(([productId, occs]) => ({
            productId,
            occurrences: occs
          }))
        };
      });
    }
  );
};

type SalesToProductOccurrenceMap = {
  [productId: string]: {
    all: {
      prev: ITrackedConvertedSale[];
      curr: ITrackedConvertedSale[];
    };
    byOccurrence: {
      [occ: string]: {
        prev: ITrackedConvertedSale[];
        curr: ITrackedConvertedSale[];
      };
    };
  };
};

/**
 * @deprecated
 */
const toProductsWithCountsAndTrendsAndOccurrencesAndSalesOld = (
  products: Doc<IProduct>[],
  counts: IProductCounter[],
  salesMap: SalesToProductOccurrenceMap,
  currency: CurrencyCode
): Doc<IProductWithCountsAndTrendsAndOccurrencesAndSales>[] => {
  const dict = keyBy(products, (d) => d.id);
  return compact(
    counts.map((c) => {
      const { productId, total, byOccurrence } = c;
      const doc = dict[productId];
      if (!doc) {
        return null;
      }
      const sales = salesMap[productId] || {
        all: {
          prev: [],
          curr: []
        },
        byOccurrence: {}
      };
      return {
        ...doc,
        data: {
          ...doc.data,
          counts: total,
          sales: {
            prev: {
              earnings: aggregateSales(
                sales.all.prev.map((d) => d.sale),
                currency
              )
            },
            curr: {
              earnings: aggregateSales(
                sales.all.curr.map((d) => d.sale),
                currency
              )
            }
          },
          byOccurrence: mapValues(byOccurrence, (v, k) => {
            const ss = sales.byOccurrence[k] || {
              prev: [],
              curr: []
            };
            return {
              counts: v,
              sales: {
                prev: {
                  earnings: aggregateSales(
                    ss.prev.map((d) => d.sale),
                    currency
                  )
                },
                curr: {
                  earnings: aggregateSales(
                    ss.curr.map((d) => d.sale),
                    currency
                  )
                }
              }
            };
          })
        }
      };
    })
  );
};

const salesToProductOccurrenceMap = (
  sales: Doc<ITrackedConvertedSale>[],
  cutOff: moment.Moment
): SalesToProductOccurrenceMap => {
  const byProductId = groupBy(
    sales.map((s) => s.data),
    (s) => (s.click ? s.click.pId : 'UNKNOWN')
  );

  return mapValues(byProductId, (ss) => {
    const [prev, curr] = partition(ss, (s) => s.dates.sale.isBefore(cutOff));
    const all = { prev, curr };

    const byOccurrencePrev = groupBy(prev, (s) =>
      s.click ? s.click.occ : 'UNKNOWN'
    );
    const byOccurrenceCurr = groupBy(curr, (s) =>
      s.click ? s.click.occ : 'UNKNOWN'
    );

    const byOccurrence: {
      [occ: string]: {
        prev: ITrackedConvertedSale[];
        curr: ITrackedConvertedSale[];
      };
    } = {};
    Object.entries(byOccurrencePrev).forEach(([k, v]) => {
      const container = (byOccurrence[k] = byOccurrence[k] || {
        prev: [],
        curr: []
      });
      container.prev = v;
    });
    Object.entries(byOccurrenceCurr).forEach(([k, v]) => {
      const container = (byOccurrence[k] = byOccurrence[k] || {
        prev: [],
        curr: []
      });
      container.curr = v;
    });
    return { all, byOccurrence };
  });
};

export const useProductsWithCountsOnPage = (
  href: string,
  timeframe: Timeframe,
  compare: boolean
): LoadingValue<Doc<IProductWithCountsAndTrendsAndOccurrences>[]> => {
  const { space } = useCurrentUser();
  return useMappedLoadingValue(
    combineLoadingValues(
      useProductsBySpaceId(space.id),
      useCountsInTimeframePerProductForPageFs(href, timeframe, compare)
    ),
    ([products, counts]) => {
      const dict = keyBy(products, (d) => d.id);
      return compact(
        counts.map((c) => {
          const { productId, total, byOccurrence } = c;
          const doc = dict[productId];
          if (!doc) {
            return null;
          }
          return {
            ...doc,
            data: {
              ...doc.data,
              counts: total,
              byOccurrence: mapValues(byOccurrence, (v, k) => {
                return { counts: v };
              })
            }
          };
        })
      );
    }
  );
};

const useEarningsOnPagePerProduct = (
  spaceId: string,
  url: string,
  timeframe: Timeframe,
  compare: boolean,
  currency: CurrencyCode
) => {
  const queries: EarningsArgsGroupedInTimeframe[] = useMemo(
    () =>
      compact([
        {
          type: 'groupedInTimeframe',
          d: {
            groupBy: ['product_id', 'click_occ'],
            page_url: [url],
            dateColumn: 'click_or_sale_date',
            dates: {
              ...timeframeToMs(timeframe),
              column: 'click_or_sale_date'
            },
            currency
          }
        },
        compare && {
          type: 'groupedInTimeframe',
          d: {
            groupBy: ['product_id', 'click_occ'],
            page_url: [url],
            dates: {
              ...timeframeToMs(toComparableTimeframe(timeframe)),
              column: 'click_or_sale_date'
            },
            currency
          }
        }
      ]),
    [url, timeframe, compare, currency]
  );
  return useMappedLoadingValue(
    useEarnings<EarningsRespGroupedInTimeframe[]>(spaceId, queries, currency),
    (r) => {
      // prev is empty if we are in non-compare mode
      console.log('useEarningsOnPagePerProduct', r);
      const [curr, prev = {}] = r.res.map((x) => {
        return mapValues(
          groupBy(x.d, (g) => g.group['product_id'] as string),
          (gs) => {
            const byOccurrence = mapValues(
              keyBy(gs, (g) => (g.group['click_occ'] as string) ?? UNKNOWN),
              (g) => toEarningFromMinimal(g.d)
            );
            return {
              total: Object.values(byOccurrence).reduce(
                addOneEarningToAnother,
                EMPTY_EARNING(currency)
              ),
              byOccurrence
            };
          }
        );
      });
      return { curr, prev };
    }
  );
};

const toProductsWithCountsAndTrendsAndOccurrencesAndSales = (
  products: Doc<IProduct>[],
  counts: IProductCounter[],
  earnings: {
    prev: {
      [productId: string]: {
        total: IEarning;
        byOccurrence: { [occ: string]: IEarning };
      };
    };
    curr: {
      [productId: string]: {
        total: IEarning;
        byOccurrence: { [occ: string]: IEarning };
      };
    };
  },
  currency: CurrencyCode
): Doc<IProductWithCountsAndTrendsAndOccurrencesAndSales>[] => {
  const allProductIds = [
    ...new Set([
      ...counts.map((c) => c.productId),
      ...Object.keys(earnings.prev),
      ...Object.keys(earnings.curr)
    ])
  ];
  const dict = keyBy(products, (d) => d.id);
  const countsByProductId = keyBy(counts, (c) => c.productId);

  return compact(
    allProductIds.map<Doc<
      IProductWithCountsAndTrendsAndOccurrencesAndSales
    > | null>((productId) => {
      const doc = dict[productId];
      if (!doc) {
        return null;
      }
      const counts = countsByProductId[productId];
      const allOccurences = [
        ...new Set([
          ...Object.keys(counts?.byOccurrence || {}),
          ...Object.keys(earnings.prev[productId]?.byOccurrence || {}),
          ...Object.keys(earnings.curr[productId]?.byOccurrence || {})
        ])
      ];
      return {
        ...doc,
        data: {
          ...doc.data,
          counts: counts?.total || EMPTY_TREND_COUNTER(),
          sales: {
            prev: {
              earnings:
                earnings.prev[productId]?.total || EMPTY_EARNING(currency)
            },
            curr: {
              earnings:
                earnings.curr[productId]?.total || EMPTY_EARNING(currency)
            }
          },
          byOccurrence: fromPairs(
            allOccurences.map((occ) => {
              return [
                occ,
                {
                  counts: counts?.byOccurrence[occ] || EMPTY_TREND_COUNTER(),
                  sales: {
                    prev: {
                      earnings:
                        earnings.prev[productId]?.byOccurrence[occ] ||
                        EMPTY_EARNING(currency)
                    },
                    curr: {
                      earnings:
                        earnings.curr[productId]?.byOccurrence[occ] ||
                        EMPTY_EARNING(currency)
                    }
                  }
                }
              ];
            })
          )
        }
      };
    })
  );
};

const toNarrowProductsWithCountsAndTrendsAndOccurrencesAndSales = (
  products: IPostgresProduct[],
  counts: IProductCounter[],
  earnings: {
    prev: {
      [productId: string]: {
        total: IEarning;
        byOccurrence: { [occ: string]: IEarning };
      };
    };
    curr: {
      [productId: string]: {
        total: IEarning;
        byOccurrence: { [occ: string]: IEarning };
      };
    };
  },
  currency: CurrencyCode
): INarrowProductWithCountsAndTrendsAndOccurrencesAndSales[] => {
  const allProductIds = [
    ...new Set([
      ...counts.map((c) => c.productId),
      ...Object.keys(earnings.prev),
      ...Object.keys(earnings.curr)
    ])
  ];
  const dict = keyBy(products, (d) => d.id);
  const countsByProductId = keyBy(counts, (c) => c.productId);

  return compact(
    allProductIds.map<INarrowProductWithCountsAndTrendsAndOccurrencesAndSales | null>(
      (productId) => {
        const doc = dict[productId];
        if (!doc) {
          return null;
        }
        const counts = countsByProductId[productId];
        const allOccurences = [
          ...new Set([
            ...Object.keys(counts?.byOccurrence || {}),
            ...Object.keys(earnings.prev[productId]?.byOccurrence || {}),
            ...Object.keys(earnings.curr[productId]?.byOccurrence || {})
          ])
        ];
        return {
          ...doc,
          counts: counts?.total || EMPTY_TREND_COUNTER(),
          sales: {
            prev: {
              earnings:
                earnings.prev[productId]?.total || EMPTY_EARNING(currency)
            },
            curr: {
              earnings:
                earnings.curr[productId]?.total || EMPTY_EARNING(currency)
            }
          },
          byOccurrence: fromPairs(
            allOccurences.map((occ) => {
              return [
                occ,
                {
                  counts: counts?.byOccurrence[occ] || EMPTY_TREND_COUNTER(),
                  sales: {
                    prev: {
                      earnings:
                        earnings.prev[productId]?.byOccurrence[occ] ||
                        EMPTY_EARNING(currency)
                    },
                    curr: {
                      earnings:
                        earnings.curr[productId]?.byOccurrence[occ] ||
                        EMPTY_EARNING(currency)
                    }
                  }
                }
              ];
            })
          )
        };
      }
    )
  );
};

export const useProductsWithCountsAndSalesOnPage = (
  url: string,
  timeframe: Timeframe,
  compare: boolean,
  currency: CurrencyCode
) => {
  const { space } = useCurrentUser();
  return useMappedLoadingValue(
    combineLoadingValues3(
      useEarningsOnPagePerProduct(space.id, url, timeframe, compare, currency),
      useProductsBySpaceId(space.id), // this should ideally happen only one step later, where we can target which products to get, so that we don't have to fetch all of them
      useCountsInTimeframePerProductForPageFs(url, timeframe, compare)
    ),
    ([earnings, products, counts]) => {
      return toProductsWithCountsAndTrendsAndOccurrencesAndSales(
        products,
        counts,
        earnings,
        currency
      );
    }
  );
};

export const useProductsWithCountsAndSalesOnPageWithLazyProductRetrieval = (
  url: string,
  timeframe: Timeframe,
  compare: boolean,
  currency: CurrencyCode
) => {
  const { space } = useCurrentUser();
  const spaceId = space.id;
  const [value] = combineLoadingValues(
    useEarningsOnPagePerProduct(spaceId, url, timeframe, compare, currency),
    useCountsInTimeframePerProductForPagePg(spaceId, url, timeframe, compare)
  );
  const [earnings, counts] = value || [];
  return usePromise(async () => {
    if (!earnings || !counts) {
      return new Promise<
        INarrowProductWithCountsAndTrendsAndOccurrencesAndSales[]
      >(() => []);
    }

    console.log('COUNTS', counts);
    const pIds = [
      ...new Set([
        ...counts.map((c) => c.productId),
        ...Object.keys(earnings.prev),
        ...Object.keys(earnings.curr)
      ])
    ];
    const products = await getProductsByIdPg(spaceId, pIds);
    return toNarrowProductsWithCountsAndTrendsAndOccurrencesAndSales(
      products,
      counts,
      earnings,
      currency
    );
  }, [spaceId, earnings, counts, currency]);
};

/**
 * @deprecated
 */
export const useProductsWithCountsAndSalesOnPageOld = (
  href: string,
  timeframe: Timeframe,
  compare: boolean,
  currency: CurrencyCode
) => {
  const { space } = useCurrentUser();
  return useMappedLoadingValue(
    combineLoadingValues3(
      useSalesInTimeFrameForPageBySpaceId(
        space.id,
        href,
        compare ? multiplyTimeframe(timeframe, 2) : timeframe,
        currency
      ),
      useProductsBySpaceId(space.id),
      useCountsInTimeframePerProductForPageFs(href, timeframe, compare)
    ),
    ([sales, products, counts]) => {
      const moments = timeframeToMoments(timeframe);
      const cutOff = moments.start;
      const salesMap = salesToProductOccurrenceMap(sales, cutOff);
      return toProductsWithCountsAndTrendsAndOccurrencesAndSalesOld(
        products,
        counts,
        salesMap,
        currency
      );
    }
  );
};

export const filterProductIdsByPartnerAtPointInTime = (
  partnerKey: string,
  timeKey: string,
  tz: string,
  productIds: string[],
  productsById: { [productId: string]: Doc<IProduct> }
) => {
  const threshold = moment.tz(timeKey, TIMEKEY_FORMAT, tz).endOf('d').valueOf();

  return productIds.filter((pId) => {
    const product = productsById[pId];
    if (!product) {
      return false;
    }
    const pK = getPartnerKeyForProductAtPointInTime(product.data, threshold);
    return pK === partnerKey;
  });
};

export const useProductsWithCountsForPartner = (
  partnerKey: string,
  timeframe: Timeframe,
  compare: boolean
) => {
  const { space } = getCurrentUser();
  const [products, loadingPs, errorPs] = useProductsBySpaceId(space.id);

  const { tz } = timeframe;
  const productsById = useMemo(() => {
    if (!products) {
      return {};
    }
    return keyBy(products, (p) => p.id);
  }, [products]);

  const filterProductIds = useCallback(
    (timeKey: string, productIds: string[]) => {
      return filterProductIdsByPartnerAtPointInTime(
        partnerKey,
        timeKey,
        tz,
        productIds,
        productsById
      );
    },
    [productsById, tz, partnerKey]
  );

  const [
    counts,
    loadingCounts,
    errorCounts
  ] = useDenormalizedCountsInTimeframePerProductForProducts(
    space.id,
    filterProductIds,
    timeframe,
    compare
  );

  const productsWithCounts:
    | Doc<IProductWithCountsAndTrendsAndOccurrences>[]
    | undefined = useMemo(() => {
    if (products && counts) {
      const dict = keyBy(products, (d) => d.id);
      return compact(
        counts.map((c) => {
          const { productId, total, byOccurrence } = c;
          const doc = dict[productId];
          if (!doc) {
            return null;
          }
          return {
            ...doc,
            data: {
              ...doc.data,
              counts: total,
              byOccurrence: mapValues(byOccurrence, (v, k) => {
                return { counts: v };
              })
            }
          };
        })
      );
    }
  }, [products, counts]);

  return [
    productsWithCounts,
    loadingPs || loadingCounts,
    errorPs || errorCounts
  ] as [typeof productsWithCounts, boolean, any];
};

export const deleteProducts = async (spaceId: string, productIds: string[]) => {
  const products = await Promise.all(
    productIds.map((productId) =>
      store()
        .collection(FS.products)
        .doc(productId)
        .get()
        .then((s) => (s.exists ? toProductDoc(s) : null))
    )
  ).then(compact);

  const finalProductIds = products.map((p) => p.id);

  return Promise.all([
    batchDelete(FS.products, finalProductIds),
    callFirebaseFunction(CF.products.deleteProductsFromPg, {
      spaceId,
      // Technically should use finalProductIds here - but in case something went wrong with
      // a prior PG deletion, a product id that's NOT in FS anymore might appear here.
      // Try to remove it again, so that the system cleans up after itself.
      productIds
    })
  ]).then(async () => {
    // don't wait - fire and forget
    callFirebaseFunction(
      CF.trackingConfig_v2.removeProductsFromTrackingConfig,
      {
        spaceId,
        products
      }
    );
    flushPgProductsCacheForSpace(spaceId);
    return products;
  });
};

export const deleteProductsOld = (
  spaceId: string,
  products: Doc<IProduct>[]
) => {
  return batchDelete(
    FS.products,
    products.map((p) => p.id)
  ).then(() => {
    // don't wait - fire and forget
    callFirebaseFunction(
      CF.trackingConfig_v2.removeProductsFromTrackingConfig,
      {
        spaceId,
        products
      }
    );
    return products;
  });
};

// TODO When the url changes, we probably should also check
// if the destination changed!
export const updateProduct = (
  id: string,
  { name, url }: Pick<IProduct, 'name' | 'url'>
) => {
  return productsCollection().doc(id).update({
    name,
    url
  });
};

export const updateProductName = async (
  product: Doc<IProduct>,
  name: string
) => {
  product.data.name = name;
  await updateDoc<IProduct>(product, (d) => ({ name: d.name }));

  const spaceId = product.data.spaceId;
  await callFirebaseFunction(CF.products.pushProductsToPg, {
    spaceId,
    products: [product]
  });

  flushPgProductsCacheForSpace(spaceId);
};

const toAliasMerged = (doc: Doc<IProduct>): IProductAliasMerged => {
  const currentUser = getCurrentUser();
  return {
    type: 'merged',
    createdBy: currentUser ? currentUser.id : 'admin',
    createdAt: now(),

    url: doc.data.url,
    formerProductId: doc.id
  };
};

export const mergeProducts = (target: Doc<IProduct>, src: Doc<IProduct>) => {
  return productsCollection()
    .doc(target.id)
    .update({
      aliases: [...target.data.aliases, toAliasMerged(src), ...src.data.aliases]
    });
};

const createProductDoc = (
  spaceId: string,
  createdBy: string,
  args: CreateProductArgs,
  createdAt: firebase.firestore.Timestamp
): Doc<IProduct> => {
  const destinations: IProductDestination[] = [
    {
      url: args.destinationUrl,
      foundAt: createdAt
    }
  ];
  return {
    id: shortid(),
    collection: FS.products,
    data: {
      name: args.name,
      url: args.url,
      spaceId,
      aliases: [],
      destinations,
      createdAt,
      createdBy,
      issues: [],
      partnerKey: null
    }
  };
};

const notifyOtherPartiesAboutCreatedProducts = async (
  spaceId: string,
  docs: Doc<IProduct>[],

  opts: { trackImportEvent?: boolean } = {}
) => {
  await Promise.all([
    callFirebaseFunction(CF.trackingConfig_v2.addProductsToTrackingConfig, {
      spaceId,
      products: docs,
      trackImportEvent: !!opts.trackImportEvent
    }),
    callFirebaseFunction(CF.products.pushProductsToPg, {
      spaceId,
      products: docs
    })
  ]);
  flushPgProductsCacheForSpace(spaceId);
};

export const createProducts = async (
  createdBy: string,
  space: ISpace,
  products: CreateProductArgs[],
  createdAt: firebase.firestore.Timestamp,
  opts: { trackImportEvent?: boolean } = {}
) => {
  const docs = products.map((p) =>
    createProductDoc(space.id, createdBy, p, createdAt)
  );

  await batchSet(FS.products, docs);

  await Promise.all([
    notifyOtherPartiesAboutCreatedProducts(space.id, docs, opts),
    fireAndForget(() =>
      callFirebaseFunction(
        CF.pages.tryRecreateExistingScreenshots,
        {
          spaceId: space.id,
          urls: [
            ...products.reduce<Set<string>>((m, p) => {
              p.pageUrls.forEach((url) => m.add(removeTrailingSlash(url)));
              return m;
            }, new Set())
          ]
        },
        600
      )
    ),
    async () => {
      if (!space.onboarding.importedProducts) {
        const update: Partial<ISpace> = {
          onboarding: {
            ...space.onboarding,
            importedProducts: {
              finishedAt: createdAt,
              finishedBy: createdBy
            }
          }
        };
        await store().collection(FS.spaces).doc(space.id).update(update);
      }
    }
  ]);

  return docs;
};

export const createProduct = async (name: string, url: string) => {
  const space = getCurrentSpace();
  const spaceId = space.id;
  const createdAt = now();
  const createdBy = getCurrentUser().id;
  const { destinationUrl } = await callFirebaseFunction(
    CF.products.getDestinationUrl,
    { spaceId, url }
  );
  const doc = createProductDoc(
    spaceId,
    createdBy,
    {
      name,
      url,
      destinationUrl,
      pageUrls: [],
      partnerKey: getPartnerForUrl(destinationUrl).key
    },
    createdAt
  );

  await productsCollection().doc(doc.id).set(doc.data);
  await notifyOtherPartiesAboutCreatedProducts(spaceId, [doc]);

  return doc;
};

export const findProductsInSpaceByName = (spaceId: string, name: string) => {
  const lowerCased = name.toLowerCase();
  return getProductsBySpaceId(spaceId).then((docs) =>
    docs.filter((d) => d.data.name.toLowerCase().indexOf(lowerCased) !== -1)
  );
};

export const findProductsByName = (name: string) =>
  findProductsInSpaceByName(getCurrentSpace().id, name);

export const getDestinationUrlForProduct = (product: IProduct) => {
  const destination = product.destinations[product.destinations.length - 1];
  return destination ? destination.url : product.url;
};

export const tryUpdateDestinationUrlForProduct = async (p: Doc<IProduct>) => {
  const { spaceId, url } = p.data;
  const destUrl = getDestinationUrlForProduct(p.data);

  const { destinationUrl: nextDestUrl } = await callFirebaseFunction(
    CF.products.getDestinationUrl,
    { spaceId, url }
  );

  if (!nextDestUrl) {
    return false;
  }

  if (removeTrailingSlash(nextDestUrl) === removeTrailingSlash(destUrl)) {
    return false;
  }

  const destinations: IProductDestination[] = [
    ...p.data.destinations,
    {
      url: nextDestUrl,
      foundAt: now()
    }
  ];

  p.data.destinations = destinations;
  await updateDoc<IProduct>(p, (d) => ({ destinations: d.destinations }));
  await notifyOtherPartiesAboutCreatedProducts(spaceId, [p]);
  flushPgProductsCacheForSpace(spaceId);
  return true;
};

const getProductAnalyticsFnName = (v: 1 | 2) => {
  return v === 1
    ? 'analytics-getProductAnalytics'
    : 'analytics_v2-getProductAnalytics';
};

type GetProductAnalyticsOptions = {
  v: 1 | 2;
  noCache?: boolean;
};
export const getProductAnalytics = (
  spaceId: string,
  q: Omit<ProductAnalyticsQuery, 'asTimeseries'>,
  opts: GetProductAnalyticsOptions = { v: 1, noCache: false }
): Promise<ProductAnalyticsResponseSum> => {
  const fullQ: ProductAnalyticsQuery = {
    ...q,
    asTimeseries: false
  };
  const req = () =>
    callFirebaseFunction<
      ProductAnalyticsResponseSum,
      { spaceId: string; q: ProductAnalyticsQuery }
    >(getProductAnalyticsFnName(opts.v), {
      spaceId,
      q: fullQ
    });
  if (opts.noCache) {
    return req();
  }
  const checksum = toChecksum(fullQ);
  const cache = getPgProductsCacheForSpace(spaceId).analytics;
  return (cache[checksum] = cache[checksum] || req());
};

export const getProductAnalyticsAsTimeseries = (
  spaceId: string,
  q: Omit<ProductAnalyticsQuery, 'asTimeseries'>,
  opts: GetProductAnalyticsOptions = { v: 1, noCache: false }
): Promise<ProductAnalyticsResponseTimeseries> => {
  const fullQ: ProductAnalyticsQuery = {
    ...q,
    asTimeseries: true
  };
  const req = () =>
    callFirebaseFunction<
      ProductAnalyticsResponseTimeseries,
      { spaceId: string; q: ProductAnalyticsQuery }
    >(getProductAnalyticsFnName(opts.v), {
      spaceId,
      q: fullQ
    });
  if (!opts.noCache) {
    return req();
  }
  const checksum = toChecksum(fullQ);
  const cache = getPgProductsCacheForSpace(spaceId).analytics;
  return (cache[checksum] = cache[checksum] || req());
};

export const getProductAnalyticsAsCountMap = async (
  q: Omit<ProductAnalyticsQuery, 'asTimeseries'> & { spaceId: string }
): Promise<
  {
    productId: string;
    counts: IShortCounter;
    name: string;
    url: string;
    partnerKey: string;
    issues: IProductIssue[];
    epc: number;
    ctr: number;
    earnings: number;
    createdAt: firebase.firestore.Timestamp;
  }[]
> => {
  const spaceId = q.spaceId;
  return getProductAnalytics(spaceId, q).then((r) => {
    console.log('getProductAnalytics', r);
    const mapped = r.d.map(
      ([
        productId,
        p,
        s,
        v,
        c,
        earnings,
        epc,
        ctr,
        name,
        url,
        partnerKey,
        issues,
        createdAt
      ]) => {
        return {
          productId,
          counts: {
            p: p,
            s: s,
            v: v,
            c: c
          },
          name,
          url,
          partnerKey,
          issues,
          earnings,
          epc,
          ctr,
          createdAt: refreshTimestamp(createdAt)
        };
      }
    );
    return mapped;
  });
};

const refreshPostgresProduct = (d: IPostgresProduct) => {
  d.created_at = refreshTimestamp(d.created_at);
  d.issues.forEach((i) => {
    i.createdAt = refreshTimestamp(i.createdAt);
    i.updatedAt = refreshTimestamp(i.updatedAt);
    i.mutedAt = refreshTimestamp(i.mutedAt);
  });
  return d;
};

export const getProductsPgOld = async (
  spaceId: string
): Promise<IPostgresProduct[]> => {
  return [];
  // const spaceCache = (PG_PRODUCTS_CACHE[spaceId] =
  //   PG_PRODUCTS_CACHE[spaceId] || {});
  // return (spaceCache['ALL'] =
  //   spaceCache['ALL'] ||
  //   callFirebaseFunction<{ time: number; ds: IPostgresProduct[] }>(
  //     'products-getProductsPg',
  //     {
  //       spaceId
  //     }
  //   ).then((r) => {
  //     console.log('getProductsPg', r);
  //     r.ds.forEach((d) => {
  //       d.created_at = refreshTimestamp(d.created_at);
  //       d.issues.forEach((i) => {
  //         i.createdAt = refreshTimestamp(i.createdAt);
  //         i.updatedAt = refreshTimestamp(i.updatedAt);
  //         i.mutedAt = refreshTimestamp(i.mutedAt);
  //       });
  //     });
  //     return r.ds;
  //   }));
};

// UNCACHED
// TODO - this should pump items in the cache too!
export const getProductsPg = async (
  spaceId: string,
  q?: Omit<PostgresProductQuery, 'returnIdsOnly'>
) => {
  return callFirebaseFunction<{ time: number; ds: IPostgresProduct[] }>(
    'products-getProductsPg',
    {
      spaceId,
      q
    }
  ).then((r) => {
    r.ds.forEach(refreshPostgresProduct);
    return r.ds;
  });
};

export const getProductIdsPg = async (
  spaceId: string,
  q: Omit<PostgresProductQuery, 'returnIdsOnly'>
) => {
  const cache = getPgProductsCacheForSpace(spaceId).productIds;
  const checksum = toChecksum(q);
  return (cache[checksum] =
    cache[checksum] ||
    callFirebaseFunction<{ time: number; ds: string[] }>(
      'products-getProductsPg',
      {
        spaceId,
        q: {
          ...q,
          returnIdsOnly: true
        }
      }
    ).then((r) => {
      console.log('products-getProductIdsPg', r);
      return r.ds;
    }));
};

export const getProductsByIdPg = (spaceId: string, productIds: string[]) => {
  const spaceCache = getPgProductsCacheForSpace(spaceId).products;
  const uncached = productIds.filter((id) => !spaceCache[id]);
  if (uncached.length) {
    const req = getProductsPg(spaceId, { id: uncached }).then((ds) =>
      keyBy(ds, (d) => d.id)
    );
    uncached.forEach((id) => {
      spaceCache[id] = req.then((byId) => byId[id] || null);
    });
  }
  return Promise.all(productIds.map((id) => spaceCache[id])).then(compact);
};

export const getProductByIdPg = (
  spaceId: string,
  productId: string
): Promise<IPostgresProduct | null> => {
  return getProductsByIdPg(spaceId, [productId]).then((ps) => ps[0] || null);
};

export const useProductsPg = (spaceId: string) => {
  return usePromise(() => getProductsPgOld(spaceId), [spaceId]);
};

export const getPartnersWithProductCounts = (
  spaceId: string
): Promise<
  {
    partner_key: string;
    count: number;
  }[]
> => {
  return callFirebaseFunction<{
    time: number;
    ds: { partner_key: string; count: number }[];
  }>('products-countDistinctValuesPg', {
    spaceId,
    groupingFields: ['partner_key']
  }).then((r) => {
    console.log('getPartnersWithProductCounts', r);
    return r.ds;
  });
};

const PAGE_INDEX_CACHE: {
  [key: string]: Promise<{ [productId: string]: string[] }>;
} = {};
export const getPageIndexInTimeframe = async (
  spaceId: string,
  tf: Timeframe
) => {
  const key = `${spaceId}-${tf.start}-${tf.end}-${tf.tz}`;
  return (PAGE_INDEX_CACHE[key] =
    PAGE_INDEX_CACHE[key] ||
    callFirebaseFunction('products-getPageIndexInTimeframe', {
      spaceId,
      tf
    }));
};

const PARTNER_KEYS_WTH_EXTRACTABLE_DEEPLINKS = [
  'cj',
  'skimlinks',
  'shareasale',
  'rakuten',
  'pepperjam',
  'awin',
  'partnerize',
  'impact'
];

export const extractDeepLink = ({
  name,
  url,
  partnerKey
}: {
  name: string;
  url: string;
  partnerKey: string;
}) => {
  try {
    const urlObj = new URL(url);

    if (['cj', 'skimlinks', 'pepperjam'].includes(partnerKey)) {
      return urlObj.searchParams.get('url');
    }

    if (['shareasale'].includes(partnerKey)) {
      return urlObj.searchParams.get('urllink');
    }

    if (['rakuten'].includes(partnerKey)) {
      return urlObj.searchParams.get('murl');
    }

    if (['awin'].includes(partnerKey)) {
      return urlObj.searchParams.get('ued');
    }

    if (['impact'].includes(partnerKey)) {
      return urlObj.searchParams.get('u');
    }

    if (partnerKey === 'partnerize') {
      const [, matches] = new RegExp(/\/destination:(.+)/).exec(url) || [];
      if (matches) {
        return decodeURIComponent(matches);
      }
    }
  } catch (err) {
    return null;
  }
};

export const renderLinkName = ({
  name,
  url,
  partnerKey
}: {
  name: string;
  url: string;
  partnerKey: string;
}) => {
  const nameOrUrl = name || url;

  if (name !== url) {
    return name || url;
  }

  try {
    const urlObj = new URL(url);
    if (urlObj.hostname === 'affiliate.insider.com') {
      return urlObj.searchParams.get('u') || nameOrUrl;
    }
  } catch (err) {
    // continue
  }

  if (PARTNER_KEYS_WTH_EXTRACTABLE_DEEPLINKS.includes(partnerKey)) {
    return extractDeepLink({ name, url, partnerKey }) || nameOrUrl;
  }

  try {
    const urlObj = new URL(url);

    if (partnerKey === 'amazon' && urlObj.searchParams.has('tag')) {
      const [matches] = new RegExp(/\/(.+)\/dp\//).exec(urlObj.pathname) || [];
      if (matches) {
        return startCase(matches);
      }
    }
    return nameOrUrl;
  } catch (e) {
    return nameOrUrl;
  }
};
