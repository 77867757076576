import assertNever from 'assert-never';
import { capitalize, compact, keyBy, partition, truncate } from 'lodash';
import { useMemo } from 'react';
import { pluralize } from '../pluralize';
import { getAdvertiserColor } from '../../components/AdvertiserWithColor';
import {
  Data as EarningsBarChartData,
  limitAndSort
} from '../../components/Charts/EarningsChart';
import { COUNTRY_ABBREVIATIONS } from '../../domainTypes/country';
import { CurrencyCode } from '../../domainTypes/currency';
import {
  EarningsArgs,
  EarningsArgsGroupedInTimeframeAsTimeseries,
  EarningsResp,
  EarningsRespGroupedInTimeframe,
  EarningsRespGroupedInTimeframeAsTimeseries,
  EarningsRespInTimeframe,
  EarningsRespInTimeframeAsTimeseries,
  EarningsRespTopPagesForOriginInTimeFrame,
  EMPTY_EARNINGS_MINIMAL_DAILY,
  IEarningMinimal,
  IEarningMinimalDaily,
  SalesFilterArgs,
  SALE_UI_CONFIG,
  toDailyEarningFromMinimal,
  IDailyEarning,
  EMPTY_EARNING,
  addOneEarningToAnother,
  PAYOUT_UI_CONFIG,
  EARNING_MINIMAL_FIELD_SET,
  subtractOneEarningFromAnother,
  PayoutStatus
} from '../../domainTypes/performance';
import { mapValuesAsync } from '../../helpers';
import { usePromise } from '../../hooks/usePromise';
import { getCountryColor } from '../analytics/country';
import { toChecksum } from '../checksum';
import { CURRENCY_CONVERTER } from '../currency/converter';
import { useCurrentUser } from '../currentUser';
import { LoadingValue, useMappedLoadingValue } from '../db';
import { callFirebaseFunction } from '../firebaseFunctions';
import { getTimeKeyRangeFromTimeframe, msToTimeframe } from '../time';
import { getSalesCacheforSpace, useSalesCacheContext } from './cache';
import { COLOR_UNKNOWN, getStableRandomColor } from '../color';
import {
  AdvertiserProductGrouper,
  EarningsPerProductSoldForAdvertisersRow,
  productGrouperToEnglish,
  productNameFieldToEnglish
} from '../../features/PerformanceNew/services/advertisers';
import { UNKNOWN } from '../../domainTypes/analytics';

const convertEarningMinimal = async <T extends IEarningMinimal>(
  e: T,
  cur: CurrencyCode
): Promise<T> => {
  const conv = (x: number) =>
    x === 0
      ? 0
      : CURRENCY_CONVERTER.convert(x, e.cur, cur).then((t) => Math.round(t));

  return {
    ...e,
    cf: await conv(e.cf),
    cp: await conv(e.cp),
    cc: await conv(e.cc),
    crf: await conv(e.crf),
    crj: await conv(e.crj),
    cnc: await conv(e.cnc),
    cu: await conv(e.cu),
    cur
  };
};

const convertTopPagesForOriginInTimeframe = async (
  d: EarningsRespTopPagesForOriginInTimeFrame,
  cur: CurrencyCode
): Promise<EarningsRespTopPagesForOriginInTimeFrame> => {
  if (cur === d.q.currency) {
    return d;
  }
  return {
    ...d,
    d: await mapValuesAsync(d.d, async ({ curr, prev }) => ({
      curr: await convertEarningMinimal(curr, cur),
      prev: prev ? await convertEarningMinimal(prev, cur) : null
    }))
  };
};

const convertInTimeframe = async (
  d: EarningsRespInTimeframe,
  cur: CurrencyCode
): Promise<EarningsRespInTimeframe> => {
  if (cur === d.q.currency) {
    return d;
  }
  return {
    ...d,
    d: await convertEarningMinimal(d.d, cur)
  };
};

const padTimeseries = (
  tkRange: string[],
  series: IEarningMinimalDaily[],
  cur: CurrencyCode
) => {
  if (series.length === tkRange.length) {
    return series;
  }
  const dict = keyBy(series, (t) => t.tk);
  return tkRange.map((tk) => dict[tk] || EMPTY_EARNINGS_MINIMAL_DAILY(cur, tk));
};

// convert and make ranges continuous
const finalizeInTimeframeAsTimeseries = async (
  d: EarningsRespInTimeframeAsTimeseries,
  cur: CurrencyCode
): Promise<EarningsRespInTimeframeAsTimeseries> => {
  const tkRange = getTimeKeyRangeFromTimeframe(msToTimeframe(d.q.dates));
  const series =
    cur === d.q.currency
      ? d.d
      : await Promise.all(d.d.map((x) => convertEarningMinimal(x, cur)));
  return {
    ...d,
    d: padTimeseries(tkRange, series, cur)
  };
};

const convertGroupedInTimeframe = async (
  d: EarningsRespGroupedInTimeframe,
  cur: CurrencyCode
): Promise<EarningsRespGroupedInTimeframe> => {
  if (cur === d.q.currency) {
    return d;
  }
  return {
    ...d,
    d: await Promise.all(
      d.d.map(async (x) => ({
        ...x,
        d: await convertEarningMinimal(x.d, cur)
      }))
    )
  };
};

// convert and make ranges continuous
const finalizeGroupedInTimeframeAsTimeseries = async (
  d: EarningsRespGroupedInTimeframeAsTimeseries,
  cur: CurrencyCode
): Promise<EarningsRespGroupedInTimeframeAsTimeseries> => {
  const tkRange = getTimeKeyRangeFromTimeframe(msToTimeframe(d.q.dates));
  return {
    ...d,
    d: await Promise.all(
      d.d.map(async (x) => {
        const series =
          cur === d.q.currency
            ? x.ds
            : await Promise.all(x.ds.map((y) => convertEarningMinimal(y, cur)));
        return {
          ...x,
          ds: padTimeseries(tkRange, series, cur)
        };
      })
    )
  };
};

const USE_REPORTING_CURRENCY = true;

const _getEarnings = <T extends EarningsResp[]>(
  spaceId: string,
  queries: EarningsArgs[]
) => {
  return callFirebaseFunction<T>('sales-getEarnings', {
    spaceId,
    qs: USE_REPORTING_CURRENCY
      ? queries
      : queries.map((q) => ({
          ...q,
          d: {
            ...q.d,
            currency: 'USD'
          }
        }))
  });
};

export const getEarnings = async <T extends EarningsResp[]>(
  spaceId: string,
  queries: EarningsArgs[]
): Promise<T> => {
  const cache = getSalesCacheforSpace(spaceId).earnings;
  const withChecksums = queries.map((q) => ({
    checksum: toChecksum({ spaceId, q }),
    q
  }));
  const uncached = withChecksums.filter((x) => !cache[x.checksum]);
  if (uncached.length) {
    // console.log(
    //   'UNCACHED',
    //   uncached.map((x) => x.checksum),
    //   cache,
    //   spaceId
    // );
    const promise = _getEarnings<T>(
      spaceId,
      uncached.map((x) => x.q)
    );
    uncached.forEach((x, i) => {
      cache[x.checksum] = promise.then((r) => r[i]);
    });
  }
  return ((await Promise.all(
    withChecksums.map(async (x) => await cache[x.checksum])
  )) as unknown) as T;
};

export const useEarnings = <T extends EarningsResp[]>(
  spaceId: string,
  queries: EarningsArgs[],
  currency: CurrencyCode
) => {
  const { version } = useSalesCacheContext();
  return usePromise<{
    time: number;
    res: T;
  }>(() => {
    const n = Date.now();
    return getEarnings<T>(spaceId, queries).then(async (res) => {
      return {
        time: Date.now() - n,
        res: (await Promise.all(
          res.map<Promise<EarningsResp>>(async (r) => {
            switch (r.type) {
              case 'topPagesForOriginInTimeframe':
                return convertTopPagesForOriginInTimeframe(r, currency);
              case 'inTimeframe':
                return convertInTimeframe(r, currency);
              case 'inTimeframeAsTimeseries':
                return finalizeInTimeframeAsTimeseries(r, currency);
              case 'groupedInTimeframe':
                return convertGroupedInTimeframe(r, currency);
              case 'groupedInTimeframeAsTimeseries':
                return finalizeGroupedInTimeframeAsTimeseries(r, currency);
              default:
                return assertNever(r);
            }
          })
        )) as any // too tough to type - giving up.
      };
    });
  }, [spaceId, queries, currency, version]);
};

export const useEarningsSingle = <T extends EarningsResp>(
  spaceId: string,
  query: EarningsArgs,
  currency: CurrencyCode
) => {
  const queries = useMemo(() => [query], [query]);
  return useMappedLoadingValue(
    useEarnings<[T]>(spaceId, queries, currency),
    ({ time, res }) => ({ time, res: res[0] })
  );
};

export const usePayoutStatusTimeSeriesData = (
  spaceId: string,
  q: SalesFilterArgs,
  currency: CurrencyCode
): LoadingValue<{
  dates: { start: number; end: number; tz: string };
  data: EarningsBarChartData[];
}> => {
  const query: EarningsArgsGroupedInTimeframeAsTimeseries = useMemo(() => {
    return {
      type: 'groupedInTimeframeAsTimeseries',
      d: {
        groupBy: ['payout_status'],
        ...q,
        currency
      }
    };
  }, [q, currency]);
  return useMappedLoadingValue(
    useEarningsSingle<EarningsRespGroupedInTimeframeAsTimeseries>(
      spaceId,
      query,
      currency
    ),
    (r) => {
      console.log('payout status timeseries', r);
      return {
        dates: r.res.q.dates,
        data: limitAndSort(
          compact(
            r.res.d.map<EarningsBarChartData | null>((x) => {
              const payoutStatus: PayoutStatus = x.group['payout_status'];

              const data = payoutStatus
                ? PAYOUT_UI_CONFIG[payoutStatus] || PAYOUT_UI_CONFIG.Unknown
                : PAYOUT_UI_CONFIG.Unknown;

              return {
                container: {
                  key: payoutStatus,
                  pk: payoutStatus,
                  color: data.color,
                  label: capitalize(payoutStatus as string)
                },
                earnings: x.ds.map(toDailyEarningFromMinimal)
              };
            })
          ),
          15
        )
      };
    }
  );
};

export const useSaleStatusTimeSeriesData = (
  spaceId: string,
  q: SalesFilterArgs,
  currency: CurrencyCode
): LoadingValue<{
  dates: { start: number; end: number; tz: string };
  data: EarningsBarChartData[];
}> => {
  const query: EarningsArgsGroupedInTimeframeAsTimeseries = useMemo(() => {
    return {
      type: 'groupedInTimeframeAsTimeseries',
      d: {
        groupBy: ['sale_status'],
        ...q,
        currency
      }
    };
  }, [q, currency]);
  return useMappedLoadingValue(
    useEarningsSingle<EarningsRespGroupedInTimeframeAsTimeseries>(
      spaceId,
      query,
      currency
    ),
    (r) => {
      console.log('sale status timeseries', r);
      return {
        dates: r.res.q.dates,
        data: limitAndSort(
          compact(
            r.res.d.map<EarningsBarChartData | null>((x) => {
              const saleStatus: string = capitalize(x.group['sale_status']);
              const data = SALE_UI_CONFIG[saleStatus] || SALE_UI_CONFIG.Unknown;
              return {
                container: {
                  key: saleStatus,
                  pk: saleStatus,
                  color: data.color,
                  label: saleStatus
                },
                earnings: x.ds.map(toDailyEarningFromMinimal)
              };
            })
          ),
          15
        )
      };
    }
  );
};

export const useDeviceTimeSeriesData = (
  spaceId: string,
  q: SalesFilterArgs,
  currency: CurrencyCode
): LoadingValue<{
  dates: { start: number; end: number; tz: string };
  data: EarningsBarChartData[];
}> => {
  const query: EarningsArgsGroupedInTimeframeAsTimeseries = useMemo(() => {
    return {
      type: 'groupedInTimeframeAsTimeseries',
      d: {
        groupBy: ['device'],
        ...q,
        currency
      }
    };
  }, [q, currency]);
  return useMappedLoadingValue(
    useEarningsSingle<EarningsRespGroupedInTimeframeAsTimeseries>(
      spaceId,
      query,
      currency
    ),
    (r) => {
      console.log('device timeseries', r);
      return {
        dates: r.res.q.dates,
        data: limitAndSort(
          compact(
            r.res.d.map<EarningsBarChartData | null>((x) => {
              const deviceType: string = capitalize(x.group['device']);
              return {
                container: {
                  key: deviceType,
                  pk: deviceType,
                  color: getAdvertiserColor(deviceType, ''),
                  label: deviceType
                },
                earnings: x.ds.map(toDailyEarningFromMinimal)
              };
            })
          ),
          15
        )
      };
    }
  );
};

export const useCountryTimesSeriesData = (
  spaceId: string,
  q: SalesFilterArgs,
  currency: CurrencyCode
): LoadingValue<{
  dates: { start: number; end: number; tz: string };
  data: EarningsBarChartData[];
}> => {
  const query: EarningsArgsGroupedInTimeframeAsTimeseries = useMemo(() => {
    return {
      type: 'groupedInTimeframeAsTimeseries',
      d: {
        groupBy: ['click_country'],
        ...q,
        currency
      }
    };
  }, [q, currency]);
  return useMappedLoadingValue(
    useEarningsSingle<EarningsRespGroupedInTimeframeAsTimeseries>(
      spaceId,
      query,
      currency
    ),
    (r) => {
      console.log('country timeseries', r);
      return {
        dates: r.res.q.dates,
        data: limitAndSort(
          compact(
            r.res.d.map<EarningsBarChartData | null>((x) => {
              const countryCode: string = x.group['click_country'];
              const countryName =
                COUNTRY_ABBREVIATIONS[countryCode] || countryCode;
              const label =
                countryName !== 'Unknown'
                  ? `${countryCode} - ${countryName}`
                  : 'Not available';

              return {
                container: {
                  key: countryCode,
                  pk: countryCode,
                  color: getCountryColor(countryCode),
                  label
                },
                earnings: x.ds.map(toDailyEarningFromMinimal)
              };
            })
          ),
          15
        )
      };
    }
  );
};

export const useOriginTimeseriesData = (
  spaceId: string,
  q: SalesFilterArgs,
  currency: CurrencyCode
): LoadingValue<{
  dates: { start: number; end: number; tz: string };
  data: EarningsBarChartData[];
}> => {
  const { space } = useCurrentUser();
  const activeSites = space.domains
    .filter((d) => d.active)
    .map((d) => new URL(d.url).hostname);
  const youtubeDomains = ['www.youtube.com', 'youtube.com', 'm.youtube.com'];

  const query: EarningsArgsGroupedInTimeframeAsTimeseries = useMemo(() => {
    return {
      type: 'groupedInTimeframeAsTimeseries',
      d: {
        groupBy: ['origin'],
        ...q,
        currency
      }
    };
  }, [q, currency]);
  return useMappedLoadingValue(
    useEarningsSingle<EarningsRespGroupedInTimeframeAsTimeseries>(
      spaceId,
      query,
      currency
    ),
    (r) => {
      console.log('origin timeseries', r);

      const [onsiteEarnings, offsiteEarnings] = partition(r.res.d, (x) => {
        return (
          [...activeSites, ...youtubeDomains].indexOf(x.group['origin']) !== -1
        );
      });

      const data = limitAndSort(
        compact(
          onsiteEarnings.map<EarningsBarChartData | null>((x) => {
            const originName: string = x.group['origin'];
            return {
              container: {
                key: originName,
                pk: originName,
                color: getAdvertiserColor(originName, ''),
                label: originName
              },
              earnings: x.ds.map(toDailyEarningFromMinimal)
            };
          })
        ),
        15
      );

      const otherEarnings = offsiteEarnings.reduce<{
        [timeKey: string]: IDailyEarning;
      }>((m, s) => {
        s.ds.forEach((xs) => {
          const e = toDailyEarningFromMinimal(xs);
          const x: IDailyEarning = (m[e.timeKey] = m[e.timeKey] || {
            timeKey: e.timeKey,
            ...EMPTY_EARNING(e.currency)
          });
          addOneEarningToAnother(x, e);
          return m;
        });
        return m;
      }, {});

      const otherData = data.find((d) => d.container.key === 'OTHER');
      const result: EarningsBarChartData[] = otherData
        ? data.map((d) => {
            if (d.container.key === 'OTHER') {
              return {
                ...d,
                earnings: d.earnings.map((e) => {
                  const x: IDailyEarning = (d.earnings[e.timeKey as any] = d
                    .earnings[e.timeKey as any] || {
                    timeKey: e.timeKey,
                    ...EMPTY_EARNING(e.currency)
                  });
                  return addOneEarningToAnother(x, otherEarnings[e.timeKey]);
                })
              };
            }
            return d;
          })
        : data.concat({
            container: {
              key: 'OTHER',
              label: 'Other',
              color: COLOR_UNKNOWN
            },
            earnings: Object.values(otherEarnings)
          });

      return {
        dates: r.res.q.dates,
        data: result
      };
    }
  );
};

// This requires an explicit list of groupable fields,
// so it doesn't try to group by a field with enormous
// cardinality, and return all possible values
export const useProductTimeseriesData = (
  spaceId: string,
  q: SalesFilterArgs,
  currency: CurrencyCode,
  groupBy: AdvertiserProductGrouper,
  perProductSold: void | EarningsPerProductSoldForAdvertisersRow[]
): LoadingValue<{
  dates: { start: number; end: number; tz: string };
  data: EarningsBarChartData[];
}> => {
  const queries: EarningsArgsGroupedInTimeframeAsTimeseries[] = useMemo(() => {
    // One query just grouped by advertiser, all in "Other"
    // And one query both grouped and filtered by product

    const allQueries: EarningsArgsGroupedInTimeframeAsTimeseries[] = [];

    const query1: EarningsArgsGroupedInTimeframeAsTimeseries = {
      type: 'groupedInTimeframeAsTimeseries',
      d: {
        groupBy: ['advertiser_name'],
        fields: EARNING_MINIMAL_FIELD_SET.COMMISSIONS_AND_SALES_VOLUME,
        ...q,
        currency
      }
    };
    allQueries.push(query1);

    const filterValues = perProductSold
      ? perProductSold.map((p) => p.name)
      : null;

    if (filterValues) {
      const query2: EarningsArgsGroupedInTimeframeAsTimeseries = {
        type: 'groupedInTimeframeAsTimeseries',
        d: {
          fields: EARNING_MINIMAL_FIELD_SET.COMMISSIONS_AND_SALES_VOLUME,
          groupBy: [groupBy],
          ...q,
          currency,
          [groupBy]: filterValues
        }
      };
      allQueries.push(query2);
    }

    return allQueries;
  }, [q, currency, groupBy, perProductSold]); // eslint-disable-line react-hooks/exhaustive-deps
  return useMappedLoadingValue(
    useEarnings<EarningsRespGroupedInTimeframeAsTimeseries[]>(
      spaceId,
      queries,
      currency
    ),
    (r) => {
      console.log('product timeseries', r);
      const [allEarnings, perProductEarnings] = r.res;

      let allEarningsBarChart: EarningsBarChartData[] = allEarnings.d.map<
        EarningsBarChartData
      >((x) => {
        const grouper: string = x.group[groupBy] || UNKNOWN;
        const OTHER_LABEL = `Other ${pluralize(
          productGrouperToEnglish(groupBy).toLowerCase()
        )}`;

        return {
          container: {
            key: grouper,
            pk: q.partner_key!,
            color:
              grouper === UNKNOWN
                ? COLOR_UNKNOWN
                : getStableRandomColor(grouper),
            label:
              grouper === UNKNOWN
                ? OTHER_LABEL
                : truncate(grouper, { length: 40 })
          },
          earnings: x.ds.map((earning) => {
            const e = toDailyEarningFromMinimal(earning);
            return e;
          })
        };
      });

      if (perProductEarnings) {
        const perProductBarChartData = perProductEarnings.d.map<
          EarningsBarChartData
        >((x) => {
          const grouper: string = x.group[groupBy] || UNKNOWN;
          return {
            container: {
              key: grouper,
              pk: q.partner_key!,
              color:
                grouper === UNKNOWN
                  ? COLOR_UNKNOWN
                  : getStableRandomColor(grouper),
              label:
                grouper === UNKNOWN
                  ? 'Other'
                  : truncate(productNameFieldToEnglish(grouper, groupBy), {
                      length: 40
                    })
            },
            earnings: x.ds.map((earning) => {
              const e = toDailyEarningFromMinimal(earning);
              return e;
            })
          };
        });
        // Get the total earnings per day from all the per product
        // earnings, and subtract them from the total earnings
        // to get the "other" earnings
        const otherEarnings = perProductBarChartData.reduce<{
          [timeKey: string]: IDailyEarning;
        }>((m, s) => {
          s.earnings.forEach((e) => {
            const x: IDailyEarning = (m[e.timeKey] = m[e.timeKey] || {
              timeKey: e.timeKey,
              ...EMPTY_EARNING(e.currency)
            });
            addOneEarningToAnother(x, e);
            return m;
          });
          return m;
        }, {});

        allEarningsBarChart = allEarningsBarChart.map((x) => {
          if (x.container.key === UNKNOWN) {
            return {
              ...x,
              earnings: x.earnings.map((e) => {
                const de: IDailyEarning = e || {
                  ...EMPTY_EARNING(currency)
                };
                return subtractOneEarningFromAnother(
                  de,
                  otherEarnings[e.timeKey] || EMPTY_EARNING(currency)
                );
              })
            };
          }
          return x;
        });

        allEarningsBarChart = allEarningsBarChart.concat(
          perProductBarChartData
        );
      }

      return {
        dates: allEarnings.q.dates,
        data: compact(allEarningsBarChart)
      };
    }
  );
};

export const useAdvertiserTimeseriesData = (
  spaceId: string,
  q: SalesFilterArgs,
  currency: CurrencyCode,
  search?: string
): LoadingValue<{
  dates: { start: number; end: number; tz: string };
  data: EarningsBarChartData[];
}> => {
  const query: EarningsArgsGroupedInTimeframeAsTimeseries = useMemo(() => {
    return {
      type: 'groupedInTimeframeAsTimeseries',
      d: {
        groupBy: ['advertiser_name', 'partner_key'],
        fields: EARNING_MINIMAL_FIELD_SET.COMMISSIONS_VOLUME_AND_TX_COUNT,
        ...q,
        currency
      }
    };

    /*
     * Eventually the search should be performed on the server, but we would
     * need to define that only the advertiser name should be searched, and
     * right now the search applies to all columns, which leads to unexpected
     * views in the frontend
     */
  }, [q, currency, search]); // eslint-disable-line react-hooks/exhaustive-deps
  return useMappedLoadingValue(
    useEarningsSingle<EarningsRespGroupedInTimeframeAsTimeseries>(
      spaceId,
      query,
      currency
    ),
    (r) => {
      console.log('advertiser timeseries', r);
      return {
        dates: r.res.q.dates,
        data: limitAndSort(
          compact(
            r.res.d.map<EarningsBarChartData | null>((x) => {
              const advertiserName: string = x.group['advertiser_name'];
              const partnerKey: string = x.group['partner_key'];

              return {
                container: {
                  key: `${advertiserName}-${partnerKey}`,
                  pk: partnerKey,
                  color: getAdvertiserColor(advertiserName, partnerKey),
                  label: advertiserName
                },
                earnings: x.ds.map(toDailyEarningFromMinimal)
              };
            })
          ).filter((d) => {
            return search
              ? d.container.key.toLocaleLowerCase().includes(search)
              : true;
          }),
          15
        )
      };
    }
  );
};
