import { compact, flatten, keyBy, mapValues, startCase, uniq } from 'lodash';
import { useMemo } from 'react';
import { Timeframe, UNKNOWN } from '../../../domainTypes/analytics';
import { COUNTRY_ABBREVIATIONS } from '../../../domainTypes/country';
import { CurrencyCode } from '../../../domainTypes/currency';
import {
  Advertiser,
  AdvertiserWithData,
  EarningsArgsGroupedInTimeframe,
  EarningsRespGroupedInTimeframe,
  EMPTY_EARNING,
  IEarning,
  IEarningMinimal,
  ISaleMetadataPath,
  SALE_TYPES_WITHOUT_BONUS,
  SalesFilterArgs,
  toEarningFromMinimal
} from '../../../domainTypes/performance';
import { IPostgresProduct } from '../../../domainTypes/product';
import { usePromise } from '../../../hooks/usePromise';
import { toComparableTimeframe } from '../../../services/analytics';
import { useMappedLoadingValue } from '../../../services/db';
import { callFirebaseFunction } from '../../../services/firebaseFunctions';
import { getProductsByIdPg } from '../../../services/products';
import { getEarnings, useEarnings } from '../../../services/sales/earnings';
import { msToTimeframe, timeframeToMs } from '../../../services/time';
import { CF } from '../../../versions';

export type AdvertiserProductGrouper =
  | 'partner_product_id'
  | 'partner_product_name'
  | ISaleMetadataPath;

export const productNameFieldToEnglish = (
  field: string,
  grouper: AdvertiserProductGrouper
) => {
  if (field.startsWith('http')) {
    return field.substr(field.lastIndexOf('/'), field.length);
  }
  if (grouper === 'metadata.destinationCountry') {
    return COUNTRY_ABBREVIATIONS[field] || field;
  }

  if (grouper === 'metadata.customerCountry') {
    return COUNTRY_ABBREVIATIONS[field] || field;
  }

  return field;
};

export const earningsMinimalFieldtoEnglish = (
  field: keyof IEarningMinimal
): string => {
  switch (field) {
    case 'ct':
      return 'Earnings';
    case 'oct':
      return 'Order count';
    case 'qt':
      return 'Quantity';
    case 'ac':
      return 'Average commission';
    case 'pt':
      return 'Sales volume';
    default:
      return field;
  }
};

export const productGrouperToEnglish = (grouper: AdvertiserProductGrouper) => {
  if (grouper === 'partner_product_name') {
    return 'Product name';
  }
  if (grouper === 'partner_product_id') {
    return 'Product ID';
  }

  return startCase(grouper.replace('metadata.', ''));
};

export const getDataFromAdvertisers = async ({
  spaceId,
  timeframe,
  advertisers
}: {
  spaceId: string;
  timeframe: Timeframe;
  advertisers: Advertiser[];
}) => {
  return callFirebaseFunction<{ advertisers: AdvertiserWithData[] }>(
    CF.reporting.getDataFromAdvertisers,
    {
      spaceId,
      timeframe,
      advertisers
    }
  ).then((r) => r.advertisers);
};

export type EarningsPerPageForAdvertisersRow = {
  pageUrl: string;
  curr: IEarning;
  prev: IEarning;
};

export const useEarningsPerPageForAdvertisers = (
  spaceId: string,
  partnerKeys: string[],
  advertiserNames: string[],
  timeframe: Timeframe,
  compare: boolean,
  currency: CurrencyCode,
  q: Omit<SalesFilterArgs, 'dates'>
) => {
  const queries: EarningsArgsGroupedInTimeframe[] = useMemo(() => {
    return compact([
      {
        type: 'groupedInTimeframe',
        d: {
          ...q,
          advertiser_name: advertiserNames,
          partner_key: partnerKeys,
          groupBy: ['page_url'],
          dateColumn: 'sale_date',
          dates: { ...timeframeToMs(timeframe), column: 'sale_date' },
          sale_type: SALE_TYPES_WITHOUT_BONUS,
          currency
        }
      },
      compare && {
        type: 'groupedInTimeframe',
        d: {
          ...q,
          advertiser_name: advertiserNames,
          partner_key: partnerKeys,
          groupBy: ['page_url'],
          dateColumn: 'sale_date',
          dates: {
            ...timeframeToMs(toComparableTimeframe(timeframe)),
            column: 'sale_date'
          },
          sale_type: SALE_TYPES_WITHOUT_BONUS,
          currency
        }
      }
    ]);
  }, [timeframe, compare, currency, q, advertiserNames, partnerKeys]);

  return useMappedLoadingValue(
    useEarnings<EarningsRespGroupedInTimeframe[]>(spaceId, queries, currency),
    (r) => {
      // prev is empty if we are in non-compare mode
      console.log('useEarningsPerPageForAdvertisers', r.time, r);
      const [curr, prev = {}] = r.res.map((x) => {
        return mapValues(
          keyBy(x.d, (t) => {
            const pageUrl = (t.group['page_url'] as string) || UNKNOWN;
            return pageUrl;
          }),
          (v) => toEarningFromMinimal(v.d)
        );
      });
      const allPageUrls = uniq([...Object.keys(curr), ...Object.keys(prev)]);
      return allPageUrls.map<EarningsPerPageForAdvertisersRow>((pageUrl) => {
        return {
          pageUrl,
          curr: curr[pageUrl] || EMPTY_EARNING(currency),
          prev: prev[pageUrl] || EMPTY_EARNING(currency)
        };
      });
    }
  );
};

export type EarningsPerProductForAdvertisersRow = {
  product: IPostgresProduct;
  curr: IEarning;
  prev: IEarning;
};

// Add limit and orderBy here maybe?
export const useEarningsPerProductForAdvertisers = (
  spaceId: string,
  partnerKeys: string[],
  advertiserNames: string[],
  timeframe: Timeframe,
  compare: boolean,
  currency: CurrencyCode,
  q: Omit<SalesFilterArgs, 'dates'>
) => {
  const queries: EarningsArgsGroupedInTimeframe[] = useMemo(() => {
    return compact([
      {
        type: 'groupedInTimeframe',
        d: {
          ...q,
          advertiser_name: advertiserNames,
          partner_key: partnerKeys,
          groupBy: ['product_id'],
          dateColumn: 'sale_date',
          dates: { ...timeframeToMs(timeframe), column: 'sale_date' },
          sale_type: SALE_TYPES_WITHOUT_BONUS,
          currency
        }
      },
      compare && {
        type: 'groupedInTimeframe',
        d: {
          ...q,
          advertiser_name: advertiserNames,
          partner_key: partnerKeys,
          groupBy: ['product_id'],
          dateColumn: 'sale_date',
          dates: {
            ...timeframeToMs(toComparableTimeframe(timeframe)),
            column: 'sale_date'
          },
          sale_type: SALE_TYPES_WITHOUT_BONUS,
          currency
        }
      }
    ]);
  }, [timeframe, compare, currency, q, advertiserNames, partnerKeys]);

  const [value] = useMappedLoadingValue(
    useEarnings<EarningsRespGroupedInTimeframe[]>(spaceId, queries, currency),
    (r) => {
      // prev is empty if we are in non-compare mode
      console.log('useEarningsPerProductForAdvertisers', r.time, r);
      const [curr, prev = {}] = r.res.map((x) => {
        return mapValues(
          keyBy(x.d, (t) => {
            const productIds = (t.group['product_id'] as string) || UNKNOWN;
            return productIds;
          }),
          (v) => toEarningFromMinimal(v.d)
        );
      });
      const allProductIds = uniq([...Object.keys(curr), ...Object.keys(prev)]);
      return allProductIds.map<{
        productId: string;
        prev: IEarning;
        curr: IEarning;
      }>((productId) => {
        return {
          productId,
          curr: curr[productId] || EMPTY_EARNING(currency),
          prev: prev[productId] || EMPTY_EARNING(currency)
        };
      });
    }
  );

  return usePromise(async () => {
    if (!value) {
      return new Promise<EarningsPerProductForAdvertisersRow[]>(() => []);
    }
    // latest point to apply limit and sort on value here
    const byProductId = keyBy(value, (x) => x.productId);
    const products = await getProductsByIdPg(spaceId, Object.keys(byProductId));
    return products.map<EarningsPerProductForAdvertisersRow>((p) => {
      return {
        product: p,
        prev: byProductId[p.id].prev,
        curr: byProductId[p.id].curr
      };
    });
  }, [spaceId, value]);
};

export type EarningsPerProductSoldForAdvertisersRow = {
  name: string;
  curr: IEarning;
  prev: IEarning;
};

export const useEarningsPerProductSoldForAdvertisers = (
  spaceId: string,
  q: SalesFilterArgs,
  compare: boolean,
  currency: CurrencyCode,
  groupBy: AdvertiserProductGrouper
) => {
  return usePromise<EarningsPerProductSoldForAdvertisersRow[]>(async () => {
    const initialQuery: EarningsArgsGroupedInTimeframe = {
      type: 'groupedInTimeframe',
      d: {
        groupBy: [groupBy],
        ...q,
        currency
      }
    };
    const earningsCurr = await getEarnings<EarningsRespGroupedInTimeframe[]>(
      spaceId,
      [initialQuery]
    );

    const allProductNames = uniq(
      flatten(
        earningsCurr.map((x) => x.d.map((t) => t.group[groupBy] as string))
      )
    );

    const tf = msToTimeframe(q.dates);
    const secondQuery: EarningsArgsGroupedInTimeframe = {
      type: 'groupedInTimeframe',
      d: {
        ...q,
        dates: {
          ...timeframeToMs(toComparableTimeframe(tf)),
          column: 'sale_date'
        },
        partner_product_name: allProductNames,
        groupBy: [groupBy],
        sale_type: SALE_TYPES_WITHOUT_BONUS,
        currency
      }
    };

    const earningsPrev = await getEarnings<EarningsRespGroupedInTimeframe[]>(
      spaceId,
      [secondQuery]
    );

    const curr = mapValues(
      keyBy(flatten(earningsCurr.map((x) => x.d)), (t) => {
        return t.group[groupBy] as string;
      }),
      (v) => toEarningFromMinimal(v.d)
    );

    const prev = mapValues(
      keyBy(flatten(earningsPrev.map((x) => x.d)), (t) => {
        return t.group[groupBy] as string;
      }),
      (v) => toEarningFromMinimal(v.d)
    );

    const map = new Map(Object.entries(curr));
    const keys = [...map.keys()];
    const res = keys.map<EarningsPerProductSoldForAdvertisersRow>((name) => {
      return {
        name,
        curr: curr[name] || EMPTY_EARNING(currency),
        prev: prev[name] || EMPTY_EARNING(currency)
      };
    });

    return res;
  }, [spaceId, q, compare, currency, groupBy]);
};
