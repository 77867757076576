import React from 'react';
import {
  ITimeframeOption,
  TimeframePickerDense
} from '../../../../components/TimeframePicker';
import { Timeframe } from '../../../../domainTypes/analytics';
import { Doc } from '../../../../domainTypes/document';
import { IProduct } from '../../../../domainTypes/product';
import {
  DEFAULT_OFFSET,
  PageToolbar,
  PageToolbarSection,
  PageToolbarTitle
} from '../../../../layout/PageToolbar';
import { DetailsTitle } from '../DetailsTitle';

export const DetailsToolbar: React.FC<{
  product: void | Doc<IProduct> | null;
  timeframe: Timeframe;
  setTimeframe: (nextTf: Timeframe) => void;
  options: ITimeframeOption[];
}> = ({ product, timeframe, setTimeframe, options, children }) => {
  return (
    <PageToolbar sticky offset={DEFAULT_OFFSET}>
      <PageToolbarTitle flex={4}>
        {!!product && <DetailsTitle p={product} />}
        {!!product && children}
      </PageToolbarTitle>
      <PageToolbarSection flex={1} justifyContent="flex-end">
        <TimeframePickerDense
          value={timeframe}
          options={options}
          onChange={setTimeframe}
        />
      </PageToolbarSection>
    </PageToolbar>
  );
};
