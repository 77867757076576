import { IGeoData } from './geodata';
import { IProductMap } from './productMap';

export type EventPubSub = {
  event: EventWithType;
  params: ExtraParams;
};

export type IEventType =
  | 'click'
  | 'impressionViewed'
  | 'impressionServed'
  | 'redirect';

export type EventWithType =
  | ({
      type: 'click';
    } & IClickEventParams)
  | ({
      type: 'impressionViewed' | 'impressionServed';
    } & IImpressionEventParams)
  | ({
      type: 'redirect';
    } & IRedirectEventParams);

export type ExtraParams = {
  userAgent: string;
  apiToken: string;
  origin: string;
  geoData: IGeoData;
};

export interface IAutomaticTags {
  [groupName: string]: string[];
}
export type IDefaultDimensionsClick = {
  clickData01?: string[];
  clickData02?: string[];
  clickData03?: string[];
  clickData04?: string[];
  clickData05?: string[];
  clickData06?: string[];
  clickData07?: string[];
  clickData08?: string[];
  clickData09?: string[];
  clickData10?: string[];
};
export type IDefaultDimensions = {
  dateModified?: string;
  datePublished?: string;
  authors?: string[];
  tags?: IAutomaticTags;
  publisher?: string[];
  utmSource?: string;
  utmMedium?: string;
  utmCampaign?: string;
  utmContent?: string;
  utmTerm?: string;
  gclid?: string;
  data01?: string[];
  data02?: string[];
  data03?: string[];
  data04?: string[];
  data05?: string[];
  data06?: string[];
  data07?: string[];
  data08?: string[];
  data09?: string[];
  data10?: string[];
  data11?: string[];
  data12?: string[];
  data13?: string[];
  data14?: string[];
  data15?: string[];
} & IDefaultDimensionsClick;

// Old event structures that required Product IDs with all events

export interface IImpressionEventParams {
  href: string;
  pageViewId: string;
  sessionId?: string;
  referrer: string;
  referrerEntry: string;
  visitorId?: string;
  userAgent?: string;
  dateModified?: string;
  channelId: string | null;
  dimensions?: IDefaultDimensions;
  impressions: Partial<IEventParams>[];
}

export interface IClickEventParams {
  productId?: string;
  trackingId: string;
  href: string;
  occurrence: number;
  linkHref?: string;
  originalHref?: string;
  destinationUrl?: string;
  pageViewId: string;
  sessionId?: string;
  referrer: string;
  referrerEntry: string;
  visitorId?: string;
  userAgent?: string;
  dateModified?: string;
  partnerKey: string | null;
  channelId: string | null;
  dimensions?: IDefaultDimensions;
}

export interface IRedirectEventParams {
  trackingId: string;
  originalHref: string;
  linkHref: string;
  destinationUrl: string;
  partnerKey: string | null;
}

export interface IEventParams {
  productId?: string;
  trackingId: string;
  widgetId: string;
  pageViewId: string;
  spaceId: string;
  referrer: string;
  referrerEntry: string;
  href: string;
  linkHref: string;
  originalHref?: string;
  occurrence: number;
  createdAt?: number;
  sessionId?: string;
  visitorId?: string;
  userAgent?: string;
  dimensions?: IDefaultDimensions;
  dateModified?: string;
  channelId: string | null;
  partnerKey: string | null;
}

export type BoundingRect = {
  bottom: number;
  height: number;
  left: number;
  right: number;
  top: number;
  width: number;
};

export type ProductLinkPosition = {
  productId?: string;
  href: string;
  occurrence: number;
  ref: string;
  position: BoundingRect;
};

export interface IAffilimateTracker {
  getTrackedElements: () => Promise<ProductLinkPosition[]>;
}

export interface IAffilimateGlobal {
  initializedScripts: { [apiToken: string]: boolean };
  instances: { [apiToken: string]: Promise<IAffilimate> };
  getInstance: (apiToken: string) => Promise<IAffilimate>;
}

export interface IAffilimate extends IAffilimateTracker {}

export type Device = 'desktop' | 'mobile' | 'tablet' | 'unknown';

export const getDeviceSortValue = (device: Device) => {
  switch (device) {
    case 'desktop':
      return 0;
    case 'mobile':
      return 1;
    case 'tablet':
      return 2;
    default:
      return 3;
  }
};

export const getDeviceLabel = (device: Device) => {
  switch (device) {
    case 'desktop':
      return 'Desktop';
    case 'mobile':
      return 'Mobile';
    case 'tablet':
      return 'Tablet';
    default:
      return 'Unknown';
  }
};

export interface IAutoLabelConfigItem {
  key: string;
  // at least one identifier is required
  domain?: string;
  queryParam?: string;
  labelParam?: string;
  replace?: {
    match: string;
    template: string;
    fallbackMatch?: string;
    fallbackTemplate?: string;
  };
  maxLabelLength?: number;
  alphanumeric?: boolean;
  allLowerCase?: boolean;
}

export interface IAutoLabelConfig {
  spaceId: string;
  partners: string[];
  v: 2;
}

export interface ITrackingConfig {
  // The inactive field was introduced later. We're using inactive instead of active
  // to make the migration easier - by default tracking will still be active,
  // only when we explicitly set it to inactive, tracking will stop.
  inactive: boolean;
  scanlessTracking: boolean;
  productMap: IProductMap;
  smartLabels: string[]; // partnerKeys
  domains: {
    url: string;
    verified: boolean;
    urlFormat: 'url' | 'canonical';
    updateMethod: 'replace' | 'append';
    subidStrategy: 'network' | 'universal';
  }[];
  redirectUrls: { domain: string; urls: string[] }[];
  v: 3;
}

export type TrackingConfigProduct = [number, string] | [number, string, string];

export interface ITrackingConfigSmall {
  products: TrackingConfigProduct[];
  autoLabelling: IAutoLabelConfigItem[];
  scanlessTracking: boolean;
  redirectUrls: string[];
  urlFormat: 'url' | 'canonical';
  updateMethod: 'replace' | 'append';
  subidStrategy: 'network' | 'universal';
  isEu: boolean;
  selectors?: string[];
  tag?: string;
}

export const PARTNERS_WITH_TRACKING: {
  [partnerKey: string]: IAutoLabelConfigItem[];
} = {
  affilimate: [
    {
      key: 'affilimate',
      domain: 'affilimate.io',
      labelParam: 'tid'
    },
    {
      key: 'affilimate',
      domain: 'dev.affilimate.io',
      labelParam: 'tid'
    },
    {
      key: 'affilimate',
      domain: 'affilimate.com',
      labelParam: 'tid'
    }
  ],
  amazon: [
    {
      key: 'amazon',
      labelParam: 'ascsubtag',
      domain: 'www.amazon.co.uk',
      maxLabelLength: 100
    },
    {
      key: 'amazon',
      labelParam: 'ascsubtag',
      domain: 'www.amazon.de',
      maxLabelLength: 100
    },
    {
      key: 'amazon',
      labelParam: 'ascsubtag',
      domain: 'www.amazon.com',
      maxLabelLength: 100
    }
  ],
  adsbymoney: [
    {
      key: 'adsbymoney',
      domain: 'secure.money.com',
      labelParam: 's3',
      maxLabelLength: 500
    }
  ],
  affilae: [
    {
      key: 'affilae',
      maxLabelLength: 100,
      labelParam: 'aev'
    },
    {
      key: 'affilae',
      maxLabelLength: 100,
      labelParam: 'cv'
    }
  ],
  admitad: [
    {
      key: 'admitad',
      maxLabelLength: 50,
      domain: 'ad.admitad.com',
      labelParam: 'subid4'
    },
    {
      key: 'admitad',
      maxLabelLength: 50,
      domain: 'fas.st',
      labelParam: 'subid4'
    }
  ],
  affise: [
    {
      key: 'affise',
      labelParam: 'sub2'
    }
  ],
  affiliatly: [
    {
      key: 'affiliatly',
      labelParam: 'subid'
    }
  ],
  agoda: [
    {
      key: 'agoda',
      domain: 'www.agoda.com',
      labelParam: 'tag'
    }
  ],
  awin: [
    {
      key: 'awin',
      domain: 'www.awin1.com',
      labelParam: 'clickref',
      maxLabelLength: 50
    }
  ],
  avantlink: [
    {
      key: 'avantlink',
      domain: 'www.avantlink.com',
      labelParam: 'ctc',
      maxLabelLength: 64
    },
    {
      key: 'avantlink',
      domain: 'classic.avantlink.com',
      labelParam: 'ctc',
      maxLabelLength: 64
    }
  ],
  booking: [
    {
      key: 'booking',
      domain: 'www.booking.com',
      labelParam: 'label',
      allLowerCase: true
    }
  ],
  belboon: [
    {
      key: 'belboon',
      labelParam: 'smc',
      maxLabelLength: 100
    }
  ],
  cake: [
    {
      key: 'cake',
      labelParam: 's2'
    }
  ],
  'commission-factory': [
    {
      key: 'commission-factory',
      domain: 't.cfjump.com',
      labelParam: 'UniqueId'
    }
  ],
  civitatis: [
    {
      key: 'civitatis',
      domain: 'www.civitatis.com',
      labelParam: 'cmp',
      maxLabelLength: 32
    }
  ],
  digidip: [
    {
      key: 'digidip',
      labelParam: 'ref',
      maxLabelLength: 100
    }
  ],
  travelpayouts: [
    {
      key: 'travelpayouts',
      domain: 'tp.media',
      replace: {
        match: `marker=[0-9]+(\\.[A-Za-z0-9_\%\-]+)&`, // eslint-disable-line no-useless-escape
        template: `.TRACKING_LABEL`,
        fallbackMatch: `marker=[0-9]+`,
        fallbackTemplate: `MATCH.TRACKING_LABEL`
      },
      maxLabelLength: 128
    },
    {
      key: 'travelpayouts',
      domain: '.tp.st',
      labelParam: 'sub_id',
      maxLabelLength: 128
    }
  ],
  cj: [
    {
      key: 'cj',
      domain: 'www.anrdoezrs.net',
      // using a label param in addition to a replacement schema,
      // as some shapes of anrdoezrs links can take a query param
      labelParam: 'sid',
      replace: {
        match: `(\/dlg\/(sid\/.+?\/)?)`, // eslint-disable-line no-useless-escape
        template: '/dlg/sid/TRACKING_LABEL/'
      },
      maxLabelLength: 64
    },
    {
      key: 'cj',
      domain: 'www.tkqlhce.com',
      labelParam: 'sid',
      maxLabelLength: 64
    },
    {
      key: 'cj',
      domain: 'www.kqzyfj.com',
      labelParam: 'sid',
      maxLabelLength: 64
    },
    {
      key: 'cj',
      domain: 'www.dpbolvw.net',
      labelParam: 'sid',
      maxLabelLength: 64
    },
    {
      key: 'cj',
      domain: 'www.jdoqocy.com',
      labelParam: 'sid',
      maxLabelLength: 64
    }
  ],
  clickbank: [
    {
      key: 'clickbank',
      domain: 'hop.clickbank.net',
      labelParam: 'tid',
      maxLabelLength: 100
    }
  ],
  daisycon: [
    {
      key: 'daisycon',
      labelParam: 'ws',
      maxLabelLength: 100
    }
  ],
  ebay: [
    {
      key: 'ebay',
      domain: 'www.ebay.com',
      labelParam: 'customid',
      maxLabelLength: 50
    },
    {
      key: 'ebay',
      domain: 'www.ebay.co.uk',
      labelParam: 'customid',
      maxLabelLength: 50
    }
  ],
  financeads: [
    {
      key: 'financeads',
      domain: 'www.financeads.net',
      labelParam: 'subid'
    }
  ],
  idevaffiliate: [
    {
      key: 'idevaffiliate',
      labelParam: 'tid2',
      maxLabelLength: 25,
      alphanumeric: true,
      allLowerCase: true
    }
  ],
  everflow: [
    {
      key: 'everflow',
      labelParam: 'sub2',
      maxLabelLength: 600
    }
  ],
  effiliation: [
    {
      key: 'effiliation',
      domain: 'track.effiliation.com',
      labelParam: 'effi_id',
      maxLabelLength: 255
    }
  ],
  expressvpn: [
    {
      key: 'expressvpn',
      domain: 'xvinlink.com',
      labelParam: 'data2'
    },
    {
      key: 'expressvpn',
      domain: 'xvbelink.com',
      labelParam: 'data2'
    },
    {
      key: 'expressvpn',
      domain: 'xvmelink.com',
      labelParam: 'data2'
    },
    {
      key: 'expressvpn',
      domain: 'kzrzpzf.com',
      labelParam: 'data2'
    }
  ],
  secomapp: [
    {
      key: 'secomapp',
      labelParam: 'sca_source'
    }
  ],
  goaffpro: [
    {
      key: 'goaffpro',
      labelParam: 'sub_id'
    }
  ],
  gyg: [
    {
      key: 'gyg',
      domain: 'www.getyourguide.com',
      labelParam: 'cmp'
    },
    {
      key: 'gyg',
      domain: 'www.getyourguide.de',
      labelParam: 'cmp'
    },
    {
      key: 'gyg',
      domain: 'www.getyourguide.ch',
      labelParam: 'cmp'
    },
    {
      key: 'gyg',
      domain: 'www.getyourguide.fr',
      labelParam: 'cmp'
    },
    {
      key: 'gyg',
      domain: 'www.getyourguide.nl',
      labelParam: 'cmp'
    },
    {
      key: 'gyg',
      domain: 'www.getyourguide.no',
      labelParam: 'cmp'
    },
    {
      key: 'gyg',
      domain: 'www.getyourguide.pl',
      labelParam: 'cmp'
    },
    {
      key: 'gyg',
      domain: 'www.getyourguide.com.mx',
      labelParam: 'cmp'
    },
    {
      key: 'gyg',
      domain: 'www.getyourguide.fi',
      labelParam: 'cmp'
    },
    {
      key: 'gyg',
      domain: 'www.getyourguide.pt',
      labelParam: 'cmp'
    },
    {
      key: 'gyg',
      domain: 'www.getyourguide.com.br',
      labelParam: 'cmp'
    },
    {
      key: 'gyg',
      domain: 'www.getyourguide.se',
      labelParam: 'cmp'
    },
    {
      key: 'gyg',
      domain: 'www.getyourguide.ru',
      labelParam: 'cmp'
    },
    {
      key: 'gyg',
      domain: 'www.getyourguide.com.tw',
      labelParam: 'cmp'
    },
    {
      key: 'gyg',
      domain: 'www.getyourguide.co.kr',
      labelParam: 'cmp'
    },
    {
      key: 'gyg',
      domain: 'www.getyourguide.com.tr',
      labelParam: 'cmp'
    },
    {
      key: 'gyg',
      domain: 'www.getyourguide.co.jp',
      labelParam: 'cmp'
    },
    {
      key: 'gyg',
      domain: 'www.getyourguide.at',
      labelParam: 'cmp'
    },
    {
      key: 'gyg',
      domain: 'www.getyourguide.dk',
      labelParam: 'cmp'
    },
    {
      key: 'gyg',
      domain: 'www.getyourguide.co.uk',
      labelParam: 'cmp'
    },
    {
      key: 'gyg',
      domain: 'www.getyourguide.es',
      labelParam: 'cmp'
    },
    {
      key: 'gyg',
      domain: 'www.getyourguide.it',
      labelParam: 'cmp'
    }
  ],
  hotels_combined: [
    {
      key: 'hotels_combined',
      domain: 'www.hotelscombined.com',
      labelParam: 'label',
      maxLabelLength: 15
    }
  ],
  impact: [
    {
      key: 'impact',
      labelParam: 'subId3',
      maxLabelLength: 50
    }
  ],
  ingenioustech: [
    {
      key: 'ingenioustech',
      labelParam: 'smc',
      maxLabelLength: 100
    }
  ],
  linkconnector: [
    {
      key: 'linkconnector',
      domain: 'www.linkconnector.com',
      labelParam: 'atid',
      maxLabelLength: 100
    }
  ],
  firstpromoter: [
    {
      key: 'firstpromoter',
      labelParam: 'fp_sid'
    }
  ],
  flexoffers: [
    {
      key: 'flexoffers',
      domain: 'track.flexlinkspro.com',
      labelParam: 'fobs'
    },
    {
      key: 'flexoffers',
      domain: 'fxo.co',
      labelParam: 'fobs'
    }
  ],
  bol: [
    {
      key: 'bol',
      labelParam: 'subid',
      domain: 'partner.bol.com'
    },
    {
      key: 'bol',
      labelParam: 'subid',
      domain: 'partnerprogramma.bol.com'
    }
  ],
  rakuten: [
    {
      key: 'rakuten',
      domain: 'click.linksynergy.com',
      labelParam: 'u1',
      maxLabelLength: 72
    },
    {
      key: 'rakuten',
      domain: 'linksynergy.com',
      labelParam: 'u1',
      maxLabelLength: 72
    }
  ],
  odyssey: [
    {
      key: 'odyssey',
      labelParam: 'utm_content',
      allLowerCase: true
    }
  ],
  partnerstack: [
    {
      key: 'partnerstack',
      domain: 'grsm.io',
      labelParam: 'sid1'
    },
    {
      key: 'partnerstack',
      domain: 'try.thinkific.com',
      labelParam: 'sid1'
    },
    {
      key: 'partnerstack',
      domain: 'aff.trypipedrive.com',
      labelParam: 'sid1'
    },
    {
      key: 'partnerstack',
      domain: 'try.quillbot.com',
      labelParam: 'sid1'
    }
  ],
  pepperjam: [
    {
      key: 'pepperjam',
      domain: 'gopjn.com',
      labelParam: 'sid',
      maxLabelLength: 100
    },
    {
      key: 'pepperjam',
      domain: 'pjtra.com',
      labelParam: 'sid',
      maxLabelLength: 100
    },
    {
      key: 'pepperjam',
      domain: 'pntrac.com',
      labelParam: 'sid',
      maxLabelLength: 100
    },
    {
      key: 'pepperjam',
      domain: 'pntra.com',
      labelParam: 'sid',
      maxLabelLength: 100
    },
    {
      key: 'pepperjam',
      domain: 'pjatr.com',
      labelParam: 'sid',
      maxLabelLength: 100
    },
    {
      key: 'pepperjam',
      domain: 'pntrs.com',
      labelParam: 'sid',
      maxLabelLength: 100
    }
  ],
  maxbounty: [
    {
      key: 'maxbounty',
      labelParam: 's2',
      maxLabelLength: 100
    }
  ],
  mvf: [
    {
      key: 'mvf',
      labelParam: 'sub5',
      maxLabelLength: 100
    }
  ],
  pap: [
    {
      key: 'pap',
      labelParam: 'data2',
      maxLabelLength: 255
    }
  ],
  partnerize: [
    {
      key: 'partnerize',
      labelParam: 'pubref',
      domain: 'prf.hn',
      replace: {
        match: `\/pubref:[a-zA-Z\-_\%0-9]+\/?`, // eslint-disable-line no-useless-escape
        template: '/pubref:TRACKING_LABEL/',
        fallbackMatch: `\/camref:[a-zA-Z\-_\%0-9]+\/?`, // eslint-disable-line no-useless-escape
        fallbackTemplate: `MATCH/pubref:TRACKING_LABEL/`
      },
      maxLabelLength: 100
    },
    {
      key: 'partnerize',
      labelParam: 'pubref',
      domain: 'expedia.com',
      maxLabelLength: 100
    },
    {
      key: 'partnerize',
      labelParam: 'pubref',
      domain: 'vrbo.com',
      maxLabelLength: 100
    }
  ],
  refersion: [
    {
      key: 'refersion',
      queryParam: 'rfsn',
      labelParam: 'subid'
    }
  ],
  rentalcarscom: [
    {
      key: 'rentalcarscom',
      domain: 'rentalcars.com',
      labelParam: 'adcamp',
      maxLabelLength: 50
    }
  ],
  shareasale: [
    {
      key: 'shareasale',
      domain: 'shareasale.com',
      labelParam: 'afftrack',
      maxLabelLength: 255
    }
  ],
  skimlinks: [
    {
      key: 'skimlinks',
      domain: 'go.skimresources.com',
      labelParam: 'xcust',
      maxLabelLength: 50
    }
  ],
  sovrn: [
    {
      key: 'sovrn',
      domain: 'redirect.viglink.com',
      labelParam: 'cuid',
      maxLabelLength: 32,
      alphanumeric: true
    }
  ],
  stacked_brands: [
    {
      key: 'stacked_brands',
      domain: 'instantknockout.com',
      labelParam: 'data2',
      maxLabelLength: 255
    },
    {
      key: 'stacked_brands',
      domain: 'testofuel.com',
      labelParam: 'data2',
      maxLabelLength: 255
    },
    {
      key: 'stacked_brands',
      domain: 'primemale.com',
      labelParam: 'data2',
      maxLabelLength: 255
    },
    {
      key: 'stacked_brands',
      domain: '4gauge.com',
      labelParam: 'data2',
      maxLabelLength: 255
    }
  ],
  tapfiliate: [
    {
      key: 'tapfiliate',
      labelParam: 'tm_subid5'
    }
  ],
  redventures: [
    {
      key: 'redventures',
      domain: 'oc.brcclx.com',
      labelParam: 'tid'
    },
    {
      key: 'redventures',
      domain: 'www.bankrate.com',
      labelParam: 'sub-id'
    }
  ],
  tradedoubler: [
    {
      key: 'tradedoubler',
      domain: 'clk.tradedoubler.com',
      replace: {
        match: `epi\([^\)]+\)`, // eslint-disable-line no-useless-escape
        template: 'epi(TRACKING_LABEL',
        fallbackMatch: `g\([0-9]+\)`, // eslint-disable-line no-useless-escape
        fallbackTemplate: `MATCHepi(TRACKING_LABEL)`
      },
      labelParam: 'epi',
      maxLabelLength: 64
    },
    {
      key: 'tradedoubler',
      domain: 'clkuk.tradedoubler.com',
      replace: {
        match: `epi\([^\)]+\)`, // eslint-disable-line no-useless-escape
        template: 'epi(TRACKING_LABEL',
        fallbackMatch: `g\([0-9]+\)`, // eslint-disable-line no-useless-escape
        fallbackTemplate: `MATCHepi(TRACKING_LABEL)`
      },
      labelParam: 'epi',
      maxLabelLength: 64
    }
  ],
  tradetracker: [
    {
      key: 'tradetracker',
      domain: 'tc.tradetracker.net',
      replace: {
        match: `NO_MATCH`, // eslint-disable-line no-useless-escape
        template: '',
        fallbackMatch: `(tt=[0-9]+_[0-9]+_[0-9]+(?:_[a-zA-Z]*)?)`, // eslint-disable-line no-useless-escape
        fallbackTemplate: `MATCH_TRACKING_LABEL`
      },
      labelParam: 'r',
      maxLabelLength: 255
    }
  ],
  cbdAffs: [
    {
      key: 'cbdAffs',
      labelParam: 'aff_sub2',
      domain: 'clicks.trackcb.com'
    }
  ],
  cannaffiliate: [
    {
      key: 'cannaffiliate',
      labelParam: 'aff_sub2',
      domain: 'tracking.cannaffiliate.com'
    }
  ],
  revoffers: [
    {
      key: 'revoffers',
      labelParam: 'aff_sub2',
      domain: 'track.revoffers.com'
    }
  ],
  tune: [
    {
      key: 'tune',
      labelParam: 'aff_sub2'
    }
  ],
  webgains: [
    {
      key: 'webgains',
      domain: 'track.webgains.com',
      labelParam: 'clickref'
    }
  ],
  viator: [
    {
      key: 'viator',
      domain: 'viator.com',
      labelParam: 'campaign'
    }
  ],
  engine: [
    {
      key: 'engine',
      domain: 'fiona.com',
      labelParam: 'tag.subid'
    },
    {
      key: 'engine',
      domain: 'offers.evenfinancial.com',
      labelParam: 'tag.subid'
    }
  ],
  howl: [
    {
      key: 'howl',
      domain: 'howl.me',
      labelParam: 'u1'
    },
    {
      key: 'howl',
      domain: 'shop-links.co',
      labelParam: 'u1'
    }
  ],
  klook: [
    {
      key: 'klook',
      domain: 'www.klook.com',
      labelParam: 'aff_ext'
    },
    {
      key: 'klook',
      domain: 'affiliate.klook.com',
      labelParam: 'aff_ext'
    }
  ],
  stay22: [
    {
      key: 'stay22',
      domain: 'www.stay22.com',
      labelParam: 'campaign',
      allLowerCase: true
    }
  ],
  freshreach: [
    {
      key: 'freshreach',
      domain: 'freshreach.co',
      labelParam: 'sid'
    }
  ],
  gbr: [
    {
      key: 'gbr',
      domain: 'products.gobankingrates.com',
      labelParam: 'targeting[utm_term]={affilimate_id}',
      allLowerCase: true
    }
  ]
};

export const generateId = (prefix = 'amcid-') => {
  const affilimateEpoch = new Date('2019-01-01').getTime();
  const now = new Date().getTime();
  const diff = Math.round((now - affilimateEpoch) / 10); // Tenth of a second between now and affilimate epoch
  const randomBytes = (Math.random() + 1).toString(36).substring(2, 6);
  return `${prefix}${diff}${randomBytes}`;
};
