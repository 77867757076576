//console.cloud.google.com/logs/query;query=resource.type%3D%22cloud_function%22%0Aresource.labels.function_name%3D%22analytics_v2-query%22%0Aresource.labels.region%3D%22us-central1%22%0AjsonPayload.logType%3D%22CLICKHOUSE_QUERY%22%0AjsonPayload.logId%3D%22b68fEWtU0S%22;cursorTimestamp=2024-05-01T09:32:14.260714321Z;duration=PT15M?project=affilimate-dev
import { mapKeys } from 'lodash';
import * as ENVS from '../env.json';

const BASE = 'https://console.cloud.google.com/logs/query;query=';

export const toLogLink = (
  jsonPayloadQueryArgs: { [key: string]: string },
  otherQueryArgs?: { [key: string]: string }
) => {
  const projectId = ENVS.firebase.projectId;

  const allArgs = {
    ...mapKeys(jsonPayloadQueryArgs, (_, k) => `jsonPayload.${k}`),
    ...otherQueryArgs
  };

  const argsStr = encodeURIComponent(
    Object.entries(allArgs)
      .map(([k, v]) => `${k}="${v}"`)
      .join('\n')
  );
  return `${BASE}${argsStr}?project=${projectId}`;
};

export const toCloudFunctionGen1LogLink = (
  fnName: string | null,
  jsonPayloadQueryArgs: { [key: string]: string },
  otherQueryArgs?: { [key: string]: string }
) => {
  const otherArgs: { [key: string]: any } = {
    'resource.type': 'cloud_function',
    ...otherQueryArgs
  };
  if (fnName) {
    otherArgs['resource.labels.function_name'] = fnName;
  }
  return toLogLink(jsonPayloadQueryArgs, otherArgs);
};
