import firebase from 'firebase/app';
import { compact } from 'lodash';
import { downloadFileFromBlob, toCsv } from '../../../../services/file';
import { FS } from '../../../../versions';
import { toAttributionRuleDoc } from '../../components/RuleCreateDialog/service';

const handleSnapshot = (
  snap: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
) => {
  const docs = snap.docs.map((d) => toAttributionRuleDoc(d));
  return docs;
};

export const exportRules = async (spaceId: string) => {
  const limit = 5000;
  const docs = await firebase
    .firestore()
    .collection(FS.attributionRules)
    .orderBy('createdAt', 'desc')
    .where('spaceId', '==', spaceId)
    .limit(limit)
    .get()
    .then(handleSnapshot);

  // Create a CSV file in the browser including the rules
  const csvObj = compact(
    docs.map((d) => {
      // I tried and failed to use the typeguards
      const rule = d.data.match?.els[0] as any;
      const apply = d.data.apply[0];

      if (!rule) {
        return null;
      }

      const matchAttribute = rule.k;
      const matchCondition = rule.op;
      const matchValue = rule.v;
      const applyValue = apply.value;

      return {
        Match: matchAttribute,
        Condition: matchCondition,
        Value: matchValue,
        Apply: applyValue
      };
    })
  );

  const csv = await toCsv(csvObj);
  const filename = `rules.csv`;
  const fileType = 'csv';

  downloadFileFromBlob(filename, fileType, csv);
};
