import {
  TimeframePickerDense,
  useStandardOptions,
  useTimeframeFromUrl
} from '../../TimeframePicker';
import { AnalyticsQuery } from '../../../domainTypes/analytics_v2';
import { timeframeToIsoRange } from '../../../services/time';
import { useMemo } from 'react';
import { toComparableTimeframe } from '../../../services/analytics';

export const Timeframe = () => {
  const { options, defaultOption } = useStandardOptions();
  const [timeframe, setTimeframe] = useTimeframeFromUrl(defaultOption.value);

  return (
    <TimeframePickerDense
      value={timeframe}
      onChange={setTimeframe}
      options={options}
    />
  );
};

export const useTimeframe = (): Pick<AnalyticsQuery, 'range' | 'compare'> => {
  const { defaultOption } = useStandardOptions();
  const [{ end, tz, start }] = useTimeframeFromUrl(defaultOption.value);

  return useMemo(() => {
    let tf = { end, tz, start };
    return {
      range: timeframeToIsoRange(tf),
      compare: { range: timeframeToIsoRange(toComparableTimeframe(tf)) }
    };
  }, [end, start, tz]);
};
