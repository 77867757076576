import React, { useCallback, useMemo } from 'react';
import { CampaignsPageBody } from '../components/CampaignsPageBody';
import {
  CampaignForm,
  CampaignFormFields,
  fieldsToFirestore
} from '../components/CampaignForm';
import { Paper } from '@material-ui/core';
import { css } from '../../../emotion';
import Typography from '@material-ui/core/Typography';
import { EMPTY_CAMPAIGN, saveCampaign } from '../service';
import { useCurrentUser } from '../../../services/currentUser';
import { useSnackbar } from '../../../hooks/useSnackbar';
import { useRoutes } from '../../../routes';
import shortid from 'shortid';
import { Helmet } from 'react-helmet';
import { LimitedWidth } from '../../../layout/PageBody';
import { useMixpanel, useTrackMixpanelView } from '../../../services/mixpanel';

export const CampaignCreator: React.FC = () => {
  useTrackMixpanelView('view_campaigns_creator');
  const { track } = useMixpanel();
  const { goTo, ROUTES } = useRoutes();
  const { enqueueSnackbar } = useSnackbar();
  const { space } = useCurrentUser();
  const campaign = useMemo(() => EMPTY_CAMPAIGN(space), [space]);
  const onSaveCampaign = useCallback(
    async (fields: CampaignFormFields) => {
      try {
        track('create_campaign');
        await saveCampaign(space.id, shortid(), fieldsToFirestore(fields));
        goTo(ROUTES.campaigns.overview.url());
      } catch (e) {
        enqueueSnackbar(`Failed to save ${fields.name} campaign`, {
          variant: 'error'
        });
      }
    },
    [ROUTES.campaigns.overview, enqueueSnackbar, goTo, space.id, track]
  );

  return (
    <>
      <Helmet>
        <title>Create campaign | Affilimate</title>
      </Helmet>
      <CampaignsPageBody>
        <LimitedWidth width={600}>
          <Paper
            className={css((t) => ({
              padding: t.spacing(3),
              marginTop: t.spacing(4)
            }))}
          >
            <Typography variant="h6">Create your campaign</Typography>
            <CampaignForm
              campaign={campaign}
              saveCampaign={onSaveCampaign}
              saveLabel="Create campaign"
            />
          </Paper>
        </LimitedWidth>
      </CampaignsPageBody>
    </>
  );
};
