import { Typography } from '@material-ui/core';
import moment from 'moment-timezone';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { AlertBox } from '../../../../../../components/AlertBox';
import {
  AnalyticsTable,
  ColumnDefinitions,
  useTable
} from '../../../../../../components/analytics_v2/Table';
import { ChartMode } from '../../../../../../components/Charts/EarningsChartCard/ChartModeSelector';
import { GraphMode } from '../../../../../../components/Charts/EarningsChartCard/GraphModeSelector';
import {
  EarningsBarChartCardMetricV2,
  EarningsChartCardWithoutDataV2
} from '../../../../../../components/Charts/EarningsChartCardV2';
import { EarningsPieChartCardV2 } from '../../../../../../components/Charts/EarningsPieChartV2';
import { CustomPagination } from '../../../../../../components/CustomPagination';
import {
  ExportQueryButton,
  useExportQuery
} from '../../../../../../components/ExportQuery';
import { SearchInput } from '../../../../../../components/SearchInput';
import { ColumnSelector } from '../../../../../../components/Table/ColumnSelector';
import {
  AnalyticsFilter,
  AnalyticsInterval,
  AnalyticsIntervalUnit,
  AnalyticsQuery,
  AnalyticsResponseRowWithComparison
} from '../../../../../../domainTypes/analytics_v2';
import { EMPTY_ARR } from '../../../../../../domainTypes/emptyConstants';
import { styled } from '../../../../../../emotion';
import { FlexContainer } from '../../../../../../layout/Flex';
import {
  DEFAULT_OFFSET,
  PageToolbar,
  PageToolbarOtherRow,
  PageToolbarSection
} from '../../../../../../layout/PageToolbar';
import {
  useRoutes,
  useStringQueryParam,
  useTypedStringQueryParam
} from '../../../../../../routes';
import { allTime } from '../../../../../../services/analytics';
import { ANALYTICS_GROUPS } from '../../../../../../services/analyticsV2/groups';
import { Metric } from '../../../../../../services/analyticsV2/metrics';
import { useAnalyticsQueryV2 } from '../../../../../../services/analyticsV2/query';
import {
  useCurrentUser,
  useHasCurrentUserRequiredScopes
} from '../../../../../../services/currentUser';
import { timeframeToIsoRange, toMoment } from '../../../../../../services/time';
import { useSpaceCurrency } from '../../../../../../services/useSpaceCurrency';
import { PerformancePageBody } from '../../../../components/PerformancePageBody';
import { useKnownAdvertisersV2 } from '../../../../services/hooksV2';
import {
  FiltersDrawer,
  FiltersIcon,
  useFilterDrawerState
} from '../../../../../../components/analytics_v2/Filters/Drawer';
import { useFilterClauses } from '../../../../../../components/analytics_v2/Filters/hooks';
import { Dimension } from '../../../../../../components/analytics_v2/Filters/filters';
import { compact, isEqual } from 'lodash';
import { useFeatureEnabled } from '../../../../../../services/features';
import {
  Timeframe,
  useTimeframe
} from '../../../../../../components/analytics_v2/Timeframe';

const Grid = styled('div')`
  display: grid;
  grid-column-gap: ${(p) => p.theme.spacing(3)}px;
  grid-row-gap: ${(p) => p.theme.spacing(6)}px;
  grid-template-columns: 1.75fr 3fr;
  margin-bottom: ${(p) => p.theme.spacing(6)}px;
  min-height: 500px;

  ${(p) => p.theme.breakpoints.down('md')} {
    grid-column-gap: ${(p) => p.theme.spacing(1)}px;
    grid-row-gap: ${(p) => p.theme.spacing(2)}px;
    grid-template-columns: 1fr 1fr;
  }

  ${(p) => p.theme.breakpoints.down('sm')} {
    grid-column-gap: ${(p) => p.theme.spacing(1)}px;
    grid-row-gap: ${(p) => p.theme.spacing(2)}px;
    grid-template-columns: 1fr;
  }
`;

const customColumns = ['advertiser', 'pk'] as const;
type CustomColumns = typeof customColumns[number];
type Column = CustomColumns | Metric;
const columns: Column[] = [
  'order_count_net',
  'quantity_net',
  'aov_net',
  'avg_rate_net',
  'commission_sum_net',
  'gmv_sum_net'
];
const availableColumns: Column[] = [...customColumns, ...columns];
const defaultVisibleColumns: Column[] = [
  ...customColumns,
  'order_count_net',
  'quantity_net',
  'aov_net',
  'avg_rate_net',
  'commission_sum_net',
  'gmv_sum_net'
];
const columnDefinitions: ColumnDefinitions<CustomColumns> = {
  advertiser: {
    column: {
      key: 'advertiser',
      head: () => 'Advertiser',
      cell: (p) => ANALYTICS_GROUPS.advertiser.toCell(p),
      align: 'left',
      sortable: true,
      width: 200,
      flexGrow: 2
    },
    sorter: {
      key: 'advertiser',
      items: {
        sort: (p: AnalyticsResponseRowWithComparison) =>
          p.group['advertiser_name'] || '',
        dir: 'desc'
      }
    }
  },
  pk: {
    column: {
      key: 'pk',
      head: () => 'Platform',
      cell: (p) => ANALYTICS_GROUPS.pk.toContainer(p).label,
      align: 'left',
      sortable: true,
      width: 80,
      flexGrow: 1
    },
    sorter: {
      key: 'pk',
      items: {
        sort: (p: AnalyticsResponseRowWithComparison) => p.group['pk'] || '',
        dir: 'desc'
      }
    }
  }
};

const PAGE_SIZE = 25;

export const PagePerformanceAdvertisersInnerV2 = () => {
  const { ROUTES } = useRoutes();
  const { space, tz } = useCurrentUser();
  const currency = useSpaceCurrency();

  const showTrafficSources = useFeatureEnabled('REFERRER_REPORTS_V1');
  const showCustomDimensions = useFeatureEnabled('CUSTOM_DIMENSIONS');
  const showPayoutDimensions = useFeatureEnabled('PAYOUTS_V1');

  const filterDrawer = useFilterDrawerState(false, {
    localStorageKey: 'advertisers-filter-drawer-state'
  });

  const [search, setSearch] = useStringQueryParam('q');
  const filterClauses = useFilterClauses();

  const filters = useMemo<AnalyticsFilter[]>(() => {
    if (search && search.length > 2) {
      return [
        ...filterClauses,
        {
          field: 'advertiser_name',
          condition: 'ilike',
          pattern: `%${search}%`
        }
      ];
    }
    return filterClauses;
  }, [filterClauses, search]);

  const availableDimensions = useMemo<Dimension[]>(
    () =>
      compact([
        showCustomDimensions && 'click_data_01',
        showCustomDimensions && 'click_data_02',
        showTrafficSources && 'utm_medium',
        showTrafficSources && 'utm_source',
        showTrafficSources && 'utm_campaign',
        showTrafficSources && 'utm_content',
        showTrafficSources && 'utm_term',
        showTrafficSources && 'referrer',
        showPayoutDimensions && 'payout_status',
        showPayoutDimensions && 'payout_id',
        'channel',
        'platform',
        'advertiser',
        'transaction_status',
        'transaction_type',
        'device',
        'tag'
      ]),
    [showCustomDimensions, showTrafficSources, showPayoutDimensions]
  );

  const { compare, range } = useTimeframe();
  const hasComparison = useMemo(
    () => !isEqual(timeframeToIsoRange(allTime()), range),
    [range]
  );

  const [graphMode, setGraphMode] = useTypedStringQueryParam<GraphMode>(
    'graph_mode',
    'advertiser'
  );
  const [chartMode, setChartMode] = useTypedStringQueryParam<ChartMode>(
    'chart_mode',
    'barChart'
  );
  const [intervalUnit, setIntervalUnit] = useTypedStringQueryParam<
    AnalyticsIntervalUnit
  >('interval_unit', 'day');
  const interval = useMemo<AnalyticsInterval>(
    () => ({
      unit: intervalUnit,
      value: 1,
      tz
    }),
    [tz, intervalUnit]
  );
  const [metric, setMetric] = useTypedStringQueryParam<
    EarningsBarChartCardMetricV2
  >('metric', 'commission_sum_net');

  const [advertisers] = useKnownAdvertisersV2(space.id, range, filters);
  const pageCount = useMemo(() => {
    if (!advertisers) {
      return 1;
    }
    return Math.ceil((advertisers.length || 0) / PAGE_SIZE);
  }, [advertisers]);

  const [canExportAdvertisers] = useHasCurrentUserRequiredScopes([
    'reports.advertisers.export'
  ]);

  const isNewSpace = moment().diff(toMoment(space.createdAt), 'hours') < 48;

  const {
    tableProps,
    metrics,
    paginationSelectorProps,
    pagination,
    orderBy,
    columnSelectorProps
  } = useTable(availableColumns, columnDefinitions, {
    pageSize: PAGE_SIZE,
    defaultSortColumn: 'commission_sum_net',
    defaultVisibleColumns
  });

  const q = useMemo<AnalyticsQuery>(() => {
    return {
      range,
      compare: hasComparison ? compare : undefined,
      groupBy: ANALYTICS_GROUPS.advertiser.groupBy,
      filters,
      paginate: pagination,
      orderBy: [orderBy],
      select: metrics
    };
  }, [range, hasComparison, compare, filters, pagination, orderBy, metrics]);
  const exportQ = useExportQuery(q);
  const tableData = useAnalyticsQueryV2(space.id, q);

  return (
    <PerformancePageBody noTopPadding>
      <PageToolbar sticky offset={DEFAULT_OFFSET} wrap>
        <PageToolbarSection flex={10}>
          <Typography
            variant="h6"
            component="span"
            style={{
              marginRight: '9px',
              position: 'relative',
              fontWeight: 'bold',
              top: '-2px'
            }}
          >
            Advertisers
          </Typography>
          <SearchInput
            value={search}
            onChange={setSearch}
            width={250}
            placeholder="Search by advertiser name"
            size="small"
            autoFocus
          />
          <FiltersIcon {...filterDrawer} />
        </PageToolbarSection>
        <PageToolbarSection flex={1} justifyContent="flex-end">
          <Timeframe />
        </PageToolbarSection>

        <PageToolbarOtherRow>
          <FiltersDrawer
            isOpen={filterDrawer.isOpen}
            marginTop={2}
            orderBy={'commission_sum_net'}
            availableDimensions={availableDimensions}
          />
        </PageToolbarOtherRow>
      </PageToolbar>

      {isNewSpace && (
        <AlertBox variant="success" style={{ marginBottom: '36px' }}>
          Your affiliate network and program-level analytics will begin
          collecting here.
          <br />
          <br />
          In the mean time, try{' '}
          <Link
            style={{ borderBottom: '1px solid black' }}
            to={ROUTES.performanceNew.transactions.url()}
          >
            setting up reporting
          </Link>{' '}
          with your affiliate networks and programs.
        </AlertBox>
      )}
      <div>
        <Grid>
          <EarningsPieChartCardV2
            space={space}
            range={range}
            filters={filters}
            currency={currency}
            metric={metric}
            grouper={ANALYTICS_GROUPS.advertiser}
            heading={'Top Advertisers'}
            subheading={'By share of total earnings'}
          />
          <EarningsChartCardWithoutDataV2
            space={space}
            range={range}
            interval={interval}
            filters={filters}
            search={EMPTY_ARR}
            currency={currency}
            metric={metric}
            intervalUnit={intervalUnit}
            setIntervalUnit={setIntervalUnit}
            setMetric={setMetric}
            graphMode={graphMode}
            setGraphMode={setGraphMode}
            chartMode={chartMode}
            setChartMode={setChartMode}
          />
        </Grid>

        <FlexContainer justifyContent="space-between">
          <div>
            <Typography
              variant="body1"
              component="p"
              style={{ fontWeight: 'bold' }}
            >
              Advertiser metrics
            </Typography>
            <Typography
              variant="body2"
              component="p"
              color="textSecondary"
              paragraph
            >
              Compare advertiser performance during this period according to
              your key metrics
            </Typography>
          </div>
          <FlexContainer>
            <ColumnSelector {...columnSelectorProps} />
            <CustomPagination
              {...paginationSelectorProps}
              siblingCount={0}
              count={pageCount}
            />
            {canExportAdvertisers && (
              <ExportQueryButton
                query={exportQ}
                reportType="advertisers"
                title="Export advertisers to CSV"
              />
            )}
          </FlexContainer>
        </FlexContainer>

        <AnalyticsTable
          d={tableData}
          tableProps={tableProps}
          rowToKey={ANALYTICS_GROUPS.advertiser.toKey}
          rowToHref={(row) => {
            const advertiserName = row.group['advertiser_name'];
            const pk = row.group['pk'];
            return ROUTES.performanceNew.advertisers.details.url({
              advertisers: advertiserName,
              partners: pk
            });
          }}
        />
      </div>
    </PerformancePageBody>
  );
};
