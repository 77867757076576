import { Card } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
  FiltersDrawer,
  FiltersIcon,
  useFilterDrawerState
} from '../../../../../components/analytics_v2/Filters/Drawer';
import {
  Dimension,
  useDimensionTitle
} from '../../../../../components/analytics_v2/Filters/filters';
import { useFilterClauses } from '../../../../../components/analytics_v2/Filters/hooks';
import {
  ColumnDefinitions,
  useTable
} from '../../../../../components/analytics_v2/Table';
import {
  Timeframe,
  useTimeframe
} from '../../../../../components/analytics_v2/Timeframe';
import { CustomPagination } from '../../../../../components/CustomPagination';
import { ExportQueryButton } from '../../../../../components/ExportQuery';
import { RowsRenderer } from '../../../../../components/GroupableList';
import { Loader } from '../../../../../components/Loader';
import { ColumnSelector } from '../../../../../components/Table/ColumnSelector';
import { WithHoverIndicator } from '../../../../../components/WithHoverIndicator';
import {
  AnalyticsOrderBy,
  AnalyticsQuery,
  AnalyticsResponseRowWithComparison
} from '../../../../../domainTypes/analytics_v2';
import { css } from '../../../../../emotion';
import { Centered } from '../../../../../layout/Centered';
import { FlexContainer } from '../../../../../layout/Flex';
import {
  DEFAULT_OFFSET,
  PageToolbar,
  PageToolbarSection,
  PageToolbarTitle
} from '../../../../../layout/PageToolbar';
import { Section } from '../../../../../layout/Section';
import { useRoutes } from '../../../../../routes';
import { Metric } from '../../../../../services/analyticsV2/metrics';
import { useAnalyticsQueryV2 } from '../../../../../services/analyticsV2/query';
import { useCurrentUser } from '../../../../../services/currentUser';
import { useMappedLoadingValue } from '../../../../../services/db';
import { availableMetrics } from '../service/columns';
import { Slot } from '../service/slot';
import { CustomDimensionTimeseries } from './CustomDimensionTimeseries';
import { useCustomDimensions } from '../../../../../services/customDimensions';
import { ClickDimensionName } from '../../../../../domainTypes/customDimensions';
import { CustomDimensionOptionLabel } from '../../../../../components/CustomDimensionOptionLabel';

const PAGE_SIZE = 10;
const customColumns = ['click_data'] as const;

type CustomColumns = typeof customColumns[number];
type Column = CustomColumns | Metric;

const availableColumns: Column[] = [...customColumns, ...availableMetrics];

const defaultVisibleColumns: Column[] = availableColumns;

const Value = ({ slot, value }: { slot: Slot; value: string }) => {
  const { ROUTES } = useRoutes();
  return (
    <Link to={ROUTES.content.customDimensions.details.url(slot, value)}>
      <WithHoverIndicator>
        <CustomDimensionOptionLabel slot={slot} value={value} large />
      </WithHoverIndicator>
    </Link>
  );
};

const columnDefinitions: (slot: Slot) => ColumnDefinitions<CustomColumns> = (
  slot: Slot
) => ({
  click_data: {
    column: {
      key: 'click_data',
      head: () => 'Name',
      cell: (p) => {
        return <Value slot={slot} value={p.group[slot]} />;
      },
      align: 'left',
      sortable: true,
      width: 200,
      flexGrow: 4
    },
    sorter: {
      key: 'click_data',
      items: { sort: (p) => p.group[slot], dir: 'asc' }
    }
  }
});

const useAvailableFilterDimensions = (): Dimension[] => {
  const [customDimensions = []] = useMappedLoadingValue(
    useCustomDimensions(),
    (dimensions) => Object.keys(dimensions) as ClickDimensionName[]
  );
  return ['channel', ...customDimensions];
};

const useCustomDimensionCount = (slot: Slot) => {
  const filters = useFilterClauses();
  const { space } = useCurrentUser();
  const { range } = useTimeframe();

  const count = `count_uniq_${slot}` as const;

  const query = useMemo<AnalyticsQuery>(() => {
    return {
      select: [count],
      range,
      filters: [
        ...filters,
        {
          field: slot,
          condition: 'not in',
          values: ['']
        }
      ]
    };
  }, [count, range, slot, filters]);

  return useMappedLoadingValue(
    useAnalyticsQueryV2(space.id, query),
    (resp) => resp.rows[0]?.data[count]?.curr || 0
  );
};

const useCustomDimensionData = (
  slot: Slot,
  metrics: Metric[],
  paginate: AnalyticsQuery['paginate'],
  orderBy: AnalyticsOrderBy
) => {
  const { space } = useCurrentUser();
  const { range, compare } = useTimeframe();
  const filters = useFilterClauses();

  const query = useMemo<AnalyticsQuery>(() => {
    return {
      select: metrics,
      range,
      compare,
      groupBy: [slot],
      orderBy: [orderBy],
      paginate,
      filters: [
        ...filters,
        {
          field: slot,
          condition: 'not in',
          values: ['']
        }
      ]
    };
  }, [metrics, range, compare, slot, orderBy, paginate, filters]);

  return useAnalyticsQueryV2(space.id, query);
};

const useCustomDimensionExportQuery = (
  slot: Slot,
  metrics: Metric[],
  orderBy: AnalyticsOrderBy
) => {
  const filters = useFilterClauses();
  const { range } = useTimeframe();
  return useMemo<AnalyticsQuery>(
    () => ({
      range,
      select: metrics,
      groupBy: [slot],
      orderBy: [orderBy],
      filters: [
        ...filters,
        {
          field: slot,
          condition: 'not in',
          values: ['']
        }
      ]
    }),
    [filters, metrics, orderBy, range, slot]
  );
};

export const CustomDimensionsSlot = ({ slot }: { slot: Slot }) => {
  const availableFilterDimensions = useAvailableFilterDimensions();
  const columnDefs = useMemo(() => columnDefinitions(slot), [slot]);
  const rowToKey = useCallback(
    (p: AnalyticsResponseRowWithComparison) => p.group[slot],
    [slot]
  );

  const {
    tableProps,
    columnSelectorProps,
    paginationSelectorProps,
    pagination,
    orderBy,
    metrics
  } = useTable(availableColumns, columnDefs, {
    pageSize: PAGE_SIZE,
    defaultSortColumn: 'commission_sum_net',
    defaultVisibleColumns
  });

  const exportQuery = useCustomDimensionExportQuery(slot, metrics, orderBy);

  const [count = 0] = useCustomDimensionCount(slot);

  const [data, loading] = useCustomDimensionData(
    slot,
    metrics,
    pagination,
    orderBy
  );

  const title = useDimensionTitle(slot);

  const { isOpen, toggle } = useFilterDrawerState(false, {
    localStorageKey: 'custom-dimensions'
  });

  return (
    <div>
      <PageToolbar
        className={css((t) => ({
          paddingTop: 0,
          paddingBottom: t.spacing(1)
        }))}
      >
        <PageToolbarTitle flex={1}>
          <FlexContainer>
            {title}
            <FiltersIcon isOpen={isOpen} toggle={toggle} />
          </FlexContainer>
        </PageToolbarTitle>

        <PageToolbarSection flex={2} justifyContent="flex-end">
          <ColumnSelector {...columnSelectorProps} />
          <CustomPagination
            {...paginationSelectorProps}
            count={Math.ceil(count / PAGE_SIZE)}
            siblingCount={0}
          />
          <ExportQueryButton
            title="Custom dimensions"
            reportType="custom_dimensions"
            query={exportQuery}
          />
          <Timeframe />
        </PageToolbarSection>
      </PageToolbar>

      <FiltersDrawer
        isOpen={isOpen}
        availableDimensions={availableFilterDimensions}
        orderBy={orderBy.field as Metric}
      />

      <div
        className={css((t) => ({
          paddingTop: t.spacing(1),
          paddingBottom: t.spacing(1)
        }))}
      >
        <CustomDimensionTimeseries slot={slot} />
      </div>

      <Section>
        {loading ? (
          <Card>
            <Centered height={200}>
              <Loader />
            </Centered>
          </Card>
        ) : !data || data.rows.length === 0 ? (
          <Card>
            <Centered height={200}>
              <Typography variant="h6">No data</Typography>
            </Centered>
          </Card>
        ) : (
          <RowsRenderer
            {...tableProps}
            renderHead={true}
            headProps={{
              sticky: true,
              offset: DEFAULT_OFFSET
            }}
            rows={data.rows}
            rowToKey={rowToKey}
          />
        )}
      </Section>
    </div>
  );
};
