import { Paper, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import SwaggerUI from 'swagger-ui-react';
import { SupportButton } from '../../../../components/SupportButton';
import { LimitedWidth } from '../../../../layout/PageBody';
import { useCurrentUser } from '../../../../services/currentUser';
import { useFeatureEnabled } from '../../../../services/features';
import { callFirebaseFunction } from '../../../../services/firebaseFunctions';
import { CF } from '../../../../versions';
import { KnowledgeBasePageBody } from '../KnowledgeBase/components/KnowledgeBasePageBody';
import { SwaggerWrapper } from './theme';

const ApiNotAvailable = () => {
  return (
    <LimitedWidth width={600}>
      <img
        src="/images/empty-states/api-key-empty-state.svg"
        style={{ width: '100%' }}
        alt="Publisher API coming soon"
      />
      <Typography variant="h6" component="h2" paragraph>
        <strong>Access the Publisher API</strong>
      </Typography>
      <Typography variant="body1" component="h2" paragraph>
        The Affilimate Publisher API is an advanced feature which includes a
        unified transaction and payouts API, programmatic retrieval of analytics
        data, API-driven link generation, and more.
      </Typography>
      <Typography variant="body1" component="h2" paragraph>
        Reach out to us for more details on features and pricing.
      </Typography>
      <SupportButton subject="Publisher API" label="Request API information" />
    </LimitedWidth>
  );
};

export const PageApiDocs = () => {
  const enabled = useFeatureEnabled('API_V1');
  const [loading, setLoading] = useState(true);
  const [spec, setSpec] = useState<undefined | object>(undefined);
  const [err, setErr] = useState(false);
  const { space } = useCurrentUser();

  useEffect(() => {
    if (!space.id) {
      return;
    }
    if (!enabled) {
      setErr(true);
      setSpec(undefined);
      return;
    }
    callFirebaseFunction<object>(CF.apiSpec.get, { spaceId: space.id })
      .then((r) => {
        setSpec(r);
        setLoading(false);
        setErr(false);
      })
      .catch(() => {
        setSpec(undefined);
        setErr(true);
      });
  }, [enabled, space.id]);

  return (
    <KnowledgeBasePageBody>
      {!loading && spec ? (
        <LimitedWidth width={900}>
          <Paper>
            <SwaggerWrapper>
              <SwaggerUI spec={spec} />
            </SwaggerWrapper>
          </Paper>
        </LimitedWidth>
      ) : null}
      {err && <ApiNotAvailable />}
    </KnowledgeBasePageBody>
  );
};
