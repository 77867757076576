import moment from 'moment-timezone';
import { CurrencyCode } from '../../../../../domainTypes/currency';
import {
  getTrackingId,
  SaleStatus
} from '../../../../../domainTypes/performance';
import { fromMoment, now } from '../../../../../services/time';
import { toCents, toPercent } from '../helpers';
import { IFileReportHandler } from '../types';

const DATE_FORMAT = 'MMM D YYYY, H:mm A';

const toAmountAndCurrency = (str: string) => {
  let currency: CurrencyCode = 'USD';
  let amount = str;

  if (str.indexOf('€') !== -1) {
    currency = 'EUR';
    amount = str.replace(',', '.');
  }

  amount = amount.replace('$', '').replace('€', '');

  return { currency, amount };
};

const toDate = (dateStr: string) => {
  const agoLoc = dateStr.indexOf('ago');
  const onlyDate = dateStr.substr(agoLoc + 3, dateStr.length);
  return moment.tz(onlyDate, DATE_FORMAT, 'UTC');
};

const toStatus = (status: string, commissionAmount: string): SaleStatus => {
  const isNegative = commissionAmount.replace('$', '').indexOf('-') !== -1;
  if (isNegative) {
    return 'Refunded';
  }
  if (status === 'Pending') {
    return 'Pending';
  }
  if (status === 'Approved') {
    return 'Final';
  }
  if (status === 'Paid') {
    return 'Final';
  }
  return 'Unknown';
};

export const UPPROMOTE: IFileReportHandler<string> = {
  type: 'CSV',
  partnerKey: 'secomapp',
  parser: {
    name: 'Commission',
    csvHeaders:
      '"Create at","Referral ID","Order number","Total sales","Quantity","Commission","Status","Source"',
    matches: (text, expectedHeaders) => text.indexOf(expectedHeaders) === 0,
    processRows: async (rows, { partnerKey, reportId, integrationId }) => {
      return rows.slice(1).map((row) => {
        const [
          createdAt,
          referralId,
          orderNumber,
          totalSales,
          quantity,
          commissionAmount,
          statusValue,
          source,
          // eslint-disable-next-line
          action,
          // eslint-disable-next-line
          networkCommissionBroughtBy
        ] = row;

        const { currency, amount: cAmount } = toAmountAndCurrency(
          commissionAmount
        );
        const { amount: sales } = toAmountAndCurrency(totalSales);
        let price = toCents(sales);

        let commission = toCents(cAmount);
        const saleDate = toDate(createdAt);
        const status = toStatus(statusValue, commissionAmount);
        const commissionPercent = toPercent(commission, price);

        if (status === 'Refunded') {
          price = Math.abs(price);
          commission = Math.abs(commission);
        }

        return {
          saleId: referralId,
          orderId: orderNumber,
          trackingId: getTrackingId(source || ''),
          trackingLabel: source || '',
          reportId,
          integrationId,
          saleDate: fromMoment(saleDate),
          completionDate: null,
          origin: null,
          status,
          partnerKey,
          partnerProductName: '',
          partnerProductId: '',
          payoutId: null,
          payoutDate: null,
          payoutStatus: null,
          lastModified: now(),
          coupon: '',
          saleType: 'unknown',
          amount: {
            currency,
            price,
            revenue: null,
            commission
          },
          quantity: parseInt(quantity),
          commissionPercent,
          advertiserId: '',
          advertiserName: ''
        };
      });
    }
  }
};
