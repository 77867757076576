import { Totals, TotalsObject } from '../service/totals';
import { FlexContainer } from '../../../layout/Flex';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { css } from '../../../emotion';

export const CampaignSummaryLayout: React.FC<{
  totals: Totals;
  children: (totals: TotalsObject) => React.ReactNode;
}> = ({ totals, children }) => {
  const [totalValues, loading] = totals;
  if (loading || !totalValues) {
    return (
      <FlexContainer direction="column" spacing={0.5} alignItems="flex-start">
        <Skeleton variant="text" width={50} />
        <Skeleton variant="text" width={150} />
      </FlexContainer>
    );
  }

  return (
    <div
      className={css((t) => ({
        paddingRight: t.spacing(5)
      }))}
    >
      {children(totalValues)}
    </div>
  );
};
