import React from 'react';
import { Filter } from 'react-feather';
import {
  getAppliedLabel,
  MultiSelector,
  MultiSelectorChip,
  MultiSelectorOption
} from '../../../../../../components/MultiSelector';

export const VerticalSelector = ({
  verticals,
  value,
  onChange
}: {
  value: Set<string>;
  onChange: (nextValue: Set<string>) => void;
  verticals: string[];
}) => {
  const options: MultiSelectorOption<string>[] = verticals.map((s) => ({
    label: s,
    value: s
  }));

  return (
    <MultiSelector
      value={value}
      onChange={onChange}
      legend="Select industries"
      options={options}
      allowFocusing
    >
      <MultiSelectorChip
        isApplied={verticals.length !== value.size}
        onDelete={() => onChange(new Set(verticals))}
        label={
          <>
            <Filter size={14} /> &nbsp; Industries
          </>
        }
        appliedLabel={getAppliedLabel('industry', [...value])}
      />
    </MultiSelector>
  );
};
