import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip
} from '@material-ui/core';
import firebase from 'firebase/app';
import { compact, isNaN } from 'lodash';
import moment, { Moment } from 'moment-timezone';
import React, { useMemo } from 'react';
import { Check as IconCheck, Clock as IconClock } from 'react-feather';
import { AdditionalActionsMenu } from '../../../../../../components/AdditionalActionsMenu';
import { AlertBox } from '../../../../../../components/AlertBox';
import { Ctr, CtrLoader } from '../../../../../../components/Ctr';
import { InlineLoader, Loader } from '../../../../../../components/Loader';
import { Currency } from '../../../../../../components/Number';
import {
  BaseCountCell,
  CountCell,
  HideableTableCell,
  ITableHead,
  Paginator,
  SortableHead,
  TableLabelWithTooltip
} from '../../../../../../components/Table';
import {
  aggregateDailyCounters,
  ICounter,
  Timeframe,
  TIMEKEY_FORMAT
} from '../../../../../../domainTypes/analytics';
import { CurrencyCode } from '../../../../../../domainTypes/currency';
import { Doc } from '../../../../../../domainTypes/document';
import {
  IPageMetadata,
  IPageRevision
} from '../../../../../../domainTypes/page';
import {
  EarningsArgsInTimeframe,
  EarningsRespInTimeframe,
  EMPTY_EARNING,
  IEarning,
  toEarningFromMinimal
} from '../../../../../../domainTypes/performance';
import { ISpace } from '../../../../../../domainTypes/space';
import { css, styled } from '../../../../../../emotion';
import { usePagination } from '../../../../../../hooks/usePagination';
import { useRoutes } from '../../../../../../routes';
import {
  diffDays,
  getCpm,
  getEpc,
  getRpm,
  useCountsInTimeframeForPagePg
} from '../../../../../../services/analytics';
import {
  useCurrentUser,
  useHasCurrentUserRequiredScopes
} from '../../../../../../services/currentUser';
import { useMappedLoadingValue } from '../../../../../../services/db';
import { updatePageRevision } from '../../../../../../services/page';
import {
  compareRevisions,
  getMatchingTimeframe,
  getTimeBoundaries,
  isLatestRevision,
  toEnd,
  toStart
} from '../../../../../../services/page/revision';
import { useEarnings } from '../../../../../../services/sales/earnings';
import {
  compareTimestamps,
  formatDatePrecise,
  formatSmartRelativeDate,
  toMoment
} from '../../../../../../services/time';
import { removeTrailingSlash } from '../../../../../../services/url';
import { useSpaceCurrency } from '../../../../../../services/useSpaceCurrency';
import { useTheme } from '../../../../../../themes';
import { RevisionsEmptyState } from '../../../../components/EmptyStateRevision';
import { toUrlState } from '../../../../components/RevisionSelector';
import { RevisionNoteButton } from './RevisionNoteButton';
import { useExperimentalContext } from '../../../../../../services/experimental';
import { AnalyticsQuery } from '../../../../../../domainTypes/analytics_v2';
import { usePromise } from '../../../../../../hooks/usePromise';
import { queryAnalyticsV2 } from '../../../../../../services/analyticsV2/query';
import { Dash } from '../../../../../../components/Table/CountCell';

type Props = {
  url: string;
  metadata: Doc<IPageMetadata>;
};

export type TableRoutes = {
  toDetails: (revision: IPageRevision) => string;
  toHeatmap: (revision: IPageRevision) => string;
};

export type TableData = {
  revision: IPageRevision;
  start: firebase.firestore.Timestamp;
  end: firebase.firestore.Timestamp | null;
  index: number;
};
type TableDataWithCounts = TableData & {
  counts: ICounter | null;
  activeDays: number | null;
  earnings: IEarning;

  loading: boolean;
  error: any;
  index: number;
};

type TableKey =
  | 'lastModifiedAt'
  | 'activeDays'
  | 'pageViews'
  | 'dailyPageViews'
  | 'products'
  | 'links'
  | 'viewRatio'
  | 'clickRatio'
  | 'cpm'
  | 'rpm'
  | 'epc'
  | 'actions';

const HEADS: ITableHead<TableKey>[] = [
  {
    key: 'lastModifiedAt',
    label: 'Revision',
    align: 'left',
    sortable: true,
    defaultDirection: 'desc'
  },
  {
    key: 'activeDays',
    label: 'Active days',
    align: 'right',
    sortable: false,
    defaultDirection: 'desc'
  },
  {
    key: 'pageViews',
    label: 'Total Pageviews',
    align: 'right',
    sortable: false,
    defaultDirection: 'desc'
  },
  {
    key: 'dailyPageViews',
    label: 'Avg daily pageviews',
    align: 'right',
    sortable: false,
    defaultDirection: 'desc'
  },
  {
    key: 'products',
    label: 'Products',
    align: 'right',
    sortable: false,
    defaultDirection: 'desc'
  },
  {
    key: 'links',
    label: 'Links',
    align: 'right',
    sortable: false,
    defaultDirection: 'desc'
  },
  {
    key: 'cpm',
    label: (
      <TableLabelWithTooltip
        align="right"
        label={<span>Page CTR</span>}
        tooltip={`
          Page-level click-through rate. The percentage of clicks through to your links per 100 pageviews.
        `}
        placement="top"
      />
    ),
    align: 'right',
    sortable: false,
    defaultDirection: 'desc',
    size: 'small'
  },
  {
    key: 'epc',
    label: (
      <TableLabelWithTooltip
        align="right"
        label={<span>EPC</span>}
        tooltip={`
      Earnings per click. How much you earn on average for every click through on an affiliate link on this page.
        `}
        placement="top"
      />
    ),
    align: 'right',
    sortable: false,
    defaultDirection: 'desc',
    size: 'small'
  },
  {
    key: 'rpm',
    label: (
      <TableLabelWithTooltip
        align="right"
        label={<span>RPM</span>}
        tooltip={`
          Revenue per one-thousand pageviews. If you drive 1,000 pageviews
          to this page, the amount of revenue to expect. Based on sales originating from clicks during this time period.
        `}
        placement="top"
      />
    ),
    align: 'right',
    sortable: false,
    defaultDirection: 'desc',
    size: 'small'
  },
  {
    key: 'actions',
    label: '',
    align: 'right',
    sortable: false,
    size: 'small'
  }
];

const PAGE_SIZES = [10];

const PaperWrapper = styled(Paper)`
  padding: ${(p) => p.theme.spacing(2)}px 0;
`;

const isRevisionReadyCH = (d: TableDataCH) => {
  return (
    d.activeDays !== null &&
    d.activeDays > 7 &&
    !isNaN(d.pageviews) &&
    d.pageviews > 2500
  );
};

const isRevisionReady = (d: TableDataWithCounts) => {
  return (
    d.activeDays !== null &&
    d.activeDays > 7 &&
    d.counts !== null &&
    d.counts.pageViews > 2500
  );
};

const RevisionPendingWrapper = styled('div')`
  display: flex;
  background-color: ${(p) => p.theme.palette.grey.A700};
  color: white;
  margin-left: ${(p) => p.theme.spacing(1)}px;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  padding: 2px;
  border-radius: 100%;
`;

export const RevisionReadyWrapper = styled('div')`
  display: flex;
  background-color: ${(p) => p.theme.palette.primary.main};
  color: white;
  margin-left: ${(p) => p.theme.spacing(1)}px;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  padding: 2px;
  border-radius: 100%;
`;

const NoIgnore: React.FC<{ d: { revision: IPageRevision } }> = ({
  d,
  children
}) => {
  if (!!d.revision.ignore) {
    return null;
  }
  return <>{children}</>;
};

const AdditionalActions: React.FC<{
  spaceId: string;
  url: string;
  d: { revision: IPageRevision };
  routes: TableRoutes;
  isFirstVersion: boolean;
}> = ({ d, spaceId, url, routes, isFirstVersion }) => {
  return (
    <AdditionalActionsMenu
      options={compact([
        !d.revision.ignore && {
          key: 'heatmap',
          label: 'Open heatmap',
          href: routes.toHeatmap(d.revision)
        },
        (!isFirstVersion || d.revision.ignore) && {
          key: 'toggle',
          label: d.revision.ignore ? 'Reactivate revision' : 'Ignore revision',
          onClick: () =>
            updatePageRevision(spaceId, url, {
              ...d.revision,
              ignore: !d.revision.ignore
            })
        }
      ])}
    />
  );
};

const useEarningsForRevisions = (
  spaceId: string,
  url: string,
  revs: TableData[],
  currency: CurrencyCode,
  tz: string
) => {
  const queries = useMemo<EarningsArgsInTimeframe[]>(
    () =>
      revs.map<EarningsArgsInTimeframe>((r) => ({
        type: 'inTimeframe',
        d: {
          currency,
          page_url: [url],
          dates: {
            start: r.start.toMillis(),
            end: r.end?.toMillis() || moment().add(2, 'd').endOf('d').valueOf(), // just any stable date in the future, as we currently disabled open ended queries to make typing easier
            column: 'click_or_sale_date',
            tz
          }
        }
      })),
    [url, revs, tz, currency]
  );

  return useMappedLoadingValue(
    useEarnings<EarningsRespInTimeframe[]>(spaceId, queries, currency),
    (r) => {
      return r.res.map((x) => toEarningFromMinimal(x.d));
    }
  );
};

const revisionQuery = (
  { end, start }: { end: Moment; start: Moment },
  url: string
): AnalyticsQuery => {
  return {
    range: {
      start: start.toISOString(),
      end: end.toISOString()
    },
    select: ['p', 'ctr', 'epc_net', 'rpm_net'],
    filters: [
      {
        field: 'page_url',
        condition: 'in',
        values: [url]
      }
    ]
  };
};

const toMoments = ({ end, start, ...rest }: TableData) => {
  const endMillis = end ? end.toMillis() : new Date();
  const endMoment = moment(endMillis);
  const startMoment = moment(start.toMillis());
  return {
    ...rest,
    end: endMoment,
    start: startMoment
  };
};

interface TableDataCH {
  index: number;
  revision: IPageRevision;
  start: Moment;
  end: Moment;
  activeDays: number;
  pageviews: number;
  ctr: number;
  epc: number;
  rpm: number;
  avgPageviews: number;
}

// TODO: Fix TableData type name
const useRevisionMetrics = (revisions: TableData[], url: string) => {
  const revisionsWithMoments = useMemo(() => revisions.map(toMoments), [
    revisions
  ]);
  const { space } = useCurrentUser();
  return useMappedLoadingValue(
    usePromise(
      () =>
        Promise.all(
          revisionsWithMoments.map(async (revision) => {
            const result = await queryAnalyticsV2(
              space.id,
              revisionQuery(revision, url)
            );
            return {
              ...revision,
              data: result.rows[0].data
            };
          })
        ),
      [revisionsWithMoments]
    ),
    (rows) => {
      return rows.map((row) => {
        const { revision, start, end, index, data } = row;
        const activeDays = diffDays(start, end);

        const pageviews = data.p?.curr ?? 0;
        return {
          index,
          revision,
          start,
          end,
          activeDays,
          pageviews,
          ctr: data.ctr?.curr ?? 0,
          epc: data.epc_net?.curr ?? 0,
          rpm: data.rpm_net?.curr ?? 0,
          avgPageviews: pageviews / activeDays
        };
      });
    }
  );
};

const RevisionHistoryTableCH = ({
  spaceId,
  url,
  pagedRevisions,
  revisions,
  timeframe,
  routes
}: {
  spaceId: string;
  url: string;
  pagedRevisions: TableData[];
  revisions: IPageRevision[];
  timeframe: Timeframe;
  routes: TableRoutes;
}) => {
  const currency = useSpaceCurrency();

  const { tz } = timeframe;

  const [data, loading] = useRevisionMetrics(pagedRevisions, url);

  const [canIgnoreRevisions] = useHasCurrentUserRequiredScopes([
    'revisions.toggle_ignore'
  ]);

  const visibleColumns = useMemo(
    () =>
      new Set(
        HEADS.filter((h) => {
          if (h.key === 'actions' && !canIgnoreRevisions) {
            return false;
          }
          return h.key !== 'products' && h.key !== 'links';
        }).map((h) => h.key)
      ),
    [canIgnoreRevisions]
  );

  const theme = useTheme();
  return (
    <Table>
      <TableHead>
        <TableRow>
          {HEADS.map((h) => (
            <SortableHead
              key={h.key}
              head={h}
              currentDirection={'desc'}
              currentSortBy={'lastModifiedAt'}
              setSort={() => {}}
              padding={h.padding}
              hide={!visibleColumns.has(h.key)}
              size={h.size}
            />
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {loading || !data ? (
          <TableCell
            colSpan={8}
            className={css((t) => ({ padding: t.spacing(8), height: 350 }))}
          >
            <Loader size={32} />
          </TableCell>
        ) : (
          data.map((d, i) => (
            <TableRow key={i} style={{ color: 'red' }}>
              <HideableTableCell
                align="left"
                hide={!visibleColumns.has('lastModifiedAt')}
              >
                <span
                  style={{
                    display: 'flex',
                    whiteSpace: 'nowrap',
                    alignItems: 'center',
                    color: d.revision.ignore
                      ? theme.palette.text.disabled
                      : 'inherit'
                  }}
                >
                  <div
                    title={formatDatePrecise(
                      toMoment(d.revision.lastModifiedAt).tz(tz),
                      'MMM D, YYYY'
                    )}
                  >
                    v{d.index + 1} ·{' '}
                    {d.revision.provider === 'user'
                      ? 'Created manually'
                      : 'Published'}{' '}
                    {formatSmartRelativeDate(
                      toMoment(d.revision.lastModifiedAt).tz(tz),
                      'MMM D, YYYY'
                    )}
                  </div>
                  <RevisionNoteButton spaceId={spaceId} url={url} d={d} />
                  {!d.revision.ignore && isRevisionReadyCH(d) && (
                    <Tooltip
                      title="This revision has enough data for analysis"
                      placement="top"
                    >
                      <RevisionReadyWrapper>
                        <IconCheck size={14} />
                      </RevisionReadyWrapper>
                    </Tooltip>
                  )}

                  {!d.revision.ignore &&
                    isLatestRevision(d.revision, revisions) &&
                    !isRevisionReadyCH(d) && (
                      <Tooltip
                        title="This revision is still collecting data"
                        placement="top"
                      >
                        <RevisionPendingWrapper>
                          <IconClock size={14} />
                        </RevisionPendingWrapper>
                      </Tooltip>
                    )}
                </span>
              </HideableTableCell>
              <HideableTableCell
                align="right"
                hide={!visibleColumns.has('activeDays')}
              >
                <NoIgnore d={d}>
                  <CountCell
                    before={0}
                    after={d.activeDays}
                    format="decimal"
                    digits={0}
                    compare={false}
                  />
                </NoIgnore>
              </HideableTableCell>
              <HideableTableCell
                align="right"
                hide={!visibleColumns.has('pageViews')}
              >
                <NoIgnore d={d}>
                  <CountCell
                    before={0}
                    after={d.pageviews}
                    format="decimal"
                    digits={0}
                    compare={false}
                  />
                </NoIgnore>
              </HideableTableCell>
              <HideableTableCell
                align="right"
                hide={!visibleColumns.has('dailyPageViews')}
              >
                <NoIgnore d={d}>
                  {d.activeDays === 0 && <Dash />}
                  {d.activeDays > 0 && (
                    <CountCell
                      before={0}
                      after={Math.round(d.avgPageviews)}
                      format="decimal"
                      digits={0}
                      compare={false}
                    />
                  )}
                </NoIgnore>
              </HideableTableCell>
              <HideableTableCell
                align="right"
                hide={!visibleColumns.has('products')}
              >
                <NoIgnore d={d}>{0}</NoIgnore>
              </HideableTableCell>
              <HideableTableCell
                align="right"
                hide={!visibleColumns.has('links')}
              >
                <NoIgnore d={d}>{0}</NoIgnore>
              </HideableTableCell>
              <HideableTableCell
                align="right"
                hide={!visibleColumns.has('cpm')}
                size="small"
              >
                <NoIgnore d={d}>
                  <BaseCountCell
                    before={0}
                    after={d.ctr}
                    compare={false}
                    format="decimal"
                    digits={1}
                  >
                    <Ctr
                      steps={[0, 0.05, 0.15, 0.2, 0.3, 0.4, 0.5]}
                      rate={d.ctr}
                      format={(item) => `${(item * 100).toFixed(1)}%`}
                    />
                  </BaseCountCell>
                </NoIgnore>
              </HideableTableCell>
              <HideableTableCell
                align="right"
                hide={!visibleColumns.has('epc')}
                size="small"
              >
                <NoIgnore d={d}>
                  <Currency
                    cents={d.epc}
                    currency={currency}
                    zeroState={<Dash />}
                  />
                </NoIgnore>
              </HideableTableCell>
              <HideableTableCell
                align="right"
                hide={!visibleColumns.has('rpm')}
                size="small"
              >
                <NoIgnore d={d}>
                  <Currency
                    cents={d.rpm}
                    currency={currency}
                    zeroState={<Dash />}
                  />
                </NoIgnore>
              </HideableTableCell>
              <HideableTableCell
                align="right"
                hide={!visibleColumns.has('actions')}
                size="small"
              >
                <AdditionalActions
                  routes={routes}
                  d={d}
                  spaceId={spaceId}
                  url={url}
                  isFirstVersion={d.index === 0}
                />
              </HideableTableCell>
            </TableRow>
          ))
        )}
      </TableBody>
    </Table>
  );
};

const RevisionHistoryTablePg = ({
  spaceId,
  url,
  pagedRevisions,
  revisions,
  timeframe,
  routes
}: {
  spaceId: string;
  url: string;
  pagedRevisions: TableData[];
  revisions: IPageRevision[];
  timeframe: Timeframe;
  routes: TableRoutes;
}) => {
  const currency = useSpaceCurrency();

  const { tz } = timeframe;
  const [earnings, loadingEarnings, errorEarnings] = useEarningsForRevisions(
    spaceId,
    url,
    pagedRevisions,
    currency,
    tz
  );
  const [counts, loading, error] = useCountsInTimeframeForPagePg(
    spaceId,
    url,
    timeframe
  );

  const tableData = useMemo(
    () =>
      pagedRevisions
        .map<TableDataWithCounts>((d, i) => {
          if (d.revision.ignore || !counts || loading || error) {
            return {
              ...d,
              counts: null,
              activeDays: null,
              earnings: EMPTY_EARNING(currency),
              loading: loading || loadingEarnings,
              error: error || errorEarnings,
              index: i
            };
          }

          const start = toStart(d.start, tz, TIMEKEY_FORMAT);
          const end = toEnd(d.end, tz, TIMEKEY_FORMAT);

          const selectedCounts = counts.filter(
            (c) => c.timeKey >= start && c.timeKey <= end
          );

          return {
            ...d,
            counts: aggregateDailyCounters(selectedCounts),
            activeDays: selectedCounts.length,
            // Specifically check loadingEarnings
            // Earnings might have data for other revisions at this point, so matching against i would lead to wrong data being shown
            earnings:
              (earnings && !loadingEarnings ? earnings[i] : null) ||
              EMPTY_EARNING(currency),
            loading,
            error,
            index: i
          };
        })
        .sort((a, b) =>
          compareTimestamps(
            b.revision.lastModifiedAt,
            a.revision.lastModifiedAt
          )
        )
        .map((a, i) => ({ ...a, index: pagedRevisions.length - 1 - i })),
    [
      pagedRevisions,
      counts,
      loading,
      error,
      earnings,
      loadingEarnings,
      errorEarnings,
      currency,
      tz
    ]
  );

  const [canIgnoreRevisions] = useHasCurrentUserRequiredScopes([
    'revisions.toggle_ignore'
  ]);

  const visibleColumns = useMemo(
    () =>
      new Set(
        HEADS.filter((h) => {
          if (h.key === 'actions' && !canIgnoreRevisions) {
            return false;
          }
          return h.key !== 'products' && h.key !== 'links';
        }).map((h) => h.key)
      ),
    [canIgnoreRevisions]
  );

  const theme = useTheme();
  return (
    <Table>
      <TableHead>
        <TableRow>
          {HEADS.map((h) => (
            <SortableHead
              key={h.key}
              head={h}
              currentDirection={'desc'}
              currentSortBy={'lastModifiedAt'}
              setSort={() => {}}
              padding={h.padding}
              hide={!visibleColumns.has(h.key)}
              size={h.size}
            />
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {tableData.map((d, i) => (
          <TableRow key={i} style={{ color: 'red' }}>
            <HideableTableCell
              align="left"
              hide={!visibleColumns.has('lastModifiedAt')}
            >
              <span
                style={{
                  display: 'flex',
                  whiteSpace: 'nowrap',
                  alignItems: 'center',
                  color: d.revision.ignore
                    ? theme.palette.text.disabled
                    : 'inherit'
                }}
              >
                <div
                  title={formatDatePrecise(
                    toMoment(d.revision.lastModifiedAt).tz(tz),
                    'MMM D, YYYY'
                  )}
                >
                  v{tableData[i].index + 1} · Published{' '}
                  {formatSmartRelativeDate(
                    toMoment(d.revision.lastModifiedAt).tz(tz),
                    'MMM D, YYYY'
                  )}
                </div>
                <RevisionNoteButton spaceId={spaceId} url={url} d={d} />
                {!d.loading && !d.revision.ignore && isRevisionReady(d) && (
                  <Tooltip
                    title="This revision of your post has enough data for analysis"
                    placement="top"
                  >
                    <RevisionReadyWrapper>
                      <IconCheck size={14} />
                    </RevisionReadyWrapper>
                  </Tooltip>
                )}

                {!d.loading &&
                  !d.revision.ignore &&
                  isLatestRevision(d.revision, revisions) &&
                  !isRevisionReady(d) && (
                    <Tooltip
                      title="The latest revision of this page is still collecting data"
                      placement="top"
                    >
                      <RevisionPendingWrapper>
                        <IconClock size={14} />
                      </RevisionPendingWrapper>
                    </Tooltip>
                  )}
              </span>
            </HideableTableCell>
            <HideableTableCell
              align="right"
              hide={!visibleColumns.has('activeDays')}
            >
              <NoIgnore d={d}>
                {d.loading && <InlineLoader />}
                {d.activeDays !== null && d.activeDays === 0 && <Dash />}
                {d.activeDays !== null && d.activeDays > 0 && (
                  <CountCell
                    before={0}
                    after={d.activeDays}
                    format="decimal"
                    digits={0}
                    compare={false}
                  />
                )}
              </NoIgnore>
            </HideableTableCell>
            <HideableTableCell
              align="right"
              hide={!visibleColumns.has('pageViews')}
            >
              <NoIgnore d={d}>
                {d.loading && <InlineLoader />}
                {d.activeDays !== null && d.activeDays === 0 && <Dash />}
                {d.counts && d.activeDays !== null && d.activeDays > 0 && (
                  <CountCell
                    before={0}
                    after={d.counts.pageViews}
                    format="decimal"
                    digits={0}
                    compare={false}
                  />
                )}
              </NoIgnore>
            </HideableTableCell>
            <HideableTableCell
              align="right"
              hide={!visibleColumns.has('dailyPageViews')}
            >
              <NoIgnore d={d}>
                {d.loading && <InlineLoader />}
                {d.activeDays !== null && d.activeDays === 0 && <Dash />}
                {d.counts && d.activeDays !== null && d.activeDays > 0 && (
                  <CountCell
                    before={0}
                    after={Math.round(
                      d.counts.pageViews / Math.max(d.activeDays, 1)
                    )}
                    format="decimal"
                    digits={0}
                    compare={false}
                  />
                )}
              </NoIgnore>
            </HideableTableCell>
            <HideableTableCell
              align="right"
              hide={!visibleColumns.has('products')}
            >
              <NoIgnore d={d}>
                {d.loading && <InlineLoader />}
                {d.counts && 0}
              </NoIgnore>
            </HideableTableCell>
            <HideableTableCell
              align="right"
              hide={!visibleColumns.has('links')}
            >
              <NoIgnore d={d}>
                {d.loading && <InlineLoader />}
                {d.counts && 0}
              </NoIgnore>
            </HideableTableCell>
            <HideableTableCell
              align="right"
              hide={!visibleColumns.has('cpm')}
              size="small"
            >
              <NoIgnore d={d}>
                {d.loading && <CtrLoader />}
                {d.counts && (
                  <BaseCountCell
                    before={0}
                    after={getCpm(d.counts)}
                    compare={false}
                    format="decimal"
                    digits={1}
                  >
                    <Ctr
                      steps={[0, 0.05, 0.15, 0.2, 0.3, 0.4, 0.5]}
                      rate={getCpm(d.counts)}
                      format={(item) => `${(item * 100).toFixed(1)}%`}
                    />
                  </BaseCountCell>
                )}
              </NoIgnore>
            </HideableTableCell>
            <HideableTableCell
              align="right"
              hide={!visibleColumns.has('epc')}
              size="small"
            >
              <NoIgnore d={d}>
                {d.loading && <InlineLoader />}
                {d.counts && d.earnings && (
                  <Currency
                    cents={getEpc(d.counts.clicked, d.earnings.total)}
                    currency={currency}
                    zeroState="-"
                  />
                )}
              </NoIgnore>
            </HideableTableCell>
            <HideableTableCell
              align="right"
              hide={!visibleColumns.has('rpm')}
              size="small"
            >
              <NoIgnore d={d}>
                {d.loading && <InlineLoader />}
                {d.counts && d.earnings && (
                  <Currency
                    cents={getRpm(d.counts.pageViews, d.earnings.total)}
                    currency={currency}
                    zeroState="-"
                  />
                )}
              </NoIgnore>
            </HideableTableCell>
            <HideableTableCell
              align="right"
              hide={!visibleColumns.has('actions')}
              size="small"
            >
              <AdditionalActions
                routes={routes}
                d={d}
                spaceId={spaceId}
                url={url}
                isFirstVersion={tableData[i].index === 0}
              />
            </HideableTableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const RevisionHistory = ({
  space,
  url,
  routes,
  metadata
}: {
  space: ISpace;
  url: string;
  routes: TableRoutes;
  metadata: Doc<IPageMetadata>;
}) => {
  const [isExperiment] = useExperimentalContext();
  const {
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    getCurrentPageItems
  } = usePagination<TableData>(0, PAGE_SIZES[0], []);

  if (metadata.data.sitemapError === 'NOT_FOUND') {
    return (
      <AlertBox variant="error">
        We were unable to find this page in your sitemap and are therefore not
        able to track its revisions.
      </AlertBox>
    );
  }

  if (metadata.data.sitemapError === 'NO_LAST_MODIFIED') {
    return (
      <AlertBox variant="error">
        The sitemap entry for this page doesn't contain its last modification
        date. We're therefore not able to track its revisions.
      </AlertBox>
    );
  }

  if (!metadata.data.revisions.length) {
    return <RevisionsEmptyState />;
  }

  const revisions = [...metadata.data.revisions].sort(compareRevisions('asc'));

  if (!revisions.length) {
    return <RevisionsEmptyState />;
  }

  const framedRevisions = revisions.map((revision, index) => {
    const { start, end } = getTimeBoundaries(revision, revisions);
    return { revision, start, end, index };
  });

  const pagedRevisions = getCurrentPageItems([...framedRevisions].reverse());
  const timeframe = getMatchingTimeframe(
    space,
    pagedRevisions[pagedRevisions.length - 1].start,
    pagedRevisions[0].end
  );

  return (
    <PaperWrapper>
      {isExperiment ? (
        <RevisionHistoryTablePg
          spaceId={space.id}
          url={url}
          pagedRevisions={pagedRevisions}
          revisions={revisions}
          timeframe={timeframe}
          routes={routes}
        />
      ) : (
        <RevisionHistoryTableCH
          spaceId={space.id}
          url={url}
          pagedRevisions={pagedRevisions}
          revisions={revisions}
          timeframe={timeframe}
          routes={routes}
        />
      )}
      <Paginator
        totalCount={revisions.length}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={PAGE_SIZES}
        page={page}
        onChangePage={setPage}
        onChangeRowsPerPage={setRowsPerPage}
      />
    </PaperWrapper>
  );
};

export const Revisions: React.FC<Props> = ({ url: rawUrl, metadata }) => {
  const url = removeTrailingSlash(rawUrl);
  const { space } = useCurrentUser();
  const { ROUTES } = useRoutes();

  const routes = useMemo<TableRoutes>(() => {
    const defaultFieldNames = {
      revision: 'revision',
      syncTimeframe: 'syncTimeframe',
      timeframe: 'timeframe'
    };
    return {
      toDetails: (revision) =>
        ROUTES.content.details.trends.url(url, {
          ...toUrlState({ revision, syncTimeframe: true }, defaultFieldNames)
        }),
      toHeatmap: (revision) =>
        ROUTES.content.details.heatmap.url(url, {
          ...toUrlState({ revision, syncTimeframe: true }, defaultFieldNames)
        })
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return (
    <>
      <RevisionHistory
        space={space}
        url={url}
        routes={routes}
        metadata={metadata}
      />
    </>
  );
};
