import { Button, ButtonProps } from '@material-ui/core';
import React from 'react';
import { ArrowRight } from 'react-feather';
import { styled } from '../emotion';
import { Beacon } from '../services/beacon';

const SupportButtonWrapper = styled(Button)`
  font-size: 16px;
`;

export const SupportButton = ({
  subject,
  text,
  label,
  variant,
  color,
  sessionData,
  fields,
  size
}: {
  subject?: string;
  text?: string;
  label: string;
  fields?: {
    id: number;
    value: string | number | boolean;
  }[];
  sessionData?: { [key: string]: string | boolean };
  color?: ButtonProps['color'];
  variant?: ButtonProps['variant'];
  size?: ButtonProps['size'];
}) => {
  const iconSize = size === 'small' ? '12px' : '14px';

  return (
    <SupportButtonWrapper
      variant={variant || 'contained'}
      color={color || 'primary'}
      size={size || 'medium'}
      onClick={(e) => {
        e.preventDefault();
        if (sessionData) {
          Beacon('session-data', sessionData);
        }
        Beacon('open');
        Beacon('navigate', '/ask/message/');
        const prefill: any = {
          subject: subject || '',
          text: text || ''
        };
        if (fields) {
          prefill.fields = fields;
        }
        Beacon('prefill', prefill);
      }}
    >
      {label}
      <ArrowRight size={iconSize} style={{ marginLeft: '3px' }} />
    </SupportButtonWrapper>
  );
};
