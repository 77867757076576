import { RunningCampaign } from '../../service';
import { useCampaignTotals } from '../../service/totals';
import { CampaignReportToolbar } from './CampaignReportToolbar';
import { css } from '../../../../emotion';
import { CampaignTotals } from '../CampaignTotals';
import { CampaignTimeseries } from '../CampaignTimeseries';
import { CampaignTables } from '../CampaignTables';
import React from 'react';
import { RunningCampaignProgress } from '../RunningCampaignProgress';
import { CampaignSummaryLayout } from '../CampaignSummaryLayout';

export const RunningCampaignReport: React.FC<{ campaign: RunningCampaign }> = ({
  campaign
}) => {
  const totals = useCampaignTotals(campaign);

  return (
    <>
      <CampaignReportToolbar campaign={campaign} />
      <div
        className={css((t) => ({
          display: 'flex',
          flexDirection: 'column',
          rowGap: t.spacing(4)
        }))}
      >
        <CampaignTotals
          campaign={campaign}
          totals={totals}
          cards={['flatSpend', 'gmv', 'goal', 'earnings', 'c', 'p', 'v']}
        />
        <CampaignTimeseries campaign={campaign}>
          <CampaignSummaryLayout totals={totals}>
            {(totalValues) => {
              return (
                <RunningCampaignProgress
                  campaign={campaign}
                  totalValues={totalValues}
                />
              );
            }}
          </CampaignSummaryLayout>
        </CampaignTimeseries>
        <CampaignTables campaign={campaign} />
      </div>
    </>
  );
};
