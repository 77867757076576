import { Metric } from '../../../../../services/analyticsV2/metrics';
import { ColumnDefinitions } from '../../../../../components/analytics_v2/Table';
import { IPostgresTags } from '../../../../../domainTypes/tags';
import { HeadSelector, Selector } from '../Tags/selectors';
import { AnalyticsResponseRowWithComparison } from '../../../../../domainTypes/analytics_v2';
import { Href } from './Href';
import { compact, without } from 'lodash';
import React, { useMemo } from 'react';
import { useCanAssignTags } from '../service';
import { PageSelectionState } from '../ContentOverviewBody';

const customColumns = ['selector', 'page_url'] as const;
type CustomColumns = typeof customColumns[number];
export type Column = CustomColumns | Metric;

const availableColumns: Column[] = [
  ...customColumns,
  'p',
  'v',
  'c',
  's',
  'ctr',
  'view_ratio',
  'order_count_net',
  'order_count_gross',
  'avg_rate_net',
  'aov_net',
  'commission_sum_net',
  'commission_count_net',
  'quantity_net',
  'rpm_net',
  'epc_net',
  'gmv_sum_net',
  'page_last_modified'
];

const defaultVisibleColumns: Column[] = [
  ...customColumns,
  'p',
  'c',
  'ctr',
  'commission_sum_net',
  'rpm_net',
  'epc_net',
  'page_last_modified'
];

export const useColumns = (): {
  defaultVisible: Column[];
  available: Column[];
} => {
  const canAssignTags = useCanAssignTags();
  return useMemo(() => {
    const excludedColumns: Column[] = canAssignTags ? [] : ['selector'];
    return {
      available: without<Column>(availableColumns, ...excludedColumns),
      defaultVisible: without<Column>(defaultVisibleColumns, ...excludedColumns)
    };
  }, [canAssignTags]);
};

export const columnDefinitions = ({
  allPagesCount,
  toggleAllPages,
  togglePage,
  pages,
  isAllPagesSelected
}: Pick<
  PageSelectionState,
  | 'allPagesCount'
  | 'toggleAllPages'
  | 'togglePage'
  | 'pages'
  | 'isAllPagesSelected'
>): ColumnDefinitions<CustomColumns> => ({
  selector: {
    column: {
      key: 'selector',
      head: () => (
        <HeadSelector
          allPagesCount={allPagesCount}
          toggleAllPages={toggleAllPages}
          isAllPagesSelected={isAllPagesSelected}
        />
      ),
      cell: (p: AnalyticsResponseRowWithComparison) => (
        <Selector
          pageUrl={p.group.page_url}
          isAllPagesSelected={isAllPagesSelected}
          pages={pages}
          togglePage={togglePage}
        />
      ),
      align: 'center',
      width: 50,
      flexGrow: 0
    },
    sorter: {
      key: 'selector',
      items: {
        sort: (p: AnalyticsResponseRowWithComparison) => p.group.page_url,
        dir: 'asc'
      }
    }
  },
  page_url: {
    column: {
      key: 'page_url',
      head: () => 'Page URL',
      cell: (p: AnalyticsResponseRowWithComparison) => (
        <Href
          href={p.group.page_url}
          /*
           NOTE:
             Ideally we should have some type parameter around AnalyticsResponseRow for 'extra' field.
             And that parameter should be also passed through ColumnDefinitions type.
          */
          tags={compact(
            (p as AnalyticsResponseRowWithComparison & {
              extra: { tags: IPostgresTags[] };
            }).extra.tags
          )}
        />
      ),
      align: 'left',
      sortable: true,
      defaultDirection: 'asc',
      width: 400,
      flexGrow: 8
    },
    sorter: {
      key: 'page_url',
      items: {
        sort: (p: AnalyticsResponseRowWithComparison) => p.group.page_url,
        dir: 'asc'
      }
    }
  }
});
