import React from 'react';
import { Helmet } from 'react-helmet';
import {
  useColumnsQueryParam,
  useSortQueryParam
} from '../../../../components/GroupableList';
import { Loader } from '../../../../components/Loader';
import { ColumnSelector } from '../../../../components/Table/ColumnSelector';
import { ModeSelector } from '../../../../components/Table/ModeSelector';
import {
  useStandardOptions,
  useTimeframeFromUrl
} from '../../../../components/TimeframePicker';
import { isSameTimeframe, Mode } from '../../../../domainTypes/analytics';
import { PageToolbarSection } from '../../../../layout/PageToolbar';
import { useRoutes, useTypedStringQueryParam } from '../../../../routes';
import { allTime } from '../../../../services/analytics';
import { useProduct } from '../../../../services/products';
import { DetailsNotFound } from '../../components/DetailsNotFound';
import { DetailsToolbar } from '../../components/DetailsToolbar';
import { PageBodyLinkDetails } from '../../components/PageBodyLinkDetails';
import { COLUMNS, DEFAULT_COLUMNS, PagesTable, SORTERS } from './PagesTable';

export const PageLinkDetailsPages = ({ productId }: { productId: string }) => {
  const { ROUTES } = useRoutes();
  const [product, loading] = useProduct(productId);
  const { options, defaultOption } = useStandardOptions();
  const [timeframe, setTimeframe] = useTimeframeFromUrl(defaultOption.value);
  const compare = !isSameTimeframe(allTime(), timeframe);

  const [columns, setColumns] = useColumnsQueryParam(
    'columns',
    DEFAULT_COLUMNS
  );
  const [mode, setMode] = useTypedStringQueryParam<Mode>(
    'tabe-mode',
    'absolute-numbers',
    true
  );

  const sorters = SORTERS[mode];
  const [[sorter, direction], setSort] = useSortQueryParam('sort', sorters);

  return (
    <PageBodyLinkDetails productId={productId} noTopPadding>
      <Helmet>
        <title>Link Performance | Affilimate</title>
      </Helmet>
      <DetailsToolbar
        product={product}
        timeframe={timeframe}
        options={options}
        setTimeframe={setTimeframe}
      >
        <PageToolbarSection flex={1}>
          <ColumnSelector
            value={columns}
            onChange={setColumns}
            columns={COLUMNS}
          />
          <ModeSelector value={mode} onChange={setMode} />
        </PageToolbarSection>
      </DetailsToolbar>
      {loading && <Loader height={500} />}
      {product === null && <DetailsNotFound />}
      {product && (
        <PagesTable
          productId={productId}
          timeframe={timeframe}
          compare={compare}
          sorter={sorter || sorters.clicked}
          sortDirection={direction}
          onSort={(k, dir) => setSort([sorters[k] || null, dir])}
          columns={columns}
          rowToHref={(d) => ROUTES.content.details.trends.url(d.href)}
          mode={mode}
        />
      )}
    </PageBodyLinkDetails>
  );
};
