import { Paper } from '@material-ui/core';
import { PaginationProps } from '@material-ui/lab';
import { compact } from 'lodash';
import React, { ChangeEvent, useCallback, useMemo } from 'react';
import {
  AnalyticsOrderBy,
  AnalyticsQuery,
  AnalyticsResponse,
  AnalyticsResponseRowWithComparison
} from '../../../domainTypes/analytics_v2';
import { CurrencyCode } from '../../../domainTypes/currency';
import { SortDirection } from '../../../hooks/useSort';
import { Centered } from '../../../layout/Centered';
import {
  DEFAULT_OFFSET,
  DEFAULT_TOOLBAR_HEIGHT
} from '../../../layout/PageToolbar';
import { useNumberQueryParam } from '../../../routes';
import { Metric, metricTitle } from '../../../services/analyticsV2/metrics';
import { useCurrentUser } from '../../../services/currentUser';
import { LoadingValueLike } from '../../../services/db';
import { useSpaceCurrency } from '../../../services/useSpaceCurrency';
import { Ctr } from '../../Ctr';
import {
  ItemSorter,
  ROW_HEIGHTS,
  RowsRenderer,
  RowsRendererProps
} from '../../GroupableList';
import { Loader } from '../../Loader';
import { IColumn } from '../../Table/Column';
import { ColumnSelectorProps } from '../../Table/ColumnSelector';
import { Dash } from '../../Table/CountCell';
import { useColumnsQueryParam, useSortQueryParam } from './hooks';
import { metricCell } from '../MetricCell';

export interface TableMetadata {
  spaceId: string;
  currency: CurrencyCode;
  showComparison: boolean;
}

export const ALL_COLUMN_NAMES: Metric[] = [
  'p',
  'v',
  'c',
  's',
  'ctr',
  'view_ratio',
  'order_count_net',
  'order_count_gross',
  'avg_rate_net',
  'aov_net',
  'commission_sum_net',
  'commission_count_net',
  'quantity_net',
  'rpm_net',
  'epc_net',
  'gmv_sum_net',
  'page_last_modified',
  'count_uniq_link_occ'
];

export const DEFAULT_AVAILABLE_COLUMN_NAMES: Metric[] = [
  'p',
  'v',
  'c',
  's',
  'ctr',
  'view_ratio',
  'order_count_net',
  'order_count_gross',
  'avg_rate_net',
  'aov_net',
  'commission_sum_net',
  'commission_count_net',
  'quantity_net',
  'rpm_net',
  'epc_net',
  'gmv_sum_net'
];

export const DEFAULT_VISIBLE_COLUMN_NAMES: Metric[] = [
  'p',
  'c',
  'ctr',
  'commission_sum_net',
  'quantity_net',
  'rpm_net',
  'epc_net'
];

export const ALL_COLUMNS: Record<
  Metric,
  IColumn<AnalyticsResponseRowWithComparison, Metric, TableMetadata>
> = {
  p: {
    key: 'p',
    head: () => metricTitle('p'),
    headInfo: () => `
      How many times this page has been viewed in the selected timeframe. You'll only see pageviews for content which contains tracked affiliate links.
    `,
    cell: (p, o) => metricCell('p', p, o),
    align: 'right',
    sortable: true,
    width: 100,
    flexGrow: 2
  },
  v: {
    key: 'v',
    head: () => metricTitle('v'),
    headInfo: () => `
      How many affiliate links were actually seen by visitors
      as a result of scrolling down the page.
    `,
    cell: (p, o) => metricCell('v', p, o),
    align: 'right',
    sortable: true,
    width: 100,
    flexGrow: 2
  },
  s: {
    key: 's',
    head: () => metricTitle('s'),
    headInfo: () => `
      How many times affiliate links were served to one of your visitors.
    `,
    cell: (p, o) => metricCell('s', p, o),
    align: 'right',
    sortable: true,
    width: 100,
    flexGrow: 2
  },
  c: {
    key: 'c',
    head: () => metricTitle('c'),
    headInfo: () => `
      The number of times affiliate links on this page have been clicked
      during the given timeframe. Excludes duplicate clicks and bot clicks.
    `,
    cell: (p, o) => metricCell('c', p, o),
    align: 'right',
    sortable: true,
    width: 100,
    flexGrow: 2
  },
  ctr: {
    key: 'ctr',
    head: () => metricTitle('ctr'),
    headInfo: () =>
      `Page-level click-through rate. The number of clicks divided by the number of pageviews. A Page CTR of over 100% can indicate your visitors tend to click multiple links per page.`,
    cell: (p) => {
      const rate = p.data.ctr?.curr ?? 0;

      if (rate === 0) {
        return <Dash size={12} />;
      }
      return <Ctr rate={p.data.ctr?.curr ?? 0} />;
    },
    align: 'right',
    sortable: true,
    width: 100,
    flexGrow: 2
  },
  view_ratio: {
    key: 'view_ratio',
    head: () => metricTitle('view_ratio'),
    headInfo: () =>
      `How often visitors scroll far enough on the page to see this link`,
    cell: (p, o) => metricCell('view_ratio', p, o),
    align: 'right',
    sortable: true,
    width: 100,
    flexGrow: 2
  },
  order_count_net: {
    key: 'order_count_net',
    head: () => metricTitle('order_count_net'),
    headInfo: () =>
      'How many net orders you drove on this page. Only counts commissions from networks that report unique Order IDs. Used to calculate AOV.',
    cell: (p, o) => metricCell('order_count_net', p, o),
    align: 'right',
    sortable: true,
    width: 100,
    flexGrow: 2
  },
  order_count_gross: {
    key: 'order_count_gross',
    head: () => metricTitle('order_count_gross'),
    headInfo: () =>
      'How many net orders you drove on this page. Including canceled, rejected or refunded orders.',
    cell: (p, o) => metricCell('order_count_gross', p, o),
    align: 'right',
    sortable: true,
    width: 100,
    flexGrow: 2
  },
  aov_net: {
    key: 'aov_net',
    head: () => metricTitle('aov_net'),
    headInfo: () =>
      'Average order value (AOV). How much revenue you drove on this page, on average, for every order generated. Based on Final, Pending, and Refunded commissions. Only counts commissions from networks that report unique Order IDs.',
    cell: (p, o) => metricCell('aov_net', p, o),
    align: 'right',
    sortable: true,
    width: 100,
    flexGrow: 2
  },
  rpm_net: {
    key: 'rpm_net',
    head: () => metricTitle('rpm_net'),
    headInfo: () => `
      Revenue per one-thousand pageviews. If you drive 1,000 pageviews to this page, the amount of revenue to expect. Based on sales during this time period.
    `,
    cell: (p, o) => metricCell('rpm_net', p, o),
    align: 'right',
    sortable: true,
    width: 100,
    flexGrow: 2
  },
  epc_net: {
    key: 'epc_net',
    head: () => metricTitle('epc_net'),
    headInfo: () => `
      Earnings per click. How much you earn on average for every click through on an affiliate link on this page.
    `,
    cell: (p, o) => metricCell('epc_net', p, o),
    align: 'right',
    sortable: true,
    width: 100,
    flexGrow: 2
  },
  commission_sum_net: {
    key: 'commission_sum_net',
    head: () => metricTitle('commission_sum_net'),
    headInfo: () => `
      Commissions earned on this page during the selected timeframe, regardless of original click date. Based on Final, Pending, and Refunded commissions.
    `,
    cell: (p, o) => metricCell('commission_sum_net', p, o),
    align: 'right',
    sortable: true,
    width: 100,
    flexGrow: 2
  },
  gmv_sum_net: {
    key: 'gmv_sum_net',
    head: () => metricTitle('gmv_sum_net'),
    headInfo: () => `
      Total sales volume on this page during the selected timeframe, regardless of original click date. Only includes commissions for networks and advertisers that report this figure.
    `,

    cell: (p, o) => metricCell('gmv_sum_net', p, o),
    align: 'right',
    sortable: true,
    width: 100,
    flexGrow: 2
  },
  quantity_net: {
    key: 'quantity_net',
    head: () => metricTitle('quantity_net'),
    headInfo: () => `
      Net quantity of products sold during this timeframe. Not all networks report product count or basket size, in which case those are counted as a single item order.
    `,
    cell: (p, o) => metricCell('quantity_net', p, o),
    align: 'right',
    sortable: true,
    width: 100,
    flexGrow: 2
  },
  commission_count_net: {
    key: 'commission_count_net',
    head: () => metricTitle('commission_count_net'),
    headInfo: () => `
      How many individual line items or orders were posted by this advertiser, during this timeframe. Same as order count for networks and advertisers that do not report SKU-level commissions.
    `,
    cell: (p, o) => metricCell('commission_count_net', p, o),
    align: 'right',
    sortable: true,
    width: 100,
    flexGrow: 2
  },
  avg_rate_net: {
    key: 'avg_rate_net',
    head: () => metricTitle('avg_rate_net'),
    headInfo: () =>
      `The effective commission rate you've been earning through this advertiser during the selected timeframe. A dash may mean the advertiser does not provide order amounts needed to independently calculate the effective rate.`,
    cell: (p, o) => metricCell('avg_rate_net', p, o),
    align: 'right',
    sortable: true,
    width: 100,
    flexGrow: 2
  },
  page_last_modified: {
    key: 'page_last_modified',
    head: () => metricTitle('page_last_modified'),
    headInfo: () => `The last time this page was updated.`,
    cell: (p, o) => metricCell('page_last_modified', p, o),
    align: 'right',
    sortable: true,
    width: 150,
    flexGrow: 2
  },
  count_uniq_page_url: {
    key: 'count_uniq_page_url',
    head: () => metricTitle('count_uniq_page_url'),
    cell: (p, o) => metricCell('count_uniq_page_url', p, o),
    align: 'right',
    sortable: true,
    width: 60,
    flexGrow: 2
  },
  count_uniq_link_occ: {
    key: 'count_uniq_link_occ',
    head: () => metricTitle('count_uniq_link_occ'),
    headInfo: () => `How many times this link appeared within the page.`,
    cell: (p, o) => metricCell('count_uniq_link_occ', p, o),
    align: 'right',
    sortable: true,
    width: 80,
    flexGrow: 2
  }
};

export const ALL_SORTERS: Record<
  Metric,
  ItemSorter<AnalyticsResponseRowWithComparison>
> = {
  p: {
    key: 'p',
    items: {
      sort: (p) => p.data.p?.curr ?? null,
      dir: 'desc'
    }
  },
  c: {
    key: 'c',
    items: {
      sort: (p) => p.data.c?.curr ?? null,
      dir: 'desc'
    }
  },
  s: {
    key: 's',
    items: {
      sort: (p) => p.data.s?.curr ?? null,
      dir: 'desc'
    }
  },
  v: {
    key: 'v',
    items: {
      sort: (p) => p.data.v?.curr ?? null,
      dir: 'desc'
    }
  },
  ctr: {
    key: 'ctr',
    items: {
      sort: (p) => p.data.ctr?.curr ?? null,
      dir: 'desc'
    }
  },
  view_ratio: {
    key: 'view_ratio',
    items: {
      sort: (p) => p.data.view_ratio?.curr ?? null,
      dir: 'desc'
    }
  },
  order_count_net: {
    key: 'order_count_net',
    items: {
      sort: (p) => p.data.order_count_net?.curr ?? null,
      dir: 'desc'
    }
  },
  order_count_gross: {
    key: 'order_count_gross',
    items: {
      sort: (p) => p.data.order_count_gross?.curr ?? null,
      dir: 'desc'
    }
  },
  aov_net: {
    key: 'aov_net',
    items: {
      sort: (p) => p.data.aov_net?.curr ?? null,
      dir: 'desc'
    }
  },
  commission_sum_net: {
    key: 'commission_sum_net',
    items: {
      sort: (p) => p.data.commission_sum_net?.curr ?? null,
      dir: 'desc'
    }
  },
  gmv_sum_net: {
    key: 'gmv_sum_net',
    items: {
      sort: (p) => p.data.gmv_sum_net?.curr ?? null,
      dir: 'desc'
    }
  },
  quantity_net: {
    key: 'quantity_net',
    items: {
      sort: (p) => p.data.quantity_net?.curr ?? null,
      dir: 'desc'
    }
  },
  commission_count_net: {
    key: 'commission_count_net',
    items: {
      sort: (p) => p.data.commission_count_net?.curr ?? 0,
      dir: 'desc'
    }
  },
  rpm_net: {
    key: 'rpm_net',
    items: {
      sort: (p) => p.data.rpm_net?.curr ?? null,
      dir: 'desc'
    }
  },
  epc_net: {
    key: 'epc_net',
    items: {
      sort: (p) => p.data.epc_net?.curr ?? null,
      dir: 'desc'
    }
  },
  avg_rate_net: {
    key: 'avg_rate_net',
    items: {
      sort: (p) => p.data.avg_rate_net?.curr ?? null,
      dir: 'desc'
    }
  },
  page_last_modified: {
    key: 'page_last_modified',
    items: {
      sort: (p) => p.data.page_last_modified?.curr ?? null,
      dir: 'desc'
    }
  },
  count_uniq_page_url: {
    key: 'count_uniq_page_url',
    items: {
      sort: (p) => p.data.count_uniq_page_url?.curr ?? 0,
      dir: 'desc'
    }
  },
  count_uniq_link_occ: {
    key: 'count_uniq_link_occ',
    items: {
      sort: (p) => p.data.count_uniq_link_occ?.curr ?? 0,
      dir: 'desc'
    }
  }
};

export interface TableSettings<ColumnName extends string> {
  tableProps: Pick<
    RowsRendererProps<
      AnalyticsResponseRowWithComparison,
      ColumnName,
      TableMetadata
    >,
    'columns' | 'sorter' | 'sortDirection' | 'onHeadClick' | 'otherProps'
  >;
  columnSelectorProps: Pick<
    ColumnSelectorProps<ColumnName>,
    'columns' | 'value' | 'onChange'
  >;
  paginationSelectorProps: Pick<PaginationProps, 'page' | 'onChange'>;
  pagination: NonNullable<AnalyticsQuery['paginate']>;
  orderBy: AnalyticsOrderBy;
  metrics: Metric[];
}

export type ColumnDefinitions<ColumnName extends string> = Record<
  ColumnName,
  ColumnDefinition<ColumnName>
>;

export interface ColumnDefinition<Column extends string> {
  sorter: ItemSorter<AnalyticsResponseRowWithComparison>;
  column: IColumn<AnalyticsResponseRowWithComparison, Column, TableMetadata>;
}

interface Options<ColumnName> {
  defaultVisibleColumns?: Array<ColumnName>;
  defaultSortColumn: ColumnName;
  defaultSortDirection?: SortDirection;
  sortQueryParamName?: string;
  columnSelectorParamName?: string;
  paginationParamName?: string;
  pageSize: number;
  showComparison?: boolean;
}

export const useTable = <
  CustomColumn extends string,
  MetricColumn extends Metric
>(
  columns: Array<CustomColumn | MetricColumn>,
  customColumnsDefinitions: ColumnDefinitions<CustomColumn>,
  {
    defaultSortColumn,
    defaultSortDirection = 'desc',
    columnSelectorParamName = 'columns',
    sortQueryParamName = 'sort',
    paginationParamName = 'page',
    pageSize,
    showComparison = true,
    defaultVisibleColumns = columns
  }: Options<CustomColumn | MetricColumn>
): TableSettings<CustomColumn | MetricColumn> => {
  const { space } = useCurrentUser();
  const spaceId = space.id;
  const currency = useSpaceCurrency();
  const [[sortColumn, sortDirection], setSort] = useSortQueryParam(
    sortQueryParamName,
    columns,
    {
      column: defaultSortColumn,
      direction: defaultSortDirection
    }
  );
  const [selectedColumns, selectColumns] = useColumnsQueryParam(
    columnSelectorParamName,
    defaultVisibleColumns
  );
  const [page, setPage] = useNumberQueryParam(paginationParamName, 1);

  const getColumn = useCallback(
    (c: CustomColumn | MetricColumn) => {
      return (ALL_COLUMNS[c as MetricColumn] ??
        customColumnsDefinitions[c as CustomColumn]?.column) as IColumn<
        AnalyticsResponseRowWithComparison,
        CustomColumn | MetricColumn,
        TableMetadata
      >;
    },
    [customColumnsDefinitions]
  );

  const sorter = useMemo(
    () =>
      ALL_SORTERS[sortColumn as MetricColumn] ??
      customColumnsDefinitions[sortColumn as CustomColumn]?.sorter,
    [customColumnsDefinitions, sortColumn]
  );

  const pagination = useMemo(
    () => ({
      page,
      limit: pageSize
    }),
    [page, pageSize]
  );

  const metrics = useMemo(
    () =>
      ALL_COLUMN_NAMES.filter(
        (c) => selectedColumns.has(c as MetricColumn) || c === sortColumn
      ),
    [selectedColumns, sortColumn]
  );

  const orderBy = useMemo<AnalyticsOrderBy>(
    () => ({
      // NOTE: if you leave custom column as sortable, beware sending that key to query!
      field: sortColumn as AnalyticsOrderBy['field'],
      direction: sortDirection === 'asc' ? 'ASC' : 'DESC'
    }),
    [sortColumn, sortDirection]
  );

  return useMemo(() => {
    return {
      tableProps: {
        columns: compact(
          columns.filter((c) => selectedColumns.has(c)).map(getColumn)
        ),
        sorter,
        sortDirection,
        onHeadClick: (c, dir) => {
          const hasSorter =
            ALL_SORTERS[c.key as MetricColumn] ||
            customColumnsDefinitions[c.key as CustomColumn]?.sorter;
          if (c.sortable && hasSorter) {
            setSort([c.key, dir ?? defaultSortDirection]);
          }
        },
        otherProps: { currency, spaceId, showComparison: showComparison }
      },
      columnSelectorProps: {
        columns: compact(columns.map(getColumn)),
        onChange: selectColumns,
        value: selectedColumns
      },
      paginationSelectorProps: {
        page,
        onChange: (_: ChangeEvent<unknown>, newPage: number) => {
          setPage(newPage);
        }
      },
      orderBy,
      pagination,
      metrics
    };
  }, [
    columns,
    showComparison,
    currency,
    customColumnsDefinitions,
    defaultSortDirection,
    getColumn,
    metrics,
    orderBy,
    page,
    pagination,
    selectColumns,
    selectedColumns,
    setPage,
    setSort,
    sortDirection,
    sorter,
    spaceId
  ]);
};

export const AnalyticsTable = <
  CustomColumn extends string,
  MetricColumn extends Metric
>({
  d,
  tableProps,
  rowToKey,
  rowToHref,
  rowHeight = ROW_HEIGHTS.dense,
  headProps = {
    sticky: true,
    offset: DEFAULT_OFFSET + DEFAULT_TOOLBAR_HEIGHT
  },
  height = 300
}: {
  d: LoadingValueLike<AnalyticsResponse>;
  tableProps: TableSettings<CustomColumn | MetricColumn>['tableProps'];
  rowToKey: (row: AnalyticsResponseRowWithComparison) => string;
  rowToHref?: (row: AnalyticsResponseRowWithComparison) => string;
  rowHeight?: number;
  headProps?: {
    sticky?: boolean;
    offset?: number;
  };
  height?: number;
}) => {
  const [data, loading, error] = d;

  if (error) {
    return (
      <Paper>
        <Centered height={height}>Something went wrong.</Centered>
      </Paper>
    );
  }

  if (loading || !data) {
    return (
      <Paper>
        <Centered height={height}>
          <Loader size={24} />
        </Centered>
      </Paper>
    );
  }

  return (
    <>
      <RowsRenderer
        {...tableProps}
        renderHead
        rows={data.rows}
        headProps={headProps}
        variant="contained"
        rowHeight={rowHeight}
        rowToKey={rowToKey}
        rowToHref={rowToHref}
      />
    </>
  );
};
