import moment from 'moment-timezone';
import { useMemo } from 'react';
import { Timeframe } from '../../../../domainTypes/analytics';
import { CurrencyCode } from '../../../../domainTypes/currency';
import { Doc } from '../../../../domainTypes/document';
import { Payout } from '../../../../domainTypes/payout';
import {
  SalesFilterArgs,
  SALE_TYPES,
  TransactionsArgs
} from '../../../../domainTypes/performance';
import { Routes } from '../../../../domainTypes/routes';
import { NETWORKS_WITH_MATCHING_PAYOUT_IDS } from '../../../../services/payouts';
import { useSalesConverted } from '../../../../services/sales/sales';
import { timeframeToMs, toMoment } from '../../../../services/time';
import { useTotals } from '../../services/sale';
import {
  createPayoutIdFilterDefinition,
  createPlatformFilterDefinition,
  createTransactionTypeFilterDefinition
} from '../../../../components/analytics_v2/Filters/filters';
import { compact } from 'lodash';

const now = new Date().getTime();
const nowMoment = moment();
const SALE_DATE_COLUMN = 'sale_date';
export const TRANSACTIONS_PAGE_SIZE = 20;

export const toTransactionsLink = (
  routes: Routes,
  payout: Doc<Payout>,
  timeframe: Timeframe
) => {
  const salesFilter = toSalesFilter({ timeframe, payout });

  return routes.performanceNew.transactions.url({
    filters: compact([
      salesFilter.partner_key &&
        createPlatformFilterDefinition(salesFilter.partner_key),
      createPayoutIdFilterDefinition([payout.data.payoutId]),
      createTransactionTypeFilterDefinition(SALE_TYPES)
    ]),
    timeframe: {
      start: moment(salesFilter.dates.start).format('YYYY-MM-DD'),
      end: moment(salesFilter.dates.end).format('YYYY-MM-DD')
    }
  });
};

const toSalesFilter = ({
  timeframe,
  payout
}: {
  timeframe: Timeframe;
  payout: Doc<Payout>;
}): SalesFilterArgs => {
  // Look for transactions within one year of the payout date by default
  const query: SalesFilterArgs = {
    dates: {
      start: payout.data.payoutDate
        ? toMoment(payout.data.payoutDate).subtract(1, 'year').valueOf()
        : nowMoment.subtract(1, 'year').valueOf(),
      end: payout.data.payoutDate
        ? toMoment(payout.data.payoutDate).add(1, 'year').valueOf()
        : now,
      tz: timeframe.tz
    }
  };

  const hasInvoiceId = NETWORKS_WITH_MATCHING_PAYOUT_IDS.includes(
    payout.data.partnerKey
  );

  query.partner_key = [payout.data.partnerKey];

  if (hasInvoiceId) {
    query.payout_id = [payout.data.payoutId];
  }

  if (payout && payout.data.reportingPeriod && !hasInvoiceId) {
    const tf: Timeframe = {
      start: toMoment(payout.data.reportingPeriod.start).format('YYYY-MM-DD'),
      end: toMoment(payout.data.reportingPeriod.end)
        .add(1, 'day')
        .format('YYYY-MM-DD'), // one day because of our weird dates
      tz: timeframe.tz
    };
    query.dates = { ...timeframeToMs(tf), column: SALE_DATE_COLUMN };
  }

  if (
    payout &&
    payout.data.reportingPeriod &&
    hasInvoiceId &&
    payout.data.payoutDate
  ) {
    // Look for commissions matching the payout
    // through the date of the invoice, just in case --
    // we have seen this happen with Impact bonuses
    // which are paid after the reporting period
    const tf: Timeframe = {
      start: toMoment(payout.data.reportingPeriod.start)
        .subtract(1, 'week')
        .format('YYYY-MM-DD'),
      end: toMoment(payout.data.payoutDate).add(1, 'day').format('YYYY-MM-DD'),
      tz: timeframe.tz
    };
    query.dates = { ...timeframeToMs(tf), column: SALE_DATE_COLUMN };
  } else if (payout && payout.data.reportingPeriod && hasInvoiceId) {
    // Otherwise, just go for the reporting period
    const tf: Timeframe = {
      start: toMoment(payout.data.reportingPeriod.start)
        .subtract(1, 'week')
        .format('YYYY-MM-DD'),
      end: toMoment(payout.data.reportingPeriod.end)
        .add(1, 'day')
        .format('YYYY-MM-DD'),
      tz: timeframe.tz
    };
    query.dates = { ...timeframeToMs(tf), column: SALE_DATE_COLUMN };
  }

  query.sale_type = SALE_TYPES;

  return query;
};

export const usePayoutEarnings = (
  spaceId: string,
  timeframe: Timeframe,
  currency: CurrencyCode,
  payout: Doc<Payout>
) => {
  const sf = toSalesFilter({ timeframe, payout });

  const queries: SalesFilterArgs = useMemo(() => {
    return { ...sf, currency };
  }, [payout, timeframe, currency]); // eslint-disable-line react-hooks/exhaustive-deps

  return useTotals(spaceId, queries, currency);
};

export const usePayoutTransactions = (
  spaceId: string,
  timeframe: Timeframe,
  currency: CurrencyCode,
  payout: Doc<Payout>,
  page: number
) => {
  const q: TransactionsArgs = useMemo(() => {
    let query: TransactionsArgs = {
      dates: { ...timeframeToMs(timeframe), column: SALE_DATE_COLUMN },
      page,
      limit: TRANSACTIONS_PAGE_SIZE
    };

    if (payout) {
      const filterParams = toSalesFilter({ timeframe, payout });
      query = { ...query, ...filterParams };
    }

    return query;
  }, [payout, timeframe, page]);
  return useSalesConverted(spaceId, q, currency);
};
