import {
  Button,
  InputAdornment,
  InputLabel,
  TextField
} from '@material-ui/core';
import { head, isEmpty, isNil, negate } from 'lodash';
import { Moment } from 'moment-timezone';
import React, { useCallback } from 'react';
import { Field, Form } from 'react-final-form';
import { AdvertiserSelector } from '../../../components/AdvertisersSelector';
import { AutocompleteUserMultiById } from '../../../components/AutocompleteUserMulti';
import { DateRangePicker } from '../../../components/DateRangePicker';
import {
  beginsWithProtocol,
  isPositive,
  required
} from '../../../components/Form/validators';
import { MoneyInput } from '../../../components/MoneyInput';
import { ICampaign } from '../../../domainTypes/campaigns';
import { symbolForCurrencyCode } from '../../../domainTypes/currency';
import { css, styled } from '../../../emotion';
import { fromMoment, toMoment } from '../../../services/time';
import { useSpaceCurrency } from '../../../services/useSpaceCurrency';
import { DeleteCampaign } from './DeleteCampaign';
import { LinksSelector } from './LinksSelector';
import { removeTrailingSlash } from '../../../services/url';

const validUrls = (value: string) => {
  const urls = value.split('\n');
  const results = urls
    .filter(negate(isEmpty))
    .map(beginsWithProtocol)
    .filter(negate(isNil));
  return head(results);
};

const FieldContainer = styled('div')`
  grid-column: span 2;
  display: flex;
  flex-direction: column;
  gap: ${(t) => t.theme.spacing(2)}px;
`;

interface CampaignFormProps {
  campaign: ICampaign;
  saveCampaign: (campaign: CampaignFormFields) => void;
  saveLabel: string;
  allowDelete?: boolean;
}

export interface CampaignFormFields
  extends Pick<ICampaign, 'name' | 'flatSpend' | 'advertisers' | 'links'> {
  pageUrls: string;
  timeframe: {
    start: Moment;
    end: Moment;
  } | null;
  managers: string[];
  goal: number;
}

export const fieldsToFirestore = (
  campaign: CampaignFormFields
): Omit<ICampaign, 'id' | 'spaceId'> => {
  return {
    name: campaign.name,
    timeframe: campaign.timeframe
      ? {
          start: fromMoment(campaign.timeframe.start),
          end: fromMoment(campaign.timeframe.end)
        }
      : null,
    flatSpend: campaign.flatSpend * 100,
    goals: [{ type: 'roas', amount: campaign.goal }],
    advertisers: campaign.advertisers,
    managers: campaign.managers,
    pageUrls: campaign.pageUrls.split('\n').filter(negate(isEmpty)),
    links: campaign.links
  };
};

export const CampaignForm: React.FC<CampaignFormProps> = ({
  campaign,
  saveCampaign,
  saveLabel,
  allowDelete
}) => {
  const currency = useSpaceCurrency();

  const submit = useCallback(
    (fields: CampaignFormFields) => {
      saveCampaign(fields);
    },
    [saveCampaign]
  );

  return (
    <Form
      onSubmit={submit}
      initialValues={{
        name: campaign.name,
        flatSpend: campaign.flatSpend / 100,
        goal: campaign.goals[0]?.amount ?? 1,
        advertisers: campaign.advertisers,
        links: campaign.links,
        managers: campaign.managers,
        pageUrls: campaign.pageUrls.join('\n'),
        timeframe: campaign.timeframe
          ? {
              start: toMoment(campaign.timeframe.start),
              end: toMoment(campaign.timeframe.end)
            }
          : null
      }}
      render={({ handleSubmit, submitting, values }) => {
        return (
          <form
            className={css((t) => ({
              paddingTop: t.spacing(3),
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              columnGap: t.spacing(2),
              rowGap: t.spacing(4)
            }))}
            onSubmit={handleSubmit}
          >
            <FieldContainer>
              <InputLabel>Campaign name</InputLabel>
              <Field name="name" validate={required}>
                {({ input, meta }) => {
                  const hasError = meta.error && meta.touched;
                  return (
                    <TextField
                      name={input.name}
                      value={input.value}
                      onChange={input.onChange}
                      error={hasError}
                      helperText={hasError && 'Name is required'}
                      onBlur={input.onBlur}
                      onFocus={input.onFocus}
                      autoFocus
                      variant="outlined"
                      fullWidth
                    />
                  );
                }}
              </Field>
            </FieldContainer>
            <FieldContainer>
              <InputLabel>Advertisers</InputLabel>
              <Field name="advertisers">
                {({ input }) => (
                  <AdvertiserSelector
                    value={input.value}
                    onChange={input.onChange}
                  />
                )}
              </Field>
            </FieldContainer>
            <FieldContainer>
              <InputLabel>Page URLs</InputLabel>
              <Field name="pageUrls" validate={validUrls}>
                {({ input, meta }) => {
                  const hasError = meta.error && meta.touched;
                  return (
                    <TextField
                      id="page-urls"
                      value={input.value}
                      onChange={input.onChange}
                      onBlur={input.onBlur}
                      onFocus={input.onFocus}
                      error={hasError}
                      helperText={hasError && meta.error}
                      multiline
                      rows={4}
                      variant="outlined"
                      fullWidth
                      placeholder="One URL per line, including protocol (e.g. https://)"
                    />
                  );
                }}
              </Field>
            </FieldContainer>
            <FieldContainer>
              <InputLabel>Links</InputLabel>
              <Field name="links">
                {({ input }) => {
                  return (
                    <LinksSelector
                      value={input.value}
                      onChange={input.onChange}
                      pageUrls={values.pageUrls
                        .split('\n')
                        .map(removeTrailingSlash)}
                    />
                  );
                }}
              </Field>
            </FieldContainer>
            {campaign.id && (
              <FieldContainer>
                <InputLabel>Campaign ID</InputLabel>
                <TextField
                  id="campaign-id"
                  disabled
                  value={campaign.id}
                  required
                  variant="outlined"
                  fullWidth
                />
              </FieldContainer>
            )}
            <FieldContainer>
              <InputLabel>Campaign managers</InputLabel>
              <Field name="managers">
                {({ input }) => (
                  <AutocompleteUserMultiById
                    spaceId={campaign.spaceId}
                    value={input.value}
                    onChange={input.onChange}
                    fullWidth
                  />
                )}
              </Field>
            </FieldContainer>
            <FieldContainer>
              <InputLabel>Timeframe</InputLabel>
              <Field name="timeframe">
                {({ input }) => (
                  <DateRangePicker
                    value={input.value}
                    onChange={input.onChange}
                  />
                )}
              </Field>
            </FieldContainer>

            <FieldContainer>
              <InputLabel>Flat spend</InputLabel>
              <Field name="flatSpend" validate={isPositive}>
                {({ input, meta }) => {
                  const hasError = meta.error && meta.touched;
                  return (
                    <MoneyInput
                      onChange={input.onChange}
                      value={input.value}
                      variant="outlined"
                      numericFormatProps={{
                        prefix: symbolForCurrencyCode(currency)
                      }}
                      error={hasError}
                      helperText={
                        hasError &&
                        'Flat spend amount must be a positive number'
                      }
                      onBlur={input.onBlur}
                      onFocus={input.onFocus}
                    />
                  );
                }}
              </Field>
            </FieldContainer>
            <FieldContainer>
              <InputLabel>Campaign goal</InputLabel>
              <Field name="goal" validate={isPositive}>
                {({ input, meta }) => {
                  const hasError = meta.error && meta.touched;
                  return (
                    <TextField
                      id="campaign-goal"
                      type="number"
                      variant="outlined"
                      style={{
                        width: 130
                      }}
                      inputProps={{
                        step: 1
                      }}
                      value={input.value}
                      onChange={input.onChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">ROAS</InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">x</InputAdornment>
                        )
                      }}
                      error={hasError}
                      helperText={
                        hasError && 'Campaign goal must be a positive number'
                      }
                      onBlur={input.onBlur}
                      onFocus={input.onFocus}
                    />
                  );
                }}
              </Field>
            </FieldContainer>
            {allowDelete && (
              <div
                className={css(() => ({
                  gridColumn: '1 / 2',
                  textAlign: 'left'
                }))}
              >
                <DeleteCampaign campaign={campaign} />
              </div>
            )}
            <div
              className={css(() => ({
                gridColumn: '2 / 3',
                textAlign: 'right'
              }))}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={submitting}
              >
                {saveLabel}
              </Button>
            </div>
          </form>
        );
      }}
    />
  );
};
