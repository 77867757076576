import React from 'react';
import styled from '../emotion/styled';

interface IPageProps {
  children: any;
  width?: 'M' | 'L' | number;
  className?: string;
  style?: any;
}

const Container = styled('div')`
  display: flex;
  justify-content: center;
  height: 100%;
`;

const BodyBase = styled('div')`
  flex: 1;
  height: 100%;
  padding-bottom: ${p => p.theme.spacing() * 2}px;
`;

const BodyM = styled(BodyBase)`
  max-width: 680px;
`;

const BodyL = styled(BodyBase)`
  max-width: 1160px;
`;

const BodyCustom = styled<typeof BodyBase, { width: number }>(BodyBase)`
  max-width: ${p => p.width}px;
`;

export const Page = ({ width, className, style, children }: IPageProps) => (
  <Container>
    {typeof width === 'number' && (
      <BodyCustom className={className} style={style} width={width}>
        {children}
      </BodyCustom>
    )}
    {width === 'M' && (
      <BodyM className={className} style={style}>
        {children}
      </BodyM>
    )}
    {(width === 'L' || !width) && (
      <BodyL className={className} style={style}>
        {children}
      </BodyL>
    )}
  </Container>
);
